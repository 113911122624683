<template>
  <div
    @dragover="dragFileOver"
    @dragleave="dragFileOver"
    @drop="uploadConversation($event, 'drop')"
    class="background"
    style="width: 100%"
  >
    <PageHeader :showBreadcrumbs="false" title="Transform" />
    <PageTitle title="TRANSFORM" />
    <br />
    <br />
    <div
      style="
        width: 100%;
        background-color: white;
        border-radius: 15px;
        padding: 8px;
        margin: auto;
        margin-top: 20px;
      "
    >
      <div
        class="wrap1"
        style="padding: 25px; height: 80vh; background: white; overflow-y: auto"
      >
        <div class="grid justify-items-center">
          <div class="centered">
            <tr>
              <br />
            </tr>
            <div
              @click="$refs.file.click()"
              @dragover="dragFileOver"
              @dragleave="dragFileOver"
              @drop="uploadConversation($evt, 'drop')"
              class="uploadCanvas"
            >
              <br />
              <tr>
                <img src="./../../../assets/imageUpload.png" />
              </tr>
              <br />
              <tr>
                <span class="dragText">
                  Drag and drop 2x <b>JSON</b>
                  <br />
                  or <b><u>browse files</u></b> on computer
                </span>
              </tr>
              <!-- <editor ref="myEditor" v-model="json_2x" @init="editorInit2x" lang="json" theme="chrome" width="500" height="400px"></editor> -->
              <input
                id="contentFile"
                type="file"
                ref="file"
                accept="application/json"
                style="display: none"
                @change="uploadConversation"
              />
            </div>
            <br />
            <br />
            <div class="grid justify-items-center">
              <button
                v-if="json_3x"
                :disabled="!json_3x"
                @click="download3XJSON"
                class="btn btn-primary downloadButton"
              >
                Download
              </button>
            </div>
            <!-- <editor ref="myEditor" v-model="json_3x" @init="editorInit3x" lang="json" theme="chrome" width="500" height="400px"></editor> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { saveAs } from 'file-saver';
import axios from 'axios';
export default {
  name: 'FlowTransform',
  components: {
    // editor: require('vue2-ace-editor')
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
json_2x: '',
      json_3x: '',
    };
  },
  methods: {
    dragFileOver(event) {
      event.preventDefault();
    },
    uploadConversation(evt, drop) {
      if (drop) {
        evt.preventDefault();
      }
      // console.log(evt);
      this.json_3x = null;
      try {
        const files = evt.target?.files || evt.dataTransfer?.files;
        if (!files.length) {
          alert('No file selected!');
          return;
        }
        const file = files[0];
        const reader = new FileReader();
        this.fileName = file.name;
        reader.onload = async (event) => {
          // this.json_2x =
          let fileObj;
          try {
            fileObj = JSON.parse(reader.result) || event.target.result;
            this.json_2x = JSON.parse(reader.result) || event.target.result;
            await this.convert2xTo3x(fileObj);
          } catch (error) {
            console.error('Error: ', error.message, error);
            alert('Not a valid JSON.');
            return;
          }
        };
        reader.readAsText(file);
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    download3XJSON() {
      saveAs(
        new Blob([JSON.stringify(this.json_3x, null, 4)], {
          type: 'application/json',
        }),
        `${this.fileName}-convertedTo3x.json`
      );
    },
    async convert2xTo3x(data) {
      const conversation = this.json_2x || data;
      const x = await axios.post(
        `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/transform/2xto3x`,
        {
          idToken: this.appStore.getClaims.token,
          ...conversation,
        }
      );
      if (x.status === 200) {
        this.json_3x = x.data;
      } else {
        this.json_3x = null;
        alert('Could not transfrom to 3x');
      }
    },
    // editorInit2x: function (editor) {
    //   require('brace/mode/html');
    //   require('brace/theme/chrome');
    //   require('brace/mode/json');
    //   editor.renderer.setShowGutter(false);
    //   editor.getSession().setTabSize(2);
    //   editor.getSession().setUseWrapMode(true);
    //   console.log(editor);
    // }
  },
};
</script>

<style lang="scss" scoped>
.downloadButton {
  border-radius: 15px;
  height: 35px;
  width: 100px;
}

.ace_editor {
  border: 1px solid grey;
  overflow: auto;
}
.wrap1::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.wrap1 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.dragText {
  font-size: 17px;
  font-weight: 400;
}

.uploadCanvas {
  padding: 15px 35px 15px 35px;
  width: 500px;
  cursor: copy;
  width: 40vw;
  border-right: 2px solid lightgrey;
  border-radius: 7px;
  background: #edf0f4 !important;
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 35vh;
  border: 2px dashed #8e9ba7;
  opacity: 1;
}
</style>
