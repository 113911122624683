<template>
  <div class="dialog-content-overlay" :style="modalStyles">
    <div class="dialog-content-header">
      <div class="closeButton">
        <button
          class="dialog-close-btn"
          @click="
            () => {
              closeModal();
            }
          "
        >
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>
    <div class="dialog-content">
      <div class="dialog-c-title" v-if="params.title" v-html="params.title || ''"></div>
      <div v-if="params.impersonate && params.impersonate != 'NONE'" class="dialog-c-impersonate">
        <span class="impersonate"><i class="fas fa-clone"></i> {{ params.impersonate }} </span><br />
      </div>
      <component v-bind="params.props" v-if="params.component" :is="params.component"> </component>
      <div class="dialog-c-description">
        <input @keyup.enter="saveDescription" type="text" style="width: 100%" placeholder="Add Slot Description" v-model="params.description" />
      </div>
    </div>

    <div class="vue-dialog-buttons" v-if="buttons">
      <button v-for="(button, i) in buttons" :class="button.class || 'vue-dialog-button'" type="button" :style="buttonStyle" :key="i" @click.stop="click(i, $event)" v-html="button.title" />
    </div>
    <div v-else class="vue-dialog-buttons-none"></div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'CustomDialog',
  props: {
    collection: {
      required: false
    },
    width: {
      type: [Number, String],
      default: 1050
    },
    clickToClose: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'fade'
    },
    inputParams: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      params: {},
      defaultButtons: [{ title: 'CLOSE' }],
      modalOptions: {
        adaptive: true,
        teleportTo: 'body',
        modelValue: false,
        displayDirective: 'if',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        clickToClose: true,
        escToClose: true,
        background: 'non-interactive',
        lockScroll: true,
        swipeToClose: 'none',
        transition: 'slide-fade',
        fitParent: true
      }
    };
  },
  mounted() {
    this.params = this.inputParams || {};
    if (this.appStore.slots.length === 0) {
      this.appStore.SET_SLOTS({ collection: this.collection });
    }
  },
  computed: {
    buttons() {
      return this.params.buttons || this.defaultButtons;
    },
    /**
     * Returns FLEX style with correct width for arbitrary number of
     * buttons.
     */
    buttonStyle() {
      return {
        flex: `1 1 ${100 / this.buttons.length}%`
      };
    },
    wrapperStyles() {
      return {
        'z-index': 5000
      };
    },
    modalStyles() {
      return {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxImageWidth}`,
        'max-height': `${this.setModalWidthHeight().maxImageHeight}`,
        'pivot-y': 0.3
      };
    }
  },
  methods: {
    saveDescription(e) {
      this.$emit('saveDescription', e);
    },
    beforeOpened(event) {
      window.addEventListener('keyup', this.onKeyUp);
      this.params = event.params || {};
      this.$emit('before-opened', event);
    },
    beforeClosed(event) {
      window.removeEventListener('keyup', this.onKeyUp);
      this.params = {};
      this.$emit('before-closed', event);
    },
    click(i, event, source = 'click') {
      const button = this.buttons[i];
      if (button && typeof button.handler === 'function') {
        button.handler(i, event, { source });
      } else {
        this.$emit('closed');
      }
    },
    onKeyUp(event) {
      if (event.which === 13 && this.buttons.length > 0) {
        const buttonIndex = this.buttons.length === 1 ? 0 : this.buttons.findIndex((button) => button.default);
        if (buttonIndex !== -1) {
          this.click(buttonIndex, event, 'keypress');
        }
      }
    },
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '800px';
      let width = `${this.width}px`;
      let height = 'auto';

      if (window.innerWidth < 1050) {
        width = '90%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    },
    closeModal() {
      this.$emit('close');
    }
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.onKeyUp);
  }
};
</script>

<style scoped>
.dialog-content-overlay {
  background-color: #fff;
  border-radius: 10px;
}

.dialog-content-header {
  position: relative;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
}

.impersonate {
  background-color: lightblue;
  color: black;
  padding: 10px;
  border-radius: 25px;
  font-weight: bold;
}
</style>

<style>
.dialog-content-overlay div {
  box-sizing: border-box;
}

.dialog-content-overlay .dialog-flex {
  width: 100%;
  height: 100%;
}

.dialog-content-overlay .dialog-content {
  flex: 1 0 auto;
  width: 100%;
  padding: 15px;
  font-size: 14px;
}

.dialog-content-overlay .dialog-c-title {
  font-weight: 600;
  padding-bottom: 15px;
}

.dialog-content-overlay .dialog-c-description {
  color: grey;
  font-size: 10px;
  width: 50vw;
}

.dialog-content-overlay .dialog-c-impersonate {
  color: black;
  font-size: 12px;
  float: right;
}

.dialog-content-overlay .vue-dialog-buttons {
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  border-top: 1px solid #eee;
  padding: 15px;
}

.dialog-content-overlay .vue-dialog-buttons-none {
  width: 100%;
  padding-bottom: 15px;
}

.vue-dialog-button {
  font-size: 12px;
  background: #fff;
  padding: 0;
  margin: 0;
  border: 0;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 40px;
  color: #111;
  font: inherit;
  outline: none;
}

.vue-dialog-button:hover {
  background: rgba(0, 0, 0, 0.01);
  color: #000;
}

.vue-dialog-button:active {
  background: rgba(0, 0, 0, 0.025);
  color: #000;
}

.vue-dialog-button:not(:first-of-type) {
  border-left: 1px solid #eee;
}
</style>
