<template>
  <div class="slider">
    <carousel :perPage="1" :autoplay="false" :autoplayHoverPause="true" :centerMode="true" :navigationEnabled="false" paginationColor="#000000" :paginationEnabled="true" paginationPosition="bottom" navigationNextLabel="<button class='mdc-fab mdc-fab--mini material-icons rightnav'><span class='mdc-fab__icon'>keyboard_arrow_right</span></button>" navigationPrevLabel="<button class='mdc-fab mdc-fab--mini material-icons leftnav'><span class='mdc-fab__icon'>keyboard_arrow_left</span></button>" :navigationClickTargetSize="0" :paginationSize="15" :paginationPadding="5" :loop="true">
      <slide v-for="(slide, i) in slides" :key="i">
        <div>
          <img width="220" height="140" v-if="slide.imageUri" :src="slide.imageUri" />
          <section
            :class="{
              infonew: !slide.imageUri,
              infonewBorder: slide.imageUri
            }"
          >
            <tr v-html="slide.title" class="titlenew"></tr>
            <tr v-html="slide.subtitle" class="subtitlenew"></tr>
            <tr class="openpage">
              <a v-html="slide.button" target="_blank"></a>
            </tr>
          </section>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  props: {
    slides: {
      type: Array
    }
  },
  data() {
    return {};
  },
  components: {
    Carousel,
    Slide
  }
};
</script>

<style scoped>
.carousel-item {
}

.VueCarousel-inner {
  height: 100px;
}

.avatarnew {
  margin-top: 1px;
  margin-left: 5px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  background-size: cover;
  float: right;
}

.titlenew {
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 600;
  line-height: 1.5em;
  overflow: hidden;
  font-size: 16px;
  color: black;
  margin-left: 20px;
}

.subtitlenew {
  font-size: 13px;
  color: grey;
  margin-left: 20px;
  line-height: 2em;
}

.infonew {
  padding: 10px 10px 10px 25px;
  border-radius: 15px;
  background-color: white;
  width: 95%;
  border: 1px solid black;
  min-height: 50px;
}

.openpage {
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
  font-size: 14px;
  color: black;
  margin-left: 20px;
  text-align: left;
}

.slider {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
}
</style>
