import { isDeepEqual } from '@/helpers/utils';

/**
 * The Caretaker class is responsible for the external caretaker object in the Memento design pattern.
 * It keeps track of multiple mementos, representing states at different points in time, and allows
 * undoing and redoing changes to the originator's state.
 *
 * @example // Initialize a new caretaker
 *          const caretaker = new Caretaker(originator);
 */
class Caretaker {
  mementos = [];
  currentIndex = -1;

  /**
   * The originator object that has the capability to save its current state
   * and restore to a previous state.
   * NOTE: originator MUST clone its state before saving and restoring
   * @exemple
   *   class Originator {
   *     constructor() {
   *       this.state = {};
   *     }
   *
   *     save() {
   *       return cloneDeep(this.state);
   *     }
   *
   *     restore(memento) {
   *       this.state = cloneDeep(memento);
   *     }
   *   }
   * @type {{save: ()=> Object, restore: (mementos: Object) => void}}
   */
  originator;

  /**
   * Initializes the caretaker with an originator object.
   * @param {Object} originator - The originator object whose state is to be saved and restored.
   */
  constructor(originator) {
    if (typeof originator !== 'object' || typeof originator.save !== 'function' || typeof originator.restore !== 'function') {
      throw new TypeError('Originator must have save and restore functions');
    }
    this.originator = originator;
  }

  /**
   * Starts the tracking of originator's states by creating the first memento.
   * @returns {Object} The first memento of the originator's state if tracking has not started, otherwise undefined.
   */
  start() {
    if (this.currentIndex === -1) {
      return this.backup();
    }
  }

  /**
   * Clears all recorded mementos and resets the current index.
   */
  clear() {
    this.mementos = [];
    this.currentIndex = -1;
  }

  /**
   * Saves the current state of the originator as a new memento and updates the current index.
   */
  backup() {
    const newMemento = this.originator.save();
    if (this.currentIndex > -1) {
      const currentMemento = this.mementos[this.currentIndex];
      if (isDeepEqual(newMemento, currentMemento)) {
        return;
      }
      if (this.currentIndex < this.mementos.length - 1) {
        this.mementos = this.mementos.slice(0, this.currentIndex + 1);
      }
    }
    this.mementos.push(newMemento);
    this.currentIndex = this.mementos.length - 1;
    // console.log('[MEM] Backup - index: ' + this.currentIndex + ' count: ' + this.mementos.length);
  }

  /**
   * Undoes the last change to the originator's state by restoring the previous memento, if available.
   */
  undo() {
    if (!this.canUndo()) {
      return;
    }
    this.currentIndex -= 1;
    const memento = this.mementos[this.currentIndex];
    this.originator.restore(memento);
    // console.log('[MEM] Undo - index: ' + this.currentIndex + ' count: ' + this.mementos.length);
  }

  /**
   * Checks if it's possible to undo to a previous state.
   * @returns {boolean} - True if there is a previous state to undo to, false otherwise.
   */
  canUndo() {
    return this.currentIndex > 0;
  }

  /**
   * Checks if it's possible to redo to a subsequent state.
   * @returns {boolean} - True if there is a subsequent state to redo to, false otherwise.
   */
  canRedo() {
    return this.currentIndex < this.mementos.length - 1 && this.currentIndex > -1;
  }

  /**
   * Redoes the last undone change to the originator's state by restoring the next memento, if available.
   */
  redo() {
    if (!this.canRedo()) {
      return;
    }
    this.currentIndex += 1;
    const memento = this.mementos[this.currentIndex];
    this.originator.restore(memento);
    // console.log('[MEM] Redo - index: ' + this.currentIndex + ' count: ' + this.mementos.length);
  }

  /**
   * Prints the history of all saved mementos to the console.
   */
  mapMementos(fn = (memento) => JSON.stringify(memento)) {
    return this.mementos.map(fn);
  }
}

export default Caretaker;
