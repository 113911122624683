<template>
  <path
    :marker-end="pathStyle.markerEnd"
    :id="`line-${source}-${positions[0]}-${optionIndex}`"
    class="edge__connection-path"
    :d="line"
    :style="pathStyle"
  />
</template>

<script>
import * as d3 from 'd3';
export default {
  name: 'EdgesStraightDecision',
  props: {
    optionIndex: {},
    source: {},
    target: {},
    curved: {},
    dotted: {},
    status: {
      default: '',
    },
    positions: {},
    id: {},
    start: {},
    end: {},
    radius: {
      default: 5,
    },
    pathStyle: {},
  },
  computed: {
    points() {
      const isLeft = this.positions[0] === 'left' ? true : false;
      let points;
      if (this.start[0] < this.end[0] - 150) {
        points = [
          this.start,
          [
            isLeft
              ? this.leadLeft(this.start[0])
              : this.leadRight(this.start[0]),
            this.start[1],
          ],
          [
            isLeft
              ? this.leadLeft(this.start[0], 1.2)
              : this.leadRight(this.start[0], 1.2),
            this.start[1],
          ],
          [
            this.end[0] + this.offsetX(isLeft),
            this.leadTop(this.end[1] - this.radius, isLeft),
          ],
          [this.end[0], this.leadTop(this.end[1] - this.radius)],
          this.end,
        ];
      }
      //  else if (this.start[0] < this.end[0]) {
      //   points = [this.start, [isLeft ? this.leadLeft(this.start[0]) : this.leadRight(this.start[0]), this.start[1]], [isLeft ? this.leadLeft(this.start[0], 1.2) : this.leadRight(this.start[0], 1.2), this.start[1]], [this.end[0] + this.offsetX(isLeft), this.leadTop(this.end[1] - this.radius, isLeft)], [this.end[0], this.leadTop(this.end[1] - this.radius)], this.end];
      // }
      else if (this.start[0] > this.end[0] + 200) {
        points = [
          this.start,
          [
            isLeft
              ? this.leadLeft(this.start[0])
              : this.leadRight(this.start[0]),
            this.start[1],
          ],
          [
            isLeft
              ? this.leadLeft(this.start[0], 1.2)
              : this.leadRight(this.start[0], 1.2),
            this.start[1],
          ],
          [
            this.end[0] + this.offsetX(isLeft),
            this.leadTop(this.end[1] - this.radius, isLeft),
          ],
          [this.end[0], this.leadTop(this.end[1] - this.radius)],
          this.end,
        ];
      } else {
        points = [
          this.start,
          [this.end[0], this.leadTop(this.end[1] - this.radius)],
          this.end,
        ];
      }
      return points;
    },
    line() {
      this.points;
      const values = this.points.find(([elem]) => elem);
      if (values) {
        return d3
          .line()
          .x((d) => d[0])
          .y((d) => d[1])
          .curve(d3.curveStepAfter)(this.points);
      }
      return false;
    },
  },
  methods: {
    offsetX(isLeft) {
      if (isLeft) return 200;
      else return -200;
    },
    leadLeft(point, multiplier = 1) {
      return point - 10 * multiplier;
    },
    leadRight(point, multiplier = 1) {
      return point + 10 * multiplier;
    },
    leadTop(point, isLeft) {
      if (isLeft) {
        return point - 25 - this.radius * 2;
      }
      return point - 25 + this.radius * 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.edge__connection {
  position: relative;
}
.edge__connection:hover {
  cursor: pointer;

  .edge__connection-path:hover {
    stroke: #2698ed;
    stroke-width: 2;
  }
}
</style>
