<template>
  <div :style="carouselDeleteCardModalStyle">
    <div class="sidebar components">
      <div class="carousel-container">
        <div class="row cards-index-wrapper">
          <div class="col-sm-2">
            <span class="sidebar type card-type-title">Cards</span>
          </div>
          <div class="col-md-10">
            <div class="row">
              <div :class="isSelectedCarousel('')" @click="switchCarousels('main', -1, $event)">
                <span>1</span>
              </div>
              <div :class="isSelectedCarousel(index)" v-for="(param, index) in carousels" :key="index" @click="switchCarousels(param.id, index, $event)">
                <div class="delete-card-wrapper hide-card" @click="showDeleteCardModal(param, $event)">
                  <span>x</span>
                </div>
                <span>{{ index + 2 }}</span>
                <div v-if="deleteCardModal && cardToDelete && cardToDelete.id === param.id" class="delete-card-modal triangle">
                  <div class="delete-card-modal-wrapper">
                    <div class="delete-card-modal-text">Are you sure you want to delete this card?</div>
                    <div class="delete-card-modal-buttons">
                      <button class="delete-modal-button button-cancel" @click="cancelDeleteCard($event)">Cancel</button>
                      <button class="delete-modal-button button-delete" @click="deleteCard($event)">Delete</button>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="addCarouselButtonClass()" @click="addNewCard">
                <span>+</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sidebar components">
      <div class="sidebar-text-input">
        <SidebarText :field="`imageUrl`" :imageUpload="`true`" :placeHolder="'Add URL image'" :id="componentSelected.id" :mainComponentId="getMainComponentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Image URL</span>
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
    </div>
    <div class="sidebar components" style="padding-bottom: 4px">
      <div class="row editor-header">
        <div class="col-11" style="margin-bottom: 10px">
          <span class="sidebar type">Image size</span>
        </div>
        <RadioButton :key="componentKey" :payload="{ imageSizeSelection: 'auto' }" :onSelect="editImageSize" :selected="componentSelected.imageSizeSelection === 'auto'" :componentId="componentSelected.id">
          <template v-slot:title> Auto size </template>
        </RadioButton>
        <RadioButton :key="componentKey" :payload="{ imageSizeSelection: 'custom' }" :onSelect="editImageSize" :selected="componentSelected.imageSizeSelection === 'custom'" :componentId="componentSelected.id">
          <template v-slot:title> Custom size </template>
        </RadioButton>
      </div>
    </div>
    <div v-if="componentSelected.imageSizeSelection === 'custom'" class="sidebar components" style="padding-bottom: 4px">
      <div class="row editor-header">
        <div class="col-11" style="margin-bottom: 10px">
          <span class="sidebar type">Size</span>
        </div>
      </div>
      <div class="sidebar-prefix-container">
        <div class="sidebar-text-input">
          <SidebarText :field="`imageWidth`" :prefix="`w`" :id="componentSelected.id" :mainComponentId="getMainComponentId" :number="`true`" />
        </div>
        <div class="sidebar-text-input">
          <SidebarText :field="`imageHeight`" :prefix="`h`" :id="componentSelected.id" :mainComponentId="getMainComponentId" :number="`true`" />
        </div>
      </div>
    </div>
    <div class="sidebar components">
      <div class="row editor-header">
        <div class="col-11" style="margin-bottom: 10px">
          <span class="sidebar type">Image</span>
        </div>
      </div>
      <div v-if="!isValidUrl(componentSelected.imageUrl)" class="sidebar-image-container">
        <img src="./../../../../../../assets/imageUpload.png" />
      </div>
      <div v-if="isValidUrl(componentSelected.imageUrl)" class="sidebar-image-container-with">
        <img :src="componentSelected.imageUrl" :style="imageStyling" alt="image" />
      </div>
    </div>
    <div class="sidebar components">
      <div class="sidebar-text-input">
        <SidebarText :field="`imageAltText`" :placeHolder="'Add title'" :id="componentSelected.id" :mainComponentId="getMainComponentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Image alt text</span>
                <ConversationBuilderIcon v-if="false" name="info" :styleOnIcon="{ marginTop: '-3px' }" />
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
    </div>
    <div class="sidebar components">
      <div class="sidebar-text-input">
        <SidebarText :field="`title`" :placeHolder="'Add title'" :id="componentSelected.id" :mainComponentId="getMainComponentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Card Title</span>
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
    </div>
    <div class="sidebar components">
      <div class="sidebar-text-input">
        <SidebarText :field="`subTitle`" :placeHolder="'Add subtitle'" :id="componentSelected.id" :mainComponentId="getMainComponentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Card Subtitle</span>
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
    </div>
    <div class="sidebar components">
      <div class="sidebar-text-input">
        <SidebarText :field="`cardCta`" :placeHolder="'Add CTA'" :id="componentSelected.id" :mainComponentId="getMainComponentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">CTA</span>
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
    </div>
    <div class="sidebar components">
      <ReferenceInput :field="`reference`" :id="componentSelected.id" :mainComponentId="getMainComponentId" :reference="componentSelected.reference">
        <template v-slot:header>
          <div class="row editor-header">
            <div class="col-11">
              <div>
                <ConversationBuilderIcon :width="14" name="reference" />
                <span class="sidebar type" style="margin-left: 12px">Internal Reference</span><ConversationBuilderIcon v-if="false" name="info" />
              </div>
            </div>
          </div>
        </template>
      </ReferenceInput>
    </div>
    <div class="sidebar components" style="padding-bottom: 4px">
      <div class="row editor-header">
        <div class="col-11" style="margin-bottom: 10px">
          <span class="sidebar type">Link To</span>
        </div>
        <RadioButton :key="componentKey" :payload="{ linkTo: 'url' }" :onSelect="linkTo" :selected="componentSelected.linkTo.type === 'url'" :componentId="componentSelected.id">
          <template v-slot:title> URL </template>
        </RadioButton>
        <RadioButton :key="componentKey" :payload="{ linkTo: 'flow' }" :onSelect="linkTo" :selected="componentSelected.linkTo.type === 'flow'" :componentId="componentSelected.id">
          <template v-slot:title> Flow </template>
        </RadioButton>
      </div>
    </div>
    <div v-if="componentSelected.linkTo.type === 'url'" class="sidebar components" style="padding-bottom: 4px">
      <LinkUrlEdit :id="componentSelected.id" :carousel="true" :mainComponentId="getMainComponentId" />
    </div>
    <div v-if="componentSelected.linkTo.type === 'flow'" class="sidebar components" style="padding-bottom: 4px">
      <FlowEdit :id="componentSelected.id" :carousel="true" :mainComponentId="getMainComponentId" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import essentials from '@/components/ConversationBuilder/Canvas/3.0/Mixins/essentials';
import ReferenceInput from '../Inputs/ReferenceInput.vue';
import LinkUrlEdit from '@/components/ConversationBuilder/Canvas/3.0/SidebarComponents/LinkUrlEdit';
import FlowEdit from '@/components/ConversationBuilder/Canvas/3.0/SidebarComponents/FlowEdit';

export default {
  name: 'CarouselEdit',
  mixins: [essentials],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      selectedCard: '',
      imageData: null,
      image: null,
      componentKey: 0,
      deleteCardModal: false,
      cardToDelete: ''
    };
  },
  mounted() {
    if (this.componentSelected && this.carousels && this.selectedCarouselCard && this.selectedCarouselCard !== '') {
      const index = this.carousels.findIndex((object) => {
        return object.id === this.selectedCarouselCard;
      });
      this.switchCarousels(this.selectedCarouselCard, index, null);
    }
  },
  computed: {
    getMainComponentId: {
      get() {
        return this.componentSelected?.mainCarouselId ? this.componentSelected?.mainCarouselId : '';
      }
    },
    selectedCarouselCard: {
      get() {
        return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id)?.selectedCarouselCard;
      }
    },
    componentSelected: {
      get() {
        const selectedComponent = this.appStore.getComponentListFromComponents.find((x) => x.id === this.id);
        if (this.selectedCard !== '') {
          if (selectedComponent?.carousels?.length > 0) {
            return selectedComponent.carousels[this.selectedCard];
          }
        }
        return selectedComponent;
      }
    },
    carousels() {
      let carousels;
      if (this.selectedCard !== '') {
        carousels = this.appStore.getComponentListFromComponents.find((x) => x.id === this.id).carousels;
      } else if (this.componentSelected) {
        carousels = this.componentSelected?.carousels;
      } else {
        return [];
      }
      const index = carousels.findIndex((x) => x.main === true);
      if (index > -1) {
        carousels.splice(index, 1);
      }
      return carousels;
    },
    imageStyling() {
      return {
        width: this.componentSelected.imageWidth && this.componentSelected.imageSizeSelection === 'custom' ? this.componentSelected.imageWidth + 'px' : 'auto',
        height: this.componentSelected.imageSizeSelection === 'custom' && this.componentSelected.imageHeight ? this.componentSelected.imageHeight + 'px' : 'auto'
      };
    },
    carouselDeleteCardModalStyle() {
      if (this.deleteCardModal) {
        return {
          position: 'absolute'
        };
      } else return {};
    }
  },
  watch: {
    selectedCarouselCard() {
      const index = this.carousels.findIndex((object) => {
        return object.id === this.selectedCarouselCard;
      });
      this.switchCarousels(this.selectedCarouselCard, index, null);
    }
  },
  methods: {
    isValidUrl(imageUrl) {
      let url;

      try {
        url = new URL(imageUrl);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'data:';
    },
    showDeleteCardModal(card, event) {
      event.stopPropagation();
      this.deleteCardModal = true;
      this.cardToDelete = card;
    },
    cancelDeleteCard(event) {
      event.stopPropagation();
      this.deleteCardModal = false;
      this.cardToDelete = '';
    },
    deleteCard(event) {
      event.stopPropagation();
      const id = this.componentSelected.mainCarouselId;
      this.selectedCard = '';
      this.deleteCardModal = false;
      if (this.componentSelected.mainCarouselId && this.componentSelected.mainCarouselId !== '') {
        this.appStore.updateComponent({
          id: this.componentSelected.mainCarouselId,
          selectedCarouselCard: id
        });
      } else {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          selectedCarouselCard: id
        });
      }
      if (this.cardToDelete.mainCarouselId && this.cardToDelete.mainCarouselId !== '') {
        this.appStore.removeItemFromCollection({
          componentId: this.cardToDelete.id,
          mainCarouselId: this.cardToDelete.mainCarouselId,
          collectionField: 'carousels'
        });
      }
      this.cardToDelete = '';
    },
    isSelectedCarousel(index) {
      let className = 'carousel-index';
      if (this.selectedCard === index) {
        className += ' selected-carousel';
      }
      if (index > 6) {
        className += ' add-margin-top-card';
      }

      return className;
    },
    addCarouselButtonClass() {
      let className = 'add-carousel-button';
      if (this.componentSelected.mainCarouselId) {
        if (this.appStore.getComponentListFromComponents.find((x) => x.id === this.componentSelected.mainCarouselId).carousels.length > 6) {
          className += ' add-margin-top-remove-margin-right';
        }
      } else {
        if (this.appStore.getComponentListFromComponents.find((x) => x.id === this.id).carousels.length > 6) {
          className += ' add-margin-top-remove-margin-right';
        }
      }
      return className;
    },
    editImageSize(payload) {
      if (this.componentSelected.mainCarouselId && this.componentSelected.mainCarouselId !== '') {
        this.appStore.updateComponent({
          ...payload,
          id: this.componentSelected.id,
          mainCarouselId: this.componentSelected.mainCarouselId
        });
      } else {
        this.appStore.updateComponent({ ...payload, id: this.id });
      }
      this.componentKey += 1;
    },
    linkTo(payload) {
      if (this.componentSelected.mainCarouselId && this.componentSelected.mainCarouselId !== '') {
        this.appStore.updateComponent({
          ...payload,
          id: this.componentSelected.id,
          mainCarouselId: this.componentSelected.mainCarouselId
        });
      } else {
        this.appStore.updateComponent({ ...payload, id: this.id });
      }
      this.componentKey += 1;
    },
    addNewCard() {
      this.deleteCardModal = false;
      this.cardToDelete = '';
      this.appStore.addItemToCollection({
        componentId: this.id,
        collectionField: 'carousels'
      });
    },
    switchCarousels(id, index, event) {
      if (event) {
        event.stopPropagation();
      }

      if (id === 'main') {
        id = this.componentSelected.mainCarouselId;
      }
      if (index === -1) {
        this.selectedCard = '';
      } else {
        this.selectedCard = index;
      }

      this.cardToDelete = '';
      this.deleteCardModal = false;

      if (this.componentSelected.mainCarouselId && this.componentSelected.mainCarouselId !== '') {
        this.appStore.updateComponent({
          id: this.componentSelected.mainCarouselId,
          selectedCarouselCard: id
        });
      } else {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          selectedCarouselCard: id
        });
      }

      return this.selectedCard;
    }
  },

  components: {
    ReferenceInput,
    LinkUrlEdit,
    FlowEdit
  }
};
</script>

<style lang="scss" scoped>
.sidebar.components {
  /* border-bottom: 1px solid #e3e8ee; */
  padding: 15px 15px 0px 15px;
  height: max-content;
}
.carousel-container {
  align-items: center;
}
.carousel-index {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  padding: 5px 11px 6px;
  border-radius: 4px;
  background-color: #edf0f4;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  span {
    font-weight: 800;
  }
}
.carousel-index:hover {
  .delete-card-wrapper {
    display: flex;
  }
  .delete-card-wrapper:hover {
    background-color: #ff0000;
  }
}

.delete-card-modal {
  background-color: white;
  border: #d7dde5 solid 1px;
  position: absolute;
  z-index: 5;
  margin-top: 15px;
  width: 370px;
  height: 155px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  right: -20%;
}

.triangle::after {
  content: '';
  height: 8px;
  width: 8px;
  position: absolute;
  background-color: white;
  top: -5px;
  right: 5%;
  border-top: #d7dde5 solid 1px;
  border-left: #d7dde5 solid 1px;
  transform: rotate(45deg);
}

.delete-card-modal-wrapper {
  display: block;
}
.delete-card-modal-text {
  font-size: 17px;
  color: #383f45;
}
.delete-card-modal-buttons {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
}

.delete-modal-button {
  width: 120px;
  height: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-cancel {
  background-color: #f2f2f2;
  text-decoration: underline;
  color: #383f45;
}
.button-delete {
  background-color: #1996f0;
  color: white;
}
.delete-card-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 15px;
  width: 15px;
  align-items: center;
  cursor: pointer;
  background-color: #56616a;
  color: white;
  top: -5px;
  right: -5px;
  border-radius: 50%;
}
.hide-card {
  display: none;
}
.selected-carousel {
  background-color: #1996f0;
  color: white;
}
.cards-index-wrapper:nth-child(2) {
  margin-left: 10px;
}
.add-carousel-button {
  width: 30px;
  border-radius: 50%;
  height: 30px;
  margin-right: 10px;
  padding: 10px 10px 10px 10px;
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.5);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  span {
    color: #2698ed;
    font-size: 30px;
  }
}
.sidebar-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 374px;
  height: 160px;
  border: 1px dashed #8e9ba7;
}
.sidebar-image-container-with {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 374px;
  height: 160px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: fill;
  }
}
.sidebar-prefix-container {
  display: flex;
  height: 37px;
  div:nth-child(2) {
    margin-left: 10px;
  }
}
.sidebar.components.link-url {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 0px 15px;
  height: 80px;
  margin-bottom: 15px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
  text-align: left;
  line-height: 1.35;
  font-weight: 700;
}
.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}
textarea,
option,
select {
  font-size: 12px;
}
.card-type-title {
  position: relative;
  top: 4px;
}
.add-margin-top-card {
  margin-top: 5px;
}
.add-margin-top-remove-margin-right {
  margin-right: 0;
  margin-top: 5px;
}
textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
