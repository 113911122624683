<template>
  <div>
    <div v-if="!showPublished">
      <div class="named-versions-container">
        <div class="checkbox-container">
          <input class="input-checkbox" type="checkbox" @click="handleShowNamedDrafts" />
          <h5>Show named versions</h5>
        </div>

        <div v-if="showNamedDrafts" class="input-group search-input">
          <input v-model="searchBy" class="form-control py-2 border-0" type="search" placeholder="Search version by name" data-test="search-input" />
          <span class="input-group-append">
            <div class="input-group-text border-0"><i class="fa fa-search"></i></div>
          </span>
        </div>
      </div>
      <hr />

      <div v-if="!showNamedDrafts" class="drafts-container px-4">
        <DraftsContainer :id="idx" :key="idx" v-for="({ key, draftList }, idx) in draftVersionsContainers" :draft-list="draftList" :title="key" :is-expanded="idx" @expandDrafts="handleDraftsExpansion" :user-colors="userColors" />
      </div>
      <div v-else class="drafts-container" data-test="named-drafts">
        <div v-for="(draft, index) in searchNamedDrafts" :key="index" class="row collapsable-div">
          <div class="col text-start flow-version-container">
            <FlowVersion :version="draft" :icon-color="getUserColor(draft)" showNamedDrafts="true" />
          </div>
        </div>
      </div>
    </div>

    <div v-else class="published-container px-4" style="padding-top: 80px">
      <DraftsContainer :id="idx" :key="idx" v-for="({ key, draftList }, idx) in publishedVersionsContainers" :draft-list="draftList" :title="key" :is-expanded="idx" @expandDrafts="handleDraftsExpansion" :user-colors="userColors" />
      <!--      <div v-for='(version, index) in versions' :key='index' class='row collapsable-div'>
        <div class='col text-start ml-5 pl-1'>
          <FlowVersion :version='version' :icon-color='getUserColor(version)' />
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { useAppStore, useVersionsStore } from '@/store/index.js';
import { mapState } from 'pinia';
import DraftsContainer from './DraftsContainer.vue';
import { aggregateDrafts, analyseDraftVersionsForDateGrouping } from '@/helpers/dates';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'VersionsSidebar',
  components: { DraftsContainer },
  data() {
    return {
      appStore: useAppStore(),
      versionsStore: useVersionsStore(),
      expandedDrafts: 1,
      userColors: [],
      showNamedDrafts: false,
      namedDrafts: [],
      searchBy: ''
    };
  },
  mounted() {
    if (this.showPublished) {
      this.getVersionsByStatus(true);
    } else {
      this.getVersionsByStatus(false);
      this.getLatestPublished();
    }
    this.versionsStore.resetPreviewVersion();
  },
  computed: {
    ...mapState(useVersionsStore, ['versions', 'showPublished', 'latestPublishedVersion']),
    searchNamedDrafts() {
      const drafts = this.namedDrafts.filter((draft) => {
        return draft.versionName.toLowerCase().includes(this.searchBy.toLowerCase());
      });

      this.removeAllButLatestPublishedId(drafts);
      return drafts;
    },
    draftVersionsContainers() {
      const sortedDrafts = cloneDeep(this.versions) || [];
      this.removeAllButLatestPublishedId(sortedDrafts);
      const containers = analyseDraftVersionsForDateGrouping(sortedDrafts, new Date());
      return containers.map(({ key, draftList }) => ({ key, draftList: aggregateDrafts(draftList) }));
    },
    publishedVersionsContainers() {
      const sortedDrafts = cloneDeep(this.versions) || [];
      sortedDrafts.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
      const containers = analyseDraftVersionsForDateGrouping(sortedDrafts, new Date());
      return containers.map(({ key, draftList }) => ({ key, draftList: draftList.map((v) => [v]) }));
    }
  },
  methods: {
    // NOTE: this function mutates drafts
    removeAllButLatestPublishedId(drafts) {
      drafts.forEach((draft) => {
        if (Object.prototype.hasOwnProperty.call(draft, 'publishedId') && draft?.publishedId != this.latestPublishedVersion?.id) {
          delete draft.publishedId;
        }
      });
    },
    async getNamedVersions() {
      await this.getVersionsByStatus(this.showPublished, true);
      this.namedDrafts = this.versions;
      this.setUserColor();
    },
    async getVersionsByStatus(isShowPublished, filterByName = false) {
      await this.filteredVersions({
        showLoading: true,
        draftOrPublished: isShowPublished ? 'published' : 'draft',
        filterByName
      });
      this.setUserColor();
    },

    async filteredVersions({ showLoading, draftOrPublished, filterByName }) {
      const {
        current: { slot, taxonomy },
        language
      } = this.appStore;

      await this.versionsStore.getVersionsByFilter({
        taxonomy,
        slotId: slot.id,
        language,
        showLoading,
        draftOrPublished,
        filterByName
      });
    },

    getLatestPublished() {
      const taxonomy = this.appStore.currentTaxonomy;
      const slotId = this.appStore.currentSlot.id;
      const language = this.appStore.language;

      this.versionsStore.getLatestVersion({ taxonomy, slotId, draftOrPublished: 'published', language }); // find latest published version for managing published badge
    },

    handleShowNamedDrafts() {
      this.showNamedDrafts = !this.showNamedDrafts;
      if (this.showNamedDrafts === true) this.getNamedVersions();
    },

    handleDraftsExpansion() {
      // no longer supported
    },
    setUserColor() {
      if (!this.versions) return;
      this.userColors = this.versions.map((e) => e.user);
      const distinct = (val, index, self) => {
        return self.indexOf(val) === index;
      };
      this.userColors = this.userColors.filter(distinct).map((e) => ({
        user: e,
        color: '#' + Math.floor(Math.random() * 0x1000000).toString(16)
      }));
    },
    getUserColor(version) {
      if (this.userColors.find((o) => o.user === version.user)) return this.userColors.find((o) => o.user === version.user).color;
    }
  },
  watch: {
    showPublished(newValue) {
      if (newValue) {
        this.showNamedDrafts = false;
        this.getVersionsByStatus(true);
      } else {
        this.expandedDrafts = 1;

        this.getVersionsByStatus(false);
        this.getLatestPublished();
      }
    },
    showNamedDrafts(newValue) {
      if (!newValue) {
        this.expandedDrafts = 1;
        this.getVersionsByStatus(this.showPublished);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.input-checkbox {
  margin-right: 10px;
}

.named-versions-container {
  padding-top: 80px;

  .checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    margin-left: 30px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.search-input {
  width: 85%;
  max-width: 100%;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
  margin: 30px;
  display: flex;
  align-items: center;
}

.search-input input {
  outline: none !important;
  box-shadow: none !important;
}

.search-input .input-group-text {
  background: #fff;
  color: #8e9ba7;
}

.drafts-container {
  hr {
    border: solid 1px #d7dde5;
    margin: 0;
  }
}

.published-container {
  hr {
    border: solid 1px #d7dde5;
    margin: 0;
  }
}

hr {
  border: solid 1px #b3d7fc;
}

.col.text-start.ms-5.ps-1:hover {
  background: #f3f4f5;
}

.flow-version-container {
  margin-left: 48px;
  padding-left: 4px;
}
</style>
