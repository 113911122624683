import { defineStore } from 'pinia';
import axios from 'axios';
import { ElLoading } from 'element-plus';
import { useAppStore, useVcmStore } from '@/store/index.js';

export const useVersionsStore = defineStore({
  id: 'versions',
  state: () => ({
    versions: [],
    currentVersion: undefined,
    previewVersion: undefined,
    restoredPopupDate: 0,
    restoredPopupName: '',
    //restoreVersionId is a firebase doc id, not a UUID.
    restoreVersionId: undefined,
    showPublished: false,
    publishedForTopStatus: {
      flag: false,
      publishedId: 0
    },
    latestPublishedVersion: undefined, // this is used for "published" badge in drafts section
    restoredFromDateTime: null
  }),
  actions: {
    async resetPreviewVersion() {
      this.SET_PREVIEW_VERSION(this.currentVersion);
    },
    async getLatestVersion({ taxonomy, slotId, draftOrPublished, language }) {
      await axios
        .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getLatestVersion`, {
          slotId,
          taxonomy: `${taxonomy}_flows`,
          draftOrPublished,
          language
        })
        .then((response) => {
          if (draftOrPublished === 'draft') {
            this.SET_CURRENT_VERSION(response.data);
          } else {
            this.SET_LATEST_PUBLISHED_VERSION(response.data);
          }
        });
    },

    async getVersionsByFilter({ taxonomy, slotId, language, showLoading, draftOrPublished, filterByName }) {
      let loadingInstance;

      try {
        if (showLoading) {
          loadingInstance = ElLoading.service();
        }
        this.versions = [];

        const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getVersionsMetadata`, {
          slotId,
          taxonomy: `${taxonomy}_flows`,
          language,
          draftOrPublished
        });

        let data = response.data;
        // NOTE this marks this version as current for the versioning widget
        if (data && data[0]) {
          data = data.map((d, i) => ({
            ...d,
            currentversion: i === 0
          }));
        }
        if (filterByName) {
          data = data.filter ? data.filter((d) => !!d.versionName) : [];
        }
        this.SET_VERSIONS(data);
      } catch (error) {
        console.error('Error fetching versions by filter:', error);
      } finally {
        // Close the loading indicator if it was opened
        if (showLoading && loadingInstance) {
          loadingInstance.close();
        }
      }
    },
    setPreviewVersion(version) {
      this.SET_PREVIEW_VERSION(version);
    },
    async updateVersion({ taxonomy, slotId, language, versionId, versionName, publishedId, draftOrPublished }) {
      const dto = {
        slotId,
        taxonomy: `${taxonomy}`,
        language,
        versionId,
        status: draftOrPublished
      };

      if (versionName !== undefined) {
        dto.versionName = versionName;
      }

      if (publishedId) {
        dto.publishedId = publishedId;
      }

      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/updateVersion`, dto);
      const wasDbUpdateSuccessful = response.status === 200 ? true : false;

      //Update versions in state to match DB
      if (wasDbUpdateSuccessful) {
        this.updateVersions({ versionId, versionName, publishedId });
      }
    },

    updateVersions({ versionId, versionName, publishedId }) {
      const newVersionsState = this.versions.map((version) => {
        if (version.id === versionId) {
          if (publishedId) {
            version.publishedId = publishedId;
          }
          if (versionName) {
            version.versionName = versionName;
          }
        }
        return version;
      });
      this.SET_VERSIONS(newVersionsState);
    },
    setRestoredPopupDate(restoredPopupDate) {
      this.restoredPopupDate = restoredPopupDate;
    },

    setRestoredPopupName(restoredPopupName) {
      this.restoredPopupName = restoredPopupName;
    },
    setRestoredFromDateTime: function (restoredFromDateTime) {
      this.restoredFromDateTime = restoredFromDateTime;
    },
    getRestoredFromDateTime: function () {
      return this.restoredFromDateTime;
    },

    setRestoreVersionId(restoreVersionId) {
      this.SET_RESTORE_VERSION_ID(restoreVersionId);
    },

    async loadDraft({ draft }) {
      const appStore = useAppStore();
      ElLoading.service();

      const { slot, taxonomy } = appStore.current;
      const { language } = appStore;

      const draftPreview = (
        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getVersion`, {
          slotId: slot.id,
          taxonomy: `${taxonomy}_flows`,
          language,
          id: draft.id,
          draftOrPublished: 'draft'
        })
      ).data;
      await appStore.RESTORE_FLOW({ uxState: [draftPreview] });
    },
    async restoreVersion(sourceVersionId) {
      const appStore = useAppStore();
      const vcmStore = useVcmStore();
      //NOTE: When restoring a draft or published version, a new draft version is created in both cases.
      //sourceVersionId is the firebase document (eg 2uBE4jiDjj5d7J613Ay1) id of the version that we restored from, not the versionId (eg 8b5ee09f-749d-49da-9a8e-9118cce0f1c6)
      ElLoading.service();

      let status;

      if (this.showPublished) {
        status = 'published';
      } else {
        status = 'draft';
      }

      this.SET_RESTORE_VERSION_ID(undefined);

      const sourceVersion = this.versions.find((version) => version.id === sourceVersionId);

      if (!sourceVersion) {
        console.log('restoreVersion version not found');
        return;
      }

      this.setRestoredPopupDate(sourceVersion.createdAt);
      this.setRestoredFromDateTime(sourceVersion.createdAt);
      if (sourceVersion.versionName) {
        this.setRestoredPopupName(sourceVersion.versionName);
      }

      const { slot, taxonomy } = appStore.current;
      const { language } = appStore;

      //This is the uxState of the version we have restored from
      const sourceVersionsUxState = (
        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getVersion`, {
          slotId: slot.id,
          taxonomy: `${taxonomy}_flows`,
          language,
          id: sourceVersion.id,
          draftOrPublished: status
        })
      ).data;
      this.SET_PREVIEW_VERSION(undefined);
      //Use the uxState of the version we have restored from
      appStore.RESTORE_FLOW({ uxState: [sourceVersionsUxState] });

      //Create new draft and get the returned versionId
      const newDraftVersionId = await new Promise((callback) => {
        vcmStore.createVersion({ callback });
      });

      //Temporary versionId, loadLatestDraft will overwrite with full object
      this.SET_CURRENT_VERSION({ versionId: newDraftVersionId });
      await vcmStore.loadLatestDraft({});
    },
    SET_SHOW_PUBLISHED(showPublished) {
      this.showPublished = showPublished;
    },
    SET_VERSIONS(versions) {
      this.versions = versions;
    },
    SET_LATEST_PUBLISHED_VERSION(draft) {
      this.latestPublishedVersion = draft;
    },
    SET_CURRENT_VERSION(draft) {
      this.currentVersion = draft;
    },
    SET_PREVIEW_VERSION(draft) {
      this.previewVersion = draft;
    },
    SET_RESTORE_VERSION_ID(restoreVersionId) {
      this.restoreVersionId = restoreVersionId;
    }
  },
  getters: {
    getVersions: (state) => {
      return state.versions;
    },
    getCurrentVersion: (state) => {
      return state.currentVersion;
    },
    getPreviewVersion: (state) => {
      return state.previewVersion;
    },
    getRestoredPopupDate: (state) => {
      return state.restoredPopupDate;
    },
    getRestoredPopupName: (state) => {
      return state.restoredPopupName;
    },
    getRestoreVersionId: (state) => {
      return state.restoreVersionId;
    },
    getShowPublished: (state) => {
      return state.showPublished;
    },
    getLatestPublishedVersion: (state) => {
      return state.latestPublishedVersion;
    }
  }
});
