<template>
  <div class="reference-group" @mouseover="mouseover" @mouseleave="mouseleave">
    <div class="reference-item" :class="{ 'reference-item-hovering': isHovering }" v-html="arrayItem"></div>
    <div v-if="isHovering" class="wrapper">
      <button class="copy-button" @click="copyToClipboard">Copy</button>
      <button class="reference-edit" @click="editEvent(index)">
        <ConversationBuilderIcon class="edit-button" name="edit" width="12" height="12" />
      </button>
      <button class="reference-exit" @click="deleteEvent(index)">
        <ConversationBuilderIcon class="close-button" name="x" />
      </button>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      isHovering: false
    };
  },
  props: {
    arrayItem: {},
    index: {}
  },
  methods: {
    mouseover() {
      this.isHovering = true;
    },
    mouseleave() {
      this.isHovering = false;
    },
    deleteEvent(index) {
      this.$emit('delete', index);
    },
    editEvent(index) {
      this.$emit('edit', index);
    },
    copyToClipboard() {
      this.$clipboard(this.arrayItem);
      this.$emit('copyToClipboard', this.arrayItem);
    }
  }
};
</script>

<style lang="scss" scoped>
.reference-group {
  align-items: center;
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  justify-content: space-between;
  height: auto;
  min-height: 6vh;

  .reference-item {
    padding: 1rem 1.25rem;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    cursor: default;
  }

  .reference-item-hovering {
    color: #1996f0;
  }

  .reference-exit {
    width: 1.25rem;
    height: 1.25rem;
    margin: 1.188rem 0.625rem 1.125rem 0.625rem;
    padding: 0.125rem;
    background-color: #edf0f4;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
  }

  .reference-edit {
    width: 1.25rem;
    height: 1.25rem;
    margin: 1.125rem 0rem 1.188rem 0.625rem;
    padding: 0.269rem 0.281rem 0.331rem 0.3rem;
    background-color: #edf0f4;
    border-radius: 50%;
    cursor: pointer;
  }
}

.wrapper {
  margin-top: -0.6vh;
  display: flex;
  align-items: center;
}
.copy-button {
  background: #fff;
  border-radius: 3px;
  border: #bebebe 1px solid;
  width: 70px;
  align-items: center;
  margin: 2px 0px 2px 0px;
  font-size: 12px;
  height: 25px;
}

.edit-button {
  width: 10px;
  height: 10px;
  display: flex;
}

.close-button {
  width: 15px;
  height: 15px;
  display: flex;
}

.copy-button:hover {
  background: white;
  border: #2698ed 1px solid;
}

.copy-button:active {
  background: #2698ed;
  color: white;
}
</style>
