<template>
  <div @mouseover="displayComments === true" @mouseout="displayComments === false" :class="['comment__component__handle', `comment__handle-${position}-connected`, 'nodrag']">
    <div v-if="comment" class="connector-point" @mouseover="displayComments = true" @mouseout="displayComments = false">
      <div v-if="pendingComment" class="connector-point">
        <div @click="goToComment(pinned)" class="number" v-for="(pinned, index) in comment" :key="index" :class="{ hoverComments: displayComments }">
          <span>{{ pinned.pinnedCommentIndex }}</span>
        </div>
      </div>
      <div class="number" v-if="!pendingComment" :class="{ hoverComments: displayComments }">
        <span>{{ comment.pinnedCommentIndex }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      displayComments: false,
      loadCommentTimeout: null
    };
  },
  name: 'CommentPinpointComponent',
  props: {
    comment: {},
    position: {
      type: String,
      required: false,
      default: 'right'
    }
  },
  methods: {
    goToComment({ id }) {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'comments' });
      this.loadCommentTimeout = setTimeout(() => {
        this.commentsStore.goToComment({ id });
      }, 1000);
    }
  },
  beforeUnmount() {
    clearTimeout(this.loadCommentTimeout);
  },
  computed: {
    pendingComment() {
      if (Array.isArray(this.comment)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hoverComments {
  margin-right: -5px !important;
}
.connector-point {
  cursor: default;
  display: flex;
  flex-direction: row;
  direction: rtl;
}
.number {
  z-index: 999 !important ;
  width: 20px;
  height: 20px;
  background-color: #fd5422;
  border-radius: 10px;
  box-shadow: 1.5px 0.5px rgba(0, 0, 0, 0.3);
  white-space: normal;
  margin-right: -15px;
  font-size: 12px;
  line-height: 2;
  color: #fff;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number:first-child {
  box-shadow: none;
}

.comment__component__handle {
  background: transparent;
  border: none;
  height: 0px;
  position: absolute;
  width: 0px;
  height: 40px;
  margin-top: -10px;
  right: 21px;
  display: flex;
  cursor: pointer;
}

* {
  font-family: 'Roboto', sans-serif !important;
}
</style>
