<template>
  <div>
    <div class="sidebar components linkToContainer">
      <div class="row editor-header">
        <div class="col-11">
          <span class="sidebar type">Link to Container</span>
          <ConversationBuilderIcon v-if="false" name="info" :styleOnIcon="{ marginTop: '-3px' }" />
        </div>
      </div>
      <div class="linkToContainer-dropdown">
        <v-select class="containers-dropdown" :clearable="false" placeholder="Container" v-model="selectedContainer" :options="listOfContainers" :reduce="(container) => container" label="title" filterable autocomplete>
          <template v-if="selectedContainer">
            <span>{{ selectedContainer }}</span>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'LinkToContainerComponentEdit',
  props: {
    componentId: {
      type: String,
      default: ''
    },
    parentContainerId: {
      type: String,
      default: ''
    },
    linkToContainer: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedContainer: {}
    };
  },
  mounted() {
    this.selectedContainer = this.appStore.containers[this.linkToContainer];
  },
  methods: {
    setSelectedContainer(container) {
      if (container) {
        this.selectedContainer = container;
        const linkToContainer = container.id;
        this.appStore.updateComponent({
          id: this.componentId,
          linkToContainer
        });
      }
    }
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    listOfContainers() {
      const containerIdsToExclude = ['start-id', this.parentContainerId];
      const filteredContainersArray = this.appStore.getContainerListFromContainers.filter((container) => !containerIdsToExclude.includes(container.id));
      return filteredContainersArray;
    }
  },
  watch: {
    selectedContainer(current) {
      this.setSelectedContainer(current);
    }
  }
};
</script>

<style scoped>
.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}
.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 5px 15px;
  height: max-content;
}

.sidebar.components.linkToContainer {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
  font-weight: 700;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.linkToContainer-dropdown {
  height: 50px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
