<template>
  <div>
    <div class="sidebar components" @click="onCloseClickHandler">
      <div class="form-container row">
        <div class="col-sm-2">
          <span class="sidebar form-type-title" @click="onCloseClickHandler"
            ><strong>Pages</strong></span
          >
        </div>
        <div class="options col-sm-8" @click="onCloseClickHandler">
          <span
            v-for="(page, idx) in localFormdata"
            :key="idx"
            :class="{ numTab: true, selected: idx === currentPage }"
            @click="onBubbleClickHandler(idx)"
            >{{ idx + 1 }}
          </span>
          <span @click="addPage" class="addNumTab">
            <strong>+</strong>
          </span>
        </div>
      </div>
    </div>
    <div
      v-for="(currEleList, indx) in localFormdata"
      :key="indx"
      class="sidebar components"
    >
      <div @click="onCloseClickHandler" class="col-11">
        <Collapsible
          v-bind="$props"
          @formDataChange="formDataChangeHandler"
          @deleteFormField="deleteFormFieldHandler"
          @removeFormCard="removeCard"
          :formData="Object.keys(currEleList)"
          :data="currEleList"
          :pageNumber="indx.toString()"
        />
      </div>
      <div class="fields">
        <button
          @click="toggleAddFieldHandler(indx)"
          type="button"
          :class="{
            customButton: true,
            btnActive: showFieldsNav && indx === currentPage,
          }"
        >
          <img
            src="../../../../../../assets/icons/add_field.svg"
            alt="icon not found"
          />
          Add Field
        </button>
        <nav class="nav" v-if="showFieldsNav && currentPage === indx">
          <img
            @click="onTextFieldClickHandler"
            title="Text Field"
            class="image"
            src="../../../../../../assets/icons/textField.svg"
            alt="no image found"
          />
          <img
            @click="tempAlert"
            class="image"
            title="Calendar"
            src="../../../../../../assets/icons/calendar_unselected.svg"
            alt="no image found"
          />
          <!-- <img @click="onCustTextFieldClickHandler(indx)" title="Customized Text Field" class="image" src="../../../../../../assets/icons/textField.svg" alt="no image found" /> -->
          <img
            @click="tempAlert"
            class="image"
            title="Radio Button"
            src="../../../../../../assets/icons/radio.svg"
            alt="no image found"
          />
          <img
            @click="tempAlert"
            class="image"
            title="Checkbox"
            src="../../../../../../assets/icons/checkbox_not_selected.svg"
            alt="no image found"
          />
          <img
            @click="tempAlert"
            class="image"
            title="Dropdown"
            src="../../../../../../assets/icons/dropdown_down_unselected.svg"
            alt="no image found"
          />
          <img
            @click="onEmailFieldClickHandler"
            class="image"
            title="Email"
            src="../../../../../../assets/icons/email.svg"
            alt="no image found"
          />
          <img
            @click="tempAlert"
            class="image"
            title="CTI"
            src="../../../../../../assets/icons/CTI_unselected.svg"
            alt="no image found"
          />
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import Collapsible from '../Collapse/FormCollapse.vue';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import essentials from '../Mixins/essentials';
import _ from 'lodash';

export default {
  name: 'FormEdit',
  mixins: [essentials],
  methods: {
    toggleAddFieldHandler(indx) {
      this.showFieldsNav = !this.showFieldsNav;
      this.currentPage = indx;
    },
    onTextFieldClickHandler() {
      const tempTextFieldObj = {
        fieldLabel: '',
        placeholder: '',
        required: false,
        charLimit: 25,
        encrypt: false,
        PII: false,
        regex: '',
        varName: '',
        key: null,
      };
      const index = Object.keys(this.elementList).length;
      this.elementList['textField ' + this.currentPage + '' + index] =
        tempTextFieldObj;
      this.showFieldsNav = false;
    },
    onEmailFieldClickHandler() {
      const tempEmailFieldObj = {
        fieldLabel: '',
        placeholder: '',
        required: false,
        encrypt: false,
        varName: '',
        key: null,
      };
      const index = Object.keys(this.elementList).length;
      this.elementList['email ' + this.currentPage + '' + index] =
        tempEmailFieldObj;
      this.showFieldsNav = false;
    },
    onCustTextFieldClickHandler(indx) {
      this.localFormdata[indx].push('customtextField');
      this.showFieldsNav = false;
    },
    onCloseClickHandler() {
      this.showFieldsNav = false;
    },
    addPage() {
      this.elementList = new Object();
      this.localFormdata.push(this.elementList);
      this.currentPage += 1;
    },
    onBubbleClickHandler(idx) {
      this.currentPage = idx;
    },
    toggleCloseIcon(idx) {
      this.currentPageInHover = idx;
      this.showCloseIcon = !this.showCloseIcon;
    },
    removeCard(idx) {
      const remIdx = parseInt(idx);
      this.localFormdata.splice(remIdx, 1);
      this.currentPage = remIdx - 1;
    },
    tempAlert() {
      alert('This form component needs to be developed.');
    },
    formDataChangeHandler({ type, value, key, dataType }) {
      try {
        const tempEleList = this.localFormdata[this.currentPage];
        tempEleList[dataType + ' ' + key][type] = value;
      } catch (e) {
        return;
      }
    },
    deleteFormFieldHandler({ index, key }) {
      const tempEleList = this.localFormdata[index];
      delete tempEleList[key];
      this.$forceUpdate();
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      componentKey: 0,
      elementList: new Object(),
      showFieldsNav: false,
      localFormdata: [],
      currentPage: 0,
      showCloseIcon: false,
      currentPageInHover: 0,
      currentFormContainer: null,
    };
  },
  beforeMount() {
    this.currentFormContainer = _.cloneDeep(
      this.appStore.getContainer(this.appStore.activeContainer)
    );
    try {
      if (
        this.currentFormContainer.type === 'form' &&
        this.currentFormContainer.properties.formData.length !== 0
      ) {
        this.localFormdata = _.cloneDeep(
          this.currentFormContainer.properties.formData
        );
        this.elementList = this.localFormdata[0];
        return;
      }

      this.localFormdata.push(this.elementList);
    } catch (e) {
      this.localFormdata.push(this.elementList);
      return;
    }
  },
  beforeUpdate() {
    const tempContainer = _.cloneDeep(
      this.appStore.getContainer(this.appStore.activeContainer)
    );
    if (tempContainer.id !== this.currentFormContainer.id) {
      this.localFormdata = tempContainer.properties.formData;
      this.currentFormContainer = tempContainer;
      if (tempContainer.properties.formData.length === 0) {
        this.addPage();
      }
      return;
    }
    this.elementList = this.localFormdata[this.currentPage];
    try {
      this.appStore.updateFormContainer({
        id: this.currentFormContainer.id,
        currentFormData: _.cloneDeep(this.localFormdata),
      });
    } catch (e) {
      console.log('Container not created in Pinia yet.');
    }
  },
  updated() {
    try {
      this.appStore.updateFormContainer({
        id: this.currentFormContainer.id,
        currentFormData: _.cloneDeep(this.localFormdata),
      });
    } catch (e) {
      console.log('Container not created in Pinia yet.');
    }
  },
  components: {
    Collapsible,
  },
};
</script>

<style scoped>
.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 25px 15px;
  height: max-content;
}

.sidebar.components.flow {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}

.nav {
  height: 40px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  border-radius: 15px;
  width: 70%;
  display: flex;
  left: 1;
  margin-top: 20px;
  animation: fadeInAnimation ease-in 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.customButton {
  width: 121px;
  height: 30px;
  margin: 20px 273px 0 20px;
  padding: 3px 14.8px 3px 3px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  background-color: #fff;
}

.btnActive {
  background-color: #1996f0;
}

.image {
  margin-left: 5px;
  margin-right: 5px;
}

.image:hover {
  filter: invert(48%) sepia(32%) saturate(3743%) hue-rotate(181deg)
    brightness(98%) contrast(92%);
  cursor: pointer;
}

.image-close {
  align-content: left;
  position: relative;
  margin-left: 40px;
  filter: invert(93%) sepia(25%) saturate(123%) hue-rotate(179deg)
    brightness(97%) contrast(92%);
}
.image-close:hover {
  filter: invert(53%) sepia(10%) saturate(3250%) hue-rotate(316deg)
    brightness(84%) contrast(110%);
  cursor: pointer;
}

.fields {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.form-type-title {
  position: relative;
  top: 4px;
}

.form-container {
  align-items: center;
}

.numTab {
  padding: 6px 10px 6px 10px;
  border: none;
  border-radius: 5px;
  background-color: #edf0f4;
  margin-right: 10%;
  font-size: 12px;
  margin-bottom: 5%;
}

.addNumTab {
  padding: 4px 11px 3px 11px;
  background-color: white;
  border-radius: 80%;
  color: #1996f0;
  font-size: 15px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.selected {
  background-color: #1996f0;
  color: white;
  border-color: #1996f0;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}
</style>
