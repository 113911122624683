<template>
  <div>
    <div v-show="drawer === 'nluReview'" class="overlay"></div>
    <DrawerMain id="nluEngineDrawer" v-show="drawer === 'nluReview'">
      <template v-slot:header>
        <PageHeader title="Train cH NLU Engine Review" subtitle="NLU Training / Train cH NLU Engine Review" />
      </template>
      <template v-slot:body>
        <div v-if="currentTaxonomy" class="pageLayout">
          <div class="nluTaxonomy">
            Training completed and the Inference URLs KVM is updated for
            {{ appStore.currentTaxonomy }}
          </div>
          <div class="actionsOptions" v-if="!readOnly">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import { mapState } from 'pinia';

export default {
  name: 'NluReviewDrawer',
  components: {
    DrawerMain
  },
  setup() {
    const appStore = useAppStore();
    return { appStore };
  },
  computed: {
    ...mapState(useAppStore, ['drawer', 'currentTaxonomy'])
  },
  data() {
    return {};
  }
};
</script>

<style>
#target-df-agent-input {
  background: white;
  border: solid 1px #8e9ba7;
  border-radius: 4px;
  height: 40px;
  padding: 5px;
  color: #35495e;
}
#target-df-agent-input::placeholder {
  color: rgb(117, 117, 117);
}
#validate-entities-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#validate-entities-wrapper > p {
  margin: 0 0 0 10px;
  color: lightcoral;
}
#validate-entities-wrapper > p.validate-result-icons {
  font-size: 20px;
}
#validate-result-text.success {
  color: rgb(2, 154, 2);
}
#validate-result-text.fail {
  color: red;
}
.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
#target-df-agent-input.disabled {
  pointer-events: none;
  opacity: 0.6;
  color: rgb(180, 180, 180);
}
#target-df-agent-input.disabled::placeholder {
  color: black;
}
.nluTaxonomy {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  height: 100vh;
}
</style>
