import { defineStore } from 'pinia';
import firebase from 'firebase/compat/app';
import { RPC_CONNECTED, RPC_DISCONNECTED, RPC_SOMEONE_ELSE_EDITING } from '@/helpers/rpc/constants';
import { collabPlugin } from '@/plugins/collabPlugin';

const getOtherEditorErrorMessage = (editor) => ({
  level: 'warning',
  icon: 'warning',
  message: `
<div>
  <div style="white-space: nowrap; min-width: 0;"><a href="mailto:${editor}">${editor}</a> is currently editing this flow.</div>
  <div>Please wait for them to finish.</div>
</div>
`
});

const getDisconnectedErrorMessage = () => ({
  level: 'error',
  icon: 'disconnected',
  message: `
<div>
<div style="white-space: nowrap; min-width: 0;">You have been disconnected from the server. Please refresh the page to reconnect.</div>
</div>
`
});

const getEditingSuccessMessage = () => ({
  level: 'success',
  icon: 'success',
  message: `
<div>
<div style="white-space: nowrap; min-width: 0;">You are now editing.</div>
</div>
`
});

export const useCollabStore = defineStore({
  id: 'collab',
  state: () => ({
    collabMode: 'reader',
    collaborators: {},
    collabMessaqe: {}
  }),
  actions: {
    async onErrorOrConnectionChange(message) {
      const { editor = 'N/A' } = this.collaborators;
      switch (message) {
        case RPC_CONNECTED:
          this.collabMessaqe = {};
          break;
        case RPC_SOMEONE_ELSE_EDITING:
          this.SET_COLLAB_MESSAGE(getOtherEditorErrorMessage(editor));
          setTimeout(() => (this.collabMessage = {}), 5000);
          break;
        case RPC_DISCONNECTED:
          this.SET_COLLAB_MESSAGE(getDisconnectedErrorMessage());
          this.collabMode = 'reader-disabled';
          break;
      }
    },
    async updateFlowUsers(flowUsers) {
      const current = firebase.auth().currentUser?.email;
      if (flowUsers.editor !== current && flowUsers.readers.includes(current) === false) {
        flowUsers.readers.push(current);
      }
      this.SET_COLLABORATORS({ ...flowUsers, current });
    },
    SET_COLLABORATORS(collaborators) {
      const { editor: previousEditor } = this.collaborators;
      const { editor } = collaborators;

      this.collaborators = collaborators;

      let collabMode = 'reader';
      if (editor === collaborators.current) {
        collabMode = 'editor';
        if (previousEditor !== editor) {
          this.collabMessaqe = getEditingSuccessMessage();
          setTimeout(() => (this.collabMessaqe = {}), 4000);
        }
      } else if (editor !== null) {
        this.collabMessaqe = {};
        collabMode = 'reader-disabled';
      } else {
        this.collabMessaqe = {};
      }

      this.collabMode = collabMode;
      console.log('[COL]', collabMode, JSON.stringify(collaborators));
    },
    SET_COLLAB_MESSAGE(collabMessaqe) {
      this.collabMessaqe = collabMessaqe;
    },

    setInfo(info) {
      this.info = info;
    },

    setActivityStatus(status) {
      this.activityStatus = status;
    },

    setError(message) {
      this.errorMessage = message;
    },

    startCollabService() {
      const collabPluginStore = collabPlugin();
      if (collabPluginStore) {
        collabPluginStore.start();
      }
    },

    stopCollabService() {
      const collabPluginStore = collabPlugin();
      if (collabPluginStore) {
        collabPluginStore.stop();
      }
    },

    setCollabMode(collabMode) {
      const collabPluginStore = collabPlugin();
      if (collabPluginStore) {
        collabPluginStore.setCollabMode(collabMode);
      }
    },

    collabServiceActivity() {
      const collabPluginStore = collabPlugin();
      if (collabPluginStore) {
        collabPluginStore.activity();
      }
    }
  },
  getters: {
    getCollaborators: (state) => {
      return state.collaborators;
    },
    getCollabMode: (state) => {
      return state.collabMode;
    },
    isFlowEditor: (state) => {
      return state.collabMode === 'editor';
    },
    getCollabMessaqe: (state) => {
      return state.collabMessaqe;
    }
  }
});
