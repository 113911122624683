<template>
  <div class="optionsClass">
    <span>Add Extra Training Phrases </span>
    <vue3-tags-input :autocomplete-items="filteredItems" add-from-paste placeholder="Add Training Phrases" :allow-edit-tags="true" :avoid-adding-duplicates="true" v-model="tag" @tags-changed="(newTags) => (MorePhrases = newTags)" :tags="computedPhrases" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { defineComponent } from 'vue';
import Vue3TagsInput from 'vue3-tags-input';
import axios from 'axios';

export default defineComponent({
  name: 'AddPhrases',
  components: {
    Vue3TagsInput
  },
  props: {
    responses: {},
    collection: {
      required: true,
      type: String
    },
    phrases: {
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      autocompleteItems: [],
      newNotes: this.notes,
      newFunctions: this.functions,
      MorePhrases: this.phrases,
      tag: '',
      inputTag: '',
      outputTag: '',
      sanitized: []
    };
  },
  mounted() {
    this.getPhonetics();
    this.MorePhrases = this.phrases;
  },
  computed: {
    computedPhrases() {
      //May need to add more to this function to sanitize v2.2 extras;
      /// Added an extra conditional, will need to test;
      for (let i of this.MorePhrases) {
        if (typeof i === 'object') {
          i.text = i.text.replace(/<[^>]*>?/gm, '').trim();
          if (this.sanitized.includes(i.text.toLowerCase())) {
            i.classes = 'phonetic';
          } else {
            const object = { text: i };
            i = object;
            if (this.sanitized.includes(i.text.toLowerCase())) {
              i.classes = 'phonetic';
            }
          }
        }
      }
      return this.MorePhrases;
    },
    filteredItems() {
      return this.autocompleteItems.filter((i) => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    }
  },
  watch: {
    MorePhrases() {
      this.$emit('save-phrases', { data: this.MorePhrases });
    }
  },
  methods: {
    async getPhonetics() {
      try {
        const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/phonetics/get`, {
          collection: this.collection,
          idToken: this.appStore.getClaims.token
        });
        if (response.status === 200) {
          this.phonetics = response.data[0].phonetics;
          this.sanitized = this.phonetics.map((x) => {
            return x.name.toLowerCase();
          });
          this.autocompleteItems = this.phonetics.map((x) => {
            return { text: x.name };
          });
        }
      } catch (error) {
        if (error.response) {
          this.phonetics = [];
        }
      }
    }
  }
});
</script>

<style>
.v--modal-box.modalwrapper {
  border-radius: 10px;
  background: white;
}

.phonetic {
  background-color: lightseagreen !important;
}
</style>

<style lang="css" scoped>
.optionsClass {
  padding: 10px;
}

.save_button {
  color: white;
  padding: 4px 10px;
  background-color: #fb5422;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.7;
}
</style>
