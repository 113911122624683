<template>
  <div>
    <div class="row linkToContainer">
      <div class="col-12">
        <MessageEditor placeHolder="Link To Container" :readOnly="readOnly" :id="id" :type="`linkToContainer`" :modelValue="getLinkToContainerName" @update:modelValue="(val) => (getLinkToContainerName = val.trim())" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
import MessageEditor from '../../../Editors/3.0/MessageEditor.vue';
export default {
  name: 'LinkToContainerComponent',
  mixins: [essentials],
  components: {
    MessageEditor
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      appStore: useAppStore()
    };
  },
  computed: {
    getLinkToContainerName() {
      const linkToContainerId = this.response?.linkToContainer;
      const containerList = this.appStore.getContainerListFromContainers;
      const selectedContainer = containerList.find((x) => x.id === linkToContainerId);

      if (linkToContainerId && selectedContainer) {
        return selectedContainer.title;
      }
      return '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import './LinkToContainerComponent.scss';
</style>
