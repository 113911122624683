<template>
  <path
    :marker-end="pathStyle.markerEnd"
    :id="`line-${source}-${positions[0]}-${optionIndex}`"
    class="edge__connection-path"
    :d="getPathPoints"
    :style="pathStyle"
  />
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'EdgesCurvedDecision',
  props: {
    optionIndex: {},
    source: {},
    target: {},
    curved: {},
    dotted: {},
    status: {
      default: '',
    },
    positions: {},
    id: {},
    start: {},
    end: {},
    radius: {
      default: 5,
    },
    pathStyle: {},
  },
  computed: {
    points() {
      const isLeft = this.positions[0] === 'left';
      return [
        this.start,
        [
          isLeft ? this.leadLeft(this.start[0]) : this.leadRight(this.start[0]),
          this.start[1],
        ],
        [this.end[0], this.leadTop(this.end[1])],
        this.end,
      ];

      //   return [this.start, [isLeft ? this.leadLeft(this.start[0]) : this.leadRight(this.start[0]), this.start[1]], [this.start[0] + this.radius, this.end[1]], [this.start[0], this.end[1] - this.radius], [this.end[0], this.leadTop(this.end[1])], this.end];
    },
    getPathPoints() {
      const values = this.points.find(([elem]) => elem);
      if (!values) {
        return false;
      }

      return d3
        .line()
        .x((d) => d[0])
        .y((d) => d[1])
        .curve(d3.curveBundle.beta(1))(this.points);
    },
  },
  methods: {
    leadLeft(point) {
      return point - 100;
    },
    leadRight(point) {
      return point + 100;
    },
    leadTop(point) {
      return point - 100;
    },
    getCenter(
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition
    ) {
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      const LeftOrRight = [Position.Left, Position.Right];

      const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition);
      const targetIsLeftOrRight = LeftOrRight.includes(targetPosition);
      // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
      // a mixed edge is when one the source is on the left and the target is on the top for example.
      const mixedEdge =
        (sourceIsLeftOrRight && !targetIsLeftOrRight) ||
        (targetIsLeftOrRight && !sourceIsLeftOrRight);
      if (mixedEdge) {
        const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0;
        const centerX =
          sourceX > targetX ? sourceX - xOffset : sourceX + xOffset;

        const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY);
        const centerY =
          sourceY < targetY ? sourceY + yOffset : sourceY - yOffset;

        return [centerX, centerY, xOffset, yOffset];
      }
      const xOffset = Math.abs(targetX - sourceX) / 2;
      const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;

      const yOffset = Math.abs(targetY - sourceY) / 2;
      const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;
      return [centerX, centerY, xOffset, yOffset];
    },
  },
};
</script>

<style lang="scss" scoped>
.edge__connection {
  position: relative;
}

.edge__connection:hover {
  cursor: pointer;

  .edge__connection-path:hover {
    stroke: #2698ed;
    stroke-width: 2;
  }
}
</style>
