<template>
  <div class="overflow-auto entityEdit" :style="customStyles">
    <br />
    <el-tag color="black" style="color: white; margin-left: 20px; font-size: 30px" size="large">@{{ this.entity_info.name.toUpperCase() }}</el-tag>
    <span style="float: right; margin-right: 25px; font-size: 12px; color: grey" v-if="this.last_addition_timestamp">Last updated: {{ this.last_addition_timestamp }}</span>
    <br /><br />
    <el-button @click="closeModal" size="default" type="info" style="float: right; margin-right: 10px">CANCEL</el-button>
    <el-button @click="SendtoDB" size="default" type="primary" style="float: right; margin-right: 10px">SAVE</el-button>
    <el-button @click="addEntity" size="default" type="info" style="float: right; margin-right: 10px">ADD</el-button>

    <br />
    <el-table max-height="400px" stripe :data="filteredEntities" style="width: 100%; padding: 10px">
      <el-table-column align="left" label="Value" prop="name" width="200">
        <template #default="scope">
          <el-input @keyup.enter="addEntity" placeholder="Enter Value" size="small" v-model="scope.row.name"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="synonyms" label="Synonyms">
        <template #default="scope">
          <!-- <p>lastEdited: {{ scope.row.lastEdited}}</p> -->
          <el-tag :key="i" :color="entity_info.highlightColor" v-for="i in scope.row.synonyms" closable :disable-transitions="false" @close="handleClose(i, scope.$index)"> {{ i }}</el-tag>
          <el-input class="input-new-tag" v-model="scope.row.inputValue" size="small" placeholder="Synonym" @keyup.enter="handleInputConfirm(scope.row.inputValue, scope.$index, scope)"> </el-input>

          <!-- <el-button @click="showInput(scope.$index)" class="button-new-tag" size="small">+ New Tag</el-button> -->
        </template>
        <!-- </el-table-column></el-table></div></template> -->
      </el-table-column>

      <el-table-column width="100px" align="left">
        <template #default="scope">
          <el-button size="small" @click="deleteEntry(scope.$index)">
            <i class="fas fa-trash-alt"></i>
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { db } from '@/main';
import firebase from 'firebase/compat/app';

export default {
  name: 'EntityEdit',
  props: {
    collection: {
      type: String,
      required: true
    },

    entity_info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      entities: [],
      entities_id: '',
      search: '',
      wheels: [],
      inputVisible: false,
      last_addition_timestamp: ''
    };
  },
  watch: {
    entities: function () {}
  },
  mounted() {
    this.currentUser();
    this.bindIntentsData();
    this.bindEntityValues();
  },
  computed: {
    customStyles() {
      return {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxWidth}`,
        'max-height': `${this.setModalWidthHeight().maxHeight}`,
        overflow: 'auto',
        position: `relative`
      };
    },
    filteredEntities() {
      return this.entities.filter((data) => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '90%';
      let width = '90%';
      let height = '600px';

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    },
    bindIntentsData() {
      const ref = db.collection('Taxonomies').doc(process.env.VUE_APP_TAXONOMY_COLLECTION_NAME || 'collections');
      ref
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.wheels = doc.data().collections;
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.error('Error: ', error.message, error);
        });
    },
    bindEntityValues() {
      const ref = db.collection(this.collection).doc('EntityDoc').collection('EntityColl').doc(this.entity_info.id).collection('values').orderBy('timestamp', 'desc').limit(1);

      ref.onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            db.collection(this.collection)
              .doc('EntityDoc')
              .collection('EntityColl')
              .doc(this.entity_info.id)
              .collection('values')
              .add({
                entities: [{ name: 'Enter Value', synonyms: [] }],
                user: this.email,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
              })
              .then((docRef) => {
                this.entities_id = docRef.id;
              });
          }

          snapshot.forEach((doc) => {
            const data = doc.data();

            this.entities = data.entities;
            this.entities_id = doc.id;
            this.last_addition_timestamp = doc.data().timestamp?.toDate()?.toLocaleString();
          });
        },
        (err) => {
          console.error('Error: ', err.message, err);
        }
      );
    },
    deleteEntry(index) {
      this.entities.splice(index, 1);
    },
    handleClose(tag, index) {
      this.entities[index].synonyms.splice(this.entities[index].synonyms.indexOf(tag), 1);
    },
    handleInputConfirm(new_synonym, index) {
      // Trim the input value to remove whitespace
      const trimmedSynonym = new_synonym.trim();

      // Check if the trimmed input is empty to prevent adding empty strings
      if (trimmedSynonym === '') {
        this.$message({
          message: 'Synonym cannot be empty',
          showClose: 'true',
          time: 1000
        });
        return;
      }

      // Check if the synonym already exists to prevent duplicates
      if (this.entities[index].synonyms.includes(trimmedSynonym)) {
        this.$message({
          message: 'Synonym has already been added',
          showClose: 'true',
          time: 1000
        });
        return;
      }

      // Add the trimmed input to the synonyms array
      this.entities[index].synonyms.push(trimmedSynonym);
      // Clear the input field
      this.entities[index].inputValue = '';
    },

    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    SendtoDB() {
      let counter = 0;

      this.entities.forEach((item) => {
        if (/^[a-zA-Z0-9,_-\s]+[^\s]$/.test(item.name) === false) {
          this.$vs.notify({
            title: 'Unable to save.',
            text: 'Inappropriate character/space in Entity Value',
            color: 'danger',
            time: 3000
          });
          counter++;
        }
      });

      if (counter < 1) {
        db.collection(this.collection)
          .doc('EntityDoc')
          .collection('EntityColl')
          .doc(this.entity_info.id)
          .collection('values')
          .add({
            entities: this.entities,
            user: this.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            this.$vs.notify({
              title: 'Entities saved' + '    ' + "     <i class='fas fa-save'></i>",
              text: 'Successfully saved to database',
              color: 'dark',
              time: 3000
            });
          });
      }
    },
    removeEntity() {
      this.entities.pop();
    },
    addEntity() {
      this.entities.push({
        name: '',
        synonyms: [],
        inputValue: ''
      });
    },
    addEntityName(index) {
      this.entities[index].push({
        name: 'Placeholder',
        value: 'Placeholder',
        synonyms: ['Placeholder']
      });
    },
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.entityEdit {
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  overflow: auto;
  height: 100%;
}
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-table {
  overflow: hidden;
  font-size: 12px;
}

.el-table td,
.el-table th {
  padding: 0px;
}

.el-table__empty-text {
  display: none;
}

.el-tag {
  color: black;
  font-size: 12px;
  /* font-weight: bold; */
}

.entity.table {
  /* background-color: rgb(0, 123, 255); */
  width: 73%;
  height: 96%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  /* padding: 30px; */
}

.vs-table-text {
  color: dodgerblue;
}

.vs-table-text {
  font-size: 12px;
}

.vs-table--content {
  width: 100%;
  overflow: hidden scroll;
  height: 91%;
}

.vs-con-table {
  background: white;
}

.vs-component.vs-con-table.vs-table-primary {
  width: 99vh;
  padding: 20px;
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  /* background: mediumseagreen; */
}
</style>
