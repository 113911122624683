<template>
  <div class="container-fluid-signup">
    <div class="sign-up">
      <br />
      <div class="grid justify-items-center">
        <img height="100px" src="" />
        <br />
        <br />
        <h2>Create a new Account!</h2>
        <br />
        <input required type="text" v-model="email" placeholder="Email" /><br />
        <hr />
        <input required type="password" v-model="password" placeholder="Password" /><br />
        <hr />
        <button style="color: white" @click="signUp">Sign Up</button>
        <span> or go back to <router-link to="/login">login</router-link></span>
      </div>
      <br /><br /><br />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import firebase from 'firebase/compat/app';

export default {
  name: 'SignUp',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      email: '',
      password: ''
    };
  },
  methods: {
    signUp: function () {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.email, this.password)
        .then(
          () => {
            this.$router.replace('home');
          },
          (err) => {
            alert('Oops. ' + err.message);
          }
        );
    }
  }
};
</script>

<style scoped>
.container-fluid-signup {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 101vh;
  background: white;
}

.sign-up {
  /* margin-top: 20px; */
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: center;
  position: relative;
}

input {
  /* margin: 10px 0; */
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #fb4222;
  height: 15%;
}

button {
  margin-top: 10px;
  width: 50%;
  cursor: pointer;
  border-radius: 15px;
  background: #fb4222;
  /* border: 1px solid black;  */
  height: 60%;
  width: 40%;
}

p {
  margin-top: 5px;
  font-size: 13px;
}

p a {
  text-decoration: underline;
  cursor: pointer;
}

img {
  padding: 5px;
}
</style>
