import { getDBAdapter, getStoreAdapter } from '@/helpers/utils';
import { VersionControl } from '@/helpers/versionControl';
import { useAppStore, useVcmStore, useVersionsStore } from '@/store/index.js';

export const vcmPlugin = () => {
  const appStore = useAppStore();
  const vcmStore = useVcmStore();
  const versionsStore = useVersionsStore();
  const store = getStoreAdapter(appStore, vcmStore);
  const db = getDBAdapter(appStore, vcmStore, versionsStore);
  const vcm = new VersionControl({ store, db });
  vcm.start();

  return store;
};
