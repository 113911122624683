import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';
import { DOMParser, Slice } from 'prosemirror-model';

export const CustomPasteHandling = Extension.create({
  name: 'customPasteHandling',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handlePaste: (view, event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            if (clipboardData) {
              event.preventDefault();
              const html = clipboardData.getData('text/html');
              const text = clipboardData.getData('text/plain');

              if (html) {
                const { schema } = view.state;
                const content = new DOMParser.fromSchema(schema).parseFromString(html, 'text/html');
                const transaction = view.state.tr.replaceSelection(new Slice(content, 0, 0));
                view.dispatch(transaction);
                view.dispatch(transaction);
              } else if (text) {
                view.dispatch(view.state.tr.insertText(text));
              }

              view.focus();
              return true;
            }
            return false;
          }
        }
      })
    ];
  }
});
