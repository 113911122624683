<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <v-select
        append-to-body
        class="taxonomy-dropdown"
        :clearable="false"
        v-model="itemSelected"
        placeholder="Search Agent"
        :reduce="(taxonomies) => taxonomies"
        label="name"
        :options="taxonomies"
        filterable
        autocomplete
      />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import dropdowns from '../Mixins/dropdowns';
export default {
  mixins: [dropdowns],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
taxonomies: [],
    };
  },
  mounted() {
    this.taxonomies = [{ name: 'None', id: 0 }].concat(
      this.appStore.taxonomies
    );
  },
  beforeUnmount() {
    this.taxonomies = [];
  },
};
</script>
