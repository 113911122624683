<template>
  <div class="container-menu-container">
    <ul :class="{ opened: opened }" class="container-sub-menu">
      <li
        @click="
          item.click();
          closeParent();
        "
        v-for="(item, i) in items.filter((x) => typeof x === 'object')"
        :key="i"
      >
        <span class="container-option-text">{{ item.title }}</span>
      </li>
      <slot name="extra"></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ContainerDropdown',

  data() {
    return {};
  },
  methods: {},
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    },
    closeParent: {
      type: Function
    }
  }
};
</script>

<style>
.container-option-text {
  letter-spacing: normal;
  color: #383f45;
  line-height: 1.2;
}

.containerOptions {
  width: 100px !important;
  min-width: 90px !important;
  border-radius: 1px !important;
  padding: 0px !important;
  font-family: 'Roboto' !important;
  margin-left: 15px !important;
}

.containerOptionsSidebar {
  width: max-content !important;
  min-width: 80px !important;
  border-radius: 1px !important;
  padding: 0px;
  font-family: 'Roboto' !important;
  margin-top: -25px !important;
}
</style>

<style scoped>
li > .caption {
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.container-sub-menu {
  display: none;
  font-size: 14px;
  list-style-type: none;
  padding: 0px 0px 0px 0px !important;
  margin-bottom: 0px !important;
}

li.opened {
  display: block;
}

ul.container-sub-menu {
  display: none;
}

ul.opened.container-sub-menu {
  display: block;
}
.container-sub-menu li {
  width: 100%;
  height: max-content;
  cursor: pointer;
  line-height: 12px;
  padding: 7px 3px 7px 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.container-sub-menu li span {
  margin-left: 4px;
}

.container-sub-menu li:hover {
  background: #e8f5fe;
}
.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  margin-top: 3px;
}
.label {
  background: #fd5422;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 21px;
  display: none;
  margin-right: 10px;
}
.container-sub-menu li:hover .label {
  display: block;
}
</style>
