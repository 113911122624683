<template>
  <div class="fullwrapper">
    <v-style>.convo__health__user::after {background-image: url('{{ cssProps }}') !important;} </v-style>
    <div class="fullwrapper" style="width: 100%">
      <div class="wrapper ai-window" style="overflow: hidden; background-color: transparent">
        <div slot="top-right"></div>
        <p></p>
        <p></p>
        <div style="padding-top: 5px">
          <tr></tr>
        </div>
        <div style="width: 100%">
          <div class="grid justify-items-center">
            <el-date-picker @change="selectedDate" v-model="dateSelected" range-separator="To" format="MM-dd-yyyy" type="daterange" value-format="MM-dd-yyyy" @onPick="retrieveChanges()" placeholder="Select Date"> </el-date-picker>
            <br />
            <br />
            <el-table end-placeholder="Select Date" start-placeholder="Select Date" v-loading="loading" element-loading-color="red" element-loading-background="black" height="600" stripe :data="historyArray.scenes" :default-sort="{ prop: 'timestampMilli', order: 'descending' }" style="width: max-content; border-radius: 1px; padding: 15px; box-shadow: rgba(39, 39, 39, 0.1) 10px 10px 15px 10px">
              <el-table-column sortable prop="timestampMilli" label="Date Updated" :formatter="formatter" width="200"> </el-table-column>
              <el-table-column prop="user" sortable label="User" width="150">
                <template #default="scope">
                  <span v-if="scope.row.user.includes('health')" class="convo__health__user"><img :src="chLogo" width="30px" height="30px" /> {{ scope.row.user.replace('@conversationhealth.com', '').replace('@emojihealth.com', '') }}</span>
                  <span v-else> {{ scope.row.user.toUpperCase() }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="slotName" sortable label="Slot" width="200">
                <template #default="scope">
                  {{ scope.row.slotName.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column :filters="ChangeToArray" :filter-method="filterChangeTo" filter-placement="right-start" prop="changeType" label="Change To" width="120">
                <template #default="scope">
                  <div class="grid justify-items-center">
                    <i v-if="scope.row.changeType === 'Conversation'" class="fas fa-comment"></i>
                    <i v-else-if="scope.row.changeType === 'Entities'" class="fas fa-tags"></i>
                    <i v-else-if="scope.row.changeType === 'Status Change'" class="fas fa-thermometer-three-quarters"></i>
                    <span v-else>{{ scope.row.changeType }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :filters="TaxonomyArray" :filter-method="filterTaxonomy" filter-placement="right-start" sortable prop="taxonomy" label="Taxonomy" width="200">
                <template #default="scope">
                  {{ scope.row.taxonomy.toUpperCase() }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <br />
        <a id="bottom"></a>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { db } from '../main';
import firebase from 'firebase/compat/app';
import moment from 'moment';

export default {
  name: 'HistoryPage',
  props: {
    data: {
      type: Object,
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    actions: {
      required: false,
      type: Object
    }
  },

  directives: {},
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      chLogo: process.env.VUE_APP_CONVERSATIONHEALTH_LOGO,
      graphNodes: {
        clicked: null,
        mouseOver: null,
        zoomed: null,
        root: null,
        highlighted: null
      },
      width: null,
      height: null,
      fallback: '',
      node_color: '',
      showModal: false,
      child_chosen: '',
      parent_chosen: '',
      saved: this.email,
      showBot: '',
      utterance: '',
      responses: [],
      response_id: '',
      email: '',
      last_addition_timestamp: '',
      dateSelected: '',
      historyArray: { scenes: [], responses: [], entities: [] },
      loading: false,
      slotNameSaved: {}
    };
  },
  mounted() {
    this.currentUser();
  },
  methods: {
    formatter(row) {
      return row.timestamp;
    },
    selectedDate(e) {
      if (!moment(e[0]) || !moment(e[1] === null)) {
        this.loading = false;
        this.$message({
          type: 'error',
          message: 'Error occured. Select new date range'
        });
        return;
      }

      const startDate = moment(e[0]);
      const endDate = moment(e[1]);

      this.loading = true;
      this.dateSelected = e;
      const scenes = db.collectionGroup('Scene');
      // let responses = db.collectionGroup('Responses')
      const statusUpdates = db.collectionGroup('STATUS UPDATES');
      const entities = db.collectionGroup('values');
      this.historyArray.scenes = [];
      Promise.all([scenes, entities]).then(() => {
        scenes
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              const queryTimestamp = new Date(doc.data().timestamp?.toDate());
              if (queryTimestamp > startDate && queryTimestamp < endDate) {
                this.convertToSlotName(doc.ref)
                  .get()
                  .then((slotDoc) => {
                    if (slotDoc.exists) {
                      this.historyArray.scenes.push({
                        changeType: 'Conversation',
                        user: doc.data().user,
                        timestamp: doc.data().timestamp?.toDate()?.toLocaleString(),
                        timestampMilli: doc.data().timestamp?.toMillis(),
                        slotName: slotDoc.data().name,
                        taxonomy: doc.ref.path ? doc.ref.path.split('/')[0] : ''
                      });
                    }
                  });
              }
            });
          })
          .then(() => {
            statusUpdates.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const queryTimestamp = new Date(doc.data().timestamp?.toDate());
                if (queryTimestamp > startDate && queryTimestamp < endDate) {
                  this.convertToSlotName(doc.ref)
                    .get()
                    .then((slotDoc) => {
                      if (slotDoc.exists) {
                        this.historyArray.scenes.push({
                          changeType: 'Status Change',
                          user: doc.data().user,
                          timestamp: doc.data().timestamp.toDate().toLocaleString(),
                          timestampMilli: doc.data().timestamp?.toMillis(),
                          slotName: slotDoc.data().name,
                          taxonomy: doc.ref.path ? doc.ref.path.split('/')[0] : ''
                        });
                      }
                    });
                }
              });
            });
          })
          .then(() => {
            entities.get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const queryTimestamp = new Date(doc.data().timestamp?.toDate());
                if (queryTimestamp > startDate && queryTimestamp < endDate) {
                  this.historyArray.scenes.push({
                    changeType: 'Entities',
                    user: doc.data().user,
                    timestamp: doc.data().timestamp?.toDate()?.toLocaleString(),
                    slotName: 'N/A',
                    timestampMilli: doc.data().timestamp?.toMillis(),
                    scene: doc.data().scene,
                    taxonomy: doc.ref.path ? doc.ref.path.split('/')[0] : ''
                  });
                }
              });
            });
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          });
      });
    },
    retrieveChanges() {
      //TO BE ADDED
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    filterChangeTo(value, row) {
      return row.changeType === value;
    },
    filterTaxonomy(value, row) {
      return row.taxonomy === value;
    },
    filterHandler(value, row, column) {
      const property = column['property'];
      return row[property] === value;
    },
    convertToSlotName(docRef) {
      if (Object.prototype.hasOwnProperty.call(this.slotNameSaved, docRef.path.split('/')[1])) {
        return this.slotNameSaved[docRef.path.split('/')[1]].docReference;
      } else {
        const newQuery = db.collection(docRef.path.split('/')[0]).doc(docRef.path.split('/')[1]);
        this.slotNameSaved[docRef.path.split('/')[1]] = {
          docReference: newQuery
        };
        return newQuery;
      }
    }
  },
  computed: {
    cssProps() {
      return process.env.VUE_APP_CONVERSATIONHEALTH_LOGO;
    },
    TaxonomyArray() {
      const unique = new Set(
        this.historyArray.scenes.map((item) => {
          return item.taxonomy;
        })
      );
      const uniqueObject = [];
      unique.forEach((item) => {
        uniqueObject.push({ text: item, value: item });
      });
      return uniqueObject;
    },
    ChangeToArray() {
      const unique = new Set(
        this.historyArray.scenes.map((item) => {
          return item.changeType;
        })
      );
      const uniqueObject = [];
      unique.forEach((item) => {
        uniqueObject.push({ text: item, value: item });
      });
      return uniqueObject;
    }
  },
  watch: {
    data: {
      handler(current) {
        this.onData(current);
      },
      deep: true
    },
    historyArray: function () {
      // SendtoDB(this.responses)
    }
  }
};
</script>

<style>
.el-table th > .cell {
  font-size: 14px;
  /* text-transform: uppercase; */
}

.convo__health__user {
  color: #fb5422;
}

.pageTitle {
  font-weight: bold;
  margin-left: 0px;
  font-family: 'Oswald', sans-serif !important;
  font-size: 20px;
  position: fixed !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  top: 10px;
  right: 3%;
  z-index: 343242;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.pageHeaderIcon {
  position: fixed !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  top: 10px;
  right: 10px;
  background-color: transparent !important;
  background: transparent;
  z-index: 343242;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  font-size: 25px;
}
</style>

<style lang="less" scoped>
.vs-list--title {
  font-size: 25px;
  text-transform: uppercase !important;
  font-family: 'Oswald', sans-serif !important;
}

.text {
  font-size: 14px;
}

.cell {
  font-size: 12px !important;
}

.clicked.name {
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  border: none;
  padding: 3px;
}

.el-table {
  overflow: hidden !important;
}

.card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 13px;
}

.wrapper.ai-window {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 8px;
  width: 90%;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: white;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.7;
  /* Firefox */
  font-style: italic;
}

.navbar-brand {
  background: url(https://www.gstatic.com/devrel-devsite/vc67ef93e81a468795c57df87eca3f8427d65cbe85f09fbb51c82a12b89aa3d7e/dialogflow/images/lockup.svg?dcb_=0.7649172296890541) no-repeat 0 0;
  width: 171px;
  height: 45px;
  background-size: 171px 45px;
}

.wrapper.query {
  width: 510px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f4f4f4;
  position: initial;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.queryform.typing {
  border-radius: 15px;
  border: 0px solid white;
  position: relative;
  height: 35px;
  width: 95%;
  font-size: 16px;
  background-color: white;
  margin-left: 10px;
  margin-right: auto;
  padding: 10px;
  color: #262f34;
  font-family: 'Arial', sans-serif;
}

.suggestion {
  float: left;
  color: rgba(0, 0, 0, 0.7);
  background-color: white; // border: 0.3px solid grey;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  font-size: 12px; // font-weight: bold;
  -webkit-box-shadow: 1px 1px 2px black;
  box-shadow: 1px 1px 2px black;
  height: 25px;
  margin-left: 10px;
}

.input-select-con,
.con-select {
  font-size: 12px;
}

.suggestion.fallback {
  float: left;
  color: rgba(0, 0, 0, 0.7);
  background-color: lightgrey; // border: 0.3px solid grey;
  border-radius: 20px;
  cursor: pointer;
  padding: 5px;
  text-decoration: none;
  font-size: 12px; // font-weight: bold;
  -webkit-box-shadow: 1px 1px 2px black;
  box-shadow: 1px 1px 2px black;
  height: 25px;
  margin-left: 10px;
}

.clicked.name {
  font-size: 30px;
  font-weight: bold;
  width: fit-content;
  border: none;
}

.mdc-card {
  background-color: white;
  max-width: 50%;
  margin-bottom: 5px;
  animation: msg 0.45s ease-in-out;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.28);
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
}

.mdc-card__media-item {
  height: auto;
  width: 100%;
  margin-top: -5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.7;
  /* Firefox */
  font-style: italic;
}

.fullwrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
  // background-color: lightgray;
}
</style>
