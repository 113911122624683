<template>
  <div>
    <div class="row">
      <div class="col-12">
        <form class="form">
          <div class="form-group">
            <label class="label">Field Label</label>
            <input
              @change="onChangeHandler($event, 'fieldLabel')"
              v-model="localEmailFieldData.fieldLabel"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label class="label">Placeholder</label>
            <input
              @change="onChangeHandler($event, 'placeholder')"
              v-model="localEmailFieldData.placeholder"
              type="text"
              class="form-control"
            />
          </div>
          <div class="form-group slider-switch">
            <label class="reqLabel">Field Required</label>
            <label class="switch">
              <input
                @change="onChangeHandler($event, 'required')"
                v-model="localEmailFieldData.required"
                type="checkbox"
                checked
              />
              <span class="slider round"></span>
            </label>
          </div>
        </form>
      </div>
    </div>
    <hr class="solid" />
    <div class="row">
      <div class="col-12">
        <h6 class="sectionHeading"><strong>Back-end</strong></h6>
        <form class="form">
          <div class="form-group slider-switch">
            <label class="reqLabel">Encrypt</label>
            <label class="switch">
              <input
                @change="onChangeHandler($event, 'encrypt')"
                v-model="localEmailFieldData.encrypt"
                type="checkbox"
                checked
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div class="form-group">
            <label class="label">Variable Name</label>
            <input
              @change="onChangeHandler($event, 'varName')"
              v-model="localEmailFieldData.varName"
              type="text"
              class="form-control"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'EmailField',
  methods: {
    onChangeHandler(e, label) {
      if (label === 'fieldLabel') {
        this.$emit('emailFieldChange', {
          type: 'fieldLabel',
          value: e.target.value,
        });
      } else if (label === 'placeholder') {
        this.$emit('emailFieldChange', {
          type: 'placeholder',
          value: e.target.value,
        });
      } else if (label === 'required') {
        this.$emit('emailFieldChange', {
          type: 'required',
          value: e.target.value,
        });
      } else if (label === 'encrypt') {
        this.$emit('emailFieldChange', {
          type: 'encrypt',
          value: e.target.value,
        });
      } else if (label === 'varName') {
        this.$emit('emailFieldChange', {
          type: 'varName',
          value: e.target.value,
        });
      }
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
localEmailFieldData: this.emailFieldData,
    };
  },
  props: {
    emailFieldData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  position: absolute;
}

.slider.round:before {
  border-radius: 50%;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  margin-right: auto;
}

.reqLabel {
  position: absolute;
  left: 17px;
}

hr.solid {
  border-top: 3px solid #d1d8e1;
}

.slider-switch {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 12px;
}

.sectionHeading {
  display: flex;
  margin-top: 25px;
  color: #2196f3;
  margin-bottom: 20px;
}
</style>
