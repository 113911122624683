<template>
  <nav @mouseover="openNav" @mouseleave="closeNav" :class="{ hover: checkBottomNavHovering }" class="navbar">
    <input ref="zoomPercent" @keyup.esc="resetEdit" @change="setZoom" class="customInput" :class="{ hover: checkBottomNavHovering }" type="text" v-model="zoom" />
    <template v-if="checkBottomNavHovering">
      <div @click="zoomHandler('in')" class="navbar-brand" title="Zoom In"><ConversationBuilderIcon name="zoom-in" :disabled="zoomLevel === 200" nameHover="zoom-in-hover" nameDisabled="zoom-in-disabled" :width="18" /></div>
      <div @click="zoomHandler('out')" class="navbar-brand" title="Zoom Out"><ConversationBuilderIcon name="zoom-out" :disabled="zoomLevel === 10" nameHover="zoom-out-hover" nameDisabled="zoom-out-disabled" :width="18" /></div>
      <div class="navbar-brand" :disabled="!canUndo()" title="Undo" :onUndoRedo="onUndoRedo">
        <button :disabled="!canUndo()" @click="onUndoHandler">
          <ConversationBuilderIcon :disabled="!canUndo()" nameDisabled="undo-disabled" name="undo" nameHover="undo-hover" :width="17" />
        </button>
      </div>
      <div class="navbar-brand" :disabled="!canRedo()" title="Redo">
        <button :disabled="!canRedo()" @click="onRedoHandler">
          <ConversationBuilderIcon :disabled="!canRedo()" nameDisabled="redo-disabled" name="redo" nameHover="redo-hover" :width="17" />
        </button>
      </div>
      <div class="navbar-brand" :aria-hidden="true" @click="goToHome"><ConversationBuilderIcon name="home" nameHover="home-hover" :width="18" /></div>
      <div v-if="fullscreen" @click="openFullscreen" class="navbar-brand" title="Minimize"><ConversationBuilderIcon name="minimize" nameHover="minimize-hover" :width="17" /></div>
      <div v-if="!fullscreen" @click="openFullscreen" class="navbar-brand" title="Fullscreen"><ConversationBuilderIcon name="expand_view" nameHover="expand_view-hover" :width="17" /></div>
      <div v-if="canEdit()" @click="toggleAutoArrangeSizeInput" class="navbar-brand" title="Auto Arrange"><ConversationBuilderIcon name="auto-arrange-regular" nameHover="auto-arrange-hover" :width="19" /></div>
      <div class="invisibleDiv" v-if="autoArrangeSpacingInputVisible">
        <div class="autoArrangeBackground">
          <input maxlength="3" type="text" class="autoArrangeInput" ref="autoArrangeSpacing" v-model="autoArrangeSpacingPercentage" @keyup.enter="fireAutoArrangeMethod" />
          <div class="percentage">%</div>
        </div>
      </div>
      <div v-if="edgeStyle === 'curved'" @click="setEdgesTo('straight')" class="navbar-brand last" title="Set Lines to Straight"><ConversationBuilderIcon name="straight-connector" nameHover="straight-connector-hover" :width="19" /></div>
      <div v-if="edgeStyle === 'straight'" @click="setEdgesTo('curved')" class="navbar-brand last" title="Set Lines to Curved"><ConversationBuilderIcon name="curved-connector" nameHover="curved-connector-hover" :width="19" /></div>
    </template>
  </nav>
</template>

<script>
import { useAppStore, useVcmStore, useCollabStore } from '@/store/index.js';
import { nextTick } from 'vue';
import { mapState } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import ELK from 'elkjs';
const elk = new ELK();
//Default auto arrange spacing at an autoArrangeSpacingPercentage of 100% is half a container width. Full container width is 350px, half a container width is 175px.
const AUTO_ARRANGE_PIXEL_SPACING_AT_100_PERCENT = 175;

export default {
  name: 'BottomNav',
  data() {
    return {
      appStore: useAppStore(),
      collabStore: useCollabStore(),
      vcmStore: useVcmStore(),
      showBtns: false,
      fullscreen: false,
      home: false,
      onUndoRedo: false,
      loadNavigationTimeout: null,
      autoArrangeSpacingPercentage: 100,
      autoArrangeSpacingInputVisible: false,
      //Assume curved edges, need to assume one or the other (as some may have been changed to straight or curved manually)
      edgeStyle: 'curved'
    };
  },
  props: {},
  computed: {
    ...mapState(useAppStore, ['zoomLevel', 'canvasDisabled', 'checkBottomNavHovering']),
    zoom: {
      get() {
        return parseInt(this.appStore.zoomLevel) + '%';
      }
    },
    autoArrangeSpacingPixels() {
      return (AUTO_ARRANGE_PIXEL_SPACING_AT_100_PERCENT * this.autoArrangeSpacingPercentage) / 100;
    }
  },
  methods: {
    canEdit() {
      return this.collabStore.collabMode === 'editor';
    },
    undo() {
      this.vcmStore.undo();
      this.onUndoRedo = !this.onUndoRedo;
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    redo() {
      this.vcmStore.redo();
      this.onUndoRedo = !this.onUndoRedo;
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    canUndo() {
      return this.vcmStore.canUndo() && !this.canvasDisabled;
    },
    canRedo() {
      return this.vcmStore.canRedo() && !this.canvasDisabled;
    },
    openNav() {
      this.appStore.SET_BOTTOM_NAV_HOVER({ bool: true });
    },
    closeNav() {
      this.autoArrangeSpacingInputVisible = false;
      this.appStore.SET_BOTTOM_NAV_HOVER({ bool: false });
    },
    createNodesArray() {
      const currentNodes = this.appStore.scene.nodes;

      //children, nodes and containers are the same thing for elkjs
      const childrenArray = currentNodes.map((node) => {
        return {
          id: node.id,
          width: this.dimensionsAt100PercentZoom(this.getContainerWidthAndHeight(node.id).width),
          height: this.dimensionsAt100PercentZoom(this.getContainerWidthAndHeight(node.id).height)
        };
      });
      return childrenArray;
    },
    getContainerWidthAndHeight(containerId) {
      const containerElement = document.getElementById(containerId);
      const containerDimensions = containerElement?.getBoundingClientRect();
      return {
        width: containerDimensions.width,
        height: containerDimensions.height
      };
    },
    dimensionsAt100PercentZoom(dimension) {
      //Compensates for change in container size on zoom. Without this, the containers will be cramped togehter when using auto arrange (if zoom < 100%)
      return dimension / (this.appStore.zoomLevel / 100);
    },
    returnParentContainerId(edgeSourceOrTargetId) {
      //Need to return the parent container id, instead of the component id (to deal with decision component).
      const components = this.appStore.getComponents;
      let parentContainerId = edgeSourceOrTargetId;

      if (components[edgeSourceOrTargetId]) {
        //Set to the parent container
        parentContainerId = components[edgeSourceOrTargetId].parentId;
      }
      return parentContainerId;
    },
    createEdgesArray() {
      //Edges and links are the same thing for elkjs
      const currentEdges = this.appStore.scene.links;
      const edgesArray = currentEdges.map((edge) => {
        return {
          id: edge.id,
          sources: [this.returnParentContainerId(edge.from)],
          targets: [this.returnParentContainerId(edge.to)]
        };
      });
      return edgesArray;
    },
    createGraph() {
      //Edges and links are the same thing for elkjs
      //Children, nodes and containers are the same thing for elkjs
      const graph = {
        id: 'root',
        layoutOptions: {
          'elk.algorithm': 'mrtree',
          'elk.spacing.nodeNode': `${this.autoArrangeSpacingPixels}`
        },
        children: this.createNodesArray(),
        edges: this.createEdgesArray()
      };
      //This is a promise
      return elk.layout(graph);
    },
    autoArrange() {
      //createGraph is the auto-arrange algorithm using elkjs package.
      this.createGraph().then((graph) => {
        const updatedScene = cloneDeep(this.appStore.scene);
        const graphNodesArray = graph.children;

        updatedScene.nodes.forEach((sceneNode) => {
          const matchingNode = graphNodesArray.find((graphNode) => graphNode.id === sceneNode.id);
          sceneNode.x = matchingNode?.x;
          sceneNode.y = matchingNode?.y;
        });
        this.appStore.updateScene({ scene: updatedScene });

        //Need to rerender edges after container/node positions in DOM are updated
        nextTick(() => {
          this.appStore.rerenderEdges++;
        });
      });
    },
    toggleAutoArrangeSizeInput() {
      this.autoArrangeSpacingInputVisible = !this.autoArrangeSpacingInputVisible;
    },
    setEdgesTo(edgeStyle) {
      const edges = this.appStore.scene.links;
      this.edgeStyle = edgeStyle;

      edges.forEach((edge) => {
        this.appStore.setEdgeType({ id: edge.id, curved: this.edgeStyle === 'curved' ? true : false });
      });

      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    openFullscreen: function() {
      this.dropdownKey = Math.random();
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
        this.fullscreen = false;
      } else {
        this.fullscreen = true;
        const elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    },
    goToHome() {
      this.$emit('zoom', 'home');
      this.home = true;
      this.loadNavigationTimeout = setTimeout(() => {
        this.home = false;
      }, 2000);
    },
    zoomHandler(val) {
      if (val === 'in' && this.zoomLevel === 200) return;
      if (val === 'out' && this.zoomLevel === 10) return;
      this.$emit('zoom', val);
    },
    onUndoHandler(e) {
      e.preventDefault();
      this.undo();
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    onRedoHandler(e) {
      e.preventDefault();
      this.redo();
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    updateBtnStatus() {
      this.showBtns = !this.showBtns;
      this.$forceUpdate();
    },
    resetEdit() {
      this.$emit('zoom', false);
      this.$forceUpdate();
      nextTick(() => {
        this.$refs.zoomPercent.blur();
      });
    },
    setZoom(e) {
      // if Invalid number over 200 and under 10, emit false
      const val = parseInt(e.target.value);
      if (val < 10 || val > 200 || Number.isNaN(val)) {
        this.$emit('zoom', false);
        this.$forceUpdate();
        return;
      }
      const numberToDecimal = parseInt(e.target.value) / 100;
      if (numberToDecimal < 3) {
        this.$emit('zoom', numberToDecimal);
      }
    },
    fireAutoArrangeMethod() {
      const isInputStringANumber = !isNaN(this.autoArrangeSpacingPercentage) && this.autoArrangeSpacingPercentage > 0 && this.autoArrangeSpacingPercentage.toString().indexOf('.') === -1 && this.autoArrangeSpacingPercentage <= 400;

      //Only fire auto arrange method if the characters entered are numbers.
      if (isInputStringANumber === true) {
        this.autoArrange();
      } else {
        this.autoArrangeSpacingPercentage = 100;
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.loadNavigationTimeout);
  }
};
</script>

<style lang='scss' scoped>
.navbar {
  background-color: #fff;
  width: 120px;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 5;
  bottom: 30px;
  padding: 5px 0px 5px 0px !important;
  color: #383f45;
  border-radius: 4px;
  margin-left: 280px;
  margin-right: auto;
  height: 48px;
}

.navbar.hover {
  width: 600px;
}

.customInput {
  width: 80px;
  height: 40px;
  margin-left: 20px;
  border-radius: 2px;
  font-size: 17px;
  text-align: center;
  color: #383f45;
}

.navbar-brand {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
  width: 40px;

  &:disabled {
    cursor: default !important;
  }

  span:hover {
    color: #0d6efd;
    cursor: pointer;
  }
}

button:disabled {
  color: #383f45;
  cursor: default !important;

  span {
    cursor: default !important;
  }

  img {
    cursor: default !important;
  }
}

.last {
  margin-right: 20px;
}

.customInput:hover {
  background: #edf0f4;
}

.customInput:focus {
  // padding: 3px 0px 3px 3px;
  border-radius: 2px;
  background: #edf0f4;
  border: solid 1px #2698ed;
}

.invisibleDiv {
  height: 47px;
  margin: 0px;
  position: absolute;
  right: 60px;
  bottom: 48px;
  width: 80px;
}

.autoArrangeBackground {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 40px;
}

.autoArrangeBackground:hover {
  border: solid 1px #2698ed;
}

.autoArrangeInput {
  width: 3ch;
  font-size: 17px;
  text-align: right;
}

.percentage {
  font-size: 17px;
}
</style>
