<template>
  <div class="conversationComponent" :class="{ connected: connected }" :data-componentId="id">
    <div class="row conversationComponentRowWrapper">
      <div class="col-12 conversationComponentColumnWrapper">
        <div class="decision-container" v-for="(option, index) in decisionOptions" :key="index" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
          <div class="row option-container">
            <div class="col-1 option-container-icon">
              <img v-if="index === 0" class="sideBarIcon" src="./../../../../../../assets/icons/decision.svg" />
            </div>
            <div class="col-10 text-container">
              <span v-html="option.text" />
            </div>
          </div>
          <hr v-if="index !== decisionOptions.length - 1" class="divider" />
          <!-- <NodeConnector @linking-start="outputMouseDown" :response="response" :componentId="id" :node="node" /> -->
          <NodeConnector :hide="false" :isConnected="isConnected(index)" :isSource="true" :isContainer="false" :componentId="`${id}`" :optionIndex="index" :data-nodeType="`decision`" />
          <NodeConnector :hide="!isHovering && !isLinking" :isConnected="isConnected(index)" :isSource="true" :isContainer="false" :componentId="`${id}`" :optionIndex="index" :data-nodeType="`decision`" @linking-stop="linkingStop" @linking-start="decideLinking(index, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import NodeConnector from './NodeConnector.vue';
import essentials from '../Mixins/essentials';
import { mapState } from 'pinia';

export default {
  name: 'DecisionComponent',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      isHovering: false
    };
  },
  mixins: [essentials],
  components: {
    NodeConnector
  },
  props: {
    componentId: {
      type: String
    },
    index: {},
    isLinking: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(useAppStore, ['canvasDisabled', 'getTargetNode', 'getSourceNode']),
    connected() {
      return this.componentId === this.getTargetNode || this.componentId === this.getSourceNode;
    }
  },
  methods: {
    isConnected(decisionIndex) {
      return this.appStore.getScene.links.some((link) => link?.isFromOption && link?.from === this.id && link?.optionIndex === decisionIndex);
    },
    decideLinking(decisionIndex, evt) {
      const isLinked = this.appStore.getScene.links.find((link) => link.isFromOption && link.from === this.id && link.optionIndex === decisionIndex);
      if (isLinked === undefined && !this.canvasDisabled) {
        evt.isFromOption = true;
        evt.optionIndex = decisionIndex;
        this.linkingStart(evt);
      }
    },
    outputMouseDown(e) {
      e.event.preventDefault();
      this.$emit('linking-start', e);
    },
    linkingStart(payload) {
      payload.containerTitle = this.titleValue;
      this.$emit('node-selected', {
        id: this.id,
        event: payload.event,
        drag: true
      });
      this.$emit('linking-start', payload);
    },
    linkingStop(e) {
      this.$emit('linking-stop', e);
    },
    handleMouseOver() {
      if (this.canvasDisabled) {
        return;
      }
      this.isHovering = true;
    },
    handleMouseLeave() {
      try {
        this.isHovering = false;
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Decision.scss';

.conversationComponent {
  //Required for links to be draggable from decision components
  pointer-events: all;
}

.connected {
  border: solid 2px #2698ed !important;
}

.decision-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .option-container {
    display: flex;
    align-items: center;
  }

  .divider {
    width: 89%;
    align-self: center;
    margin-top: 11.5px;
    margin-bottom: 10.5px;
  }
}

.text-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1px;
  word-break: break-word;
}
</style>
