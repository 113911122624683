<template>
  <div @mouseleave="hover = false" class="message-editor" id="editor" :class="{}">
    <div
      class="row"
      :class="{
        decision: type === 'decision',
        flow: type === 'flow',
        linkUrl: type === 'linkUrl',
        linkToContainer: type === 'linkToContainer'
      }"
    >
      <div class="col-1" v-if="!noIcon">
        <img v-if="type === 'message'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/message.svg" />
        <img v-if="type === 'decision'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/decision.svg" />
        <img v-if="type === 'carousel'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/carousel.svg" />
        <img v-if="type === 'flow'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/flow.svg" />
        <img v-if="type === 'linkUrl' && url === false" class="sideBarIcon" width="13" src="./../../../../../assets/icons/linkUrl.svg" />
        <img v-if="type === 'set'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/set.svg" />
        <img v-if="type === 'linkToContainer'" class="sideBarIcon" width="13" src="./../../../../../assets/icons/linkToContainer.svg" />
      </div>
      <div class="col-10">
        <editor-content v-if="type === 'message'" :class="`message__content__bubble ${id} ${tabindex} ${avatar} ${url}`" :editor="editor" />
        <editor-content spellcheck="false" v-if="type === 'linkUrl' || type === 'flow' || type === 'set' || type === 'decision' || type === 'linkToContainer'" :class="`message__content__bubble ${type} ${id} ${tabindex} ${avatar} ${url ? 'highlight' : ''}`" :editor="editor" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { nextTick } from 'vue';
import {
  Alignment,
  Superscript,
  Subscript,
  Highlight,
  /*HighlightSecondary,
  Reference,
  Image,
  Mention,*/
  HorizontalRule,
  Note,
  NotePrimary
} from '@/components/ConversationBuilder/Extensions/index.js';
import Link from '@tiptap/extension-link';
// import Fuse from 'fuse.js';
// import tippy from 'tippy.js';
import { mapState } from 'pinia';

import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Focus from '@tiptap/extension-focus';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';

export default {
  name: 'MessageEditor',
  components: {
    EditorContent
  },
  props: {
    placeholder: {
      type: String,
      default: ' '
    },
    reference: {
      type: Array
    },
    url: {
      default: false
    },
    avatar: {
      type: Boolean,
      default: false
    },
    noAnnotation: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    modelValue: {
      type: [String, Object]
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    collection: {
      required: false,
      type: String
    },
    noIcon: {
      required: false,
      type: Boolean
    },
    nodes: {
      required: false,
      type: Object
    },
    language: {
      required: false
    },
    replyData: {}
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      tippyObj: {
        note: '',
        lastSavedBy: '',
        photoURL: '',
        show: false
      },
      hover: false,
      textBetween: '',
      editor: null,
      editorChange: false,
      linkUrl: null,
      refUrl: null,
      sayAsPhrase: null,
      phoneNumber: null,
      note: '',
      notePrimary: '',
      linkMenuIsActive: false,
      refMenuIsActive: false,
      telephoneMenuIsActive: false,
      noteMenuIsActive: false,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      insertMentionGroup: () => {},
      observer: null,
      users: useAppStore().getAllUsers,
      groups: [{ displayName: 'MODERATOR', uid: 1232342 }],
      textLength: 0,
      refsCreated: [],
      linksCreated: [],
      popperOptions: { boundariesElement: 'body', gpuAcceleration: false },
      clientsIDs: [],
      colorsMap: {},
      loadHTMLTimeout: null,
      loadContentTimeout: null
    };
  },
  computed: {
    ...mapState(useAppStore, ['getTippyObj']),
    setLanguage() {
      return this.appStore.language;
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: this.editable ?? false,
      onInit: () => {},
      onTransaction: ({ editor }) => {
        this.loadHTMLTimeout = setTimeout(() => {
          this.$emit('input', editor.getHTML());
        }, 200);
      },
      onFocus: ({ editor }) => {
        this.noteMenuIsActive = false;
        this.notePrimaryMenuIsActive = false;

        const state = editor?.state;
        if (state) {
          this.textLength = state.doc.textContent.length;
          this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
        }
        this.textLength = state.doc.textContent.length;
        this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
      },
      onBlur: ({ editor, event }) => {
        if (event != null) this.textLength = 0;
        const state = editor?.state;
        if (state) {
          this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
        }
      },
      onUpdate: ({ editor, transaction }) => {
        this.editorChange = true;
        this.$emit('input', editor.getHTML());
        this.textLength = transaction.doc.textContent.length;
      },
      useBuiltInExtensions: true,
      content: this.modelValue,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Alignment,
        Note,
        NotePrimary,
        Superscript,
        Subscript,
        Highlight,
        HorizontalRule,
        TaskItem,
        TaskList,
        Link,
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Table.configure({
          resizable: true
        }),
        TableHeader,
        TableCell,
        TableRow,
        /*HighlightSecondary,
        Reference,
        Image,
        Mention.configure({
          // a list of all suggested items
          items: () => this.users,
          // is called when a suggestion starts
          onEnter: ({ items, query, range, command, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.renderPopup(virtualNode);
            // we save the command for inserting a selected mention
            // this allows us to call it inside of our custom popup
            // via keyboard navigation and on click
            this.insertMention = command;
          },
          // is called when a suggestion has changed
          onChange: ({ items, query, range, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.navigatedUserIndex = 0;
            this.renderPopup(virtualNode);
          },
          // is called when a suggestion is cancelled
          onExit: () => {
            // reset all saved values
            this.query = null;
            this.filteredUsers = [];
            this.suggestionRange = null;
            this.navigatedUserIndex = 0;
            this.destroyPopup();
          },
          // is called on every keyDown event while a suggestion is active
          onKeyDown: ({ event }) => {
            // pressing up arrow
            if (event.keyCode === 38) {
              this.upHandler();
              return true;
            }
            // pressing down arrow
            if (event.keyCode === 40) {
              this.downHandler();
              return true;
            }
            // pressing enter
            if (event.keyCode === 13) {
              this.enterHandler(event);
              return true;
            }
            return false;
          },
          // is called when a suggestion has changed
          // this function is optional because there is basic filtering built-in
          // you can overwrite it if you prefer your own filtering
          // in this example we use fuse.js with support for fuzzy search
          onFilter: (items, query) => {
            if (!query) {
              return items;
            }
            const fuse = new Fuse(items, {
              threshold: 0.2,
              keys: ['displayName'],
            });
            return fuse.search(query);
          },
        }),*/
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: true,
          showOnlyCurrent: true
        })
      ]
    });
  },
  methods: {
    addHorizontalRule() {
      nextTick(() => {
        // this.$refs.content.editor.commands.setHorizontalRule();
        this.editor.commands.setContent(this.modelValue + '<hr>', true);
        this.loadContentTimeout = setTimeout(() => {
          this.editor.commands.setContent(this.modelValue + '<p></p>', true);
        }, 30);
      });
    }
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) {
        this.editor.destroy();
      }
    } catch (err) {
      //
    }
    clearTimeout(this.loadHTMLTimeout);
    clearTimeout(this.loadContentTimeout);
  },
  watch: {
    modelValue(newValue) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(newValue ?? '', true);
      }
      this.editorChange = false;
    },
    editable() {
      const editable = this.readOnly ? false : true;
      this.editor.setEditable(editable);
    }
  }
};
</script>

<style scoped>
.sideBarIcon {
  margin-right: 12px;
  width: 14px;
  margin-bottom: 3px;
}
</style>

<style>
.hr {
  padding-top: 2px;
  margin: 0px;
  margin-top: 2px;
}

hr {
  opacity: 0.3;
  border-top: solid 1px #707070;
  margin: 6.5px 0 4.5px 0px;
}

a[target='_blank'] {
  text-decoration: underline;
}

ref[reference] {
  text-decoration: none;
  /* vertical-align: super; */
  /* color: purple; */
  font-size: inherit;
}

highlight,
h {
  /* background: yellow; */
  text-shadow: 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow;
}

highlightsecondary {
  /* background: thistle; */
  text-shadow: 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle;

  color: black;
}

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
  font-style: italic;
  text-decoration-color: black;
}
</style>

<style>
.message__content__bubble {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  margin-left: -2px;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 3px;
  -webkit-transition: none;
  transition: none;
  cursor: text;
  font-family: 'Roboto', sans-serif !important;
  text-align: left;
}
.message__content__bubble:hover {
  cursor: move;
}

.message__content__bubble.linkUrl {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #383f45;
}

.message__content__bubble.linkUrl.highlight {
  font-style: italic;
  letter-spacing: normal;
  text-align: left;
  color: #1996f0;
  font-size: 14px;
  line-height: 1.36;
  font-weight: normal;
  margin-top: 10px;
}

.row.decision {
  padding: 8px 0px 8px 0px;
}
.row.message {
  padding: 8px 0px 8px 0px;
}

.bi.bi-hdd-stack {
  color: #92b8d9;
}

.message_content__bubble.true:before {
  /* background-image: attr(clientLogo); */
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: -27px;
  content: '';
  display: inline-block;
  height: 20px;
  left: -33px;
  position: absolute;
  width: 20px;
  background-size: cover;
}

sup::before,
sub::before {
  background-image: none !important;
}

.message_content__bubble.true:after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 23px;
  width: 20px;
  height: 0;
  border: 20px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  border-left: 0;
  margin-left: 3px;
  margin-bottom: -5px;
  transform: rotate(16deg);
  z-index: -1;
}

.message__content__bubble p {
  text-align: left;
  margin: 0px;
  font-family: 'Roboto', sans-serif !important;
  margin-left: -2px;
}

.ProseMirror,
.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror {
  position: relative;
  word-wrap: break-word;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}
</style>
