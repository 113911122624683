<template>
  <div class="slider">
    <br />
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="(slide, i) in slides" :key="i" :index="i">
        <figcaption :style="{ backgroundColor: backgroundColor, margin: 'auto' }">
          <div class="figcap">
            <!-- <i   class="fas fa-chevron-circle-right"></i> -->
            <!-- <div class="grid justify-items-center">
                <tr v-if="slide.title">
                  <span v-html="slide.title" class="carouselTitle"></span>
                </tr>
              </div> -->
          </div>
          <div class="grid justify-items-center">
            <div v-if="slide.imageUri" class="ctaImageWrapper">
              <img @click="postBackClick(slide.postback)" :src="slide.imageUri" class="ctaImage" width="100%" height="100%" />
            </div>
          </div>
        </figcaption>
      </div>
    </flickity>
    <br />
    <br />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import Flickity from 'vue-flickity';

export default {
  props: {
    dots: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: ''
    },
    slides: {
      type: Array
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    titleFontSize: {
      type: String,
      default: '18px'
    },
    subtitleFontSize: {
      type: String,
      default: '16px'
    }
  },
  components: {
    Flickity
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      flickityOptions: {
        prevNextButtons: false,
        pageDots: this.dots,
        wrapAround: true,
        adaptiveHeight: true
      }
    };
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    postBackClick() {}
  }
};
</script>

<style>
.flickity-page-dots .dot {
  background: #1996f0;
}

.flickity-prev-next-button {
  background: #1996f0;
  color: white;
  width: 25px;
  height: 44px;
  border-radius: 15px;
}

.flickity-prev-next-button:hover {
  color: #1996f0;
  background: white;
  border: 1px solid #1996f0;
}

.flickity-prev-next-button.previous {
  left: 30px;
}

.flickity-prev-next-button.next {
  right: 30px;
}
</style>

<style scoped>
.fas.fa-chevron-circle-right {
  margin-top: 3px;
  float: right;
  font-size: 130%;
  cursor: grab;
  color: #00214e;
}

.ctaImageWrapper {
  width: 100%;
  height: 100%;
}

.cta {
  padding: 8px 2px 8px 2px;
  border-radius: 4px;
  background: white;
  border: solid 1px #1996f0;
  color: #1996f0;
  width: 80px;
  position: absolute;
  width: 100%;
  bottom: 25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.ctaText {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.carouselTitle {
  font-weight: bolder;
  font-stretch: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: #00214e;
}

.carouselTitle:hover {
  color: #1996f0;
  text-decoration: underline;
}

.subtitle {
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #00214e;
  opacity: 0.4;
}

@media screen and (min-width: 200px) {
  /* half-width cells for larger devices */
  .carousel-cell {
    height: 30vh;
  }
  .carouselTitle {
    font-size: 15px;
  }
  .subtitle {
    font-size: 11px;
  }
  .ctaText {
    font-size: 12px;
  }
}

@media screen and (min-width: 550px) {
  /* half-width cells for larger devices */
  .carousel-cell {
    height: 40vh;
  }

  .carouselTitle {
    font-size: 14px;
  }
  .subtitle {
    font-size: 11px;
  }
  .ctaText {
    font-size: 11px;
  }
}

.carousel-cell {
  width: 90%;
  /* half-width */
  height: 250px;
  /* margin-right: 10px; */
  margin-top: 3px;
  cursor: pointer;
}

.figcap {
  padding: 11px;
}

.slider {
  /* max-width: 600px; */
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  /* margin-right: auto; */
}

.carousel-3d-container figure {
  margin: 0;
}

figcaption {
  /* padding: 15px; */
  border-radius: 8px;
  min-width: 50%;
  max-width: 90%;
  background-color: white !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-3d-slide.current {
  border-radius: 10px;
  border: none;
}

.avatarnew {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 1.2rem;
  height: 1.2rem;
  background-size: cover;
  float: right;
}

.openpage {
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
  font-size: 14px;
  color: black;
  margin: 0px;
}
</style>

<style>
.carousel-cell p {
  margin: 0px !important;
  display: block !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
