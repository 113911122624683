<template>
  <div>
    <div class="decisions-all">
      <div class="styles">
        <div
          :class="`style${getActiveStyle('suggestions')}`"
          @mouseover="suggestionsHover = true"
          @mouseleave="suggestionsHover = false"
          @click="() => setStyle('suggestions')"
        >
          <ConversationBuilderIcon
            :width="43"
            :name="`suggestions${getActiveStyle('suggestions')}`"
          />
          <span class="style-name">Suggestions</span>
        </div>
        <div
          :class="`style${getActiveStyle('vertical')}`"
          @mouseover="verticalHover = true"
          @mouseleave="verticalHover = false"
          @click="() => setStyle('vertical')"
        >
          <ConversationBuilderIcon :width="43" :name="`vertical${getActiveStyle('vertical')}`" />
          <span class="style-name">Vertical</span>
        </div>
        <div
          :class="`style${getActiveStyle('dropdown')}`"
          @mouseover="dropdownHover = true"
          @mouseleave="dropdownHover = false"
          @click="() => setStyle('dropdown')"
        >
          <ConversationBuilderIcon :width="43" :name="`dropdown${getActiveStyle('dropdown')}`" />
          <span class="style-name">Dropdown</span>
        </div>
      </div>
      <div
        class="dropdown-container"
        v-if="componentSelected.style === 'dropdown'"
      >
        <SidebarText
          :field="`dropdownText`"
          :placeHolder="placeholder"
          :id="componentSelected.id"
          :mainComponentId="
            componentSelected.mainCarouselId
              ? componentSelected.mainCarouselId
              : ''
          "
        >
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Dropdown Select Text</span>
              </div>
            </div>
          </template>
        </SidebarText>
      </div>
      <div v-if="clarificationOptions && clarificationOptions.length > 0">
        <div
          class="sidebar components"
          v-for="(option, index) in clarificationOptions"
          :key="index"
        >
          <DecisionEach
            :option="option"
            :index="index"
            :optionsCount="clarificationOptions.length"
            :componentId="id"
          />
        </div>
      </div>
    </div>
    <RemoveOption v-if="clarificationOptions && clarificationOptions.length > 0" :width="24" :func="removeOption" />
    <AddOption v-else :width="24" :func="addOption" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import AddOption from '../Buttons/AddOption.vue';
import RemoveOption from '../Buttons/RemoveOption';
import DecisionEach from '../SidebarComponents/DecisionEach';
import essentials from '../Mixins/essentials';
import { mapActions } from 'pinia';
export default {
  name: 'ClarificationEdit',
  mixins: [essentials],
  props: {
    id: {},
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    ...mapActions(useVcmStore, ['forceSave']),
    async addOption() {
      this.appStore.addItemToCollection({ componentId: this.componentSelected.id, collectionField: 'options', componentType: 'clarification' });
      await this.appStore.createOptionContainer({ decisionComponentId: this.componentSelected.id, componentType: 'clarification' });
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    async removeOption() {
      this.appStore.removeItemFromCollection({
        index: this.index,
        componentId: this.id,
        collectionField: 'options',
      });
      await this.appStore.removeOptionContainer({
        index: this.index,
        decisionComponentId: this.id,
      });
      this.appStore.FORCE_STATE_RECOMPUTE();
    },
    getActiveStyle(style) {
      if (this.componentSelected.style === style) {
        return '-active';
      }

      if (this[style + 'Hover']) {
        return '-hover';
      }

      return '';
    },
    setStyle(style) {
      let dropdownText;

      if (style !== 'dropdown') {
        dropdownText = '';
      }

      this.appStore.updateComponent({
        id: this.id,
        style,
        dropdownText,
      });
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      slots: [],
      time: '1 sec',
      taxonomy: 'COVID_TEST',
      intent: 'SA2_WarningPrecautions',
      suggestionsHover: false,
      verticalHover: false,
      dropdownHover: false,
      placeholder: 'e.g. Choose an option',
    };
  },
  beforeUnmount() {
    this.slots = [];
  },
  components: {
    AddOption,
    DecisionEach,
    RemoveOption,
  },
};
</script>

<style scoped>
.dropdown-container {
  width: 100%;
  height: 100px;
  padding: 15px 15px 0px 15px;
  border-bottom: solid 1px #d1d8e1;
}

.styles {
  width: 100%;
  height: 80px;
  display: flex;
  cursor: pointer;
}

.style:last-of-type {
  border-right: none;
}

.style-active:last-of-type {
  border-right: none;
}

.style-hover:last-of-type {
  border-right: none;
}

.style {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.29;
  text-align: left;
  border-right: solid 1px #d1d8e1;
  border-bottom: solid 1px #d1d8e1;
}

.style-active {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.29;
  text-align: left;
  border-right: solid 1px #d1d8e1;
  border-bottom: solid 1px #1996f0;
}

.style-hover {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 1.29;
  text-align: left;
  border-right: solid 1px #d1d8e1;
  border-bottom: solid 1px #d1d8e1;
}

.style-name {
  margin-top: 10px;
}

.decisions-all {
  border-bottom: 1px solid #e3e8ee;
  max-height: 75vh;
  overflow: hidden scroll;
}

textarea.decision-title {
  width: 240px !important;
  min-height: 15px;
  max-height: 60px;
  margin-top: 2px;
}
textarea::placeholder {
  font-style: normal !important;
  font-weight: normal;
  font-size: 14px;
}

.decision-index {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1996f0;
}

.decision-title {
  font-weight: bold;
  margin-left: 8px;
  width: 100%;
}

.bi.bi-record-circle-fill {
  color: #1996f0;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.bi.bi-record-circle {
  color: #d7dde5;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.fas.fa-info-circle {
  margin-left: 5px;
}

.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}
.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 5px 22px;
  height: max-content;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
