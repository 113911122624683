import { routingComponents } from './constants/constants';

const isEmptyContainer = function (targetEvt) {
  return targetEvt?.relatedContext.list.length === 0;
};
const isBackground = function (targetEvt) {
  return targetEvt?.related.id.includes('Background') || targetEvt?.related.id.includes('dropZone') || targetEvt?.related.classList.contains('dropZone') || targetEvt?.related.classList.contains('canvasContainer');
};
const isContainerHandle = function (targetEvt) {
  return targetEvt?.related.id.includes('commentDropZone') || targetEvt?.related.classList.contains('commentDropZone') || targetEvt?.related.classList.contains('message-container-title');
};
const isComponent = function (targetEvt) {
  return targetEvt?.related.id.includes('commentDropZone-component') || targetEvt?.related.classList.contains('commentDropZone-component') || targetEvt?.to?.classList.contains('commentDropZone-component');
};
const isRoutingComponent = function (targetEvt) {
  return routingComponents().includes(targetEvt.draggedContext?.element.type.toUpperCase().replace(/\s/g, ''));
};
const containerHasRoutingComponentAlready = function (components) {
  return components.some((x) => routingComponents().includes(x.type?.toUpperCase()));
};
const isLastComponent = function (targetEvt) {
  return targetEvt.draggedContext?.futureIndex >= targetEvt.relatedContext.list.length;
};
const droppedOnBackground = function (event) {
  try {
    // Sometimes user may drop on and svg where an edge may be - this is technically the canvas;
    return event?.target?.classList.contains('dropZone') || event?.target?.classList.contains('infinite-viewer-scroll-area') || event?.target?.classList.contains('canvasContainer') || event?.target?.nodeName === 'svg';
  } catch (err) {
    console.error('Error: ', err.message, err);
    return true;
  }
};

const droppedOnContainer = function (event) {
  try {
    // Sometimes user may drop on and svg where an edge may be - this is technically the canvas;
    return event?.target?.classList.contains('commentDropZone') || event?.target?.classList.contains('containerHeader') || event?.target?.classList.contains('message-container-title');
  } catch (err) {
    console.error('Error: ', err.message, err);
    return true;
  }
};

const droppedOnValidComponent = function (element) {
  try {
    // iF element has componentId as attribute then component can be used for pinpoint comment;
    return element.getAttribute('data-componentId') || false;
  } catch (err) {
    console.error('Error: ', err.message, err);
    return false;
  }
};

const selectedContainerHandle = function ({ target }) {
  try {
    return target?.classList?.contains('message-container-title') || target.classList.contains('container-overlay') || target?.classList?.contains('message-container-popup');
  } catch (err) {
    console.error('Error: ', err.message, err);
    return false;
  }
};
const containerCheckMove = function (targetEvt) {
  try {
    if (isBackground(targetEvt)) {
      return true;
    } else {
      if (isRoutingComponent(targetEvt) && containerHasRoutingComponentAlready(targetEvt.relatedContext.list)) {
        return false;
      }
      // This conditional checks if component being moved from library is a routing component or component being moved from a container is a routing component
      if (isRoutingComponent(targetEvt)) {
        if (!isLastComponent(targetEvt)) return false;
        // else if (targetEvt?.relatedContext.list.some((x) => routingComponents().includes(x.type.toUpperCase()))) return false;
      } else {
        if (containerHasRoutingComponentAlready(targetEvt.relatedContext.list) && isLastComponent(targetEvt)) return false;
        else return true;
      }
    }
  } catch (err) {
    console.error('Error: ', err.message, err);
    return true;
  }
};

const libraryCheckMove = function (targetEvt) {
  try {
    if (isBackground(targetEvt)) {
      return true;
    } else {
      if (isEmptyContainer(targetEvt)) {
        return true;
      }
      //Allow items moving into empty container
      if (isRoutingComponent(targetEvt) && containerHasRoutingComponentAlready(targetEvt.relatedContext.list)) {
        return false;
      }
      // This conditional checks if component being moved from library is a routing component or component being moved from a container is a routing component
      if (isRoutingComponent(targetEvt)) {
        if (!isLastComponent(targetEvt)) return false;
      } else {
        if (containerHasRoutingComponentAlready(targetEvt.relatedContext.list) && isLastComponent(targetEvt)) return false;
        else return true;
      }
    }
  } catch (err) {
    console.error('Error: ', err.message, err);
    return true;
  }
};

export { isContainerHandle, isComponent, libraryCheckMove, containerCheckMove, droppedOnContainer, droppedOnValidComponent, selectedContainerHandle, droppedOnBackground, isEmptyContainer, isBackground, isRoutingComponent, containerHasRoutingComponentAlready, isLastComponent };
