<template>
  <div class="autocomplete">
    <div :class="boxClass">
      <input v-model="search" class="search-navbar" type="search" placeholder="Search" @focus="onFocus('in')" @focusout="onFocus('out')" @keydown.down="onArrowDown" @keydown.up="onArrowUp" @keydown.enter="onEnter" @input="onChange" />
      <span class="search-navbar-end">
        <div :class="iconClass"><i class="fa fa-search"></i></div>
      </span>
    </div>
    <ul id="search-results" v-show="isOpen" class="search-results" :class="{ opened: isOpen }">
      <li class="loading" v-if="isLoading">Loading intents..</li>
      <li v-else v-for="(result, i) in results" :key="i" @click="setResult(result)" class="search-result" :class="{ 'is-active': i === arrowCounter }">
        {{ result.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'AutocompleteInput',
  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      boxClass: 'search-navbar-container',
      iconClass: 'search-navbar-icon',
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: -1
    };
  },
  watch: {
    items: function (value, oldValue) {
      if (value.length !== oldValue.length) {
        this.results = value;
        this.isLoading = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    onFocus(val) {
      if (val === 'out') {
        this.boxClass = 'search-navbar-container';
        this.iconClass = 'search-navbar-icon';
      } else if (val === 'in') {
        this.boxClass += ' active-search-box';
        this.iconClass += ' active-icon';
      }
    },
    setResult(result) {
      this.search = result.name;
      this.isOpen = false;
      if (result) {
        this.$emit('setFlow', result);
      }
    },
    filterResults() {
      const newResults = this.items.filter((item) => {
        return item?.name?.toLowerCase().includes(this.search.toLowerCase());
      });
      this.results = newResults;
    },
    onChange() {
      this.$emit('input', this.search);
      if (this.search === '') {
        this.isOpen = false;
      } else {
        this.filterResults();
        this.isOpen = true;
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      this.setResult(this.results[this.arrowCounter]);
      this.arrowCounter = -1;
    }
  }
};
</script>

<style lang="scss" scoped>
input {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
}
.search-input:active {
  border-radius: 4px;
  border: solid 1px #2698ed;
}

.search-navbar-container {
  display: flex;
  width: 305px;
  height: 48px;
  border-radius: 4px;
  margin: 2px 10px 0 2px;
  border: solid 1px #d7dde5;
  justify-content: center;
  align-items: center;
}
.search-navbar-container:active {
  border: solid 1px #2698ed;
  background-color: #fff;
  color: #2698ed;
  .search-navbar-icon {
    i {
      color: #2698ed;
    }
  }
}
.active-search-box {
  border: solid 1px #2698ed;
}
.search-navbar {
  width: inherit;
  padding: 14.7px 20.1px 14px 19.9px;
}
.search-navbar-end {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}
.search-navbar-icon {
  color: #383f45;
  margin-right: 10px;
  i {
    font-size: 16px;
  }
}
.active-icon {
  color: #1996f0;
}
.autocomplete {
  position: relative;
}

.search-results {
  padding: 0;
  margin: 0;
  border-radius: 4px;
  background-color: #fff;
  margin-left: 2px;
  width: 305px;
  overflow: auto;
}
.search-results.opened {
  display: block;
  position: fixed;
  max-height: 300px;
}
.search-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
  height: 30px;
  color: #383f45;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result.is-active,
.search-result:hover {
  background-color: #e8f5fe;
}
@media screen and (max-width: 1800px) {
  .search-navbar-container {
    width: 200px !important;
  }
  .search-results {
    width: 200px;
  }
  .search-navbar-icon {
    margin-right: 30px;
    position: absolute;
  }
}
@media screen and (max-width: 1600px) {
  .search-navbar-container {
    width: 150px !important;
  }
  .search-results {
    width: 150px;
  }
  .search-navbar-icon {
    margin-right: 30px;
    position: absolute;
  }
}
@media screen and (max-width: 1400px) {
  .search-navbar-container {
    width: 120px !important;
  }
  .search-results {
    width: 120px;
  }
  .search-navbar-icon {
    margin-right: 30px;
    position: absolute;
  }
}
@media screen and (max-width: 1370px) {
  .search-navbar-container {
    width: 170px !important;
  }
  .search-results {
    width: 170px;
  }
}
@media screen and (max-width: 200px) {
  .search-navbar-container {
    display: none !important;
  }
  .search-results {
    display: none !important;
  }
  .search-navbar-icon {
    display: none !important;
  }
}
</style>
