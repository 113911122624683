<template>
  <li :class="{ opened: opened }">
    <div @click="toggle" class="caption">
      <span class="expandMenu"
        ><i
          :class="{
            fas: true,
            'fa-caret-right': !opened,
            'fa-caret-down': opened
          }"
        ></i
      ></span>
      {{ title }}
    </div>
    <ul class="actionsSubMenu">
      <li
        @click="
          item.click();
          closeParent();
        "
        v-for="(item, i) in items.filter((x) => typeof x === 'object')"
        :key="i"
      >
        <span>{{ item.title }}</span>
        <span class="menuLabel">{{ item.buttonLabel || 'Run' }}</span>
      </li>
    </ul>
  </li>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'ActionMenuItem',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      opened: false
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonLabel: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    },
    closeParent: {
      type: Function
    }
  }
};
</script>

<style scoped>
li > .caption {
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.actionsSubMenu {
  display: none;
  font-size: 14px;
  list-style-type: none;
  padding-left: 0;
}
li.opened .actionsSubMenu {
  display: block;
}
.actionsSubMenu li {
  height: 48px;
  cursor: pointer;
  line-height: 48px;
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}
.actionsSubMenu li:hover {
  background: #e8f5fe;
}
.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.menuLabel {
  background: #fd5422;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 21px;
  display: none;
  margin-right: 10px;
}
.actionsSubMenu li:hover .menuLabel {
  display: block;
}
</style>
