<template>
  <div class="intentList">
    <div class="filtersWrapper">
      <div class="taxonomyFilters">
        <div class="filterEach">
          <span class="filterName">Batch</span>
          <VueMultiselect :close-on-select="false" :disabled="readOnly" @input="selectionChanged" :show-labels="false" tag-position="bottom" :searchable="false" class="batch" v-model="batches" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersBatch" :multiple="true">
            <template #option="props">
              <div style="display: flex; justify-content: space-between">
                <input type="checkbox" style="margin-right: 5px" class="selectCheckbox" :checked="isChecked(batches, props.option.text)" />
                <span>{{ props.option.text }}</span>
              </div>
            </template>
            <template #selection="{ values }"
              ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
            </template>
          </VueMultiselect>
        </div>
        <div class="filterEach">
          <span class="filterName">Status</span>
          <VueMultiselect :close-on-select="false" :disabled="readOnly" @input="selectionChanged" :show-labels="false" tag-position="bottom" :searchable="false" class="status" v-model="statuses" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersStatus" :multiple="true">
            <template #option="props">
              <div class="flex">
                <input type="checkbox" style="margin-right: 5px" class="statusCheckbox" :checked="isChecked(statuses, props.option.text)" />
                <span style="margin-top: 2px">{{ props.option.text }}</span>
              </div>
            </template>
            <template #selection="{ values }"
              ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
            </template>
          </VueMultiselect>
        </div>
        <slot name="extraFilters"></slot>
      </div>
    </div>

    <div class="replaceFiltersWrapper">
      <div class="replaceTaxonomyFilters">
        <div class="replaceFilterEach">
          <span class="replaceFilterName">Find</span>
          <div class="flex status">
            <input @focus="appStore.searchActive({ status: false })" v-model="findQueryInput" class="inputFind" type="search" placeholder="Keyword" />
          </div>
        </div>
        <div class="replaceFilterEach">
          <span class="replaceFilterName">Replace</span>
          <div class="flex status">
            <input v-if="!inputDisabled" @focus="appStore.searchActive({ status: true })" v-model="replaceQueryInput" class="inputFind" type="search" placeholder="NewWord" disabled />
            <input v-else @focus="appStore.searchActive({ status: true })" v-model="replaceQueryInput" class="inputFind" type="search" placeholder="NewWord" />
          </div>
        </div>
        <div class="findActionsOptions">
          <button v-if="!searchActive" @click="findQuery()" type="button" class="btn btn-primary findBtn">Find</button>
          <button v-else @click="findQuery()" type="button" class="btn btn-primary findBtn" disabled>Find</button>

          <button v-if="searchActive" @click="replaceQuery()" type="button" class="btn btn-cancel findBtn replaceBtn">Replace</button>
          <button disabled v-else @click="replaceQuery()" type="button" class="btn btn-cancel findBtn replaceBtn">Replace</button>
        </div>
      </div>
      <slot name="extraFilters"></slot>
    </div>

    <div v-if="queryFound" class="tableWrapper">
      <div class="cards">
        <FindReplaceCard v-for="item of this.foundQuery" :key="item.slotId" :intentId="item.slotId" :item="item" :intent="slotIntent" class="findCard" />
      </div>
    </div>

    <div class="tableWrapper">
      <div class="container-fluid tableContainer">
        <table class="slotsTable">
          <thead>
            <tr>
              <th width="20">
                <input :disabled="readOnly" type="checkbox" v-model="allslots" @click="allSlotsClick(allslots)" />
              </th>
              <th width="50%">All intents</th>
              <th>Batch</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="slot in ((!readOnly && slots) || metadata.job['job_meta_data'].allIntents).filter((s) => (!this.batches.length || this.batches.find((x) => x.value === s.batch)) && (!this.statuses.length || this.statuses.find((x) => x.value === s.status)))" :key="slot.id">
              <td>
                <input v-if="!readOnly" @change="selectionChanged" type="checkbox" v-model="slot.checked" :id="slot.id" value="1" />
                <input v-else @change="selectionChanged" :disabled="true" type="checkbox" :checked="metadata.job['payload'].config.whitelist.includes(slot.name)" :id="slot.id" value="1" />
              </td>
              <td>
                <label :for="slot.id">{{ slot.name }}</label>
              </td>
              <td>{{ slot.batch }}</td>
              <td><i :style="{ color: appStore.statusColor[slot.status] }" class="fas fa-circle"></i>{{ slot.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import VueMultiselect from 'vue-multiselect';
import { mapState } from 'pinia';
import axios from 'axios';

import _ from 'lodash';
export default {
  name: 'FindReplaceList',
  props: {
    collection: {
      type: String
    },
    slotIntent: {
      type: Array
    },
    readOnly: {
      type: Boolean
    },
    metadata: {
      type: Object
    },
    queryFound: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  async mounted() {
    this.$vs.loading();
    if (this.readOnly) {
      this.batches = this.metadata.job['job_meta_data'].batches.map((b) => {
        return { text: b, value: b, selected: false };
      });
      this.statuses = this.metadata.job['job_meta_data'].statuses.map((b) => {
        return { text: b, value: b, selected: false };
      });
    }
    const slots = await this.appStore.getAllSlots({
      collection: this.collection
    });
    this.slots = _.sortBy(slots, 'name');
    this.$vs.loading.close();

    if (this.slots)
      this.filtersBatch = _.uniq(this.slots.map((x) => x.batch))
        .map((y) => {
          return { text: y, value: y, selected: false };
        })
        .sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
    this.filtersStatus = _.uniq(this.slots.map((x) => x.status)).map((y) => {
      return { text: y, value: y, selected: false };
    });
    this.selectionChanged();
  },
  watch: {},
  methods: {
    async findQuery() {
      if (this.findQueryInput === undefined && this.$props.slotIntent.length === 0) {
        this.$message({
          message: 'No Find Value or Intents Selected',
          duration: 3000,
          showClose: true
        });
      }
      if (this.findQueryInput && this.$props.slotIntent.length === 0) {
        this.$message({
          message: 'No Intents Selected',
          duration: 3000,
          showClose: true
        });
      }
      if (!this.findQueryInput && this.$props.slotIntent.length > 0) {
        this.$message({
          message: 'No Find Value',
          duration: 3000,
          showClose: true
        });
      }

      if (this.findQueryInput && this.$props.slotIntent.length > 0) {
        this.appStore.searchActive({ status: true });

        this.$vs.loading();
        const foundQuery = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/history/search`, {
          idToken: this.appStore.getClaims.token,
          query: this.findQueryInput,
          collection: this.collection,
          slots: this.slotIntent.map((s) => {
            return { id: s.id, name: s.name, impersonate: s.impersonate };
          })
        });

        this.foundQuery = foundQuery.data;

        if (Object.keys(this.foundQuery).length === 0) {
          this.appStore.searchActive({ status: false });
          this.appStore.inputDisabled({ status: false });
          this.$props.queryFound = false;
          this.$props.queryFound = true;
          this.$message({
            message: 'No Matches',
            duration: 3000,
            showClose: true
          });
          this.$vs.loading.close();
        } else {
          this.appStore.inputDisabled({ status: true });
          this.$props.queryFound = false;
          this.$props.queryFound = true;
          this.$vs.loading.close();
        }
      }
    },
    async replaceQuery() {
      if (this.replaceQueryInput === undefined) {
        this.$message({
          message: 'No Replace Value',
          duration: 3000,
          showClose: true
        });
      }
      if (this.replaceQueryInput) {
        this.$vs.loading();
        const replaceQuery = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/history/replace`, {
          data: this.foundQuery.queryResponses,
          replacement: this.replaceQueryInput
        });
        this.replacedQuery = replaceQuery;
        this.$vs.loading.close();
        this.appStore.searchActive({ status: false });
        this.$message({
          message: 'Values Replaced',
          duration: 5000,
          showClose: true
        });
        this.appStore.inputDisabled({ status: false });

        this.close();
      }
    },
    close() {
      this.appStore.searchActive({ status: false });
      this.appStore.closeDrawer();
    },
    selectionChanged() {
      this.$emit('selectionChanged', {
        intents: this.slots.filter((s) => s.checked && (!this.batches.length || this.batches.find((x) => x.value === s.batch)) && (!this.statuses.length || this.statuses.find((x) => x.value === s.status))),
        batches: this.batches,
        statuses: this.statuses,
        allIntents: this.slots.map((s) => {
          return { status: s.status, name: s.name, batch: s.batch };
        })
      });
    },
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    allSlotsClick(val) {
      if (!val) {
        this.slots = this.slots.map((s) => {
          return { ...s, checked: true };
        });
      } else {
        this.slots = this.slots.map((s) => {
          return { ...s, checked: false };
        });
      }
      this.selectionChanged();
    }
  },
  computed: {
    ...mapState(useAppStore, ['searchActive', 'inputDisabled'])
  },
  components: {
    VueMultiselect
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      allslots: false,
      batches: [],
      slots: [],
      statuses: [],
      filtersBatch: [],
      filtersStatus: [],
      slotTest: this.$props.slotIntent,
      foundQuery: {},
      findQueryInput: '',
      replaceQueryInput: ''
    };
  },
  beforeUnmount() {
    this.slots = [];
  }
};
</script>

<style scoped>
.btn-primary {
  background-color: #fd5422 !important;
  border-color: #fd5422 !important;
}

.btn-primary:hover {
  background-color: #fff !important;
  border-color: #fd5422 !important;
  color: #000 !important;
}

.btn-primary:disabled {
  background-color: #717e89 !important;
  border-color: #717e89 !important;
  color: #000 !important;
}

.btn-cancel:disabled:hover {
  cursor: default;
}

.btn-cancel {
  background-color: #fd5422 !important;
  border-color: #fd5422 !important;
}

.btn-cancel:hover {
  background-color: #fff !important;
  border-color: #fd5422 !important;
  color: #000 !important;
}

.btn-cancel:disabled {
  background-color: #717e89 !important;
  border-color: #717e89 !important;
  color: #000 !important ;
}

.btn-cancel:disabled:hover {
  cursor: default;
}

.tableWrapper {
  overflow-x: hidden;
  overflow-y: auto;
}
.cards {
  width: 100%;
}
.findCard {
  padding-right: 15px;
  padding-top: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.multiselect.batch {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}

.el-message .el-message--info .is-closable {
  z-index: 9999;
}
.multiselect.status {
  width: 300px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}

.multiselect.notes {
  width: 190px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  /* height: 40px; */
  height: max-content;
}

#close-button {
  height: 27px;
  width: 27px;
  position: absolute;
  right: 43px;
  top: 25px;
  cursor: pointer;
  background-color: #f6f8fa;
  padding: 5px;
  border-radius: 50%;
  z-index: 1000;
}
.close-color {
  stroke: #383f45;
  stroke-width: 5px;
}

.drwr-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.drwr {
  background: #fff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 945px;
  height: calc(100% - 60px);
  top: 60px;
  right: 0;
  position: absolute;
}

.drawer-body {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.drwr-fade-enter-active,
.drwr-fade-leave-active {
  transition: transform 0.5s;
}

.drwr-fade-enter,
.drwr-fade-leave-to {
  transform: translateX(100%);
}

.drwr-fade-enter-to,
.drwr-fade-leave {
  transform: translateX(0);
}

.batch * .multiselect__tag,
.status * .multiselect__tag,
.notes * .multiselect__tag {
  padding: 10px 30px 10px 11px !important;
}

.batch * .multiselect__tag-icon,
.status * .multiselect__tag-icon,
.notes * .multiselect__tag-icon {
  padding-top: 15px !important;
  padding-right: 5px !important;
  line-height: 1px !important;
}

.multiselect__tag-icon:after {
  color: #383f45 !important;
}
.multiselect__tag-icon:after:hover {
  background-color: #8e9ba8 !important;
  color: white !important;
}

.batchCheckbox,
.selectCheckbox {
  padding-right: 10px !important;
  margin-top: 1px;
}
.statusCheckbox {
  margin-right: 12px !important;
  margin-top: 3px !important;
}

.batch * .multiselect__tag,
.notes .multiselect__tag {
  border-radius: 2px !important;
  background-color: #edf0f4 !important;
  font-size: 14px !important;
  color: #383f45 !important;
  height: 30px !important;
  padding: 8px 30px 8px 8px !important;
}

.status * .multiselect__tag {
  border-radius: 2px !important;
  background-color: #edf0f4 !important;
  font-size: 14px !important;
  color: #383f45 !important;
  height: 30px !important;
  padding: 8px 30px 8px 8px !important;
}
.multiselect__tags {
  caret-color: transparent;
}
.notes * .multiselect__tags {
  border: 1px solid #8e9ba7;
  caret-color: transparent;
}

.multiselect__tag-icon:hover {
  background: none !important;
}

.multiselect.batch,
.multiselect.status,
.multiselect.notes {
  line-height: 5% !important;
}

.filterName {
  padding-right: 10px;
  margin-top: 9px;
  white-space: nowrap;
}
.filterEach input[type='number'] {
  background-color: #fff;
  border: 1px solid #8e9ba7;
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  width: 40px;
}

.taxonomyFilters {
  height: 100%;
  width: 80%;
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
}

.filterEach {
  display: flex;
  padding-right: 30px;
  min-height: 47px;
}

.multiselect__option--selected {
  background: none;
  font-weight: normal !important;
}

.multiselect__option--highlight {
  background: none !important;
  color: inherit !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: none;
  color: inherit !important;
}

.filterSelection.batch > .vs__dropdown-toggle {
  padding: 5px 5px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.filterSelection.status > .vs__dropdown-toggle {
  padding: 5px 7px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.filterSelection.batch .vs__selected {
  width: 58px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
}

.filterSelection.status .vs__selected {
  width: 150px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
  display: flex;
  justify-content: space-between;
}

.filterSelection.batch .vs__selected .vs__deselect {
  margin-left: 50% !important;
}

.intentList {
  max-height: 100%;
}

.filtersWrapper {
  height: auto;
  padding: 5px 265px 5px 40px;
  background-color: #edf0f4;
  /* min-height: 60px; */
}

.tableWrapper {
  height: calc(100% - 125px);
  position: relative;
}

.tableContainer {
  padding: 0 10px !important;
}

.filterName {
  padding-right: 10px;
  margin-top: 9px;
  white-space: nowrap;
}
.filterEach input[type='number'] {
  background-color: #fff;
  border: 1px solid #8e9ba7;
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  width: 40px;
}

.taxonomyFilters {
  height: 100%;
  width: 80%;
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
}

/* SEARCH AND REPLACE STYLE */
/* SEARCH AND REPLACE STYLE */
/* SEARCH AND REPLACE STYLE */
/* SEARCH AND REPLACE STYLE */

.replaceBtn {
  width: 100px;
  min-height: 40px;
  margin-right: 10px;
}

.findBtn {
  width: 100px;
  min-height: 40px;
  margin-right: 10px;
}

.findActionsOptions {
  display: flex;
  height: 50px;
  width: 160px;
  align-items: center;
  margin: 0 7px 0 0;
  padding: 10px 10px;
  background-color: #383f45;
  width: 100%;
  text-align: center;
  z-index: 100;
}

.inputFind {
  min-height: 40px;
  width: 210px;
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
}

.inputFind::placeholder {
  color: black;
}

.inputFindDisabled {
  min-height: 40px;
  width: 210px;
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: rgba(255, 255, 255, 0.774);
  font-size: 14px;
}

.inputFindDisabled::placeholder {
  color: black;
}

.replaceFilterName {
  padding-right: 10px;
  /* margin-top: 9px; */
  color: white;
  white-space: nowrap;
}
.replaceFilterEach input[type='number'] {
  background-color: #fff;
  border: 1px solid #8e9ba7;
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  width: 40px;
}

.replaceTaxonomyFilters {
  height: 100%;
  width: 80%;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
}

.replaceFilterEach {
  align-items: center;
  display: flex;
  padding-right: 30px;
  min-height: 47px;
}

.multiselect__option--selected {
  background: none;
  font-weight: normal !important;
}

.multiselect__option--highlight {
  background: none !important;
  color: inherit !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: none;
  color: inherit !important;
}

.replaceFilterSelection.batch > .vs__dropdown-toggle {
  padding: 5px 5px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.replaceFilterSelection.status > .vs__dropdown-toggle {
  padding: 5px 7px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.replaceFilterSelection.batch .vs__selected {
  width: 58px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
}

.replaceFilterSelection.status .vs__selected {
  width: 150px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
  display: flex;
  justify-content: space-between;
}

.replaceFilterSelection.batch .vs__selected .vs__deselect {
  margin-left: 50% !important;
}

.replaceIntentList {
  max-height: 100%;
}

.replaceFiltersWrapper {
  height: auto;
  padding: 0px 40px 0px 40px;
  background-color: #363f46;
  /* min-height: 60px; */
}

.replaceTableWrapper {
  height: calc(100% - 125px);
  position: relative;
}

.replaceFilterName {
  padding-right: 10px;
  /* margin-top: 9px; */
  white-space: nowrap;
}
.replaceFilterEach input[type='number'] {
  background-color: #fff;
  border: 1px solid #8e9ba7;
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  width: 40px;
}

.replaceTaxonomyFilters {
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
}
</style>
