<template>
  <div style="background: white; width: 100vw; height: 100vh"></div>
</template>

<script>
export default {
  mounted() {
    this.$vs.loading();
  }
};
</script>
