<template>
  <div @mouseover="changeCursor" class="connector-point">
    <div v-if="!isConnected && show" class="outer-ellipse">
      <div class="inner-circle"></div>
    </div>
    <div
      v-if="isConnected"
      :class="[
        {
          connecting: !isConnected,
          connected: isConnected
        },
        {
          source: connectorType === 'source',
          target: connectorType === 'target'
        },
        {
          'source-selected-circle': isConnected && connectorType === 'source',
          'target-selected-circle': isConnected && connectorType === 'target'
        },
        {
          transparent: !show && optionIndex !== false
        }
      ]"
      :style="wrapperStyle"
    >
      <template v-if="connectorType === 'source'">
        <svg v-if="isRightConnected" class="connectorLineSvg" :id="`${id}-connector-point-right-${optionIndex}`" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px">
          <line class="connectorLine" x1="4" y1="4" x2="15" y2="4" stroke="rgb(142, 155, 167)" />
        </svg>

        <svg v-if="isLeftConnected" class="connectorLineSvg leftSide" :id="`${id}-connector-point-left-${optionIndex}`" xmlns="http://www.w3.org/2000/svg" width="25px" height="25px">
          <line class="connectorLine" x1="4" y1="4" x2="15" y2="4" stroke="rgb(142, 155, 167)" />
        </svg>
      </template>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';

export default {
  name: 'ConnectorPoint',
  props: {
    id: {},
    currentOptionConnectedPosition: {},
    optionIndex: {
      default: false
    },
    position: {
      type: String,
      default: 'left'
    },
    wrapperStyle: {
      type: Object,
      required: false
    },
    isConnected: {
      type: Boolean,
      default: false,
      required: false
    },
    connectorType: {
      type: String,
      required: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeCursor(e) {
      if (e?.target?.style) {
        e.target.style.cursor = 'crosshair';
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['stateRecomputeCounter']),
    isLeftConnected() {
      this.stateRecomputeCounter;
      if (this.currentOptionConnectedPosition === 'left' && this.position === 'left') return true;
      else return false;
    },
    isRightConnected() {
      this.stateRecomputeCounter;
      if (this.currentOptionConnectedPosition === 'right' && this.position === 'right') return true;
      else return false;
    }
  }
};
</script>

<style lang="scss" scoped>
.transparent {
  background: transparent !important;
}

.connectorLineSvg {
  pointer-events: none;
  z-index: 100;
}
.connectorLine {
  z-index: 100;
  stroke-width: 1.5;
  pointer-events: none;
}
.connectorLineSvg.leftSide {
  transform: translate(-10px, 0px) !important;
}

.connector-point {
  cursor: crosshair;
}

.outer-ellipse {
  width: 10px;
  height: 10px;
  background-color: rgba(#1996f0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

  .inner-circle {
    width: 4px;
    height: 4px;
    background-color: rgba(#1996f0, 0.5);
    border-radius: 100%;
  }

  // .inner-circle:hover {
  //   background-color: rgba(#1996f0, 0.7);
  // }
}

.selected-circle {
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.source-selected-circle {
  width: 8px;
  height: 8px;
  background-color: #8e9ba8;
  border-radius: 100%;
}

.target-selected-circle {
  width: 8px;
  height: 8px;
  background-color: #8e9ba8;
  border-radius: 100%;
}

// .outer-ellipse:hover {
//   background-color: rgba(#1996f0, 0.5);
// }
</style>
