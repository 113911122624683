<template id="timeline-item-template">
  <li class="timeline-item">
    <div class="timeline-badge"><i v-if="item.imageUri" class="fas fa-camera"></i><i v-else class="fas fa-image"></i></div>

    <div @click="window.open(item.postback)" v-if="item.imageUri" class="timeline-panel">
      <!-- <div class="timeline-heading">
                          <h4 class="timeline-title">{{ item.title }}</h4>
                          <div class="timeline-panel-controls">
                          </div>
                      </div> -->
      <img class="carouselImage" :src="item.imageUri" width="220px" height="140px" />

      <!-- <div class="timeline-body">{{ itCem.body }}</div> -->
    </div>

    <div class="noImage" v-else>
      <Carousel6 :dots="false" :slides="[item]"> </Carousel6>
    </div>
    <br />
    <div v-if="item.postback" class="imageUrl">
      <span @click="openPage(item.postback)" class="imageUrlLink"> {{ formatPostback }}</span>
    </div>
  </li>
</template>

<script>
export default {
  name: 'CarouselItem',
  props: {
    item: {}
  },
  computed: {
    formatPostback() {
      return this.truncate(this.item.postback, 120, '');
    }
  },
  methods: {
    openPage(url) {
      window.open(url, '_blank');
    },
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  }
};
</script>

<style lang="scss" scoped>
body {
  font-family: 'Libre Franklin', sans-serif;
}

.carouselImage {
  transition: transform 0.3s;
  transition-delay: 1s;
  cursor: zoom-in;
  z-index: 400;
}
.carouselImage:hover {
  transform: scale(1.6); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.imageUrl {
  font-size: 12px;
  color: #1996f0;
  width: 90%;
  padding-left: 55px;
  padding-right: 10px;
  z-index: 234324234;
  position: relative;
}

.imageUrlLink {
  cursor: grab;
  word-wrap: break-word;
}

.noImage {
  height: 150px;
}

#timeline-header {
  font-size: 26px;
}

.timeline {
  list-style: none;
  padding: 20px 0 5px;
  position: relative;
  margin-bottom: 1px;
  &:before {
    background-color: #eee;
    bottom: 0;
    content: ' ';
    left: 50px;
    margin-left: -31px;
    position: absolute;
    top: 0;
    width: 3px;
  }
  > li {
    margin-bottom: 20px;
    position: relative;
    &:before,
    &:after {
      content: ' ';
      display: table;
    }
    &:after {
      clear: both;
    }
    > .timeline-panel {
      border-radius: 2px;
      border: 1px solid #d4d4d4;
      box-shadow: 0 1px 2px rgba(100, 100, 100, 0.2);
      margin-left: 75px;
      padding: 5px;
      position: relative;
      width: 230px;
      height: 150px;
      .timeline-heading {
        .timeline-panel-controls {
          position: absolute;
          right: 8px;
          top: 5px;
          .timestamp {
            display: inline-block;
          }
          .controls {
            display: inline-block;
            padding-right: 5px;
            border-right: 1px solid #aaa;
            a {
              color: #999;
              font-size: 11px;
              padding: 0 5px;
              &:hover {
                color: #333;
                text-decoration: none;
                cursor: pointer;
              }
            }
          }
          .controls + .timestamp {
            padding-left: 5px;
          }
        }
      }
    }
    .timeline-badge {
      background-color: #999;
      border-radius: 50%;
      color: #fff;
      font-size: 1.4em;
      height: 30px;
      left: 50px;
      line-height: 32px;
      margin-left: -39px;
      position: absolute;
      text-align: center;
      top: 16px;
      width: 30px;
      z-index: 0;
    }
    // .timeline-badge + .timeline-panel {
    //   // &:before {
    //   //     border-bottom: 15px solid transparent;
    //   //     border-left: 0 solid #ccc;
    //   //     border-right: 15px solid #ccc;
    //   //     border-top: 15px solid transparent;
    //   //     content: " ";
    //   //     display: inline-block;
    //   //     position: absolute;
    //   //     left: -15px;
    //   //     right: auto;
    //   //     top: 26px;
    //   // }
    //   // &:after {
    //   //     border-bottom: 14px solid transparent;
    //   //     border-left: 0 solid #fff;
    //   //     border-right: 14px solid #fff;
    //   //     border-top: 14px solid transparent;
    //   //     content: " ";
    //   //     display: inline-block;
    //   //     position: absolute;
    //   //     left: -14px;
    //   //     right: auto;
    //   //     top: 27px;
    //   // }
    // }
  }
}

.timeline-badge {
  &.primary {
    background-color: #2e6da4 !important;
  }
  &.success {
    background-color: #3f903f !important;
  }
  &.warning {
    background-color: #f0ad4e !important;
  }
  &.danger {
    background-color: #d9534f !important;
  }
  &.info {
    background-color: #5bc0de !important;
  }
}

.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body {
  > p,
  > ul {
    margin-bottom: 0;
  }
  > p + p {
    margin-top: 5px;
  }
}

.copy {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaa;
  font-size: 11px;
  > * {
    color: #444;
  }
}
</style>
