<template>
  <div class="print-wrapper">
    <div class="title-wrapper">{{ this.flowName }}</div>
    <div class="date-wrapper">{{ this.timestamp }}</div>
    <div id="capture" class="viewer">
      <div ref="chpWrapper" class="viewer2" id="zoomID">
        <div id="printZone" class="printZone">
          <template v-for="(node, index) in getScene.nodes">
            <container v-if="showContainer(node)" :collection="collection" v-model="getScene.nodes[index]" :key="node.id" :index="index" :ref="node.id"> </container>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
/* eslint-disable */
//
import { nextTick } from 'vue';
import { setTimeout } from 'timers';
import { mapActions, mapState } from 'pinia';
import ContainerComponent from '../Canvas/3.0/ContainerComponents/ContainerComponent.vue';
import { getElementPosition } from '../../Utilities/position';
import draggable from 'vuedraggable';
import * as d3 from 'd3';
import cloneDeep from 'lodash/cloneDeep';
import { ElLoading } from 'element-plus';
import moment from 'moment/moment';
import { useVfm } from 'vue-final-modal';
export default {
  name: 'PrintCanvas',
  components: {
    container: ContainerComponent,
    draggable
  },
  props: {
    mode: {
      type: String
    },
    data: {
      type: Object,
      required: false
    },
    collection: {
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    flowName: {
      type: String,
      default: ''
    },
    actions: {
      required: false,
      type: Object
    },
    slots: {
      required: false,
      type: Array
    },
    slotInfo: {
      required: false,
      type: Array
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      printZone: [],
      firstPageBreakPoint: 1340,
      differentPageBreakPointDiffs: 1405,
      printNodes: [],
      viewer: [],
      flattenedNodeStructure: [],
      timestamp: moment().format('YYYY-MM-DD_hh-mm_A'),
      margin: {
        left: 100,
        right: 50,
        top: 50,
        bottom: 50
      },
      language: useAppStore().language ? useAppStore().language : 'gb',
      canvasSelected: false,
      svg: null,
      g: null,
      zoom: 1,
      links: [],
      unModifiedLinks: [],
      action: {
        linking: false,
        dragging: false,
        scrolling: false,
        selected: 0,
        panning: {
          mouse: false,
          space: false
        }
      },
      mouse: {
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0
      },
      rootDivOffset: {
        top: 0,
        left: 0
      },
      loadEdgeTreeTimeout: null,
      loadRouterTimeout: null
    };
  },
  computed: {
    ...mapState(useAppStore, ['getScene', 'getContainer', 'componentId', 'getLogos', 'stateRecomputeCounter', 'getListofLanguages', 'getClientStatus', 'getClientEditAccess', 'getAlteredNodes', 'usersConnected', 'containerComponents', 'getComponentList', 'getScene', 'selectedContainers']),
    isAdmin() {
      return !!this.appStore.getClaims.admin;
    }
  },
  beforeMount() {
    ElLoading.service();
    this.appStore.setComponentId(false);
    this.appStore.setActiveContainer(null);
    this.appStore.SET_SELECTED_CONTAINERS([]);

    let links = cloneDeep(this.getScene.links);
    let unModifiedLinks = cloneDeep(this.getScene.links);
    links.map((link) => {
      // Convert all our links because decision components link is from a component not from a container
      let fromId = '',
        toId = '';
      const isContainerFrom = this.appStore.getContainerListFromContainers.find((c) => c.id === link.from);
      const isComponentFrom = this.appStore.getComponentListFromComponents.find((c) => c.id === link.from);
      if (isContainerFrom !== undefined) {
        fromId = isContainerFrom.id;
      } else if (isComponentFrom !== undefined) {
        fromId = isComponentFrom.parentId;
      }
      const isContainerTo = this.appStore.getContainerListFromContainers.find((c) => c.id === link.to);
      const isComponentTo = this.appStore.getComponentListFromComponents.find((c) => c.id === link.to);
      if (isContainerTo !== undefined) {
        toId = isContainerTo.id;
      } else if (isComponentTo !== undefined) {
        toId = isComponentTo.parentId;
      }
      link.from = fromId;
      link.to = toId;
      return link;
    });
    links = links.filter(
      (
        value,
        index,
        self // This code block is removing looped links
      ) => index === self.findIndex((t) => t.to === value.to)
    );
    unModifiedLinks = unModifiedLinks.filter(
      (
        value,
        index,
        self // This code block is removing looped links
      ) => index === self.findIndex((t) => t.to === value.to)
    );
    this.links = links;
    this.unModifiedLinks = unModifiedLinks;
    this.printNodes = this.getNodesToPrint();
    this.appStore.SET_SLOTS({ collection: this.collection });
  },
  mounted() {
    nextTick(() => {
      this.loadEdgeTreeTimeout = setTimeout(() => {
        this.showEdgeTree();
      }, 500);
    });
    ElLoading.service().close();
  },

  methods: {
    ...mapActions(useAppStore, ['addNodeSync', 'updateScene', 'setLanguage']),
    containerContainsDecision(containerId, containerComponents) {
      return containerComponents.some((component) => component.type === 'decision');
    },
    showContainer(node) {
      if (node.id === 'start-id') return true;
      if (node.type === 'Hidden') return false;
      if (this.links.find((link) => link.to === node.id) === undefined && this.links.find((link) => link.from === node.id) === undefined) return false;
      // if standalone container with no links coming to it, do not display
      else return true;
    },
    autoClose() {
      // ignored
    },
    closePage(ignore) {
      if (this.getClientStatus || this.getClientEditAccess) {
        let r = confirm('Are you sure you want to navigate away from this page?');
        if (r === false) {
          return;
        } else {
          let id = this.$route.params.id;
          this.$router.push('/changeSlot');
          this.loadRouterTimeout = setTimeout(() => {
            this.$router.replace({
              path: `/agents/${id}?initial=flows`
            });
          }, 500);
        }
      }
    },
    showEdgeTree() {
      nextTick(() => {
        return this.edges();
      });
    },
    getFlowNodes() {
      // We are getting the nodes but also we are removing any containers with no links
      let nodes = cloneDeep(this.getScene.nodes);
      nodes.map((n) => {
        n.hasLink = this.links.find((x) => x?.from === n.id || x?.to === n.id) || false;
        return n;
      });
      return nodes.filter((item) => item.hasLink);
    },
    isContainerPartOfStartPath(containerId) {
      // Lets keep this function for now
      let links = this.links;
      let startNode = null;
      let containers = [];
      let isPartOfStartPath = (containerId) => {
        containers.push(containerId);
        for (let i = 0; i < links.length; i++) {
          if (links[i].to === containerId) {
            if (links[i].from === 'start-id') {
              startNode = links[i];
            } else {
              isPartOfStartPath(links[i].from);
            }
          }
        }
        return startNode;
      };
      return isPartOfStartPath(containerId);
    },
    getNodesToPrint() {
      return this.getFlowNodes()
        .map((fn) => {
          if (fn.id !== 'start-id') {
            return fn;
          } else {
            return fn;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined);
    },
    getFlowContainers() {
      const containers = this.getFlowNodes().filter((x) => x.type === 'container');
      const linkedContainers = containers.map((c) => {
        c.components = this.containerComponents(c.id);
        if (this.links.find((link) => link.from === c.id || link.to === c.id)) {
          return c;
        }
      });
      return linkedContainers.filter((lc) => lc);
    },
    getStartComponent() {
      let startComponent = this.getScene.nodes.find((x) => x.id === 'start-id');
      let startLink = this.getScene.links.filter((link) => {
        return link.from === 'start-id';
      });
      if (startLink.length > 0) {
        startComponent.to = startLink[0].to;
        startComponent.linked = true;
      }
      return startComponent;
    },
    getContainerRoutes(id, containerComponents) {
      let connectedContainers = [];
      if (this.containerContainsDecision(id, containerComponents)) {
        const decisionComponent = containerComponents.find((component) => component.type === 'decision');
        connectedContainers = this.unModifiedLinks.filter((link) => {
          return link.from === decisionComponent.id;
        });
      } else {
        connectedContainers = this.links.filter((link) => {
          return link.from === id;
        });
      }
      return connectedContainers.map((x) => x.to);
    },
    flowTree() {
      let links = this.links;

      const flowStartNode = this.getStartComponent();

      let flowContainers = this.getFlowContainers().map((x, i) => {
        return {
          name: x.id,
          index: i,
          components: x.components
        };
      });
      let map = {},
        flowStartNodeChildren = [],
        i;
      let excludeChildContainers = [];

      for (i = 0; i < flowContainers.length; i += 1) {
        map[flowContainers[i].name] = i;
        flowContainers[i].children = [];
      }

      let link = null;
      let containersToTrackForIsBranched = [];
      let isBranchedFromDecision = (containerId) => {
        if (!containersToTrackForIsBranched.includes(containerId)) {
          for (let i = 0; i < links.length; i++) {
            if (links[i].to === containerId) {
              containersToTrackForIsBranched.push(containerId);
              const containerComponents = this.containerComponents(links[i].from);
              if (containerComponents.some((component) => component.type === 'decision')) {
                link = links[i];
              } else {
                isBranchedFromDecision(links[i].from);
              }
            }
          }
          return link;
        }
      };
      let containersThatWentThrough = [];
      flowContainers.forEach((node) => {
        const addToContainer = (node) => {
          containersThatWentThrough.push(node.name);
          let updatedNode = node;
          const connectedNodes = this.getContainerRoutes(node.name, node.components);
          const isUnderDecision = isBranchedFromDecision(node.name);
          if (connectedNodes.length > 0 && (isUnderDecision || this.containerContainsDecision(node.name, node.components))) {
            // Lets only branch out if its a decision component or if any of its parents are a decision component
            const childNodesList = flowContainers.filter((x) => connectedNodes.includes(x.name));
            let childNodes = [];
            childNodesList.forEach((childNode) => {
              if (!containersThatWentThrough.includes(childNode.name)) {
                // If a child container is already a parent then we have a LOOP!! do not push it
                childNodes.push(addToContainer(childNode));
              }
            });

            excludeChildContainers.push(...connectedNodes);

            let nodeChildren = node.children;
            nodeChildren.push(...childNodes);
            updatedNode = {
              name: node.name,
              children: nodeChildren
            };
            if (node.index) {
              updatedNode.index = node.index;
            }
          }

          return updatedNode;
        };
        if (!excludeChildContainers.includes(node.name)) {
          addToContainer(node);
          flowStartNodeChildren.push(node);
        }
      });

      const flowData = {
        name: flowStartNode.id,
        children: flowStartNodeChildren
      };
      return flowData;
    },
    getNodeRect(id) {
      const selectedNode = document.getElementById(`${id}`);
      const selectedNodeRect = getElementPosition(undefined, selectedNode);
      return {
        x: selectedNodeRect?.x,
        y: selectedNodeRect?.y,
        width: this.$refs[id][0].$el.offsetWidth,
        height: this.$refs[id][0].$el.offsetHeight
      };
    },
    calculateHeights(nodes, targetNode) {
      let totalHeight = 0;
      const startNode = this.getNodeRect('start-id');
      if (targetNode.index === 0) {
        return startNode.height;
      }
      nodes.forEach((node) => {
        if (node.index < targetNode.index) {
          const nodeProps = this.getNodeRect(node.name);
          totalHeight += nodeProps.height;
        }
      });
      if (targetNode.index === 1) {
        totalHeight += startNode.height;
      }
      return totalHeight;
    },
    findPageNumber(newSpace, start) {
      // Returns page number of the node on print view
      // 100 is the max page number currently, but doesn't mean we loop 100 times. as soon as page is found loop breaks so we never go to 100 unless there are 100 pages
      for (let i = start; i < 100; i++) {
        let pageEnd = this.firstPageBreakPoint + this.differentPageBreakPointDiffs * i;
        if (pageEnd % newSpace === pageEnd) {
          // Means its bigger than this page so increase i and go back to loop
          this.findPageNumber(newSpace, start + 1);
        } else {
          return i + 1;
        }
      }
    },
    calculateProps(node_) {
      const node = this.getNodeRect(node_.name);
      const totalHeightOfPreviousContainers = this.calculateHeights(this.flattenedNodeStructure, node_);
      let previousContainer = this.getNode(node_.index - 1, this.flattenedNodeStructure);
      const heightOfPreviousContainer = this.getNodeRect(previousContainer.name).height;
      let spacingOfPreviousContainer = 0;
      let extraSpacing = 0;
      let spacing = 0;
      if (totalHeightOfPreviousContainers - node.height < 0) {
        spacing = node.height;
      } else {
        spacing = totalHeightOfPreviousContainers;
      }
      if (totalHeightOfPreviousContainers - node.height - heightOfPreviousContainer < 0) {
        spacingOfPreviousContainer = heightOfPreviousContainer;
      } else {
        spacingOfPreviousContainer = spacing - heightOfPreviousContainer;
      }
      let currentY = spacing - node.height / 2;
      let previousY = spacingOfPreviousContainer + heightOfPreviousContainer / 2;
      let previousY_ = spacingOfPreviousContainer - heightOfPreviousContainer / 2;
      if (previousY > currentY || previousY_ > currentY) {
        //Overlapping
        extraSpacing = previousY - currentY;
      }
      let newSpace = spacing + totalHeightOfPreviousContainers + node.height / 2; // this is where the DOT will be placed on the canvas
      let addedSpaceForPageBreak = 0;
      return {
        nodeProps: node,
        totalHeightOfPreviousContainers,
        heightOfPreviousContainer,
        spacingOfPreviousContainer,
        currentSpacing: spacing,
        previousNodeId: node_.index - 1 === -1 ? 'start-id' : node_.index - 1,
        extraSpacing,
        newSpace: newSpace,
        addedSpaceForPageBreak
      };
    },
    getNode(index, nodes) {
      if (index === -1) {
        return { name: 'start-id' };
      }
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].index === index) {
          return nodes[i];
        }
      }
    },
    async edges() {
      // Basically logic below here is that we first flatten the nodes as its easier to do operations, otherwise it will require multiple recursive functions that will cause Maximum stack issues
      // Once we are done, we unflatten it with all the operations done so it is ready for the tree.
      let nodesData = this.flowTree();
      let flattened = [];
      const flattenNodes = ({ nodes, isChild, parentId }) => {
        for (let i = 0; i < nodes.length; i++) {
          if (isChild) {
            nodes[i].isChild = isChild;
            nodes[i].parentId = parentId;
          }
          flattened.push(nodes[i]);
          if (nodes[i].children && nodes[i].children.length > 0) {
            const children = cloneDeep(nodes[i].children);
            nodes[i].children = [];
            flattenNodes({
              nodes: children,
              isChild: true,
              parentId: nodes[i].name
            });
          }
        }
      };
      const modifyNodes = (nodes) => {
        return nodes.map((nd) => {
          nd = Object.assign(nd, this.calculateProps(nd));
          return nd;
        });
      };
      if (nodesData.children && nodesData.children.length > 0) {
        flattenNodes({ nodes: cloneDeep(nodesData.children) });
      }
      if (flattened && flattened.length > 0) {
        this.flattenedNodeStructure = flattened;
        modifyNodes(flattened);
      }
      let copiedFlattenedNodes = cloneDeep(this.flattenedNodeStructure);

      // indexes each item by id
      const indexedNodes = copiedFlattenedNodes.reduce(function (result, item) {
        result[item.name] = item;
        return result;
      }, {});

      let unflattenedNodes = copiedFlattenedNodes.filter(function (item) {
        let parent = indexedNodes[item.parentId];

        if (parent) {
          parent.children = (parent.children || []).concat(item);
        }
        return !parent;
      });
      //TODO change this with flattenedNodes without throwing page timeout, as currently child nodes are not considered for overlap
      const fixOverlapping = (height, index) => {
        for (let i = index; i < unflattenedNodes.length; i++) {
          if (unflattenedNodes[i].name !== 'start-id') {
            if (unflattenedNodes[i].newSpace < this.firstPageBreakPoint) {
              if (unflattenedNodes[i].newSpace + unflattenedNodes[i].nodeProps.height / 2 > this.firstPageBreakPoint) {
                // on Page 1 and overlaps to 2nd page
                return {
                  height: unflattenedNodes[i].nodeProps.height,
                  index: unflattenedNodes[i].index
                };
              } else {
                // on Page 1 but no overlap
              }
            } else {
              if (unflattenedNodes[i].newSpace - unflattenedNodes[i].nodeProps.height / 2 < this.firstPageBreakPoint) {
                return {
                  height: unflattenedNodes[i].nodeProps.height,
                  index: unflattenedNodes[i].index
                };
              } else {
                let pageNumberOfContainer = this.findPageNumber(unflattenedNodes[i].newSpace, 1);
                let endPixelOfPage = this.firstPageBreakPoint + this.differentPageBreakPointDiffs * (pageNumberOfContainer - 1);
                let startPixelOfPage = endPixelOfPage - 1405;
                if (unflattenedNodes[i].newSpace - unflattenedNodes[i].nodeProps.height / 2 < startPixelOfPage) {
                  //container overlaps from top of the page
                  return {
                    height: unflattenedNodes[i].nodeProps.height,
                    index: unflattenedNodes[i].index
                  };
                } else if (unflattenedNodes[i].newSpace + unflattenedNodes[i].nodeProps.height / 2 > endPixelOfPage) {
                  return {
                    height: unflattenedNodes[i].nodeProps.height,
                    index: unflattenedNodes[i].index
                  };
                } else {
                  //NO OVERLAPPING
                }
              }
            }
          }
        }
        return null;
      };
      //let isOverlapping = fixOverlapping(0, 0);
      /*while (isOverlapping !== null) { //TODO commenting this for 3.3
        for (let i = isOverlapping.index; i < unflattenedNodes.length; i++) {
          unflattenedNodes[i].newSpace += isOverlapping.height;
        }
        isOverlapping = fixOverlapping(isOverlapping.height, isOverlapping.index);
      }*/
      let treeNodes = {
        name: 'start-id',
        children: cloneDeep(unflattenedNodes)
      };
      const options = {
        marginLeft: this.margin.left,
        marginRight: this.margin.right,
        marginTop: this.margin.top,
        marginBottom: this.margin.bottom
      };
      const { indentSpacing = 50, lineSpacing = 20, minHeight = 600, boxSize = 9.5, marginLeft = Math.round(boxSize / 2) + 1, marginRight = 120, marginTop = 10, marginBottom = 10, width = 400, defaultNodeHeight = 50 } = options;
      let root = d3.hierarchy(treeNodes);

      root.x = 0;
      root.y = 0;
      let index = 0;
      root.eachBefore((d) => {
        d.index = index++;
      });

      index = -1;
      root.eachBefore((d) => {
        if (d.data.name === 'start-id') {
          const node = this.getNodeRect(d.data.name);
          d.y = 50;
          d.x = d.depth * indentSpacing;
          d.height = node.height;
        } else {
          let spacingOfPreviousContainer = 0;
          let currentIndex = d.data.index;
          let previousContainer = this.getNode(currentIndex - 1, treeNodes.children);
          if (previousContainer) {
            if (previousContainer.extraSpacing && previousContainer.extraSpacing > 0) {
              spacingOfPreviousContainer = previousContainer.extraSpacing;
            }
          }

          //d.y = d.data.currentSpacing + ((d.data.extraSpacing + spacingOfPreviousContainer) * 1.5); // another way of calculating d.y but below is better but lets keep it
          d.y = d.data.newSpace;

          d.x = d.depth * indentSpacing;
          d.height = d.data.nodeProps.height;
        }
      });
      /*      root.eachBefore((d) => { //TODO lets keep this logic this can come in handy..
        if(d.data.name !== 'start-id'){
          let prevNode = root.descendants().filter(node => node.data.index === d.data.index - 1);
          if(prevNode && prevNode.length > 0){
            if(prevNode[0].y > d.y){
              d.y += prevNode[0].y / 2
            }
            if(d.y - prevNode[0].y < 80){
              d.y += 75;
            }
          }
        }
      });*/
      const nodes = root.descendants();
      const links = root.links();
      const svg = d3
        .select('#printZone')
        .append('svg')
        .attr('viewBox', [-marginLeft, -marginTop, width, Math.max(minHeight, defaultNodeHeight + marginTop + marginBottom)])
        .attr('font-family', 'sans-serif')
        .attr('font-size', 10)
        .style('overflow', 'visible');
      const link = svg
        .append('g')
        .attr('fill', 'none')
        .attr('stroke', '#8e9ba7')
        .attr('stroke-width', '2px')
        .selectAll('path')
        .data(links)
        .join('path')
        .attr('d', (d) => {
          return `
              M${d.source.x},${d.source.y}
              V${d.target.y}
              h${d.target.x}
            `;
        });

      const node = svg
        .append('g')
        .selectAll('g')
        .data(nodes)
        .join('g')
        .attr('transform', (d) => `translate(0, ${d.y})`);

      node
        .append('circle')
        .attr('cx', (d) => {
          return d.depth * defaultNodeHeight;
        })
        .attr('r', 2.5)
        .attr('fill', (d) => (d.children ? null : '#999'))
        .attr('id', (d) => `connector-${d.data.name}`);
      this.appStore.recomputeNodePositions({
        nodes: this.recomputeNodePositions()
      });

      return svg.node();
    },
    recomputeNodePositions() {
      const x = this.getNodesToPrint().map((d) => {
        const connectorElement = document.getElementById(`connector-${d.id}`);
        const nodeRect = this.getNodeRect(`${d.id}`);
        let connectorElement_x, connectorElement_y;
        if (connectorElement !== null) {
          const connectorElementPosition = getElementPosition(undefined, connectorElement);
          if (d.id === 'start-id') {
            connectorElement_x = Math.round(connectorElementPosition?.x - nodeRect?.width / 2 + 2.5);
            connectorElement_y = Math.round(connectorElementPosition?.y - nodeRect?.height + 25);
          } else {
            connectorElement_x = Math.round(connectorElementPosition?.x + 5);
            connectorElement_y = Math.round(connectorElementPosition?.y - (nodeRect?.height - 20) / 2);
          }
        }

        return {
          ...d,
          x: connectorElement_x || d.x,
          y: connectorElement_y || d.y
        };
      });
      return x;
    }
  },
  beforeUnmount() {
    this.viewer = [];
    this.printZone = [];
    this.printNodes = [];
    this.viewer = [];
    this.flattenedNodeStructure = [];
    this.svg = null;
    this.g = null;
    this.links = [];
    this.unModifiedLinks = [];

    clearTimeout(this.loadEdgeTreeTimeout);
    clearTimeout(this.loadRouterTimeout);
  }
};
</script>

<style lang="scss" scoped>
@import './PrintCanvas.scss';
div,
span,
img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>

<style lang="less" scoped>
@import './PrintCanvas.less';
</style>

<style lang="scss" scoped>
svg:not(:root) {
  overflow: visible;
}

.print-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #333;
  background: #fdfdfd;
  transform-style: preserve-3d;
}

.viewer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #edf0f4 !important;
  padding: 15px;
  overflow: scroll;
}

.viewer2 {
  position: relative;
  width: 400px;
  height: 600px;
  background: #edf0f4 !important;
}

i.fas.fa-save.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.locked {
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  font-size: 3vw;
}
.zoomPercent {
  background-color: #fff;
  position: absolute;
  z-index: 5;
  bottom: 10px;
  left: 10px;
  padding: 13px 19px 12px;
  color: #383f45;
  border-radius: 4px;
}

.con-vs-popup,
.vs-popup {
  z-index: 9999234;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-200px);
  z-index: 999999;
}

.ui-toolbar__title {
  font-size: 25px;
}

.ui-toolbar {
  z-index: 78342432;
  position: fixed;
  width: 100%;
}

.ui-toolbar--type-colored {
  background-color: black;
}

.toolbarIcon {
  padding-left: 40px;
  font-size: 30px;
}

.flowchart-container {
  margin: 0;
  position: relative;
  overflow: scroll;
  width: 100%;
  flex: 1 1 auto;
}

.flowchart-container svg {
  cursor: grab;
}

.userImage {
  border-radius: 50px;
  width: 32px;
  height: 32px;
  border: 2px solid rgb(11, 189, 135);
}

.userName {
  color: white;
}
.userEmail {
  font-size: 10px;
  color: white;
}

.pdfButton,
.uploadAll {
  color: white;
  font-size: 16px;
  background-color: rgb(0, 123, 258);
  padding: 10px;
  border-radius: 25px;
  font-weight: 700;
}
.title-wrapper {
  display: none;
}
.date-wrapper {
  display: none;
}
@media print {
  @page {
    size: 210mm 297mm;
    margin: 0;
  }
  .print-wrapper {
    min-height: 297mm;
    width: 210mm;
  }
  .title-wrapper {
    display: block;
    position: absolute;
    left: 5px;
    top: 0;
    z-index: 99;
    font-size: 20px;
  }
  .date-wrapper {
    display: block;
    position: absolute;
    right: 5px;
    top: 0;
    z-index: 99;
    font-size: 20px;
  }
  .viewer {
    overflow: visible !important;
    background: #ffffff !important;
  }
  .viewer2 {
    background: #ffffff !important;
  }
}
</style>
