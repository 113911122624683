<template>
  <div>
    <div class="sidebar components">
      <div class="row editor-header"></div>
      <div>
        <message-editor-sidebar :id="componentSelected.id" @input="update" :value="currentValue" placeHolder="Edit Root" :readOnly="false" :type="`message`" :excludeTools="['chevron', 'time']" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import MessageEditorSidebar from '@/components/ConversationBuilder/Editors/3.0/MessageEditorSidebar.vue';
import essentials from '../Mixins/essentials';

export default {
  name: 'Root-Sidebar',
  mixins: [essentials],
  data() {
    return {
      appStore: useAppStore()
    };
  },
  methods: {
    update(e) {
      if (this.componentSelected.type === 'start') {
        this.appStore.editContainer({
          field: 'title',
          value: e,
          id: this.componentSelected.id
        });
      } else {
        this.appStore.updateComponent({ text: e, id: this.id });
      }
    }
  },
  components: {
    MessageEditorSidebar
  },
  computed: {
    currentValue() {
      return this.componentSelected?.title;
    }
  }
};
</script>

<style scoped>
.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}

.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 25px 15px;
  height: max-content;
}

.sidebar.components.flow {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
