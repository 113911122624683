<template>
  <div v-click-away="onClickAway">
    <div v-if="shouldShowButton()" id="moreButton" class="text-right toggle-button" @click="showPopup = !showPopup">
      <ConversationBuilderIcon v-show="isVisible" :width="18" name="more_v" class="draft-icon icon" />
      <div id="popup" v-if="showPopup" class="popup text-center">
        <div id="restoreSessionButton" class="more-button px-4 py-2 m-2 restore-button" @click="handleShowRestorePopup">Restore session</div>
        <div id="versionNameButton" class="more-button px-4 py-2 m-2" @click="editVersionName">Name this version</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore, useCollabStore } from '@/store/index.js';
import { mixin as VueClickAway } from 'vue3-click-away';
import { mapActions } from 'pinia';
import ConversationBuilderIcon from '../../../Canvas/3.0/Icons/ConversationBuilderIcon.vue';

export default {
  name: 'MorePopup',
  mixins: [VueClickAway],
  components: { ConversationBuilderIcon },
  props: {
    version: {
      type: Object,
      required: true
    },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      collabStore: useCollabStore(),
      showPopup: false
    };
  },
  methods: {
    ...mapActions(useVersionsStore, ['setRestoreVersionId']),
    shouldShowButton() {
      return this.collabStore.isFlowEditor;
    },
    onClickAway() {
      this.showPopup = false;
      this.$emit('hideMore');
    },
    editVersionName() {
      this.$emit('editVersionName');
    },
    handleShowRestorePopup() {
      this.setRestoreVersionId(this.version.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.toggle-button {
  position: relative;
}

.icon {
  margin-left: 10px;
}

.popup {
  width: 170px;
  height: 98px;
  position: absolute;
  background: white;
  z-index: 5000;
  top: 30px;
  right: 0;
  box-shadow: 0 0 5px rgb(85, 85, 85);
  border-radius: 4px;
}

.more-button {
  background: #fff;
  font-weight: 700;
  color: rgb(104, 104, 104);
  border-color: #2698ed;
  font-size: 13px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;
}

.more-button:hover {
  background-color: #fff;
  color: #2698ed;
}

.more-button:active {
  background-color: #2698ed;
  color: #fff;
}

.restore-button {
  border: 1px solid #2698ed;
  color: #2698ed;
}

.restore-button:hover {
  border: 1px solid #2698ed;
  background-color: #2698ed;
  color: #fff;
}

#versionNameButton:hover {
  text-decoration: underline;
}
</style>
