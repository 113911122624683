<template>
  <div class="editor" :class="{ editorButton: type === 'Button' }">
    <editor-content v-if="type === 'Button' && !readOnly" class="editor__content__bubble__shorten__button" :editor="editor" />
    <!-- <editor-content  v-if='type === "note" && readOnly' class="editor__content__bubble__shorten__readOnly" :editor="editor" /> -->
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Focus from '@tiptap/extension-focus';
import Underline from '@tiptap/extension-underline';

import { Superscript, Subscript, Highlight } from '@/components/ConversationBuilder/Extensions/index.js';
import { mapActions } from 'pinia';

export default {
  components: {
    EditorContent
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    value: {
      type: String
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    collection: {
      required: false,
      type: String
    },
    nodes: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      editor: null,
      editorChange: false,
      note: '',
      query: null
    };
  },
  computed: {
    setLanguage() {
      return this.appStore.language;
    },
    voiceActive() {
      return this.appStore.voiceActive;
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: this.readOnly ? false : true,
      onFocus: () => {},
      onBlur: () => {
        // console.log(event, state, view)
      },
      onUpdate: ({ getHTML }) => {
        this.editorChange = true;
        this.$emit('input', getHTML());
      },
      content: this.value,
      extensions: [
        StarterKit,
        Superscript,
        Subscript,
        Highlight,
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: '',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true
        })
        // Limit.configure({
        //     limit: 22
        // }),
      ]
    });
  },
  methods: {
    ...mapActions(useAppStore, ['sendSceneToDB'])
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) this.editor.destroy();
    } catch (err) {
      //
    }
  },
  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style>
u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
  font-style: italic;
  text-decoration-color: black;
}

.editor__content table .column-resize-handle,
.column-resize-handle.ProseMirror-widget {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper table {
  min-width: 340px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
</style>

<style scoped>
.editor__content__bubble:before {
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 2px;
  content: '';
  display: inline-block;
  height: 12px;
  left: 5px;
  position: absolute;
  width: 12px;
  background-size: cover;
}

.menububble__button {
  font-size: 10px;
}

.menububble__input::placeholder {
  color: white;
  font-size: 10px;
}

.editorButtonWrapper {
  position: relative;
  max-width: 30rem;
  margin: 1rem auto 0rem;
}

.editorButton {
  width: fit-content;
  background-color: #325a99;
  color: white;
  font-size: 13px;
  min-width: 130px;
  font-weight: normal;
  border-radius: 7px;
  margin-left: 150px;
  margin-right: auto;
  /* float: right; */
  max-width: 320px;
  overflow-x: auto;
  cursor: text;
}

.menubar {
  margin-bottom: 0rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.editor__content__table {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  max-width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 5px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
  cursor: text;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar__button__menu {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: white;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.menubar.is-focused {
  visibility: visible;
  opacity: 1;
}
</style>

<style>
.editor__content__bubble__shorten__button {
  /* display: -webkit-box;
    display: -ms-flexbox;
    display: flex; */
  /* -webkit-box-align: start;
    align-items: flex-start; */
  /* -webkit-box-orient: vertical;
    -webkit-box-direction: normal; */
  /* -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center; */
  text-align: left;
}

.editor__content__bubble__shorten__button p {
  margin: 0.5em !important;
}

.editor__content__bubble__shorten__button p highlight {
  color: black;
}

.vs-chip--text span highlight {
  color: black;
}
</style>
