import { useModal } from '@/bootstrap/modal.js';
import AppTimeout from '@/components/Utilities/AppTimeout.vue';
import AgentFlowsBulkActionsModal from '@/views/3.0/AgentFlowsBulkActionsModal.vue';

function generateUid(prefix) {
  return `${prefix}`;
}

function baseModal(component, uid) {
  const modal = useModal({
    component,
    attrs: {
      name: uid,
      onConfirm() {
        close();
      },
      openModal() {
        open();
      },
      closeModal() {
        close();
      },
      onBeforeClose() {
        console.log(`${uid} onBeforeClose`);
      },
      onClosed() {
        console.log(`${uid} onClosed`);
      }
    }
  });
  return modal;
}

export function appTimeOutModal() {
  const uid = generateUid('AppTimeout');
  return baseModal(AppTimeout, uid);
}

export function agentFlowsBulkActionsModal() {
  const uid = generateUid('AgentFlowsBulkActionsModal');
  return baseModal(AgentFlowsBulkActionsModal, uid);
}
