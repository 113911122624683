<template>
  <div class="add-component">Drag a component</div>
</template>

<script>
export default {
  name: 'AddComponent',
  props: {
    containerId: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.add-component {
  width: 100%;
  height: 45px;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  text-align: left;
  color: #8e9ba8;
  padding: 10px 10px 15px 20px;
  border-radius: 4px;
  border: dashed 3px #d7dde5;
  background-color: #f6f7f9;
  margin-top: 5px;
}
</style>
