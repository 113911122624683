import { createApp, configureCompat } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import Vuesax from 'vuesax3';
import VueCarousel from 'vue-carousel';
import Carousel3d from 'vue-carousel-3d';
import vSelect from 'vue-select';
import { vfm } from '@/bootstrap/modal.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUndo, faCoffee, faRedo, faExpandAlt, faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import GSignInButton from 'vue-google-signin-button';
import ElementPlus from 'element-plus';
import locale from 'element-plus/dist/locale/en.min.js';
import Clipboard from 'v-clipboard';
import KeenUI from 'keen-ui';
import FloatingVue from 'floating-vue';
import CountryFlag from 'vue-country-flag-next';
import axios from 'axios';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import VueClickAway from 'vue3-click-away';
import { initCMPSocket, initSocket } from './helpers/socket';
import { useAppStore /*, useVcmStore*/ } from '@/store/index.js';
import 'bootstrap';

// Vuetify
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import router from '@/routes';

// import the styles
import './styles/index.css';
import './styles/bootstrap.scss';
import 'material-icons/iconfont/material-icons.css';
import 'vue-select/dist/vue-select.css';
import 'vuesax3/dist/vuesax.css';
import 'font-awesome/less/font-awesome.less';
import 'keen-ui/dist/keen-ui.css';
import 'vue-simple-context-menu/dist/vue-simple-context-menu.css';
import 'vue-simple-flowchart/dist/vue-flowchart.css';
import 'element-plus/dist/index.css';
import 'floating-vue/dist/style.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';
import 'vue-final-modal/style.css';
import './styles/app.scss';
import 'tippy.js/dist/tippy.css';
import { plugins } from '@/store/plugins';

library.add(faCoffee, faUndo, faRedo, faExpandAlt, faSearchPlus, faSearchMinus);

// TODO: Remove this after all Vue3 migration warnings are resolved
configureCompat({
  MODE: 3,
  GLOBAL_MOUNT: false,
  COMPONENT_V_MODEL: false,
  WATCH_ARRAY: false,
  RENDER_FUNCTION: false,
  ATTR_FALSE_VALUE: false,
  // INSTANCE_EVENT_HOOKS: false,
  ATTR_ENUMERATED_COERCION: false
});

const pinia = createPinia();

pinia.use(({ options, store }) => {
  const pluginId = options.id;
  const plugin = plugins[pluginId];
  if (plugin) {
    // console.log(`[MAIN] Adding plugin '${pluginId}'`);
    store['$' + pluginId] = plugin();
  }
});

const app = createApp(App);

app.use(pinia);

// Global Properties
app.config.devtools = true;
app.config.productionTip = false;
// app.config.silent = true;

app.config.errorHandler = (err, vm, info) => {
  console.error('Global Error Handler:', err, vm, info);
};

app.config.globalProperties.$axios = axios;
dayjs.extend(relativeTime);
app.config.globalProperties.$dayjs = dayjs;

app.use(FloatingVue);
app.use(VueClickAway);
app.use(KeenUI);
app.use(Clipboard);
app.use(GSignInButton);
app.use(Vuesax);
app.use(vfm);
app.use(ElementPlus, { locale });
app.use(VueCarousel);
app.use(Carousel3d);

const vuetify = createVuetify({
  components,
  directives
});

app.use(vuetify);

// Custom directive
app.directive('uppercase', {
  beforeMount(el) {
    el.value = el.value.toUpperCase();
  }
});

// Global component registration
app.component('country-flag', CountryFlag);
app.component('v-select', vSelect);
app.component('v-style', {
  render: function (createElement) {
    return createElement('style', this.$slots.default);
  }
});
app.component('font-awesome-icon', FontAwesomeIcon);

const requireComponent = require.context('./components/ConversationBuilder', true, /.*\.vue$/);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = fileName.split('/').slice(-1)[0].replace('.vue', '');
  app.component(componentName, componentConfig.default || componentConfig);
});

const requireLayoutComponents = require.context('./components/Layout', true, /.*\.vue$/);
requireLayoutComponents.keys().forEach((fileName) => {
  const componentConfig = requireLayoutComponents(fileName);
  const componentName = fileName.split('/').slice(-1)[0].replace('.vue', '');
  app.component(componentName, componentConfig.default || componentConfig);
});

const requireDrawerComponents = require.context('./components/Drawer', true, /.*\.vue$/);
requireDrawerComponents.keys().forEach((fileName) => {
  const componentConfig = requireDrawerComponents(fileName);
  const componentName = fileName.split('/').slice(-1)[0].replace('.vue', '');
  app.component(componentName, componentConfig.default || componentConfig);
});
// Initialize Sockets
initSocket();
initCMPSocket();

// Firebase Initialization and logic remain the same
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID
};
const firebaseApp = firebase.initializeApp(firebaseConfig);

function isTokenExpired(token) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const decodedToken = JSON.parse(atob(base64));

    // The `exp` field is the expiration time in seconds since the epoch.
    const expirationTimeInSeconds = decodedToken.exp;
    const currentTimeInSeconds = Date.now() / 1000;

    // Consider the token expired if there are less than 30 minutes until expiration.
    const bufferTime = 30 * 60;
    return expirationTimeInSeconds - currentTimeInSeconds < bufferTime;
  } catch (error) {
    console.error('Error checking token expiration:', error);
    return true; // If there's an error, assume the token is expired.
  }
}

async function refreshFirebaseTokenIfNeeded() {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    const token = await currentUser.getIdToken();
    if (isTokenExpired(token)) {
      const newToken = await currentUser.getIdToken(true);
      const appStore = useAppStore();
      appStore.SET_TOKEN({ token: newToken });
    }
  }
}

let appMounted = false;
async function handleAuthStateChange(user) {
  const appStore = useAppStore();
  if (user) {
    try {
      user.getIdToken(true);
      const idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
      if (idTokenResult?.claims?.version === '3x') {
        await appStore.updateClaims({
          admin: idTokenResult.claims.admin,
          role: idTokenResult.claims.role,
          token: idTokenResult.token,
          taxonomy: idTokenResult.claims.taxonomy || false,
          ...idTokenResult
        });

        await appStore.getTaxonomies();
        await appStore.createUserList();
        await appStore.initializeLanguages(process.env.VUE_APP_ADDITIONAL_LANG);
        appStore.updateUser({ user });
        initCMPSocket(idTokenResult);

        appStore.SAVE_TO_DB_CONFIG({
          type: idTokenResult.claims.role === 'CLIENT' ? 'CLIENT' : 'INTERNAL',
          slotId: '',
          slotName: ''
        });
      } else {
        // Convert user to 3x AUTH roles then reload page to view updated roles
        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/convertUserTo3x`, {
          userToChange: firebase.auth().currentUser?.uid,
          idToken: idTokenResult.token
        });
        setTimeout(() => {
          location.reload();
        }, 2000);
      }
      appStore.updateUser({ user: user });
    } catch (err) {
      appStore.updateUser({ user: user });
      if (!appMounted) {
        app.use(router);
        app.mount('#app');
        appMounted = true;
      }
    }
  } else {
    appStore.clearUser();
  }

  if (!appMounted) {
    app.use(router);
    app.mount('#app');
    appMounted = true;
  }

  // Update token periodically
  setInterval(refreshFirebaseTokenIfNeeded, 1000 * 60 * 10);
}

firebase.auth().onAuthStateChanged(handleAuthStateChange);

export const db = firebaseApp.firestore();
export const storage = firebase.storage();
export const rtdb = firebase.database();
export const firestore = firebase.firestore;
