<template>
  <div :id="comment.id" :class="containerStyle" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="header">
      <img :src="comment.userPhoto" alt="" />
      <span class="user-name">{{ comment.userName }}</span>
      <span v-if="!hover || (hover && !isUsersComment)" class="age">{{ commentHowOld }}</span>
      <div v-if="hover && isUsersComment" @click="openList" v-click-outside="onClickOutsideList" tabindex="0">
        <ConversationBuilderIcon :key="hover" class="add-button" :width="18" name="ellipsis_blue" :style="iconContainerStyle" />
      </div>
    </div>
    <div :class="optionsStyle" v-if="isContainerOpen">
      <div v-if="!resolvedView" class="option" @click="handleClick('resolve')" data-test="resolve">
        <ConversationBuilderIcon :width="14" name="resolve" />
        <span>Resolve</span>
      </div>
      <button v-if="!resolvedView" class="option" @click="handleClick('edit')" data-test="edit">
        <ConversationBuilderIcon :width="14" name="edit" />
        <span>Edit</span>
      </button>
      <div v-if="resolvedView" class="option" @click="handleClick('unresolve')" data-test="unresolve">
        <ConversationBuilderIcon :width="14" name="unresolve" />
        <span>Move to unresolved</span>
      </div>
      <div class="option" @click="handleClick('delete')" data-test="delete">
        <ConversationBuilderIcon :width="14" name="delete" />
        <span>Delete</span>
      </div>
    </div>
    <div class="options_container" v-if="showConfirmDeleteContainer" v-click-outside="onClickOutsideConfirmDelete" data-test="delete-dialog">
      <div class="option" @click="handleClick('confirmDelete')">
        <ConversationBuilderIcon :width="14" name="delete" />
        <span>Confirm delete</span>
      </div>
      <div class="option" @click="handleClick('cancelDelete')">
        <ConversationBuilderIcon :width="14" name="remove" />
        <span>Cancel delete</span>
      </div>
    </div>
    <div class="content" data-test="content">
      <span v-if="!resolvedView && comment.pinned" :class="{ component: comment.attachedTo === 'component' }" class="number" @click="handleGoToComponentOrContainer(comment)">{{ comment.pinnedCommentIndex }}</span>
      <p v-if="!showEditComment" class="comment-content" v-html="comment.text" />
      <CommentEditText @removeEditComment="handleClick('removeEditComment')" v-if="showEditComment" :isUpdating="true" :startingText="comment.text" @finished="handleFinishEditing" :index="index" :id="comment.id" :showIcon="true" />
    </div>
    <div @click="handleReplyTextClick" v-if="isShowingReplies" class="reply-text-and-icon" data-test="reply-icon">
      <svg v-if="this.comment.childComments && this.comment.childComments.length" class="reply-icon" :class="{ flip: showChildCommentsAndReplyForm }" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <circle class="dropdown-circle" cx="10" cy="10" r="10" />
        <path class="dropdown-chevron" fill="none" stroke="#1996f0" stroke-linecap="round" stroke-width="2px" d="M0 3.346L3.456 0l3.456 3.346" transform="translate(6.478 8.029)" />
      </svg>
      <span class="reply-text">{{ replyOrReplies }}</span>
    </div>
    <ChildComments v-if="showChildCommentsAndReplyForm" :childComments="comment.childComments" :parentCommentId="comment.id" data-test="child-comments" />
    <CommentReplyForm v-if="showChildCommentsAndReplyForm && !resolvedView" :childComments="comment.childComments" :parentCommentId="comment.id" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore, useCollabStore } from '@/store/index.js';
import { mapActions, mapState } from 'pinia';
import CommentEditText from './CommentEditText.vue';
import ChildComments from './ChildComments.vue';
import CommentReplyForm from './CommentReplyForm.vue';
import vClickOutside from 'click-outside-vue3';
import ConversationBuilderIcon from '../../../Canvas/3.0/Icons/ConversationBuilderIcon.vue';

export default {
  name: 'CommentsSidebarEach',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    CommentEditText,
    ChildComments,
    CommentReplyForm,
    ConversationBuilderIcon
  },
  props: {
    comment: {
      default: {}
    },
    index: {
      default: 0
    },
    resolvedView: {
      type: Boolean,
      default: false
    },
    pinnedComments: {
      default: 0
    }
  },
  mounted() {
    this.commentAge();
    this.loadCommentAgeInterval = setInterval(() => this.commentAge(), 10000);
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      hover: false,
      isContainerOpen: false,
      showEditComment: false,
      iconContainerStyle: {
        width: '30px',
        height: '30px',
        'background-color': '#fff',
        'border-radius': '15px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        cursor: 'pointer'
      },
      showChildCommentsAndReplyForm: false,
      commentHowOld: null,
      showConfirmDeleteContainer: false,
      loadCommentAgeInterval: null
    };
  },
  computed: {
    ...mapState(useCollabStore, ['isFlowEditor']),
    isShowingReplies() {
      return (this.resolvedView && this.replyOrReplies !== 'Reply') || !this.resolvedView;
    },
    containerStyle() {
      let style = `comment-container ${this.hover ? 'active' : ''}`;
      if (this.resolvedView) {
        style += ' resolved-container';
      }
      return style;
    },
    optionsStyle() {
      let style = `options_container`;
      if (this.resolvedView) {
        style += ' resolved-options';
      }
      return style;
    },
    isUsersComment() {
      return this.comment.userId === this.appStore.getUser.uid;
    },
    replyOrReplies() {
      if (this.comment.childComments && this.comment.childComments.length) {
        return this.comment.childComments.length === 1 ? '1 Reply' : `${this.comment.childComments.length} Replies`;
      } else {
        return 'Reply';
      }
    }
  },
  methods: {
    ...mapActions(useCommentsStore, ['deleteComment', 'updateComment', 'resolveComment', 'goToComponentOrContainer']),
    handleGoToComponentOrContainer(comment) {
      this.appStore.setZoomLevel({ zoomLevel: 1 });
      this.goToComponentOrContainer({ comment });
    },
    onClickOutsideList() {
      this.isContainerOpen = false;
    },
    onClickOutsideConfirmDelete() {
      this.showConfirmDeleteContainer = false;
    },
    openList() {
      this.isContainerOpen = !this.isContainerOpen;
    },
    handleReplyTextClick() {
      this.showChildCommentsAndReplyForm = !this.showChildCommentsAndReplyForm;
    },
    handleClick(e) {
      if (e === 'delete') {
        this.showConfirmDeleteContainer = true;
      }
      if (e === 'resolve') {
        this.resolveComment({ id: this.comment.id, resolveBool: true });
      }
      if (e === 'unresolve') {
        this.resolveComment({ id: this.comment.id, resolveBool: false });
      }
      if (e === 'edit') {
        this.showEditComment = true;
        this.$emit('editCommentId', { id: this.comment.id });
      }
      if (e === 'confirmDelete') {
        this.deleteComment({
          id: this.comment.id,
          resolvedComment: this.resolvedView
        });
        this.showConfirmDeleteContainer = false;
      }
      if (e === 'cancelDelete') {
        this.showConfirmDeleteContainer = false;
      }
      if (e === 'removeEditComment') {
        this.showEditComment = false;
      }
      this.isContainerOpen = false;
    },
    handleFinishEditing() {
      this.showEditComment = false;
    },
    commentAge() {
      const seconds = Math.floor((new Date() - this.comment.createdAt) / 1000);

      let interval = seconds / 31536000;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' years ago';
        return;
      }

      interval = seconds / 2592000;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' months ago';
        return;
      }

      interval = seconds / 86400;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' days ago';
        return;
      }

      interval = seconds / 3600;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' hours ago';
        return;
      }

      interval = seconds / 60;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' minutes ago';
        return;
      }

      this.commentHowOld = Math.floor(seconds) + ' seconds ago';
      return;
    }
  },
  beforeUnmount() {
    clearInterval(this.loadCommentAgeInterval);
  }
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #f2f2f2;
}

.comment-container {
  width: 100%;
  // min-height: 134px;
  display: flex;
  flex-direction: column;
  padding: 25px 20px 20px 15px;
  border-left: 5px solid #fd5422;
  position: relative;
}
.resolved-container {
  border-left: 5px solid #20bbd0;
}
.options_container {
  width: 141px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 4;
}
.resolved-options {
  width: 170px;
  height: 60px;
}
.option {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;

  i {
    margin-left: 11px;
  }

  span {
    margin-left: 13px;
    font-size: 14px;
    line-height: 2.14;
    text-align: left;
  }
}

.option:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.option:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.option:hover {
  background-color: #e8f5fe;
}
.header {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  img {
    width: 20px;
    height: 20px;
    background-color: #fd5422;
    border-radius: 10px;
    margin-right: 10px;
    object-fit: cover;
  }

  .user-name {
    font-size: 14px;
    line-height: 1.36;
    margin-right: auto;
  }

  .age {
    font-size: 12px;
    line-height: 1.33;
  }
}

.content {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  // margin-bottom: 10px;

  .number {
    width: 20px;
    height: 20px;
    background-color: #fd5422;
    border-radius: 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 2;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .number.component {
    background-color: #fd5422 !important;
  }

  .comment-content {
    width: 345px;
    // min-height: 45px;
    font-size: 17px;
    line-height: 1.29;
    color: #383f45;
    text-align: left;
    margin: 0;
    word-wrap: break-word;
  }
}

.reply-text-and-icon {
  text-align: right;
  margin-left: 30px;
  margin-top: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 800;
  display: flex;
  justify-content: right;
  align-items: center;
  .reply-icon {
    transform: rotate(90deg);
    margin-right: 10px;

    .dropdown-circle {
      fill: white;
    }

    .dropdown-chevron {
      stroke: #56616a;
    }
  }

  .reply-icon.flip {
    transform: rotate(180deg);
  }
}

.reply-text-and-icon:hover {
  color: #1996f0;
}
</style>
