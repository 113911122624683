<template>
  <div>
    <div v-show="appStore.drawer === 'FindReplaceDrawer'" class="overlay"></div>
    <DrawerMain id="highlightsDrawer" v-show="appStore.drawer === 'findReplace'">
      <template v-slot:header>
        <PageHeader title="Find and Replace" subtitle="Utilities / Configuration" />
      </template>
      <template v-slot:filters> </template>
      <template v-slot:body>
        <div v-if="appStore.currentTaxonomy" class="pageLayout">
          <FindReplaceList @selectionChanged="selectionChanged" :slotIntent="intents" :collection="appStore.currentTaxonomy" v-show="appStore.drawer === 'findReplace'"> </FindReplaceList>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import FindReplaceList from '../FindReplaceList/FindReplaceList.vue';

export default {
  name: 'FindReplaceDrawer',
  components: {
    DrawerMain,
    FindReplaceList
  },
  data() {
    const appStore = useAppStore();
    return {
      notes: [],
      intents: [],
      filtersNotes:
        appStore.getClaims?.role != 'CLIENT'
          ? [
              { text: 'CLIENT', value: 'CLIENT', selected: false },
              { text: 'INTERNAL', value: 'INTERNAL', selected: false }
            ]
          : [{ text: 'CLIENT', value: 'CLIENT', selected: false }]
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    autoSaveStore() {
      return useAutoSaveStore();
    },
    commentsStore() {
      return useCommentsStore();
    },
    versionsStore() {
      return useVersionsStore();
    },
    historyStore() {
      return useHistoryStore();
    },
    notificationsStore() {
      return useNotificationsStore();
    },
    vcmStore() {
      return useVcmStore();
    }
  },
  methods: {
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    selectionChanged(payload) {
      this.intents = payload.intents;
    }
  }
};
</script>
