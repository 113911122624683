<template>
  <div class="fullwrapper trainingPhrasesContainer" style="background: lightgray" :style="customStyles">
    <div class="fullwrapper" :style="wrapperStyle">
      <div class="wrapper ai-window" style="width: 100%; overflow-y: hidden; overflow-x: hidden; background-color: lightgray; border-bottom: 2px solid black">
        <div class="closeButton">
          <button style="float: right" @click="closeModal">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>

        <p></p>
        <p></p>
        <div>
          <h2 style="font-weight: bold; font-family: Oswald, sans-serif !important">TRAINING PHRASES</h2>
        </div>
        <br />
        <el-select v-model="entities_chosen" value-key="name" v-if="!entities_have_been_chosen" filterable multiple placeholder="Select Entities" popper-class="el-select-dropdown">
          <el-option v-for="item in entities" :key="item.name" :label="item.name" :value="item"> </el-option>
        </el-select>

        <el-select v-model="entities_chosen" value-key="name" v-if="entities_have_been_chosen === true" filterable multiple placeholder="Select Entities" popper-class="el-select-dropdown">
          <el-option v-for="item in entities" :key="item.name" :label="item.name" :value="item"> </el-option>
        </el-select>
        <i @click="SaveEntities(entities_chosen)" style="float: right; font-size: 1.5em; padding: 2px" class="fas fa-check-double"></i>
        <el-button size="10" @click="SendtoDB()" class="save_button">SAVE</el-button><br />

        <div class="grid justify-items-center">
          <span style="font-size: 12px; color: grey" v-if="last_addition_timestamp">Last updated: {{ last_addition_timestamp }}</span>
          <br />
        </div>
        <div style="overflow: scroll; overflow-x: hidden; height: 70%">
          <Container @drop="onDrop">
            <div v-for="(item, index) in trainingphrases" :key="item.id">
              <div class="draggable-item" style="overflow-y: hidden">
                <div v-if="item.type === 'text'" style="padding-bottom: 8px">
                  <tr>
                    <highlightable-input highlight-style="background-color:yellow" :highlight-enabled="highlightEnabled" :highlight="highlight" v-model="item.response" class="bubble bot" data-placeholder="Paste Single Training Phrase" />
                  </tr>
                </div>

                <div v-if="item.type === 'batch_text'" style="padding-bottom: 8px">
                  <tr>
                    <highlightable-input :highlight-enabled="highlightEnabled" :highlight="highlight" v-model="item.response" class="bubble bot batchtext" data-placeholder="Paste Multiple Phrases" />
                    <!-- <i v-if="item.type === 'batch_text'" style="font-size: 10px" class="material-icons">text_format</i> -->
                    <td>
                      <i @click="RemoveUtterance(index)" style="padding: 4px" class="fas fa-trash-alt"></i>
                    </td>
                    <td>
                      <i @click="AddUtterance(index)" style="padding: 4px" class="fas fa-plus"></i>
                    </td>
                  </tr>
                  <!-- <tr><span v-if="item.type === 'batch_text'" style='font-size: 10px; color: grey; float: right; margin-right: 5px;'>Save</span> </tr> -->
                </div>
              </div>
            </div>
          </Container>

          <a id="bottom"></a>
        </div>
      </div>
      <div class="vue-dialog-buttons">
        <button @click="addResponse('batch_text')" type="button" class="vue-dialog-button" style="flex: 1 1 15%; background: lightgray"><i class="fas fa-comment fa-2x"></i><br />Add Phrases</button>
        <button @click="removeResponse()" type="button" class="vue-dialog-button" style="flex: 1 1 15%; background: lightgray"><i class="fas fa-minus fa-2x"></i><br />Remove</button>
      </div>
    </div>

    <div v-if="view_entities && showEntities" style="width: 760px; padding-right: 25px; height: 100%; padding: 3px; background-color: whitesmoke; z-index: 9999">
      <br />
      <el-tabs v-if="showEntities" style="overflow: scroll; height: 100%" tab-position="top">
        <el-tab-pane label="">
          <h4 slot="label" style="font-family: 'Roboto', sans-serif !important">
            <i style="font-size: 20px" class="fas fa-tags"></i>
          </h4>
          <div v-for="item in entities_chosen" :key="item.id">
            <EntityPhrases @copyref="updatePhrase($event)" :collection="collection" :entityInfo="item" />
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-table>
        <el-table-column align="right">
          <template #default="scope">
            <el-button size="small" @click="EntitySettings(scope.row.name, scope.row.id, scope.row.highlightColor)">
              <i class="fas fa-copy"></i>
            </el-button>
            <el-button size="small" @click="deleteEntity(scope.row.id)"><i class="fas fa-edit"></i> </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "transition" }]*/

import { Container } from 'vue3-smooth-dnd';
import { applyDrag } from '@/components/Utilities/utils';
import HighlightableInput from 'vue-highlightable-input';
import { db } from '@/main';
import firebase from 'firebase/compat/app';
import EntityPhrases from '@/components/Entities/EntityPhrases';

export default {
  name: 'TrainingPhrases',
  components: {
    Container,
    HighlightableInput,
    EntityPhrases
  },
  inject: {
    // defaultSchemeColor: {
    //   default: Object.keys(colorSchemes)[0]
    // }
  },

  props: {
    showEntities: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    actions: {
      required: false,
      type: Object
    },

    collection: {
      required: true,
      type: String
    },

    slots: {
      required: true,
      type: Array
    }
  },

  directives: {},

  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      graphNodes: {
        clicked: null,
        mouseOver: null,
        zoomed: null,
        root: null,
        highlighted: null
      },

      width: null,
      phrasesReplace: [],
      viewPasteInput: false,
      view_entities: true,
      entities_chosen: [],
      entities_have_been_chosen: '',
      entities: [],
      search: '',
      height: null,
      yFilter: [],
      entity: '',
      response: '',
      batch_text: '',
      showModal: false,
      child_chosen: '',
      parent_chosen: '',
      showBot: '',
      trainingphrases: [],
      trainingphrases_id: '',
      last_addition_timestamp: '',
      email: '',
      highlight: [],
      highlightEnabled: true,
      value3: [1],
      value4: [1]
    };
  },
  mounted() {
    this.currentUser();
    this.bindTrainingPhrases();
    this.bindEntities();
    this.bindIntentData();
  },
  computed: {
    wrapperStyle() {
      return { width: this.showEntities ? '530px' : '100%', padding: '15px' };
    },
    customStyles() {
      return {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxWidth}`,
        'max-height': `${this.setModalWidthHeight().maxHeight}`,
        overflow: 'auto',
        position: `relative`
      };
    }
  },
  methods: {
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '90%';
      let width = '1290px';
      let height = '600px';

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    },
    bindTrainingPhrases() {
      const ref = db.collection(this.collection).doc(this.nodes.clicked.data.data.id).collection('Utterances').orderBy('timestamp', 'desc').limit(1);
      ref.onSnapshot(
        (doc) => {
          if (doc.empty) {
            db.collection(this.collection)
              .doc(this.nodes.clicked.data.data.id)
              .collection('Utterances')
              .add({
                utterances: [],
                user: this.email,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                highlight: this.highlight
              })
              .then((docRef) => {
                this.trainingphrases_id = docRef.id;
              });
          } else {
            if (doc[0]) this.trainingphrases = doc[0].utterances;
            this.trainingphrases_id = doc[0]['.key'];
            this.last_addition_timestamp = doc[0]?.timestamp?.toDate()?.toLocaleString();
            if (doc[0].entitiesChosen) {
              this.entities_chosen = doc[0].entitiesChosen;
              this.entities_have_been_chosen = true;
            }

            if (doc[0].highlight) {
              this.highlight = doc[0].highlight;
              this.highlight.push({
                text: '?',
                style: 'background-color: red'
              });
              // console.log(this.highlight)
            }
          }
        },
        (error) => {
          console.error(`Could not bind training phrases`, error);
        }
      );
    },

    bindEntities() {
      const ref = db.collection(this.collection).doc('EntityDoc').collection('EntityColl');
      ref.onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            return;
          }
          snapshot.forEach((doc) => {
            // if (doc.hidden != true) {
            this.entities.push({
              id: doc['.key'],
              name: doc.name,
              highlightColor: doc.highlightColor
              // lastEdited: doc.lastEdited
            });
            // }
          });
        },
        (error) => {
          console.error(`Could not bind entities`, error);
        }
      );
    },

    bindIntentData() {
      const ref = db.collection(this.collection).doc(this.nodes.clicked.data.data.id);
      ref.onSnapshot(
        (doc) => {
          if (doc.entitiesChosen) {
            this.entities_chosen = doc.entitiesChosen;
            this.entities_have_been_chosen = true;
          }
        },
        (error) => {
          console.error(`Could not bind intent data`, error);
        }
      );
    },
    ReplacePhrases() {
      const r = confirm('Are you sure you want to replace?');

      if (r === true) {
        this.trainingphrases = JSON.parse(this.phrasesReplace);
        //   this.SendtoDB()
        this.$vs.notify({
          title: 'Phrases Replaced',
          text: 'Must click save to write over previous',
          color: 'primary',
          time: 4000
        });
      }
    },
    changePasteInput() {
      // console.log('changing paste input', this.viewPasteInput)
      if (this.viewPasteInput) {
        this.viewPasteInput = false;
      } else {
        this.viewPasteInput = true;
      }
    },
    copyPhrases() {
      this.$clipboard(this.trainingphrases);
      this.$vs.notify({
        title: 'Response Copied',
        text: 'You can paste this object in another response',
        color: 'danger',
        time: 4000
      });
    },
    RemoveUtterance(index) {
      this.trainingphrases.splice(index, 1);
    },
    copyTrainingPhasesURL() {
      this.$clipboard(`${window.location.hostname}/taxonomy?product=${this.collection}&slot=${this.nodes.clicked.data.data.name}&utterance=yes`);
      this.$vs.notify({
        title: 'Utterances link copied to clipboard',
        text: 'You can share this link now',
        color: 'primary',
        time: 4000
      });
    },
    updatePhrase(payload) {
      // let new_highlight = {
      //   text: '@' + payload.entityInfo.name + '.' + payload.value,
      //   style: 'background-color:' + payload.entityInfo.highlightColor
      // };
      if (this.highlight.filter((array) => array.text === '@' + payload.entityInfo?.name + '.' + payload.value).length > 0) {
        //    this.$vs.notify({
        //   title: `This entity has been copied or used before.`,
        //   text: "Just saying....",
        //   color: "danger",
        //   time: 1000
        // });
        // console.log('Already copied')
      } else {
        this.highlight.push({
          text: '@' + payload.entityInfo.name + '.' + payload.value,
          style: 'background-color:' + payload.entityInfo.highlightColor
        });
      }

      this.$clipboard('' + '@' + payload.entityInfo.name + '.' + payload.value);

      this.$vs.notify({
        title: `Copy successful    <i class="fas fa-thumbs-up"></i>`,
        text: 'Please paste in training phrases',
        color: 'primary',
        time: 3000
      });

      // console.log(payload);
    },
    renderFunc(h, option) {
      return option.label;
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    notify(title_text, color_notify) {
      this.$vs.notify({
        title: title_text + '    ' + "     <i class='fas fa-save'></i>",
        text: 'Successfully saved to database',
        color: color_notify,
        time: 2000
      });
    },

    SendtoDB() {
      db.collection(this.collection)
        .doc(this.nodes.clicked.data.data.id)
        .collection('Utterances')
        .add({
          utterances: this.trainingphrases,
          user: this.email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          highlight: this.highlight
        })
        .then(() => {
          this.notify('Training Phrases saved', 'primary');
        })
        .catch(() => {
          this.$vs.notify({
            title: `Unable to save    <i class="fas fa-thumbs-down"></i>`,
            text: 'Please try again later',
            color: 'danger',
            time: 3000
          });
        });
    },
    SaveEntities(arrayofEntities) {
      let xx = arrayofEntities.map((a) => a.id);

      xx = xx.sort();
      const formattedArray = xx.join().toString();

      db.collection(this.collection)
        .where('entitiesFormatted', '==', formattedArray)

        .get()
        .then((snapshot) => {
          if (snapshot.empty || snapshot.docs[0].id === this.nodes.clicked.data.data.id) {
            db.collection(this.collection)
              .doc(this.nodes.clicked.data.data.id)
              .update({
                entitiesFormatted: formattedArray,
                entitiesChosen: this.entities_chosen
              })
              .then(() => {
                this.notify('Successfully saved to database', 'dark');
                this.entities_have_been_chosen = true;
              });
          } else {
            this.$vs.notify({
              title: `Entity combination already exists    <i class="fas fa-thumbs-down"></i>`,
              text: 'Must use different combination',
              color: 'danger',
              time: 3000
            });
          }
        });
    },
    onDrop(dropResult) {
      this.trainingphrases = applyDrag(this.trainingphrases, dropResult);
    },
    addResponse(type) {
      if (!this.entities_have_been_chosen) {
        this.$vs.notify({
          title: `Must choose entities first  <i class="fas fa-thumbs-down"></i>`,
          text: 'Please chose your entities',
          color: 'danger',
          time: 3000
        });
        return;
      }

      if (type === 'text') {
        this.trainingphrases.push({
          type: 'text',
          response: 'Hello',
          saved: this.email
        });
      }

      if (type === 'batch_text') {
        this.trainingphrases.push({
          type: 'batch_text',
          response: '',
          saved: this.email
        });
      }
    },
    AddUtterance(type, index) {
      this.trainingphrases.splice(index, 0, {
        type: 'batch_text',
        response: '',
        saved: this.email
      });
    },
    removeResponse() {
      this.trainingphrases.pop();
    },
    remove(item) {
      this.entities_chosen.splice(this.entities_chosen.indexOf(item), 1);
    },
    /**
     * @private
     */

    /**
     * @private
     */
    onData(current) {
      return current;
    },
    closeModal() {
      this.$emit('closed');
      this.$emit('closeModal');
    }
  },
  watch: {
    data: {
      handler(current) {
        this.onData(current);
      },
      deep: true
    }
    // entities_chosen: {
    //   handler() {
    //     this.yFilter = this.entities.map(itemY => {
    //       return itemY.id;
    //     });
    //   }
    // },
  }
};
</script>

<style lang="less">
.vs-component {
  font-size: 10px;
}

.el-tabs.el-tabs--left {
  height: 95%;
  border-radius: 15px;
}

.el-tabs__item.is-active {
  font-weight: 700;
  font-size: 16px;
}

.el-transfer-panel__item {
  height: 10px;
}

.el-select {
  display: inline-block;
  position: relative;
  width: 92%;
}

.el-transfer-panel__list.is-filterable {
  height: 400px;
}

.el-transfer-panel {
  max-height: 600px;
  height: 100%;
  border-radius: 15px;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}

.text {
  font-size: 10px;
}

.card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 13px;
}

.bubble {
  width: fit-content;
  background-color: #fb5422;
  padding: 3px;
  border-radius: 25px;
  color: black;
  float: right;
  font-size: 12px;
  margin-right: 10px;
  border: none;
}

.bubble.bot:before {
  display: none;
}

.bubble.bot {
  width: 30em;
  height: auto;
  background-color: white;
  padding: 3px;
  border-radius: 25px;
  color: black;
  font-size: 12px;
  margin-right: 1px;
  border: none;
}

.bubble.bot.batchtext {
  width: 36em;
  height: 6em;
  background-color: white;
  padding: 3px;
  border-radius: 15px;
  color: black;
  font-size: 12px;
  margin-right: 1px;
  border: none;
  overflow: hidden;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
  font-family: inherit;
}

.con-chips {
  height: 40px;
  border-radius: 15px;
  padding: 3px;
}

.suggestion {
  float: right;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  border: 0.3px solid white;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  box-shadow: 1px 1px 2px black;
}

.mdc-card {
  background-color: white;
  max-width: 50%;
  margin-bottom: 5px;
  animation: msg 0.45s ease-in-out;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.28);
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
}

.mdc-card__media-item {
  height: auto;
  width: 100%;
  margin-top: -5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.7;
  /* Firefox */
  font-style: italic;
}

.fullwrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
}

// ::-webkit-scrollbar {
//    width: 0.2em;
// }
// ::-webkit-scrollbar-track {
//    -webkit-box-shadow: inset 0 0 6p white;
// }
// ::-webkit-scrollbar-thumb {
//    background-color: lightgrey;
//    outline: 1px solid lightgray;
// }
</style>

<style scoped>
.wrapper.ai-window {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 8px;
  width: 450px;
  overflow-y: scroll;
}
</style>
