<template>
  <div class="drafts-container">
    <div id="expandButton" class="time-span-title text-start pb-2 mt-3" @click="triggerExpand">
      <!--
      <ConversationBuilderIcon
        :width="26"
        :name="$vnode.key === isExpanded ? 'arrow_down' : 'arrow_right'"
        class="draft-icon"
      />
      -->
      {{ title }}
    </div>
    <hr />
    <div v-for="(drafts, index) in draftList" :key="index">
      <div v-if="drafts.length > 0">
        <FlowVersions
          :key="index" :id="index" :created-at="parseDate(drafts[0].createdAt, showYear)" :filtered-drafts="drafts"
          :is-expanded="expandedDrafts" @expandDrafts="handleDraftsExpansion" :user-colors="userColors"
          :show-year="showYear" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  useAppStore,
  useAutoSaveStore,
  useCommentsStore,
  useHistoryStore,
  useNotificationsStore,
  useVcmStore,
  useVersionsStore
} from '@/store/index.js';
//DraftsContainer.vue ONLY USED FOR DRAFTS VERSIONS, NOT PUBLISHED VERSIONS.
import moment from 'moment';
import FlowVersions from './FlowVersions.vue';

export default {
  name: 'DraftsContainer',
  components: { FlowVersions },
  emits: ['expandDrafts'],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      expandedDrafts: this.id
    };
  },
  props: {
    id: {
      required: true
    },
    isExpanded: {
      required: true,
      default: false
    },
    userColors: {
      type: Array,
      required: true
    },
    draftList: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    showYear: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    triggerExpand() {
      this.$emit('expandDrafts', this.id);
    },
    parseDate(date, showYear) {
      return moment(date).format(showYear ? 'Y MMMM DD, h:mm a' : 'MMMM DD, h:mm a');
    },
    handleDraftsExpansion(value) {
      if (this.expandedDrafts === value) this.expandedDrafts = '';
      else this.expandedDrafts = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.current-draft-version {
  color: #2698ed;
  font-weight: 700;
  font-size: 12px;
}

.time-span-title {
  font-weight: 900;
  font-size: 16px;
}
</style>
