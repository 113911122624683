import { FlowUserRPCAdapter } from '@/helpers/rpc/FlowUserRPCAdapter';

export class CollabService {
  constructor({ store, options }) {
    this._store = store;
    this._options = options;
  }

  start() {
    const info = this._store.getInfo();
    const onFlowUpdate = this._store.onFlowUpdate;
    const onErrorOrConnectionChange = this._store.onErrorOrConnectionChange;

    FlowUserRPCAdapter.start({ ...info, onFlowUpdate, onErrorOrConnectionChange });
    this._store.start();
  }

  stop() {
    const info = this._store.getInfo();
    FlowUserRPCAdapter.stop(info);
    this._store.stop();
  }

  activity() {
    const info = this._store.getInfo();
    FlowUserRPCAdapter.onActivity(info);
  }

  setCollabMode(shouldEdit) {
    const info = this._store.getInfo();
    FlowUserRPCAdapter.setCollabMode(info, shouldEdit);
  }
}
