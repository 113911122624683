<template>
  <div class="filter-card" v-click-outside="closeFilterDrawer" @keydown.esc="closeFilterDrawer">
    <div class="card-content">
      <div class="all-status">
        <div class="single-checkbox">
          <div v-show="!showIcon">
            <input class="input-checkbox" v-model="allSelected" type="checkbox" @click="selectAll" />
          </div>
          <div v-show="showIcon" @click="selectAll">
            <IconUtility name="all-status" :width="17" :styleOnIcon="drawerStyleOnIcon" />
          </div>
          <h5>All Status</h5>
        </div>
      </div>

      <div class="all-status-content" v-for="(status, index) in filterData.status" :key="index">
        <div class="checkbox-container">
          <!-- <input class="input-checkbox" :value="filterData.status[index].value" type="checkbox" @input="toggleFilter(index)"/> -->
          <input class="input-checkbox" v-model="filterData.status[index].value" type="checkbox" />
          <h5>{{ status.name }}</h5>
        </div>
      </div>

      <div class="options">
        <div class="single-checkbox">
          <input class="input-checkbox" v-model="filterData.published" type="checkbox" />
          <h5>Published</h5>
        </div>
        <div class="single-checkbox">
          <input class="input-checkbox" v-model="filterData.watching" type="checkbox" />
          <h5>Watching</h5>
        </div>
      </div>
      <div class="buttons">
        <button @click="reset" type="button" class="btn btn-reset" data-test="btn-reset">Reset</button>
        <button @click="applyFilter" type="button" class="btn btn-primary newFlowButton" data-test="btn-apply">Apply Filter</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import vClickOutside from 'click-outside-vue3';
import cloneDeep from 'lodash/cloneDeep';
import IconUtility from './../Utilities/IconUtility.vue';

export default {
  name: 'FilterDrawer',
  components: {
    IconUtility
  },
  props: ['currentStatusData'],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      filterData: cloneDeep(this.currentStatusData),
      showIcon: false
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    allSelected() {
      const isAllSelected = !this.filterData?.status.some((status) => status.value === false);
      return isAllSelected;
    },
    drawerStyleOnIcon() {
      return {
        marginRight: '10px',
        width: '20px',
        height: '20px'
      };
    }
  },
  methods: {
    selectAll() {
      this.allSelected ? this.filterData.status.map((status) => (status.value = false)) : this.filterData.status.map((status) => (status.value = true));
    },
    applyFilter() {
      const filter = {
        ...this.filterData,
        email: this.appStore.user.email
      };
      this.$emit('closeFilterDrawer');
      this.$emit('applyFilter', filter);
    },
    closeFilterDrawer() {
      this.$emit('closeFilterDrawer');
    },
    reset() {
      this.filterData.status.map((status) => (status.value = false));
      this.filterData.published = false;
      this.filterData.watching = false;
      this.applyFilter();
      this.$emit('closeFilterDrawer');
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-card {
  display: flex;
  width: 290px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  z-index: 99;
  position: absolute;
  top: 48px;
  left: 50px;
}
.card-content {
  padding: 20px;
  width: 100%;
  height: 400px;
  max-height: 400px;
  width: 100%;
  overflow-y: scroll;
}
.all-status-content {
  padding-left: 20px;
}
.single-checkbox {
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.checkbox-container {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}
.input-checkbox {
  margin-right: 10px;
  border: solid 1px #8e9ba7;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.input-checkbox:checked {
  border-radius: 4px;
}
.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
}
.btn-reset {
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  width: 110px;
}

.btn-reset:hover {
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  width: 110px;
}
.btn-apply {
  width: 110px;
}

.newFlowButton {
  width: 120px;
  margin-left: 10px;
}
</style>
