<template>
  <!-- <div class="container-fluid"> -->
  <div style="background-color: #fb5422; height: 100vh">
    <br />
    <br />
    <br />
    <br />
    <br />
    <transition name="fade">
      <img :src="cHLogoFull" style="display: flex; align-items: center; justify-content: center; height: auto; padding: 7%; width: 100%" />
    </transition>

    <br />
    <div class="grid justify-items-center">
      <img :src="clientLogo" style="border-radius: 100px" width="200px" />
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { detect } from 'detect-browser';
const browser = detect();
import firebase from 'firebase/compat/app';

export default {
  name: 'AppHome',
  mounted() {
    // const name = store.currentUser.displayName
    if (browser) {
      if (this.appStore.browser != 'chrome') {
        this.$message({ message: 'Optimized for the Chrome browser' });
      }
    }
    const user = firebase.auth().currentUser;
    if (user && user.email) {
      this.appStore.updateUser({ user: user });
      this.$router.push('/agents');
    } else {
      this.$router.push('/login');
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      clientLogo: process.env.VUE_APP_CLIENT_LOGO,
      cHLogoFull: process.env.VUE_APP_CONVERSATIONHEALTH_FULL_LOGO
    };
  }
};
</script>

<style lang="less">
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}

.body {
  font-family: 'Avenir', sans-serif;
}

.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: table-cell;
  padding: 3px 8px;
  border-radius: 25px;
  background-color: #3c82f7;
  color: white;
  font-size: 20px;
}
</style>
