<template>
  <div>
    <div class="sidebar header edit-sidebar-header">
      <div class="editor-header row">
        <div class="col-10">
          <div>
            <ConversationBuilderIcon
              :name="type"
              :width="15"
              :custom-style="{ marginRight: '5px' }"
            />
            <span class="sidebar-type">{{ convertType }}</span>
          </div>
        </div>
        <div
          v-if="this.type !== 'start'"
          class="col-2"
          :title="`${convertType} options`"
        >
          <CustomPopover
            popper-class="containerOptionsSidebar"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: false,
            }"
            trigger="click"
          >
            <ContainerEdit
              :opened="true"
              :closeParent="closeContainer"
              :items="[
                {
                  icon: '',
                  title: 'Delete',
                  click: () => {
                    this.removeComponent()
                  },
                },
              ]"
            >
            </ContainerEdit>

            <template #reference>
              <ConversationBuilderIcon     
                :styleOnIcon="{ marginTop: '2px' }"
                :width="4"
                name="ellipsis"
              />
            </template>
          </CustomPopover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import ContainerEdit from '../Dropdowns/ContainerEdit';
export default {
  name: 'SidebarHeader',
  components: {
    ContainerEdit,
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
openContainerOptions: true,
    };
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useAppStore, ['componentId']),
    convertType() {
      switch (this.type) {
        case 'linkUrl':
          return 'Link URL';
        case 'start':
          return 'Root';
        case 'linkToContainer':
          return 'Link to Container';
        default:
          return this.type.charAt(0).toUpperCase() + this.type.slice(1);
      }
    },
  },
  methods: {
    closeContainer() {
      this.openContainerOptions = false;
    },
    removeComponent() {
      this.appStore.removeComponentAction({ id: this.componentId });
      this.appStore.removeEmptyUnconnectedContainers({});
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-header > .col-10 {
  display: flex;
  align-content: center;
  justify-content: space-between;
  max-width: 100%;
  padding: 0 20px 0 20px;
}
.edit-sidebar-header {
  height: 40px;
  border-bottom: solid 1px #d1d8e1;
  padding: 5px 0px 0px 10px;
}

.sidebar-type {
  font-size: 14px;
  color: #383f45;
  margin-left: 2px;
  font-weight: 500;
}

.sideBarIcon {
  margin-right: 5px;
  width: 15px;
  font-size: 14px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}
</style>
