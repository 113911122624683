<template>
  <div class="card border-0" :class="{ actionsOpened: actionsOpened }">
    <div v-for="(slotObj, nodeId, index) in item" :key="index" class="card-body border-0" :id="$index">
      Component type: {{ slotObj.component.type }}<br />
      Match: {{ slotObj.match }} <br />
      Flow: {{ slotObj.flowName }}
      <!-- <div class="cardInner" :id="index">
          <div class="row mainRow">
            <div class="col mb-1">
              <span @click="toggle(index)" class="expand">
                <div :class="{ opened: opened.includes(index) }" class="expand"><i :class="{ fas: true, 'fa-caret-right': !opened.includes(index), 'fa-caret-down': opened.includes(index) }"></i></div>
              </span>
              <span v-if="slot !== undefined" class="itemName">{{ slot.find((x) => x.id === nodeId)['name'] || '' }}</span>
              <span class="arrayLength">({{ findLength(slotObj) }})</span>
            </div>
          </div>
        </div>
        <div v-if="opened.includes(index)" :class="{ accordion: true, opened: opened }" :class1="$index">
          <div v-for="(node, index) in slotObj" :key="index" class="textResponse">
            <p v-for="(arrayList, index) in node" :key="index" class="queryText">
              <span v-html="arrayList.matchedResponse" class="queryText"></span>
            </p>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import moment from 'moment';

export default {
  name: 'FindReplaceCard',
  components: {},
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      opened: [],
      loading: 0,
      actionsOpened: false
    };
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  },
  props: {
    item: {
      type: Object
    },
    intent: {
      type: Array
    },
    intentId: {
      type: Array
    }
  },
  async mounted() {},
  methods: {
    findLength(obj) {
      let length = 0;
      for (const id in obj) {
        length = length + obj[id].length;
      }
      return length;
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },
    moment,
    utilitiesConcat(item) {
      if (this.appStore.jobs[item.id]['clearHighlights'])
        return this.appStore.jobs[item.id]['pdfExport'].concat(this.appStore.jobs[item.id]['clearHighlights']).sort((a, b) => {
          return b.job_creation_date && a.job_creation_date ? b.job_creation_date._seconds - a.job_creation_date._seconds : false;
        });
      else return this.appStore.jobs[item.id]['pdfExport'];
    },
    async toggleCard() {
      this.opened = !this.opened;
      await this.refreshHistory();
    },
    openPage(url) {
      window.open(url, '__blank');
    },
    getColor(status) {
      const colors = {
        running: '#1996f0',
        complete: '#6cc900',
        completed: '#6cc900',
        error: '#fd5422',
        failed: '#fd5422'
      };
      return colors[status.toLowerCase()];
    },
    async refreshHistory() {
      this.loading = 1;
      await this.appStore.getJobs({ taxonomyId: this.item.id });
      this.loading = 0;
      //this.$forceUpdate();
    },
    async toggleActions() {
      this.actionsOpened = !this.actionsOpened;
    }
  }
};
</script>

<style scoped>
.arrayLength {
  color: #0645ad;
  font-size: 15px;
  margin: 10px 5px;
}
.card-body {
  margin-bottom: 20px;
}

.itemName {
  font-size: 14px;
}
.cardInner {
  width: 100%;
  height: 40px;
}
.cardInner button {
  margin-left: 10px;
}
.cardInner .mainRow,
.cardInner .mainRow .col {
  line-height: 50px;
}
.cardInner button.vs-con-dropdown.parent-dropdown {
  margin-left: 0px !important;
}
.mainRow .expand {
  display: inline-block;
}
.mainRow .expand > div {
  color: #383f45;
  margin-right: 20px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
  font-size: 15px;
}
.mainRow .expand > div:hover {
  background-color: #edf0f4;
}

.accordion {
  display: none;
  text-align: left;
  /* padding: 20px 20px 10px 20px;
  min-height: 100px; */
}
.accordion.opened {
  display: block;
  padding: 0 30px 0 70px;
}

.mainRow .expand > div {
  color: #383f45;
  margin-right: 20px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
  font-size: 15px;
}
.slotName {
  font-weight: 900;
}
.card {
  margin: 10px 0;
  font-size: 17px;
}
.card-body {
  min-height: 68px;
  padding: 10px;
}
.cardInner {
  width: 100%;
  height: 40px;
}
.cardInner button {
  margin-left: 10px;
}
.cardInner .mainRow,
.cardInner .mainRow .col {
  height: 40px;
  line-height: 50px;
}

.mainRow .expand {
  display: inline-block;
}

.fas.fa-caret-down.hidden,
.fas.fa-caret-right.hidden {
  visibility: hidden;
}

.queryText {
  margin-bottom: 10px;
}

.card-body {
  min-height: 30px;
  padding: 0px;
}

.mb-1 {
  margin-bottom: 0rem !important;
}

.mainRow .expand > div {
  color: #383f45;
  margin-right: 20px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
  font-size: 15px;
}
.mainRow .expand > div:hover {
  background-color: #edf0f4;
}
.itemName {
  /* margin: 20px 0px; */
  /* padding-bottom: 10px; */
  font-size: 14px;
}

.buttonIcon {
  margin-right: 5px;
}
.item-group {
  list-style-type: none;
}
.cardInner button.vs-con-dropdown.parent-dropdown {
  margin-left: 0px !important;
}

.card.actionsOpened .btnactions {
  background: #fff !important;
  border-color: #fd5422 !important;
}

.buttons {
  display: none;
}
.card:hover .created,
.card.actionsOpened .created {
  display: none;
}
.card:hover .buttons,
.card.actionsOpened .buttons {
  display: block;
}
.actions {
  position: absolute;
  display: none;
  right: 18px;
  top: 68px;
  z-index: 10;
  padding: 20px 20px 5px 20px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.5);
  background-color: #ffffff;
  min-width: 280px;
}
.card.actionsOpened .actions {
  display: block;
}
.buttons button {
  padding: 0 20px;
}
.buttons button img {
  margin-right: 5px;
  display: inline-block;
}
.actionsMenu,
.actionHistory {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}
.actionHistory li:not(:first-child) {
  border-top: solid 1px #e3e8ee;
}
.actionsTable td,
.actionsTable th {
  font-size: 14px;
}
.actionsTable {
  width: 100%;
}

.actionsTable td {
  padding: 6px 10px;
  padding-top: 5px;
  padding-bottom: 3px;
}
.actionsTable tr:hover td {
  background-color: #e8f5fe;
}
.actionsDownload {
  height: 30px;
  padding: 4px 21px 7px 22px;
  width: 105px;
  visibility: hidden;
}
.actionsTable tr:hover .actionsDownload {
  visibility: visible;
}
.fa-refresh {
  cursor: pointer;
  float: right;
  margin-top: -2px;
  margin-right: -10px;
  font-size: 16px;
}
</style>
