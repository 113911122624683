<template>
  <div>
    <div class="sidebar components" style="padding-bottom: 4px">
      <div class="row editor-header">
        <RadioButton :key="componentKey" :payload="{ viewType: 'basic' }" :onSelect="editViewType" :selected="componentSelected.viewType === 'basic' || !componentSelected.viewType ? true : false" :componentId="componentSelected.id">
          <template v-slot:title>
            <span class="radio-button-title-sm">Basic view</span>
          </template>
        </RadioButton>
        <RadioButton :textWidthIncrease="2" :key="componentKey" :payload="{ viewType: 'advanced', advancedViewType: 'subpath' }" :onSelect="editViewType" :selected="componentSelected.viewType === 'advanced'" :componentId="componentSelected.id">
          <template v-slot:title>
            <span class="radio-button-title-sm">Advanced view</span>
          </template>
        </RadioButton>
      </div>
    </div>
    <div v-if="!carousel" class="sidebar components link-url">
      <SidebarText :field="`label`" :placeHolder="'Label component'" :id="componentSelected.id" :mainComponentId="componentSelected.mainCarouselId ? componentSelected.mainCarouselId : ''">
        <template v-slot:header>
          <div class="row editor-header">
            <div class="col-11">
              <span class="sidebar type">Label</span>
            </div>
          </div>
        </template>
      </SidebarText>
    </div>
    <div class="sidebar components link-url">
      <SidebarText :field="`url`" :placeHolder="'Add URL'" :id="componentSelected.id" :mainComponentId="componentSelected.mainCarouselId ? componentSelected.mainCarouselId : ''">
        <template v-slot:header>
          <div class="row editor-header">
            <div class="col-11">
              <span class="sidebar type">Link URL</span>
            </div>
          </div>
        </template>
      </SidebarText>
    </div>
    <hr v-if="!carousel" class="advanced-divider" />
    <div v-if="componentSelected.viewType === 'advanced'">
      <div class="sidebar components" style="padding-bottom: 4px">
        <div class="row editor-header">
          <RadioButton :key="componentKey" :payload="{ advancedViewType: 'subpath' }" :onSelect="editViewType" :selected="componentSelected.advancedViewType === 'subpath' || !componentSelected.advancedViewType ? true : false" :componentId="componentSelected.id">
            <template v-slot:title>
              <span class="radio-button-title-sm">Sub-path</span>
            </template>
          </RadioButton>
          <RadioButton :textWidthIncrease="2" :key="componentKey" :payload="{ advancedViewType: 'queryParameters' }" :onSelect="editViewType" :selected="componentSelected.advancedViewType === 'queryParameters'" :componentId="componentSelected.id">
            <template v-slot:title>
              <span class="radio-button-title-sm">Query Parameters</span>
            </template>
          </RadioButton>
        </div>
      </div>
      <div v-if="componentSelected.advancedViewType === 'subpath'" class="sidebar components link-url">
        <SidebarText :field="`subpath`" :placeHolder="'Add sub-path'" :id="componentSelected.id" :mainComponentId="componentSelected.mainCarouselId ? componentSelected.mainCarouselId : ''" />
      </div>
      <div v-if="componentSelected.advancedViewType === 'queryParameters'" class="query-parameters-container" :key="parameterKey">
        <div class="sidebar components" v-for="(param, index) in queryParameters" :key="index">
          <LinkUrlParameterEach :param="param" :index="index" :paramsCount="queryParameters.length" :componentId="id" :mainComponentId="componentSelected.mainCarouselId ? componentSelected.mainCarouselId : ''" @update-key="updateComponentKey" />
        </div>
      </div>
      <div v-if="componentSelected.advancedViewType === 'queryParameters'">
        <hr class="advanced-divider" />
        <AddOption :width="24" :func="addQueryParameter" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
import LinkUrlParameterEach from './LinkUrlParameterEach.vue';
import AddOption from '../Buttons/AddOption.vue';
export default {
  name: 'LinkUrlEdit',
  mixins: [essentials],
  methods: {
    updateComponentKey() {
      this.parameterKey += 1;
      this.$forceUpdate();
    },
    editViewType(payload) {
      if (payload.viewType === 'basic') {
        this.appStore.removeLinkUrlAdvancedData({
          linkUrlComponentId: this.id,
          subpath: true,
          queryParameters: true
        });
      }

      if (payload.advancedViewType === 'subpath') {
        this.appStore.removeLinkUrlAdvancedData({
          linkUrlComponentId: this.id,
          mainCarouselId: this.mainComponentId,
          queryParameters: true
        });
      }

      if (payload.advancedViewType === 'queryParameters') {
        this.appStore.removeLinkUrlAdvancedData({
          linkUrlComponentId: this.id,
          mainCarouselId: this.mainComponentId,
          subpath: true
        });
      }
      if (this.mainComponentId && this.mainComponentId !== '') {
        payload['mainCarouselId'] = this.mainComponentId;
      }
      this.appStore.updateComponent({ ...payload, id: this.id });
      this.componentKey += 1;
    },
    addQueryParameter() {
      if (this.mainComponentId && this.mainComponentId !== '') {
        this.appStore.addItemToCollection({
          componentId: this.id,
          collectionField: 'queryParameters',
          mainCarouselId: this.mainComponentId
        });
      } else {
        this.appStore.addItemToCollection({
          componentId: this.id,
          collectionField: 'queryParameters'
        });
      }
      this.parameterKey += 1;
    }
  },
  computed: {
    componentSelected() {
      if (this.mainComponentId && this.mainComponentId !== '') {
        const selectedComponent = this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId);
        return selectedComponent?.carousels.find((x) => x.id === this.id);
      } else {
        return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id);
      }
    },
    queryParameters() {
      return this.componentSelected?.queryParameters || [];
    }
  },
  props: {
    id: {},
    text: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    carousel: {},
    mainComponentId: {}
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      componentKey: 0,
      parameterKey: 0,
      time: '1 sec',
      taxonomy: 'COVID_TEST',
      intent: 'SA2_WarningPrecautions'
    };
  },
  components: {
    LinkUrlParameterEach,
    AddOption
  }
};
</script>

<style scoped>
.query-parameters-container {
  padding-left: 0px;
}

.advanced-divider {
  border: solid 1px #d1d8e1;
  margin-top: 30px;
}

.radio-button-title-sm {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #2f2f2f;
}

.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}

.sidebar.components {
  /* border-bottom: 1px solid #e3e8ee; */
  padding: 15px 15px 0px 15px;
  height: max-content;
}

.sidebar.components.link-url {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 0px 15px;
  height: 80px;
  margin-bottom: 15px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
  text-align: left;
  line-height: 1.35;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
