<template>
  <div @click="onSelect(payload)" :class="`radio-button-container col-${5 + textWidthIncrease}`">
    <!-- <i :class="`bi bi-record-circle${selected ? '-fill' : ''}`"></i> -->
    <i :class="`bi ${selected ? 'bi-record-circle-fill' : 'bi-circle'}`"></i>
    <slot name="title"></slot>
  </div>
</template>

<script>
export default {
  props: {
    componentId: {},
    textWidthIncrease: {
      default: 0
    },
    selected: {},
    onSelect: {},
    payload: {}
  }
};
</script>

<style lang="scss" scoped>
.bi.bi-record-circle-fill {
  color: #1996f0;
  padding-right: 5px;
  font-size: 17px;
  margin-top: 2px;
}

.bi.bi-circle {
  color: #d7dde5;
  padding-right: 5px;
  font-size: 17px;
  margin-top: 2px;
}

.fas.fa-info-circle {
  margin-left: 5px;
}

.radio-button-container {
  display: flex;
  align-items: center;
}
</style>
