import { Node, mergeAttributes } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { Fragment } from '@tiptap/pm/model';

export const EntityMention = Node.create({
  name: 'entityMention',

  addOptions: {
    matcher: {
      char: '@',
      allowSpaces: false,
      startOfLine: false
    },
    mentionClass: 'mentionEntity',
    suggestionClass: 'mention-suggestion'
  },

  group: 'inline',
  inline: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null
      },
      label: {
        default: null
      },
      highlightColor: {
        default: null
      },
      name: {
        default: null
      },
      role: {
        default: null
      },
      value: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.mentionEntity',
        getAttrs: (dom) => {
          const id = dom.getAttribute('id');
          const name = dom.getAttribute('name');
          const highlightColor = dom.getAttribute('highlightColor');
          const label = dom.textContent.split(this.options.matcher.char).join('');
          const role = dom.getAttribute('role');
          const value = dom.getAttribute('value');
          const customAttributes = { id, label, highlightColor, name, role, value };
          return customAttributes;
        },
        getContent: (dom) => {
          const label = dom.textContent.split(this.options.matcher.char).join('');
          return this.createFragment(this.editor.schema, label);
        }
      }
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        {
          ...node.attrs,
          class: this.options.mentionClass,
          style: `background: ${node.attrs.highlightColor}`
        },
        HTMLAttributes
      ),
      `${this.options.matcher.char}${node.attrs.label}`
    ];
  },

  addCommands() {
    return {
      insertMention: ({ range, attrs }) => {
        return ({ commands }) => {
          commands.deleteRange(range);
          commands.insertContentAt(range.from, {
            type: this.name,
            attrs
          });
          return true;
        };
      }
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.options.matcher,
      callback: () => {
        this.editor.commands.insertMention(this.options.attrs);
        return true;
      }
    };
  },

  addProseMirrorPlugins() {
    const plugins = [];
    plugins.push(
      Suggestion({
        ...this.options,
        command: ({ props }) => this.editor.commands.insertMention(props),
        items: ({ query }) => this.options.onFilter(this.options.items(), query),
        render: () => {
          return {
            onStart: (props) => {
              const decorationId = props.decorationNode.dataset.decorationId;
              const element = document.querySelector(`[data-decoration-id="${decorationId}"]`);
              return this.options.onStart({ ...props, virtualNode: element || null });
            },
            onUpdate: (props) => this.options.onUpdate(props),
            onKeyDown: (props) => this.options.onKeyDown(props),
            onExit: () => this.options.onExit()
          };
        },
        editor: this.editor
      })
    );

    return plugins;
  },

  createFragment(schema, label) {
    return Fragment.fromJSON(schema, [{ type: 'text', text: `${this.options.matcher.char}${label}` }]);
  }
});
