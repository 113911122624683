<template>
  <div class="conversation-builder-wrapper" @dragover="dragFileOver" @dragleave="dragFileLeave" @drop="dropFile">
    <transition name="slide">
      <div class="restored-popup" v-if="getRestoredPopupDate">
        <p>
          Restored version: <b>{{ getRestoredText }}</b>
        </p>
      </div>
    </transition>
    <transition name="slide">
      <div class="notification-popup" v-if="notificationPopupText">
        <p>{{ notificationPopupText }}</p>
      </div>
    </transition>
    <div class="restore-popup-container" v-if="getRestoreVersionId">
      <div class="restore-popup">
        <p>Restore session</p>
        <span>Your current document will revert to the version from:</span>
        <b>{{ getRestorePopupText }}</b>
        <div class="restore-popup-buttons">
          <button @click="() => setRestoreVersionId(undefined)">Cancel</button>
          <button class="restore-button" @click="handleRestoreVersion">Restore</button>
        </div>
      </div>
    </div>
    <NavBar :slotName="getFlowName()" />
    <div id="conversation-builder">
      <div class="cmp-wrapper">
        <FlowComponentLibrary v-if="builderCanEdit" />
        <MainCanvas ref="mainCanvas" v-if="hasLinks && loadCanvas && !loadPrintCanvas" :collection="collection" @nodeClick="nodeClick" @preventSidebarOpening="preventSidebarOpening" @canvasClick="canvasClick" :height="2000" />
        <PrintCanvas ref="printCanvas" v-if="hasLinks && loadPrintCanvas" :collection="collection" :flowName="getFlowName()" @nodeClick="nodeClick" :height="2000" />
        <NotesSideBar v-if="sidebarToggle" hidden-background parent=".flowchart-containter" :reduce="false" reduce-not-rebound :reduce-not-hover-expand="false" default-index="1" :position-right="true" color="success" class="sidebarx" spacer :value="value">
          <EditSidebar v-if="isBuilderMode" :id="activeComponentId" />
          <CommentsSidebar v-if="isCommentsMode" />
          <VersionsSidebar v-if="isVersionsMode" />
          <PreviewSidebar v-if="isPreviewMode" :slotInfo="slotInfo" :collection="collection" />
          <PDFPreviewSidebar v-if="isPDFExportMode" :flowName="getFlowName()" />
        </NotesSideBar>
      </div>
    </div>
    <BottomNav v-if="!isPDFExportMode" @zoom="executeZoom" />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { useAppStore, useCommentsStore, useVersionsStore, useVcmStore } from '@/store/index.js';
// TODO: This needs refactor
import 'vue-simple-flowchart/dist/vue-flowchart.css';
import FlowComponentLibrary from './Sidebar/3.0/FlowComponentLibrary';
import EditSidebar from './Canvas/3.0/SidebarComponents/EditSidebar';
import NavBar from './Sidebar/3.0/NavBar';
import BottomNav from './Canvas/3.0/BottomNavigation/BottomNav.vue';
import NotesSideBar from './Sidebar/NotesSideBar.vue';
import CommentsSidebar from './Sidebar/3.0/Comments/CommentsSidebar.vue';
import VersionsSidebar from './Sidebar/3.0/Versions/VersionsSidebar.vue';
import { mapActions, mapState } from 'pinia';
import moment from 'moment';
import PreviewSidebar from './Sidebar/3.0/Preview/PreviewSidebar.vue';
import PDFPreviewSidebar from './Sidebar/3.0/PDFPreview/PDFPreviewSidebar.vue';
import Ajv from 'ajv';
import { flowSchema } from '../Utilities/constants/constants';
import axios from 'axios';
const agv = new Ajv();
const flowValidate = agv.compile(flowSchema);

export default {
  name: 'ConversationBuilder',
  components: {
    EditSidebar,
    NavBar,
    FlowComponentLibrary,
    NotesSideBar,
    CommentsSidebar,
    BottomNav,
    VersionsSidebar,
    PreviewSidebar,
    PDFPreviewSidebar
  },
  props: {
    flowName: {
      required: true,
      type: String
    },
    flowId: {
      required: true,
      type: String
    },
    slots: {
      required: false,
      type: Array
    },
    collection: {
      required: true,
      type: String
    },
    commentId: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      vcmStore: useVcmStore(),
      drawer: true,
      mouse: {
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0
      },
      loadCanvas: false,
      slotInfo: {
        id: this.flowId,
        name: this.flowName
      },
      sidebarToggle: true,
      showNotificationPopup: false,
      notificationPopupText: '',
      draggingStart: false,
      dragCounter: 0,
      loadNotificationTimeout: null,
      loadRestoreTimeout: null
    };
  },
  beforeMount() {
    // if (this.collection && this.flowId && this.flowName) {
    //   this.slotInfo = { id: this.flowId, name: this.flowName };
    //   return;
    // }
    //set current taxonomy before the main canvas loads;
    this.appStore.SET_CURRENT_TAXONOMY({
      taxonomy: this.collection,
      slot: this.slotInfo
    });
    this.loadCanvas = true;
  },
  async mounted() {
    await this.appStore.createStart({ type: 'start' });
    await this.appStore.SET_INVALID_ACTIONS_COUNT();
    try {
      const commentId = this.$route?.query?.commentId || this.commentId;
      if (commentId) {
        const comment = this.getComments.find((comment) => comment.id === commentId);
        const childComment = this.getComments.find((comment) => comment.childComments.some((child) => child.id === commentId));

        if (!comment && !childComment) {
          this.notificationPopupText = 'The comment does not exist.';

          this.loadNotificationTimeout = setTimeout(() => {
            this.notificationPopupText = undefined;
          }, 1500);

          return;
        }

        const isChildCommentTagged = childComment?.childComments.find((comment) => comment.text.includes(this.getUserLess.displayName));

        if (!comment?.text.includes(this.getUserLess.displayName) && !isChildCommentTagged) {
          this.notificationPopupText = 'Tag from the comment has been removed.';

          this.loadNotificationTimeout = setTimeout(() => {
            this.notificationPopupText = undefined;
          }, 1500);
        }

        this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'comments' });
        this.commentsStore.SET_COMMENT_FILTERS({
          filterName: 'resolved',
          filterValue: comment?.resolved
        });
        this.commentsStore.goToComment({
          id: comment ? commentId : childComment.id
        });
      }
      if (this.$route.query.pdf) {
        this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'pdfExport' });
        this.appStore.SET_ORIGINAL_NODE_POSITIONS({
          nodes: this.appStore.getScene.nodes
        });
        this.commentsStore.CLEAR_PENDING();
        this.showPublishMenu = false;
      }
    } catch (err) {
      console.error('Error: ', err.message, err);
    }
  },
  methods: {
    ...mapActions(useAppStore, ['addNodeSync', 'updateScene', 'sendSceneToDB', 'undo', 'redo']),
    ...mapActions(useVersionsStore, ['setRestoredPopupDate', 'setRestoredPopupName', 'setRestoreVersionId', 'restoreVersion']),
    async handleRestoreVersion() {
      await this.restoreVersion(this.getRestoreVersionId);
    },
    canvasClick() {
      this.appStore.setComponentId(false);
      this.appStore.SET_SIDEBAR_ACTIVE({ bool: false });
    },
    getFlowName() {
      return this.flowName;
    },
    dragFileOver(event) {
      event.preventDefault();
    },
    dragFileLeave(event) {
      event.preventDefault();
    },
    nodeClick(id) {
      if (id === 'start-id' && this.draggingStart === true && this.dragCounter < 2) {
        this.sidebarToggle = false;
        this.dragCounter += 1;
        return;
      }

      this.draggingStart = false;
      this.sidebarToggle = true;
    },
    preventSidebarOpening() {
      this.dragCounter = 0;
      this.draggingStart = true;
    },
    async dropFile(event) {
      try {
        event.preventDefault();
        let filesArr = event.dataTransfer.files;
        filesArr = [...filesArr];
        const reader = new FileReader();

        reader.onload = async () => {
          let fileObj;
          try {
            fileObj = JSON.parse(reader.result);
          } catch (error) {
            return alert('Not a valid JSON.');
          }
          const invalidMessage = 'JSON structure is not valid.';
          const isValid = flowValidate(fileObj);
          if (!isValid) return alert(invalidMessage);

          const newUxArr = [];
          const overWrite = confirm('Doing the operation will overwrite the current canvas, do you still want to proceed?');

          if (overWrite) {
            try {
              const uxStateConverted = (
                await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/transform/flowConvert/v1`, {
                  ...fileObj
                })
              ).data;
              newUxArr.push(uxStateConverted?.uxState);
              await this.appStore.RESTORE_FLOW({ uxState: newUxArr, type: 'import' });
              this.$refs.mainCanvas.transformHasLinkToLinkedDecisionContainerInfo();
            } catch (error) {
              return alert(invalidMessage);
            }
            return newUxArr;
          }
        };
        if (filesArr.length) reader?.readAsText(filesArr[0]);
      } catch (error) {
        console.log(error);
        return;
      }
    },
    executeZoom(val) {
      const currentZoom = this.$refs.mainCanvas.viewer.zoom;
      let arr = [0.1, 0.15, 0.25, 0.33, 0.5, 0.75, 1, 1.5, 2.0];

      function findNextStepUp(value) {
        let i = arr.length;
        while (arr[--i] > value);
        return arr[++i];
      }
      function findNextStepDown(value) {
        let i = arr.length;
        arr = arr.reverse();
        while (arr[--i] < value);
        return arr[++i];
      }
      if (typeof val === 'number') {
        this.$refs.mainCanvas.viewer.zoom = val;
        this.appStore.setZoomLevel({
          zoomLevel: this.$refs.mainCanvas.viewer.zoom
        });
        return;
      } else {
        if (!val) {
          this.$refs.mainCanvas.viewer.zoom = currentZoom;
          this.appStore.setZoomLevel({ zoomLevel: currentZoom });
        }
        if (val === 'home') {
          this.$refs.mainCanvas.viewer.zoom = 1;
          this.$refs.mainCanvas.moveToCenter();
        }
        if (val === 'in') this.$refs.mainCanvas.viewer.zoom = findNextStepUp(currentZoom);
        if (val === 'out') this.$refs.mainCanvas.viewer.zoom = findNextStepDown(currentZoom);
      }
      this.appStore.setZoomLevel({
        zoomLevel: this.$refs.mainCanvas.viewer.zoom
      });
      // });
    },
    cancelRestore() {
      this.setRestoreVersionId(undefined);
    }
  },
  computed: {
    ...mapState(useAppStore, ['getUserLess', 'currentTaxonomyId', 'getScene', 'displayMode', 'getCanvasDisabled', 'isSideBarActive']),
    ...mapState(useCommentsStore, ['getComments']),
    ...mapState(useVersionsStore, ['getRestoredPopupDate', 'getRestoredPopupName', 'getVersions', 'getRestoreVersionId']),
    value() {
      if (this.displayMode === 'comments' || this.displayMode === 'versions' || this.displayMode === 'preview' || this.displayMode === 'pdfExport') {
        return this.displayMode;
      }
      return this.appStore.componentId;
    },
    builderCanEdit() {
      const canEdit = this.displayMode === 'builder' && !this.getCanvasDisabled;
      if (!canEdit && this.isSideBarActive) {
        nextTick(() => {
          this.canvasClick();
        });
      }
      return canEdit;
    },
    loadPrintCanvas() {
      return this.displayMode === 'pdfPreview';
    },
    getRestoredText() {
      return this.getRestoredPopupName ? this.getRestoredPopupName : moment(this.getRestoredPopupDate).format('MMMM DD, h:mm a');
    },
    getRestorePopupText() {
      const version = this.getVersions.find((version) => version.id === this.getRestoreVersionId);
      return version?.versionName ? version.versionName : moment(version.createdAt).format('MMMM DD, h:mm a');
    },
    isBuilderMode() {
      return this.displayMode === 'builder';
    },
    shouldDisplayCanvas() {
      return this.appStore.getScene && this.appStore.getScene.links;
    },
    activeComponentId() {
      return this.appStore.componentId || this.appStore.activeContainer;
    },
    isCommentsMode() {
      return this.displayMode === 'comments';
    },
    isVersionsMode() {
      return this.displayMode === 'versions';
    },
    isPreviewMode() {
      return this.displayMode === 'preview';
    },
    isPDFExportMode() {
      return this.displayMode === 'pdfExport';
    },
    hasLinks() {
      return this.appStore.getScene && this.appStore.getScene.links;
    }
  },
  watch: {
    getRestoredPopupDate() {
      //Every time the restored popup shows up, make it disappear after 2 seconds
      this.loadRestoreTimeout = setTimeout(() => {
        this.setRestoredPopupDate(0);
        this.setRestoredPopupName('');
      }, 2000);
    }
  },
  beforeUnmount() {
    this.appStore.setActiveContainer(null);
    this.appStore.SET_SELECTED_CONTAINERS([]);
    this.loadCanvas = false;
    clearTimeout(this.loadNotificationTimeout);
    clearTimeout(this.loadRestoreTimeout);
  }
};
</script>

<style lang="scss">
.restore-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #8e9ba8;
  z-index: 9999;
  cursor: default;

  .restore-popup {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 467px;
    height: 235px;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);

    p {
      font-size: 24px;
      line-height: 0.92;
      text-align: left;
      color: #383f45;
      margin-bottom: 20px;
    }

    span {
      font-size: 17px;
      line-height: 1.29;
      text-align: left;
      color: #383f45;
    }

    b {
      font-size: 17px;
      line-height: 1.29;
      text-align: left;
      color: #383f45;
    }

    .restore-popup-buttons {
      width: 100%;
      height: 48px;
      display: flex;
      margin-top: 30px;

      button {
        width: 120px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        background-color: #f2f2f2;
        margin-right: 10px;
        text-decoration: underline;
      }

      .restore-button {
        background-color: #1996f0;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.trash-class {
  display: none !important;
}

.notification-popup {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 376px;
  height: 83px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  border: solid 1px #fa552f;
  background-color: #fff;
  z-index: 20;
}

.restored-popup {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 376px;
  height: 83px;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  border: solid 1px #fa552f;
  background-color: #fff;
  z-index: 20;
}

.slide-enter {
  transform: translateX(-50%) translateY(-100px);
  opacity: 0;
  left: 50%;
}

.slide-enter-active {
  transition: all 0.5s ease;
}

.slide-enter-to {
  opacity: 1;
}

.slide-leave-active {
  transition: all 0.5s ease;
}

.slide-leave-to {
  transform: translateX(-50%) translateY(-100px);
  opacity: 0;
  left: 50%;
}

.zoomPercent {
  background-color: #fff;
  position: absolute;
  z-index: 5;
  bottom: 30px;
  left: 260px;
  padding: 13px 19px 12px;
  color: #383f45;
  border-radius: 4px;
}
button.undo-button,
button.redo-button {
  margin-left: 5px;
  margin-right: 5px;
}

.undo-redo {
  background-color: #fff;
  position: absolute;
  z-index: 5;
  bottom: 30px;
  right: 260px;
  padding: 13px 19px 12px;
  color: #383f45;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px;
}

.el-radio-button__inner {
  padding: 2px;
}
.conversation-builder-wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
}
.cmp-wrapper {
  flex-grow: 1;
  height: 100%;
  max-height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  //set to scroll if scrolling issues
}

#conversation-builder::-webkit-scrollbar {
  display: none;
}

#conversation-builder {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  overflow: hidden;
  background-repeat: initial;
  height: 100%;
  max-height: 100%;
  display: flex;
  z-index: 1;

  .tool-wrapper {
    position: relative;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .clicked.name {
    font-size: 30px;
    font-weight: bold;
    width: fit-content;
    border: none;
  }
  .node-label {
    font-size: 15px;
  }
  // #panzoom-wrapper {
  //   background: linear-gradient(-45deg, #FFFFFF, #FFFFFF, whitesmoke, #3c9191);
  //   animation: gradientBG 10s ease infinite;
  // }

  .flowchart-container {
    height: 7000px;
    width: 2500px;
    // overflow: scroll;
    // border: 3px dashed black;
  }
  #canvasBackground {
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
    background-size: 20px 20px;
    background: #edf0f4;
    // background-image: linear-gradient(to right, lightgrey 1px, transparent 1px), linear-gradient(to bottom, darkgrey 1px, whitesmoke 1px);
    // background-repeat: repeat;
    @keyframes gradientBG {
      0% {
        background-position: 0% 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0% 50%;
      }
    }
  }
}

@media print {
  #conversation-builder {
    overflow: visible !important;
  }
  .cmp-wrapper {
    overflow: visible !important;
  }
  .sidebarx {
    display: none !important;
  }
}
</style>
