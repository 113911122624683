<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <v-select
        :append-to-body="appendToBody ? true : false"
        class="universal-dropdown"
        :clearable="false"
        filterable
        v-model="itemSelected"
        :placeholder="placeHolder"
        :reduce="(data) => data"
        :label="label"
        :options="data"
        autocomplete
      />
    </div>
  </div>
</template>

<script>
import dropdowns from '../Mixins/dropdowns';
export default {
  name: 'UniversalSidebarDropdown',
  mixins: [dropdowns],
  props: {
    placeHolder: {
      default: 'Select',
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    data: {},
    label: {},
  },
};
</script>

<style>
@import './Dropdowns.scss';
</style>
