import { Node, nodeInputRule } from '@tiptap/core';
import { storage } from '@/main.js';

const IMAGE_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;
const EXTENSION_NAME = 'image';
export const Image = Node.create({
  name: EXTENSION_NAME,

  addOptions: {
    HTMLAttributes: {}
  },

  inline: true,

  group: 'inline',

  draggable: true,

  addAttributes() {
    return {
      src: {
        default: null
      },
      alt: {
        default: null
      },
      title: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
        getAttrs: (dom) => ({
          src: dom.getAttribute('src'),
          alt: dom.getAttribute('alt'),
          title: dom.getAttribute('title')
        })
      }
    ];
  },

  renderHTML({ node }) {
    return ['img', { ...node.attrs, class: 'editorImage' }];
  },

  addCommands() {
    return {
      setImage:
        (attributes) =>
        ({ commands }) => {
          return commands.insertContent({
            type: EXTENSION_NAME,
            attrs: attributes
          });
        }
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: IMAGE_INPUT_REGEX,
        type: EXTENSION_NAME,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;
          return { src, alt, title };
        }
      })
    ];
  },

  addProseMirrorPlugins() {
    return [
      {
        props: {
          handleDOMEvents: {
            drop: (view, event) => {
              const hasFiles = event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length;

              if (!hasFiles) {
                return;
              }

              const images = Array.from(event.dataTransfer.files).filter((file) => /image/i.test(file.type));

              if (images.length === 0) {
                return;
              }

              event.preventDefault();
              const coordinates = view.posAtCoords({
                left: event.clientX,
                top: event.clientY
              });

              images.forEach((image) => {
                const storageRef = storage.ref(`Images/${image.name}-${Date.now()}`).put(image);

                storageRef.on(
                  'state_changed',
                  (snapshot) => {
                    console.log(snapshot);
                  },
                  (error) => {
                    console.error('Error: ', error.message, error);
                  },
                  () => {
                    storageRef.snapshot.ref.getDownloadURL().then((url) => {
                      const transaction = view.state.tr.insert(coordinates.pos, {
                        type: EXTENSION_NAME,
                        attrs: { src: url }
                      });
                      view.dispatch(transaction);
                    });
                  }
                );
              });

              return true;
            }
          }
        }
      }
    ];
  }
});
