<template>
  <div :class="`list-container ${loading && 'loading-list'}`">
    <div v-if="loading" class="loading-list-spinner">
      <div class="list-spinner"></div>
    </div>
    <div class="list-title">
      <span class="list-title-total">{{ selectedCount }}</span>
      <span class="list-title-type"> {{ type }} </span> <span>selected</span>
      <span class="list-title-reset" @click="reset">Reset</span>
    </div>
    <div class="list-body">
      <div class="list-header-container">
        <div class="list-filter">
          <div class="list-filter-wrapper search-box">
            <input type="text" :placeholder="'Search ' + this.type" v-model="search" />
            <img src="../../../../assets/icons/search.svg" alt="" />
          </div>
        </div>
        <div class="list-filter">
          <span class="list-filter-name">{{ type === 'Intents' ? 'Vault #' : 'Batch' }}</span>
          <div class="list-filter-wrapper">
            <VueMultiselect :close-on-select="false" :disabled="this.slots && this.slots.length === 0" open-direction="bottom" :show-labels="false" tag-position="bottom" :searchable="false" class="batch" v-model="batches" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersBatch" :multiple="true">
              <template #option="props">
                <div style="display: flex; justify-content: space-between">
                  <input type="checkbox" style="margin-right: 5px" class="selectCheckbox" :checked="isFilterChecked(batches, props.option.text)" />
                  <span>{{ props.option.text }}</span>
                </div>
              </template>
              <template #selection="{ values }"
                ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
              </template>
            </VueMultiselect>
          </div>
        </div>
        <div class="list-filter">
          <span class="list-filter-name">Status</span>
          <div class="list-filter-wrapper">
            <VueMultiselect :close-on-select="false" :show-labels="false" :disabled="this.slots && this.slots.length === 0" tag-position="bottom" :searchable="false" open-direction="bottom" class="status" v-model="statuses" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersStatus" :multiple="true">
              <template #option="props">
                <div style="display: flex; height: auto">
                  <input type="checkbox" style="margin-right: 5px" class="statusCheckbox" :checked="isFilterChecked(statuses, props.option.text)" />
                  <span style="margin-top: 2px">{{ props.option.text }}</span>
                </div>
              </template>
              <template #selection="{ values }"
                ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
              </template>
            </VueMultiselect>
          </div>
        </div>
      </div>
      <div class="list-body-container">
        <div class="list-body-column">
          <div class="list-body-column-checkbox" style="width: 3%">
            <label class="select-all-container">
              <div :class="`checkbox ${selectAllChecked || selectedPartially ? 'checkbox-filled' : ''}`" @click="selectAll">
                <div v-if="selectAllChecked" class="full-checkmark"></div>
                <div v-else-if="selectedPartially" class="partial-checkmark"></div>
              </div>
            </label>
          </div>
          <div class="list-body-column-name" style="width: 44%">
            <span>All {{ type }}</span>
          </div>
          <div class="list-body-column-name" style="width: 10%">
            <span>{{ type === 'Intents' ? 'Vault #' : 'Batch' }}</span>
          </div>
          <div class="list-body-column-name" style="width: 15%">
            <span>Status</span>
          </div>
          <div class="list-body-column-name" style="width: 27%">
            <span>Version</span>
          </div>
        </div>
        <div class="list-body-wrapper" v-if="slots && slots.length > 0">
          <div class="list-body-content" :key="index" v-for="(slot, index) in filteredSlots">
            <div class="list-body-content-wrapper">
              <div class="list-body-column-content" style="width: 3%">
                <label class="select-all-container">
                  <input type="checkbox" :checked="isSelected(slot)" @click="select(slot)" />
                  <span class="single-checkmark"></span>
                </label>
              </div>
              <div class="list-body-column-content" style="width: 44%">
                <div class="list-body-column-content-name-wrapper">{{ slot.name }}</div>
              </div>
              <div class="list-body-column-content" style="width: 10%">
                <span>{{ type === 'Intents' ? slot.vault : slot.batch }}</span>
              </div>
              <div class="list-body-column-content" style="width: 15%">
                <span><i :style="{ color: appStore.statusColor[slot.status] }" class="fas fa-circle"></i>{{ slot.status }}</span>
              </div>
              <div class="list-body-column-content" style="width: 27%">
                <div v-if="type === 'Intents'">Latest Version</div>
                <div v-else>
                  <VersionsDropdown :versions="slot.versions" :currentVersion="slot.currentVersion" @update:currentVersion="setCurrentVersion(slot, $event)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="empty-list-body-content">This agent has no {{ type }}. Please select a different agent.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import VueMultiselect from 'vue-multiselect';
import VersionsDropdown from './VersionsDropdown.vue';

export default {
  name: 'ReleaseListComponent',
  props: {
    type: String,
    collection: String
  },
  components: {
    VueMultiselect,
    VersionsDropdown
  },
  watch: {
    async collection(value) {
      if (!value) return;
      this.loading = true;
      this.reset();
      const slots =
        this.type === 'Intents'
          ? await this.appStore.getAllSlots({ collection: value })
          : await this.appStore.getAllFlows({
              collection: value,
              dropdown: false,
              store: true,
              versions: true
            });
      this.slots = sortBy(slots, 'name');
      if (this.type === 'Flows') {
        this.slots = this.slots.map((s) => {
          s.currentVersion = {
            versionName: s.published ? 'Latest published version' : 'Latest draft version',
            default: true,
            createdAt: new Date().toISOString()
          };
          return s;
        });
      }
      if (this.slots && this.slots.length > 0) {
        this.filtersBatch = uniq(this.slots.map((x) => (this.type === 'Intents' ? x.vault : x.batch)))
          .map((y) => {
            return { text: y, value: y, selected: false };
          })
          .sort(function (a, b) {
            return parseInt(a.value) - parseInt(b.value);
          });
        this.filtersStatus = uniq(this.slots.map((x) => x.status)).map((y) => ({ text: y, value: y, selected: false }));
      }

      this.loading = false;
    },
    dataToWatch() {
      if (this.batches.length > 0 || this.statuses.length > 0 || this.search !== '') {
        //in filtered view
        const diff = differenceWith(this.filteredSlots, this.selectedSlots, isEqual);
        if (diff.length === 0) {
          this.selectAllChecked = true;
          this.selectedPartially = false;
        } else if (this.filteredSlots.length === diff.length) {
          this.selectAllChecked = false;
          this.selectedPartially = false;
        } else {
          this.selectAllChecked = false;
          this.selectedPartially = true;
        }
      } else {
        // in non-filtered view
        if (this.selectedSlots.length === 0) {
          this.selectAllChecked = false;
          this.selectedPartially = false;
        } else if (this.selectedSlots.length === this.slots.length) {
          this.selectAllChecked = true;
          this.selectedPartially = false;
        } else {
          this.selectAllChecked = false;
          this.selectedPartially = true;
        }
      }
    },
    filteredSlots: {
      handler(slots) {
        this.slotVersionNames = slots.map((_, index) => this.currentVersionName(index));
      },
      immediate: true,
      deep: true
    }
  },

  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      selectAllChecked: false,
      selectedPartially: false,
      slots: [],
      flows: [],
      selectedSlots: [],
      batches: [],
      statuses: [],
      filtersBatch: [],
      filtersStatus: [],
      search: '',
      loading: false,
      slotVersionNames: [],
      selectedFlowVersion: null
    };
  },
  methods: {
    getVersionName(version) {
      if (typeof version === 'string') {
        return version;
      } else if (typeof version === 'object') {
        return version?.versionName || version?.name || '';
      } else {
        return '';
      }
    },
    setCurrentVersion(slot, selectedVersion) {
      const updatedVersions = slot.versions.filter((v) => v.id !== selectedVersion.id);
      updatedVersions.push(slot.currentVersion);
      updatedVersions.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const updatedSlot = cloneDeep(slot);
      updatedSlot.currentVersion = selectedVersion;
      updatedSlot.versions = updatedVersions;
      this.updateSlot(updatedSlot);
    },
    updateSlot(slot) {
      const clonedSlots = cloneDeep(this.slots);
      const updatedSlots = clonedSlots.map((s) => {
        if (s.id === slot.id) {
          return slot;
        } else {
          return s;
        }
      });
      const isSelected = this.isSelected(slot);
      if (isSelected === true) {
        this.selectedSlots = this.selectedSlots.map((s) => {
          if (s.id === slot.id) {
            return slot;
          } else {
            return s;
          }
        });
      }
      this.slots = updatedSlots;
    },
    searchFilter(item) {
      return item.name.toLowerCase().includes(this.search.toLowerCase());
    },
    statusFilter(item) {
      return this.statuses.find((x) => x.value === item.status);
    },
    batchFilter(item) {
      return this.batches.find((x) => x.value === (this.type === 'Intents' ? item.vault : item.batch));
    },
    reset() {
      this.batches = [];
      this.statuses = [];
      this.search = '';
      this.selectedSlots = [];
      this.selectAllChecked = false;
      this.selectedPartially = false;
    },
    isSelected(slot) {
      return this.selectedSlots.some((s) => s.name === slot.name);
    },
    isFilterChecked(array, filter) {
      return array.find((x) => x.value === filter) === undefined ? false : true;
    },
    select(slot) {
      const isSelected = this.isSelected(slot);
      if (isSelected === false) {
        this.selectedSlots = [...this.selectedSlots, slot];
      } else {
        this.selectedSlots = this.selectedSlots.filter((selectedSlot) => selectedSlot.name !== slot.name);
      }
      this.updateChecked();
      this.emitSelectionChange();
    },
    selectAll() {
      const filteredElements = cloneDeep(this.filteredSlots);
      if (this.selectAllChecked === false) {
        if (filteredElements.length === this.slots.length) {
          this.selectedSlots = cloneDeep(this.slots);
        } else {
          filteredElements.forEach((el) => {
            const isSelected = this.isSelected(el);
            if (isSelected === false) {
              this.selectedSlots.push(el);
            }
          });
        }
      } else {
        if (filteredElements.length > 0) {
          filteredElements.forEach((el) => {
            const isSelected = this.isSelected(el);
            if (isSelected === true) {
              this.selectedSlots = this.selectedSlots.filter((selectedSlot) => selectedSlot.name !== el.name);
            }
          });
        } else if (filteredElements.length === this.slots.length) {
          this.selectedSlots = [];
        } else if (this.selectedSlots.length === 0) {
          this.selectedSlots = [];
        }
      }
      if (filteredElements.length > 0) {
        this.updateFilteredChecked(filteredElements);
      } else {
        this.updateChecked();
      }
      if (this.selectedSlots.length === 0) {
        this.selectAllChecked = false;
        this.selectedPartially = false;
      }
      this.emitSelectionChange();
    },
    updateChecked() {
      this.selectAllChecked = this.selectedSlots.length === this.slots.length;
      this.selectedPartially = this.selectedSlots.length > 0 && !this.selectAllChecked;
    },
    updateFilteredChecked(filteredElements) {
      this.selectAllChecked = this.selectedSlots.length === filteredElements.length;
      this.selectedPartially = this.selectedSlots.length > 0 && !this.selectAllChecked;
    },
    emitSelectionChange() {
      const eventType = this.type === 'Intents' ? 'select-intents' : 'select-flows';
      this.$emit(eventType, this.selectedSlots);
    },
    getCurrentVersionName(slot) {
      return slot.currentVersion.versionName;
    },
    currentVersionName(index) {
      return {
        get: () => this.filteredSlots[index].currentVersion.versionName,
        set: (value) => {
          this.filteredSlots[index].currentVersion.versionName = this.getVersionName(value);
        }
      };
    }
  },
  computed: {
    dataToWatch() {
      // This is used for the watch function, more organized.
      const { statuses, search, batches, selectedSlots } = this;
      return {
        statuses,
        search,
        batches,
        selectedSlots
      };
    },
    filteredSlots() {
      return this.slots.filter((s) => (!this.batches.length || this.batchFilter(s)) && (!this.statuses.length || this.statusFilter(s)) && this.searchFilter(s));
    },
    selectedCount() {
      return Object.keys(this.selectedSlots).length;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;
  padding: 30px 40px 0 40px;
}
.list-title {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  span {
    font-size: 17px;
  }
}
.list-title-total {
  color: #1996f0;
  font-weight: bold;
}

.list-title-type {
  color: #383f45;
  font-weight: bold;
}

.list-title-reset {
  margin-left: auto;
  color: #383f45;
  text-decoration: underline;
  cursor: pointer;
}

.list-body {
  display: flex;
  flex-direction: column;
  max-height: 463px;
  border: solid 1px #8e9ba7;
  border-radius: 5px;
}

.list-header-container {
  display: flex;
  gap: 30px;
  padding: 0 30px 0 30px;
  min-height: 60px;
  background-color: #f4f6fa;
  align-items: center;
}

.list-filter {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    color: #383f45;
  }
}

.list-body-container {
  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.list-body-column {
  padding: 0 30px 10px 30px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.list-body-column-name {
  span {
    color: #383f45;
    font-weight: bold;
  }
}

.list-body-content {
  padding: 5px 30px 5px 30px;
  margin-top: 15px;
  min-height: 40px;
}

.list-body-content-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
.list-body-column-content-name-wrapper {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* The container */
.select-all-container {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 15px;
}

/* Hide the browser's default checkbox */
.select-all-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox {
  position: relative;
  display: inline-block;
  height: 20px;
  border-radius: 4px;
  width: 20px;
  border: solid 1px #8e9ba7;
  top: 9px;
}

.checkbox-filled {
  background-color: #1996f0;
}

.full-checkmark {
  position: absolute;
  transform: rotate(38deg);
  left: 29%;
  top: 7%;
  height: 13px;
  width: 7px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.partial-checkmark {
  position: absolute;
  transform: rotate(90deg);
  left: 38%;
  top: 20%;
  height: 10px;
  border-right: 3px solid #fff;
}

.checkmark {
  position: absolute;
  height: 20px;
  border-radius: 4px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #8e9ba7;
}

.single-checkmark {
  position: absolute;
  height: 20px;
  border-radius: 4px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #8e9ba7;
}

/* On mouse-over, add a grey background color */
.select-all-container:hover input ~ .checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.checkmark-checked {
  background-color: #1996f0;
}

.checkmark-checked:after {
  display: block;
  left: 6px;
  top: 7px;
  width: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
}

/* On mouse-over, add a grey background color */
.select-all-container:hover input ~ .single-checkmark {
  background-color: #fff;
}

/* When the checkbox is checked, add a blue background */
.select-all-container input:checked ~ .single-checkmark {
  background-color: #1996f0;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.single-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.select-all-container input:checked ~ .checkmark:after {
  display: block;
}

.select-all-container input:checked ~ .single-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.select-all-container .checkmark:after {
  left: 6px;
  top: 7px;
  width: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
}

.select-all-container .single-checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.list-body-content:hover,
.list-body-content:active {
  background-color: #e8f5fe;
}

.empty-list-body-content {
  display: flex;
  justify-content: center;
  padding: 5%;
}

.search-box {
  width: 320px;
  margin: auto;
  height: 40px;
  position: relative;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background-color: #fff;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    font-size: 14px;
    line-height: 3.07;
    text-align: left;
    color: #383f45;
    background-color: #fff;
  }

  input::placeholder {
    font-size: 14px;
    font-style: italic;
    line-height: 3.07;
    text-align: left;
    color: #8e9ba8;
  }

  img {
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.versioning-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.versioning-option-title {
  width: 180px;
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.versioning-option-name {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.loading-list {
  pointer-events: none;
  opacity: 0.1;
  position: relative;
}

.loading-list-spinner {
  position: absolute;
  left: 50%;
  top: 60%;
}

.list-spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.list-spinner:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #000000 transparent #000000 transparent;
  animation: list-spinner 1.2s linear infinite;
}

.versioning-title {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect.batch {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.language {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.status {
  width: 300px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}

.multiselect.notes {
  width: 190px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  /* height: 40px; */
  height: max-content;
}

@keyframes list-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1280px) {
  .search-box {
    min-width: 100px;
    width: unset;
  }
}
</style>
