<template>
  <div style="height: 70%">
    <div>
      <br />
      <!-- <span style='float:right; font-size:7px'>{{id}}</span> -->
      <Editor :readOnly="true" read-only :id="id" :collection="collection" :nodes="nodes" :type="`note`" :value="notes" />
      <br />
      <div class="grid justify-items-center">
        <el-button style="padding: 5px 10px" size="small" round @click="goToRoot(id)" type="info">{{ buttonText }}</el-button>
      </div>
      <vs-divider />
      <br />
    </div>
    <a id="bottom"></a>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
// import { app } from 'firebase';
// import firebase from 'firebase/compat/app';

// import { db, store } from '@/main';

export default {
  name: 'DrawerList',
  inject: {},
  props: {
    customHeight: {},
    buttonText: {
      type: String,
      default: 'Go To Note'
    },
    id: {
      type: String
    },
    notes: {
      type: String
    },
    replies: {
      type: String
    },
    type: {
      type: String,
      required: false
    },
    data: {
      type: Object,
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    actions: {
      required: false,
      type: Object
    },
    slots: {
      required: true,
      type: Array
    },
    collection: {
      required: true,
      type: String
    }
  },
  directives: {},
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      loadScrollTimeout: null
    };
  },
  computed: {
    language() {
      return this.appStore.language != 'fr' ? true : false;
    }
  },
  methods: {
    goToRoot(id) {
      this.loadScrollTimeout = setTimeout(() => {
        const element = document.getElementById(id ? id.toString() : '2');
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
        element.getElementsByClassName('noteNumber')[0].click();
        // this.appStore.repositionLines();
      }, 500);
    }
  },
  watch: {},
  beforeUnmount() {
    clearTimeout(this.loadScrollTimeout);
  }
};
</script>
