<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-if="!isHome" class="breadcrumb-item">
        <router-link to="/taxonomy">Taxonomy </router-link>
      </li>
      <li v-if="appStore.taxonomies.find((i) => i.id === this.$route.params.id)" class="breadcrumb-item active" aria-current="page">
        {{ appStore.taxonomies.find((i) => i.id === this.$route.params.id).name }}
      </li>
    </ol>
  </nav>
</template>

<script>
import { mapState } from 'pinia';
import { useAppStore } from '@/store/index.js';
export default {
  name: 'BreadCrumb',
  props: ['breadCrumbs'],
  data() {
    return {
      appStore: useAppStore()
    };
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies']),
    isHome() {
      return this.$route.name === 'Home';
    }
  }
};
</script>

<style scoped>
.router-link-active {
  color: #6c757d !important;
  text-decoration: none;
  background-color: transparent;
}
.router-link-active:hover {
  color: #007bff !important;
  text-decoration: none;
  background-color: transparent;
}
.container {
  margin: 0px !important;
  padding: 0px !important;
  background-color: transparent;
  /* width: 50%; */
}

.breadcrumb {
  padding: 0px !important;
  margin: 0px;
  background-color: transparent;
}
</style>
