<template>
  <div class="intentList">
    <div class="filtersWrapper">
      <div class="taxonomyFilters">
        <div class="filterEach" v-if="this.appStore.drawer !== 'chNluEngine'">
          <span class="filterName">Batch</span>
          <VueMultiselect :close-on-select="false" :disabled="readOnly" @input="selectionChanged" @select="selectionChanged" @remove="selectionChanged" :show-labels="false" tag-position="bottom" :searchable="false" class="batch" v-model="batches" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersBatch" :multiple="true">
            <template #option="props">
              <div style="display: flex; justify-content: space-between">
                <input type="checkbox" style="margin-right: 5px" class="selectCheckbox" :checked="isChecked(batches, props.option.text)" />
                <span>{{ props.option.text }}</span>
              </div>
            </template>
            <template #selection="{ values }"
              ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
            </template>
          </VueMultiselect>
        </div>
        <div class="filterEach">
          <span class="filterName">Status</span>
          <VueMultiselect :close-on-select="false" :disabled="readOnly" @input="selectionChanged" @select="selectionChanged" @remove="selectionChanged" :show-labels="false" tag-position="bottom" :searchable="false" class="status" v-model="statuses" :option-height="300" placeholder="" label="value" track-by="text" :options="filtersStatus" :multiple="true">
            <template #option="props">
              <div class="flex" v-if="appStore.drawer !== 'chNluEngine'">
                <input type="checkbox" style="margin-right: 5px" class="statusCheckbox" :checked="isChecked(statuses, props.option.text)" />

                <span style="margin-top: 2px"> {{ props.option.text }}</span>
              </div>
              <div class="flex" v-else>
                <input type="checkbox" style="margin-right: 5px" class="statusCheckbox" :checked="isChecked(statuses, props.option.text)" :disabled="readOnly" />
                <span style="margin-top: 2px"> {{ props.option.text }}</span>
              </div>
            </template>
            <template #selection="{ values }"
              ><span class="multiselect__single" v-if="values.length">{{ values.length }} selected</span>
            </template>
          </VueMultiselect>
        </div>
        <div class="filterEach">
          <IconUtility class="filterName" name="globe" :width="17" />
          <VueMultiselect v-model="selectedLanguage" :show-labels="false" @input="selectionChanged" @select="selectionChanged" @remove="selectionChanged" class="language" :option-height="300" track-by="language" label="language" placeholder="" :options="filterLanguage" :searchable="false" :allow-empty="false">
            <template #singleLabel="{ option }">
              <span>{{ option.language }}</span>
            </template>
          </VueMultiselect>
        </div>
        <slot name="extraFilters"></slot>
      </div>
    </div>

    <div class="tableWrapper">
      <div class="container-fluid tableContainer">
        <table class="slotsTable">
          <thead>
            <tr>
              <th width="20">
                <input :disabled="disabledDrawer() === false ? readOnly : true" type="checkbox" v-model="allslots" @click="allSlotsClick(allslots)" />
              </th>
              <th width="50%">All flows</th>
              <th>Batch</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="slot in ((!readOnly && slots) || metadata.job['job_meta_data'].allIntents).filter((s) => (!this.batches.length || this.batches.find((x) => x.value === s.batch)) && (!this.statuses.length || this.statuses.find((x) => x.value === s.status)))" :key="slot.id">
              <td>
                <input v-if="!readOnly" :disabled="disabledDrawer()" @change="selectionChanged" type="checkbox" v-model="slot.checked" :id="slot.id" value="1" />
                <input v-else @change="selectionChanged" :disabled="disabledDrawer()" type="checkbox" :checked="shouldBeChecked(slot.name)" :id="slot.id" value="1" />
              </td>
              <td>
                <label :for="slot.id">{{ slot.name }}</label>
              </td>
              <td>{{ slot.batch }}</td>
              <td><i :style="{ color: appStore.statusColor[slot.status] }" class="fas fa-circle"></i>{{ slot.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import VueMultiselect from 'vue-multiselect';
import _ from 'lodash';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import IconUtility from './../../Utilities/IconUtility.vue';
export default {
  name: 'FlowList',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      allslots: false,
      slots: [],
      batches: [],
      statuses: [],
      languages: useAppStore().alternativeLanguage,
      selectedLanguage: null,
      filterLanguage: [],
      filtersBatch: [],
      filtersStatus: []
    };
  },
  props: {
    collection: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    metadata: {
      type: Object
    }
  },
  async mounted() {
    this.$vs.loading();
    if (this.readOnly) {
      this.batches = this.metadata.job['job_meta_data'].batches.map((b) => {
        return { text: b, value: b, selected: false };
      });
      this.statuses = this.metadata.job['job_meta_data'].statuses.map((b) => {
        return { text: b, value: b, selected: false };
      });
    }
    const slots = await this.appStore.getAllFlows({
      collection: this.collection,
      dropdown: false,
      store: true,
      reload: true
    });
    this.slots = _.sortBy(slots, 'name');
    this.$vs.loading.close();

    if (this.slots)
      this.filtersBatch = _.uniq(this.slots.map((x) => x.batch))
        .map((y) => {
          return { text: y, value: y, selected: false };
        })
        .sort(function (a, b) {
          return parseInt(a.value) - parseInt(b.value);
        });
    this.filtersStatus = _.uniq(this.slots.map((x) => x.status)).map((y) => {
      return { text: y, value: y, selected: false };
    });
    this.filterLanguage = this.languages.map((x) => {
      const language = Object.keys(x)[0];
      const code = x[language];
      return { language, code };
    });

    this.selectionChanged();
  },
  methods: {
    selectionChanged() {
      // Check the number of selected intents
      const selectedIntentsCount = this.slots.filter((s) => s.checked).length;

      if (selectedIntentsCount > 50) {
        // Show warning dialog if more than 50 intents are selected
        this.showWarningDialog();
        return; // Stop further processing if the limit is exceeded
      }
      let intents = [];
      if (this.appStore.drawer === 'chNluEngine') {
        intents = this.slots.filter((s) => {
          if (!this.statuses.length || this.statuses.find((x) => x.value === s.status)) {
            if (this.statuses.length > 0) {
              s.checked = true;
            } else {
              s.checked = false;
            }
            return s;
          }
          s.checked && (!this.statuses.length || this.statuses.find((x) => x.value === s.status));
        });
      } else {
        intents = this.slots.filter((s) => s.checked && (!this.batches.length || this.batches.find((x) => x.value === s.batch)) && (!this.statuses.length || this.statuses.find((x) => x.value === s.status)));
      }

      this.$emit('selectionChanged', {
        intents: intents,
        language: this.selectedLanguage,
        batches: this.batches,
        statuses: this.statuses,
        allIntents: this.slots.map((s) => {
          return { status: s.status, name: s.name, batch: s.batch };
        })
      });
    },
    showWarningDialog() {
      this.$vs.dialog({
        color: 'danger',
        title: 'PDF Export Limit Error',
        text: 'Please select less than the maximum supported 50 PDF limit.',
        accept: () => {
          this.clearCheckedValues();
        }
      });
    },
    clearCheckedValues() {
      this.slots = this.slots.map((s) => ({ ...s, checked: false }));
      this.allslots = false;
      this.selectionChanged();
    },
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    allSlotsClick(val) {
      const filteredSlots = ((!this.readOnly && this.slots) || this.metadata.job['job_meta_data'].allIntents).filter((s) => (!this.batches.length || this.batches.find((x) => x.value === s.batch)) && (!this.statuses.length || this.statuses.find((x) => x.value === s.status)));

      if (val) {
        filteredSlots.forEach((slot) => {
          slot.checked = false;
        });
      } else {
        filteredSlots.forEach((slot) => {
          slot.checked = true;
        });
      }

      this.allslots = !val;
      this.selectionChanged();
    },
    disabledDrawer() {
      if (this.appStore.drawer !== 'chNluEngine') {
        return false;
      } else return true;
    },
    shouldBeChecked(slotName) {
      if (this.metadata.job['job_meta_data'].allIntents?.map((x) => x.name) || this.metadata.job['payload'].config?.whitelist?.includes(slotName)) return true;
      return false;
    }
  },

  computed: {},
  components: {
    VueMultiselect,
    IconUtility
  }
};
</script>

<!-- Cant be scoped styling for dialog -->
<style>
.vs-dialog {
  color: #721c24;
  border-color: #f5c6cb;
  color: #000000;
  font-size: 13px;
}

span.vs-button-text.vs-button--text {
  color: #fff;
}

.con-vs-dialog .vs-dialog header .vs-dialog-cancel {
  display: none;
}

.con-vs-dialog .vs-dialog footer {
  display: flex;
  align-items: center;
  padding: 0 16px 10px;
  justify-content: flex-end;
  position: unset;
}
</style>
<style scoped>
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect.batch {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.language {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.status {
  width: 300px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}

.multiselect.notes {
  width: 190px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  /* height: 40px; */
  height: max-content;
}

#close-button {
  height: 27px;
  width: 27px;
  position: absolute;
  right: 43px;
  top: 25px;
  cursor: pointer;
  background-color: #f6f8fa;
  padding: 5px;
  border-radius: 50%;
  z-index: 1000;
}
.close-color {
  stroke: #383f45;
  stroke-width: 5px;
}

.drwr-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.drwr {
  background: #fff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 945px;
  height: calc(100% - 60px);
  top: 60px;
  right: 0;
  position: absolute;
}

.drawer-body {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.drwr-fade-enter-active,
.drwr-fade-leave-active {
  transition: transform 0.5s;
}

.drwr-fade-enter,
.drwr-fade-leave-to {
  transform: translateX(100%);
}

.drwr-fade-enter-to,
.drwr-fade-leave {
  transform: translateX(0);
}

.batch * .multiselect__tag,
.language * .multiselect__tag,
.status * .multiselect__tag,
.notes * .multiselect__tag {
  padding: 10px 30px 10px 11px !important;
}

.batch * .multiselect__tag-icon,
.language * .multiselect__tag-icon,
.status * .multiselect__tag-icon,
.notes * .multiselect__tag-icon {
  padding-top: 15px !important;
  padding-right: 5px !important;
  line-height: 1px !important;
}

.multiselect__tag-icon:after {
  color: #383f45 !important;
}
.multiselect__tag-icon:after:hover {
  background-color: #8e9ba8 !important;
  color: white !important;
}

.batchCheckbox,
.selectCheckbox {
  padding-right: 10px !important;
  margin-top: 1px;
}
.statusCheckbox {
  margin-right: 12px !important;
  margin-top: 3px !important;
}

.batch * .multiselect__tag,
.language * .multiselect__tag,
.notes .multiselect__tag {
  border-radius: 2px !important;
  background-color: #edf0f4 !important;
  font-size: 14px !important;
  color: #383f45 !important;
  height: 30px !important;
  padding: 8px 30px 8px 8px !important;
}

.status * .multiselect__tag {
  border-radius: 2px !important;
  background-color: #edf0f4 !important;
  font-size: 14px !important;
  color: #383f45 !important;
  height: 30px !important;
  padding: 8px 30px 8px 8px !important;
}
.multiselect__tags {
  display: flex;
  caret-color: transparent;
}
.notes * .multiselect__tags {
  border: 1px solid #8e9ba7;
  caret-color: transparent;
}

.multiselect__tag-icon:hover {
  background: none !important;
}

.multiselect.batch,
.multiselect.language,
.multiselect.status,
.multiselect.notes {
  line-height: 5% !important;
}

.filterName {
  padding-right: 10px;
  margin-top: 9px;
  white-space: nowrap;
  width: unset;
  top: 0.4rem;
}
.filterEach input[type='number'] {
  background-color: #fff;
  border: 1px solid #8e9ba7;
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  width: 40px;
}

.taxonomyFilters {
  height: 100%;
  width: 100%;
  padding: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 0px;
}

.filterEach {
  display: flex;
  padding-right: 30px;
  min-height: 47px;
}

.multiselect__option--selected {
  background: none;
  font-weight: normal !important;
}

.multiselect__option--highlight {
  background: none !important;
  color: inherit !important;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: none;
  color: inherit !important;
}

.filterSelection.batch > .vs__dropdown-toggle {
  padding: 5px 5px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.filterSelection.language > .vs__dropdown-toggle {
  padding: 5px 5px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.filterSelection.status > .vs__dropdown-toggle {
  padding: 5px 7px 5px !important;
  background: white !important;
  margin-bottom: 4px !important;
  border: solid 1px #707070 !important;
}

.filterSelection.batch .vs__selected {
  width: 58px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
}
.filterSelection.language .vs__selected {
  width: 58px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
}
.filterSelection.status .vs__selected {
  width: 150px !important;
  height: 30px !important;
  padding: 5px 10px 6px 10px !important;
  margin: 0 5px 0 0;
  border: none !important;
  border-radius: 2px !important;
  background: #edf0f4 !important;
  display: flex;
  justify-content: space-between;
}

.filterSelection.batch .vs__selected .vs__deselect {
  margin-left: 50% !important;
}

.filterSelection.language .vs__selected .vs__deselect {
  margin-left: 50% !important;
}

.intentList {
  max-height: 100%;
}

.filtersWrapper {
  height: auto;
  padding: 5px 20px 5px 20px;
  background-color: #edf0f4;
  /* min-height: 60px; */
}

.tableWrapper {
  height: calc(100% - 125px);
  position: relative;
}

.tableContainer {
  padding: 0 10px !important;
  height: 61vh !important;
}
</style>
