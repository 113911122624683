<template>
  <nav id="sidebar" :class="{ minimized: isMinimized }" @mouseenter="showChevron = true" @mouseleave="showChevron = false">
    <button v-show="showChevron" id="toggleMenu" class="menu-toggle" @click="toggleMenu">
      <div class="chevron-wrapper">
        <span :title="this.expandedMenu ? 'Collapse' : 'Expand'"><i :class="this.expandedMenu ? 'fa fa-chevron-left chevron-left' : 'fa fa-chevron-right chevron-right'" :aria-hidden="true"></i></span>
      </div>
    </button>
    <div v-if="isMinimized && isContentEditor()" class="scroll">
      <ul class="components">
        <li v-if="isContentEditor()" data-test="agents-minimized">
          <vs-tooltip position="right" text="Agents">
            <router-link to="/agents">
              <img class="menuIcon" width="19" src="../../../assets/icons/agents.svg" />
              <img class="menuIconHover" width="19" src="../../../assets/icons/agents_w.svg" />
              <span class="rootMenuLabel">Agents</span>
            </router-link>
          </vs-tooltip>
        </li>
      </ul>
      <ul class="components" v-if="isEditor">
        <li data-test="users-minimized">
          <vs-tooltip position="right" text="Users">
            <router-link to="/admin">
              <img class="menuIcon" width="19" src="../../../assets/icons/people.svg" />
              <img class="menuIconHover" width="19" src="../../../assets/icons/people_w.svg" />
              <span class="rootMenuLabel">Users</span>
            </router-link>
          </vs-tooltip>
        </li>
      </ul>

      <ul class="components">
        <li data-test="transfer-minimized" v-if="isEditor">
          <vs-tooltip position="right" text="Transfer">
            <router-link to="/transfer">
              <img class="menuIcon" width="19" src="../../../assets/icons/arrows.svg" />
              <img class="menuIconHover" width="19" src="../../../assets/icons/arrows_w.svg" />
              <span class="rootMenuLabel">Transfer</span>
            </router-link>
          </vs-tooltip>
        </li>
        <li data-test="release-minimized" v-if="isAdmin">
          <vs-tooltip position="right" text="Release">
            <router-link to="/release">
              <img class="menuIcon" width="19" src="../../../assets/icons/release.svg" />
              <img class="menuIconHover" width="19" src="../../../assets/icons/release_w.svg" />
              <span class="rootMenuLabel">Release</span>
            </router-link>
          </vs-tooltip>
        </li>
        <li data-test="notifications-minimized">
          <vs-tooltip position="right" text="Notifications" v-if="isContentEditor()">
            <router-link to="/notifications">
              <img class="menuIcon" width="19" src="../../../assets/icons/notification_sidebar.svg" />
              <img class="menuIconHover" width="19" src="../../../assets/icons/notification_white.svg" />
              <span class="rootMenuLabel">Notifications</span>
            </router-link>
          </vs-tooltip>
        </li>
      </ul>
    </div>

    <div v-else class="scroll">
      <ul class="components" v-if="isContentEditor()">
        <li v-if="isContentEditor()" data-test="agents">
          <router-link to="/agents">
            <img class="menuIcon" width="19" src="../../../assets/icons/agents.svg" />
            <img class="menuIconHover" width="19" src="../../../assets/icons/agents_w.svg" />
            <span class="rootMenuLabel">Agents</span>
          </router-link>
        </li>
      </ul>
      <ul class="components" v-if="isAdmin">
        <li data-test="users">
          <router-link to="/admin">
            <img class="menuIcon" width="19" src="../../../assets/icons/people.svg" />
            <img class="menuIconHover" width="19" src="../../../assets/icons/people_w.svg" />
            <span class="rootMenuLabel">Users</span>
          </router-link>
        </li>
      </ul>

      <ul class="components" v-if="!isGuest">
        <li v-if="isEditor" data-test="transfer">
          <router-link to="/transfer">
            <img class="menuIcon" width="19" src="../../../assets/icons/arrows.svg" />
            <img class="menuIconHover" width="19" src="../../../assets/icons/arrows_w.svg" />
            <span class="rootMenuLabel">Transfer</span>
          </router-link>
        </li>
        <li v-if="isAdmin" data-test="release">
          <router-link to="/release">
            <img class="menuIcon" width="19" src="../../../assets/icons/release.svg" />
            <img class="menuIconHover" width="19" src="../../../assets/icons/release_w.svg" />
            <span class="rootMenuLabel">Release</span>
          </router-link>
        </li>
        <li data-test="notifications" v-if="isContentEditor()">
          <router-link to="/notifications">
            <img class="menuIcon" width="19" src="../../../assets/icons/notification_sidebar.svg" />
            <img class="menuIconHover" width="19" src="../../../assets/icons/notification_white.svg" />
            <span class="rootMenuLabel">Notifications</span>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="grid justify-items-center sidebar-footer" data-test="footer">
      <div class="footer-platform-version" data-test="footer-platform-version">
        <span class="platformVersion" data-test="platform-version">
          {{ this.platformVersion }}
        </span>
      </div>
      <div class="footer-logo" data-test="footer-logo">
        <img class="footerImgFull" height="27" src="../../../assets/chlogoFull.svg" data-test="footer-logo-full" />
        <img class="footerImgSmall" height="27" src="../../../assets/ch.svg" data-test="footer-logo-small" />
      </div>
    </div>
  </nav>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';

export default {
  name: 'AppSidebar',
  props: {
    minimized: {
      type: Boolean
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      expandedMenu: !this.minimized,
      showChevron: false,
      isMinimized: this.minimized
    };
  },
  computed: {
    ...mapState(useAppStore, ['isGuest', 'isAdmin', 'isEditor']),
    platformVersion() {
      return process.env.VUE_APP_PLATFORM_VERSION || '';
    }
  },
  methods: {
    isContentEditor() {
      const contentEditor = this.appStore.isContentEditor;
      // console.log({ contentEditor }, this.appStore.claims);
      return contentEditor;
    },
    toggleMenu() {
      this.expandedMenu = !this.expandedMenu;
      this.isMinimized = !this.isMinimized;
    }
  }
};
</script>

<style lang="scss" scoped>
.menu-toggle {
  background-color: #56616a !important;
  position: absolute;
  top: 50%;
  right: 0;
}

.rootMenuLabel {
  text-decoration: none;
}

.chevron-wrapper {
  position: absolute;
  z-index: 99;
  left: -10px;

  span {
    background-color: #56616a;
    border: 1px solid #56616a;
    padding: 2px;
    border-radius: 50%;
    i {
      font-size: 12px;
    }
  }

  span:hover {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.11);
  }

  @media screen and (max-width: 1001px) {
    display: none;
  }
}

.chevron-right {
  margin: 2px;
  padding-left: 2px;
}
.chevron-left {
  margin: 2px;
  padding-right: 2px;
}

.toggle-line:hover {
  background-color: #1996f0;
}
</style>
