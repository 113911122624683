<template>
  <div>
    <div v-if="appStore.drawer === 'bulkPdfOptions'" class="overlay"></div>
    <DrawerMain id="bulkPdfOptions" v-if="appStore.drawer === 'bulkPdfOptions'">
      <template v-slot:header>
        <PageHeader title="PDF Export" subtitle="Utilities / Configuration" />
      </template>
      <template v-slot:filters></template>
      <template v-slot:body>
        <PDFPreviewSidebar />
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import PDFPreviewSidebar from '@/components/ConversationBuilder/Sidebar/3.0/PDFPreview/PDFPreviewSidebar.vue';

export default {
  name: 'PdfOptionsDrawer',
  data() {
    return {
      appStore: useAppStore()
    };
  },
  components: {
    PDFPreviewSidebar,
    DrawerMain
  }
};
</script>
