import { useAppStore } from '@/store/index.js';

export const StarterConfig = () => {
  const appStore = useAppStore();
  appStore.SET_SCENE({
    scene: {
      centerX: 1024,
      centerY: 140,
      scale: 1,
      nodes: [],
      links: [],
    },
  });

  appStore.addNodeSync({
    scene: appStore.scene,
    type: 'start',
    title: 'Start Here',
    position: {
      x: 10,
      y: 0,
    },
    start: true,
  });
  appStore.addNodeSync({
    scene: appStore.scene,
    type: 'Container',
    title: 'Container 1',
    position: {
      x: -60,
      y: 100,
    },
    start: true,
  });
};

export const generateCanvasObject = () => {
  const appStore = useAppStore();
  return {
    componentList: [...appStore.componentList],
    components: { ...appStore.getComponentListFromComponents },
    containerList: [...appStore.containerList],
    containers: { ...appStore.getContainerListFromContainers },
    scene: { ...appStore.scene },
  };
};

export const modifyCanvas = (localState, canvasObject) => {
  const appStore = useAppStore();
  localState = { ...localState, ...canvasObject };

  appStore['componentList'] = localState.componentList;
  appStore['components'] = localState.components;
  appStore['containerList'] = localState.containerList;
  appStore['containers'] = localState.containers;
  appStore['scene'] = localState.scene;
};
