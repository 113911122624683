<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <v-select
        append-to-body
        class="intent-dropdown"
        :clearable="false"
        v-model="itemSelected"
        placeholder="Search Intent"
        :items="slots"
        label="name"
        :options="slots"
        filterable
        autocomplete
      />
    </div>
  </div>
</template>

<script>
import dropdowns from '../Mixins/dropdowns';
export default {
  name: 'IntentDropdown',
  mixins: [dropdowns],
  props: {
    data: {},
    taxonomy: {},
  },
  data() {
    return {};
  },
};
</script>
