<template>
  <div class="intent-training-data-full-wrapper">
    <div v-if="view_entities" class="intent-entities">
      <div v-for="item in entities_chosen" :key="item.id" class="intent-entities-item">
        <EntityPhrases @copyref="updatePhrase($event)" :collection="collection" :entityInfo="item" />
      </div>
    </div>

    <div class="intent-training-phrases-wrapper">
      <div class="wrapper ai-window intent-training-phrases-container">
        <div slot="top-right" class="intent-training-phrases-header-close">
          <button @click="$emit('close')">
            <i class="fas fa-times-circle"></i>
          </button>
        </div>

        <span class="slotClickedTraining" v-if="this.nodes.clicked">{{ this.nodes.clicked.data.data.name.toUpperCase() }}</span>
        <div class="intent-training-phrases-info">
          <el-button @click="SendtoDB()">SAVE</el-button>
          <span
            >TRAINING PHRASES<sup v-if="trainingPhrases.length > 0" class="el-badge__content">{{ trainingPhrases.length }}</sup></span
          >
        </div>

        <div class="intent-training-phrases-select-entities">
          <el-select v-if="!entities_have_been_chosen" v-model="entities_chosen" value-key="name" filterable multiple placeholder="Select Entities">
            <el-option v-for="item in entities" :key="item.name" :label="item.name" :value="item"> </el-option>
          </el-select>

          <el-select v-if="entities_have_been_chosen" v-model="entities_chosen" value-key="name" filterable multiple placeholder="Select Entities">
            <el-option v-for="item in entities" :key="item.name" :label="item.name.toUpperCase()" :value="item"> </el-option>
          </el-select>
          <i @click="SaveEntities(entities_chosen)" class="fas fa-check-double"></i>
        </div>

        <div class="intent-training-phrases">
          <div class="list-item intent-training-phrases-item" v-for="(item, index) in trainingPhrases" :key="item.uuid">
            <div class="draggable-item intent-training-phrases-item-container">
              <div v-if="item.type === 'text'" class="intent-training-phrases-item-content">
                <EditorTraining :tabindex="index" :readOnly="false" :id="`10000`" :collection="collection" :nodes="nodes" v-model.trim="item.phrase" :role="item.role" :timestamp="item.timestamp ? item.timestamp : ''" :type="`message`" />
              </div>

              <div v-if="item.type === 'batch_text'" class="intent-training-phrases-item-content">
                <EditorTraining :entitiesChosen="entities_chosen" :tabindex="index" @remove="RemoveUtterance(index)" @add="AddUtterance(index)" :entities="entities" :readOnly="false" :id="`10000`" :collection="collection" :nodes="nodes" v-model="item.phrase" :type="`trainingphrase`" :lastSavedBy="item.lastSavedBy ? item.lastSavedBy : ''" :uuid="item.uuid ? item.uuid : ''" :timestamp="item.timestamp" :photoURL="item.photoURL" />
              </div>
            </div>
          </div>

          <a id="bottom"></a>
          <div class="trainingOptions">
            <div class="grid">
              <button v-if="trainingPhrases.length > 0" @click="copyTextToClipboard" class="icon-button" aria-label="Copy to Clipboard"><i class="fas fa-file-excel"></i></button>
              <i v-if="currentIndex != 1 && trainingOlder.length > 1" @click="goBack(currentIndex - 1)" class="fas fa-backward"></i>
              <i v-if="currentIndex < trainingOlder.length" @click="goForward(currentIndex + 1)" class="fas fa-forward"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { applyDrag } from '@/components/Utilities/utils';
import { db } from '@/main';
import firebase from 'firebase/compat/app';
import EntityPhrases from '@/components/Entities/EntityPhrases';
import _ from 'lodash';
import axios from 'axios';

export default {
  name: 'TrainingPhrases',
  components: {
    EntityPhrases
  },
  inject: {},

  props: {
    data: {
      type: Object,
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    actions: {
      required: false,
      type: Object
    },

    collection: {
      required: true,
      type: String
    },
    slots: {
      required: true,
      type: Array
    }
  },

  directives: {},

  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      graphNodes: {
        clicked: null,
        mouseOver: null,
        zoomed: null,
        root: null,
        highlighted: null
      },
      slotNameSaved: {},
      allUtterances: [],
      width: null,
      phrasesReplace: [],
      viewPasteInput: false,
      view_entities: true,
      entities_chosen: [],
      entities_have_been_chosen: '',
      entities: [],
      search: '',
      height: null,
      yFilter: [],
      entity: '',
      response: '',
      phrase: '',
      batch_text: '',
      showModal: false,
      child_chosen: '',
      parent_chosen: '',
      showBot: '',
      trainingphrasesTemp: [],
      trainingPhrases: [
        {
          type: 'batch_text',
          phrase: '',
          role: useAppStore().getClaims?.role,
          uuid: useAppStore().getUser.uid,
          lastSavedBy: useAppStore().getUser.displayName,
          photoURL: useAppStore().getUser.photoURL
        }
      ],
      trainingphrases_id: '',
      last_addition_timestamp: '',
      email: '',
      highlight: [],
      highlightEnabled: true,
      value3: [1],
      value4: [1],
      trainingOlder: [],
      currentIndex: 0
    };
  },
  mounted() {
    this.currentUser();
    this.getTrainingPhrases();
    this.getIntentData();
    this.getEntities();
  },

  methods: {
    async copyTextToClipboard() {
      // Ensure there's text to copy
      const text = this.getClipboardText();
      if (!text) {
        this.$message.error('No text available to copy.');
        return;
      }

      // Attempt to copy the text to the clipboard
      try {
        await navigator.clipboard.writeText(text);
        // Provide feedback on success
        this.$message.success('Text successfully copied to clipboard.');
      } catch (err) {
        // Log and provide feedback on failure
        console.error('Failed to copy text to clipboard:', err);
        this.$message.error('Failed to copy text to clipboard.');
      }
    },

    // Combines retrieving and formatting the text
    getClipboardText() {
      return this.trainingPhrases.map((r) => this.strip(r.phrase)).join('\n');
    },
    async getTrainingPhrases() {
      try {
        if (!this.nodes?.clicked?.data?.data?.id) {
          throw new Error('getTrainingPhrases: No intent id found');
        }

        const ref = db.collection(this.collection).doc(this.nodes.clicked.data.data.id).collection('Utterances').orderBy('timestamp', 'desc').limit(10);

        const querySnapshot = await ref.get();

        if (querySnapshot.empty) {
          const docRef = await db.collection(this.collection).doc(this.nodes.clicked.data.data.id).collection('Utterances').add({
            utterances: [],
            user: this.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            highlight: this.highlight
          });

          this.trainingphrases_id = docRef.id;
          this.AddUtterance(0);
        } else {
          const docData = querySnapshot.docs[0].data();
          this.trainingPhrases = docData.utterances;
          this.trainingphrases_id = querySnapshot.docs[0].id;
          this.last_addition_timestamp = docData?.timestamp?.toDate()?.toLocaleString();

          if (docData.entitiesChosen) {
            this.entities_chosen = docData.entitiesChosen;
            this.entities_have_been_chosen = true;
          }

          if (docData.highlight) {
            this.highlight = docData.highlight;
            this.highlight.push({
              text: '?',
              style: 'background-color: red'
            });
          }

          if (querySnapshot.size > 1) {
            this.trainingOlder = [];
            querySnapshot.docs.slice(1).forEach((doc) => {
              this.trainingOlder.push(doc.data().utterances);
            });
            this.trainingOlder = _.orderBy(this.trainingOlder, ['timestamp']['desc']);
            this.currentIndex = querySnapshot.size - 1;
          }
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    async getIntentData() {
      try {
        if (!this.nodes?.clicked?.data?.data?.id) {
          throw new Error('getIntentData: No intent id found');
        }
        const docRef = db.collection(this.collection).doc(this.nodes.clicked.data.data.id);
        const doc = await docRef.get();

        if (doc.exists && doc.data().entitiesChosen) {
          this.entities_chosen = doc.data().entitiesChosen;
          this.entities_have_been_chosen = true;
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    async getEntities() {
      try {
        this.$vs.loading();
        const entities = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/training/getAllEntitiesAndValues`, {
          idToken: this.appStore.getClaims.token,
          collection: this.collection
        });
        this.entities = entities.data.data;
        this.$vs.loading.close();
      } catch (err) {
        this.$vs.loading.close();
        this.$message({
          message: `An error occurred while loading training phrases and entities. `
        });
      }
    },
    convertUtterances() {
      return new Promise((resolve) => {
        this.trainingPhrases.forEach((item) => {
          item['converted'] = this.strip(item.phrase);
          item['entities'] = this.extractEntities(item.phrase) ? this.extractEntities(item.phrase) : [];
        });
        resolve(true);
      });
    },
    goBack(index) {
      if (index + 1 === this.trainingOlder.length) {
        this.trainingphrasesTemp = this.trainingPhrases;
      }
      if (index > 0) {
        this.trainingPhrases = this.trainingOlder[index];
        this.currentIndex = index;
      }
    },
    goForward(index) {
      if (index < this.trainingOlder.length) {
        this.trainingPhrases = this.trainingOlder[index];
        this.currentIndex = index;
      }
      if (index === this.trainingOlder.length) {
        this.trainingPhrases = [];
        this.trainingPhrases = this.trainingphrasesTemp;
        this.currentIndex = index;
      }
    },
    ReplacePhrases() {
      const r = confirm('Are you sure you want to replace?');
      if (r === true) {
        this.trainingPhrases = JSON.parse(this.phrasesReplace);
        //   this.SendtoDB()
        this.$vs.notify({
          title: 'Phrases Replaced',
          text: 'Must click save to write over previous',
          color: 'primary',
          time: 4000
        });
      }
    },
    hasDuplicates(a) {
      const phrases = a.map((utter) => {
        return utter.phrase;
      });
      return _.uniq(phrases).length !== a.length;
    },
    findDuplicate(array) {
      const uniq = array
        .map((name) => {
          return {
            count: 1,
            name: name.toLowerCase()
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      return Object.keys(uniq).filter((a) => uniq[a] > 1);
    },
    RemoveUtterance(index) {
      this.trainingPhrases.splice(index, 1);
      this.clearDups();
    },
    copyTrainingPhasesURL() {
      this.$clipboard(`${window.location.hostname}/taxonomy?product=${this.collection}&slot=${this.nodes.clicked.data.data.name}&utterance=yes`);
      this.$vs.notify({
        title: 'Utterances link copied to clipboard',
        text: 'You can share this link now',
        color: 'primary',
        time: 4000
      });
    },
    updatePhrase(payload) {
      if (this.highlight.filter((array) => array.text === '@' + payload.entityInfo.name + '.' + payload.value).length > 0) {
        //    this.$vs.notify({
        //   title: `This entity has been copied or used before.`,
        //   text: "Just saying....",
        //   color: "danger",
        //   time: 1000
        // });
      } else {
        this.highlight.push({
          text: '@' + payload.entityInfo.name + '.' + payload.value,
          style: 'background-color:' + payload.entityInfo.highlightColor
        });
      }

      this.$clipboard('' + '@' + payload.entityInfo.name + '.' + payload.value);

      this.$vs.notify({
        title: `Copy successful    <i class="fas fa-thumbs-up"></i>`,
        text: 'Please paste in training phrases',
        color: 'primary',
        time: 3000
      });
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    notify(title_text, color_notify) {
      this.$vs.notify({
        title: title_text + '    ' + "     <i class='fas fa-save'></i>",
        text: 'Successfully saved to database',
        color: color_notify,
        time: 2000
      });
    },
    convertToSlotName(docRef) {
      if (Object.prototype.hasOwnProperty.call(this.slotNameSaved, docRef.path.split('/')[1])) {
        return this.slotNameSaved[docRef.path.split('/')[1]].docReference;
      } else {
        const newQuery = db.collection(docRef.path.split('/')[0]).doc(docRef.path.split('/')[1]);
        this.slotNameSaved[docRef.path.split('/')[1]] = {
          docReference: newQuery
        };
        return newQuery;
      }
    },
    stripTags(text) {
      const tmp = document.createElement('div');
      tmp.innerHTML = text;
      return tmp.textContent || tmp.innerText || '';
    },
    strip(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || '';
    },
    extractEntities(html) {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      const entities = [...doc.getElementsByTagName('span')];
      if (entities.length > 0) {
        return entities.map((obj) => {
          return {
            id: obj.getAttribute('id'),
            name: obj.getAttribute('name'),
            highlightColor: obj.getAttribute('highlightColor'),
            value: obj.getAttribute('value') || obj.getAttribute('name')
          };
        });
      }
    },
    checkforDuplicates() {
      this.convertUtterances();
      return new Promise((resolve) => {
        db.collection(this.collection)
          .where(
            'utterances',
            'array-contains-any',
            this.trainingPhrases.map((utter) => {
              return utter.converted != '' ? utter.converted : '__';
            })
          )
          .get()
          .then((ref) => {
            const doc = ref.docs[0];
            if (!ref.empty) {
              if (doc.id === this.nodes.clicked.data.data.id) {
                resolve(false);
              } else {
                this.$message({
                  message: `You saved an utterance that has already been used in slot ${doc.data().name} `,
                  type: 'error',
                  duration: '3000'
                });
                resolve(doc.data());
              }
            } else {
              resolve(false);
            }
          });
      });
    },
    findDuplicates() {
      const utterances = db.collectionGroup('Utterances').orderBy('timestamp', 'desc').limit(2);
      this.allUtterances = [];
      Promise.all([utterances]).then(() => {
        utterances
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              if (doc.data() && doc.data().utterances) {
                doc.data().utterances.map((utter) => {
                  return utter.phrase;
                });
              }
              this.convertToSlotName(doc.ref)
                .get()
                .then((slotDoc) => {
                  utterances.forEach((r) => {
                    this.allUtterances.push({
                      phrase: r,
                      slotName: slotDoc.data().name,
                      taxonomy: doc.ref.path ? doc.ref.path.split('/')[0] : ''
                    });
                  });
                });
            });
          })
          .then(() => {
            this.loading = false;
            const totalUtterances = this.allUtterances.concat(this.trainingPhrases);
            if (this.hasDuplicates(totalUtterances)) {
              this.$message({
                message: 'You saved an utterance that has already been used elsewhere'
              });
            }
          });
      });
    },
    clearDups() {
      for (const utter2 of [...document.getElementsByClassName('editor editorTraining')]) {
        utter2.className = 'editor editorTraining';
      }
    },
    async SendtoDB() {
      // this.findDuplicates()

      this.convertUtterances();
      // if (this.hasDuplicates(this.trainingPhrases)) {
      try {
        const filter = this.trainingPhrases.map((a) => {
          return a.converted.toLowerCase().trim();
        });
        const duplicates = [...new Set(this.findDuplicate(filter))];
        this.$vs.loading();
        const slots = await this.appStore.getAllSlots({
          collection: this.collection
        });
        const currIntentUtteranceArr = this.trainingPhrases.map((currPhrase) => {
          return currPhrase?.converted.trim();
        });
        let dupEntryInAnotherIntent = false;
        let dupEntryIntentName = '';
        slots.every((currIntent) => {
          if (currIntent.utterances) {
            const localUttrArr = currIntent.utterances.map((currUttr) => {
              return currUttr.trim();
            });
            const interArr = _.intersection(localUttrArr, currIntentUtteranceArr);
            if (interArr.length === 1 && interArr[0].trim() === '') {
              return true;
            }
            if (interArr.length > 0 && currIntent?.name !== this.nodes?.clicked?.data?.data?.name) {
              dupEntryInAnotherIntent = true;
              dupEntryIntentName = currIntent.name;
              return false;
            }
          }
          return true;
        });
        this.$vs.loading.close();
        if (duplicates.length > 0) {
          for (const utter of [...document.getElementsByTagName('p')]) {
            for (const dup of duplicates)
              if (this.stripTags(utter.innerHTML).trim().toLowerCase() === dup.trim().toLowerCase()) {
                const closest = utter.closest('.editorTraining');
                closest.className = 'editor editorTraining dup';
              }
          }
          this.$message({
            message: `This slot has an utterance that has been added multiple times. Please remove the utterance before saving`,
            type: 'error'
          });
          return;
        } else if (dupEntryInAnotherIntent) {
          this.$message({
            message: `This slot has an utterance that is already present in intent ${dupEntryIntentName}. Please remove the utterance before saving`,
            type: 'error'
          });
          return;
        } else {
          db.collection(this.collection)
            .doc(this.nodes.clicked.data.data.id)
            .collection('Utterances')
            .add({
              utterances: this.trainingPhrases,
              user: this.email,
              timestamp: firebase.firestore.FieldValue.serverTimestamp(),
              highlight: this.highlight
            })
            .then(() => {
              // eslint-disable-next-line no-unused-vars
              // const h = this.$createElement;
              this.$createElement;
              this.$message({
                message: 'Training Phrases saved'
              });
              db.collection(this.collection)
                .doc(this.nodes?.clicked?.data?.data?.id)
                .update({
                  utterances: this.trainingPhrases.map((utter) => {
                    return utter.converted;
                  }),
                  hasUtterances: true
                });
              for (const utter of [...document.getElementsByClassName('editor editorTraining')]) {
                utter.className = 'editor editorTraining';
              }
            })
            .catch(() => {
              this.$message({
                message: 'Unable to save training phrases',
                duration: 3000,
                type: 'error'
              });
            });
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    SaveEntities(arrayofEntities) {
      let xx = arrayofEntities.map((a) => a.id);

      xx = xx.sort();
      const formattedArray = xx.join().toString();

      db.collection(this.collection)
        .where('entitiesFormatted', '==', formattedArray)

        .get()
        .then((snapshot) => {
          if (snapshot.empty || snapshot.docs[0].id === this.nodes.clicked.data.data.id) {
            db.collection(this.collection)
              .doc(this.nodes.clicked.data.data.id)
              .update({
                entitiesFormatted: formattedArray,
                entitiesChosen: this.entities_chosen
              })
              .then(() => {
                this.$message({
                  message: 'Entity Selection saved'
                });
                this.entities_have_been_chosen = true;
              });
          } else {
            db.collection(this.collection)
              .doc(this.nodes.clicked.data.data.id)
              .update({
                entitiesFormatted: formattedArray,
                entitiesChosen: this.entities_chosen
              })
              .then(() => {
                this.$message({
                  message: 'Entity Selection saved'
                });
                this.entities_have_been_chosen = true;
              });
          }
        });
    },
    onDrop(dropResult) {
      this.trainingPhrases = applyDrag(this.trainingPhrases, dropResult);
    },
    addResponse() {
      if (!this.entities_have_been_chosen) {
        this.$notify({
          title: 'Must choose entities first  <i class="fas fa-thumbs-down"></i>',
          // eslint-disable-next-line no-undef
          message: h('i', { style: 'color: red' }, 'Please chose your entities')
        });

        return;
      }

      this.AddUtterance(0);
    },
    AddUtterance(index) {
      this.trainingPhrases.splice(index + 1, 0, {
        type: 'batch_text',
        phrase: '',
        role: this.appStore.getClaims?.role,
        uuid: this.appStore.getUser.uid,
        lastSavedBy: this.appStore.getUser.displayName,
        photoURL: this.appStore.getUser.photoURL,
        timestamp: Date.now()
      });
      this.clearDups();
    },
    removeResponse() {
      this.trainingPhrases.pop();
    },
    remove(item) {
      this.entities_chosen.splice(this.entities_chosen.indexOf(item), 1);
    },
    onData(current) {
      return current;
    }
  },
  computed: {
    slotCLickedTrainingStyle() {
      return {
        fontSize: '32px',
        color: this.nodes.clicked.data.data.color ? this.nodes.clicked.data.data.color : this.nodes.clicked.parent.data.data.color
      };
    }
  },
  watch: {
    data: {
      handler(current) {
        this.onData(current);
      },
      deep: true
    }
  }
};
</script>

<style lang="less">
.vs-component {
  font-size: 10px;
}

.el-tabs.el-tabs--left {
  height: 95%;
  border-radius: 15px;
}

.el-tabs__item.is-active {
  font-weight: 700;
  font-size: 16px;
}

.el-transfer-panel__item {
  height: 10px;
}

.el-select {
  display: inline-block;
  position: relative;
  width: 92%;
}

.el-transfer-panel__list.is-filterable {
  height: 400px;
}

.el-transfer-panel {
  max-height: 600px;
  height: 100%;
  border-radius: 15px;
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
}
.text {
  font-size: 10px;
}

.card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  font-size: 13px;
}

.bubble {
  width: fit-content;
  background-color: #fb5422;
  padding: 3px;
  border-radius: 25px;
  color: black;
  float: right;
  font-size: 12px;
  margin-right: 10px;
  border: none;
}

.bubble.bot:before {
  display: none;
}

.bubble.bot {
  width: 30em;
  height: auto;
  background-color: white;
  padding: 3px;
  border-radius: 25px;
  color: black;
  font-size: 12px;
  margin-right: 1px;
  border: none;
}

.bubble.bot.batchtext {
  width: 36em;
  height: 6em;
  background-color: lightgray;
  padding: 3px;
  border-radius: 15px;
  color: black;
  font-size: 12px;
  margin-right: 1px;
  border: none;
  overflow: hidden;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
  font-family: inherit;
}

.con-chips {
  height: 40px;
  border-radius: 15px;
  padding: 3px;
}

.suggestion {
  float: right;
  color: rgba(0, 0, 0, 0.7);
  background-color: white;
  border: 0.3px solid white;
  border-radius: 30px;
  cursor: pointer;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  box-shadow: 1px 1px 2px black;
}

.mdc-card {
  background-color: white;
  max-width: 50%;
  margin-bottom: 5px;
  animation: msg 0.45s ease-in-out;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(32, 33, 36, 0.28);
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
}

.mdc-card__media-item {
  height: auto;
  width: 100%;
  margin-top: -5px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 0.7; /* Firefox */
  font-style: italic;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

/* .list-enter, */
.list-leave-to {
  opacity: 0;
  transform: translateY(-200px);
}

// ::-webkit-scrollbar {
//    width: 0.2em;
// }

// ::-webkit-scrollbar-track {
//    -webkit-box-shadow: inset 0 0 6p white;
// }

// ::-webkit-scrollbar-thumb {
//    background-color: lightgrey;
//    outline: 1px solid lightgray;
// }
</style>

<style scoped>
.trainingOptions {
  opacity: 0;
}

.trainingOptions:hover {
  opacity: 1;
}

.slotClickedTraining {
  font-weight: bold;
  font-family: 'Oswald', sans-serif !important;
}

.wrapper.ai-window {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  /* display: grid; */
  align-items: center;
  justify-content: stretch;
  padding: 8px;
  overflow-y: hidden;
}

.intent-training-data-full-wrapper {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
}

.intent-training-phrases-wrapper {
  width: 35%;
  height: 100%;
  background-color: white;
}

.intent-training-phrases-container {
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.intent-training-phrases-select-entities {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.intent-training-phrases {
  width: 100%;
  height: 100%;
  overflow: scroll;
  margin: 0;
}

.intent-entities {
  width: 65%;
  height: 100%;
  overflow: scroll;
  padding: 3px;
  background-color: white;
}

.intent-training-data-full-wrapper .intent-training-phrases-header-close button {
  float: right;
}

.intent-training-data-full-wrapper .slotClickedTraining {
  font-weight: bold;
  font-family: 'Roboto', sans-serif !important;
  margin-left: 0px;
  font-size: 32px;
  color: blue;
}

.intent-training-data-full-wrapper .intent-training-phrases .list-item .draggable-item {
  width: 100%;
  overflow-y: hidden;
}

.intent-training-phrases-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.intent-training-data-full-wrapper .intent-training-phrases .list-item .draggable-item .intent-training-phrases-item-content {
  margin-bottom: 8px;
}

.intent-training-data-full-wrapper .intent-training-phrases .trainingOptions {
  padding-bottom: 2px;
}

.intent-training-data-full-wrapper .intent-training-phrases .trainingOptions .grid i {
  padding-left: 10px;
}

.intent-training-data-full-wrapper i.fas.fa-check-double {
  margin-left: 6px;
  font-size: 1.5em;
}

.intent-training-phrases-info {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flow-root;
}

.intent-training-phrases-info span {
  float: left;
  font-size: 1.35rem;
  font-weight: bold;
  font-family: Roboto, sans-serif !important;
  margin-left: 0px;
}

.intent-training-phrases-info .el-badge__content {
  color: #fb5422;
}

.intent-training-phrases-info .el-button {
  float: right;
  background: black;
  color: white;
  margin-right: 10px;
}

.intent-training-data-full-wrapper .intent-training-phrases .el-select {
  margin-bottom: 20px;
}
</style>
