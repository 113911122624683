import { statuses } from '../components/Utilities/constants/status';
import { STATUS_COLORS } from '../components/Utilities/constants/constants';

const { detect } = require('detect-browser');

const defaultState = {
  current: {
    slot: '',
    taxonomy: '',
    taxonomyId: '',
    breadCrumbs: [],
    activeScene: '',
    slots: [],
    flows: [],
    flowPrintProperties: {}
  },
  initialCanvasState: {},
  snackbar: false,
  snackbarText: '',
  taxonomySlots: {},
  taxonomyFlows: {},
  clipboard: '',
  readyToImport: false,
  jobs: {},
  jobsV3: {},
  runs: {},
  selectedEdge: '',
  selectedEdgeOptionData: {},
  targetNode: '',
  sourceNode: '',
  lastFilter: 'APPROVED',
  lastFilterClient: 'READY FOR REVIEW',
  daysBack: 7,
  snapshots: {},
  masterUndoCanvasList: [],
  masterRedoCanvasList: [],
  initialFlow: {},
  editSidebarActive: false,
  connect: false,
  message: null,
  usersConnected: [],
  taxonomies: [],
  zoomLevel: '100',
  activeContainer: null,
  uploadOperations: [],
  //detect()?.name instead of detect().name required for Store.spec.js to be able to use the store for unit test
  browser: detect()?.name,
  voiceActive: false,
  responses: [],
  slots: [],
  scene: {},
  user: null,
  userSettings: {},
  language: 'en',
  languageTitle: 'English',
  alternativeLanguage: [{ English: 'en' }],
  languageCodes: { en: 'English' },
  currentColor: '#2698ed',
  allUsers: [],
  claims: {},
  saveConfig: { type: 'INTERNAL' },
  alteredNodes: [],
  notificationsCount: null,
  tippyObj: {},
  duplicateCounter: {},
  notesSideBar: false,
  drawer: false,
  drawerMetadata: {},
  cuiServer: process.env.VUE_APP_INTEGRATIONS_ENDPOINT,
  searchActive: false,
  inputDisabled: false,
  containers: {},
  containerList: [],
  components: {},
  componentList: [],
  resetContainers: [],
  componentId: '',
  displayMode: 'builder',
  mouseOverComponent: '',
  stateRecomputeCounter: 0,
  selectedContainers: [],
  canvasDisabled: false, //This flag will disable all operations that modifies json.
  isEditingContainerId: false,
  bottomNavHovering: false,
  originalNodePositions: [],
  statusColor: STATUS_COLORS,
  flowsFilterStatusesData: {
    status: statuses,
    published: false,
    watching: false
  },
  rerenderEdges: 0,
  invalidActionsCount: 0
};

export default defaultState;
