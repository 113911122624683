import axios from 'axios';

export const callBuilderGenerateJSON = async (state, draft, uxStateOnly) => {
  const { scene, components, containers, taxonomies, containerList, componentList, comments, user, language } = state;
  // If there is no container present in the canvas, abort
  if (!containerList.length) {
    return null;
  }

  const { slot, taxonomy } = state.current;
  const payload = {
    scene,
    components,
    containers,
    taxonomies,
    containerList,
    componentList,
    comments,
    user,
    language,
    slot,
    taxonomy,
    version: process.env.VUE_APP_PLATFORM_VERSION,
    project: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    isDraft: draft,
    uxStateOnly
  };

  const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/generateJSON`, payload, { timeout: 180000 });

  return response.data;
};
