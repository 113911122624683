<template>
  <div
    @mouseover="hover = true"
    @mouseleave="
      hover = false;
      $refs.content.menu ? ($refs.content.menu.isActive = false) : '';
    "
    class="editor"
    id="editor"
    :class="{
      editorNote: type === 'note',
      editorReply: type === 'reply'
    }"
  >
    <div v-if="table && hover" :editor="editor">
      <div class="menubar">
        <button v-if="editor.isActive('table') && table" class="menubar__button" @click="editor.commands.insertTable({ rows: 2, cols: 2 })">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path fill-rule="evenodd" d="M17,17 L17,22 L19,22 C20.6568542,22 22,20.6568542 22,19 L22,17 L17,17 Z M15,17 L9,17 L9,22 L15,22 L15,17 Z M17,15 L22,15 L22,9 L17,9 L17,15 Z M15,15 L15,9 L9,9 L9,15 L15,15 Z M17,7 L22,7 L22,5 C22,3.34314575 20.6568542,2 19,2 L17,2 L17,7 Z M15,7 L15,2 L9,2 L9,7 L15,7 Z M24,16.1768671 L24,19 C24,21.7614237 21.7614237,24 19,24 L5,24 C2.23857625,24 2.11453371e-15,21.7614237 1.77635684e-15,19 L0,5 C-3.38176876e-16,2.23857625 2.23857625,2.28362215e-15 5,0 L19,0 C21.7614237,-5.07265313e-16 24,2.23857625 24,5 L24,7.82313285 C24.0122947,7.88054124 24.0187107,7.93964623 24.0187107,8 C24.0187107,8.06035377 24.0122947,8.11945876 24,8.17686715 L24,15.8231329 C24.0122947,15.8805412 24.0187107,15.9396462 24.0187107,16 C24.0187107,16.0603538 24.0122947,16.1194588 24,16.1768671 Z M7,2 L5,2 C3.34314575,2 2,3.34314575 2,5 L2,7 L7,7 L7,2 Z M2,9 L2,15 L7,15 L7,9 L2,9 Z M2,17 L2,19 C2,20.6568542 3.34314575,22 5,22 L7,22 L7,17 L2,17 Z" />
          </svg>
        </button>

        <button v-if="table" class="menubar__button" @click="editor.commands.deleteTable()">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path
              d="M19,14 C21.7600532,14.0033061 23.9966939,16.2399468 24,19 C24,21.7614237 21.7614237,24 19,24 C16.2385763,24 14,21.7614237 14,19 C14,16.2385763 16.2385763,14 19,14 Z M16.5,19.9375 L21.5,19.9375 C22.017767,19.9375 22.4375,19.517767 22.4375,19 C22.4375,18.482233 22.017767,18.0625 21.5,18.0625 L16.5,18.0625 C15.982233,18.0625 15.5625,18.482233 15.5625,19 C15.5625,19.517767 15.982233,19.9375 16.5,19.9375 Z M12.2898787,17 L9,17 L9,22 L12.6736312,22 C13.0297295,22.7496048 13.515133,23.4258795 14.1010173,24 L5,24 C2.23857625,24 -1.43817996e-15,21.7614237 -1.77635684e-15,19 L-3.55271368e-15,5 C-3.89089055e-15,2.23857625 2.23857625,5.07265313e-16 5,-1.77635684e-15 L19,-1.77635684e-15 C21.7614237,-2.28362215e-15 24,2.23857625 24,5 L24,7.82313285 C24.0122947,7.88054124 24.0187107,7.93964623 24.0187107,8 C24.0187107,8.06035377 24.0122947,8.11945876 24,8.17686715 L24,14.1010173 C23.4258795,13.515133 22.7496048,13.0297295 22,12.6736312 L22,9 L17,9 L17,12.2898787 C16.2775651,12.5048858 15.6040072,12.8333806 15,13.2546893 L15,9 L9,9 L9,15 L13.2546893,15 C12.8333806,15.6040072 12.5048858,16.2775651 12.2898787,17 Z M17,7 L22,7 L22,5 C22,3.34314575 20.6568542,2 19,2 L17,2 L17,7 Z M15,7 L15,2 L9,2 L9,7 L15,7 Z M7,2 L5,2 C3.34314575,2 2,3.34314575 2,5 L2,7 L7,7 L7,2 Z M2,9 L2,15 L7,15 L7,9 L2,9 Z M2,17 L2,19 C2,20.6568542 3.34314575,22 5,22 L7,22 L7,17 L2,17 Z"
            />
          </svg>
        </button>
        <button v-if="table" class="menubar__button" @click="editor.commands.addColumnAfter()">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path
              d="M5,14 C7.76005315,14.0033061 9.99669388,16.2399468 10,19 C10,21.7614237 7.76142375,24 5,24 C2.23857625,24 1.77635684e-15,21.7614237 1.77635684e-15,19 C1.77635684e-15,16.2385763 2.23857625,14 5,14 Z M7.5,19.9375 C8.01776695,19.9375 8.4375,19.517767 8.4375,19 C8.4375,18.482233 8.01776695,18.0625 7.5,18.0625 L6.25,18.0625 C6.07741102,18.0625 5.9375,17.922589 5.9375,17.75 L5.9375,16.5 C5.9375,15.982233 5.51776695,15.5625 5,15.5625 C4.48223305,15.5625 4.0625,15.982233 4.0625,16.5 L4.0625,17.75 C4.0625,17.922589 3.92258898,18.0625 3.75,18.0625 L2.5,18.0625 C1.98223305,18.0625 1.5625,18.482233 1.5625,19 C1.5625,19.517767 1.98223305,19.9375 2.5,19.9375 L3.75,19.9375 C3.92258898,19.9375 4.0625,20.077411 4.0625,20.25 L4.0625,21.5 C4.0625,22.017767 4.48223305,22.4375 5,22.4375 C5.51776695,22.4375 5.9375,22.017767 5.9375,21.5 L5.9375,20.25 C5.9375,20.077411 6.07741102,19.9375 6.25,19.9375 L7.5,19.9375 Z M16,19 C16,20.6568542 17.3431458,22 19,22 C20.6568542,22 22,20.6568542 22,19 L22,5 C22,3.34314575 20.6568542,2 19,2 C17.3431458,2 16,3.34314575 16,5 L16,19 Z M14,19 L14,5 C14,2.23857625 16.2385763,0 19,0 C21.7614237,0 24,2.23857625 24,5 L24,19 C24,21.7614237 21.7614237,24 19,24 C16.2385763,24 14,21.7614237 14,19 Z"
            />
          </svg>
        </button>
        <button v-if="table" class="menubar__button" @click="editor.commands.deleteColumn()">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path d="M12.6414391,21.9312708 C12.9358807,22.5689168 13.3234155,23.1547532 13.7866134,23.6713497 C13.2317936,23.8836754 12.6294813,24 12,24 C9.23857625,24 7,21.7614237 7,19 L7,5 C7,2.23857625 9.23857625,0 12,0 C14.7614237,0 17,2.23857625 17,5 L17,12.2898787 C16.2775651,12.5048858 15.6040072,12.8333806 15,13.2546893 L15,5 C15,3.34314575 13.6568542,2 12,2 C10.3431458,2 9,3.34314575 9,5 L9,19 C9,20.6568542 10.3431458,22 12,22 C12.220157,22 12.4347751,21.9762852 12.6414391,21.9312708 Z M19,14 C21.7600532,14.0033061 23.9966939,16.2399468 24,19 C24,21.7614237 21.7614237,24 19,24 C16.2385763,24 14,21.7614237 14,19 C14,16.2385763 16.2385763,14 19,14 Z M16.5,19.9375 L21.5,19.9375 C22.017767,19.9375 22.4375,19.517767 22.4375,19 C22.4375,18.482233 22.017767,18.0625 21.5,18.0625 L16.5,18.0625 C15.982233,18.0625 15.5625,18.482233 15.5625,19 C15.5625,19.517767 15.982233,19.9375 16.5,19.9375 Z" />
          </svg>
        </button>
        <button v-if="table" class="menubar__button" @click="editor.commands.addRowAfter()">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24">
            <path
              d="M19,0 C21.7600532,0.00330611633 23.9966939,2.23994685 24,5 C24,7.76142375 21.7614237,10 19,10 C16.2385763,10 14,7.76142375 14,5 C14,2.23857625 16.2385763,0 19,0 Z M21.5,5.9375 C22.017767,5.9375 22.4375,5.51776695 22.4375,5 C22.4375,4.48223305 22.017767,4.0625 21.5,4.0625 L20.25,4.0625 C20.077411,4.0625 19.9375,3.92258898 19.9375,3.75 L19.9375,2.5 C19.9375,1.98223305 19.517767,1.5625 19,1.5625 C18.482233,1.5625 18.0625,1.98223305 18.0625,2.5 L18.0625,3.75 C18.0625,3.92258898 17.922589,4.0625 17.75,4.0625 L16.5,4.0625 C15.982233,4.0625 15.5625,4.48223305 15.5625,5 C15.5625,5.51776695 15.982233,5.9375 16.5,5.9375 L17.75,5.9375 C17.922589,5.9375 18.0625,6.07741102 18.0625,6.25 L18.0625,7.5 C18.0625,8.01776695 18.482233,8.4375 19,8.4375 C19.517767,8.4375 19.9375,8.01776695 19.9375,7.5 L19.9375,6.25 C19.9375,6.07741102 20.077411,5.9375 20.25,5.9375 L21.5,5.9375 Z M5,16 C3.34314575,16 2,17.3431458 2,19 C2,20.6568542 3.34314575,22 5,22 L19,22 C20.6568542,22 22,20.6568542 22,19 C22,17.3431458 20.6568542,16 19,16 L5,16 Z M5,14 L19,14 C21.7614237,14 24,16.2385763 24,19 C24,21.7614237 21.7614237,24 19,24 L5,24 C2.23857625,24 3.38176876e-16,21.7614237 0,19 C-1.2263553e-15,16.2385763 2.23857625,14 5,14 Z"
            />
          </svg>
        </button>
        <button v-if="table" class="menubar__button" @click="editor.commands.deleteRow()">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path d="M13.2546893,15 C12.8333806,15.6040072 12.5048858,16.2775651 12.2898787,17 L5,17 C2.23857625,17 3.38176876e-16,14.7614237 0,12 C-1.2263553e-15,9.23857625 2.23857625,7 5,7 L19,7 C21.7614237,7 24,9.23857625 24,12 C24,12.6294813 23.8836754,13.2317936 23.6713497,13.7866134 C23.1547532,13.3234155 22.5689168,12.9358807 21.9312708,12.6414391 C21.9762852,12.4347751 22,12.220157 22,12 C22,10.3431458 20.6568542,9 19,9 L5,9 C3.34314575,9 2,10.3431458 2,12 C2,13.6568542 3.34314575,15 5,15 L13.2546893,15 Z M19,14 C21.7600532,14.0033061 23.9966939,16.2399468 24,19 C24,21.7614237 21.7614237,24 19,24 C16.2385763,24 14,21.7614237 14,19 C14,16.2385763 16.2385763,14 19,14 Z M16.5,19.9375 L21.5,19.9375 C22.017767,19.9375 22.4375,19.517767 22.4375,19 C22.4375,18.482233 22.017767,18.0625 21.5,18.0625 L16.5,18.0625 C15.982233,18.0625 15.5625,18.482233 15.5625,19 C15.5625,19.517767 15.982233,19.9375 16.5,19.9375 Z" />
          </svg>
        </button>
        <button v-if="table" class="menubar__button" @click="editor.commands.mergeCells()">
          <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24">
            <path d="M2,19 C2,20.6568542 3.34314575,22 5,22 L19,22 C20.6568542,22 22,20.6568542 22,19 L22,5 C22,3.34314575 20.6568542,2 19,2 L5,2 C3.34314575,2 2,3.34314575 2,5 L2,19 Z M-1.16403344e-15,19 L-3.0678068e-16,5 C-6.44957556e-16,2.23857625 2.23857625,0 5,0 L19,0 C21.7614237,0 24,2.23857625 24,5 L24,19 C24,21.7614237 21.7614237,24 19,24 L5,24 C2.23857625,24 9.50500275e-16,21.7614237 -1.16403344e-15,19 Z M12,10 C12.5522847,10 13,10.4477153 13,11 L13,13 C13,13.5522847 12.5522847,14 12,14 C11.4477153,14 11,13.5522847 11,13 L11,11 C11,10.4477153 11.4477153,10 12,10 Z M12,16 C12.5522847,16 13,16.4477153 13,17 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4477153,21 11,20.5522847 11,20 L11,17 C11,16.4477153 11.4477153,16 12,16 Z M12,3 C12.5522847,3 13,3.44771525 13,4 L13,7 C13,7.55228475 12.5522847,8 12,8 C11.4477153,8 11,7.55228475 11,7 L11,4 C11,3.44771525 11.4477153,3 12,3 Z" />
          </svg>
        </button>

        <button v-if="table" class="menubar__button" @click="editor.commands.setHeading({ level: 4 })">
          <i class="fas fa-heading"></i>
        </button>

        <button class="menubar__button" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }" @click="editor.commands.setTextAlign('left')">
          <i class="fas fa-align-left"></i>
        </button>

        <button class="menubar__button" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }" @click="editor.commands.setTextAlign('center')">
          <i class="fas fa-align-center"></i>
        </button>
      </div>
    </div>

    <div
      ref="content"
      v-if="!readOnly && !voiceActive"
      class="menububble"
      :editor="editor"
      @hide="
        hideLinkMenu;
        hideRefMenu;
      "
    >
      <div class="menububble" :class="{ 'is-active': editor.menu.isActive }" :style="{ left: `${editor.menu.left}px`, bottom: `${editor.menu.bottom}px` }">
        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(linkUrl)">
          <input class="menububble__input" type="text" v-model="linkUrl" placeholder="Paste Link" ref="linkInput" @keydown.esc="hideLinkMenu" />
          <button
            class="menububble__button"
            @click="
              setLinkUrl(null);
              hideLinkMenu;
            "
            type="button"
          >
            Remove
          </button>
        </form>

        <form class="menububble__form" v-if="refMenuIsActive" @submit.prevent="setRefUrl(editor.commands.toggleReference(), refUrl)">
          <input class="menububble__input" type="text" v-model="refUrl" placeholder="Enter Reference Text" ref="refInput" @keydown.esc="hideRefMenu" />
          <button
            class="menububble__button"
            @click="
              setRefUrl(editor.commands.toggleReference(), null);
              hideRefMenu;
            "
            type="button"
          >
            Remove
          </button>
        </form>

        <form class="menububble__form" v-if="telephoneMenuIsActive" @submit.prevent="setTelephoneNumber(editor.commands.toggleTelephone(), phoneNumber)">
          <input class="menububble__input" type="text" v-model="phoneNumber" placeholder="Enter Phone #" ref="telephoneInput" @keydown.esc="hideTelephoneMenu" />
          <button class="menububble__button" @click="setTelephoneNumber(editor.commands.toggleTelephone(), null)" type="button">Remove</button>
        </form>

        <template v-if="!refMenuIsActive && !linkMenuIsActive && !telephoneMenuIsActive && !noteMenuIsActive">
          <button v-if="editor.isActive('table') && !voiceActive && !noAnnotation" class="menububble__button" @click="showRefMenu(editor.getMarkAttrs('reference'))" :class="{ 'is-active': editor.isActive('reference') }">
            <i class="fas fa-file-signature"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menububble__button" @click="showLinkMenu(editor.getMarkAttrs('link'))" :class="{ 'is-active': editor.isActive('link') }">
            <i class="fas fa-link"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().run()">
            <i class="fas fa-bold"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
            <i class="fas fa-italic"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('superscript') }" @click="editor.commands.toggleSuperscript()">
            <i class="fas fa-superscript"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('subscript') }" @click="editor.commands.toggleSubscript()">
            <i class="fas fa-subscript"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
            <i class="fas fa-strikethrough"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('notePrimary') }" @click="editor.commands.toggleHighlight()">
            <i style="color: yellow" class="fas fa-highlighter"></i>
          </button>

          <button v-if="editor.isActive('table') && !noAnnotation && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('note') }" @click="showNoteMenu(editor.getMarkAttrs('note'))">
            <i style="color: thistle" class="fas fa-highlighter"></i>
          </button>

          <button v-if="editor.isActive('table') && !voiceActive" class="menubar__button__menu" :class="{ 'is-active': editor.isActive('bulletList') }" @click="editor.commands.toggleBulletList()">
            <i class="fas fa-circle"></i>
          </button>
        </template>
      </div>
    </div>

    <editor-content v-if="type === 'text' || type === 'voice'" :class="`editor__content__bubble ${id} ${tabindex} ${avatar}`" :editor="editor" />
    <span style="font-size: 8px; float: right" v-if="type === 'voice' && textLength > 0">{{ textLength }} / 640</span>

    <editor-content ref="content" v-if="type === 'note' && !readOnly" class="editor__content__bubble__shorten" :editor="editor"> </editor-content>
    <editor-content ref="content" v-if="type === 'note' && readOnly" class="editor__content__bubble__shorten__readOnly" :editor="editor" />
    <editor-content ref="content" v-if="type === 'reply' && !readOnly" class="editor__content__bubble__shorten__reply" :editor="editor"> </editor-content>
    <editor-content ref="content" v-if="type === 'reply' && readOnly" class="editor__content__bubble__shorten__reply__readOnly" :editor="editor" />

    <editor-content v-if="table" class="editor__content__table" :editor="editor" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { nextTick } from 'vue';
import { Alignment, Superscript, Subscript, Highlight, HighlightSecondary, HorizontalRule, Link, Limit, Reference, Image, Note, NotePrimary, Mention } from '@/components/ConversationBuilder/Extensions/index.js';
import Fuse from 'fuse.js';
import tippy from 'tippy.js';
import { mapActions, mapState } from 'pinia';
import moment from 'moment';
import firebase from 'firebase/compat/app';
import { Editor, EditorContent } from '@tiptap/vue-3';

import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Focus from '@tiptap/extension-focus';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import suggestion from '@tiptap/suggestion';

export default {
  name: 'EditorMain',
  components: {
    EditorContent
  },
  props: {
    reference: {
      type: Array
    },
    avatar: {
      type: Boolean,
      default: false
    },
    noAnnotation: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    value: {
      type: String
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    collection: {
      required: false,
      type: String
    },
    nodes: {
      required: false,
      type: Object
    },
    language: {
      required: false
    },
    replyData: {}
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      placeholder: {
        en: '',
        fr: ''
      },
      tippyObj: {
        note: '',
        lastSavedBy: '',
        photoURL: '',
        show: false
      },
      hover: false,
      textBetween: '',
      editor: null,
      editorChange: false,
      linkUrl: null,
      refUrl: null,
      sayAsPhrase: null,
      phoneNumber: null,
      note: '',
      notePrimary: '',
      linkMenuIsActive: false,
      refMenuIsActive: false,
      telephoneMenuIsActive: false,
      noteMenuIsActive: false,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      insertMentionGroup: () => {},
      observer: null,
      users: [],
      groups: [{ displayName: 'MODERATOR', uid: 1232342 }],
      textLength: 0,
      refsCreated: [],
      linksCreated: [],
      popperOptions: { boundariesElement: 'body', gpuAcceleration: false },
      clientsIDs: [],
      colorsMap: {},
      loadHTMLTimeout: null,
      loadContentTimeout: null
    };
  },
  computed: {
    ...mapState(useAppStore, ['getTippyObj']),
    noteReturn() {
      return this.note;
    },
    setLanguage() {
      return this.appStore.language;
    },
    placeHolderText() {
      if (this.table) {
        return '';
      } else if (this.type === 'note') {
        return 'Record Notes';
      } else if (this.type === 'reply') {
        return 'Leave comment';
      } else {
        return this.setLanguage;
      }
    },
    voiceActive() {
      return this.appStore.voiceActive;
    },
    usersFiltered() {
      return this.users.filter((item) => {
        if (item.displayName && this.value) {
          return this.value.includes(item.displayName.replace(/\s/g, ''));
        }
      });
    },
    groupsFiltered() {
      // return this.groups.filter(item => {
      //     if (item.displayName) {
      //         return this.value.includes(item.displayName.replace(/\s/g, ''))
      //     }
      // })
      return this.groups;
    },
    hasResults() {
      return this.filteredUsers.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    }
  },
  mounted() {
    this.users = this.appStore.getAllUsers;
    this.editor = new Editor({
      editable: this.readOnly || true,
      onInit: () => {},
      onTransaction: ({ getHTML }) => {
        this.loadHTMLTimeout = setTimeout(() => {
          this.$emit('input', getHTML());
        }, 200);
      },
      onCreate: ({ editor }) => {
        editor.view.dom.addEventListener('paste', (event) => {
          event.stopPropagation();
          event.preventDefault();
          const text = (event.clipboardData || window.clipboardData).getData('text/plain');
          try {
            editor.commands.insertContent(text);
          } catch (error) {
            console.error('Error inserting text: ', error);
          }
        });
      },
      onFocus: ({ state }) => {
        this.noteMenuIsActive = false;
        this.notePrimaryMenuIsActive = false;
        this.textLength = state.doc.textContent.length;
        this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
      },
      onBlur: ({ event, state }) => {
        if (event != null) this.textLength = 0;
        this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
      },
      onUpdate: ({ getHTML, transaction }) => {
        try {
          this.editorChange = true;

          this.$emit('input', getHTML());

          this.textLength = transaction.doc.textContent.length;
        } catch (err) {
          console.log('Error updating response bubble');
          this.$emit('input', getHTML());
        }
      },
      useBuiltInExtensions: true,
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          },
          bulletList: {
            itemTypeName: 'listItem'
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Alignment,
        HorizontalRule,
        Note,
        NotePrimary,
        Superscript,
        Subscript,
        Highlight.configure({ multicolor: true }),
        HighlightSecondary,
        Reference,
        Image.configure({
          allowBase64: true,
          inline: true
        }),
        TaskItem.configure({
          nested: true
        }),
        TaskList,
        Link.configure({
          protocols: ['http', 'https', 'ftp', 'file', 'data', 'mailto', 'tel', 'callto']
        }),
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Table.configure({
          resizable: true
        }),
        TableRow,
        TableHeader,
        TableCell,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.avatar === true ? '' : this.placeholder.en,
          showOnlyWhenEditable: true,
          showOnlyCurrent: true
        }),
        Limit.configure({
          limit: this.type === 'voice' ? 640 : 1000000
        }),
        Mention.configure({
          HTMLAttributes: {
            class: 'mentionLabel'
          },
          suggestion,
          // a list of all suggested items
          items: () => this.users,
          // is called when a suggestion starts
          onEnter: ({ items, query, range, command, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.renderPopup(virtualNode);
            // we save the command for inserting a selected mention
            // this allows us to call it inside of our custom popup
            // via keyboard navigation and on click
            this.insertMention = command;
          },
          // is called when a suggestion has changed
          onChange: ({ items, query, range, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.navigatedUserIndex = 0;
            this.renderPopup(virtualNode);
          },
          // is called when a suggestion is cancelled
          onExit: () => {
            // reset all saved values
            this.query = null;
            this.filteredUsers = [];
            this.suggestionRange = null;
            this.navigatedUserIndex = 0;
            this.destroyPopup();
          },
          // is called on every keyDown event while a suggestion is active
          onKeyDown: ({ event }) => {
            // pressing up arrow
            if (event.keyCode === 38) {
              this.upHandler();
              return true;
            }
            // pressing down arrow
            if (event.keyCode === 40) {
              this.downHandler();
              return true;
            }
            // pressing enter
            if (event.keyCode === 13) {
              this.enterHandler(event);
              return true;
            }
            return false;
          },
          // is called when a suggestion has changed
          // this function is optional because there is basic filtering built-in
          // you can overwrite it if you prefer your own filtering
          // in this example we use fuse.js with support for fuzzy search
          onFilter: (items, query) => {
            if (!query) {
              return items;
            }
            const fuse = new Fuse(items, {
              threshold: 0.2,
              keys: ['displayName']
            });
            return fuse.search(query);
          }
        })
        // GroupMention.configure({
        //   // a list of all suggested items
        //   items: () => this.groups,
        //   // is called when a suggestion starts
        //   onEnter: ({ items, query, range, command, virtualNode }) => {
        //     this.query = query;
        //     this.filteredUsers = items;
        //     this.suggestionRange = range;
        //     this.renderPopup(virtualNode);
        //     // we save the command for inserting a selected mention
        //     // this allows us to call it inside of our custom popup
        //     // via keyboard navigation and on click
        //     this.insertMentionGroup = command;
        //   },
        //   // is called when a suggestion has changed
        //   onChange: ({ items, query, range, virtualNode }) => {
        //     this.query = query;
        //     this.filteredUsers = items;
        //     this.suggestionRange = range;
        //     this.navigatedUserIndex = 0;
        //     this.renderPopup(virtualNode);
        //   },
        //   // is called when a suggestion is cancelled
        //   onExit: () => {
        //     // reset all saved values
        //     this.query = null;
        //     this.filteredUsers = [];
        //     this.suggestionRange = null;
        //     this.navigatedUserIndex = 0;
        //     this.destroyPopup();
        //   },
        //   // is called on every keyDown event while a suggestion is active
        //   onKeyDown: ({ event }) => {
        //     // pressing up arrow
        //     if (event.keyCode === 38) {
        //       this.upHandler();
        //       return true;
        //     }
        //     // pressing down arrow
        //     if (event.keyCode === 40) {
        //       this.downHandler();
        //       return true;
        //     }
        //     // pressing enter
        //     if (event.keyCode === 13) {
        //       this.enterHandlerGroups(event);
        //       return true;
        //     }
        //     return false;
        //   },
        //   // is called when a suggestion has changed
        //   // this function is optional because there is basic filtering built-in
        //   // you can overwrite it if you prefer your own filtering
        //   // in this example we use fuse.js with support for fuzzy search
        //   onFilter: (items, query) => {
        //     if (!query) {
        //       return items;
        //     }
        //     const fuse = new Fuse(items, {
        //       threshold: 0.2,
        //       keys: ['displayName']
        //     });
        //     return fuse.search(query);
        //   }
        // })
      ]
    });
  },
  methods: {
    ...mapActions(useAppStore, ['sendSceneToDB']),
    deleteComment(comment) {
      const r = confirm('Are you sure you would like to remove your comment');
      if (r) {
        this.appStore.removeComment({ id: this.id, comment: comment });
        this.$emit('refreshNote');
      }
    },
    convertTimestampToDate(timestamp) {
      return moment(timestamp).format('MMM-DD h:mm a');
    },
    convertTimestamp(timestamp) {
      const a = moment(timestamp);
      const b = moment();
      if (b.diff(a, 'hours') === 0) {
        const mins = Math.round(b.diff(a, 'hours', true) * 60, 3);
        return mins + ' minutes ago';
      } else {
        return `${b.diff(a, 'hours')} hour${b.diff(a, 'hours') === 1 ? '' : 's'} ago`;
      }
    },
    addHorizontalRule() {
      nextTick(() => {
        // this.$refs.content.editor.commands.setHorizontalRule();
        this.editor.commands.setContent(this.value + '<hr>', true);
        this.loadContentTimeout = setTimeout(() => {
          this.editor.commands.setContent(this.value + '<p></p>', true);
        }, 30);
      });
    },
    getAllReferences() {
      try {
        const div = document.createElement('div');
        div.innerHTML = this.value.trim();
        const allLinks = [];
        [...div.childNodes].forEach((x) => {
          if ([...x.getElementsByTagName('a')].length > 0) {
            for (const node of [...x.getElementsByTagName('a')]) {
              allLinks.push(node);
            }
          }
        });

        const allRefs = [];
        [...div.childNodes].forEach((x) => {
          if ([...x.getElementsByTagName('ref')].length > 0) {
            for (const node of [...x.getElementsByTagName('ref')]) {
              allRefs.push(node);
            }
          }
        });
        this.linksCreated = [];
        this.refsCreated = [];
        for (let i = 0; i < allLinks.length; i++) {
          if (allLinks[i].href) {
            this.linksCreated.push(allLinks[i].getAttribute('href'));
          }
        }
        for (let i = 0; i < allRefs.length; i++) {
          if (allRefs[i].attributes) {
            this.refsCreated.push(allRefs[i].getAttribute('reference'));
          }
        }
        this.refsCreated = Array.from(new Set(this.refsCreated));
        this.linksCreated = Array.from(new Set(this.linksCreated));
      } catch (err) {
        console.error('Error: ', err.message, err);
        return;
      }
    },
    destroyNotePopup(instance) {
      if (this.notePopup) {
        // this.notePopup.destroy()
        instance.destroy();
        this.notePopup = null;
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    changeNote(instance) {
      nextTick(() => {
        this.note = instance.reference.getAttribute('note');
        instance.setContent(this.$refs.notePreview);
        this.tippyObj.show = true;
      });
    },
    closeNoteMenu() {
      this.noteMenuIsActive = false;
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    showRefMenu(attrs) {
      this.refUrl = attrs.reference;
      this.refMenuIsActive = true;
      nextTick(() => {
        this.$refs.refInput.focus();
      });
    },
    showTelephoneMenu(attrs) {
      this.phoneNumber = attrs.href;
      this.telephoneMenuIsActive = true;
      nextTick(() => {
        this.$refs.telephoneInput.focus();
      });
    },
    showNoteMenu(attrs) {
      if (attrs) {
        this.note = attrs.note;
      }
      this.noteMenuIsActive = true;
      nextTick(() => {
        this.$refs.content.menu.isActive = false;
      });
    },
    showNotePrimaryMenu(attrs) {
      this.notePrimary = attrs.notePrimary;
      this.notePrimaryMenuIsActive = true;
      nextTick(() => {
        this.$refs.content.menu.isActive = false;
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    hideRefMenu() {
      this.refUrl = null;
      this.refMenuIsActive = false;
    },
    hideTelephoneMenu() {
      this.phoneNumber = null;
      this.telephoneMenuIsActive = false;
    },
    hideNoteMenu() {
      this.note = null;
      this.notePrimary = null;
      this.noteMenuIsActive = false;
      this.notePrimaryMenuIsActive = false;
      nextTick(() => {
        this.$refs.content.menu.isActive = false;
      });
    },
    hideNotePrimaryMenu() {
      this.notePrimary = null;
      this.notePrimaryMenuIsActive = false;
    },
    setLinkUrl(url) {
      this.editor.commands.toggleLink({ href: url });
      this.hideLinkMenu();
    },
    setRefUrl(command, url) {
      command({ reference: url });
      this.hideRefMenu();
    },
    setTelephoneNumber(command, number) {
      command({ href: 'tel:' + number });
      this.hideTelephoneMenu();
    },
    setNote(command, note) {
      command({
        role: this.appStore.getClaims?.role,
        note: note != null ? note.replace('<p>', '').replace('</p>', '') : null,
        uuid: this.appStore.getUser.uid,
        lastSavedBy: this.appStore.getUser.displayName,
        photoURL: this.appStore.getUser.photoURL,
        timestamp: moment(),
        id: this.id
      });
      this.hideNoteMenu();
      this.appStore.retrieveAnnotations();
      nextTick(() => {
        this.$refs.content.menu.isActive = false;
      });
    },
    resolveNote(command, note, textBetween) {
      this.$confirm(`Are you sure you want to resolve this note`, 'Warning', {
        confirmButtonText: 'YES',
        cancelButtonText: 'CANCEL',
        type: 'warning',
        roundButton: true
      }).then(() => {
        appStore
          .resolveNote({
            collection: this.collection,
            slotId: this.nodes.clicked.data.data.id,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            note: note,
            textBetween: textBetween
          })
          .then(() => {
            this.setNote(command, null);
            this.hideNoteMenu();
            this.$message({ message: 'Note resolved', showClose: true });
          });
      });
    },
    // setNotePrimary(command, note) {
    //     command({
    //         role: this.appStore.getClaims?.role,
    //         note: note,
    //         uuid: this.appStore.getUser.uid,
    //         lastSavedBy: this.appStore.getUser.displayName,
    //         photoURL: this.appStore.getUser.photoURL,
    //         timestamp: moment(),
    //         id: this.id
    //     })
    //     this.hideNoteMenu();
    //     this.appStore.retrieveAnnotations()
    //     nextTick(() => {
    //             this.$refs.content.menu.isActive = false;
    //         })

    // },
    upHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length;
    },
    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    },
    enterHandler(event) {
      const user = this.filteredUsers[this.navigatedUserIndex];
      if (user) {
        this.selectUser(user, event);
      }
    },
    enterHandlerGroups(event) {
      const group = this.filteredUsers[this.navigatedUserIndex];
      if (group) {
        this.selectGroup(group, event);
      }
    },
    selectGroup(group, e) {
      // console.log(e, 'event');
      e.preventDefault();
      e.stopPropagation();
      this.insertMentionGroup({
        range: this.suggestionRange,
        attrs: {
          id: group.uid,
          role: group.displayName,
          name: group.displayName,
          label: group.displayName.replace(/\s/g, '')
        }
      });
      this.editor.commands.focus();
    },
    selectUser(user, e) {
      // console.log(e, 'event');
      e.preventDefault();
      e.stopPropagation();
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.uid,
          role: user.customClaims ? user.customClaims.role : 'GROUP',
          name: user.displayName,
          label: user.displayName.replace(/\s/g, '')
        }
      });
      this.editor.commands.focus();
    },

    renderPopup(node) {
      if (this.readOnly || this.type === 'text') {
        return;
      }
      this.destroyPopup();
      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnInit: true,
        arrow: true,
        arrowType: 'round'
      });
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate();
        });
        this.observer.observe(this.$refs.suggestions, {
          childList: true,
          subtree: true,
          characterData: true
        });
      }
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy();
        this.popup = null;
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  },
  beforeUnmount() {
    try {
      if (this.editor) {
        this.editor.destroy();
      }
    } catch (err) {
      //
    }
    clearTimeout(this.loadHTMLTimeout);
    clearTimeout(this.loadContentTimeout);
  },
  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        // this.createReferenceHTML();

        this.editor.commands.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style>
.editorImage {
  width: 220px;
  height: 140px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* say-as[interpret-as='telephone']:after,
a[phoneNumber='true']:after {
    font-family: "Font Awesome 5 Free";
    content: "\f095";
    font-weight: 900;
    font-size: 10px;
    vertical-align: super;
    font-size: 7px;
} */
.referenceText {
  color: #414c53;
  text-align: right !important;
  margin-right: 7px !important;
  font-size: 11px;
}

.referenceText:first-child {
  border-top: 1px solid #707070 !important;
  margin-top: 5px;
}

.referenceText:link,
.referenceText:visited,
.referenceText:active,
.referenceText[href] {
  color: #189bcf !important;
  text-decoration: underline;
}

.referenceText:first-of-type {
  border-top: 1px solid #707070 !important;
  margin-top: 5px !important;
  padding-top: 3px !important;
}

.hr {
  padding-top: 2px;
  margin: 0px;
  margin-top: 2px;
}

hr {
  opacity: 0.3;
  border-top: solid 1px #707070;
  margin: 6.5px 0 4.5px 0px;
}

a[target='_blank'] {
  text-decoration: underline;
}

ref[reference] {
  text-decoration: none;
  /* vertical-align: super; */
  /* color: purple; */
  font-size: inherit;
}

highlight,
h {
  /* background: yellow; */
  text-shadow: 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow, 0 0 5px yellow;
}

highlightsecondary {
  /* background: thistle; */
  text-shadow: 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle, 0 0 5px thistle;

  color: black;
}

.el-popover.el-popper.notePopover {
  background: thistle;
}

.el-popover.el-popper.notePopover.primary {
  background: lightblue;
}

.el-popover.el-popper.notePopover .el-popover__title {
  color: black !important;
  font-size: 12px;
  font-style: italic;
}

.el-popover.el-popper.notePopover .el-popover__title:after {
  font-family: 'Font Awesome 5 Free';
  content: '\f10e';
  font-weight: 900;
  font-size: 10px;
  vertical-align: super;
  font-size: 7px;
}

.el-popover.el-popper.notePopover .el-popover__title:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f10d';
  font-weight: 900;
  font-size: 10px;
  vertical-align: super;
  font-size: 7px;
}

/*
highlightsecondary[note]::after {
    font-family: "Font Awesome 5 Free";
    content: "\f075";
    font-weight: 900;
    font-size: 12px;
    vertical-align: super;
    color:black;
} */

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
  font-style: italic;
  text-decoration-color: black;
}

.editor__content table .column-resize-handle,
.column-resize-handle.ProseMirror-widget {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper table {
  min-width: 340px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
</style>

<style scoped>
.mentionLabel {
  background-color: #efefef;
  border: 1px solid #ccc;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  box-decoration-break: clone;
}
.con-vs-avatar.small {
  width: 20px;
  height: 20px;
}

.replyName {
  padding: 5px;
}
.replyTimestamp {
  font-size: 11px;
  padding: 7px;
}

.replyTimestampToDate {
  font-size: 11px;
  padding: 7px;
}
/*
.replyTimestamp:hover ~ .replyTimestampToDate {
  display: block;
}

.replyTimestamp:hover,
.replyTimestampToDate:hover {
  display: none;
} */

.notePopover,
.buttonSettings {
  z-index: 9992324;
}

.menububble__button {
  font-size: 10px;
}

.menububble__input::placeholder {
  color: white;
  font-size: 10px;
}

.tableOptions {
  font-size: 8px;
  font-weight: 300;
  text-decoration: underline;
}

.editor {
  position: relative;
  max-width: 30rem;
  margin: 0.7rem auto 0rem;
}

.editorNote {
  position: relative;
  max-width: 30rem;
  margin: 0.5rem auto 0rem;
}

.editorReply {
  border-top: 1px solid lightgrey;
  position: relative;
  max-width: 30rem;
  margin: 0.5rem auto 0rem;
}

.menubar {
  margin-bottom: 0rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.editor__content__bubble {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 350px;
  font-size: 13px;
  margin-left: 25px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 25px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 12px 10px 10px;
  cursor: text;
  font-family: 'Roboto', sans-serif !important;
}

.editor__content__bubble__shorten {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 220px;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  font-weight: 400;
  /* border: 1px solid rgb(0, 155, 207); */
  border-radius: 4px;
  -webkit-transition: none;
  transition: none;
  padding: 5px 5px 5px 5px;
  margin-bottom: 8px;
}

.editor__content__bubble__shorten:focus,
.editor__content__bubble__shorten__reply:focus,
.editor__content__bubble__shorten:hover,
.editor__content__bubble__shorten__reply:hover {
  border: 1px solid #009bcf;
}

.editor.editorReply:hover * .deleteComment {
  display: block;
}

.deleteComment {
  padding: 5px;
  display: none;
}

.editor__content__bubble__shorten__reply {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 220px;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  /* border: 1px solid red; */
  border-radius: 4px;
  -webkit-transition: none;
  transition: none;
  padding: 5px 5px 5px 5px;
  margin-bottom: 3px;
  margin-left: 2px;
}

.editor__content__bubble.true:before {
  /* background-image: attr(clientLogo); */
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: -27px;
  content: '';
  display: inline-block;
  height: 20px;
  left: -33px;
  position: absolute;
  width: 20px;
  background-size: cover;
}

sup::before,
sub::before {
  background-image: none !important;
}

.editor__content__bubble.true:after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 23px;
  width: 20px;
  height: 0;
  border: 20px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  border-left: 0;
  margin-left: 3px;
  margin-bottom: -5px;
  transform: rotate(16deg);
  z-index: -1;
}

/* .editor__content__bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 10px;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	border-left: 0;
	margin-left: -10px;
    margin-bottom: -5px;
} */

.editor__content__bubble__shorten__readOnly {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 220px;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  /* border: rgb(0, 155, 207); */
  border-radius: 4px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
}

.editor__content__table {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 350px;
  max-width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 5px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
  cursor: text;
  margin-left: 25px;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar__button__menu {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: white;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.menubar.is-focused {
  visibility: visible;
  opacity: 1;
}
</style>

<style>
.mention {
  background: #fb5422;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.mention[role='CLIENT'] {
  background: #009bcf;
}

.mentionGroup {
  background: lightseagreen;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.editor__content__table table td {
  cursor: col-resize;
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor__content__table table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
  font-weight: inherit;
}

.editor__content__table table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editor__content_bubble table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-size: inherit;
}

.is-empty.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

hr.is-empty {
  float: unset;
  content: unset;
}

.editor__content__bubble p {
  text-align: left;
  margin: 0px;
  font-family: 'Roboto', sans-serif !important;
}

/* .has-focus {
  border-radius: 25px;
  box-shadow: 0 0 0 3px black;
} */

.ProseMirror,
.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror {
  position: relative;
  word-wrap: break-word;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}
</style>

<style lang="scss" scoped>
.sendNotification {
  display: block;
  // float: right;
}

.editor.editorNote > .sendNotification:hover,
.sendNotification:hover {
  display: block;
}

.editor__content__bubble__shorten:hover ~ .sendNotification {
  display: block;
}

.menububble {
  width: max-content;
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  &.is-active {
    opacity: 1;
    visibility: visible; // animation: cssAnimation 0s 3s forwards;
    // &:hover {
    //     visibility: visible;
    // }
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    display: flex;
    align-items: center;
  }
  &__input {
    font-size: 10px;
    border: none;
    background: transparent;
    color: white;
  }
}

@keyframes cssAnimation {
  to {
    visibility: hidden;
  }
}

.addNote {
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 30px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%) translateY(-20px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  width: 200px;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    background: transparent;
    color: white;
  }
  &__input {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: inherit;
    color: white !important;
    border-radius: 25px;
    border-style: none;
    color: inherit;
  }
}

.mention-suggestion {
  color: black;
}

.suggestion-list {
  padding: 0.2rem; // border: 2px solid black;
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: white;
      color: #009bcf;
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}

.tippy-tooltip.dark-theme {
  background-color: black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: white;
  border-radius: 5px;
  .tippy-backdrop {
    display: none;
  }
  .tippy-roundarrow {
    fill: black;
  }
  .tippy-popper[x-placement^='top'] & .tippy-arrow {
    border-top-color: black;
  }
  .tippy-popper[x-placement^='bottom'] & .tippy-arrow {
    border-bottom-color: black;
  }
  .tippy-popper[x-placement^='left'] & .tippy-arrow {
    border-left-color: black;
  }
  .tippy-popper[x-placement^='right'] & .tippy-arrow {
    border-right-color: black;
  }
}
</style>
