import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core';

export const NotePrimary = Mark.create({
  name: 'notePrimary',

  addOptions: {
    openOnClick: true
  },

  // Attributes for the mark
  attributes: {
    note: {
      default: null
    },
    uuid: {
      default: null
    },
    lastSavedBy: {
      default: null
    },
    photoURL: {
      default: null
    },
    timestamp: {
      default: null
    },
    id: {
      default: null
    },
    role: {
      default: null
    }
  },

  // DOM parsing logic
  parseHTML() {
    return [
      {
        style: 'font-style',
        getAttrs: (value) => value === 'italic' && {}
      },
      {
        tag: 'highlight',
        getAttrs: (dom) => ({
          note: dom.getAttribute('note'),
          timestamp: dom.getAttribute('timestamp'),
          lastSavedBy: dom.getAttribute('lastSavedby'),
          photoURL: dom.getAttribute('photoURL'),
          uuid: dom.getAttribute('uuid'),
          id: dom.getAttribute('id'),
          role: dom.getAttribute('role')
        })
      }
    ];
  },

  // DOM rendering logic
  renderHTML({ HTMLAttributes }) {
    return ['highlight', mergeAttributes(HTMLAttributes), 0];
  },

  // Commands to add or remove the mark
  addCommands() {
    return {
      setNotePrimary:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark('notePrimary', attributes);
        },
      toggleNotePrimary:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark('notePrimary', attributes);
        },
      unsetNotePrimary:
        () =>
        ({ commands }) => {
          return commands.unsetMark('notePrimary');
        }
    };
  },

  // Paste rules if any
  addPasteRules() {
    return [
      markPasteRule({
        type: 'notePrimary'
        // Add your paste rule logic here if needed
      })
    ];
  },

  // Plugin logic, e.g. for handling clicks
  addProseMirrorPlugins() {
    if (!this.options.openOnClick) {
      return [];
    }

    // Replace this with your logic for handling clicks
    // if (attrs.href && event.target instanceof HTMLAnchorElement) {
    //   event.stopPropagation()
    //   window.open(attrs.hre, "_blank")
    // }
    return [];
  }
});
