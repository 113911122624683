<template>
  <div v-if="componentSelected" id="rightSidebar">
    <SidebarHeader v-if="type" :type="type" />
    <RootEdit v-bind="$props" v-if="type === 'start'" />
    <MessageEdit v-bind="$props" v-if="type === 'message'" />
    <LinkUrlEdit v-bind="$props" v-if="type === 'linkUrl'" />
    <FlowEdit v-bind="$props" v-if="type === 'flow'" />
    <DecisionEdit v-bind="$props" v-if="type === 'decision'" />
    <ClarificationEdit v-bind="$props" v-if="type === 'clarification'" />
    <CarouselEdit v-bind="$props" v-if="type === 'carousel'" />
    <FormEdit v-bind="$props" v-if="type === 'form'" />
    <SetEdit v-bind="$props" v-if="type === 'set'" />
    <LinkToContainerComponentEdit v-if="type === 'linkToContainer'" :componentId="componentSelected.id" :parentContainerId="componentSelected.parentId" :linkToContainer="componentSelected.linkToContainer" />
  </div>
</template>

<script>
import FlowEdit from './FlowEdit';
import DecisionEdit from './DecisionEdit.vue';
import FormEdit from './FormEdit.vue';
import SidebarHeader from './SidebarHeader';
import LinkUrlEdit from './LinkUrlEdit.vue';
import MessageEdit from './MessageEdit.vue';
import CarouselEdit from './CarouselEdit.vue';
import ClarificationEdit from './ClarificationEdit';
import LinkToContainerComponentEdit from './LinkToContainerComponentEdit';
import essentials from '../Mixins/essentials';

export default {
  name: 'EditSidebar',
  mixins: [essentials],
  components: {
    ClarificationEdit,
    FlowEdit,
    DecisionEdit,
    SidebarHeader,
    LinkUrlEdit,
    MessageEdit,
    CarouselEdit,
    FormEdit,
    LinkToContainerComponentEdit
  }
};
</script>

<style scoped>
#rightSidebar {
  width: auto;
  height: 100vh;
  margin-right: 0px;
  background-color: white;
  padding-top: 60px;
  overflow-x: hidden;
}
</style>
