<template>
  <g :id="id" class="edge__connection" @click="onEdgeClick" @mouseenter="handleMouseEnter" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave" :class="edgeClass">
    <template v-if="!isFromOption">
      <component :is="currentContainerComponent" v-bind="$props" :pathStyle="pathStyle" />
    </template>

    <template v-if="isFromOption">
      <component :is="currentDecisionComponent" v-bind="$props" :arrowTransform="arrowTransform" :pathStyle="pathStyle" />
    </template>

    <defs>
      <marker :id="markerId" markerWidth="6" markerHeight="6" refX="10" refY="5" orient="auto-start-reverse" viewBox="0 0 10 10">
        <path d="M 0 0 L 10 5 L 0 10 z" :style="arrowStyle" />
      </marker>
    </defs>

    <text>
      <textPath :fill="color" :xlink:href="lineId" :style="textStyle" startOffset="70%" textAnchor="middle"></textPath>
    </text>
  </g>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import EdgesCurvedDecision from './EdgesForDecisions/EdgesCurvedDecision.vue';
import EdgesStraightDecision from './EdgesForDecisions/EdgesStraightDecision.vue';
import EdgesCurvedContainers from './EdgesForContainers/EdgesCurvedContainers.vue';
import EdgesStraightContainers from './EdgesForContainers/EdgesStraightContainers.vue';
import EdgeOptions from '../Dropdowns/EdgeOptions.vue';
import { watch } from 'vue';
import { getMousePosition } from '@/components/Utilities/position';

export default {
  name: 'EdgesRenderer',
  components: {
    EdgesCurvedDecision,
    EdgesCurvedContainers,
    EdgesStraightDecision,
    EdgesStraightContainers,
    EdgeOptions
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    source: {},
    target: {},
    optionIndex: {},
    isFromOption: {
      type: Boolean,
      default: null
    },
    dotted: {
      type: Boolean,
      default: false
    },
    curved: {
      type: Boolean,
      default: true
    },
    text: {},
    type: {
      type: Object
    },
    start: {
      type: Array,
      default() {
        return [0, 0];
      }
    },
    end: {
      type: Array,
      default() {
        return [0, 0];
      }
    },
    positions: {
      type: Array,
      default() {
        // Source , Target
        return ['left', 'right'];
      }
    },
    color: {
      type: String,
      default: '#8e9ba7'
    },
    status: {
      default: ''
    },
    highlightConnectingNodes: {
      type: Function
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      forceRecomputeCounter: 0,
      isHovering: false,
      show: {
        observer: null,
        delete: false,
        Position: {
          Left: 'left',
          Top: 'top',
          Right: 'right',
          Bottom: 'bottom'
        },
        arrowPosition: 0
      },
      isEdgeOptionsOpened: false,
      edgeOptionsPosition: { x: 0, y: 0 },
      edgeOptionData: useAppStore().selectedEdgeOptionData,
      updatedToDotted: false
    };
  },
  mounted() {
    const appStore = useAppStore();
    watch(
      () => appStore.selectedEdgeOptionData,
      (newValue) => {
        const { id, selectedOption } = newValue;
        if (this.id === id) {
          if (selectedOption === 'delete') {
            this.linkDelete();
          } else if (selectedOption === 'dotted') {
            this.SET_EDGE_DOTTED();
          } else if (selectedOption === 'curved') {
            this.SET_EDGE_CURVED(true);
          } else if (selectedOption === 'smooth') {
            this.SET_EDGE_CURVED(false);
          }
        }
      },
      { deep: true }
    );
  },
  methods: {
    edgeEdit({ id, curved, dotted }) {
      try {
        this.appStore.setEdgeType({ id, curved, dotted });
        this.appStore.FORCE_STATE_RECOMPUTE();
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    SET_EDGE_CURVED(isCurved) {
      if (isCurved !== this.curved) {
        this.edgeEdit({ id: this.id, curved: isCurved, source: this.source });
      }
    },
    SET_EDGE_DOTTED() {
      this.edgeEdit({ id: this.id, dotted: !this.dotted, source: this.source });
    },
    onEdgeClick(event) {
      if (event) {
        event.stopPropagation();
      }
      this.isEdgeOptionsOpened = true;
      if (this.isEdgeOptionsOpened) {
        this.setEdgeOptionStyle(event);
        this.$emit('openEdgeOptions', { lineId: this.id, position: this.edgeOptionsPosition });
      } else {
        this.destroyEdgeMenu();
      }
    },
    highlightEdge(e) {
      if (e?.target?.style) {
        e.target.style.stroke = '#8e9ba7';
        e.target.style.strokeWidth = '1.5';
      }
    },
    handleMouseEnter(e) {
      if (e?.target?.style) {
        e.target.style.stroke = '#2698ed';
        e.target.style.strokeWidth = '1.5';
      }
      e.target.addEventListener(
        'mouseout',
        (e) => {
          this.edgeHighlightHandler(e);
        },
        false
      );
      e.target.addEventListener(
        'click',
        (e) => {
          this.onEdgeClick(e);
        },
        false
      );
    },
    handleMouseOver(e) {
      this.appStore.setEdgeTargetSource({ edgeId: this.id, targetId: this.target, sourceId: this.source });
      this.isHovering = true;
      if (e?.target?.style) {
        e.target.style.stroke = '#2698ed';
        e.target.style.strokeWidth = '2';
      }
    },
    handleMouseLeave(e) {
      if (e?.target?.style) {
        e.target.style.stroke = '#8e9ba7';
        e.target.style.strokeWidth = '1.5';
      }
      this.appStore.clearConnectingNodes();
      this.isHovering = false;
      e.target.removeEventListener(
        'mouseout',
        (e) => {
          this.edgeHighlightHandler(e);
        },
        false
      );
      e.target.removeEventListener(
        'click',
        (e) => {
          this.onEdgeClick(e);
        },
        false
      );
    },
    destroyEdgeMenu() {
      this.$emit('closeEdgeOptions');
      this.appStore.selectedEdge = '';
    },
    linkDelete() {
      try {
        const linkToDelete = this.appStore.getScene?.links?.find((item) => {
          // These a chance there can be two decision edges which have the same source and target, so have to use optionIndex as differentiator
          // After a new migration change, all edges will have unique ids and this could be simplified to delete link based on id
          if (this.optionIndex) return item.optionIndex === this.optionIndex && item?.from === this.source && item?.to === this.target;
          else return item?.from === this.source && item?.to === this.target;
        });
        if (linkToDelete) {
          const linksToRetain = this.appStore.getScene?.links?.filter((item) => item != linkToDelete);
          this.appStore.updateLinks({ links: linksToRetain });
          this.appStore.removeLinkedDecisionInfo({
            containerId: linkToDelete.to
          });
          this.appStore.removeEmptyUnconnectedContainers({});
        }
      } catch (err) {
        console.error('Error deleting edge', err.message);
      }
    },
    edgeHighlightHandler(e) {
      this.highlightEdge(e);
      // e.target.style.cursor = 'grab';
    },
    setEdgeOptionStyle(event) {
      const [x, y] = getMousePosition(event);
      this.edgeOptionsPosition = { x, y };
    }
  },
  computed: {
    edgeId: {
      get() {
        return this.appStore.selectedEdge;
      }
    },
    isSelected() {
      return this.appStore.selectedEdge === this.id;
    },
    edgeClass() {
      return {
        selected: this.isSelected
      };
    },
    markerId() {
      return `marker-${this.source}`;
    },
    lineId() {
      return `#line-${this.source}`;
    },
    textStyle() {
      return {
        fontSize: '10px'
      };
    },
    currentContainerComponent() {
      return this.curved ? 'EdgesCurvedContainers' : 'EdgesStraightContainers';
    },
    currentDecisionComponent() {
      return this.curved ? 'EdgesCurvedDecision' : 'EdgesStraightDecision';
    },
    arrowTransform() {
      const [sourceX, sourceY] = this.start;
      const [targetX, targetY] = this.end;
      const [arrowX, arrowY] = this.end;
      sourceY, targetY;
      const leftAndRight = ['left', 'right'];
      const [targetPosition, sourcePosition] = this.positions;
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom'
      };
      let arrowPosition = 0;
      const angle = { down: 360, right: 280, left: 95 };
      if (leftAndRight.includes(sourcePosition) && leftAndRight.includes(targetPosition)) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if ((sourcePosition === Position.Right && targetPosition === Position.Left) || (sourcePosition === Position.Left && targetPosition === Position.Right) || (sourcePosition === Position.Left && targetPosition === Position.Left)) arrowPosition = angle['left'];
      }
      if (sourcePosition === 'bottom' && leftAndRight.includes(targetPosition)) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if ((sourcePosition === Position.Right && targetPosition === Position.Left) || (sourcePosition === Position.Left && targetPosition === Position.Right) || (sourcePosition === Position.Left && targetPosition === Position.Left)) arrowPosition = angle['down'];
      }
      if (sourcePosition === 'bottom') {
        if (sourceX === targetX) arrowPosition = angle['down'];
      }
      if (targetPosition === 'top') {
        arrowPosition = angle['right'];
      }
      return `translate(${arrowX}, ${arrowY}) rotate(${arrowPosition || 90})`;
    },
    pathStyle() {
      return {
        stroke: this.isHovering ? '#2698ed' : this.color,
        strokeWidth: 1.5,
        fill: 'none',
        strokeDasharray: this.dotted ? 4 : 0,
        markerEnd: `url(#marker-${this.source})`
      };
    },
    arrowStyle() {
      return {
        fill: this.isHovering ? '#2698ed' : this.color
      };
    }
  },
  beforeUnmount() {
    removeEventListener('mouseout', (e) => {
      this.edgeHighlightHandler(e);
    });
    removeEventListener(
      'click',
      (e) => {
        this.onEdgeClick(e);
      },
      false
    );
  }
};
</script>

<style lang="scss">
.edge__connection {
  position: relative;

  &:hover {
    cursor: pointer;

    .edge__connection-path {
      stroke: #2698ed;
      stroke-width: 2;
    }
  }

  &.selected .edge__connection-path {
    stroke: #2698ed;
    stroke-width: 2;
  }
}

.selected.edge__connection .edge__connection-path {
  stroke: #2698ed;
  stroke-width: 2;
}
</style>

<style lang="scss" scoped>
path:hover ~ text {
  display: block;
}
path ~ text {
  display: none;
}

path {
  z-index: 1000;
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-moz-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

// g {
//   cursor: not-allowed;
// }
</style>
