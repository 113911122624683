<template>
  <div>
    <PdfOptionsDrawer />
    <PDFDrawer />
    <RemoveHighlightsDrawer />
    <NluValidationDFDrawer /><!-- TODO: Remove this Drawer -->
    <NluEngineTrainingDrawer /><!-- TODO: Remove this Drawer -->
    <RasaNluEngineTrainingDrawer />
    <NluReviewDrawer /><!-- TODO: Remove this Drawer -->
    <VoiceJobsDrawer />
    <FindReplaceDrawer />
    <CreateFlow />
    <RenameFlow />
    <nav v-show="currentRouteName !== 'ConvoBuilder'" class="navbar navbar-default navbar-fixed-top">
      <div id="sidebar-header" :class="{ minimized: minimized }">
        <h3 class="headerFull">CMP</h3>
        <img :class="`headerSmall ${minimized ? 'minimized-headerSmall' : ''}`" width="20" src="../assets/neuraSmall.svg" />
      </div>
      <div tabindex="0" class="notifications" v-click-outside="closeNotificationDrawer">
        <div v-if="showNotificationIcon" :class="showNotificationIcon ? `notification-icon ${showNotificationDrawer ? 'notification-icon_is-active' : ''}` : ''" @click="showNotificationDrawer = !showNotificationDrawer">
          <IconUtility name="notification_white" :width="20" />
          <div class="notification-number" v-if="unreadNotifications.length">
            {{ getUnreadNotifications }}
          </div>
        </div>
        <NotificationDrawer v-if="showNotificationDrawer" :type="'app-navbar'" @close-drawer="showNotificationDrawer = false" />
      </div>
      <div class="user" v-if="isGuest || isContentEditor || isEditor || isAdmin">
        <div class="name">
          {{ fullname }}
        </div>
        <div v-if="email" class="avatar">
          <vs-avatar v-if="photoUrl" :src="photoUrl" size="40px" />
          <vs-avatar v-else icon="verified_user" size="40px" color="#007bff" />
        </div>
        <div class="headerMenu-dropdown-container">
          <vs-dropdown class="headerMenu" vs-custom-content vs-trigger-click :key="dropdownKey">
            <a class="login-icon" href="javascript:void(0)">
              <vs-icon icon-pack="fas" icon="fa-ellipsis-v" type="flat"></vs-icon>
            </a>

            <vs-dropdown-menu class="userDropdown">
              <ul class="menu-group">
                <li class="borderBottom">
                  <a class="email" href="javascript:void(0)">
                    <div class="switch-container">
                      Email notifications
                      <label class="switch">
                        <input type="checkbox" @click="toggleEmailNotifications" v-model="emailNotifications" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </a>
                </li>
                <li>
                  <a @click="openFullscreen()" href="javascript:void(0)">Full screen mode</a>
                </li>
                <li v-if="email">
                  <a @click="logout()" href="javascript:void(0)">Logout</a>
                </li>
                <li v-if="isTestEnv">
                  <a @click="tokenToClipboard" href="javascript:void(0)">Copy Auth Token</a>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
    </nav>
    <div class="appwrapper">
      <Sidebar v-show="currentRouteName !== 'ConvoBuilder'" :minimized="minimized" />

      <!-- Page Content  -->
      <div id="content">
        <router-view :key="currentRouteFullPath"></router-view>
      </div>
    </div>
    <!-- vue-final-modal -->
    <ModalsContainer />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import firebase from 'firebase/compat/app';
import axios from 'axios';
import auth from './helpers/auth';
import NotificationDrawer from '@/components/Drawer/Notifications/NotificationDrawer.vue';
import vClickOutside from 'click-outside-vue3';
import Sidebar from './components/Sidebar/AppSidebar.vue';
import IconUtility from './components/Utilities/IconUtility.vue';
import { ModalsContainer } from 'vue-final-modal';
import { appTimeOutModal } from '@/helpers/modals.js';
import { mapState } from 'pinia';
import { useAppStore, useNotificationsStore } from '@/store/index.js';
import PdfOptionsDrawer from '@/components/Drawer/PDFOptionsDrawer.vue';

export default defineComponent({
  props: ['user_information'],
  name: 'app',
  components: {
    PdfOptionsDrawer,
    NotificationDrawer,
    Sidebar,
    IconUtility,
    ModalsContainer
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      appStore: useAppStore(),
      notificationsStore: useNotificationsStore(),
      timeoutShow: false,
      timeoutVar: null,
      notifications: [],
      minimized: false,
      reduce: false,
      fullname: '',
      email: '',
      photoUrl: '',
      dropdownKey: 0,
      showNotificationDrawer: false,
      showNotificationIcon: false,
      emailNotifications: false,
      loadAppTimeout: null
    };
  },
  async mounted() {
    await this.notificationsStore.getNotifications();
    if (!this.currentUser()) this.logout();
    if (this.currentUser()) {
      this.loadAppTimeout = setTimeout(() => {
        // TODO: This gets triggered based on expiry time calculation on browser and not based on session expiry. This need to be refactored so that the appTimOutModal is showed only when session is expiring.
        // this.timeoutFunction();
      }, 4000);
    }
    this.emailNotifications = this.appStore.getClaims?.claims?.emailNotificationsOn;
  },
  beforeUpdate() {
    if (!this.currentUser()) {
      this.showNotificationIcon = false;
      return;
    }

    this.showNotificationIcon = true;
  },
  beforeUnmount() {
    clearTimeout(this.loadAppTimeout);
    clearTimeout(this.timeoutVar);
  },
  computed: {
    ...mapState(useAppStore, ['isGuest', 'isAdmin', 'isEditor', 'isContentEditor']),
    ...mapState(useNotificationsStore, ['unreadNotifications']),
    currentRouteName() {
      return this.$route?.name;
    },
    currentRouteFullPath() {
      return this.$route?.fullPath;
    },
    getUnreadNotifications() {
      if (this.unreadNotifications.length <= 9) {
        return this.unreadNotifications.length;
      }

      return '9+';
    },
    platformVersion() {
      return process.env.VUE_APP_PLATFORM_VERSION || '';
    },
    isTestEnv() {
      return process.env.VUE_APP_FIREBASE_PROJECT_ID === process.env.VUE_APP_DEFAULT_TEST_PROJECT;
    },

    timeToExpiry() {
      // return 3000;
      return this.appStore.getExpirationTime.date - Date.now();
    }
  },
  methods: {
    navigateToLogin() {
      if (this.currentRouteName !== 'login') {
        this.$router.replace('/login');
      }
    },
    async toggleEmailNotifications() {
      const setEmailNotificationTo = !this.emailNotifications || false;
      try {
        const result = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/setEmailNotification`, {
          idToken: this.appStore.getClaims.token,
          setEmailNotificationTo: setEmailNotificationTo,
          uid: this.appStore.getUser.uid
        });
        if (result.status === 200) {
          this.emailNotifications = setEmailNotificationTo;
        } else {
          this.$message({
            message: 'There was an error changing your email setting',
            duration: 2000
          });
          this.emailNotifications = !setEmailNotificationTo;
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
        this.emailNotifications = !setEmailNotificationTo;
        this.$message({
          message: 'There was an error changing your email setting',
          duration: 2000
        });
      }
    },
    closeNotificationDrawer() {
      this.showNotificationDrawer = false;
    },
    tokenToClipboard() {
      try {
        this.appStore.refreshToken().then((idTokenResult) => {
          const token = idTokenResult.token;
          this.$clipboard(token);
          this.appStore.SET_TOKEN({ token });
          this.$message({
            message: 'Your token has been copied to your clipboard',
            duration: 2000
          });
        });
      } catch (err) {
        this.$message({
          message: 'An error occurred retrieving your token',
          duration: 2000
        });
      }
    },
    timeoutFunction() {
      if (this.timeoutVar != null) {
        clearTimeout(this.timeoutVar);
      }

      this.timeoutVar = setTimeout(() => {
        appTimeOutModal().open();

        // this.startTimer();
      }, this.timeToExpiry - 300000);
    },
    secure() {
      /* eslint-disable */
      grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise.execute(process.env.VUE_APP_SITE_KEY, { action: 'login' }).then((token) => {
          axios
            .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/security/assess`, {
              token: token,
              siteKey: process.env.VUE_APP_SITE_KEY,
              expectedAction: 'USER_ACTION',
              projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID
            })
            .then((response) => console.log(response));
        });
      });
    },
    logout: function () {
      this.notificationsStore.removeAllNotifications();
      this.dropdownKey = Math.random();
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.appStore.LOGOUT();

          if (this.currentRouteName?.toLowerCase() !== 'login') {
            this.navigateToLogin();
          }
          auth(this);
        });
    },
    openFullscreen: function () {
      this.dropdownKey = Math.random();
      if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE11 */
          document.msExitFullscreen();
        }
      } else {
        this.minimized = true;
        let elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
      }
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null && user.email) {
        this.fullname = user.displayName;
        this.email = user.email;
        this.photoUrl = user.photoURL;
        this.appStore.updateUser({ user: user });
        return true;
      } else {
        this.fullname = '';
        this.email = '';
        this.photoUrl = '';
        return false;
      }
    }
  },

  updated() {
    this.currentUser();
  }
});
</script>
