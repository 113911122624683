import { Node } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';

export const GroupMention = Node.create({
  name: 'GroupMention',

  addOptions: {
    matcher: {
      char: '#',
      allowSpaces: false,
      startOfLine: false
    },
    mentionClass: 'mentionGroup',
    suggestionClass: 'mention-suggestion'
  },

  inline: true,
  group: 'inline',
  atom: true,
  selectable: false,

  addAttributes() {
    return {
      id: {
        default: null
      },
      label: {
        default: null
      },
      role: {
        default: null
      },
      name: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: (dom) => {
          const id = dom.getAttribute('id');
          const role = dom.getAttribute('role');
          const name = dom.getAttribute('name');
          const label = dom.innerText.split(this.options.matcher.char).join('');
          return { id, label, role, name };
        }
      }
    ];
  },

  renderHTML({ node }) {
    return [
      'span',
      {
        ...node.attrs,
        class: this.options.mentionClass
      },
      `${this.options.matcher.char}${node.attrs.label}`
    ];
  },

  addCommands() {
    return {
      setGroupMention:
        (attrs) =>
        ({ commands }) => {
          return commands.insertContent({ type: this.name, attrs });
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.options.matcher,
      callback: () => {
        this.editor.commands.setGroupMention({ type: this.name, attrs: this.options.attrs });
        return true;
      }
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        command: ({ attrs }) => {
          return this.editor.commands.setGroupMention(attrs);
        },
        appendText: ' ',
        matcher: this.options.matcher,
        items: this.options.items,
        onEnter: this.options.onEnter,
        onChange: this.options.onChange,
        onExit: this.options.onExit,
        onKeyDown: this.options.onKeyDown,
        onFilter: this.options.onFilter,
        suggestionClass: this.options.suggestionClass,
        editor: this.editor,
        ...this.options
      })
    ];
  }
});
