<template>
  <div class="scroll">
    <div class="sidebar components">
      <div class="form-container row">
        <div class="col-sm-2">
          <span class="sidebar form-type-title"><strong>Pages</strong></span>
        </div>
        <div class="options col-sm-8">
          <span v-for="(page, idx) in localFormdata" :key="idx" :class="{ numTab: true, selected: idx === currentPage }">{{ idx + 1 }} </span>
          <span class="addNumTab">
            <strong>+</strong>
          </span>
        </div>
      </div>
    </div>
    <p class="hidden">{{ stateRecomputeCounter }}</p>
    <div v-for="(currEleList, indx) in localFormdata" :key="indx" class="sidebar components">
      <div class="col-11">
        <Collapsible :formData="Object.keys(currEleList)" :data="currEleList" :pageNumber="indx.toString()" />
      </div>
      <div class="fields">
        <button
          type="button"
          :class="{
            customButton: true,
            btnActive: showFieldsNav && indx === currentPage
          }"
        >
          <img src="../../../../../../assets/icons/add_field.svg" alt="icon not found" />
          Add Field
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import _ from 'lodash';
import Collapsible from './FormCollapseContainer.vue';
export default {
  name: 'FormComponent',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      localFormdata: [],
      elementList: new Object(),
      currentPage: 0,
      currentFormContainer: null,
      formContainerId: null
    };
  },
  props: {
    componentId: {
      required: false,
      type: Number
    }
  },
  computed: {
    stateRecomputeCounter() {
      return this.appStore.stateRecomputeCounter;
    }
  },
  mounted() {
    const component = this.appStore.getComponentListFromComponents.find((currEle) => {
      if (currEle.id === this.componentId && currEle.type === 'form') {
        return true;
      }

      return false;
    });

    this.formContainerId = component?.parentId;

    this.currentFormContainer = _.cloneDeep(this.appStore.getContainer(this.formContainerId));
    this.localFormdata = this.currentFormContainer.properties.formData;
    if (this.localFormdata.length === 0) {
      this.localFormdata.push(this.elementList);
    } else {
      this.elementList = this.localFormdata[0];
    }
  },
  beforeUpdate() {
    this.currentFormContainer = _.cloneDeep(this.appStore.getContainer(this.formContainerId));
    this.localFormdata = this.currentFormContainer.properties.formData;
    if (this.localFormdata.length === 0) {
      this.localFormdata.push(this.elementList);
    } else {
      this.elementList = this.localFormdata[0];
    }
  },
  components: {
    Collapsible
  }
};
</script>

<style scoped>
.hidden {
  visibility: hidden;
}
.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 25px 15px;
  height: max-content;
}

.sidebar.components.flow {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}

.nav {
  height: 40px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  border-radius: 15px;
  width: 70%;
  display: flex;
  left: 1;
  margin-top: 20px;
  animation: fadeInAnimation ease-in 0.3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.customButton {
  width: 121px;
  height: 30px;
  margin: 20px 273px 0 20px;
  padding: 3px 14.8px 3px 3px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  background-color: #fff;
}

.btnActive {
  background-color: #1996f0;
}

.image {
  margin-left: 5px;
  margin-right: 5px;
}

.image:hover {
  filter: invert(48%) sepia(32%) saturate(3743%) hue-rotate(181deg) brightness(98%) contrast(92%);
  cursor: pointer;
}

.image-close {
  align-content: left;
  position: relative;
  margin-left: 40px;
  filter: invert(93%) sepia(25%) saturate(123%) hue-rotate(179deg) brightness(97%) contrast(92%);
}
.image-close:hover {
  filter: invert(53%) sepia(10%) saturate(3250%) hue-rotate(316deg) brightness(84%) contrast(110%);
  cursor: pointer;
}

.fields {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.form-type-title {
  position: relative;
  top: 4px;
}

.form-container {
  align-items: center;
}

.numTab {
  padding: 6px 10px 6px 10px;
  border: none;
  border-radius: 5px;
  background-color: #edf0f4;
  margin-right: 10%;
  font-size: 12px;
  margin-bottom: 5%;
}

.addNumTab {
  padding: 4px 11px 3px 11px;
  background-color: white;
  border-radius: 80%;
  color: #1996f0;
  font-size: 15px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.selected {
  background-color: #1996f0;
  color: white;
  border-color: #1996f0;
}

.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.scroll {
  overflow-y: scroll;
  max-height: 600px;
}
</style>
