import { Mark, markPasteRule, mergeAttributes } from '@tiptap/core';

export const Note = Mark.create({
  name: 'note',

  addOptions: {
    openOnClick: true
  },

  // The attributes for the mark
  attributes: {
    note: {
      default: null
    },
    uuid: {
      default: null
    },
    lastSavedBy: {
      default: null
    },
    photoURL: {
      default: null
    },
    timestamp: {
      default: null
    },
    id: {
      default: null
    },
    role: {
      default: null
    }
  },

  // The DOM parsing logic
  parseHTML() {
    return [
      {
        style: 'font-style',
        getAttrs: (value) => value === 'italic' && {}
      },
      {
        tag: 'highlightsecondary',
        getAttrs: (dom) => ({
          note: dom.getAttribute('note'),
          timestamp: dom.getAttribute('timestamp'),
          lastSavedBy: dom.getAttribute('lastSavedby'),
          photoURL: dom.getAttribute('photoURL'),
          uuid: dom.getAttribute('uuid'),
          id: dom.getAttribute('id'),
          role: dom.getAttribute('role')
        })
      }
    ];
  },

  // The DOM rendering logic
  renderHTML({ HTMLAttributes }) {
    return ['highlightsecondary', mergeAttributes(HTMLAttributes), 0];
  },

  // Command to add or remove the mark
  addCommands() {
    return {
      setNote:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark('note', attributes);
        },
      toggleNote:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark('note', attributes);
        },
      unsetNote:
        () =>
        ({ commands }) => {
          return commands.unsetMark('note');
        }
    };
  },

  // Paste rule if any
  addPasteRules() {
    return [
      markPasteRule({
        type: 'note'
        // Add your paste rule logic here
      })
    ];
  },

  // Plugin logic, e.g. for handling clicks
  addProseMirrorPlugins() {
    if (!this.options.openOnClick) {
      return [];
    }

    // Replace this with your own logic
    return [];
  }
});
