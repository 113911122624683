<template>
  <div class="agent-flows-bulk-actions-modal-container">
    <BulkStatusModal v-if="currentBulkAction === 'BulkStatusModal'" @closeModal="closeModal" @bulkUpdateStatus="bulkUpdateStatus" :selectedFlowCount="selectedFlowCount" />
    <BulkPublishModal v-if="currentBulkAction === 'BulkPublishModal'" @closeModal="closeModal" @bulkPublishFlows="bulkPublishFlows" :selectedFlowCount="selectedFlowCount" :agentName="agentName" :selectedFlows="selectedFlows" />
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapActions, mapState } from 'pinia';
import BulkStatusModal from './BulkStatusModal.vue';
import BulkPublishModal from './BulkPublishModal.vue';
import axios from 'axios';

export default {
  name: 'AgentFlowsBulkActionsModal',
  props: {
    agentName: String,
    currentBulkAction: String,
    selectedFlows: Array,
    selectedFlowCount: Number
  },
  components: {
    BulkStatusModal,
    BulkPublishModal
  },
  data() {
    return {
      appStore: useAppStore(),
      modalOptions: {
        adaptive: true,
        teleportTo: 'body',
        modelValue: false,
        displayDirective: 'if',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        clickToClose: true,
        escToClose: true,
        background: 'non-interactive',
        lockScroll: true,
        swipeToClose: 'none',
        transition: 'slide-fade',
        fitParent: true
      }
    };
  },
  methods: {
    ...mapActions(useAppStore, ['updateSnackbar', 'closeSnackbar']),
    closeModal() {
      this.$emit('close');
      this.modalOptions.modelValue = false;
    },
    bulkUpdateStatus(status) {
      const reqBody = {
        agent: this.agentName,
        agentId: this.appStore.currentTaxonomy,
        flows: this.selectedFlows,
        fieldsToUpdate: {
          status: status
        },
        uid: this.appStore.getUser.uid,
        idToken: this.appStore.getClaims.token
      };
      this.appStore.updateFlows(reqBody).then((res) => {
        this.currentFlows.map((flow) => {
          if (flow.selected) flow.status = status;
        });
        this.appStore.closeSnackbar();
        this.updateSnackbar({ snackbar: true, text: res.message });
      });
    },
    async bulkPublishFlows() {
      try {
        const availableLanguages = this.appStore.languageCodes && Object.keys(this.appStore.languageCodes).length > 0 ? Object.keys(this.appStore.languageCodes) : ['en'];
        const reqBody = {
          agent: this.agentName,
          flows: this.selectedFlows,
          idToken: this.appStore.getClaims.token,
          languageCodes: availableLanguages,
          user: {
            email: this.appStore.getUser.email,
            displayName: this.appStore.getUser.displayName,
            uid: this.appStore.getUser.uid
          }
        };
        const response = (await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/publishFlows`, reqBody)).data;
        this.currentFlows.map((flow) => {
          if (flow.selected) {
            flow.published = true;
            flow.updatedAt = Date.now();
          }
        });
        this.updateSnackbar({ snackbar: true, text: response });
        setTimeout(() => {
          this.appStore.closeSnackbar();
        }, 8000);
      } catch (error) {
        console.error(error);
        this.$message({
          showClose: true,
          message: 'Bulk publish failed.',
          type: 'error'
        });
      }
    },
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '800px';
      let width = '350px';
      let height = '400px';

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    }
  },
  computed: {
    ...mapState(useAppStore, ['currentFlows']),
    wrapperStyles() {
      return {
        'z-index': 5000
      };
    },
    modalStyles() {
      return {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxImageWidth}`,
        'max-height': `${this.setModalWidthHeight().maxImageHeight}`,
        'pivot-y': 0.3
      };
    },
    bulkActionType() {
      if (this.currentBulkAction !== '') {
        this.$emit('opened');
      }
      return this.currentBulkAction;
    }
  },
  watch: {
    currentBulkAction(newValue, oldValue) {
      console.log('currentBulkAction updated from', oldValue, 'to', newValue);
      this.modalOptions.modelValue = true;
      this.bulkActionType;
    },
    agentName(newValue, oldValue) {
      console.log('agentName updated from', oldValue, 'to', newValue);
    },
    selectedFlows(newValue, oldValue) {
      console.log('selectedFlows updated from', oldValue, 'to', newValue);
    },
    selectedFlowCount(newValue, oldValue) {
      console.log('selectedFlowCount updated from', oldValue, 'to', newValue);
    }
  }
};
</script>
