<template>
  <div>
    <li :key="row" v-for="row in colorPalette" class="colors row">
      <div
        @click="changeColor(color)"
        :style="{ backgroundColor: color }"
        :key="color"
        v-for="color in row"
        :class="{ selected: currentColor === color }"
        class="colorPalette"
      ></div>
    </li>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import colorPalette from '../../../../Utilities/colorPalette';
export default {
  props: {
    onSelect: {
      type: Function,
    },
    currentColor: {},
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
colorPalette: colorPalette,
    };
  },
  methods: {
    changeColor(color) {
      if (this.currentColor && this.currentColor === color) {
        ///Set default to white if same color selected;
        this.onSelect('#ffffff');
      } else {
        this.onSelect(color);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.colorPalette {
  border-radius: 50%;
  border: none;
  width: 18px;
  height: 18px;
  cursor: grab;
}

.colorPalette:hover {
  border: 1px solid #1996f0;
}

.colorPalette.selected {
  border: 1px solid #1996f0;
}

.colors.row {
  display: flex;
  justify-content: space-around;
  margin: 0px 0px 0px 0px !important;
  padding-bottom: 5px;
  padding-top: 2px;
}
</style>
