<template>
  <div class="scroll-area-wrapper" @dragstart="handleDragStart" @mouseleave="handleMouseLeave" @mousemove="handleMouseMove" @mouseup.left="handleMouseUp" @mousedown.left="handleMouseDown" @mouseover="handleMouseOver">
    <div v-if="invalidActionsCount > 0 && appStore.displayMode === 'builder'" class="validation-banner">
      <div class="validation-banner-message">
        <span v-html="validationBannerContent()"></span>
      </div>
      <div class="validation-banner-cta" @click="toggleValidationView">
        <button class="play-button">{{ validationBannerCtaContent }}</button>
      </div>
    </div>
    <div v-if="appStore.scene?.nodesOverlap === true && appStore.displayMode === 'builder'" class="validation-banner">
      <div class="validation-banner-message">
        <span><strong>Warning:</strong> There are overlapping containers, please fix the overlap to publish draft</span>
      </div>
    </div>
    <div id="capture" class="viewer">
      <div ref="chpWrapper" class="viewer2" id="zoomID">
        <draggable v-model="mainCanvasDropList" group="components" item-key="id" id="dropZone" class="dropZone" ghost-class="trash-class" filter="input" :preventOnFilter="false" @change="log" draggable="false">
          <template #item="{ element, index }">
            <div class="canvasContainer">
              <container v-bind="node" v-for="node in filteredNodes" :key="node.id" :collection="collection" :spaceDown="isPanning" :isLinking="action.linking" :showInvalidActions="showInvalidActions" :parent="element" :component-index="index" @force-recompute="forceRecompute" @preventSidebarOpening="preventSidebarOpening" @linking-start="linkingStart" @linking-stop="linkingStop(node)" @node-selected="nodeSelected" @handle-down="handleMouseDown" />

              <svg :key="forceRecomputeCounter" shape-rendering="geometricPrecision" class="edges" id="edgeParent">
                <edges v-bind="edge" v-for="(edge, edgeIndex) in edges" :key="`edge${edgeIndex}`" @closeEdgeOptions="closeEdgeOptions" @openEdgeOptions="openEdgeOptions"></edges>
              </svg>
              <EdgeOptionsContainer v-if="isEdgeOptionsVisible" :id="selectedLineId" :position="selectedLinePosition" @closeEdgeOptions="closeEdgeOptions" />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useVersionsStore, useVcmStore, useCollabStore } from '@/store/index.js';
/* eslint-disable */
//
import { nextTick } from 'vue';
import Gesto from 'gesto';
import { setTimeout } from 'timers';
import { mapActions, mapState } from 'pinia';
import draggable from 'vuedraggable';
import InfiniteViewer from 'infinite-viewer';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';

import { droppedOnBackground, selectedContainerHandle } from '@/components/Utilities/dragging';
import KEYBOARD_KEYS_ENUM from '@/components/Utilities/constants/keyboardKeys';
import { transformCarouselComponent } from '@/helpers/transformerHelpers';
import { getConnectorPosition, getMousePosition, getEdgePositions, getOptionConnectorSource } from '../Utilities/position';
import EditSidebar from './Canvas/3.0/SidebarComponents/EditSidebar.vue';
import ContainerComponent from './Canvas/3.0/ContainerComponents/ContainerComponent.vue';
import EdgesRenderer from './Canvas/3.0/Edges/EdgesRenderer';
import EdgeOptionsContainer from './Canvas/3.0/Edges/EdgeOptionsContainer';
import { Position } from '../Utilities/constants/canvas';

import { v4 as uuidv4 } from 'uuid';

const waitForElm = async function (id) {
  return new Promise((resolve) => {
    if (document.getElementById(`${id}`)) {
      return resolve(document.getElementById(`${id}`));
    }
    const observer = new MutationObserver(() => {
      if (document.getElementById(`${id}`)) {
        resolve(document.getElementById(`${id}`));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};

export default {
  name: 'MainCanvas',
  components: {
    EditSidebar,
    container: ContainerComponent,
    edges: EdgesRenderer,
    draggable,
    EdgeOptionsContainer
  },
  props: {
    mode: {
      type: String
    },
    data: {
      type: Object,
      required: false
    },
    collection: {
      required: false
    },
    nodes: {
      required: false,
      type: Object
    },
    actions: {
      required: false,
      type: Object
    },
    slots: {
      required: false,
      type: Array
    },
    slotInfo: {
      required: false,
      type: Array
    },
    height: {
      type: Number,
      default: 400
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      versionsStore: useVersionsStore(),
      vcmStore: useVcmStore(),
      collabStore: useCollabStore(),
      forceRecomputeCounter: 0,
      mainCanvasDropList: [
        {
          id: 'default'
        }
      ],
      viewer: [],
      language: useAppStore().language ? useAppStore().language : 'en',
      canvasSelected: false,
      svg: null,
      g: null,
      zoom: 1,
      debouncedBrowserZoomLevel: 1,
      browserZoomLevelTimeout: null,
      action: {
        linking: false,
        dragging: false,
        scrolling: false,
        selected: 0,
        panning: {
          mouse: false,
          space: false
        }
      },
      mouse: {
        x: 0,
        y: 0,
        lastX: 0,
        lastY: 0
      },
      draggingLink: null,
      rootDivOffset: {
        top: 0,
        left: 0
      },
      isDragged: false,
      isPanning: false,
      gesto: null,
      loadCanvasTimeout: null,
      isEdgeOptionsVisible: false,
      selectedLineId: null,
      selectedLinePosition: null,
      validationBannerCtaContent: 'Resolve items',
      showInvalidActions: false
    };
  },
  mixins: [],
  computed: {
    ...mapState(useVersionsStore, ['getRestoredPopupDate']),
    ...mapState(useAppStore, ['activeContainer', 'getContainer', 'componentId', 'getLogos', 'stateRecomputeCounter', 'getListofLanguages', 'getClientEditAccess', 'getAlteredNodes', 'usersConnected', 'containerComponents', 'getComponentList', 'getScene', 'getSidebarFlag', 'selectedContainers', 'canvasDisabled', 'zoomLevel', 'isEditingContainerId', 'currentTaxonomy', 'getComponentListFromComponents', 'components', 'getParentContainerIdByComponentId']),
    isAdmin() {
      return !!this.appStore.getClaims.admin;
    },
    filteredNodes() {
      const filteredNodes = this.getNodes.filter((node) => this.showContainer(node));
      return filteredNodes;
    },
    getLinks() {
      return this.getScene.links;
    },
    getNodes() {
      return this.getScene.nodes;
    },
    nodeOptions() {
      return {
        centerY: this.getScene.centerY,
        centerX: this.getScene.centerX,
        scale: this.getScene.scale,
        offsetTop: this.rootDivOffset.top,
        offsetLeft: this.rootDivOffset.left,
        selected: this.action.selected
      };
    },
    edges() {
      //Need to retrigger edges computed property once the DOM is updated via autoArrange button in BottomNav bar. If not, the edges will not be connected properly (edges runs after state is updated but before DOM is updated).
      //edges() automatically get's retrigged when mouse enters the main canvas area (via this.forceRecomputeCounter), but it's possible to trigger auto arrange and not enter the canvas, which will the leave edges not connected.
      this.appStore.rerenderEdges;
      this.forceRecomputeCounter;
      this.stateRecomputeCounter;
      try {
        const links = this.getLinks.map((link) => {
          let { text, color, id, connectorPosition, optionIndex, curved, dotted, isFromOption } = link;
          let sourceConnectorPosition = connectorPosition || Position.Bottom;
          let targetConnectorPosition = Position.Top;
          if (isFromOption) {
            sourceConnectorPosition = this.getOptionConnectorSourcePosition(link);
          }

          let connectorPositions = {
            source: sourceConnectorPosition,
            target: targetConnectorPosition
          };

          const optionIndexVal = Number.isInteger(optionIndex) ? `-${optionIndex}` : '';
          const sourceConnectorId = `${link.from}__flow__source-connector-${connectorPositions.source}${optionIndexVal}`;

          const sourceConnectorElement = document.getElementById(sourceConnectorId);
          if (!sourceConnectorElement) {
            return;
          }

          const targetConnectorId = `${link.to}__flow__target-connector-${connectorPositions.target}`;
          const targetConnectorElement = document.getElementById(targetConnectorId);

          const edgePositions = getEdgePositions(sourceConnectorElement, sourceConnectorPosition, targetConnectorElement, targetConnectorPosition);

          const edges = {
            id,
            source: link.from,
            target: link.to,
            start: [edgePositions.sourceX, edgePositions.sourceY],
            end: [edgePositions.targetX, edgePositions.targetY],
            positions: [sourceConnectorPosition, targetConnectorPosition],
            color,
            curved,
            dotted,
            text,
            status: 'connected',
            optionIndex,
            isFromOption
          };
          return edges;
        });

        //When a link is being dragged (either from a container, or from an option in a decision component)
        if (this.draggingLink) {
          let sourceConnectingId = `${this.draggingLink.from}__flow__handle`;
          let sourceConnectingElement = null;
          let sourceConnectingPosition = null;
          const mousePosition = this.mouse;

          if (this.draggingLink?.isFromOption) {
            //Deals with links from an decision component
            const optionIndex = this.draggingLink.optionIndex;
            ({ sourceConnectingPosition, sourceConnectingElement } = getOptionConnectorSource(sourceConnectingId, optionIndex, mousePosition));
          } else {
            //Deals with links from a container
            sourceConnectingElement = document.getElementById(sourceConnectingId);
            sourceConnectingPosition = Position.Bottom;
          }
          const { x: SOURCE_X_DRAGGING, y: SOURCE_Y_DRAGGING } = getConnectorPosition(sourceConnectingPosition, sourceConnectingElement);
          links.push({
            id: this.draggingLink.from,
            source: this.draggingLink.from,
            target: this.draggingLink.to,
            start: [SOURCE_X_DRAGGING, SOURCE_Y_DRAGGING],
            end: [mousePosition?.x, mousePosition?.y],
            color: this.appStore.currentColor,
            curved: true,
            dotted: false,
            positions: [Position.left, this.draggingLink?.connectorPosition || Position.right],
            text: '',
            status: 'connecting'
          });
        }
        return links;
      } catch (err) {
        console.error('Error: Unable to compute canvas edges', err.message, err.stack);
      }
    },
    browserZoomLevel: {
      get() {
        return this.debouncedBrowserZoomLevel;
      },
      set(val) {
        if (this.browserZoomLevelTimeout) clearTimeout(this.browserZoomLevelTimeout);
        this.browserZoomLevelTimeout = setTimeout(() => {
          this.debouncedBrowserZoomLevel = val;
        }, 50);
      }
    },
    invalidActionsCount() {
      return this.appStore.getInvalidActionsCount;
    }
  },

  beforeMount() {
    this.vcmStore.$vcm.clear();
    this.vcmStore.setPaused(true);
    const stateToWatch = ['scene', 'components', 'containers', 'taxonomies', 'containerList', 'componentList', 'comments', 'current.slot', 'current.taxonomy'];
    this.appStore.initializeCanvasState(stateToWatch);
    this.transformDataToRemoveLanguageFlags();
    this.transformHasLinkToLinkedDecisionContainerInfo();
  },
  async mounted() {
    this.setCurrentVersion();
    this.appStore.SET_SLOTS({ collection: this.collection });
    this.viewer = new InfiniteViewer(document.querySelector('.viewer'), document.querySelector('.viewer2'), {
      // usePinch: true,
      // pinchThreshold: 50,
      useWheelScroll: true,
      displayVerticalScroll: false,
      displayHorizontalScroll: false
    }).on('dragStart', (e) => {
      const target = e.inputEvent.target;
      if (target.nodeName === 'A') {
        e.stop();
      }
    });
    requestAnimationFrame(() => {
      if (typeof this.viewer?.scrollCenter === 'function') {
        this.viewer.scrollCenter();
        this.viewer.zoom = 1;
      }
    });
    window.addEventListener('keydown', (e) => {
      this.handleCanvasKeydown(e);
    });
    window.addEventListener('keyup', (e) => {
      this.handleCanvasKeyup(e);
    });
    const scrollAreaWrapper = document.getElementsByClassName('scroll-area-wrapper')[0];
    scrollAreaWrapper.addEventListener('drop', this.handleDrop, false);
    // Force recomputing of the edges of the conversation;
    this.forceRecompute();
    this.viewer.zoom = 1;

    this.appStore.setZoomLevel({ zoomLevel: 1 });

    setTimeout(async () => {
      const element = await waitForElm('start-id');
      const { x, y } = getConnectorPosition(Position.Bottom, element);
      if (typeof this.viewer?.scrollTo === 'function') {
        this.viewer.scrollTo(x + 380 - window.innerWidth / 2, y - 100);
      }
      this.appStore.removeEmptyUnconnectedContainers({});
      this.vcmStore.$vcm.clear();
      this.collabStore.startCollabService();
      this.vcmStore.setPaused(false);
    }, 500);
  },
  beforeUnmount() {
    this.collabStore.stopCollabService();
    this.vcmStore.forceSave({ uxStateOnly: false });
    this.vcmStore.setPaused(true);
    this.vcmStore.$vcm.clear();
    try {
      window.removeEventListener('keydown', (e) => {
        this.handleCanvasKeydown(e);
      });
      window.removeEventListener('keyup', (e) => {
        this.handleCanvasKeyup(e);
      });
      this.mainCanvasDropList = [];
      this.viewer = [];
    } catch (err) {
      console.error('Error: ', err.message, err);
    }
  },
  beforeUpdate() {
    // this.createDraftMethod();
  },
  methods: {
    ...mapActions(useAppStore, ['addNodeSync', 'updateScene', 'setLanguage']),
    ...mapActions(useVcmStore, ['forceSave']),

    log: function (evt) {
      console.log(`Logging mainCanvas drag container`, {
        event: evt,
        mainCanvasDropList: this.mainCanvasDropList
      });
    },
    updateNode(index, updatedNode) {
      this.getNodes[index] = updatedNode;
      // Or, if getNodes() is a computed property:
      // this.nodes[index] = updatedNode;
    },
    updateEdge(index, updatedEdge) {
      this.edges[index] = updatedEdge;
    },

    //Transforms the data structure to remove language flags for all components when the canvas is first loaded. Follow up loads of the canvas should not modify the data structure.
    transformDataToRemoveLanguageFlags() {
      const components = this.appStore.getComponentListFromComponents || [];
      const containers = Object.values(this.appStore.getContainerListFromContainers);

      components.forEach((component, index) => {
        //array is an object in typeof, so we need to use isArray for references.
        if (component.type === 'message' && (typeof component.text === 'object' || !Array.isArray(component.reference))) {
          //Set the text field to a string instead of an object and the reference field to an array instead of an object
          this.appStore.updateComponent({
            id: component.id,
            text: component.text.en,
            reference: component.reference.en
          });
        }
        if (component.type === 'decision' && typeof component.options[0].text === 'object') {
          component.options.forEach((option, index) => {
            this.appStore.updateOption({
              text: option.text.en,
              decisionComponentId: component.id,
              optionIndex: index
            });
          });
        }
        if (component.type === 'carousel') {
          const carouselComponent = transformCarouselComponent(component);
          this.appStore.updateComponent(carouselComponent);
          if (component.queryParameters && component.queryParameters.length > 0 && typeof component.queryParameters[0].name === 'object') {
            component.queryParameters.forEach((param, index) => {
              this.appStore.updateQueryParameter({
                linkUrlComponentId: component.id,
                name: param.name.en,
                value: param.value.en,
                paramIndex: index
              });
            });
          }
        }
        if (component.type === 'flow' && typeof component.text === 'object') {
          this.appStore.updateComponent({
            id: component.id,
            text: component.text.en
          });
        }
      });
      containers.forEach((container, index) => {
        if (typeof container.title === 'object') {
          this.appStore.editContainer({
            id: container.id,
            field: 'title',
            value: container.title.en
          });
        }
        //TODO: text, verbatim and reference don't seem to be used at all for containers, but transforming them just in case. Remove these 3 keys from uxState for containers? They are not present in the flow collection, only the uxState collection.
        if (typeof container.text === 'object') {
          this.appStore.editContainer({
            id: container.id,
            field: 'text',
            value: container.text.en
          });
        }
        if (typeof container.verbatim === 'object') {
          this.appStore.editContainer({
            id: container.id,
            field: 'verbatim',
            value: container.verbatim.en
          });
        }
        if (typeof container.reference === 'object') {
          this.appStore.editContainer({
            id: container.id,
            field: 'reference',
            value: container.reference.en
          });
        }
      });
    },
    transformHasLinkToLinkedDecisionContainerInfo() {
      //In an old flows uxState.scene.nodes, there is an object called hasLink that has information about the link that connects to this node.
      //In new flows, uxState.scene.nodes, there is an object called linkedDecisionContainerInfo that has information about the node and decision component that connects to this node.

      //This function converts the old hasLink object to the newer linkedDecisionContainerInfo object.

      const nodes = this.getNodes;
      const links = this.getLinks;
      nodes.forEach((node) => {
        if (node.hasLink) {
          const containerId = node.id;

          //Use links optionIndex as source of truth for optionContainerIndex
          links.forEach((link) => {
            if (link.to === containerId && Number.isInteger(link?.optionIndex)) {
              const optionContainerIndex = link.optionIndex;
              const decisionComponentId = link.from;
              const decisionComponentParentId = this.getParentContainerIdByComponentId(decisionComponentId);
              node.linkedDecisionContainerInfo = {
                decisionComponentId,
                decisionComponentParentId,
                optionContainerIndex
              };
            }
          });
          delete node.hasLink;
        }
      });
    },
    setCurrentVersion() {
      const taxonomy = this.appStore.currentTaxonomy;
      const slotId = this.appStore.currentSlot.id;
      const language = this.language;

      this.versionsStore.getLatestVersion({ taxonomy, slotId, draftOrPublished: 'draft', language }); // find latest published draft for managing published badge
    },
    getOptionConnectorSourcePosition(link) {
      let containers = {};

      //Nodes from getNodes are the containers
      this.getNodes.forEach((container) => {
        if (container.id === this.appStore.components[link.from]?.parentId) containers['from'] = container;
        if (container.id === link.to) containers['to'] = container;
      });

      const linksSourceXPosition = containers?.from?.x;
      const linksDestinationXPosition = containers?.to?.x;
      const positionOfConnectorOnSource = linksSourceXPosition < linksDestinationXPosition ? Position.Right : Position.Left;

      return positionOfConnectorOnSource;
    },
    containerContainsDecision(containerId) {
      const containerComponents = this.containerComponents(containerId);
      return containerComponents.some((component) => component.type === 'decision');
    },
    handleDragStart(e) {
      if (e.target.getAttribute('class').search('component-overlay') === -1) {
        e.preventDefault();
      }
    },
    handleCanvasKeydown(event) {
      const { code, target } = event;

      if (code === KEYBOARD_KEYS_ENUM.SPACE) {
        this.isPanning = true;
      }

      if ((code === KEYBOARD_KEYS_ENUM.BACKSPACE || code === KEYBOARD_KEYS_ENUM.DELETE) && this.activeContainer === 'start-id') {
        return;
      }

      const isContainerOrComponent = target?.classList?.contains('ProseMirror') || target?.classList?.contains('component-overlay') || target?.classList?.contains('commentComponent') || target?.classList?.contains('container-node');

      // IF SPACE AND CLICK ON A CONTAINER/COMPONENT/LINK - prevent browser scroll down but change to pointer and allow panning;
      if (code === KEYBOARD_KEYS_ENUM.SPACE && (isContainerOrComponent || target?.tagName === 'path') && !this.canvasDisabled) {
        // to allow the use of whitespace in the message component check if the component is active
        if (!this.componentId || this.componentId === '') {
          if (!this.activeContainer === 'start-id') {
            // if there is no selected component, then use panning
            event.preventDefault();
            event.stopPropagation();
            this.startPanning();
          }
        }
      }
      // IF SPACE AND CLICKED ON BACKGROUND - prevent browser scroll down and allow panning
      if (code === KEYBOARD_KEYS_ENUM.SPACE && target?.tagName === 'BODY' && !this.canvasDisabled) {
        event.preventDefault();
        event.stopPropagation();
        this.startPanning();
      }
      if ((code === KEYBOARD_KEYS_ENUM.BACKSPACE || code === KEYBOARD_KEYS_ENUM.DELETE) && !this.canvasDisabled && !this.isEditingContainerId) {
        if (!this.componentId && this.selectedContainers.length) {
          this.selectedContainers.forEach((containerId) => {
            if (containerId !== 'start-id') {
              this.appStore.removeContainerAction({ id: containerId });
            }
          });
        }

        // Deleting will set active container to null and selected containers to empty array;
        this.appStore.setActiveContainer(null);
        this.appStore.SET_SELECTED_CONTAINERS([]);
        this.forceRecompute();

        event.stopPropagation();
      }
    },
    handleCanvasKeyup(e) {
      if (!this.canvasDisabled) {
        this.stopPanning();
      }
      if (e.code === KEYBOARD_KEYS_ENUM.SPACE) {
        this.isPanning = false;
        try {
          document.getElementsByClassName('container-overlay').map((currEle) => {
            currEle.style.cursor = 'move';
          });
        } catch (e) {
          // ignore
        }
      }
    },
    handleMouseOver(e) {
      this.action.panning.mouse = false;
      const target = e.target || e.srcElement;

      if (this.$el.contains(target) && !this.action.panning.space) {
        this.action.scrolling = false;
        this.isDragged = false;
      }
      // this.debouncedForceRecompute();
    },
    // This function will deselect containers and components and cause right sidebar to be hidden;
    executeCanvasClick(e) {
      try {
        this.$emit('canvasClick', e);
        this.canvasSelected = true;
        this.appStore.setActiveContainer(null);
        this.appStore.SET_SELECTED_CONTAINERS([]);
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    forceRecompute() {
      nextTick(() => {
        this.forceRecomputeCounter++;
      });
    },
    showContainer(node) {
      if (node.type === 'Hidden') return false;
      else return true;
    },
    toPrecision(num, precision) {
      if (precision === undefined) precision = this.numPrecision;
      return parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
    },
    convertTimestamp(timestamp) {
      let a = moment(timestamp);
      let b = moment();
      if (b.diff(a, 'hours') === 0) {
        let mins = Math.round(b.diff(a, 'hours', true) * 60, 3);
        return mins + ' minutes ago';
      } else {
        return `${b.diff(a, 'hours')} hours ago`;
      }
    },
    autoClose() {},
    closePage() {
      // ignored
    },
    async handleDrop() {
      // ignored
    },
    handleDragOver(evt) {
      evt.dataTransfer.dropEffect = 'copy';
      evt.stopPropagation();
      evt.preventDefault();
    },
    addLink(newLink) {
      this.getScene.links.push(newLink);
    },
    linkingStart(payload) {
      try {
        // Calculate mouse position of line
        this.action.linking = true;
        [this.mouse.x, this.mouse.y] = getMousePosition(payload.event);
        let draggingLink = {
          text: payload.containerTitle,
          from: payload.componentId,
          mx: this.mouse.x,
          my: this.mouse.y + 100,
          connectorPosition: payload.connectorPosition,
          event: payload.event
        };
        if (payload.isFromOption) {
          draggingLink.isFromOption = true;
          draggingLink.optionIndex = payload.optionIndex;
        }
        this.draggingLink = draggingLink;
      } catch (err) {
        console.error(err, 'issue with component start');
      }
    },
    preventSidebarOpening() {
      this.$emit('preventSidebarOpening');
    },
    linkingStop(node) {
      try {
        // Add new Link
        if (this.draggingLink && this.draggingLink.from !== node.id) {
          this.appStore.convertToOptionContainer({
            decisionComponentId: this.draggingLink.from,
            decisionContainerId: node.id,
            optionIndex: this.draggingLink.optionIndex
          });
          // Check link existence
          const existed = this.getLinks.find((link) => {
            return (link.from === this.draggingLink.from && link.to === node.id) || (link.to === this.draggingLink.from && link.from === node.id && !this.containerContainsDecision(node.id));
          });
          if (!existed) {
            // let maxID = Math.max(
            //   0,
            //   ...this.getLinks().map((link) => {
            //     return link.id;
            //   })
            // );
            if (this.draggingLink.from !== node.id) {
              const newLink = {
                text: this.draggingLink.connectorPosition,
                id: uuidv4(),
                from: this.draggingLink.from,
                to: node.id,
                connectorPosition: this.draggingLink.connectorPosition,
                color: '#8e9ba7',
                status: 'connecting'
              };
              if (this.draggingLink.isFromOption) {
                newLink.isFromOption = true;
                newLink.optionIndex = this.draggingLink.optionIndex;
              }
              this.addLink(newLink);
              this.$emit('linkAdded', newLink);
            }
          }
        }
        this.draggingLink = null;
        this.forceStateRecompute();
      } catch (err) {
        console.error(err, 'linking stop issue');
      }
    },
    async nodeSelected(payload) {
      // console.log('nodeSelected payload', payload);
      const id = payload.id;
      let e = payload;
      this.canvasSelected = false;
      this.dragging = false;
      let drag;

      if (typeof e === 'object') {
        let args = e;

        if ('drag' in args) {
          drag = args.drag;
          drag ? (this.dragging = true) : (this.dragging = false);
        }

        e = e['event'];
      }
      // const target = e.target || e.srcElement;
      if (this.dragging) {
        id !== 2 ? (this.action.dragging = id) : (this.action.dragging = null);
      }

      this.action.selected = id;
      this.$emit('nodeClick', id);
      if (drag) {
        if (e.ctrlKey || e.shiftKey) {
          e.preventDefault();

          if (this.selectedContainers.includes(id)) {
            this.appStore.setActiveContainer(null);
            this.appStore.REMOVE_SELECTED_CONTAINER(id);
          } else {
            this.appStore.setActiveContainer(id);
            this.appStore.ADD_SELECTED_CONTAINER(id);
          }
        } else {
          if (!this.selectedContainers.includes(id)) {
            this.appStore.setActiveContainer(id);
            this.appStore.SET_SELECTED_CONTAINERS([id]);
          }
        }
      } else {
        if (!e.ctrlKey && !e.shiftKey && !this.isDragged) {
          this.appStore.setActiveContainer(id);
          this.appStore.SET_SELECTED_CONTAINERS([id]);
        }
      }

      this.mouse.lastX = e.pageX || e.clientX + document.documentElement.scrollLeft;
      this.mouse.lastY = e.pageY || e.clientY + document.documentElement.scrollTop;
    },
    handleMouseEnter() {
      // TODO: Need to refactor this. This is causing issue when dragging a container component.
      if (!this.gesto) {
        this.gesto = new Gesto(document.querySelector('.viewer'), {
          container: window,
          events: ['mouse']
        }).on('drag', (e) => {
          if (this.viewer) {
            let zoom = this.viewer?.zoom || 1;
            if (this.action.panning.space) {
              this.viewer.scrollBy(-e.deltaX / zoom, -e.deltaY / zoom);
            }
          }
        });
      }
    },
    handleMouseLeave() {
      if (!this.canvasDisabled) {
        this.stopPanning();
      }
    },
    handleMouseMove: throttle(function (e) {
      let zoom = this.viewer?.zoom || 1;
      if (this.action.linking) {
        [this.mouse.x, this.mouse.y] = getMousePosition(e);
        if (this.draggingLink) {
          [this.draggingLink.mx, this.draggingLink.my] = [this.mouse.x, this.mouse.y];
        }
      }
      if (this.action.dragging) {
        this.mouse.x = e.pageX || e.clientX + document.documentElement.scrollLeft;
        this.mouse.y = e.pageY || e.clientY + document.documentElement.scrollTop;
        let diffX = this.mouse.x - this.mouse.lastX;
        let diffY = this.mouse.y - this.mouse.lastY;
        this.mouse.lastX = this.mouse.x;
        this.mouse.lastY = this.mouse.y;
        this.moveSelectedNode(diffX / zoom, diffY / zoom);
      }

      // TODO: This got moved to handleMouseEnter(). Check if this causes any regressions if removed
      if (this.action.scrolling && this.action.panning.space === true) {
        [this.mouse.x, this.mouse.y] = getMousePosition(e);
        let diffX = this.mouse.lastX - this.mouse.x;
        let diffY = this.mouse.lastY - this.mouse.y;
        if (typeof this.viewer?.scrollBy === 'function') {
          this.viewer.scrollBy(diffX / zoom, diffY / zoom);
        }
      }
    }, 10),
    forceStateRecompute() {
      nextTick(() => {
        this.appStore.FORCE_STATE_RECOMPUTE();
      });
    },
    debouncedForceRecomputeBackup: debounce(function () {
      this.appStore.FORCE_STATE_RECOMPUTE({ createBackup: true });
    }, 3),
    debouncedForceRecompute: debounce(function () {
      this.forceRecompute();
    }, 333),
    handleMouseUp(e) {
      this.action.panning.mouse = false;
      const target = e.target || e.srcElement;

      if (this.$el.contains(target)) {
        if (typeof target?.className !== 'string' || target?.className.indexOf('node-input') < 0) {
          this.draggingLink = null;
        }
        this.action.linking = false;
        this.action.dragging = null;
        this.action.scrolling = false;
        this.isDragged = false;
      }
      // this.debouncedForceRecompute();
    },
    moveToCenter() {
      this.loadCanvasTimeout = setTimeout(async () => {
        const element = await waitForElm('start-id');
        const { x, y } = getConnectorPosition(Position.Bottom, element);
        if (typeof this.viewer?.scrollTo === 'function') {
          this.viewer.scrollTo(x + 380 - window.innerWidth / 2, y - 100);
        }
      }, 500);
    },
    handleMouseDown(e) {
      this.canvasSelected = false;
      this.action.panning.mouse = true;
      const target = e.target || e.srcElement;
      try {
        if (this.$el.contains(target) && this.action.panning.space) {
          this.action.scrolling = true;
          [this.mouse.lastX, this.mouse.lastY] = getMousePosition(e);
          this.action.selected = null;
        }
        const isDroppedOnBackground = droppedOnBackground(e);
        if (((target === this.$el || target.matches('svg, svg *')) && e.which === 1) || target?.className === 'containers' || isDroppedOnBackground) {
          this.action.scrolling = true;
          [this.mouse.lastX, this.mouse.lastY] = getMousePosition(e);
          this.action.selected = null;
          if (this.action.panning.space) {
            this.gesto?.triggerDragStart(e);
          }
        }
        // on Background click, hide sidebar;
        if (isDroppedOnBackground) {
          this.executeCanvasClick(e);
        }
        // If use has NOT clicked on a container handle
        if (!selectedContainerHandle(e)) {
          this.action.dragging = null;
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    moveSelectedNode(dx, dy) {
      try {
        if (dx && dy) {
          this.isDragged = true;
        }

        this.canvasSelected = false;

        this.selectedContainers.forEach((containerId) => {
          const nodeIndex = this.getNodes.findIndex((node) => node.id === containerId);
          const node = this.getNodes[nodeIndex];

          const left = node.x + dx;
          const top = node.y + dy;

          this.getScene.nodes[nodeIndex] = Object.assign(node, {
            x: left,
            y: top
          });
        });

        this.debouncedForceRecomputeBackup();
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    startPanning() {
      this.action.panning.space = true;
      document.querySelector('.infinite-viewer-wrapper').classList.add('showPointer');
    },
    stopPanning() {
      this.action.panning.space = false;
      document.getElementsByClassName('infinite-viewer-wrapper').forEach((e) => e.classList.remove('showPointer'));
      this.gesto?.onDragEnd();
    },
    openEdgeOptions(payload) {
      const { lineId, position } = payload;
      this.selectedLineId = lineId;
      this.selectedLinePosition = position;
      this.isEdgeOptionsVisible = true;
    },
    closeEdgeOptions() {
      this.isEdgeOptionsVisible = false;
    },
    validationBannerContent() {
      const count = this.invalidActionsCount;
      const plural = count > 1;
      return `<strong>${count}${plural ? ' Invalid actions.' : ' Invalid action.'}</strong> Resolve ${plural ? 'items' : 'item'} to publish draft`;
    },
    toggleValidationView() {
      this.validationBannerCtaContent = this.validationBannerCtaContent === 'Resolve items' ? 'Hide items' : 'Resolve items';
      this.showInvalidActions = !this.showInvalidActions;
    }
  },
  watch: {
    //This is to fix the edges not rerendering after a restore
    // JOHN - Not advised to use watches on Pinia variables. Okay for now
    getRestoredPopupDate(newValue) {
      //Recompute every time the restored popup date is set to a date which means a restoring of a draft has happened
      if (newValue) {
        this.forceRecompute();
      }
    },
    canvasDisabled(newValue) {
      if (newValue) {
        this.appStore.setActiveContainer(null);
        this.appStore.SET_SELECTED_CONTAINERS([]);
        this.startPanning();
      } else {
        this.stopPanning();
      }
    },
    zoomLevel(newValue) {
      if (this.viewer) {
        this.viewer.zoom = newValue / 100;
      }
    },
    mainCanvasDropList: {
      deep: true,
      handler: function (newValue) {
        if (newValue.length > 1) {
          this.mainCanvasDropList = [
            {
              id: 'default'
            }
          ];
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './MainCanvas.scss';

div,
span,
img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
</style>

<style lang="less" scoped>
@import './MainCanvas.less';
</style>

<style lang="scss" scoped>
svg:not(:root) {
  overflow: visible;
}

.scroll-area-wrapper {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #333;
  background: #fdfdfd;
  overscroll-behavior: none;
  transform-style: preserve-3d;
}

.viewer {
  position: relative;
  width: 100%;
  height: 100%;
  background: #edf0f4 !important;
}

.viewer2 {
  position: relative;
  width: 100%;
  height: 100%;
  background: #edf0f4 !important;
}

.canvasContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

i.fas.fa-save.disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.locked {
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
  font-size: 3vw;
}

.zoomPercent {
  background-color: #fff;
  position: absolute;
  z-index: 5;
  bottom: 10px;
  left: 10px;
  padding: 13px 19px 12px;
  color: #383f45;
  border-radius: 4px;
}

.con-vs-popup,
.vs-popup {
  z-index: 9999234;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(-200px);
  z-index: 999999;
}

.ui-toolbar__title {
  font-size: 25px;
}

.ui-toolbar {
  z-index: 78342432;
  position: fixed;
  width: 100%;
}

.ui-toolbar--type-colored {
  background-color: black;
}

.toolbarIcon {
  padding-left: 40px;
  font-size: 30px;
}

.flowchart-container {
  margin: 0;
  position: relative;
  overflow: scroll;
  width: 100%;
  flex: 1 1 auto;
}

.flowchart-container svg {
  cursor: grab;
}

.userImage {
  border-radius: 50px;
  width: 32px;
  height: 32px;
  border: 2px solid rgb(11, 189, 135);
}

.userName {
  color: white;
}

.userEmail {
  font-size: 10px;
  color: white;
}

.pdfButton,
.uploadAll {
  color: white;
  font-size: 16px;
  background-color: rgb(0, 123, 258);
  padding: 10px;
  border-radius: 25px;
  font-weight: 700;
}
.validation-banner {
  width: 100%;
  height: 60px;
  background-color: #f8e697;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: auto;
  padding: 10px 0px 0px 0px;
  &-message {
    margin: 10px;
  }
  &-cta {
    width: 108.9px;
    height: 28px;
    margin: 10px;
    padding: 5px 10px 5px 10px;
    border-radius: 4px;
    background-color: #fff;
    &:hover {
      box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
      cursor: pointer;
    }
  }
}
</style>
