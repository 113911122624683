<template>
  <transition name="drwr-fade">
    <div :style="wrapperStyle" class="drwr-backdrop" @click.self="close">
      <div class="drwr" :id="`drwr-${this.id}`" role="dialog" aria-labelledby="drwrTitle" aria-describedby="drwrDescription">
        <section class="drawer-header" id="drwrHeader" style="margin-bottom: 80px">
          <slot name="header"> </slot>
          <svg id="close-button" @click="close" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
            <path id="Line_285" d="M0 0L40 40" class="close-color" />
            <path id="Line_286" d="M40 0L0 40" class="close-color" />
          </svg>
        </section>
        <section class="drawer-body" id="drwrBody">
          <slot name="body"> </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';

export default {
  name: 'DrawerMain',
  data() {
    return {
      appStore: useAppStore()
    };
  },
  props: {
    id: {
      type: String
    },
    wrapperStyle: {
      type: Object,
      required: false
    }
  },
  methods: {
    close() {
      this.appStore.setSearchActive({ status: false });
      this.appStore.closeDrawer();
    }
  },
  computed: {
    ...mapState(useAppStore, ['searchActive', 'inputDisabled'])
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
#close-button {
  height: 27px;
  width: 27px;
  position: absolute;
  right: 43px;
  top: 25px;
  cursor: pointer;
  background-color: #f6f8fa;
  padding: 5px;
  border-radius: 50%;
  z-index: 1000;
}
.close-color {
  stroke: #383f45;
  stroke-width: 5px;
}

.drwr-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}
#drwr-bulkPdfOptions {
  width: 500px !important;
}
.drwr {
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.5);
  background: #fff;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 945px;
  height: calc(100% - 60px);
  top: 60px;
  right: 0;
  position: absolute;
}

.drawer-body {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.drwr-fade-enter-active,
.drwr-fade-leave-active {
  transition: transform 0.5s;
}

.drwr-fade-enter,
.drwr-fade-leave-to {
  transform: translateX(100%);
}

.drwr-fade-enter-to,
.drwr-fade-leave {
  transform: translateX(0);
}
</style>

<style lang="scss">
.drwr {
  .actionsOptions {
    height: 68px;
    margin: 0 7px 0 0;
    padding: 10px 10px;
    background-color: #383f45;
    width: 100%;
    text-align: center;
    z-index: 100;
    position: absolute;
    bottom: 0px;
  }

  .actionsOptions button {
    padding: 0 20px;
    width: 160px;
    height: 48px;
    margin: 0 10px 0 0;
    padding: 14px 61px 15px 56px;
    border-radius: 4px;
    border: solid 1px #8e9ba7;
  }

  .actionsOptions button.transparent {
    border: solid 1px #8e9ba7;
  }
  .filterSelection .vs__search::placeholder,
  .filterSelection .vs__dropdown-toggle,
  .filterSelection .vs__dropdown-menu {
    background: #009bcf;
    border: none;
    color: black;
    text-transform: lowercase;
    font-variant: small-caps;
    width: 270px;
  }

  .filterSelection.batch {
    width: 300px;
  }

  .filtering {
    visibility: hidden;
  }

  .filterSelection.status {
    width: 450px;
  }

  .filterSelection .vs__clear,
  .filterSelection .vs__open-indicator {
    fill: #394066;
  }

  .vs-list--title {
    // font-size: 16px !important;
    // text-transform: uppercase !important;
    font-family: inherit !important;
    font-size: 14px;
    font-weight: normal;
    text-transform: none !important;
    // line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #383f45;
  }

  .el-tag {
    color: white;
    border: none;
    font-size: 11px;
  }

  .PDFStatus {
    color: blue;
    font-size: 16px;
  }

  .targetProject {
    border-radius: 4px;
    padding: 5px;
    float: right;
    width: 200px;
    margin-right: 1px;
    border: 1px solid lightgray;
  }

  .targetProject::placeholder {
    color: grey;
    align-content: center;
    margin-right: 5px;
  }

  .con-select-example {
    width: 45%;
  }

  .tooltiptext {
    display: none;
    font-size: 10px;
  }

  .vs-list--slot:hover .tooltiptext {
    display: block;
  }

  .entity.table {
    background-color: white;
    width: 83%;
    height: 56%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    /* padding: 10px; */
    border-radius: 15px;
    /* padding: 30px; */
  }

  .container-fluid {
    padding-bottom: 300px;
  }

  .uploadAll {
    color: white;
    font-size: 16px;
    background-color: rgb(0, 123, 258);
    padding: 10px;
    border-radius: 25px;
    font-weight: 700;
  }

  .clearAll {
    color: white;
    font-size: 16px;
    background-color: red;
    padding: 10px;
    border-radius: 25px;
    font-weight: 700;
  }

  ::placeholder {
    color: white; // text-align: right;
  }

  .vs-list--item:hover {
    background: lightgray !important;
  }

  .newField {
    font-size: 16px;
    color: black;
    text-align: left;
    margin-left: 5px;
    width: 100%;
  }

  .addNew {
    margin-left: 15px;
    background: black;
    border-radius: 5px;
    padding: 5px;
    color: white;
  }

  .userPhoto {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid white;
  }

  .centered {
    width: 100%;
    overflow-y: scroll;
    height: 90vh;
    padding-bottom: 60px;
    margin-top: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin: auto;
  }

  .claims {
    font-size: 16px;
    color: grey;
  }

  // .accessEdit {
  // }

  .centered::-webkit-scrollbar {
    display: none;
  }
  .pageLayout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    height: 100%;
    // max-height: 100%;
    // position: relative;
  }
  .slotsTable {
    margin-top: 10px;
  }
  .slotsTable label {
    margin-bottom: 0px;
    cursor: pointer;
  }
  .slotsTable input[type='checkbox'] {
    cursor: pointer;
  }
  .slotsTable td,
  th {
    padding: 2px;
    line-height: 14px;
    padding-bottom: 10px;
    text-align: left;
  }
  .slotsTable .fa-circle {
    margin-right: 10px;
    font-size: 10px;
    margin-top: -3px !important;
    display: inline-block;
  }
}
</style>
