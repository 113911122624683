<template>
  <div :class="`print-properties-card ${appStore.drawer && appStore.drawer === 'bulkPdfOptions' ? 'print-properties-card-no-margin' : ''}`">
    <div v-if="!appStore.drawer" class="main-title text-dark-grey">PDF Export</div>
    <div class="content">
      <div v-if="appStore.drawer === 'bulkPdfOptions'" class="content-each column">
        <div class="sub-title sub-title-text">Compressed Package Name</div>
        <input class="user-input" v-model="compressedPackageName" @change="updatePrintProperties" />
      </div>
      <div v-else class="content-each column">
        <div class="sub-title sub-title-text">File name</div>
        <input class="user-input" v-model="fileName" @change="updatePrintProperties" />
      </div>
      <div v-if="appStore.drawer === 'bulkPdfOptions'" class="content-each column">
        <div class="sub-title sub-title-text">Job Name</div>
        <input class="user-input" v-model="jobName" @change="updatePrintProperties" />
      </div>
      <div class="pdf-options column">
        <div class="option-container">
          <label class="options-container">
            <input type="checkbox" :checked="pdfOptions.hideAllNonVisibleContent" @click="selectOption('hideAllNonVisibleContent')" />
            <span class="option-checkmark"></span>
            <span class="option-text">Hide all non-visible content</span>
            <span class="option-info">
              <el-tooltip effect="light" placement="top" content="This will hide all content that an end user will not see. Internal references, carousel titles and subtitles, and certain links in references will be hidden.">
                <IconUtility name="tooltip-i" :width="20" />
              </el-tooltip>
            </span>
          </label>
        </div>
        <div class="option-container">
          <label class="options-container">
            <input type="checkbox" :checked="pdfOptions.annotateLinks" @click="selectOption('annotateLinks')" />
            <span class="option-checkmark"></span> <span class="option-text">Annotate links with boxes</span>
          </label>
          <div class="annotate-color-box-container" v-if="showAnnotateBox">
            <span class="annotate-color-box-title">Choose a color</span>
            <div class="annotate-color-box-options-container">
              <div :key="index" v-for="(option, index) in annotateColors">
                <div :class="`annotate-color-box-option ${option.selected ? ' annotate-color-box-option-selected' : ''}`" @click="selectAnnotateColor(option.name)">
                  <div class="annotate-color-box-option-color">
                    <div :style="circleStyling(option)"></div>
                  </div>
                  <div class="annotate-color-box-option-name">{{ option.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="option-container">
          <label class="options-container">
            <input type="checkbox" :checked="pdfOptions.colorCodeConnectors" @click="selectOption('colorCodeConnectors')" />
            <span class="option-checkmark"></span> <span class="option-text">Color-code connector arrows</span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="appStore.drawer === 'bulkPdfOptions'" class="actionsOptions">
      <center>
        <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
        <button @click="exportBulkPdf" type="button" class="btn btn-primary">Export</button>
      </center>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { FILE_TIMESTAMP_FORMAT } from '../../../../Utilities/constants/constants';
import IconUtility from '../../../../Utilities/IconUtility.vue';

export default {
  name: 'PDFPreviewSidebar',
  props: {
    flowName: {
      type: String,
      default: ''
    }
  },
  components: {
    IconUtility
  },
  mounted() {
    const timestamp = moment().format(FILE_TIMESTAMP_FORMAT);
    const fileName = `${this.appStore.currentIntent}_${timestamp}`;
    if (this.appStore.drawer && this.appStore.drawer === 'bulkPdfOptions') {
      this.compressedPackageName = `${this.appStore.drawerMetadata?.taxonomy}_${timestamp}`;
    }
    this.fileName = fileName;
    this.appStore.FLOW_PRINT_PROPERTIES({
      // Set the properties at mounted so file name is always generated
      fileName,
      customTitle: this.flowName,
      internalNotes: false,
      clientNotes: false,
      pdfOptions: {
        hideAllNonVisibleContent: false,
        annotateLinks: false,
        colorCodeConnectors: false,
        actions: true
      }
    });
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      fileName: '',
      compressedPackageName: '',
      jobName: '',
      customTitle: this.flowName,
      internalNotes: false,
      clientNotes: false,
      pdfOptions: {
        hideAllNonVisibleContent: false,
        annotateLinks: false,
        colorCodeConnectors: false,
        actions: true
      },
      showAnnotateBox: false,
      annotateColors: [
        { name: 'Blue', color: '#1996f0', selected: false },
        { name: 'Red', color: '#ff0000', selected: false },
        { name: 'Black', color: '#000000', selected: false }
      ]
    };
  },
  methods: {
    async exportBulkPdf() {
      if (this.jobName === null || this.compressedPackageName === null) return;
      if (!this.jobName)
        return this.$message({
          showClose: true,
          message: 'Please provide a job name',
          type: 'error'
        });
      if (!this.compressedPackageName)
        return this.$message({
          showClose: true,
          message: 'Please provide a package name',
          type: 'error'
        });
      const metaData = cloneDeep(this.appStore.drawerMetadata);
      try {
        this.$vs.loading();
        const date = Date.now();
        const notes = [];
        const pdfOptions = {
          internalNotes: notes.includes('INTERNAL'),
          clientNotes: notes.includes('CLIENT'),
          pdfOptions: this.pdfOptions,
          annotateColors: this.annotateColors
        };

        const integrationsEndpoint = process.env.VUE_APP_INTEGRATIONS_ENDPOINT;
        const cmpServerName = process.env.VUE_APP_CMP_SERVER_NAME;
        const pdfExportServicename = process.env.VUE_APP_PDF_EXPORT_SERVICE_NAME;

        let language = metaData.language;
        if (!language) {
          language = this.language?.code || 'en';
        }
        try {
          const message = {
            publisherURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : process.env.VUE_APP_DEFAULT_TEST_PROJECT_CMP_URL,
            taxonomy: metaData.taxonomy,
            taxonomyId: metaData.taxonomyId,
            jobName: this.jobName,
            fileName: this.compressedPackageName,
            flows: metaData.selectedFlows,
            uid: this.appStore.getUser.uid,
            date,
            email: this.appStore.getUser.email,
            user: this.appStore.getUser.displayName,
            language,
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...pdfOptions
          };
          await axios({
            method: 'post',
            url: `${integrationsEndpoint}/pdf-export/job`,
            data: message
          });
          this.$message({ message: 'Export job scheduled.', duration: 3000, showClose: true });
        } catch (error) {
          this.$message({
            message: 'An error occurred trying to generate the PDF. Please try again',
            duration: 3000,
            showClose: true
          });
          console.error(`Received error while publishing: ${error.message}`, {
            integrationsEndpoint,
            cmpServerName,
            pdfExportServicename,
            error
          });
        }
        this.appStore.closeDrawer();
        this.$vs.loading.close();
      } catch (error) {
        console.log('Error: An error occurred trying to generate the PDF', error);
        this.$message({
          message: 'An error occurred trying to generate the PDF. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    },
    selectOption(option) {
      this.pdfOptions[option] = !this.pdfOptions[option];
      if (option === 'annotateLinks') {
        this.showAnnotateBox = !this.showAnnotateBox;
        if (this.pdfOptions[option] === false) {
          this.annotateColors.map((c) => {
            c.selected = false;
            return c;
          });
        } else {
          this.annotateColors[0].selected = true;
        }
      }
      this.updatePrintProperties();
    },
    selectAnnotateColor(color) {
      this.annotateColors.map((c) => {
        if (c.name === color) {
          c.selected = true;
        } else {
          c.selected = false;
        }
        return c;
      });
      this.updatePrintProperties();
    },
    back() {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
    },
    updateData(dataKey, dataValue) {
      this[dataKey] = dataValue;
    },
    updatePrintProperties() {
      const printProperties = {
        fileName: this.fileName,
        customTitle: this.customTitle,
        internalNotes: this.internalNotes,
        clientNotes: this.clientNotes,
        pdfOptions: { ...this.pdfOptions, ...{ annotateColors: this.annotateColors } }
      };

      //Update state in store
      this.appStore.FLOW_PRINT_PROPERTIES(printProperties);
    },
    circleStyling(option) {
      return {
        width: '10px',
        height: '10px',
        'background-color': option.color,
        'border-radius': '50%'
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.print-properties-card {
  background: white;
  margin-top: 50px;
}

.print-properties-card-no-margin {
  margin-top: unset !important;
}

.main-title {
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  padding: 24px 30px;
  line-height: 23px;
  text-align: left;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0 30px;
}

.content-each {
  padding-bottom: 30px;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}

.sub-title {
  font-size: 17px;
  font-weight: normal;
  line-height: 23px;
  text-align: left;
}

.sub-title-text {
  color: #383f45;
  padding-bottom: 10px;
}

.user-input {
  border-radius: 4px;
  border: solid 1px #d7dde5;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  color: #383f45;
}
.options-container {
  display: flex;
  align-items: center;
}

.options-container input {
  display: none;
}

.option-checkmark {
  position: absolute;
  height: 20px;
  border-radius: 4px;
  width: 20px;
  background-color: #fff;
  border: solid 1px #8e9ba7;
}

/* When the checkbox is checked, add a blue background */
.checkmark-checked {
  background-color: #1996f0;
}

.checkmark-checked:after {
  display: block;
  left: 6px;
  top: 7px;
  width: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
}
/* On mouse-over, add a grey background color */
.options-container:hover input ~ .option-checkmark {
  background-color: #fff;
}
/* When the checkbox is checked, add a blue background */
.options-container input:checked ~ .option-checkmark {
  background-color: #1996f0;
}
.option-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.options-container input:checked ~ .option-checkmark:after {
  display: block;
}
.options-container .option-checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.option-text {
  position: relative;
  left: 25px;
  font-size: 17px;
  color: #383f45;
}

.option-container {
  text-align: left;
}

.option-info {
  position: relative;
  left: 30px;
}

.annotate-color-box-container {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}

.annotate-color-box-options-container {
  display: flex;
}

.annotate-color-box-option {
  display: flex;
  width: 65px;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
}

.annotate-color-box-option:hover {
  background-color: #edf0f4;
}

.annotate-color-box-option-selected {
  border: solid 1px #1996f0;
}

.annotate-color-box-option-name {
  margin-left: 10px;
}

.annotate-color-box-title {
  margin-left: 18px;
}
</style>
