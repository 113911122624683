<template>
  <carousel-3d width="240" height="140" :controls-visible="true" :clickable="false">
    <slide v-for="(slide, i) in slides" :key="i" :index="i">
      <figure>
        <img width="220" height="140" :src="slide.imageUri" />
      </figure>
    </slide>
  </carousel-3d>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
  props: {
    slides: {
      type: Array
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    titleFontSize: {
      type: String,
      default: '16px'
    },
    subtitleFontSize: {
      type: String,
      default: '13px'
    }
  },
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.carousel-3d-container figure {
  margin: 0;
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-3d-slide.current {
  border-radius: 10px;
  border: none;
}

.avatarnew {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  background-size: cover;
  float: right;
}

.openpage {
  text-overflow: ellipsis;
  white-space: normal;
  font-weight: 400;
  line-height: 1.5em;
  overflow: hidden;
  font-size: 14px;
  color: black;
}
</style>
