import axios from 'axios';

const setInterceptor = function (token) {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers = Object.assign(
          {
            idtoken: token
          },
          config.headers
        );
      }
      return config;
    },
    (error) => {
      console.error('Error: ', error.message, error);
      return Promise.reject(error);
    }
  );
};

export default setInterceptor;
