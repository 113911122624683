<template>
  <div>
    <div v-show="appStore.drawer === 'nluValidationDF'" class="overlay"></div>
    <DrawerMain id="nluDrawer" v-show="appStore.drawer === 'nluValidationDF'">
      <template v-slot:header>
        <PageHeader title="Dialog Flow" :subtitle="`NLU Validation / ${activeTab}`" />
      </template>
      <template v-slot:filters> </template>
      <template v-slot:body>
        <div v-if="appStore.currentTaxonomy" class="pageLayout">
          <div v-if="readOnly">
            <ul class="jobTabs">
              <li @click="loadTab('Configuration')" :class="{ isActive: activeTab === 'Configuration' }">Configuration</li>
              <li
                @click="if (appStore.drawerMetadata.job['job_output']) loadTab('Results');"
                :class="{
                  isActive: activeTab === 'Results',
                  disabled: !appStore.drawerMetadata.job['job_output']
                }"
              >
                Results
              </li>
            </ul>
          </div>
          <IntentList v-if="!readOnly || activeTab === 'Configuration' || !appStore.drawerMetadata.job['job_output']" :readOnly="readOnly" :metadata="appStore.drawerMetadata" @selectionChanged="selectionChanged" :collection="appStore.currentTaxonomy" v-show="appStore.drawer === 'nluValidationDF'">
            <template v-slot:extraFilters>
              <div class="filterEach">
                <span class="filterName">Folds</span>
                <input v-if="!readOnly" @keypress="isNumber($event)" type="number" min="1" v-model="folds" />
                <input v-else :disabled="true" type="number" min="1" :value="appStore.drawerMetadata.job['payload'].n" />
              </div>
              <div class="filterEach">
                <span class="filterName">Iterations</span>
                <input v-if="!readOnly" @keypress="isNumber($event)" type="number" min="1" v-model="freeze" />
                <input v-else :disabled="true" type="number" min="1" :value="appStore.drawerMetadata.job['payload'].freeze" />
              </div>
            </template>
          </IntentList>
          <div class="resultsTab" v-else>
            <div v-if="!appStore.drawerMetadata.job['job_output'].confMatrix">Jobs results pending</div>
            <div v-else class="resultsList">
              <a v-if="appStore.drawerMetadata.job['job_output'].crossvalidation" :href="appStore.drawerMetadata.job['job_output'].crossvalidation" target="_blank"><i class="fas fa-external-link-alt"></i>Cross Validation Data</a>
              <a v-if="appStore.drawerMetadata.job['job_output'].confMatrix" :href="appStore.drawerMetadata.job['job_output'].confMatrix" target="_blank"><i class="fas fa-external-link-alt"></i>Confusion Matrix</a>
              <a v-if="appStore.drawerMetadata.job['job_output'].cta" :href="appStore.drawerMetadata.job['job_output'].cta" target="_blank"><i class="fas fa-external-link-alt"></i>Cta</a>
              <a v-if="appStore.drawerMetadata.job['job_output'].tsne" :href="appStore.drawerMetadata.job['job_output'].tsne" target="_blank"><i class="fas fa-external-link-alt"></i>Tsne</a>
            </div>
          </div>
          <div v-if="!readOnly" class="actionsOptions">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="scheduleJob" type="button" class="btn btn-primary">Run</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import IntentList from './Lists/IntentList.vue';
import axios from 'axios';

export default {
  name: 'nluValidationDFDrawer',
  components: {
    DrawerMain,
    IntentList
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    autoSaveStore() {
      return useAutoSaveStore();
    },
    commentsStore() {
      return useCommentsStore();
    },
    versionsStore() {
      return useVersionsStore();
    },
    historyStore() {
      return useHistoryStore();
    },
    notificationsStore() {
      return useNotificationsStore();
    },
    vcmStore() {
      return useVcmStore();
    },
    readOnly() {
      return this.appStore.drawerMetadata?.job?.id !== undefined;
    }
  },
  data() {
    const appStore = useAppStore();
    return {
      folds: 4,
      freeze: 1,
      activeTab: 'Configuration',
      intents: [],
      batches: [],
      statuses: [],
      allIntents: [],
      filtersNotes:
        appStore.getClaims?.role != 'CLIENT'
          ? [
              { text: 'CLIENT', value: 'CLIENT', selected: false },
              { text: 'INTERNAL', value: 'INTERNAL', selected: false }
            ]
          : [{ text: 'CLIENT', value: 'CLIENT', selected: false }]
    };
  },
  methods: {
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    loadTab(tab) {
      this.activeTab = tab;
    },
    selectionChanged(payload) {
      this.intents = payload.intents;
      this.batches = payload.batches;
      this.statuses = payload.statuses;
      this.allIntents = payload.allIntents;
    },
    async scheduleJob() {
      try {
        if (!this.intents.length) return alert('No intents selected');

        if (!this.freeze || this.freeze < 1) return alert('Iterations should be greater than 0');
        if (!this.folds || this.folds < 2) return alert('Folds should be greated than 1');
        const jobName = prompt('Please enter new Job name', '');
        if (jobName === null) return;
        if (!jobName)
          return this.$message({
            showClose: true,
            message: 'Please provide a job name',
            type: 'error'
          });

        this.$vs.loading();
        const whitelist = this.intents.map((s) => s.name);

        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs`, {
          db: this.appStore.currentTaxonomy,
          agent: '',
          config: {
            whitelist,
            rules: ['dbUtterances'],
            colors: ['Pink', 'pink', 'Blue', 'blue', 'Green', 'green', 'Red', 'red', 'Black', 'black', 'Navy', 'navy']
          },
          dbURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
          driveFolder: '1ZzsmEHt1vQjvGBGXaVkZZoaPqoKBjlH6',
          n: this.folds,
          freeze: this.freeze,
          version: 'CMP',
          //env: 'production',
          source_url: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : `${process.env.VUE_APP_DEFAULT_TEST_PROJECT_CMP_URL}`,
          /////
          uid: this.appStore.getUser.uid,
          email: this.appStore.getUser.email,
          /////
          user: this.appStore.getUser.displayName,
          idToken: this.appStore.getClaims.token,
          taxonomyId: this.appStore.currentTaxonomyId,
          taxonomy: this.appStore.currentTaxonomy,
          metadata: {
            batches: this.batches.map((x) => x.value),
            statuses: this.statuses.map((x) => x.value),
            allIntents: this.allIntents.filter((a) => whitelist.includes(a.name))
          },
          jobName,
          jobType: 'xvalidation'
        });

        if (r.status === 200) {
          this.$message({
            message: 'Validation job scheduled.',
            duration: 3000,
            showClose: true
          });
        } else {
          this.$message({
            message: 'An error occurred trying to start the job. Please try again',
            duration: 3000,
            showClose: true
          });
        }
        this.appStore.closeDrawer();
        this.$vs.loading.close();
      } catch (error) {
        this.$message({
          message: 'An error occurred trying to start the job. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    }
  }
};
</script>

<style>
.jobTabs {
  border-bottom: 4px solid #fd5422;
  list-style: none;
}
.jobTabs li {
  display: inline-block;
  padding: 9px 27px 10px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: #f6f8fa;
  margin-left: 5px;
}
.jobTabs li.isActive {
  background-color: #fd5422;
  color: #fff;
}
.jobTabs li.disabled {
  color: #8e9ba8;
  cursor: default;
  background-color: transparent;
}
.resultsTab {
  height: 100%;
  padding-left: 20px;
}
.resultsList a {
  color: #383f45;
  text-decoration: none !important;
  margin-right: 10px;
}
.resultsList a:hover {
  text-decoration: underline !important;
}
.resultsList i {
  margin-right: 3px;
}
</style>
