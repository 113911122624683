<template>
  <div :id="`${componentId}__connector`" class="connector" :class="isConnected ? 'connector__connected' : 'connector__connecting'">
    <!-- TODO: Need refactor to handle this dynamically -->
    <template v-if="isContainer">
      <div v-if="!hide && !isSource && !isConnected" :id="`${componentId}__flow__handle`" :data-id="`${componentId}__flow__handle`" :data-nodeid="componentId" :data-handlepos="`top`" :data-handletype="`target`" :class="['flow__handle', `flow__handle-top`, 'nodrag', 'connectable', 'target']" @mousedown="outputMouseDown({ connectorPosition: 'top', componentId: componentId }, $event)" @mouseup="outputMouseUp">
        <ConnectorPoint :id="componentId" position="top" :isConnected="false" :connectorType="`target`" />
      </div>
      <div v-if="!hide && isSource && !isConnected" :id="`${componentId}__flow__handle`" :data-id="`${componentId}__flow__handle`" :data-nodeid="componentId" :data-handlepos="`bottom`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-bottom`, 'nodrag', 'connectable', 'source']" @mousedown="outputMouseDown({ connectorPosition: 'bottom', componentId: componentId }, $event)" @mouseup="outputMouseUp">
        <ConnectorPoint :id="componentId" position="bottom" :isConnected="false" :connectorType="`source`" />
      </div>
      <div v-if="!hide && !isSource && isContainer" :id="`${componentId}__flow__target-connector-top`" :data-id="`${componentId}__flow__target-connector`" :data-nodeid="componentId" :data-handlepos="`top`" :data-handletype="`target`" :class="['flow__handle', `flow__handle-top-connected`, 'nodrag', 'connectable', 'target']" :data-nodeSourceConnected="isNodeSourceConnected(componentId)" :data-nodeTargetConnected="isNodeTargetConnected(componentId)">
        <ConnectorPoint :id="componentId" :isConnected="false" :connectorType="`target`" :show="isHovering" />
      </div>

      <div v-if="!hide && isSource && isContainer" :id="`${componentId}__flow__source-connector-bottom`" :data-id="`${componentId}__flow__source-connector`" :data-nodeid="componentId" :data-handlepos="`bottom`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-bottom-connected`, 'nodrag', 'connectable', 'source']" :data-nodeSourceConnected="isNodeSourceConnected(componentId)" :data-nodeTargetConnected="isNodeTargetConnected(componentId)">
        <ConnectorPoint :id="componentId" :isConnected="isNodeSourceConnected(componentId)" :connectorType="`source`" :show="isHovering" />
      </div>
    </template>

    <template v-if="!isContainer">
      <div v-if="!hide && isSource && !isConnected" :id="`${componentId}__flow__handle-left-${optionIndex}`" :data-id="`${componentId}__flow__handle-left`" :data-nodeid="componentId" :data-handlepos="`left`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-left`, 'nodrag', 'connectable', 'source']" @mousedown="outputMouseDown({ connectorPosition: 'left', componentId: componentId }, $event)" @mouseup="outputMouseUp">
        <ConnectorPoint :optionIndex="optionIndex" :id="componentId" :position="`left`" :isConnected="false" :connectorType="`source`" />
      </div>

      <div v-if="!hide && isSource && !isConnected" :id="`${componentId}__flow__handle-right-${optionIndex}`" :data-id="`${componentId}__flow__handle-right`" :data-nodeid="componentId" :data-handlepos="`right`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-right`, 'nodrag', 'connectable', 'source']" @mousedown="outputMouseDown({ connectorPosition: 'right', componentId: componentId }, $event)" @mouseup="outputMouseUp">
        <ConnectorPoint :optionIndex="optionIndex" :id="componentId" :position="`right`" :isConnected="false" :connectorType="`source`" />
      </div>

      <div v-if="!hide && isSource && isConnected" :id="`${componentId}__flow__source-connector-left-${optionIndex}`" :data-id="`${componentId}__flow__source-connector`" :data-nodeid="componentId" :data-handlepos="`left`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-left-connected`, 'nodrag', 'connectable', 'source']" :data-nodeSourceConnected="isNodeSourceConnected(componentId)" :data-nodeTargetConnected="isNodeTargetConnected(componentId)">
        <ConnectorPoint :optionIndex="optionIndex" :id="componentId" :position="`left`" :isConnected="true" :currentOptionConnectedPosition="currentOptionConnectedPosition" :connectorType="`source`" :show="currentOptionConnectedPosition === 'left'" />
      </div>

      <div v-if="!hide && isSource && isConnected" :id="`${componentId}__flow__source-connector-right-${optionIndex}`" :data-id="`${componentId}__flow__source-connector`" :data-nodeid="componentId" :data-handlepos="`right`" :data-handletype="`source`" :class="['flow__handle', `flow__handle-right-connected`, 'nodrag', 'connectable', 'source']" :data-nodeSourceConnected="isNodeSourceConnected(componentId)" :data-nodeTargetConnected="isNodeTargetConnected(componentId)">
        <ConnectorPoint :optionIndex="optionIndex" :id="componentId" :position="`right`" :isConnected="true" :currentOptionConnectedPosition="currentOptionConnectedPosition" :connectorType="`source`" :show="currentOptionConnectedPosition === 'right'" />
      </div>
    </template>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';

export default {
  name: 'NodeConnector',
  props: {
    optionIndex: {
      default: ''
    },
    componentId: {
      type: String
    },
    nodeType: {
      type: String
    },
    response: {},
    node: {},
    hide: {
      type: Boolean,
      default: true,
      required: false
    },
    position: {
      type: String,
      required: false
    },
    isSource: {
      type: Boolean,
      default: false,
      required: false
    },
    isConnected: {
      type: Boolean,
      default: false,
      required: false
    },
    isContainer: {
      type: Boolean,
      default: true,
      required: false
    },
    isHovering: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      loadConnectorTimeout: null
    };
  },
  // Recompute after the canvas load to show the connectors
  mounted() {
    setTimeout(() => {
      this.appStore.FORCE_STATE_RECOMPUTE();
    }, 200);
  },
  computed: {
    ...mapState(useAppStore, ['stateRecomputeCounter']),
    currentOptionConnectedPosition() {
      this.appStore.rerenderEdges;
      this.stateRecomputeCounter;
      // let point = document.getElementById(`line-${this.id}-right-${this.optionIndex}`)
      if (this.hide === true) return false;
      if (document.getElementById(`line-${this.componentId}-right-${this.optionIndex}`)) return 'right';
      if (document.getElementById(`line-${this.componentId}-left-${this.optionIndex}`)) return 'left';
      else return false;
    }
  },
  methods: {
    outputMouseDown(node, e) {
      e.preventDefault();
      this.$emit('linking-start', { ...node, event: e });
    },
    outputMouseUp(e) {
      e.preventDefault();
      this.$emit('linking-stop', e);
    },
    getHandleType() {
      return this.isSource ? 'source' : 'target';
    },
    getHandlePosition() {
      // TODO: Refactor this to handle routing components
      return this.isSource ? 'bottom' : 'top';
    },
    isNodeSourceConnected(nodeId) {
      try {
        const scene = this.appStore.getScene;
        const sources = scene.links.filter((link) => link.from === nodeId);
        if (sources.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    isNodeTargetConnected(nodeId) {
      try {
        const scene = this.appStore.getScene;
        const targets = scene.links.filter((link) => link.to === nodeId);
        if (targets.length > 0) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    },
    nodeConnectedPositions() {
      // TODO: Return connected positions of the node
      return [];
    }
  },
  beforeUnmount() {}
};
</script>

<style lang="scss" scoped>
// .component-overlay:hover * .flow__handle-bottom,
// .component-overlay:hover * .flow__handle-left,
// .component-overlay:hover * .flow__handle-right {
//   visibility: visible;
// }

.component-overlay:hover * .connector,
.component-overlay:hover * .connector.connecting {
  visibility: visible;
}
.connector {
  width: 100%;
  &.connector__connecting {
    z-index: 2;
  }

  &.connector__connected {
    z-index: 1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.connecting {
  display: block;
}

.flow__handle {
  background: transparent;
  border: none;
  border-radius: 100%;
  height: 6px;
  position: absolute;
  width: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // visibility: hidden;
}

.flow__handle-right {
  top: 5px;
  right: -9px;
  justify-content: center;
  z-index: 2;
}

.flow__handle-left {
  top: 5px;
  left: -15px;
  justify-content: center;
  z-index: 2;
}

.flow__handle-bottom {
  bottom: -4px;
  left: 50%;
  justify-content: center;
  background-color: rgba(#1996f0, 0.05);
  transform: translate(-50%);
  z-index: 2;
}

.flow__handle-bottom:hover {
  background-color: rgba(#1996f0, 0.1);

  .outer-ellipse {
    background-color: rgba(#1996f0, 0.5);

    .inner-circle {
      background-color: rgba(#1996f0, 0.7);
    }
  }

  .source-selected-circle {
    background-color: #8e9ba8;
  }

  .target-selected-circle {
    background-color: #8e9ba8;
  }
}

.flow__handle-top {
  top: -4px;
  left: 50%;
  justify-content: center;
  background-color: #1996f0;
  transform: translate(-50%);
  z-index: 2;
}

.flow__handle-top:hover {
  background-color: rgba(#1996f0, 0.2);
}

.flow__handle-bottom-connected {
  bottom: -4px;
  left: 50%;
  justify-content: center;
  transform: translate(-50%);
  z-index: 1;
}

.flow__handle-top-connected {
  top: -4px;
  left: 50%;
  justify-content: center;
  transform: translate(-50%);
  z-index: 1;
}

.flow__handle-left-connected {
  top: 5px;
  left: -15px;
  justify-content: center;
  z-index: 1;
}

.flow__handle-right-connected {
  top: 5px;
  right: -8px;
  justify-content: center;
  z-index: 1;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
