<template>
  <div class="row button-row">
    <div @click="execute" @mouseover="hover = true" @mouseleave="hover = false" class="col-2">
      <ConversationBuilderIcon :key="hover" class="add-button" :width="width" :name="`add${hover ? '-hover' : ''}`" />
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'AddButton',
  data() {
    return {
      appStore: useAppStore(),
      hover: false
    };
  },
  props: {
    func: {
      type: Function
    },
    width: {
      type: Number,
      default: 45
    }
  },
  methods: {
    execute() {
      this.func();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-button {
  display: block;
}

.button-row {
  padding: 7px;
}
</style>
