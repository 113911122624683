import { Extension } from '@tiptap/core';

export const CustomEnterKeyExtension = Extension.create({
  name: 'customEnter',

  addKeyboardShortcuts() {
    return {
      Enter: () => {
        return true; // prevents the default behavior
      }
    };
  }
});
