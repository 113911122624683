import { ref } from 'vue';
import { defineStore } from 'pinia';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import { useAppStore, useVcmStore } from '@/store/index.js';

export const useCommentsStore = defineStore({
  id: 'commentsStore',

  state: () => ({
    comments: ref([]),
    pendingComment: ref({}),
    commentFilters: ref({
      resolved: false
    }),
    pinnedComments: ref([])
  }),

  getters: {
    getComments: (state) => {
      let pinnedIndex = 0;
      return state.comments.map((comment) => {
        if (comment?.['pinned']) {
          pinnedIndex += 1;
          return { ...comment, pinnedCommentIndex: pinnedIndex };
        } else {
          return comment;
        }
      });
    },
    getCommentFilters: (state) => {
      return state.commentFilters;
    },
    getPendingComment: (state) => {
      return state.pendingComment;
    },
    getMostRecentCommentId: (state) => {
      return state.mostRecentCommentId;
    }
  },
  actions: {
    PENDING_COMMENT({ comment }) {
      try {
        if (comment?.attachedTo === 'component') {
          this.pendingComment = comment;
        }
        if (comment?.attachedTo === 'container') {
          this.pendingComment = comment;
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    REMOVE_COMMENT({ id }) {
      this.comments = this.comments.filter((x) => x.id != id);
    },
    CLEAR_PENDING() {
      this.pendingComment = cloneDeep({});
    },
    ACCEPT_PENDING() {
      this.comments.push(this.pendingComment);
    },
    ADD_COMMENT({ comment }) {
      this.comments.push(comment);
    },
    ADD_CHILD_COMMENT({ comment, parentCommentId }) {
      // Refactor to use function above?
      this.comments.find((comment) => comment.id === parentCommentId).childComments.push(comment);
    },
    SET_COMMENTS({ comments }) {
      this.comments = comments;
    },
    SET_COMMENT_FILTERS({ filterName, filterValue }) {
      this.commentFilters[filterName] = filterValue;
    },
    UNPIN_COMMENT({ id, type, commentId }) {
      if (commentId === undefined) {
        const field = type === 'container' ? 'containerId' : 'componentId';
        const commentsToUnpin = this.comments.filter((x) => x[field] === id).map((x) => x.id);
        if (commentsToUnpin) {
          const newComments = this.comments.map((x) => {
            if (commentsToUnpin.includes(x.id))
              return {
                ...omit(x, ['attachedTo', 'pinnedCommentIndex', 'pinned'])
              };
            else return x;
          });
          this.comments = newComments;
        }
        return;
      }

      const comment = this.comments.find((currComment) => {
        return currComment.id === commentId;
      });
      if (comment) {
        delete comment.containerId;
        delete comment.attachedTo;
        comment.pinned = false;
      }
    },
    async goToComment({ id } = {}) {
      waitForElm(id).then((comment) => {
        // wait for DOM element to be available;
        document.querySelectorAll('.comment-container ').forEach((el) => el.classList.remove('active'));
        setTimeout(() => {
          comment.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
          });
        }, 500);
        comment.classList.add('active');
      });
    },
    async goToComponentOrContainer({ comment }) {
      const appStore = useAppStore();
      let canvasElement;
      if (comment.attachedTo === 'component') {
        const component = appStore.getComponentListFromComponents.find((comp) => {
          if (comp.id === comment.componentId) {
            return comp;
          }
        });
        const containerId = component?.parentId;

        canvasElement = document.getElementById(`${comment.componentId}`);

        appStore.setComponentId(comment.componentId);
        appStore.setActiveContainer(containerId);
        appStore.SET_SELECTED_CONTAINERS([containerId]);
      }

      if (comment.attachedTo === 'container') {
        canvasElement = document.getElementById(`${comment.containerId}`);

        appStore.setComponentId(false);
        appStore.setActiveContainer(comment.containerId);
        appStore.SET_SELECTED_CONTAINERS([comment.containerId]);
      }

      let x, y, elementX, elementY;

      canvasElement?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });

      // The way the draggable canvas works, scrollIntoView doesn't always correctly scroll the container/component
      // where it should be, so it fires of in intervals until the position of the container/components doesn't change anymore
      const interval = setInterval(() => {
        x = elementX;
        y = elementY;

        const canvasElementCoordinates = canvasElement?.getBoundingClientRect();

        elementX = canvasElementCoordinates.x;
        elementY = canvasElementCoordinates.y;

        if (x !== elementX || y !== elementY) {
          canvasElement?.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          });
        } else {
          clearInterval(interval);
        }
      }, 50);
    },
    persistDeletedAndResolved({ id }) {
      const appStore = useAppStore();
      const vcmStore = useVcmStore();
      const { slot, taxonomy } = appStore.current;
      const { language } = appStore;
      const { displayName: userName, photoURL: userPhoto, uid, email: userId } = firebase.auth().currentUser;
      const userPayload = {
        userName,
        userPhoto,
        userId,
        uid
      };
      axios
        .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/comments/persistResolvedDeleted`, {
          slot: slot,
          taxonomy: taxonomy + '_flows',
          language: language,
          comments: this.comments,
          id,
          userPayload
        })
        .then(() => {
          vcmStore.forceSave({ uxStateOnly: false });
        })
        .catch((err) => {
          console.error('Error: ', err.message, err);
        });
    },
    async persistComments({ mentionedUsers, presentUsers, type, parentCommentId }) {
      const appStore = useAppStore();
      const vcmStore = useVcmStore();
      let childCommentArr = [];
      if (type === 'reply') {
        childCommentArr = this.comments.find((comment) => comment.id === parentCommentId).childComments;
      }
      try {
        const emailService = (await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs/emailService`)).data;
        const { slot, taxonomy, taxonomyId, url } = appStore.current;
        const { language } = appStore;
        const { displayName: userName, photoURL: userPhoto, uid, email: userId } = firebase.auth().currentUser;

        const userPayload = {
          userName,
          userPhoto,
          userId,
          uid
        };

        const taxonomyPayload = {
          taxonomy,
          taxonomyId,
          url,
          emailService
        };

        const slotPayload = {
          flowName: slot.name,
          flowId: slot.id
        };

        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/comments/persistComments`, {
          taxonomy: taxonomy + '_flows',
          slotId: slot.id,
          language,
          comments: this.comments,
          presentUsers,
          mentionedUsers,
          stateComments: this.comments,
          childCommentArr,
          type,
          flow: appStore.current?.taxonomy,
          userPayload,
          slotPayload,
          taxonomyPayload
        });

        vcmStore.forceSave({ uxStateOnly: false });
      } catch (err) {
        console.log('comments err...');
        console.error('Error: ', err.message, err);
      }
    },
    addComment({ commentText, pinnedComment, attachedTo, containerId, componentId }) {
      const appStore = useAppStore();
      const currentUser = appStore.getUser;
      const currentTime = Date.now();

      const comment = {
        id: uuidv4(),
        userId: currentUser.uid,
        userName: currentUser.displayName,
        userPhoto: currentUser.photoURL,
        text: commentText,
        createdAt: currentTime,
        updatedAt: currentTime,
        resolved: false,
        pinned: pinnedComment,
        childComments: []
      };
      if (attachedTo) {
        comment.attachedTo = attachedTo;
      }

      if (containerId) {
        comment.containerId = containerId;
      }

      if (componentId) {
        comment.componentId = componentId;
      }

      return comment;
    },
    addChildComment({ commentText, parentCommentId, mentionedUsers, presentUsers, type }) {
      // Refactor to use function above?
      const appStore = useAppStore();
      const currentUser = appStore.getUser;
      const currentTime = Date.now();

      const comment = {
        id: uuidv4(),
        userId: currentUser.uid,
        userName: currentUser.displayName,
        userPhoto: currentUser.photoURL,
        text: commentText,
        createdAt: currentTime,
        updatedAt: currentTime,
        resolved: false
      };
      this.ADD_CHILD_COMMENT({ comment, parentCommentId });
      this.persistComments({
        mentionedUsers,
        presentUsers,
        type,
        parentCommentId
      });
    },
    deleteComment({ id }) {
      const index = this.comments.findIndex((object) => {
        return object.id === id;
      });
      this.comments.splice(index, 1);
      this.persistDeletedAndResolved({ id });
    },
    deleteChildComment({ index, parentCommentId }) {
      const id = this.comments.find((comment) => comment.id === parentCommentId).childComments[index].id;
      this.comments.find((comment) => comment.id === parentCommentId).childComments.splice(index, 1);
      this.persistDeletedAndResolved({ id });
    },
    resolveComment({ id, resolveBool }) {
      const comment = this.comments.find((comment) => comment.id === id);
      comment.resolved = resolveBool;
      comment.pinned = false;
      delete comment.attachedTo;
      delete comment.componentId;
      this.persistDeletedAndResolved({ id });
    },
    updateComment({ id, commentText, pinnedComment, attachedTo, containerId, componentId, mentionedUsers, presentUsers, type }) {
      const newComments = cloneDeep(this.comments);

      const index = newComments.findIndex((comment) => {
        return comment.id === id;
      });

      newComments[index].text = commentText;

      if (pinnedComment) {
        newComments[index].pinned = pinnedComment;
      }

      if (attachedTo) {
        newComments[index].attachedTo = attachedTo;
      }

      if (containerId) {
        newComments[index].containerId = containerId;
        delete newComments[index].componentId;
      }

      if (componentId) {
        newComments[index].componentId = componentId;
        delete newComments[index].containerId;
      }

      this.comments = newComments;

      this.persistComments({ mentionedUsers, presentUsers, type });
    },
    updateChildComment({ index, commentText, parentCommentId, mentionedUsers, presentUsers, type }) {
      this.comments.find((comment) => comment.id === parentCommentId).childComments[index].text = commentText;
      this.persistComments({
        mentionedUsers,
        presentUsers,
        type,
        parentCommentId
      });
    }
  }
});

const waitForElm = function (id) {
  return new Promise((resolve) => {
    if (document.getElementById(`${id}`)) {
      return resolve(document.getElementById(`${id}`));
    }
    const observer = new MutationObserver(() => {
      if (document.getElementById(`${id}`)) {
        resolve(document.getElementById(`${id}`));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};
