<template>
  <div class="decision-edit-wrapper">
    <div
      class="decision-edit"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div class="col-0">
        <span class="decision-index">{{ index + 1 }}</span>
      </div>
      <div class="col-8 option-name">
        <div
          contenteditable
          class="decision-title"
          ref="nameInput"
          :id="componentId"
          @input="updateText"
          @focusout="leavingInputFocus"
          @focusin="onFocus"
        >
          {{ this.editedName ? this.editedName : this.option.text }}
        </div>
      </div>
      <div
        class="col-1 arrow-button"
        v-if="optionsCount && index !== optionsCount - 1 && hover"
        @mouseover="hoverDown = true"
        @mouseleave="hoverDown = false"
        @click="moveOptionDown"
      >
        <ConversationBuilderIcon
          class="add-button"
          :width="20"
          :styleOnIcon="{ transform: 'rotate(0.5turn)' }"
          :name="`arrow_up${hoverDown ? '-hover' : ''}`"
        />
      </div>
      <div
        class="col-1 arrow-button"
        v-if="optionsCount && index !== 0 && hover"
        @mouseover="hoverUp = true"
        @mouseleave="hoverUp = false"
        @click="moveOptionUp"
      >
        <ConversationBuilderIcon
          class="add-button"
          :width="20"
          :name="`arrow_up${hoverUp ? '-hover' : ''}`"
        />
      </div>
      <div
        class="col-1 remove-button"
        v-if="optionsCount > 1 && hover"
        @mouseover="hoverRemove = true"
        @mouseleave="hoverRemove = false"
        @click="removeOption"
      >
        <ConversationBuilderIcon
          class="add-button"
          :width="20"
          :name="`remove${hoverRemove ? '-hover' : ''}`"
        />
      </div>
    </div>
    <!-- TODO: Will only be usable when implementing CHP-5598. Please note, there will be other requirements other than just uncommenting. For example, you should not be able to select 2 of the same intents in a decision component. -->
    <!-- <div class="intent-container" :class="{ noPointer: !isEditor }">
        <DecisionIntentDropdown :option="option" :index="index" :componentId="componentId">
          <template v-slot:header>
            <div class="row editor-header">
              <div class="col-11">
                <span class="sidebar type">Intent</span>
              </div>
            </div>
          </template>
        </DecisionIntentDropdown>
      </div> -->
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
// import DecisionIntentDropdown from '../Dropdowns/DecisionIntentDropdown.vue';
import essentials from '../Mixins/essentials';
import { mapState } from 'pinia';
export default {
  name: 'DecisionEach',
  mixins: [essentials],
  props: {
    text: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    option: {
      type: Object,
    },
    componentId: {
      type: String,
      default: '',
    },
    optionsCount: {
      type: Number,
    },
  },
  methods: {
    onFocus() {
      this.editedName = this.option.text;
    },
    updateText(e) {
      this.tempInput = e.target.innerText;
      this.isEditing = true;
    },
    leavingInputFocus(e) {
      this.editedName = this.tempInput ? this.tempInput : e.target.innerText;
      const otherOptions = this.options
        .filter((x, i) => i !== this.index)
        .map((option) => {
          return option.text;
        });
      if (otherOptions.includes(this.editedName.trim())) {
        alert('The option names have to be unique, please type another name.');
        this.isEditing = false;
        return;
      }
      this.appStore.updateOption({
        text: this.editedName.trim(),
        decisionComponentId: this.componentId,
        optionIndex: this.index,
      });

      this.isEditing = false;
    },
    moveOptionUp() {
      this.appStore.moveDecisionOption({
        startIndex: this.index,
        endIndex: this.index - 1,
        decisionComponentId: this.componentId,
      });
    },
    moveOptionDown() {
      this.appStore.moveDecisionOption({
        startIndex: this.index,
        endIndex: this.index + 1,
        decisionComponentId: this.componentId,
      });
    },
    removeOption() {
      this.appStore.removeItemFromCollection({
        index: this.index,
        componentId: this.componentId,
        collectionField: 'options',
      });
      this.appStore.removeOptionContainer({
        index: this.index,
        decisionComponentId: this.componentId,
      });
    },
    getOptionName() {
      return this.isEditing ? this.editedName : this.option.text;
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      slots: [],
      time: '1 sec',
      taxonomy: 'COVID_TEST',
      intent: 'SA2_WarningPrecautions',
      hover: false,
      hoverUp: false,
      hoverDown: false,
      hoverRemove: false,
      isEditing: false,
      editedName: '',
      options: '',
      tempInput: '',
    };
  },
  components: {
    // DecisionIntentDropdown
  },
  computed: {
    ...mapState(useAppStore, ['isEditor', 'currentComponent']),
  },
  mounted() {
    this.options = this.currentComponent.options;
  },
  beforeUnmount() {
    this.slots = [];
  },
};
</script>

<style scoped>
.arrow-button {
  padding: 0;
  margin-right: 8px;
}

.remove-button {
  padding: 0;
}

.decision-edit {
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.option-name {
  margin-top: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-bottom: 0px;
}
.intent-container {
  margin-left: 10px;
}
.noPointer {
  pointer-events: none;
  cursor: not-allowed;
}

input.decision-title {
  /* width: 240px !important; */
  height: 20px;
  margin-top: 1px;
  font-size: 14px;
  margin-bottom: 20px;
}
input::placeholder {
  font-style: normal !important;
  font-weight: normal;
  font-size: 14px;
}

.decision-index {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1996f0;
  margin-left: 5px;
}

.decision-title {
  font-weight: bold;
  margin-left: 12px;
  width: 100%;
  min-height: 21px;
  text-align: left;
}

.bi.bi-record-circle-fill {
  color: #1996f0;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.bi.bi-record-circle {
  color: #d7dde5;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.fas.fa-info-circle {
  margin-left: 5px;
}

.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

input,
option,
select {
  font-size: 12px;
}

input::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
