<template>
  <ul :class="{ opened: opened }" class="language-sub-menu">
    <li
      @click="
        item.click();
        closeParent();
      "
      v-for="(item, i) in localItems"
      :key="i"
    >
      <span>{{ item.title }}</span>
    </li>
  </ul>
</template>

<script>
import { useAppStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'LanguageDropdown',
  data() {
    return {
      appStore: useAppStore(),
      vcmStore: useVcmStore
    };
  },
  computed: {
    alternativeLanguage() {
      return this.appStore.alternativeLanguage;
    },
    localItems() {
      return this.alternativeLanguage.map((lang) => ({
        icon: '',
        title: Object.keys(lang)[0],
        click: () => {
          this.setLanguage(lang[Object.keys(lang)[0]]);
        }
      }));
    }
  },

  methods: {
    setLanguage(language) {
      const dispatched = this.appStore.setLanguage({ language });
      const done = () => {
        // this.vcmStore.clear();
        const query = { lang: this.appStore.language ? this.appStore.language : 'en' };
        this.$router.push({ query });
      };
      if (dispatched?.then) {
        dispatched.then(done);
      } else {
        done();
      }
    }
  },

  props: {
    opened: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    closeParent: {
      type: Function
    }
  }
};
</script>

<style scoped>
li > .caption {
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.language-sub-menu {
  display: none;
  font-size: 14px;
  list-style-type: none;
  padding-left: 0;
}

li.opened {
  display: block;
}

ul.language-sub-menu {
  display: none;
}

ul.opened.language-sub-menu {
  display: block;
}

.language-sub-menu li {
  width: 100%;
  height: max-content;
  cursor: pointer;
  line-height: 14px;
  padding: 7px 3px 7px 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.language-sub-menu li span {
  margin-left: 4px;
}

.language-sub-menu li:hover {
  background: #e8f5fe;
}

.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  margin-top: 3px;
}

.label {
  background: #fd5422;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 21px;
  display: none;
  margin-right: 10px;
}

.language-sub-menu li:hover .label {
  display: block;
}
</style>
