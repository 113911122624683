<template>
  <span class="icon" :class="[`icon--${name}`, `icon--${size}`, { 'has-align-fix': fixAlign }]" :style="customIconWrapperStyle">
    <img class="icon__svg" :src="svg" :style="customIconStyle" :width="width" :height="width" />
  </span>
</template>

<script>
export default {
  name: 'IconUtility',
  props: {
    name: {
      type: String,
      required: true
    },
    nameHover: {
      type: String,
      required: false
    },
    nameDisabled: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      default: 'large'
    },
    modifier: {
      default: null
    },
    fixAlign: {
      default: true
    },
    width: {
      default: 15,
      required: false
    },
    customStyle: {
      type: Object,
      required: false
    },
    styleOnIcon: {
      type: Object,
      required: false
    },
    extension: {
      type: String,
      required: false,
      default: 'svg'
    }
  },
  computed: {
    svg() {
      let name = this.name;

      if (this.nameHover && this.isHovering) {
        name = this.nameHover;
      }

      if (this.disabled && this.nameDisabled) {
        name = this.nameDisabled;
      }

      return require(`../../../assets/icons/${name}.${this.extension}`);
    },
    customIconWrapperStyle() {
      const style = {
        ...this.customStyle
      };
      return style;
    },
    customIconStyle() {
      const style = {
        ...this.styleOnIcon,
        width: `${this.width}px`,
        height: `${this.width}px`
      };
      return style;
    }
  }
};
</script>

<style lang="scss">
.icon {
  position: relative;
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  top: -0.05rem;
  fill: currentColor;
  &__svg {
    display: inline-block;
    vertical-align: top;
  }
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

// svg sprite
</style>
