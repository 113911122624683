<template>
  <!-- For versions with multiple versions within a versionGroupId -->
  <div v-if="versionGroup.length > 1">
    <div class="expandButtonAndVersion" :style="customStyle(versionGroup[0])" v-on:mouseenter="setCurrentlyHoveringId(versionGroup[0])" v-on:mouseleave="setCurrentlyHoveringId(null)">
      <div id="expandButton" @click="triggerExpand">
        <ConversationBuilderIcon :width="26" :name="$vnode.key === isExpanded ? 'arrow_down' : 'arrow_right'" class="expand-icon" :customStyle="{ display: 'block' }" />
      </div>
      <!-- Most recent version in the group -->
      <FlowVersion class="version" :version="versionGroup[0]" :icon-color="getUserColor(versionGroup[0])" :show-year="showYear" />
    </div>
    <div v-for="(draft, index) in remainingVersionsWithinGroup" :key="index" class="row collapsable-div" :style="customStyle(draft)" v-on:mouseenter="setCurrentlyHoveringId(draft)" v-on:mouseleave="setCurrentlyHoveringId(null)">
      <div v-show="$vnode.key === isExpanded" class="col text-start flow-version-sub-container">
        <!-- Remaining versions in the group -->
        <FlowVersion :version="draft" :icon-color="getUserColor(draft)" :show-year="showYear" />
      </div>
    </div>
  </div>
  <!-- For versions where they are the only version within a versionGroupId -->
  <div v-else class="expandButtonAndVersion" :style="customStyle(versionGroup[0])" v-on:mouseenter="setCurrentlyHoveringId(versionGroup[0])" v-on:mouseleave="setCurrentlyHoveringId(null)">
    <div class="fakeExpandButton"></div>
    <FlowVersion class="version" :version="versionGroup[0]" :icon-color="getUserColor(versionGroup[0])" :show-year="showYear" />
  </div>
</template>

<script>
import { mapState } from 'pinia';
import FlowVersion from './FlowVersion.vue';
import ConversationBuilderIcon from '../../../Canvas/3.0/Icons/ConversationBuilderIcon.vue';
import { useVersionsStore } from '@/store';

export default {
  name: 'FlowVersions',
  components: { FlowVersion, ConversationBuilderIcon },
  emits: ['expandDrafts'],
  data() {
    return {
      isVisible: false,
      versionGroup: [],
      currentlyHoveringId: false
    };
  },
  props: {
    id: {
      required: true
    },
    filteredDrafts: {
      type: Array,
      required: true
    },
    createdAt: {
      type: String,
      required: false,
      default: ''
    },
    isExpanded: {
      required: true,
      default: false    },
    userColors: {
      type: Array,
      required: true
    },
    showYear: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    this.versionGroup = this.filteredDrafts.map((i) => ({ ...i, isEditing: false }));
    this.isVisible = this.versionGroup.find((draft) => this.activeDraft(draft)) ? true : false;
  },
  computed: {
    ...mapState(useVersionsStore, ['currentVersion', 'previewVersion']),
    remainingVersionsWithinGroup() {
      return this.versionGroup.slice(1, this.versionGroup.length);
    }
  },
  methods: {
    activeDraft(draft) {
      return this.currentVersion ? this.currentVersion.id === draft.id : false;
    },
    triggerExpand() {
      this.$emit('expandDrafts', this.id);
    },
    getUserColor(draft) {
      if (this.userColors.find((o) => o.user === draft.user)) {
        return this.userColors.find((o) => o.user === draft.user).color;
      }
    },
    customStyle(version) {
      const isPreviewVersion = this.isThisVersionPreviewVersion(version);
      const isCurrentVersion = this.isThisVersionCurrentVersion(version);
      const style = {
        background: '#fff'
      };
      if (isCurrentVersion) {
        style.background = '#e8f5fe';
      }

      if (this.currentlyHoveringId === version.id) {
        style.background = '#edf0f4';
      }

      if (isPreviewVersion) {
        style.background = '#edf0f4 !important';
        style.border = 'solid 1px #edf0f4';
      }
      return style;
    },
    isThisVersionPreviewVersion(version) {
      return version.id === this.previewVersion?.id;
    },
    isThisVersionCurrentVersion(version) {
      return version.id === this.currentVersion?.id;
    },
    setCurrentlyHoveringId(version) {
      this.currentlyHoveringId = version?.id;
    }
  },
  watch: {
    filteredDrafts(newValue) {
      this.versionGroup = newValue.map((i) => ({ ...i, isEditing: false }));
    }
  }
};
</script>

<style>
.draft-row {
  font-weight: 700;
  font-size: 14px;
  color: rgb(87, 87, 87);
}

.expandButtonAndVersion {
  display: flex;
  width: 100%;
  align-items: center;
}

.collapsable-div {
  margin: 0 !important;
}

.fakeExpandButton {
  width: 26px;
}

.version {
  width: 100%;
}

.flow-version-sub-container {
  margin-left: 48px;
  padding-left: 4px;
}
</style>
