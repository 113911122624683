<template>
  <div class="d-flex" @mouseover="isVisible = true" @mouseleave="isVisible = false">
    <div class="p-2 text-start icon-color container" @click="handlePreviewVersion(version)">
      <div v-if="getRestoredFrom()" :class="'restored-from'">Restored from {{ getRestoredFrom() }}</div>
      <div v-if="isCurrentVersion(version)" class="current-version">Current version</div>
      <div id="versionName" v-show="!isInputActive" :class="isNameMain ? 'main-text' : 'version-info'">
        {{ version.versionName }}
      </div>
      <div v-if="!isInputActive" id="versionDate" :class="!isNameMain ? 'main-text' : 'version-info'" :style="{ color: getDateTextColour }">{{ parseDate(version.createdAt, showYear) }}</div>
      <input ref="versionNameInputRef" id="versionNameInput" :readonly="!isInputActive" :class="isInputActive ? 'input-shadow' : 'd-none'" type="text" maxlength="50" @keypress.enter="handleVersionName($event)" @blur="hideInput($event)" @click.stop />
      <div class="version-user-info">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" :fill="iconColor">
          <circle cx="12" cy="12" r="4" />
        </svg>
        <span id="versionUser" class="version-row" :style="version.user ? '' : 'font-style: italic'">{{ version.user || 'Unknown user' }}</span>
      </div>
      <div v-if="version.publisher" class="pl-4 text-published">
        Published by {{ version.publisher }} on
        {{ parseDate(version.publishedAt, showYear) }}
      </div>
    </div>
    <div class="mr-auto p-2 more-info-container" style="flex: 0 !important">
      <div class="version-popup-container" v-if="isFlowEditor">
        <MorePopup @editVersionName="editVersionName" :version="version" @hideMore="isVisible = false" :is-visible="isVisible" @click.stop />
      </div>
      <div class="published-container" v-if="showPublishedButton" @click="handleShowPublished">
        <span>Published</span>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { useAppStore, useCollabStore, useVersionsStore } from '@/store/index.js';
import { mapActions, mapState } from 'pinia';
import { ElLoading } from 'element-plus';
import MorePopup from './MorePopup.vue';
import axios from 'axios';

export default {
  name: 'FlowVersion',
  components: { MorePopup },
  data() {
    return {
      appStore: useAppStore(),
      isVisible: false,
      tempVersion: this.version,
      isInputActive: false
    };
  },
  props: {
    version: {
      type: Object,
      required: true
    },
    iconColor: {
      type: String,
      required: true,
      default: '#FFFFFF'
    },
    showYear: {
      type: Boolean,
      required: false,
      default: false
    },
    showNamedVersions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    isInputActive(newVal) {
      if (newVal) {
        setTimeout(() => {
          this.$refs.versionNameInputRef?.focus?.();
        }, 100);
      }
    }
  },
  computed: {
    ...mapState(useVersionsStore, ['currentVersion', 'showPublished', 'previewVersion']),
    ...mapState(useCollabStore, ['isFlowEditor']),
    isNameMain() {
      return this.tempVersion.versionName?.length > 0;
    },
    getDateTextColour() {
      if (this.version.id === this.previewVersion?.id) {
        return '#1996f0';
      } else {
        return '#383f45';
      }
    },
    showPublishedButton() {
      if (this.showPublished || this.showNamedVersions) return false;
      if (this.version?.publishedId) return true;
      return false;
    }
  },
  methods: {
    ...mapActions(useVersionsStore, ['updateVersion', 'setPreviewVersion', 'SET_SHOW_PUBLISHED']),
    ...mapActions(useAppStore, ['RESTORE_FLOW']),
    getRestoredFrom() {
      if (this.showPublished) return '';
      const { restoredFromDateTime } = this.tempVersion;
      if (!restoredFromDateTime) return '';
      return this.parseDate(restoredFromDateTime, this.showYear);
    },
    editVersionName() {
      this.isInputActive = true;
    },
    parseDate(date, showYear) {
      return moment(date).format(showYear ? 'Y MMMM DD, h:mm a' : 'MMMM DD, h:mm a');
    },
    handleVersionName(e) {
      const {
        current: { slot, taxonomy },
        language
      } = this.appStore;
      this.isInputActive = false;
      this.tempVersion.versionName = e.target.value;
      if (this.isCurrentVersion) {
        this.currentVersion.versionName = e.target.value;
      };
      const draftOrPublished = this.showPublished ? 'published' : 'draft';

      this.updateVersion({
        taxonomy: `${taxonomy}_flows`,
        slotId: slot.id,
        language,
        versionId: this.tempVersion.id,
        versionName: e.target.value,
        draftOrPublished
      });
    },
    hideInput(e) {
      e.target.value = '';
      this.isInputActive = false;
    },

    isCurrentVersion(version) {
      return !this.showPublished && version.currentversion;
    },

    async handlePreviewVersion(version) {
      if (this.isInputActive) return;

      const {
        current: { slot, taxonomy },
        language
      } = this.appStore;

      const versionPreview = (
        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getVersion`, {
          slotId: slot.id,
          taxonomy: `${taxonomy}_flows`,
          language,
          id: version.id,
          draftOrPublished: this.showPublished ? 'published' : 'draft'
        })
      ).data;

      ElLoading.service();
      this.setPreviewVersion(version);
      this.appStore.RESTORE_FLOW({ uxState: [versionPreview] });
      // this.changeDateColour();
      ElLoading.service().close();
    },
    handleShowPublished() {
      this.SET_SHOW_PUBLISHED(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.version-info {
  padding-left: 7px;
}

.version-user-info {
  display: inline;
}

.main-text {
  padding-left: 7px;
  font-weight: bold;
}

.more-info-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;

  .published-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 18px;
    margin-top: 3px;
    padding-right: 7px;

    span {
      font-size: 12px;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: right;
      color: #1996f0;
      padding: 3px 5px 3px;
      border-radius: 4px;
      border: solid 1px #2698ed;
      background-color: #fff;
    }
  }

  .version-popup-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 18px;
  }
}

.current-version {
  padding-left: 7px;
  color: #2698ed;
  font-weight: 700;
  font-size: 12px;
}

.restored-from {
  padding-left: 7px;
  color: #444444;
  font-weight: lighter;
  font-size: 11px;
  text-wrap: none;
}

.text-published {
  font-size: 10px;
  font-weight: bold;
  color: #444444;
}

.input-shadow {
  background: white;
  box-shadow: 0 0 5px #2698ed;
  border-radius: 4px;
  min-height: 20px;
  padding-left: 5px;
}

.p-2:hover {
  cursor: pointer;
}
</style>
