import { Mark } from '@tiptap/core';

export const Highlight = Mark.create({
  name: 'highlight',

  addOptions: {
    color: 'yellow'
  },

  attributes: {
    color: {
      default: 'yellow'
    }
  },

  parseHTML() {
    return [
      {
        tag: 'h'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['h', HTMLAttributes, 0];
  },

  addCommands() {
    return {
      toggleHighlight:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark('highlight', attributes);
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-5': ({ commands }) => commands.toggleHighlight({ color: this.options.color })
    };
  }
});
