<template>
  <v-card class="v-card-container">
    <v-card-title>
      <slot name="title" />
    </v-card-title>
    <v-card-text class="bulk-card-text">
      <slot name="body" />
    </v-card-text>

    <v-card-actions class="bulk-card-actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BulkActionsModal'
};
</script>

<style lang="scss" scoped>
.bulk-actions-modal {
  height: auto;
  .vm--modal {
    overflow: visible !important;
  }
  .v-card {
    overflow: visible !important;
  }
  .v-card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .bulk-actions-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .v-btn .v-btn--icon .theme--light {
    padding-left: 0.3em;
  }
  .v-btn-cancel {
    background-color: #e4e4e4;
  }
  .v-btn-bulk {
    background-color: #1996f0;
    color: #ffffff;
    &:disabled {
      background-color: #d7dde5;
      &.v-btn--disabled {
        color: darkgrey !important;
      }
    }
  }
  .bulk-card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
}

.bulk-card-text {
  flex: auto;
}
</style>
