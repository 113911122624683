<template>
  <div class="comment_reply_container">
    <!-- <textarea ref="replyTextArea" v-model="commentText" :placeholder="placeholderText"/> -->
    <CommentMentions @addToMentionedList="addToNotifyList" :label="''" class="mentions" :clearInputFlag="clearInputFlag" @populateCommentText="populateCommentText" :users="sendUsers" ref="replyTextArea" :placeholder="placeholderText" />
    <div v-if="displayEmptyCommentError" style="color: red">A comment cannot contain ONLY spaces. Please enter a valid comment.</div>
    <div class="buttons_container">
      <button class="cancel_button" :disabled="!commentText" @click="clearInput">Cancel</button>
      <button class="send_button" :disabled="!commentText || displayEmptyCommentError" @click="saveComment">Send</button>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import axios from 'axios';
import { runInThisContext } from 'vm';
import { mapActions } from 'pinia';
import CommentMentions from './CommentMentions.vue';

export default {
  name: 'CommentReplyForm',
  props: {
    parentCommentId: {
      default: null
    },
    childComments: {
      default: null
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      commentText: '',
      userList: [],
      clearInputFlag: false,
      mentionedUsers: [],
      presentUsers: []
    };
  },
  methods: {
    ...mapActions(useCommentsStore, ['addChildComment']),
    clearInput() {
      this.clearInputFlag = true;
      this.mentionedUsers = [];
      this.commentText = '';
    },
    async getPresentUsers() {
      const response = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/comments/getPresentUsers`);
      if (response.status === 200) this.presentUsers = response.data;
    },
    saveComment() {
      this.mentionedUsers = this.mentionedUsers.filter((currEle) => {
        if (this.commentText.search(currEle.value) !== -1) {
          return true;
        }

        return false;
      });
      this.addChildComment({
        commentText: this.commentText,
        parentCommentId: this.parentCommentId,
        mentionedUsers: this.mentionedUsers,
        presentUsers: this.presentUsers,
        type: 'reply'
      });
      this.mentionedUsers = [];
      runInThisContext.presentUsers = [];
      this.clearInputFlag = true;
      this.commentText = '';
    },
    populateCommentText(value) {
      this.commentText = value;
    },

    addToNotifyList({ email, value }) {
      this.mentionedUsers.push({ email, value });
    }
  },
  computed: {
    sendUsers() {
      if (this.userList.length === 0) {
        return [];
      }

      const tempValArr = this.userList.map((currEle) => {
        return { value: '@' + currEle.displayName, email: currEle.email };
      });

      return tempValArr;
    },
    placeholderText() {
      return this.childComments.length ? 'Reply comment' : 'Respond to comment';
    },
    displayEmptyCommentError() {
      //Display error message if the comment is ONLY spaces.
      if (this.commentText?.trim().length === 0 && this.commentText.length != 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  beforeUpdate() {
    this.getPresentUsers();
  },
  mounted() {
    this.userList = this.appStore.getAllUsers;
    this.getPresentUsers();
  },
  updated() {
    this.clearInputFlag = false;
  },
  components: {
    CommentMentions
  }
};
</script>

<style lang="scss" scoped>
.comment_reply_container {
  width: 100%;
  min-height: 115px;
  display: flex;
  flex-direction: column;

  .reply-container {
    width: 100%;
    height: auto;
    padding: 0 20px 0 30px;
    position: relative;
    // margin-top: 10px;
    // margin-bottom: 10px;

    textArea {
      width: 100%;
      height: 38px;
      padding: 8px 32px 9px 10px;
      resize: none;
      border-radius: 4px;
      border: solid 1px #d7dde5;
      background-color: #fff;
      font-size: 16px;
      line-height: 1.29;
      text-align: left;
      overflow: hidden;
    }

    textArea::placeholder {
      color: #8e9ba8;
    }

    textArea:focus {
      border: solid 1px #2698ed;
    }
  }

  .no_margin {
    margin-top: 0;
    padding: 0;
  }

  .buttons_container {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-end;
    margin-top: 1%;
    padding-right: 20px;

    button {
      width: 93px;
      height: 38px;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
    }

    button:disabled {
      color: #8e9ba8;
    }

    .cancel_button {
      color: #1996f0;
    }

    .send_button {
      margin-left: 10px;
      background-color: #1996f0;
      color: #fff;
    }

    .send_button:disabled {
      background-color: #edf0f4;
    }
    .mentions {
      margin-top: 30px !important;
    }
  }
}
</style>
