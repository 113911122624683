<template>
  <div class="preview-container">
    <div class="top-wrapper">
      <div class="back-button-wrapper">
        <span class="fa fa-chevron-left back-arrow" @click="back"></span>
        <div class="back-button-text">Back to edit</div>
      </div>
    </div>
    <hr />
    <div class="body-wrapper">
      <div class="grid justify-items-stretched preview-content">
        <div style="height: 100vh">
          <iframe
            frameborder="0"
            :src="previewURL"
            height="100%"
            width="100%"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'PreviewSidebar',
  props: {
    collection: {
      type: String,
    },
    slotInfo: {},
  },
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  methods: {
    back() {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
    },
  },
  computed: {
    previewURL() {
      const url = `${
        process.env.VUE_APP_CLIENT_TESTING_BOT_ENDPOINT
      }/?chLanguage=${
        this.appStore.language != 'en' ? this.appStore.language : ''
      }&preview=true&collection=${this.collection.replace(' ', '%20')}&slotId=${
        this.slotInfo.id
      }&singleId=2`;
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-container {
  padding-top: 63px;
}
// .top-wrapper {
// }
.back-button-wrapper {
  display: flex;
  margin: 20px 0 20px 20px;
  align-items: center;
  color: #383f45;
  .back-button-text {
    font-size: 17px;
    font-weight: 800;
    margin-left: 17px;
  }
  span {
    cursor: pointer;
    font-size: 11px;
  }
}
</style>
