<template>
  <div class="carousel-canvas" :style="carouselCanvasPdf">
    <div class="carousel-canvas-header">
      <img class="carousel-canvas-icon" src="./../../../../../../assets/icons/carousel.svg" />
      <span>Carousel / Card</span>
    </div>
    <div class="row justify-content-center">
      <div class="carousel-canvas-wrapper" ref="menu" :style="carouselCanvasPdf">
        <div :class="`carousel-main-container ${carousels.length === 0 ? 'full-width-15margin' : ''}`">
          <div :class="`carousel-canvas-container ${carousels.length === 0 ? 'single-navigation' : ''} ${carousels.length === 1 ? 'double-navigation-first' : ''} ${carousels.length > 1 ? 'multiple-navigation-first' : ''} ${carousels.length === 0 && displayMode === 'pdfPreview' ? 'no-center' : ''}`">
            <div :class="`carousel-canvas-index ${carousels.length === 1 ? 'multiple-info-label' : ''}`">
              <span>1</span>
            </div>
            <div class="carousel-canvas-info-container">
              <div :class="`carousel-canvas-info-label carousel-canvas-info-content ${title ? '' : 'placeholder'} ${displayMode === 'pdfPreview' ? 'pdf-label' : ''}`" v-html="title ? title : 'Title'" />
              <div :class="`carousel-canvas-info-subtitle carousel-canvas-info-content ${subTitle ? '' : 'placeholder'} ${displayMode === 'pdfPreview' ? 'pdf-label' : ''}`" v-html="subTitle ? subTitle : 'Subtitle'" />
              <div v-if="!isValidUrl(imageUrl) && imageUrl" height="auto" :class="`carousel-canvas-info-image-unedited ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''}`">
                <span>
                  <img src="./../../../../../../assets/imageUploadCanvas.png" />
                </span>
              </div>
              <div v-if="isValidUrl(imageUrl) && imageUrl" height="auto" :class="`carousel-canvas-info-image ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''}`">
                <img :src="imageUrl" :style="imageStyling()" alt="image" width="180px" height="auto" />
              </div>
              <div v-if="cardCta" :class="`${cardCta ? 'carousel-canvas-info-button' : 'carousel-canvas-info-button-unedited'} ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''} ${displayMode === 'pdfPreview' ? 'pdf-cta' : ''}`">
                <span>{{ cardCta ? cardCta : 'CTA' }}</span>
              </div>
            </div>
          </div>
          <div v-if="references && references.length > 0" :class="`references-container ${carousels.length === 0 ? 'single-reference reference-no-margin' : ''} ${displayMode === 'pdfPreview' && carousels.length === 0 ? 'no-width' : ''}`">
            <div v-for="(ref, index) in references" :key="index" class="references-wrapper">
              <div v-if="index === 0" class="first-reference-container">
                <img class="referenceIcon" src="./../../../../../../assets/icons/reference.svg" />
                <div :class="`ref-title-container ${carousels.length > 1 ? 'multiple-nav-ref' : ''}`">
                  {{ ref }}
                </div>
              </div>
              <div v-else :class="`reference-container ${carousels.length >= 1 ? 'multiple-nav-hr' : ''}`">
                <div class="reference-wrapper">
                  <hr />
                  <div :class="`ref-title-container ${carousels.length >= 1 ? 'multiple-nav-ref' : ''}`">
                    {{ ref }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="linkTo && linkTo.type === 'flow' && taxonomy" :class="`references-container ${carousels.length === 0 ? 'single-reference reference-no-margin' : ''} ${displayMode === 'pdfPreview' && carousels.length === 0 ? 'no-width' : ''}`">
            <div v-if="taxonomy && taxonomy.name" class="agent-carousel-wrapper">
              <img class="agent-carousel-icon" src="./../../../../../../assets/icons/agents.svg" />
              <div class="agent-carousel-name-container">
                {{ taxonomy.name }}
              </div>
            </div>
          </div>
          <div v-if="linkTo && linkTo.type === 'flow' && intent" :class="`references-container ${carousels.length === 0 ? 'single-reference reference-no-margin' : ''} ${displayMode === 'pdfPreview' && carousels.length === 0 ? 'no-width' : ''}`">
            <div v-if="intent && intent.name" class="flow-carousel-wrapper">
              <img class="flow-carousel-icon" src="./../../../../../../assets/icons/flow.svg" />
              <div class="flow-carousel-name-container">
                {{ intent.name }}
              </div>
            </div>
          </div>
          <div v-if="linkTo && linkTo.type === 'url' && url" :class="`references-container ${carousels.length === 0 ? 'single-reference reference-no-margin' : ''} ${displayMode === 'pdfPreview' && carousels.length === 0 ? 'no-width' : ''}`">
            <div v-if="url" class="link-carousel-wrapper">
              <img class="link-carousel-icon" src="./../../../../../../assets/icons/linkUrl.svg" />
              <div class="link-carousel-name-container">
                {{ url }}
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-main-container" v-for="(param, index) in carousels" :key="index">
          <div :class="`carousel-canvas-container ${currentNavigationIndex === 1 && carousels.length === 1 ? 'double-navigation' : ''} ${carousels.length > 1 ? 'multiple-navigation' : ''} ${displayMode === 'pdfPreview' && carousels.length > 1 ? 'multiple-navigation-pdf' : ''} ${displayMode === 'pdfPreview' && index > 0 ? 'multiple-navigation-pdf-margin' : ''} ${index + 1 === carousels.length ? 'multiple-navigation-last' : ''}`">
            <div :class="`carousel-canvas-index`">
              <span>{{ index + 2 }}</span>
            </div>
            <div class="carousel-canvas-info-container">
              <div :class="`carousel-canvas-info-label carousel-canvas-info-content ${getFields(param.title) ? '' : 'placeholder'} ${displayMode === 'pdfPreview' ? 'pdf-label' : ''}`" v-html="getFields(param.title) === '' ? 'Title' : getFields(param.title)" />
              <div :class="`carousel-canvas-info-subtitle carousel-canvas-info-content ${getFields(param.subTitle) ? '' : 'placeholder'} ${displayMode === 'pdfPreview' ? 'pdf-label' : ''}`" v-html="getFields(param.subTitle) === '' ? 'Subtitle' : getFields(param.subTitle)" />
              <div v-if="!isValidUrl(param.imageUrl) && getFields(param.imageUrl)" :class="`carousel-canvas-info-image-unedited ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''}`">
                <span>
                  <img src="./../../../../../../assets/imageUploadCanvas.png" />
                </span>
              </div>
              <div v-if="isValidUrl(param.imageUrl) && getFields(param.imageUrl)" :class="`carousel-canvas-info-image ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''}`">
                <img :src="getFields(param.imageUrl)" :style="imageStyling(param)" alt="image" width="180px" />
              </div>
              <div v-if="getFields(param.cardCta)" :class="`${getFields(param.cardCta) ? 'carousel-canvas-info-button' : 'carousel-canvas-info-button-unedited'} ${carousels.length > 0 ? 'multiple-navigation-image-cta' : ''} ${displayMode === 'pdfPreview' ? 'pdf-cta' : ''}`">
                <span>{{ getFields(param.cardCta) !== '' ? getFields(param.cardCta) : 'CTA' }}</span>
              </div>
            </div>
          </div>

          <div v-if="getFields(param.reference) && getFields(param.reference).length > 0" :class="referencesStyling()">
            <div v-for="(ref, index) in getFields(param.reference)" :key="index" class="references-wrapper">
              <div v-if="index === 0" class="first-reference-container">
                <img class="referenceIcon" src="./../../../../../../assets/icons/reference.svg" />
                <div class="ref-title-container multiple-nav-ref">
                  {{ ref }}
                </div>
              </div>
              <div v-else :class="`reference-container ${carousels.length >= 1 ? 'multiple-nav-hr' : ''}`">
                <div class="reference-wrapper">
                  <hr />
                  <div class="ref-title-container multiple-nav-ref">
                    {{ ref }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="param.linkTo && param.linkTo.type === 'flow' && param.taxonomy && param.taxonomy.name" :class="referencesStyling()">
            <div v-if="param.taxonomy && param.taxonomy.name" class="agent-carousel-wrapper">
              <img class="agent-carousel-icon" src="./../../../../../../assets/icons/agents.svg" />
              <div class="agent-carousel-name-container">
                {{ param.taxonomy.name }}
              </div>
            </div>
          </div>
          <div v-if="param.linkTo && param.linkTo.type === 'flow' && param.intent && param.intent.name" :class="referencesStyling()">
            <div v-if="param.intent && param.intent.name" class="flow-carousel-wrapper">
              <img class="flow-carousel-icon" src="./../../../../../../assets/icons/flow.svg" />
              <div class="flow-carousel-name-container">
                {{ param.intent.name }}
              </div>
            </div>
          </div>
          <div v-if="param.linkTo && param.linkTo.type === 'url' && param.url && param.url !== ''" :class="referencesStyling()">
            <div v-if="param.url" class="link-carousel-wrapper">
              <img class="link-carousel-icon" src="./../../../../../../assets/icons/linkUrl.svg" />
              <div class="link-carousel-name-container">
                {{ linkUrl(param) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center carousel-switcher-wrapper">
        <div class="col-md-10 carousel-switcher-container">
          <div v-if="carousels.length > 0" class="carousel-pagination">
            <div v-if="displayMode !== 'pdfPreview'" :class="`carousel-canvas-switcher ${(currentNavigationIndex === 0 || currentNavigationIndex === -1) && displayMode !== 'pdfPreview' && 'carousel-canvas-switch-selected'}`" @click="directionDecider(id)" />
            <!-- eslint-disable-next-line -->
            <template v-if="displayMode !== 'pdfPreview'">
              <div :class="`carousel-canvas-switcher ${currentNavigationIndex === index + 1 && displayMode !== 'pdfPreview' && 'carousel-canvas-switch-selected'}`" v-for="(param, index) in carousels" :key="index" @click="directionDecider(param.id)" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
import { mapState } from 'pinia';
export default {
  name: 'CarouselComponent',
  mixins: [essentials],
  props: {},
  mounted() {
    if (this.currentNavigationIndex === 0 && this.component) {
      if (this.component.mainCarouselId && this.component.mainCarouselId !== '') {
        this.appStore.updateComponent({
          id: this.component.mainCarouselId,
          selectedCarouselCard: ''
        });
      } else {
        this.appStore.updateComponent({
          id: this.component.id,
          selectedCarouselCard: ''
        });
      }
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      scrollAmount: 0,
      componentId: this.response['id'],
      currentNavigationIndex: 0
    };
  },
  watch: {
    selectedCarouselCard() {
      if (this.appStore.activeContainer === this.parentId) {
        if (this.selectedCarouselCard && this.selectedCarouselCard !== '') {
          this.directionDecider(this.selectedCarouselCard);
        }
      }
    }
  },
  computed: {
    ...mapState(useAppStore, ['displayMode']),
    carouselCanvasPdf() {
      if (this.displayMode === 'pdfPreview') {
        return {
          overflow: 'unset',
          width: '100%',
          flexFlow: 'wrap'
        };
      } else {
        return '';
      }
    },
    component: {
      get() {
        return this.response;
      }
    },
    selectedCarouselCard: {
      get() {
        return this.response['selectedCarouselCard'];
      }
    },
    id: {
      get() {
        return this.response['id'];
      }
    },
    parentId: {
      get() {
        return this.response['parentId'];
      }
    },
    title: {
      get() {
        return this.response['title'];
      }
    },
    taxonomy: {
      get() {
        return this.response['taxonomy'];
      }
    },
    intent: {
      get() {
        return this.response['intent'];
      }
    },
    subTitle: {
      get() {
        return this.response['subTitle'];
      }
    },
    url: {
      get() {
        return this.linkUrl(this.response);
      }
    },
    linkTo: {
      get() {
        return this.response['linkTo'];
      }
    },
    cardCta: {
      get() {
        return this.response['cardCta'];
      }
    },
    imageUrl: {
      get() {
        return this.response['imageUrl'];
      }
    },
    imageWidth: {
      get() {
        return this.response['imageWidth'];
      }
    },
    imageHeight: {
      get() {
        return this.response['imageHeight'];
      }
    },
    imageSizeSelection: {
      get() {
        return this.response['imageSizeSelection'];
      }
    },
    references: {
      get() {
        return this.response.reference.slice().reverse();
      }
    },
    carousels: {
      get() {
        const carousels = this.response['carousels'];
        const index = carousels.findIndex((x) => x.main === true);
        if (index > -1) {
          carousels.splice(index, 1);
        }
        return carousels;
      }
    }
  },
  methods: {
    referencesStyling() {
      let className = 'references-container ';
      if (this.carousels.length === 1 && this.currentNavigationIndex === 0) {
        className += 'reference-no-margin-multiple';
      } else if (this.carousels.length === 1 && this.currentNavigationIndex === 1) {
        className += 'reference-width';
      } else if (this.carousels.length > 0 && this.displayMode !== 'pdfPreview') {
        className += 'multiple-navigation';
      } else if (this.carousels.length > 0 && this.displayMode === 'pdfPreview') {
        className += '';
      }
      return className;
    },
    linkUrl(param) {
      const baseUrl = param['url'];

      if (param.viewType === 'basic') {
        return baseUrl;
      }

      if (param.advancedViewType === 'subpath' && param.subpath && param.subpath !== '') {
        if (param.queryParameters) {
          delete param.queryParameters;
        }
        return baseUrl + '/' + param['subpath'];
      }

      if (param?.queryParameters && baseUrl) {
        try {
          const url = new URL(baseUrl);

          param.queryParameters.forEach((queryParameter) => {
            if (queryParameter.name && queryParameter.value && queryParameter.name != '' && queryParameter.value != '') {
              url?.searchParams.append(queryParameter.name, queryParameter.value);
            }
          });

          return url.href;
        } catch (e) {
          const queryParameters = [];
          param.queryParameters.forEach((queryParameter) => {
            if (queryParameter.name && queryParameter.value && queryParameter.name != '' && queryParameter.value != '') {
              const queryParam = [queryParameter.name, queryParameter.value].join('=');
              queryParameters.push(queryParam);
            }
          });

          if (queryParameters.length > 0) {
            const subPath = queryParameters.join('&');
            return baseUrl + `?${subPath}`;
          } else {
            return baseUrl;
          }
        }
      }
      return baseUrl;
    },
    imageStyling(param) {
      const imageWidth = param ? this.getFields(param.imageWidth) : this.imageWidth;
      const imageHeight = param ? this.getFields(param.imageHeight) : this.imageHeight;
      const imageSizeSelection = param ? this.getFields(param.imageSizeSelection) : this.imageSizeSelection;
      return {
        width: imageWidth && imageSizeSelection === 'custom' ? imageWidth + 'px' : '',
        height: imageHeight && imageSizeSelection === 'custom' ? imageHeight + 'px' : ''
      };
    },
    isValidUrl(imageUrl) {
      let url;

      try {
        url = new URL(imageUrl);
      } catch (_) {
        return false;
      }

      return url.protocol === 'http:' || url.protocol === 'https:' || url.protocol === 'data:';
    },
    directionDecider(idToGo) {
      this.appStore.setComponentId(this.id);
      const carouselArray = [this.id].concat(
        this.carousels.map(function (a) {
          return a.id;
        })
      ); //This is so we can keep track of which direction we are going
      const indexToGo = carouselArray.indexOf(idToGo);
      let scrollMultiplier = 1;
      if (this.component.mainCarouselId && this.component.mainCarouselId !== '') {
        this.appStore.updateComponent({
          id: this.component.mainCarouselId,
          selectedCarouselCard: idToGo
        });
      } else {
        this.appStore.updateComponent({
          id: this.component.id,
          selectedCarouselCard: idToGo
        });
      }
      let isLast = false,
        isFirst = false;
      if (indexToGo === carouselArray.length - 1) {
        isLast = true;
      }
      if (indexToGo === 0) {
        isFirst = true;
      }
      if (indexToGo > this.currentNavigationIndex) {
        // scroll right
        if (indexToGo - this.currentNavigationIndex !== 1) {
          // means we are skipping carousels
          scrollMultiplier = indexToGo - this.currentNavigationIndex;
        }
        this.scrollRight(indexToGo, idToGo, scrollMultiplier, isLast);
      } else if (indexToGo < this.currentNavigationIndex) {
        if (this.currentNavigationIndex - indexToGo !== 1) {
          // means we are skipping carousels
          scrollMultiplier = this.currentNavigationIndex - indexToGo;
        }
        this.scrollLeft(indexToGo, idToGo, scrollMultiplier, isFirst);
      }
    },
    getFields(obj) {
      return obj || '';
    },
    scrollLeft(indexToGo, idToGo, scrollMultiplier, isFirst) {
      const scroller = this.$el.querySelector('.carousel-canvas-wrapper');
      const itemWidth = this.$el.querySelector('.carousel-canvas-container').clientWidth;
      this.componentId = idToGo;
      this.currentNavigationIndex = indexToGo;

      if (scroller.scrollLeft != 0)
        if (isFirst) {
          scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } else {
          scroller.scrollBy({
            left: -itemWidth * scrollMultiplier,
            top: 0,
            behavior: 'smooth'
          });
        }
      else
        scroller.scrollTo({
          left: scroller.scrollWidth,
          top: 0,
          behavior: 'smooth'
        });
    },

    scrollRight(indexToGo, idToGo, scrollMultiplier, isLast) {
      const scroller = this.$el.querySelector('.carousel-canvas-wrapper');
      const itemWidth = this.$el.querySelector('.carousel-canvas-container').clientWidth;
      this.componentId = idToGo;
      this.currentNavigationIndex = indexToGo;
      if (scroller.scrollLeft < scroller.scrollWidth - itemWidth * scrollMultiplier)
        if (isLast) {
          scroller.scrollBy({
            left: scroller.scrollWidth,
            top: 0,
            behavior: 'smooth'
          });
        } else {
          scroller.scrollBy({
            left: itemWidth * scrollMultiplier,
            top: 0,
            behavior: 'smooth'
          });
        }
      else scroller.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Carousel.scss';
html {
  scroll-behavior: smooth;
}
.placeholder {
  color: #8e9ba8;
}
.carousel-canvas-container {
  border: solid 1px #d7dde5;
  margin-right: 10px;
  display: flex;
  scroll-snap-align: center;
  justify-content: center;
}

.carousel-canvas-wrapper {
  display: flex;
  overflow: hidden scroll;
  scroll-snap-type: x mandatory;
}

.carousel-canvas-index {
  display: flex;
  justify-content: center;
  margin-left: 15px;
  margin-top: 20px;
  width: 18px;
  height: 18px;
  padding: 0 6px 2px;
  border-radius: 3px;
  background-color: #1996f0;
  span {
    color: white;
    font-weight: 800;
  }
}
.carousel-canvas-info-container {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
}

.carousel-canvas-info-content {
  background-color: #ffffff;
}
.carousel-canvas-info-label {
  font-weight: 800;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  width: 195px;
}
.carousel-canvas-info-title {
  text-align: left;
  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.carousel-switcher-wrapper {
  width: 300px;
}
.carousel-canvas-info-subtitle {
  text-align: left;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 195px;
}
.carousel-canvas-info-image-unedited {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 120px;
  background-color: #edf0f4;
  margin-bottom: 10px;
  span {
    img {
      width: 49px;
      height: 46px;
    }
  }
}
.carousel-canvas-info-image {
  background-size: cover;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  img {
    width: 180px;
    height: auto;
  }
}
.carousel-canvas-info-button-unedited {
  width: 230px;
  background-color: #edf0f4;
  height: 40px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  span {
    font-size: 17px;
    color: #8e9ba8;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.carousel-canvas-info-button {
  width: 230px;
  height: 40px;
  border-radius: 4px;
  border: solid 2px #2698ed;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  span {
    font-size: 17px;
    color: #1996f0;
    width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.carousel-pagination {
  display: flex;
  justify-content: center;
}
.carousel-switcher-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}
.carousel-canvas-switcher {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #d7dde5;
  margin-right: 10px;
  cursor: pointer;
  margin-top: 5px;
}
.carousel-canvas-switch-selected {
  background-color: #1996f0;
}
.double-navigation {
  margin-right: 10px;
}
.multiple-navigation {
  width: 240px !important;
  margin-left: 15px;
}
.multiple-navigation-pdf {
  width: 260px !important;
  margin-left: 15px;
}
.multiple-navigation-pdf-margin {
  margin-top: 15px;
}
.multiple-navigation-last {
  margin-right: 20px !important;
}
.double-navigation-first {
  width: 260px;
  margin-right: 10px;
  margin-left: 15px;
}
.multiple-navigation-first {
  width: 260px;
  margin-left: 15px;
  margin-right: 10px;
}
.multiple-navigation-image-cta {
  width: 190px;
  img {
    max-width: 100%;
    max-height: 100%;
    width: 180px;
    height: auto;
  }
  span {
    width: 160px;
  }
}
.single-navigation {
  margin-left: 2px;
}
.multiple-navigation-index {
  margin-left: 15px;
}
.multiple-info-label {
  margin-left: 15px;
}
.references-container {
  border: solid 1px #d7dde5;
  border-top: none;
  width: 260px;
  margin-right: 10px;
  margin-left: 15px;
}
.reference-container {
  width: 100%;
  hr {
    border: solid 1px #d7dde5;
    width: 220px;
    margin-left: 22px;
  }
}
.reference-wrapper {
  width: 100%;
}
.references-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-left: 28px;
}
.first-reference-container {
  display: flex;
  width: 100%;
  align-items: center;
  .ref-title-container {
    color: #383f45;
    font-size: 14px;
    display: block;
    word-wrap: break-word;
    margin-left: 10px;
  }
}
.ref-title-container {
  text-align: left;
  color: #383f45;
  font-size: 14px;
  display: block;
  width: 220px;
  word-wrap: break-word;
  margin-left: 21px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.message-container-title {
  text-align: left;
  margin-left: 5px;
  padding: 4px 0px 8px 0px;
  font-weight: 900;
  font-size: 14px;
}
.referenceIcon {
  width: 11px;
}
.carousel-main-container {
  width: 298px;
}
.reference-no-margin {
  margin-left: 2px !important;
}
.reference-no-margin-multiple {
  margin-left: 0 !important;
}
.reference-width {
  width: 255px !important;
  margin-left: 0 !important;
}
.single-reference {
  width: auto;
}
.multiple-nav-ref {
  width: 180px;
}
.multiple-nav-hr {
  hr {
    width: 180px;
  }
}
.agent-carousel-wrapper {
  display: flex;
  align-items: center;
  margin-left: 28px;
}
.agent-carousel-icon {
  width: 11px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.agent-carousel-name-container {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 2px;
  width: 173px;
  word-wrap: break-word;
  text-align: left;
}
.flow-carousel-wrapper {
  display: flex;
  align-items: center;
  margin-left: 28px;
}
.flow-carousel-icon {
  width: 11px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.flow-carousel-name-container {
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 2px;
  width: 173px;
  word-wrap: break-word;
  text-align: left;
}
.link-carousel-wrapper {
  display: flex;
  align-items: center;
  margin-left: 28px;
}
.link-carousel-icon {
  width: 11px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.link-carousel-name-container {
  width: 173px;
  word-wrap: break-word;
  text-align: left;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 2px;
  color: #1996f0;
  font-style: italic;
}
.carousel-canvas {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 298px;
}
.carousel-canvas-header {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 14px;
  margin-top: 10px;
  span {
    margin-left: 10px;
    font-weight: bold;
    color: #383f45;
  }
}
.carousel-canvas-icon {
  width: 19px;
}
.no-width {
  width: unset !important;
}
.full-width-15margin {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}
.no-center {
  justify-content: unset;
}
.pdf-label {
  overflow: unset !important;
  white-space: unset !important;
  word-break: break-all;
}
.pdf-cta {
  height: inherit;
  min-height: 40px;
  span {
    width: 195px;
    overflow: unset !important;
    white-space: unset !important;
    word-break: break-all;
  }
}
</style>
