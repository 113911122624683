// Update the width and height of nodes based on their container dimensions and zoom level
const updateNodesHeightAndWidth = (nodes, zoomLevel) => {
  const currentNodes = nodes;

  const updatedNodes = currentNodes.map((node) => {
    // Get the dimensions of the container element for the node
    const dimensions = getContainerWidthAndHeight(node.id);

    // Update the node's width and height based on the dimensions and zoom level
    return {
      ...node,
      width: dimensionsAt100PercentZoom(dimensions.width, zoomLevel),
      height: dimensionsAt100PercentZoom(dimensions.height, zoomLevel)
    };
  });
  return updatedNodes;
};

// Get the width and height of a container element by its ID
const getContainerWidthAndHeight = (containerId) => {
  // Get the container element from the DOM
  const containerElement = document.getElementById(containerId);

  // Return default values if the element is not found
  if (!containerElement) {
    return { width: 0, height: 0 }; // Return default values or handle as necessary
  }

  // Get the bounding rectangle of the container element
  const containerDimensions = containerElement.getBoundingClientRect();

  // Return the width and height of the container element
  return {
    width: containerDimensions.width,
    height: containerDimensions.height
  };
};

// Compensate for changes in container size due to zoom level
const dimensionsAt100PercentZoom = (dimension, zoomLevel) => {
  return dimension / (zoomLevel / 100);
};

// Check if any nodes overlap
const nodesOverlap = (nodes) => {
  // Helper function to check if two rectangles overlap
  const doRectanglesOverlap = (rect1, rect2) => {
    return rect1.x < rect2.x + rect2.width && rect1.x + rect1.width > rect2.x && rect1.y < rect2.y + rect2.height && rect1.y + rect1.height > rect2.y;
  };

  // Iterate through each pair of nodes and check for overlap
  for (let i = 0; i < nodes.length; i++) {
    for (let j = i + 1; j < nodes.length; j++) {
      if (doRectanglesOverlap(nodes[i], nodes[j])) {
        return true; // Overlapping nodes found
      }
    }
  }
  return false; // No overlapping nodes found
};

export { updateNodesHeightAndWidth, nodesOverlap };
