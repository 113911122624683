<template>
  <div class="background" style="width: 100%">
    <PageHeader :showBreadcrumbs="false" title="Transfer" />

    <AppTimeout v-if="timeoutShow" />
    <br />
    <br />
    <div style="width: 100%; background-color: white; border-radius: 15px; padding: 15px; margin: auto; margin-top: 20px">
      <div class="wrap1" style="padding: 25px; height: 90vh; background: white; overflow-y: auto">
        <div class="grid justify-items-center">
          <div class="centered">
            <tr>
              <!-- <vs-button style="color: white; background-color: black !important; border-radius: 5px; font-size: 14px">COPY CURRENT ENVIRONMENT TOKEN</vs-button> -->
              <br />
              <td class="column lefty">
                <span class="vs-list--title"> SOURCE </span><br />
                <br />
                <br />
                <el-form label-position="left" ref="form" label-width="50px">
                  <el-form-item>
                    <template #label> <i class="fas fa-dharmachakra"></i></template>
                    <el-select @change="resetVerified" filterable placeholder="Select Agent" v-model="collection" popper-class="el-select-dropdown">
                      <el-option :key="item.name" :value="item.name" :label="item.name" v-for="item in appStore.taxonomies"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item>
                    <template #label> <i class="fas fa-link"></i></template>
                    <el-input @input="resetVerified" size="default" placeholder="ENTER URL OF SOURCE ENVIRONMENT" v-model="source.url">
                      <template #prepend> https:// </template>
                    </el-input>
                  </el-form-item>
                  <!-- <el-form-item label="PROJECT ID">
                  <el-input size="default" placeholder="ENTER URL OF SOURCE ENVIRONMENT" v-model="source.projectId">
                    <template #prepend> https:// </template>
                  </el-input>
                </el-form-item> -->
                  <el-form-item label="API KEY">
                    <template #label>
                      <i class="fas fa-key"></i>
                    </template>

                    <el-input class="custom" size="default" placeholder="ENTER SECRET API KEY" :model-value="idToken"> </el-input>
                  </el-form-item>
                </el-form>

                <!-- <hr /> -->
                <!-- <i style="font-size: 50px; transform: rotate(90deg)" class="fas fa-exchange-alt"></i><br /> -->
              </td>

              <td class="column">
                <span class="vs-list--title"> TARGET </span>
                <br />
                <br />
                <br />
                <el-form label-position="left" ref="form" label-width="50px">
                  <!-- <el-form-item label="PROJECT ID">
                  <el-input size="default" placeholder="ENTER PROJECT ID" v-model="target.projectId">
                    <template #prepend> https:// </template>
                  </el-input>
                </el-form-item> -->
                  <el-form-item>
                    <template #label> <i class="fas fa-dharmachakra"></i></template>

                    <el-input @change="resetVerified" class="custom" size="default" placeholder="ENTER NAME OF NEW AGENT" v-model.trim="target.agent"> </el-input>
                  </el-form-item>
                  <el-form-item label="URL">
                    <template #label> <i class="fas fa-link"></i></template>

                    <el-input @input="resetVerified" size="default" placeholder="ENTER URL OF TARGET ENVIRONMENT" v-model="target.url">
                      <template #prepend> https:// </template>
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <template #label>
                      <i class="fas fa-key"></i>
                    </template>
                    <el-input @input="resetVerified" class="custom" size="default" placeholder="ENTER SECRET API KEY" v-model="target.token"> </el-input>
                  </el-form-item>
                </el-form>
              </td>
              <br />

              <!-- <el-transfer
              :props="{
                key: 'id',
                label: 'name'
              }"
              :titles="['Source', 'Target']"
              filterable
              v-model="value"
              :data="slots"
            >
            </el-transfer> -->
            </tr>
            <div class="grid justify-items-center">
              <br />
              <el-checkbox-group v-model="transferTypes">
                <el-checkbox disabled label="SLOTS"> </el-checkbox>
                <el-checkbox disabled v-for="(type, status) in transferSettings" :label="status" :key="type">{{ status }}</el-checkbox>
                <el-checkbox label="CONVERSATIONS">CONVERSATIONS</el-checkbox>
              </el-checkbox-group>
            </div>
            <div style="">
              <tr class="veriRow">
                <td v-if="collection && source.url" class="verify">VERIFY SOURCE</td>
                <td v-if="collection && source.url" class="verified">
                  <i v-if="source.verified" @click="verifySource" :class="{ complete: this.source.verified }" class="fas fa-check-circle"></i>
                  <i v-else @click="verifySource" :class="{ failed: !this.source.verified }" class="fas fa-times-circle"></i>
                </td>
                <td v-if="target.agent && target.url" class="verify">VERIFY TARGET</td>
                <td v-if="target.agent && target.url" class="verified">
                  <i v-if="target.verified" @click="verifyTarget" :class="{ complete: this.target.verified }" class="fas fa-check-circle"></i>
                  <i v-else @click="verifyTarget" :class="{ failed: !this.target.verified }" class="fas fa-times-circle"></i>
                </td>
                <td v-if="target.token" class="verify">VERIFY KEYS</td>
                <td v-if="target.token" class="verified">
                  <i v-if="target.tokenVerified" @click="verifyKey" :class="{ complete: this.target.tokenVerified }" class="fas fa-check-circle"></i>
                  <i v-else @click="verifyKey" :class="{ failed: !this.target.tokenVerified }" class="fas fa-times-circle"></i>
                </td>
              </tr>
            </div>
            <br />
            <vs-button :disabled="allVerified" @click="submit" style="color: white; background-color: #fb5422 !important; border-radius: 5px; font-size: 14px; font-weight: bold"><i class="fas fa-fighter-jet"></i> TRANSFER</vs-button>
            <div class="grid justify-items-center">
              <br />
              <span class="uploadStatus">{{ uploadStatus }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import AppTimeout from '../components/Utilities/AppTimeout';
import _ from 'lodash';
import axios from 'axios';

export default {
  name: 'AgentTransfer',
  components: {
    AppTimeout
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      timeoutShow: false,
      uploadStatus: '',
      timeoutVar: '',
      collection: '',
      source: {
        url: '',
        projectId: '',
        token: '',
        verified: 'NONE',
        tokenVerified: 'NONE',
        agent: ''
      },
      target: {
        url: '',
        projectId: '',
        token: '',
        agent: '',
        verified: 'NONE',
        tokenVerified: 'NONE'
      },
      transferTypes: ['SLOTS', 'ENTITIES', 'UTTERANCES'],
      transferSettings: {
        ENTITIES: true,
        UTTERANCES: true
      }
    };
  },
  mounted() {
    // this.appStore.getTaxonomies();
    this.source.token = this.appStore.getClaims.token;
    this.source.url = process.env.VUE_APP_INTEGRATIONS_ENDPOINT.replace(/https?:\/\//, '');
    this.timeoutFunction();
  },
  watch: {
    collection: function () {
      this.appStore.setSlots({ collection: this.collection });
    }
  },
  methods: {
    resetAll() {
      this.source.verified = false;
      this.target.verified = false;
      this.source.tokenVerified = false;
      this.target.tokenVerified = false;
      this.target.token = '';
      this.source.token = '';
    },
    async verifyKey() {
      try {
        const r = await axios.post(`${this.convertUrl(this.target.url)}/auth/verifyAdmin`, {
          idToken: this.target.token
        });
        if (r.status === 200) {
          this.target.tokenVerified = true;
        }
      } catch (err) {
        this.target.tokenVerified = false;
        console.error('Error: ', err.message, err);
      }
    },
    async verifySource() {
      try {
        const sourceEndpoint = process.env.NODE_ENV === 'production' ? `https://${this.source.url}/transfer/verify` : `http://${this.source.url}/transfer/verify`;
        const r = await axios.post(sourceEndpoint, {
          collection: this.collection,
          idToken: this.idToken,
          exists: false
        });
        if (r.status === 200 && r.data.exists) {
          this.source.verified = true;
          this.source.tokenVerified = true;
        } else {
          this.source.verified = false;
          this.source.tokenVerified = false;
        }
      } catch (err) {
        this.source.verified = false;
        console.error('Error: ', err.message, err);
      }
    },
    async verifyTarget() {
      try {
        const r = await axios.post(`${this.convertUrl(this.target.url)}/transfer/verify`, {
          collection: this.target.agent.toUpperCase().trim(),
          idToken: this.target.token
        });
        if (r.status === 200 && !r.data.exists) {
          this.target.verified = true;
          this.target.tokenVerified = true;
        } else {
          if (r.data.exists)
            this.$message({
              message: `${this.target.agent.toUpperCase().trim()} already exists in target`,
              duration: 0,
              showClose: true
            });
          this.target.verified = false;
          this.target.tokenVerified = false;
        }
      } catch (err) {
        this.target.verified = false;
        this.target.tokenVerified = false;
        console.error('Error: ', err.message, err);
      }
    },
    async submit() {
      try {
        this.$vs.loading({
          text: 'PLEASE WAIT WHILE WE TRANSFER YOUR AGENT....'
        });
        const email = this.appStore.getUser.email;
        const endpoint = process.env.NODE_ENV === 'production' ? `https://${this.source.url}/transfer/agent/` : `http://${this.source.url}/transfer/agent/`;
        const r = await axios.post(endpoint, {
          OLD_COLLECTION: this.collection,
          NEW_COLLECTION: this.target.agent.toUpperCase().trim(),
          SOURCE_ENDPOINT: this.convertUrl(this.source.url),
          TARGET_ENDPOINT: this.convertUrl(this.target.url),
          idToken: this.idToken,
          targetToken: this.target.token,
          transferTypes: this.transferTypes,
          email
        });
        if (!r.status === 200) {
          this.uploadStatus = 'ERROR OCCURED DURING TRANSFER....';
          this.$vs.loading.close();
          this.$message({
            message: `An error occurred while transferring the agent`,
            duration: 0,
            showClose: true
          });
        } else {
          this.$message({
            message: `Transfer complete. New agent is ready`,
            duration: 0,
            showClose: true
          });
          this.$vs.loading.close();
          this.appStore.getTaxonomies();
          this.resetAll();
        }
      } catch (err) {
        this.$vs.loading.close();
        this.$message({
          message: 'Some error occurred during the transfer.',
          duration: 0,
          showClose: true
        });
        console.error('Error: ', err.message, err);
      }
    },
    convertUrl(url) {
      return process.env.NODE_ENV === 'production' ? 'https://' + url.replace('cmp-dot', 'cmp-server-dot').replace('cmp-ux-dot', 'cmp-server-ux-dot') : 'http://' + url;
    },
    resetVerified() {
      this.source.verified = false;
      this.target.verified = false;
      this.resetVerifiedPost();
    },
    resetVerifiedPost: _.debounce(function () {
      try {
        this.verifySource();
        if (this.target.token && this.target.agent) this.verifyTarget();
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }, 500),
    timeoutFunction() {
      if (this.timeoutVar != null) {
        clearTimeout(this.timeoutVar);
      }
      this.timeoutVar = setTimeout(() => {
        this.timeoutShow = true;
      }, this.timeToExpiry - 300000);
    }
  },
  filters: {
    truncate: function (text, length, suffix) {
      return text.substring(0, length) + suffix;
    }
  },
  computed: {
    allVerified() {
      if (this.source.tokenVerified === true && this.target.tokenVerified === true && this.source.verified === true && this.target.verified === true) return false;
      else return true;
    },
    idToken() {
      return this.appStore.getClaims.token;
    },
    slots() {
      return this.appStore.getSlots;
    },
    timeToExpiry() {
      return this.appStore.getExpirationTime.date - Date.now();
    }
  }
};
</script>

<style lang="scss" scoped>
.centered {
  text-align: -webkit-center;
}
:deep(.el-form-item__label) {
  align-items: center;
}
.uploadStatus {
  color: blue;
  font-size: 16px;
}

.wrap1::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.wrap1 {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fas.fa-check-circle {
  font-size: 18px;
  color: rgb(18, 146, 250);
  cursor: grab;
}

.fas.fa-check-circle.complete {
  color: green;
}

.fas.fa-check-circle.failed {
  color: red;
}

.fas.fa-times-circle.failed {
  color: red;
}

.veriRow {
  text-align: left;
}

.verify {
  padding: 15px 0px 15px 35px;
  width: 160px;
}

.verified {
  padding: 15px 10px 15px -10px;
  width: 50px;
  font-size: 15px;
}

.column {
  padding: 15px 35px 15px 35px;
  width: 500px;
}

.column.lefty {
  border-right: 2px solid lightgrey;
}

.con-select-example {
  width: 360px;
}

.tokenInput {
  width: 200px;
}

.el-input--medium .el-input__inner,
.el-input__inner,
input.el-input__inner {
  border-radius: 4px !important;
}

.el-select {
  width: 100% !important;
}

input.idToken {
  border-radius: 2px !important;
  border: 1px solid #fb5422;
  padding: 10px;
}
</style>
