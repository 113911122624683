<template>
  <div :class="`notification-container ${hover || isOptionsOpen ? 'notification-active' : ''}`" @mouseenter="hover = true" @mouseleave="hover = false" @click="goToComment">
    <div class="notification-picture">
      <img :src="getNotificationImage()" alt="" />
    </div>
    <div class="notification-content">
      <div class="notification-content-title">
        <h2>{{ getNotificationMessage() }}</h2>
        <div v-if="hover || isOptionsOpen" @click.stop="toggleOptions" v-click-outside="closeOptions" class="notification-options">
          <IconUtility :key="hover" class="add-button" :width="18" :name="`ellipsis${isOptionsOpen ? '_blue' : ''}`" :custom-style="iconContainerStyle" />
        </div>
        <div class="notification-unread" v-if="!hover && !isOptionsOpen && !notification.isRead"></div>
        <div class="options-container" v-if="isOptionsOpen">
          <div class="option" @click.stop="handleClick('read')">
            <IconUtility :width="14" name="read" />
            <span>Mark as read</span>
          </div>
          <div class="option" @click.stop="handleClick('delete')">
            <IconUtility :width="14" name="delete" />
            <span>Delete</span>
          </div>
        </div>
      </div>
      <p v-if="notificationType === 'direct'">{{ notification.commentText }}</p>
      <div class="notification-info">
        <span>{{ notificationAge }}</span>
        <div class="notification-flow">
          <IconUtility name="notification_flow" :width="20" :custom-style="{ 'margin-right': '10px' }" />
          <b>{{ notification.flowName }}</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import vClickOutside from 'click-outside-vue3';
import { mapActions } from 'pinia';
import IconUtility from '@/components/Utilities/IconUtility.vue';

export default {
  name: 'NotificationDrawerList',
  components: {
    IconUtility
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    notification: {
      required: true,
      type: Object
    },
    notificationType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      hover: false,
      isOptionsOpen: false,
      notificationAge: '',
      iconContainerStyle: {
        width: '30px',
        height: '30px',
        'background-color': '#fff',
        'border-radius': '15px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        cursor: 'pointer'
      },
      loadNotificationAgeInterval: null
    };
  },
  mounted() {
    this.getNotificationAge();
    this.loadNotificationAgeInterval = setInterval(() => this.getNotificationAge(), 10000);
  },
  methods: {
    ...mapActions(useNotificationsStore, ['setNotificationReadAsync', 'deleteNotificationAsync']),
    toggleOptions() {
      this.isOptionsOpen = !this.isOptionsOpen;
    },
    toggleOptionsOpen(event) {
      event.preventDefault();
      event.stopPropagation();
      this.isOptionsOpen = !this.isOptionsOpen;
    },
    async goToComment() {
      this.$vs.loading();
      await this.setNotificationReadAsync(this.notification.id);

      const slots = await this.appStore.getAllFlows({
        collection: this.notification.taxonomy,
        dropdown: false
      });

      const notificationSlot = slots.find((slot) => slot.id === this.notification.flowId);

      if (!notificationSlot) {
        return alert('No slot/flow found for the notification.');
      }
      const flowPath = `/conversationBuilder/${this.notification.taxonomy}/${this.notification.flowId}/${this.notification.flowName}`;
      const queryParams = {
        commentId: this.notification.id
      };
      if (this.notification.language) queryParams.lang = this.notification.language;

      if (this.notification.notificationType === 'direct') {
        this.$router.push({ path: flowPath, query: queryParams });
      } else {
        this.$router.push({ path: flowPath });
      }
      this.$vs.loading.close();
    },
    closeOptions() {
      this.isOptionsOpen = false;
    },
    async handleClick(action) {
      if (action === 'read') {
        await this.setNotificationReadAsync(this.notification.id);
      }
      if (action === 'delete') {
        await this.deleteNotificationAsync(this.notification.id);
      }
      this.closeOptions();
    },
    getNotificationAge() {
      this.notificationAge = this.notificationType === 'direct' ? this.$dayjs(`${this.notification.createdAt}`).fromNow() : this.$dayjs(`${this.notification.publishedAt}`).fromNow();
    },
    getNotificationImage() {
      return this.notificationType === 'direct' ? this.notification.userPhoto : this.notification.publishedByPhotoURL;
    },
    getNotificationMessage() {
      return this.notificationType === 'direct' ? `${this.notification.userName} mentioned you in a comment` : `${this.notification.publishedByDisplayName} published a flow`;
    }
  },
  beforeUnmount() {
    clearInterval(this.loadNotificationAgeInterval);
  }
};
</script>

<style lang="scss" scoped>
.notification-active {
  background-color: #edf0f4;

  .notification-content {
    p {
      color: #1996f0 !important;
      text-decoration: underline;
    }
  }
}

.notification-container {
  width: 100%;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;

  .notification-picture {
    width: 30px;
    height: 100%;
    margin-right: 10px;

    img {
      width: 30px;
      height: 30px;
      background-color: gray;
      border-radius: 15px;
      object-fit: cover;
    }
  }

  .notification-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .notification-content-title {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 10px;

      .notification-unread {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #fd5422;
      }

      h2 {
        font-size: 17px;
        line-height: 1.29;
        text-align: left;
        color: #383f45;
        margin: 0;
        margin-right: auto;
      }

      .options-container {
        width: 141px;
        height: 60px;
        border-radius: 4px;
        box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 40px;
        right: 0px;
        z-index: 4;

        .option:hover {
          background-color: #e8f5fe;
        }

        .option {
          width: 100%;
          height: 30px;
          display: flex;
          padding: 5px;
          align-items: center;

          i {
            margin-left: 11px;
          }

          span {
            margin-left: 13px;
            font-size: 14px;
            line-height: 2.14;
            text-align: left;
          }
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 1.57;
      text-align: left;
      color: #383f45;
      margin-top: 0;
      margin-bottom: 10px;
    }

    .notification-info {
      display: flex;
      align-items: center;

      span {
        font-size: 12px;
        line-height: 1.33;
        text-align: left;
        color: #383f45;
      }

      .notification-flow {
        margin-left: 10px;
        border-left: #8e9ba7 1px solid;
        padding-left: 10px;
      }

      b {
        font-size: 12px;
        line-height: 1.33;
        text-align: right;
        color: #383f45;
      }
    }
  }
}
</style>
