<template>
  <div class="entity-container">
    <el-dialog append-to-body v-model="activePrompt" class="entity-dialog" title="Add entity" width="25%">
      <el-form>
        <el-form-item label="Entity Name">
          <el-input placeholder="Enter Entity Name" v-model.trim="entity_name"></el-input>
        </el-form-item>
        <hr />
        <el-form-item label="Entity Highlight Color">
          <div class="color-picker-container">
            <el-color-picker color-format="hex" :predefine="predefineColors" v-model="color_picked"></el-color-picker>
          </div>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button class="cancel-button" @click="activePrompt = false">CANCEL</el-button>
        <el-button class="confirm-button" type="primary" @click="createEntity">CONFIRM</el-button>
      </template>
    </el-dialog>

    <el-table class="entity-table" :data="filteredEntities">
      <el-table-column class="entity-table-column" align="left" label="" prop="name" width="300px">
        <template #header>
          <button class="add-button" @click="activePrompt = true"><i class="fas fa-plus"></i></button>
        </template>
        <template #default="{ row }">
          <div class="tag-group my-2 flex flex-wrap gap-1 items-center name-wrapper">
            <el-tag color="#104a96" size="default" class="mx-1" effect="plain">{{ row.name }}</el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column class="entity-table-column" align="center">
        <template #default="{ row }">
          <div class="tag-group my-2 flex flex-wrap gap-1 items-center name-wrapper">
            <el-tag :color="row.highlightColor" size="default" class="mx-1" effect="plain"></el-tag>
          </div>
        </template>
      </el-table-column>

      <el-table-column class="entity-table-column" align="right">
        <template #header>
          <el-input v-model="search" size="large" placeholder="Type to search" class="w-50 m-2 entity-list-search" />
        </template>
        <template #default="{ row }">
          <el-button size="small" @click="entitySettings(row.name, row.id, row.highlightColor)"><i class="fas fa-edit"></i></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { db } from '../../main';
import firebase from 'firebase/compat/app';
import EntityEdit from './EntityEdit.vue';
import { useModal, useModalSlot, VueFinalModal } from 'vue-final-modal';

export default {
  name: 'EntityTable',
  props: {
    collection: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      entities: [],
      search: '',
      wheels: [],
      entity_name: '',
      activePrompt: false,
      color_picked: '#D683A2',
      predefineColors: ['#ff4500', '#ff8c00', '#ffd700', '#90ee90', '#00ced1', '#1e90ff', '#c71585', 'rgba(255, 69, 0, 0.68)', 'rgb(255, 120, 0)', 'hsv(51, 100, 98)', 'hsva(120, 40, 94, 0.5)', 'hsl(181, 100%, 37%)', 'hsla(209, 100%, 56%, 0.73)']
    };
  },
  mounted() {
    this.currentUser();
    this.getEntities();
  },
  computed: {
    filteredEntities() {
      const seen = new Set();
      const uniqueEntities = this.entities.filter((entity) => {
        const lowerCaseName = entity.name.toLowerCase();

        if (!seen.has(lowerCaseName)) {
          seen.add(lowerCaseName);
          return true;
        }

        return false;
      });

      return uniqueEntities.filter((data) => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  },
  methods: {
    async createEntity() {
      const existingEntity = this.entities.find((e) => e.name.toLowerCase() === this.entity_name.toLowerCase());

      if (existingEntity) {
        this.$vs.notify({
          title: 'Entity name already been used',
          text: 'Cannot create entity',
          color: 'danger',
          time: 3000
        });
        return;
      }

      if (/^\d/.test(this.entity_name)) {
        this.$message({
          message: 'Entity Name cannot start with a number',
          showClose: 'true',
          time: 1000
        });
        return;
      }

      if (!/^[a-zA-Z\d\-_.,\s]+$/.test(this.entity_name)) {
        this.$message({
          message: 'Entity Name cannot start have special characters',
          showClose: 'true',
          time: 1000
        });
        return;
      }

      if (this.entity_name.length < 2) {
        this.$message({
          message: 'Entity Name cannot be 1 character',
          showClose: 'true',
          time: 1000
        });
        return;
      }

      db.collection(this.collection)
        .doc('EntityDoc')
        .collection('EntityColl')
        .add({
          createdBy: this.appStore.user.email,
          highlightColor: this.color_picked,
          name: this.entity_name.replace(/\s+/g, ''),
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
          this.activePrompt = false;
          this.$vs.notify({
            title: 'Entity created' + '    ' + "     <i class='fas fa-save'></i>",
            text: 'Successfully saved to database',
            color: 'dark',
            time: 3000
          });
          setTimeout(() => {
            this.$emit('reset');
          }, 1000);
        });
    },
    deleteEntity(id) {
      const r = confirm('Are you sure you want to delete');
      if (r === true) {
        db.collection(this.collection)
          .doc('EntityDoc')
          .collection('EntityColl')
          .doc(id)
          .delete()
          .then(() => {
            this.$vs.notify({
              title: 'Entity deleted' + '    ' + "     <i class='fas fa-trash-alt'></i>",
              text: 'Successfully deleted from database',
              color: 'dark',
              time: 3000
            });
            setTimeout(() => {
              this.$emit('reset');
            }, 1000);
          });
      }
    },
    getEntities() {
      const ref = db.collection(this.collection).doc('EntityDoc').collection('EntityColl');

      ref.onSnapshot(
        (snapshot) => {
          if (snapshot.empty) {
            this.entities = [];
            return;
          }

          const newEntities = [];
          snapshot.forEach((doc) => {
            const data = doc.data();

            newEntities.push({
              id: doc.id,
              name: data.name,
              highlightColor: data.highlightColor,
              lastEdited: data.lastEdited
            });
          });

          newEntities.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });

          this.entities = newEntities;
        },
        (err) => {
          console.error('Error fetching entities: ', err.message, err);
        }
      );
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    entitySettings(value, id, color) {
      const new_id = id;
      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: {
          name: 'entity-edit-modal',
          adaptive: true,
          teleportTo: 'body',
          modelValue: false,
          displayDirective: 'if',
          hideOverlay: false,
          overlayTransition: 'vfm-fade',
          contentTransition: 'vfm-fade',
          clickToClose: true,
          escToClose: true,
          background: 'non-interactive',
          lockScroll: true,
          swipeToClose: 'none',
          transition: 'slide-fade',
          fitParent: true,
          overlayClass: 'grid justify-items-center items-center entity-edit-modal',
          contentClass: 'grid justify-items-center items-center overflow-auto rounded-lg entity-edit-modal-content relative',
          onBeforeClose() {
            const r = confirm('Are you sure you want to navigate away from this page?');
            if (r === false) {
              return false;
            }
          }
        },
        slots: {
          default: useModalSlot({
            component: EntityEdit,
            attrs: {
              collection: this.collection,
              entity_info: {
                name: value,
                id: new_id,
                highlightColor: color
              },
              onClose() {
                modalInstance.close();
              }
            }
          })
        }
      });
      modalInstance.open();
    },
    removeEntity() {
      this.entities.pop();
    },
    addEntity() {
      this.entities.push([
        {
          name: 'Placeholder',
          value: 'Placeholder',
          synonyms: ['Placeholder']
        }
      ]);
    },
    addEntityName(index) {
      this.entities[index].push({
        name: 'Placeholder',
        value: 'Placeholder',
        synonyms: ['Placeholder']
      });
    }
  }
};
</script>

<style scoped>
.el-button--primary {
  border: none !important;
}

/* .el-dialog__title {
  font-family: 'Oswald' !important;
} */
.container-fluid-entities {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  background: white;
  /* overflow-x: scroll; */
}

.create {
  border-radius: 15px;
  border: 2px solid black;
}

.table thead th {
  vertical-align: unset;
}

.el-table {
  max-width: 100%;
  font-size: 10px;
}

.el-table td,
.el-table th {
  padding: 0px;
}

/* .el-table__empty-text {
  display: none;
} */

.el-tag {
  color: white;
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: 1px;
}

.entity.table {
  background-color: white;
  width: 73%;
  height: 96%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* padding: 10px; */
  border-radius: 15px;
  /* padding: 30px; */
}

.vs-table-text {
  color: dodgerblue;
}

.vs-table-text {
  font-size: 12px;
}

.vfm {
  display: flex;
  justify-content: center;
}

.vfm__content {
  background: white;
  width: 90%;
  height: 90%;
  display: flex;
  justify-items: center;
  align-content: center;
  border-radius: 10px;
  overflow: hidden scroll;
  margin-top: 10px;
}

.vs-con-table {
  background: white;
}

.vs-component.vs-con-table.vs-table-primary {
  width: 99vh;
  padding: 20px;
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  /* background: mediumseagreen; */
}

.entity-container {
  margin-top: 40px !important;
}

.entity-dialog {
  width: 25%;
}

.color-picker-container {
  display: grid;
  justify-items: center;
}

.cancel-button {
  color: white;
  background-color: black !important;
  border-radius: 5px;
  font-size: 14px;
}

.cancel-button:hover {
  color: white;
}

.confirm-button {
  color: white;
  background-color: #fb5422 !important;
  border-radius: 5px;
  font-size: 14px;
}

.entity-table {
  max-height: 70vh;
  width: 100%;
  overflow: hidden scroll;
}

.entity-table-column {
  display: flex;
}

.add-button {
  float: left;
  margin-right: 10px;
  background: black;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.name-wrapper {
  display: block;
}
</style>
