<template>
  <div>
    <div class="sidebar components" v-click-away="onClickAway">
      <div class="row editor-header"></div>
      <div>
        <message-editor-sidebar :id="componentSelected.id" @input="updateComponent" :value="componentSelected.text" placeHolder="Edit Message" :readOnly="false" :type="`message`" :changedFocus="focusChanged" />
      </div>
    </div>
    <div class="sidebar components">
      <ReferenceInput :field="`reference`" :id="componentSelected.id" :reference="componentSelected.reference">
        <template v-slot:header>
          <div class="row editor-header">
            <div class="col-11">
              <div>
                <ConversationBuilderIcon :width="14" name="reference" />
                <span class="sidebar type" style="margin-left: 12px">Internal Reference</span>
                <!-- <ConversationBuilderIcon name="info" /> it will be used in future releases -->
              </div>
            </div>
          </div>
        </template>
      </ReferenceInput>
    </div>
    <!-- Remove if condition in case time delay message response functionality needs to be added. P.S : Future functionality -->
    <div v-if="false" class="sidebar components">
      <UniversalSidebarDropdown :data="timeOptions" :label="`name`" :field="`timeResponse`" :id="componentSelected.id" :appendToBody="false">
        <template v-slot:header>
          <div class="row editor-header">
            <div class="col-11">
              <ConversationBuilderIcon :width="14" name="time" /><span class="sidebar type" style="margin-left: 7px"> Time Response </span>
              <!-- <ConversationBuilderIcon name="info" /> it will be used in future releases -->
            </div>
          </div>
        </template>
      </UniversalSidebarDropdown>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import MessageEditorSidebar from '@/components/ConversationBuilder/Editors/3.0/MessageEditorSidebar.vue';
import UniversalSidebarDropdown from '../Dropdowns/UniversalSidebarDropdown.vue';
import essentials from '../Mixins/essentials';
import ReferenceInput from '../Inputs/ReferenceInput.vue';
import debounce from 'lodash/debounce';
import { mixin as VueClickAway } from 'vue3-click-away';

export default {
  name: 'MessageEdit',
  mixins: [essentials, VueClickAway],
  methods: {
    debouncedUpdate: debounce(function (text) {
      this.appStore.updateComponent({ text, id: this.id });
    }, 400),
    update(e) {
      this.appStore.updateComponent({ text: e, id: this.id });
    },
    updateComponent(e) {
      if (!e?.type) {
        this.debouncedUpdate(e);
      }
    },
    onClickAway() {
      this.focusChanged = !this.focusChanged;
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      componentKey: 0,
      timeOptions: [0, 1, 2, 3, 4, 5, 6].map((x) => {
        return {
          name: x + (x > 1 ? ' secs' : ' sec'),
          text: x + (x > 1 ? ' secs' : ' sec')
        };
      }),
      focusChanged: false
    };
  },
  components: {
    MessageEditorSidebar,
    UniversalSidebarDropdown,
    ReferenceInput
  }
};
</script>

<style scoped>
.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}

.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 25px 15px;
  height: max-content;
}

.sidebar.components.flow {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
