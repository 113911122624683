<template>
  <div class="editor editor-training" :class="editorClasses">
    <div v-if="isTrainingPhrase" class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div v-for="(entity, index) in filteredEntities" :key="entity" class="suggestion-list__item" :class="{ 'is-selected': navigatedUserIndex === index }" @click="selectEntity(entity, $event)">
          {{ `${entity.name || 'N/A'}.${entity.value || entity.name}` }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">No entities found</div>
    </div>

    <el-row class="editor-training-row">
      <editor-content v-if="isTrainingPhrase && !readOnly" class="editor__content__bubble__shorten__training editor-training-editor" :editor="editor" />
      <span :tabindex="tabindex" class="editor-training-item-buttons">
        <button v-if="tabindex > 0" tabindex="-1" class="menubar__button__menu" @click="$emit('remove')">
          <i class="fas fa-trash-alt" style="font-size: 9px"></i>
        </button>
        <button tabindex="-1" class="menubar__button__menu" @click="$emit('add')">
          <i class="fas fa-plus" style="font-size: 9px"></i>
        </button>
      </span>
    </el-row>

    <editor-content v-if="isTrainingPhrase && readOnly" class="editor__content__bubble__shorten__training__readOnly editor-training-editor-readonly" :editor="editor" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { Note, EntityMention, Limit, CustomEnterKeyExtension } from '@/components/ConversationBuilder/Extensions/index.js';
import Fuse from 'fuse.js';
import tippy, { sticky } from 'tippy.js';
import { mapActions } from 'pinia';
import find from 'lodash/find';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Focus from '@tiptap/extension-focus';
export default {
  components: {
    EditorContent
  },
  props: {
    photoURL: {
      type: String
    },
    lastSavedBy: {
      type: [String],
      required: false
    },
    uuid: {
      type: [String],
      required: false
    },
    entitiesChosen: {
      type: [Array, String],
      required: true
    },
    entities: {
      type: [Array, String],
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    modelValue: {
      type: String,
      default: ''
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    collection: {
      required: false,
      type: String
    },
    nodes: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      editor: null,
      editorChange: false,
      linkUrl: null,
      sayAsPhrase: null,
      phoneNumber: null,
      note: '',
      linkMenuIsActive: false,
      telephoneMenuIsActive: false,
      noteMenuIsActive: false,
      query: null,
      suggestionRange: null,
      filteredEntities: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      observer: null,
      users: useAppStore().getAllUsers,
      textLength: 0,
      linksCreated: []
    };
  },
  computed: {
    setLanguage() {
      return this.appStore.language;
    },
    placeHolderText() {
      if (this.table) {
        return '';
      } else if (this.type === 'note') {
        return 'Record Notes';
      } else {
        return this.setLanguage;
      }
    },
    voiceActive() {
      return this.appStore.voiceActive;
    },
    convertEntitiesChosen() {
      const entitiesRefiltered = [];
      for (const item of this.entitiesChosen) {
        const found = find(this.entities, { id: item.id });
        if (found)
          for (const value of found.values) {
            // if (this.modelValue.includes(item.name)) {
            entitiesRefiltered.push({ ...item, value: value });
            // }
          }
      }
      return entitiesRefiltered;
    },
    filteredSelectedEntities() {
      return this.convertEntitiesChosen.filter((item) => {
        if (item.name) {
          if (this.modelValue.includes(item.name.replace(/\s/g, ''))) {
            return item;
          }
        }
      });
    },
    hasResults() {
      return this.filteredEntities.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    },
    isTrainingPhrase() {
      return this.type === 'trainingphrase';
    },
    editorClasses() {
      return {
        editorTraining: this.isTrainingPhrase,
        editorTraining__readOnly: this.isTrainingPhrase && this.readOnly
      };
    }
  },
  mounted() {
    this.initializeEditor();
  },
  methods: {
    ...mapActions(useAppStore, ['sendSceneToDB']),
    upHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredEntities.length - 1) % this.filteredEntities.length;
    },

    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredEntities.length;
    },
    enterHandler(event) {
      const entity = this.filteredEntities[this.navigatedUserIndex];
      if (entity) {
        this.selectEntity(entity, event);
      }
    },

    selectEntity(entity, e) {
      e.preventDefault();
      e.stopPropagation();
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: entity.id,
          name: entity.name,
          value: entity.value || entity.name,
          highlightColor: entity.highlightColor,
          label: entity.name + '.' + entity.value || entity.name,
          role: this.appStore.getClaims?.role,
          uuid: this.appStore.getUser.uid,
          lastSavedBy: this.appStore.getUser.displayName,
          photoURL: this.appStore.getUser.photoURL
        }
      });
      this.editor.commands.focus();
    },

    renderPopup(node) {
      this.destroyPopup();
      const clientRect = node.getClientRects();
      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'manual',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnCreate: true,
        arrow: true,
        appendTo: document.body,
        getReferenceClientRect: () => clientRect[0],
        sticky: true,
        plugins: [sticky]
      });
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy();
        this.popup = null;
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    },

    initializeEditor() {
      try {
        // 1. Basic Configuration
        const basicConfig = {
          editable: this.readOnly ? false : true,
          content: this.modelValue ?? '',
          useBuiltInExtensions: true
        };
        // console.log('EditorTraining: Basic configuration done:', basicConfig);

        // 2. Extensions Configuration
        const extensionsConfig = [
          StarterKit.configure({
            heading: {
              levels: [1, 2, 3]
            }
          }),
          Focus.configure({
            className: 'has-focus',
            nested: true
          }),
          Placeholder.configure({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: '',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true
          }),
          Note,
          CustomEnterKeyExtension,
          Limit.configure({
            limit: this.type != 'text' ? 1000000 : 640
          }),
          EntityMention.configure({
            // a list of all suggested items
            items: () => this.convertEntitiesChosen,
            // is called when a suggestion starts
            onStart: ({ items, query, range, command, virtualNode }) => {
              this.query = query;
              this.filteredEntities = items;
              this.suggestionRange = range;
              this.renderPopup(virtualNode);
              // we save the command for inserting a selected mention
              // this allows us to call it inside of our custom popup
              // via keyboard navigation and on click
              this.insertMention = command;
            },
            // is called when a suggestion has changed
            onUpdate: ({ items, query, range }) => {
              this.query = query;
              this.filteredEntities = items;
              this.suggestionRange = range;
              this.navigatedUserIndex = 0;
              // No need to render the popup again
              // this.renderPopup(virtualNode);
            },
            // is called when a suggestion is cancelled
            onExit: () => {
              // reset all saved values
              this.query = null;
              this.filteredEntities = [];
              this.suggestionRange = null;
              this.navigatedUserIndex = 0;
              this.destroyPopup();
            },
            // is called on every keyDown event while a suggestion is active
            onKeyDown: ({ event }) => {
              // pressing up arrow
              if (event.keyCode === 38) {
                this.upHandler();
                return true;
              }
              // pressing down arrow
              if (event.keyCode === 40) {
                this.downHandler();
                return true;
              }
              // pressing enter
              if (event.keyCode === 13) {
                this.enterHandler(event);
                return true;
              }
              return false;
            },
            // is called when a suggestion has changed
            // this function is optional because there is basic filtering built-in
            // you can overwrite it if you prefer your own filtering
            // in this example we use fuse.js with support for fuzzy search
            // we are actually using this filter function now
            onFilter: (items, query) => {
              if (!query) {
                return items;
              }
              const fuse = new Fuse(items, {
                threshold: 0.2,
                keys: ['name']
              });
              return fuse.search(query).map((result) => ({ ...result.item }));
            }
          })
        ];
        // console.log('EditorTraining: Extensions configuration done:', extensionsConfig);

        // 3. Event Handlers Configuration
        const eventsConfig = {
          onInit: () => {},
          onFocus: () => {},
          onBlur: () => {},
          onUpdate: ({ editor }) => {
            this.editorChange = true;
            this.$emit('update:modelValue', editor.getHTML());
          },
          onPaste: () => {
            return true;
          }
        };
        // console.log('EditorTraining: Event handlers configuration done:', eventsConfig);

        // 4. Merge all configurations and create the editor
        const finalConfig = {
          ...basicConfig,
          ...eventsConfig,
          extensions: extensionsConfig
        };
        // console.log('EditorTraining: Final configuration done:', finalConfig);

        this.editor = new Editor(finalConfig);
        // console.log('EditorTraining: Editor initialized successfully:', this.editor);
        // console.log('EditorTraining: Current editor content:', this.editor.getJSON());
      } catch (error) {
        console.error('EditorTraining: Error initializing the editor:', error);
      }
    }
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) this.editor.destroy();
    } catch (err) {
      //
    }
  },
  watch: {
    modelValue(val) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(val ?? '', true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style>
/* say-as[interpret-as='telephone']:after,
a[phoneNumber='true']:after {
    font-family: "Font Awesome 5 Free";
    content: "\f095";
    font-weight: 900;
    font-size: 10px;
    vertical-align: super;
    font-size: 7px;
} */

.editor__content__bubble__shorten__training .ProseMirror p {
  margin-bottom: 0rem !important;
}

.editor-training-row {
  display: flex;
  align-content: center;
  justify-content: stretch;
}

.editor-training-item-buttons {
  position: relative;
  height: 100%;
  width: 5%;
}

a[target='_blank'] {
  text-decoration: underline;
}

.duplicate {
  color: red;
  font-style: italic;
}

/* 
highlightsecondary[note]::after {
    font-family: "Font Awesome 5 Free";
    content: "\f075";
    font-weight: 900;
    font-size: 12px;
    vertical-align: super;
    color:black;
} */

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
  font-style: italic;
  text-decoration-color: black;
}

.editor__content table .column-resize-handle,
.column-resize-handle.ProseMirror-widget {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper table {
  min-width: 340px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
</style>

<style scoped>
:deep(.suggestion) {
  all: unset;
}
.editor__content__bubble:before {
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 2px;
  content: '';
  display: inline-block;
  height: 12px;
  left: 5px;
  position: absolute;
  width: 12px;
  background-size: cover;
}

.menububble__button {
  font-size: 10px;
}

.menububble__input::placeholder {
  color: lightgrey;
  font-size: 10px;
}

.tableOptions {
  font-size: 8px;
  font-weight: 300;
  text-decoration: underline;
}

.editor {
  position: relative;
  max-width: 30rem;
  margin: 1rem auto 0rem;
}

.editorTraining {
  position: relative;
  width: 100%;
  max-width: 490px;
  margin: 0rem auto 0rem;
  padding: 5px 10px;
  /* display: flex; */
  background: white;
  border: 1px solid lightgray;
  border-radius: 10px;
}

.dup {
  border: 1px solid red;
}

.editorTraining__readOnly {
  border: none;
}

.menubar {
  margin-bottom: 0rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  background: transparent;
  padding-left: 8px;
  padding: 1px;
  height: 25px;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.editor__content__bubble {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  font-size: 12px;
  margin-left: 10px;
  /* font-weight: bold; */
  color: rgb(0, 155, 207);
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 25px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 12px 10px 10px;
  cursor: text;
}

.editor__content__bubble__shorten__training {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 95%;
  height: max-content;
  min-height: 30px;
  overflow-y: scroll;
  font-size: 1.1em;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  /* border-top: 1px solid lightgray; */
  /* border: rgb(0, 155, 207); */
  /* border: 1px solid lightgray; */
  border-radius: 1px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 10px 10px 10px;
}

.editor__content__bubble__shorten__training__readOnly {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 1.2em;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: none;
  -webkit-transition: none;
  transition: none;
  padding: 0px;
}

/* .editor__content__bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 10px;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	border-left: 0;
	margin-left: -10px;
    margin-bottom: -5px;
} */

.editor__content__bubble__shorten__readOnly {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 100%;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  /* border: rgb(0, 155, 207); */
  border-radius: 25px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
}

.editor__content__table {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  max-width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 5px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
  cursor: text;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar__button__menu {
  font-weight: 300;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: black;
  padding: 8px;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: grab;
}

.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.menubar.is-focused {
  visibility: visible;
  opacity: 1;
}
</style>

<style>
.mention {
  /* background: #fb5422; */
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.editor__content__table table td {
  cursor: col-resize;
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor__content__table table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
}

.editor__content__table table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editor__content_bubble table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.is-empty.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.editor__content__bubble p {
  text-align: left;
}

/* .has-focus {
  border-radius: 3px;
    box-shadow: 0 0 0 3px black
} */
</style>

<style lang="scss" scoped>
.menububble {
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    display: flex;
    align-items: center;
  }
  &__input {
    font-size: 10px;
    border: none;
    background: transparent;
    color: white;
  }
}

.addNote {
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 30px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%) translateY(-20px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  width: 200px;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    background: transparent;
    color: white;
  }
  &__input {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: inherit;
    color: white !important;
    border-radius: 25px;
    border-style: none;
    color: inherit;
  }
}

.mention-suggestion {
  color: black;
}

.suggestion-list {
  padding: 0.2rem; // border: 2px solid black;
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: white;
      color: #009bcf;
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}

.tippy-tooltip.dark-theme {
  background-color: black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: white;
  border-radius: 5px;
  .tippy-backdrop {
    display: none;
  }
  .tippy-roundarrow {
    fill: black;
  }
  .tippy-popper[x-placement^='top'] & .tippy-arrow {
    border-top-color: black;
  }
  .tippy-popper[x-placement^='bottom'] & .tippy-arrow {
    border-bottom-color: black;
  }
  .tippy-popper[x-placement^='left'] & .tippy-arrow {
    border-left-color: black;
  }
  .tippy-popper[x-placement^='right'] & .tippy-arrow {
    border-right-color: black;
  }
}
</style>
