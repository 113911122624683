<template>
  <div class="child-comments_container" :key="forceRecomputeCounter">
    <ChildComment
      v-for="(comment, index) in childComments"
      :key="index"
      :comment="comment"
      :index="index"
      :parentCommentId="parentCommentId"
      @refresh="forceRecomputeCounter++"
    />
  </div>
</template>

<script>
import ChildComment from './ChildComment.vue';

export default {
  name: 'ChildComments',
  components: {
    ChildComment,
  },
  props: {
    childComments: {
      default: [],
    },
    parentCommentId: {
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.child-comments_container {
  display: flex;
  flex-direction: column;
}
</style>
