import { socket } from '@/helpers/socket';
import { DISCONNECTED_TIMEOUT, RPC_CONNECTED, RPC_DISCONNECTED, RPC_SEND_START_DELAY } from '@/helpers/rpc/constants';
import { useVcmStore } from '@/modules/vcmModule';

export const START_DELAY = 100;

const INITIAL_CHECK = 'INITIAL_CHECK';

export class FlowUserRPCAdapter {
  static get connected() {
    return this._connected;
  }

  static set connected(connected) {
    console.log('[RPC] Connection', { connected }, new Date());
    this._connected = connected;
    const vcmStore = useVcmStore();
    vcmStore.setConnected(connected);
    if (connected) {
      clearInterval(this.checkDisconnect);
      this.onErrorOrConnectionChange(RPC_CONNECTED);
    }
  }

  static checkConnection() {
    const handleDisconnect = (reason = INITIAL_CHECK) => {
      if (reason !== INITIAL_CHECK) {
        this.connected = false;
      }
      clearInterval(this.checkDisconnect);
      this.checkDisconnect = setInterval(() => {
        this.connected = socket.connected;
        if (!this.connected) {
          console.log('[RPC] Connection lost', { reason });
          this.onErrorOrConnectionChange(RPC_DISCONNECTED);
        } else {
          clearInterval(this.checkDisconnect);
        }
      }, DISCONNECTED_TIMEOUT);
    };
    handleDisconnect();
    socket.on('disconnect', handleDisconnect);
  }

  static start({ slotId, taxonomy, language, onFlowUpdate = () => {}, onErrorOrConnectionChange = () => {} }) {
    this.onErrorOrConnectionChange = onErrorOrConnectionChange;

    const flowKey = `${taxonomy}/${slotId}/${language}`;
    const event = `RPC_FLOW_USERS_${flowKey}`;
    socket.on(event, (flowUsers) => {
      onFlowUpdate({ users: flowUsers });
    });
    socket.on(`RPC_FLOW_USERS_ERROR_${flowKey}`, ({ message }) => {
      this.onErrorOrConnectionChange(message);
    });

    this.sendStart({ slotId, taxonomy, language });

    socket.io.on('reconnect', (count) => {
      clearInterval(this.checkDisconnect);
      this.connected = true;
      console.log('[RPC] Reconnected', { count });
      setTimeout(() => {
        this.sendStart({ slotId, taxonomy, language });
      }, RPC_SEND_START_DELAY);
    });
    socket.onAny(this.onAny);
    this.connected = true;
    this.checkConnection();
  }

  static sendStart({ slotId, taxonomy, language }) {
    console.log('[RPC] Sent RPC_USER_FLOW_START', { slotId, taxonomy, language }, new Date());
    // NOTE: delay or it sometimes does not get sent
    setTimeout(() => {
      socket.emit(`RPC_USER_FLOW_START`, slotId, taxonomy, language);
    }, START_DELAY);
  }

  static stop({ slotId, taxonomy, language }) {
    clearInterval(this.checkDisconnect);
    this.onErrorOrConnectionChange = () => {};
    const flowKey = `${taxonomy}/${slotId}/${language}`;
    socket.off(`RPC_FLOW_USERS_${flowKey}`);
    socket.off(`RPC_FLOW_USERS_ERROR_${flowKey}`);
    socket.io.off('reconnect');
    socket.offAny(this.onAny);
    socket.emit(`RPC_USER_FLOW_STOP`, slotId, taxonomy, language);
    console.log('[RPC] stopped', { slotId, taxonomy, language }, new Date());
  }

  static setCollabMode({ slotId, taxonomy, language }, shouldEdit) {
    socket.emit(`RPC_USER_FLOW_SET_WRITING`, slotId, taxonomy, language, shouldEdit);
    console.log('[RPC] set mode', { slotId, taxonomy, language, shouldEdit }, new Date());
  }

  static onActivity({ slotId, taxonomy, language }) {
    if (!this.connected) {
      return;
    }
    socket.emit('RPC_USER_FLOW_ACTIVITY', slotId, taxonomy, language);
  }

  static onAny(event) {
    this.connected = true;
    if (event.indexOf('RPC_') === 0) {
      console.log('[RPC] Event', { event, date: new Date() });
    }
  }
}
