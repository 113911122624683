import { defineStore } from 'pinia';
import axios from 'axios';
import { nextTick } from 'vue';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import { v4 as uuidv4 } from 'uuid';
import { useAppStore, useVersionsStore } from '@/store/index.js';

export const useAutoSaveStore = defineStore({
  id: 'autoSave',
  state: () => ({
    currentAutoSavedVersion: undefined,
    autoSavedVersions: [],
    versionIdWhenSidebarOn: '',
    autoSaveFlag: true
  }),
  getters: {
    getAutoSaveStatus: (state) => state.autoSaveFlag,
    getAutoSavedVersions: (state) => state.autoSavedVersions,
    getCurrentAutoSavedVersion: (state) => state.currentAutoSavedVersion,
    getUndoRedoStack: (state) => state.undoRedoStack
  },
  actions: {
    ADD_SAVED_VERSION({ versionId }) {
      this.autoSavedVersions.push(versionId);
    },
    SET_SAVED_VERSIONS({ data }) {
      this.autoSavedVersions = data;
    },
    SET_AUTOSAVEFLAG({ flagVal }) {
      this.autoSaveFlag = flagVal;
    },
    SET_CURRENT_SAVED_VERSION(data) {
      this.currentAutoSavedVersion = data;
    },
    SET_VERSION_ID_SIDEBAR({ versionId }) {
      this.versionIdWhenSidebarOn = versionId;
    },
    undoScene() {
      this.revertToSavedVersion({ undo: true });
    },
    redoScene() {
      this.revertToSavedVersion({ redo: true });
    },
    async PERSIST_VERSION_IN_REDIS({ versionId, data }) {
      const x = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/redis/storeVersion`, {
        versionId,
        data,
        currentAutoSavedVersions: this.autoSavedVersions
      });
      if (x.status === 200 && x?.data?.status === 'stored') {
        return true;
      } else {
        return false;
      }
    },
    async autoSave({ uxStateOnly }) {
      const appStore = useAppStore();
      const versionsStore = useVersionsStore();
      let undoRedoChanged = false;
      if (appStore.displayMode !== 'drafts') {
        // eslint-disable-next-line no-inner-declarations
        async function previousVersionIsSame(data, autoSavedVersions, isSideBarOpen) {
          if (autoSavedVersions.length === 0) return false;
          const latestVersionId = last(autoSavedVersions);
          const latestVersion = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/redis/getVersion/${latestVersionId}`);
          if (latestVersion) {
            if (Object.prototype.hasOwnProperty.call(data?.uxState, 'comments')) {
              data.uxState = omit(data?.uxState, 'comments');
            }
            if (Object.prototype.hasOwnProperty.call(latestVersion?.data?.uxState, 'comments')) {
              latestVersion.data.uxState = omit(latestVersion.data.uxState, 'comments');
            }
            if (isSideBarOpen) {
              // When sidebar is open, lets emit components so changes won't be saved until it gets closed.
              data.uxState = omit(data?.uxState, 'components');
              latestVersion.data.uxState = omit(latestVersion.data.uxState, 'components');
            }
            if (isEqual(data?.uxState, latestVersion?.data?.uxState)) return true;
            else return false;
          } else {
            return false;
          }
        }
        // eslint-disable-next-line no-inner-declarations
        async function sameVersionOrNot(data, versionId, isSideBarOpen) {
          const version = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/redis/getVersion/${versionId}`);
          if (version) {
            if (Object.prototype.hasOwnProperty.call(data?.uxState, 'comments')) {
              data.uxState = omit(data?.uxState, 'comments');
            }
            if (Object.prototype.hasOwnProperty.call(version?.data?.uxState, 'comments')) {
              version.data.uxState = omit(version.data.uxState, 'comments');
            }
            if (isSideBarOpen) {
              // When sidebar is open, lets emit components so changes won't be saved until it gets closed.
              data.uxState = omit(data?.uxState, 'components');
              version.data.uxState = omit(version.data.uxState, 'components');
            }
            if (isEqual(data?.uxState, version?.data?.uxState)) return true;
            else return false;
          } else {
            return false;
          }
        }

        const data = await appStore.generateJSON({ publish: false, draft: true, uxStateOnly: uxStateOnly });
        if (!data) {
          return;
        }
        if (
          this.currentAutoSavedVersion &&
          this.currentAutoSavedVersion.undoRedo &&
          find(this.autoSavedVersions, function (o) {
            return o === this.currentAutoSavedVersion.versionId;
          }) !== undefined
        ) {
          if (await sameVersionOrNot(data, this.currentAutoSavedVersion.versionId, appStore.editSidebarActive)) {
            // Same version after redo/undo do not save.
            return;
          } else {
            undoRedoChanged = true;
          }
        }

        if (await previousVersionIsSame(data, this.autoSavedVersions, appStore.editSidebarActive)) {
          // Do not save
        } else {
          // Generates a version Id and then stores the version
          const versionId = uuidv4();
          const newVersion = await appStore.generateJSON({ publish: true, draft: true, versionId, uxStateOnly: uxStateOnly });
          const storeVersion = await this.PERSIST_VERSION_IN_REDIS({ versionId, data: newVersion });
          if (storeVersion) {
            if (undoRedoChanged) {
              const indexOfDraft = this.autoSavedVersions.findIndex((x) => x === this.currentAutoSavedVersion.versionId);
              if (indexOfDraft !== -1) {
                //Means undo/redo version is in our undo-redo stack
                const newStack = cloneDeep(this.autoSavedVersions);
                newStack.length = indexOfDraft + 1; //Faster than splice
                this.SET_SAVED_VERSIONS({ data: newStack });
              }
            }
            this.SET_CURRENT_SAVED_VERSION({
              versionId,
              createdAt: Date.now()
            });
            // Adds the saved version to an array so user can navigate to
            this.ADD_SAVED_VERSION({ versionId, timestamp: Date.now() });

            // Update currentDraftVersion
            await versionsStore.getLatestDraftFromDb({ versionId });
          }
          this.SET_AUTOSAVEFLAG({ flagVal: true });
          undoRedoChanged = false;
          return;
        }
      }
    },
    async revertToSavedVersion({ undo, redo }) {
      const appStore = useAppStore();
      function findNextStepUp(arr, value) {
        const i = arr.findIndex((x) => x === value);
        return arr[i + 1];
      }
      function findNextStepDown(arr, value) {
        const i = arr.findIndex((x) => x === value);
        return arr[i - 1];
      }
      try {
        const { autoSavedVersions } = this;
        const { versionId } = this.currentAutoSavedVersion;

        let nextDraftId;
        if (undo) nextDraftId = findNextStepDown(autoSavedVersions, versionId);
        if (redo) nextDraftId = findNextStepUp(autoSavedVersions, versionId);
        if (nextDraftId !== undefined) {
          const state = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/redis/getVersion/${nextDraftId}`);
          if (state?.status == 200) {
            const data = state?.data;
            appStore.RESTORE_FLOW({ uxState: [data?.uxState] });
            this.SET_CURRENT_SAVED_VERSION({
              versionId: nextDraftId,
              undoRedo: true
            });
            nextTick(() => {
              appStore.FORCE_STATE_RECOMPUTE({});
            });
          }
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }
  }
});
