<template>
  <div class="card border-0" :class="{ actionsOpened: actionsOpened }">
    <div class="card-body border-0">
      <div class="cardInner">
        <div class="row mainRow">
          <div class="col mb-1 card-agent-name">
            <span v-if="isEditor" @click="toggleCard" class="expand">
              <div class="expand">
                <i
                  :class="{
                    fas: true,
                    'fa-caret-right': !opened,
                    'fa-caret-down': opened
                  }"
                ></i>
              </div>
            </span>
            <span class="itemName" data-test="item-name">{{ item.name }}</span>
          </div>
          <div class="col text-right pr-4">
            <div class="buttons">
              <router-link
                :to="{
                  path: `/agents/flows/${item.id}`,
                  query: { initial: 'flows' }
                }"
                ><button type="button" class="btn btn-secondary"><img class="buttonIcon" width="19" src="../../../assets/icons/network.svg" data-test="flows-button" />Flows</button></router-link
              >
              <router-link
                v-if="isEditor"
                :to="{
                  path: `/agents/entities/${item.id}`,
                  query: { initial: 'entities' }
                }"
                ><button type="button" class="btn btn-secondary"><img class="buttonIcon" width="30" src="../../../assets/icons/entities.svg" data-test="entities-button" />Entities</button></router-link
              >

              <router-link
                v-if="isEditor"
                :to="{
                  path: `/agents/intents/${item.id}`,
                  query: { initial: 'intents' }
                }"
                ><button type="button" class="btn btn-secondary"><img class="buttonIcon" width="16" src="../../../assets/icons/brain.png" data-test="intents-button" />Intents</button></router-link
              >
              <div v-if="isAdmin">
                <button @click="toggleActions" type="button" class="btn btn-secondary btnactions" data-test="actions-button">
                  Actions
                  <i
                    :class="{
                      fas: true,
                      'fa-caret-down': !actionsOpened,
                      'fa-caret-up': actionsOpened
                    }"
                  ></i>
                </button>
              </div>
            </div>
            <div class="created" data-test="created">
              {{ item.timestamp && `Created: ${moment(item.timestamp._seconds * 1000).format('MMMM DD, YYYY')}` }}
            </div>
          </div>
        </div>
      </div>
      <div :class="{ accordion: true, opened: opened }">
        <i v-if="opened" @click="refreshHistory" :class="{ fas: true, 'fa-refresh': true, 'fa-spin': loading }"></i>
        <ul :key="loading" class="actionHistory">
          <ActionHistoryItem v-if="isEditor" :opened="historyOpenedNLUTraining" @toggle="historyOpenedNLUTraining = !historyOpenedNLUTraining" title="NLU Training">
            <template v-slot:body>
              <div>
                <table class="actionsTable">
                  <tr>
                    <th>Job Type</th>
                    <th>Job name</th>
                    <th>Status</th>
                    <th>Job started</th>
                    <th>Job last modified</th>
                    <th>User</th>
                  </tr>
                  <template v-if="appStore.jobs[item.id]">
                    <tr v-for="(job, index) in this.appStore.jobs[item.id]" :key="job.id">
                      <template v-if="index < displayJobsTraining">
                        <td>{{ job.job_type }}</td>
                        <td>{{ job.job_name }}</td>
                        <td :style="{ color: getColor(job.job_status) }">
                          {{ getStatusDisplayName(job.job_status) }}
                        </td>
                        <td>
                          {{ job.job_creation_date && `${moment(job.job_creation_date._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>
                          {{ job.job_last_modified && `${moment(job.job_last_modified._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>{{ job.job_user }}</td>
                        <td>
                          <button v-if="job.job_output || (job.job_type === 'nlu_engine_train' && job.job_status.toLowerCase().includes('completed'))" @click="openJob(job.job_output, job)" type="button" class="btn btn-secondary actionsDownload">
                            {{ completedJobButtonText(job.job_type) }}
                          </button>
                        </td>
                      </template>
                    </tr>
                  </template>
                  <template v-if="appStore.runs[item.id]">
                    <tr v-for="(run, index) in this.appStore.runs[item.id]" :key="run.id">
                      <template v-if="index < displayJobsTraining">
                        <td>{{ run.type }}</td>
                        <td>{{ run.name }}</td>
                        <td :style="{ color: getColor(run.status) }">
                          {{ getStatusDisplayName(run.status) }} - {{ run.progress }}
                          <el-tooltip effect="dark" placement="top" v-if="run.note?.length > 0">
                            <template #content>
                              {{ run.note }}
                            </template>
                            <ConversationBuilderIcon name="info" :width="20" />
                          </el-tooltip>
                        </td>
                        <td>
                          {{ run.createdAt && `${moment(run.createdAt._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>
                          {{ run.updatedAt && `${moment(run.updatedAt._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>{{ run.author }}</td>
                      </template>
                    </tr>
                  </template>
                </table>
                <a @click="displayJobsTraining += 10" href="javascript:void(0)" v-if="(appStore.jobs[item.id] || this.appStore.runs[item.id]) && displayJobsTraining < this.appStore.jobs[item.id]?.length + this.appStore.runs[item.id]?.length">View more</a>
                <a @click="displayJobsTraining -= 10" href="javascript:void(0)" v-if="(appStore.jobs[item.id] || this.appStore.runs[item.id]) && displayJobsTraining >= this.appStore.jobs[item.id]?.length + this.appStore.runs[item.id]?.length && displayJobsTraining > 10">View Less</a>
              </div>
            </template>
          </ActionHistoryItem>
          <ActionHistoryItem :opened="historyOpenedUtilities" @toggle="toggleUtilities" title="Utilities">
            <template v-slot:body>
              <div>
                <table class="actionsTable">
                  <tr>
                    <th>Job type</th>
                    <th>Job name</th>
                    <th>Status</th>
                    <th>Job started</th>
                    <th>Job last modified</th>
                    <th>User</th>
                  </tr>
                  <!-- eslint-disable-next-line -->
                  <template v-if="appStore.jobsV3[item.id]">
                    <tr v-for="(job, index) in pdfExportJobsConcat(item)" :key="job.messageId">
                      <template v-if="index < displayJobs">
                        <td>{{ job.type }}</td>
                        <td>{{ job.name }}</td>
                        <td :style="{ color: getColor(job.status) }">
                          {{ getStatusDisplayName(job.status) }}
                        </td>
                        <td>
                          {{ job.createdAt && `${moment(job.createdAt).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>
                          {{ job.updatedAt && `${moment(job.updatedAt).format('MMMM DD, YYYY, h:mm a')}` }}
                        </td>
                        <td>{{ job.author }}</td>
                        <td v-if="job.status === 'failed'">
                          <button v-if="job.type === 'pdf_export'" @click="retryPdfExport(job)" type="button" class="btn btn-secondary actionsDownload">Retry</button>
                        </td>
                        <td v-if="job.status === 'done' && typeof job.downloadUrl === 'string' && job.downloadUrl !== ''">
                          <button @click="openJob(job.downloadUrl, job.type)" type="button" class="btn btn-secondary actionsDownload">
                            {{ completedJobButtonText(job.type) }}
                          </button>
                        </td>
                      </template>
                    </tr>
                  </template>
                </table>
                <a @click="displayJobs += 10" href="javascript:void(0)" v-if="appStore.jobsV3[item.id] && displayJobs < utilitiesConcat(item)?.length">View more</a>
                <a @click="displayJobs -= 10" href="javascript:void(0)" v-if="appStore.jobsV3[item.id] && displayJobs >= utilitiesConcat(item)?.length && displayJobs > 10">View Less</a>
              </div>
            </template>
          </ActionHistoryItem>
          <!-- <ActionHistoryItem v-if="isEditor" :opened="historyOpenedVoiceJobs" @toggle="historyOpenedVoiceJobs = !historyOpenedVoiceJobs" title="Voice">
              <template v-slot:body>
                <div>
                  <table class="actionsTable">
                    <tr>
                      <th>Job type</th>
                      <th>Job name</th>
                      <th>Status</th>
                      <th>Job started</th>
                      <th>Job last modified</th>
                      <th>User</th>
                    </tr> -->
          <!-- eslint-disable-next-line -->
          <!-- <template v-if="appStore.jobs[item.id]">
                      <tr v-for="(job, index) in appStore.jobs[item.id]['phonetic_pipeline'].filter((x) => x.job_status)" :key="job.id">
                        <template v-if="index < displayJobs">
                          <td>{{ job.job_type }}</td>
                          <td>{{ job.job_name }}</td>
                          <td :style="{ color: getColor(job.job_status) }">{{ job.job_status }}</td>
                          <td>
                            {{ job.job_creation_date && `${moment(job.job_creation_date._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                          </td>
                          <td>
                            {{ job.job_last_modified && `${moment(job.job_last_modified._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                          </td>
                          <td>{{ job.job_user }}</td>
                          <td>
                            <button @click="appStore.openDrawer({ drawer: 'voiceJobs', metadata: { job }, taxonomy: item.name, taxonomyId: item.id })" type="button" class="btn btn-secondary actionsDownload">View</button>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </table>
                  <a @click="displayJobs += 10" href="javascript:void(0)" v-if="appStore.jobs[item.id] && displayJobs < appStore.jobs[item.id]['phonetic_pipeline'].length">View more</a>
                  <a @click="displayJobs -= 10" href="javascript:void(0)" v-if="appStore.jobs[item.id] && displayJobs >= appStore.jobs[item.id]['phonetic_pipeline'].length && displayJobs > 10">View Less</a>
                </div>
              </template>
            </ActionHistoryItem> -->
          <!-- <ActionHistoryItem v-if="isEditor" :opened="historyOpenedTranscript" @toggle="historyOpenedTranscript = !historyOpenedTranscript" title="Supervised Learning Centre">
              <template v-slot:body>
                <div>
                  <table class="actionsTable">
                    <tr>
                      <th>Job Type</th>
                      <th>Job name</th>
                      <th>Status</th>
                      <th>Job started</th>
                      <th>Job last modified</th>
                      <th>User</th>
                    </tr> -->
          <!-- eslint-disable-next-line -->
          <!-- <template v-if="appStore.jobs[item.id]">
                      <tr v-for="(job, index) in appStore.jobs[item.id]['transcript_analysis'].filter((x) => x.job_status)" :key="job.id">
                        <template v-if="index < displayJobsTranscript">
                          <td>{{ job.job_type }}</td>
                          <td>{{ job.job_name }}</td>
                          <td :style="{ color: getColor(job.job_status) }">{{ job.job_status }}</td>
                          <td>
                            {{ job.job_creation_date && `${moment(job.job_creation_date._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                          </td>
                          <td>
                            {{ job.job_last_modified && `${moment(job.job_last_modified._seconds * 1000).format('MMMM DD, YYYY, h:mm a')}` }}
                          </td>
                          <td>{{ job.job_user }}</td>
                          <td>
                            <button @click="appStore.openDrawer({ drawer: 'transcriptAnalysisDrawer', metadata: { job }, taxonomy: item.name, taxonomyId: item.id })" type="button" class="btn btn-secondary actionsDownload">View</button>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </table>
                  <a @click="displayJobsTranscript += 10" href="javascript:void(0)" v-if="appStore.jobs[item.id] && displayJobsTranscript < appStore.jobs[item.id]['transcript_analysis'].length">View more</a>
                  <a @click="displayJobsTranscript -= 10" href="javascript:void(0)" v-if="appStore.jobs[item.id] && displayJobsTranscript >= appStore.jobs[item.id]['transcript_analysis'].length && displayJobsTranscript > 10">View Less</a>
                </div>
              </template>
            </ActionHistoryItem>-->
        </ul>
      </div>
    </div>
    <div class="actions">
      <ul class="actionsMenu">
        <ActionMenuItem
          v-if="isEditor"
          title="NLU Training"
          :closeParent="toggleActions"
          :items="[
            {
              // TODO: Will be Deprecated soon.
              title: 'cH NLU Engine',
              buttonLabel: 'Train',
              click: () => {
                appStore.openDrawer({
                  drawer: 'chNluEngine',
                  taxonomy: item.name,
                  taxonomyId: item.id
                });
              }
            },
            {
              title: 'RASA NLU Engine',
              buttonLabel: 'Train',
              click: () => {
                appStore.openDrawer({
                  drawer: 'rasaNluEngine',
                  taxonomy: item.name,
                  taxonomyId: item.id
                });
              }
            }
          ]"
        />
        <ActionMenuItem
          title="Utilities"
          :closeParent="toggleActions"
          :items="[
            {
              title: 'PDF',
              buttonLabel: 'Export',
              click: () => {
                appStore.openDrawer({
                  drawer: 'pdf',
                  taxonomy: item.name,
                  taxonomyId: item.id,
                  metadata: { taxonomy: item.name, taxonomyId: item.id }
                });
              }
            }
          ]"
        />
        <!-- Uncomment this code to use delete agent functionality from GUI -->
        <!-- <ActionMenuItem
            title="Delete"
            :closeParent="toggleActions"
            :items="[
              {
                title: 'Delete Agent',
                buttonLabel: 'Delete',
                click: () => deleteAgentMethod(item)
              }
            ]"
          /> -->
        <!-- <ActionMenuItem
            v-if="isEditor"
            title="Voice"
            :closeParent="toggleActions"
            :items="[
              {
                title: 'Phonetic Generator',
                buttonLabel: 'Run',
                click: () => {
                  appStore.openDrawer({ drawer: 'voiceJobs', taxonomy: item.name, taxonomyId: item.id });
                }
              }
            ]"
          /> -->

        <!-- <ActionMenuItem
            v-if="isEditor"
            title="Supervised Learning Centre"
            :closeParent="toggleActions"
            :items="[
              {
                title: 'Transcript Analysis',
                buttonLabel: 'Run',
                click: () => {
                  appStore.openDrawer({ drawer: 'transcriptAnalysisDrawer', taxonomy: item.name, taxonomyId: item.id });
                }
              }
            ]"
          /> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useHistoryStore, useNotificationsStore, useVcmStore, useVersionsStore } from '@/store/index.js';
import moment from 'moment';
import ActionMenuItem from './ActionMenuItem.vue';
import ActionHistoryItem from './ActionHistoryItem.vue';
import { mapState } from 'pinia';
import axios from 'axios';

export default {
  name: 'TaxonomyCard',
  components: {
    ActionMenuItem,
    ActionHistoryItem
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      opened: false,
      loading: 0,
      displayJobs: 10,
      // displayJobsValidation: 10,
      displayJobsTraining: 10,
      // displayJobsTranscript: 10,
      historyOpenedNLUTraining: false,
      // historyOpenedNLUValidation: false,
      historyOpenedUtilities: false,
      // historyOpenedTranscript: false,
      // historyOpenedVoiceJobs: false,
      actionsOpened: false
    };
  },
  // filters: {
  //   truncate: function (text, length, suffix) {
  //     return text.substring(0, length) + suffix;
  //   }
  // },
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    ...mapState(useAppStore, ['isEditor', 'isAdmin'])
  },
  methods: {
    // Uncomment below code to use delete agent functionality from GUI
    // async deleteAgentMethod(item) {
    //   this.$vs.loading();
    //   await this.deleteAgent(item.name);
    //   this.$vs.loading.close();
    //   this.$router.go();
    // },
    completedJobButtonText(jobType) {
      switch (jobType) {
        case 'pdf_export':
          return 'Download';
        //     case 'bot_on_demand':
        //       return 'Review';
        //     case 'json_export':
        //       return 'Download';
        //     case 'bulk_intent_status':
        //       return 'Update';
        case 'nlu_engine_train': // TODO: Soon will be Deprecated.
          return 'Review';
        case 'rasa_nlu_engine_train':
          return 'Review';
        //     // case 'transcript_analysis':
        //     //   return 'View';
      }
    },
    moment,
    utilitiesConcat(item) {
      const allJobs = this.appStore.jobsV3[item.id];
      return allJobs.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
    },
    pdfExportJobsConcat(item) {
      const allJobs = this.appStore.jobsV3[item.id];
      return allJobs.sort((a, b) => {
        return b.createdAt - a.createdAt;
      });
    },
    // transcriptAnalysisJobsConcat(item) {
    //   const allJobs = this.appStore.jobs[item.id];
    //   return [...allJobs['transcript_analysis']].sort((a, b) => {
    //     return b.job_creation_date?._seconds - a.job_creation_date?._seconds;
    //   });
    // }
    openJob(url, job) {
      //   if (job.job_type === 'bot_on_demand') {
      //     this.appStore.openDrawer({ drawer: 'botOnDemand', taxonomy: this.item.name, taxonomyId: this.item.id, metadata: job });
      if (job.job_type === 'nlu_engine_train') {
        // TODO: Soon will be Deprecated.
        this.appStore.openDrawer({
          drawer: 'nluReview',
          taxonomy: this.item.name,
          taxonomyId: this.item.id
        });
      }
      //    else if (job.job_type === 'transcript_analysis') {
      //      this.appStore.openDrawer({ drawer: 'transcriptAnalysisDrawer', taxonomy: this.item.name, taxonomyId: this.item.id });
      // }
      else {
        window.open(url, '__blank');
      }
    },
    // Uncomment below code to use delete agent functionality from GUI
    // async deleteAgent(name) {
    //   await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/transfer/deleteAgent`, {
    //     name
    //   });
    // },
    async retryPdfExport(job) {
      const date = Date.now();
      this.$vs.loading();
      try {
        let customToken = null;
        const ct = await axios({
          method: 'post',
          url: `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs/createToken`,
          data: {
            uid: this.appStore.getUser.uid
          },
          timeout: 30000
        });
        if (ct.status === 200) {
          customToken = ct.data;
        }
        if (customToken) {
          try {
            job.payload.date = date;
            job.payload.user = this.appStore.getUser.displayName;
            job.payload.email = this.appStore.getUser.email;
            job.payload.uid = this.appStore.getUser.uid;
            await axios({
              method: 'post',
              url: `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/pdf-export/job`,
              data: job.payload
            });
            this.$message({
              message: 'Retry job scheduled.',
              duration: 3000,
              showClose: true
            });
          } catch (error) {
            this.$message({
              message: 'An error occurred trying to generate the PDF. Please try again',
              duration: 3000,
              showClose: true
            });
            this.$vs.loading.close();
          }
          this.appStore.closeDrawer();
          this.$vs.loading.close();
        }
      } catch (error) {
        console.error('Error: ', error.message, error);
        this.$message({
          message: 'An error occurred trying to generate the PDF. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    },
    getColor(status) {
      // TODO: we need to normalize all those values
      const colors = {
        started: '#32637c',
        pending: '#32637c',
        running: '#1996f0',
        working: '#1996f0',
        in_progress: '#1996f0',
        complete: '#6cc900',
        completed: '#6cc900',
        done: '#6cc900',
        success: '#6cc900',
        error: '#fd5422',
        failed: '#fd5422',
        failure: '#fd5422'
      };
      return colors[status.toLowerCase()];
    },
    getStatusDisplayName(status) {
      // TODO: we need to normalize all those values
      switch (status) {
        case 'started':
          status = 'Queued';
          break;
        case 'done':
        case 'success':
        case 'complete':
        case 'completed':
          status = 'Completed';
          break;
        case 'in_progress':
        case 'job_started':
        case 'working':
        case 'running':
          status = 'Running';
          break;
        case 'error':
        case 'failed':
        case 'failure':
          status = 'Failed';
          break;
        default:
          status = 'Pending';
      }
      return status;
    },
    async refreshHistory() {
      this.loading = true;
      const v3JobTypes = ['pdf_export', 'release'];
      await this.appStore.getRuns({
        taxonomyId: this.item.id,
        taxonomyName: this.item.name
      });
      await this.appStore.getJobs({ taxonomyId: this.item.id });
      await this.appStore.getjobsV3({
        taxonomyId: this.item.id,
        taxonomyName: this.item.name,
        types: v3JobTypes
      });
      this.loading = false;
    },
    async toggleCard() {
      this.opened = !this.opened;
      await this.refreshHistory();
    },
    async toggleUtilities() {
      this.historyOpenedUtilities = !this.historyOpenedUtilities;
    },
    async toggleActions() {
      this.actionsOpened = !this.actionsOpened;
    }
  }
};
</script>

<style scoped>
.card-agent-name {
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.card {
  margin: 10px 0;
  font-size: 17px;
}
.card-body {
  min-height: 68px;
  padding: 10px;
}
.cardInner {
  width: 100%;
  height: 40px;
}
.cardInner button {
  margin-left: 10px;
}
.cardInner .mainRow,
.cardInner .mainRow .col {
  line-height: 50px;
}

.mainRow .expand {
  display: inline-block;
}

.fas.fa-caret-down.hidden,
.fas.fa-caret-right.hidden {
  visibility: hidden;
}

.mainRow .expand > div {
  color: #383f45;
  margin-right: 20px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 15px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  cursor: pointer;
  font-size: 17px;
}
.mainRow .expand > div:hover {
  background-color: #edf0f4;
}
.itemName {
  font-size: 17px;
  user-select: text;
}
.accordion {
  display: none;
  padding: 20px 20px 10px 20px;
  min-height: 100px;
  text-align: left;
}
.accordion.opened {
  display: block;
}
.buttonIcon {
  margin-right: 5px;
}
.item-group {
  list-style-type: none;
}
.cardInner button.vs-con-dropdown.parent-dropdown {
  margin-left: 0px !important;
}

.card.actionsOpened .btnactions {
  background: #fff !important;
  border-color: #fd5422 !important;
}

.buttons {
  display: none;
}
.card:hover .created,
.card.actionsOpened .created {
  display: none;
}
.card:hover .buttons {
  /* .card.actionsOpened .buttons { */
  display: flex;
  justify-content: flex-end;
}
.actions {
  position: absolute;
  display: none;
  right: 18px;
  top: 68px;
  z-index: 10;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.5);
  background-color: #ffffff;
  min-width: 280px;
}
.card.actionsOpened .actions {
  display: block;
}
.buttons button {
  padding: 0 20px;
}
.buttons button img {
  margin-right: 5px;
  display: inline-block;
}
.actionsMenu,
.actionHistory {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}
.actionHistory li:not(:first-child) {
  border-top: solid 1px #e3e8ee;
}
.actionsTable td,
.actionsTable th {
  font-size: 14px;
}
.actionsTable {
  width: 100%;
}

.actionsTable td {
  padding: 6px 10px;
  padding-top: 5px;
  padding-bottom: 3px;
}
.actionsTable tr:hover td {
  background-color: #e8f5fe;
}
.actionsDownload {
  height: 30px;
  padding: 4px 21px 7px 22px;
  width: 105px;
  visibility: hidden;
}
.actionsTable tr:hover .actionsDownload {
  visibility: visible;
}
.fa-refresh {
  cursor: pointer;
  float: right;
  margin-top: -2px;
  margin-right: -10px;
  font-size: 16px;
}
</style>
