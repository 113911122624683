import { useAppStore } from '@/store/index.js';
const auth = (component) => {
  const appStore = useAppStore();
  appStore.refreshToken().then((idTokenResult) => {
    if (!idTokenResult) return;
    return new Promise((resolve, reject) => {
      if (idTokenResult.claims) {
        appStore.updateClaims({
          admin: idTokenResult.claims.admin,
          role: idTokenResult.claims.role,
          // accessLevel: idTokenResult.claims.accessLevel,
          token: idTokenResult.token
          // taxonomy: idTokenResult.taxonomy || false
        });
        // component.currentRole = idTokenResult.claims.role;
        resolve(idTokenResult.token);
      } else {
        component.$message({
          message: 'An error occurred resolving user permissions. Try again later.',
          showClose: true
        });
        reject(idTokenResult);
      }
    })
      .then(() => {
        setTimeout(() => {
          if (typeof component?.retrieveUsers === 'function') component.retrieveUsers();
        }, 400);
      })
      .catch((error) => {
        console.error('Permissions denied', error);
        component.$message({
          message: 'An error occurred. Try again later.',
          showClose: true
        });
      });
  });
};

export default auth;
