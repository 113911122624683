<template>
  <div>
    <div v-show="drawer === 'rasaNluEngine'" class="overlay"></div>
    <DrawerMain id="rasaNluEngineDrawer" v-show="drawer === 'rasaNluEngine'">
      <template v-slot:header>
        <PageHeader title="Train RASA NLU Engine" subtitle="NLU Training / Train RASA NLU Engine" />
      </template>
      <template v-slot:filters></template>
      <template v-slot:body>
        <div v-if="currentTaxonomy" class="pageLayout">
          <IntentList @selectionChanged="selectionChanged" :collection="currentTaxonomy" v-show="drawer === 'rasaNluEngine'" :readOnly="readOnly" :metadata="drawerMetadata"> </IntentList>
          <div class="actionsOptions" v-if="!readOnly">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="trainRasaNluEngine" type="button" class="btn btn-primary" :class="{ disabled: isSafeToTrain }">Train</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useHistoryStore, useNotificationsStore, useVcmStore, useVersionsStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import IntentList from './Lists/IntentList.vue';
import { mapState } from 'pinia';
import PageHeader from '../../../src/components/Layout/PageHeader.vue';
import axios from 'axios';

export default {
  name: 'RasaNluEngineTrainingDrawer',
  components: {
    DrawerMain,
    IntentList,
    PageHeader
  },
  computed: {
    ...mapState(useAppStore, ['drawer', 'currentTaxonomy', 'getClaims', 'drawerMetadata', 'currentTaxonomyId', 'getUser']),
    isSafeToTrain() {
      return !this.statuses.length || !this.defaultModelType.value || !this.defaultModelConfig.value;
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      modelTypeOptions: '',
      modelConfigOptions: '',
      intents: [],
      statuses: [],
      allIntents: [],
      readOnly: false,
      defaultModelType: { text: 'Custom', value: 'Custom' },
      defaultModelConfig: { text: 'Multi-class', value: 'Multi-class' }
    };
  },
  methods: {
    modelTypeChange(event) {
      this.modelTypeOptions = event.text;
    },
    modelConfigChange(event) {
      this.modelConfigOptions = event.text;
    },
    selectionChanged(payload) {
      this.intents = payload.intents;
      this.statuses = payload.statuses;
      this.allIntents = payload.allIntents;
    },
    async trainRasaNluEngine() {
      try {
        if (!this.statuses.length) return alert('No status selected');
        const jobName = prompt('Please enter new Job name', '');
        if (jobName === null) return;
        if (!jobName)
          return this.$message({
            showClose: true,
            message: 'Please provide a job name',
            type: 'error'
          });

        this.$vs.loading();
        const date = Date.now();
        const statusNames = this.statuses.map((status) => status?.text);
        const nluEnginTrainParams = {
          dataset: this.appStore.currentTaxonomy,
          projectID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
          model_type: this.defaultModelType.value.toLowerCase(),
          statusNames
        };

        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs/rasa`, {
          cmpURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : `${process.env.VUE_APP_DEFAULT_TEST_PROJECT_CMP_URL}`,
          taxonomy: this.appStore.currentTaxonomy,
          taxonomyId: this.appStore.currentTaxonomyId,
          jobName,
          jobType: 'rasa_nlu_engine_train',
          date,
          user: this.appStore.getUser.displayName,
          ...nluEnginTrainParams
        });

        if (r.status === 200) {
          this.$message({
            message: 'RASA NLU Engine Train Successfully.',
            duration: 3000,
            showClose: true
          });
        } else {
          this.$message({
            message: 'An error occurred trying to Train RASA NLU Engine. Please try again',
            duration: 3000,
            showClose: true
          });
        }
        this.appStore.closeDrawer();
        this.$vs.loading.close();
      } catch (error) {
        this.$message({
          message: 'An error occurred trying to Train RASA NLU Engine. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    }
  }
};
</script>

<style>
#target-df-agent-input {
  background: white;
  border: solid 1px #8e9ba7;
  border-radius: 4px;
  height: 40px;
  padding: 5px;
  color: #35495e;
}
#target-df-agent-input::placeholder {
  color: rgb(117, 117, 117);
}
#validate-entities-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
#validate-entities-wrapper > p {
  margin: 0 0 0 10px;
  color: lightcoral;
}
#validate-entities-wrapper > p.validate-result-icons {
  font-size: 20px;
}
#validate-result-text.success {
  color: rgb(2, 154, 2);
}
#validate-result-text.fail {
  color: red;
}
.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
#target-df-agent-input.disabled {
  pointer-events: none;
  opacity: 0.6;
  color: rgb(180, 180, 180);
}
#target-df-agent-input.disabled::placeholder {
  color: black;
}

.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}
.multiselect.batch {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.language {
  width: 170px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  height: max-content;
  /* height: 40px; */
}
.multiselect.status {
  width: 300px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}

.multiselect.notes {
  width: 190px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background: white;
  /* height: 40px; */
  height: max-content;
}
</style>
