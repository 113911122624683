<template>
  <div>
    <div v-show="appStore.drawer === 'highlights'" class="overlay"></div>
    <DrawerMain id="highlightsDrawer" v-show="appStore.drawer === 'highlights'">
      <template v-slot:header>
        <PageHeader title="Clear All Highlights" subtitle="Utilities / Configuration" />
      </template>
      <template v-slot:filters>
        <!-- <div class="filters">Filters Work In Progress </div> -->
      </template>
      <template v-slot:body>
        <div v-if="appStore.currentTaxonomy" class="pageLayout">
          <IntentList @selectionChanged="selectionChanged" :collection="appStore.currentTaxonomy" v-show="appStore.drawer === 'highlights'"> </IntentList>
          <div class="actionsOptions">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="clearHighlights" type="button" class="btn btn-primary">Clear</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import IntentList from './Lists/IntentList.vue';
import axios from 'axios';

export default {
  name: 'RemoveHighlights',
  components: {
    DrawerMain,
    IntentList
  },
  data() {
    const appStore = useAppStore();
    return {
      notes: [],
      intents: [],
      filtersNotes:
        appStore.getClaims?.role != 'CLIENT'
          ? [
              { text: 'CLIENT', value: 'CLIENT', selected: false },
              { text: 'INTERNAL', value: 'INTERNAL', selected: false }
            ]
          : [{ text: 'CLIENT', value: 'CLIENT', selected: false }]
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    autoSaveStore() {
      return useAutoSaveStore();
    },
    commentsStore() {
      return useCommentsStore();
    },
    versionsStore() {
      return useVersionsStore();
    },
    historyStore() {
      return useHistoryStore();
    },
    notificationsStore() {
      return useNotificationsStore();
    },
    vcmStore() {
      return useVcmStore();
    }
  },
  methods: {
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    selectionChanged(payload) {
      this.intents = payload.intents;
    },
    async clearHighlights() {
      try {
        if (!this.intents.length) return alert('No intents selected');
        const jobName = prompt('Please enter new Job name', '');
        if (jobName === null) return;
        if (!jobName)
          return this.$message({
            showClose: true,
            message: 'Please provide a job name',
            type: 'error'
          });

        this.$vs.loading();
        const date = Date.now();

        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs`, {
          cmpURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : `${process.env.VUE_APP_DEFAULT_TEST_PROJECT_CMP_URL}`,
          taxonomy: this.appStore.currentTaxonomy,
          taxonomyId: this.appStore.currentTaxonomyId,
          jobName,
          targetUrl: process.env.NODE_ENV === 'production' ? `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/history/highlights/remove/responses` : `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/history/highlights/remove/responses`,
          jobType: 'clearHighlights',
          slots: this.intents.map((s) => {
            return {
              id: s.id,
              name: s.name,
              impersonate: s.impersonate != 'NONE' ? s.impersonate : false
            };
          }),
          uid: this.appStore.getUser.uid,
          date,
          email: this.appStore.getUser.email,
          idToken: this.appStore.getClaims.token,
          user: this.appStore.getUser.displayName
        });

        if (r.status === 200) {
          this.$message({
            message: 'Highlight removal job scheduled.',
            duration: 3000,
            showClose: true
          });
        } else {
          this.$message({
            message: 'An error occurred trying to removal highlights. Please try again',
            duration: 3000,
            showClose: true
          });
        }
        this.appStore.closeDrawer();
        this.$vs.loading.close();
      } catch (error) {
        this.$message({
          message: 'An error occurred trying to remove highlights. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    }
  }
};
</script>
