<template>
  <div id="container-fluid" class="container-fluid taxonomyShow">
    <!-- <PageHeader :showBreadcrumbs="true" title="Intents" subtitle="" />
      <br /> -->
    <div class="flows-header">
      <AgentsSearch :agentName="agentName" :tab="tab" />
    </div>
    <AgentIntents :page="true" :collection="collection" v-if="view === true && !appStore.getClaims.admin" />
    <AgentIntentsSunburst :collection="collection" v-if="view === true" class="sunburst" @reset="inAnimationDuration += 0.1" :key="inAnimationDuration" :minAngleDisplayed="0" :inAnimationDuration="inAnimationDuration" :outAnimationDuration="outAnimationDuration" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import AgentIntentsSunburst from '@/components/Sunburst/AgentIntentsSunburst';
import 'material-icons/iconfont/material-icons.css';
import { setTimeout } from 'timers';
import AgentIntents from '@/views/AgentIntents';
import { mapState } from 'pinia';
import AgentsSearch from '../components/Layout/3.0/AgentsSearch.vue';

export default {
  name: 'AppTaxonomy',
  props: {
    agentName: String,
    tab: String
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      // data,
      clientLogo: process.env.VUE_APP_CLIENT_LOGO,
      clientName: process.env.VUE_APP_CLIENT_NAME,
      avatar: process.env.VUE_APP_CLIENT_LOGO,
      minAngleDisplayed: 0.05,
      inAnimationDuration: 100,
      outAnimationDuration: 1300,
      active: false,
      data: '',
      newTaxonomyName: '',
      copyUtterances: false,
      view: '',
      popup: true,
      collection: '',
      wheels: [],
      view_change: false,
      copyEntities: false,
      switchView: true,
      viewNonTax: false,
      filterTaxonomy: '',
      loadTaxonomyTimeout: null
    };
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies'])
  },
  methods: {
    trainTaxonomy(collection) {
      this.appStore.TRAIN_TAXONOMY({
        collection
      });
    },
    loadTaxonomy(value) {
      this.collection = value;
      this.popup = false;
      this.view = true;
    }
  },
  async mounted() {
    this.popup = true;

    if (this.$route.params.id) {
      this.loadTaxonomyTimeout = setTimeout(() => {
        this.loadTaxonomy(this.appStore.taxonomies.find((i) => i.id === this.$route.params.id).name);
      }, 1000);
    } else if (this.$route.query.flow) {
      this.loadTaxonomy(this.$route.query.flow);
    } else if (this.$route.query.collection) {
      this.loadTaxonomy(this.$route.query.collection);
    } else if (this.$route.params.modal) {
      this.loadTaxonomy(this.$route.params.collection);
    }
  },
  beforeUnmount() {
    clearTimeout(this.loadTaxonomyTimeout);
  },
  components: {
    AgentIntentsSunburst,
    AgentIntents,
    AgentsSearch
  }
};
</script>

<style scoped>
.container-fluid {
  background: #edf0f4;
  background-size: cover;
  padding: 0px !important;
  height: 100% !important;
}

.container-fluid.taxonomyShow {
  background-image: none;
  background: white;
  display: unset;
}

.flows-header {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f6fa;
  padding: 0 40px;
}
</style>
