<template>
  <div>
    <el-carousel arrow="never" height="240px">
      <el-carousel-item v-for="(slide, i) in slides" :key="i">
        <img width="220px" height="140px" :src="slide.imageUri" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  props: {
    slides: {
      type: Array
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    titleFontSize: {
      type: String,
      default: '16px'
    },
    subtitleFontSize: {
      type: String,
      default: '13px'
    }
  },
  components: {},
  data() {
    return {};
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.el-carousel__button {
  background: black;
}
</style>
