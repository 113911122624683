<template>
  <BulkActionsModal>
    <template #title>
      <h3>Bulk Publish Flows</h3>
    </template>

    <template #body>
      <div v-if="showInvalidFlows" class="invalid-box">
        <ConversationBuilderIcon name="warning_icon" :width="20" />
        <div class="invalid-box-flows">
          <span> The following flows have invalid actions: </span>
          <ul v-for="(flow, index) in invalidFlows" :key="index">
            <li>{{ flow }}</li>
          </ul>
        </div>
      </div>
      {{ cardContent }}
    </template>

    <template #actions>
      <v-btn v-if="!showInvalidFlows" class="v-btn-cancel bulk-card-btn-cancel" flat @click="closeModal"> Cancel </v-btn>
      <v-btn v-if="!showInvalidFlows" class="v-btn-bulk bulk-card-btn-publish" dark flat @click="bulkPublishFlows" :disabled="!isAdmin"> Publish </v-btn>
      <v-btn v-else class="v-btn-bulk bulk-card-btn-publish" dark flat @click="closeModal"> Ok </v-btn>
    </template>
  </BulkActionsModal>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';
import BulkActionsModal from './BulkActionsModal.vue';
import formattedValidationMessage from '../../helpers/formattedValidationMessage';
export default {
  name: 'BulkPublishModal',
  components: {
    BulkActionsModal
  },
  data() {
    return {
      appStore: useAppStore(),
      cardContent: 'You are about to publish the selected flow drafts. Do you wish to continue with this action?',
      showInvalidFlows: false,
      invalidFlows: []
    };
  },
  computed: {
    ...mapState(useAppStore, ['isAdmin'])
  },
  props: {
    selectedFlowCount: Number,
    agentName: String,
    selectedFlows: Object
  },
  methods: {
    updateData(dataKey, dataValue) {
      this[dataKey] = dataValue;
    },
    closeModal() {
      this.status = null;
      this.$emit('closeModal');
    },
    async bulkPublishFlows() {
      try {
        this.$vs.loading();

        const invalidResults = await this.appStore.bulkValidateFlows({
          agentName: this.agentName,
          flows: this.selectedFlows
        });

        this.$vs.loading.close();
        if (invalidResults.length > 0) {
          this.invalidFlows = invalidResults.map((r) => {
            return formattedValidationMessage(r, Object.keys(this.appStore.languageCodes)?.length > 1);
          });
          this.cardContent = 'Resolve invalid actions to be able to publish flows.';
          this.showInvalidFlows = true;
        } else {
          this.$emit('bulkPublishFlows');
          this.$emit('closeModal');
        }
      } catch (error) {
        this.$vs.loading.close();
        this.$message({
          showClose: true,
          message: 'Bulk publish failed.',
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="scss">
.bulk-actions-modal {
  height: auto;
  .vm--modal {
    overflow: visible !important;
  }
  .v-card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .bulk-actions-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .v-btn .v-btn--icon .theme--light {
    padding-left: 0.3em;
  }
  .bulk-card-btn-cancel {
    background-color: #e4e4e4;
  }
  .bulk-card-btn-publish {
    background-color: #1996f0;
    color: #ffffff;
    &:disabled {
      background-color: #d7dde5;
      &.v-btn--disabled {
        color: darkgrey !important;
      }
    }
  }
  .bulk-card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
}
.invalid-box {
  padding: 10px;
  border-radius: 8px;
  background-color: rgba(252, 204, 5, 0.1);
  display: flex;
  margin-bottom: 10px;
  max-height: 160px;
  overflow: auto;
  font-size: 14px;
  & .icon {
    margin: 10px;
  }
  &-flows {
    margin: 10px;
  }
}
</style>
