import { Mark, mergeAttributes } from '@tiptap/core';

export const Telephone = Mark.create({
  name: 'telephone',

  addOptions: {
    openOnClick: true
  },

  parseHTML() {
    return [
      {
        tag: 'a[href]',
        getAttrs: (dom) => ({
          href: dom.getAttribute('href')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['a', mergeAttributes({ phoneNumber: 'true', ...HTMLAttributes }), 0];
  },

  addCommands() {
    return {
      setTelephone:
        (attributes) =>
        ({ commands }) => {
          if (attributes.href) {
            if (attributes.href.includes(null)) {
              return commands.unsetMark('telephone');
            }
            return commands.setMark('telephone', attributes);
          }
        }
    };
  },

  addNodeView() {
    return {
      props: {
        handleClick: (event, view, getPos, node) => {
          if (this.options.openOnClick && node.attrs.href && event.target instanceof HTMLAnchorElement) {
            event.stopPropagation();
            window.open(node.attrs.href, '_blank');
            return true;
          }

          return false;
        }
      }
    };
  }
});
