import { v4 as uuidv4 } from 'uuid';

export const ONE_MINUTE_IN_MS = 60000;
const NO_OP = () => {};

class Watcher {
  constructor({ onSaveVersionRequested, onDirtyState }) {
    this.saveFrequency = ONE_MINUTE_IN_MS;
    this.onSaveVersionRequested = onSaveVersionRequested || NO_OP;
    this.onDirtyState = onDirtyState || NO_OP;
    this.lastSave = 0;
    this.lastChange = 0;
  }

  start() {
    const checkSave = async () => {
      const now = Date.now();
      const elapsed = now - this.lastChange;
      const dirty = this.isDirty();
      if (dirty && elapsed > this.saveFrequency) {
        this.onSaveVersionRequested();
      }
    };
    this.intervalID = setInterval(checkSave, 1000);
  }

  isDirty() {
    return this.lastChange > this.lastSave;
  }

  stop() {
    clearInterval(this.intervalID);
  }

  setLastSave(lastSave) {
    this.lastSave = lastSave;
    console.log('[VCM]', { lastSave });
  }

  setLastChange(lastChange) {
    if (this.lastChange <= this.lastSave) {
      this.lastChange = lastChange;
    }
    this.onDirtyState();
    console.log('[VCM]', { firstUnsaved: new Date(this.lastChange) });
  }
}

export class VersionControl {
  /**
   * @param {{ getState: () => Object, subscribe(handler: Function): Function, setState: (state) => void  }} store
   * @param {{ saveVersion:  () => Promise<boolean> }} db
   */
  constructor({ store, db }) {
    this._store = store;
    this._db = db;
  }

  _startWatchingStore() {
    const onChange = () => {
      const lastChange = Date.now();
      this._watcher.setLastChange(lastChange);
    };
    const saveRequested = (force) => this.saveVersion(force);
    this._stopWatchingStore = this._store.watchStore(onChange, saveRequested);
  }

  start() {
    const onSaveVersionRequested = async (force) => await this.saveVersion(force);
    const onDirtyState = () => this.triggerDirty();
    this._watcher = new Watcher({ onSaveVersionRequested, onDirtyState });
    this._watcher.start();
    this._startWatchingStore();
  }

  stop() {
    this._watcher.stop();
    this._stopWatchingStore();
  }

  async saveVersion(force = false) {
    const dirty = this._watcher.isDirty();
    if (!force && !dirty) {
      return;
    }
    const versionId = uuidv4();
    console.log('[VCM]', { saving: versionId, force, dirty });
    this._watcher.setLastSave(Date.now());
    const promise = this._db.saveVersion(versionId);
    return promise.then(() => versionId).catch((error) => console.error(error));
  }

  get watcher() {
    return this._watcher;
  }

  triggerDirty() {
    this._store.updateStatus('dirty');
  }
}
