<template>
  <div class="editor">
    <div :editor="editor">
      <div class="menubar">
        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().run()">
          <i class="fas fa-bold"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
          <i class="fas fa-italic"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
          <i class="fas fa-strikethrough"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('underline') }" @click="editor.commands.toggleUnderline()">
          <i class="fas fa-underline"></i>
        </button>

        <button class="menubar__button" :class="{ 'is-active': editor.isActive('codeBlock') }" @click="editor.commands.toggleCodeBlock()">
          <i class="fas fa-code"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('bulletList') }" @click="editor.commands.toggleBulletList()">
          <i class="fas fa-circle"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" :class="{ 'is-active': editor.isActive('orderedList') }" @click="editor.commands.toggleOrderedList()">
          <i class="fas fa-list"></i>
        </button>

        <button v-if="type === 'text'" class="menubar__button" @click="editor.commands.toggleHorizontalRule()">
          <i class="fas fa-grip-lines"></i>
        </button>

        <button class="menubar__button" @click="editor.commands.undo()">
          <i class="fas fa-undo"></i>
        </button>

        <button class="menubar__button" @click="editor.commands.redo()">
          <i class="fas fa-redo-alt"></i>
        </button>
      </div>
    </div>

    <editor-content class="editor__content__bubble" :editor="editor" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { nextTick } from 'vue';
import Link from '@tiptap/extension-link';
import { SayAsTelephone } from '@/components/ConversationBuilder/Extensions/index.js';

import { Editor, EditorContent } from '@tiptap/vue-3';

import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Focus from '@tiptap/extension-focus';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { lowlight } from 'lowlight';

import css from 'highlight.js/lib/languages/css';
import js from 'highlight.js/lib/languages/javascript';
import ts from 'highlight.js/lib/languages/typescript';
import html from 'highlight.js/lib/languages/xml';

lowlight.registerLanguage('html', html);
lowlight.registerLanguage('css', css);
lowlight.registerLanguage('js', js);
lowlight.registerLanguage('ts', ts);

export default {
  components: {
    EditorContent
  },
  props: {
    value: {
      type: [String, Array]
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      editor: null,
      editorChange: false,
      linkUrl: null,
      linkMenuIsActive: false
    };
  },
  mounted() {
    this.editor = new Editor({
      onUpdate: ({ getHTML }) => {
        this.editorChange = true;
        this.$emit('input', getHTML());
      },
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          }
        }),
        SayAsTelephone,
        CodeBlockLowlight.configure({
          lowlight
        }),
        Highlight,
        Superscript,
        Subscript,
        TaskItem,
        TaskList,
        Link,
        Image,
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Table.configure({
          resizable: true
        }),
        TableHeader,
        TableCell,
        TableRow,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: `Paste ${this.type}`.toUpperCase(),
          showOnlyWhenEditable: true,
          showOnlyCurrent: true
        })
      ]
    });
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(url) {
      this.editor.commands.toggleLink({ href: url });
      this.hideLinkMenu();
    }
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) this.editor.destroy();
    } catch (err) {
      //
    }
  },
  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style scoped>
.tableOptions {
  font-size: 8px;
  font-weight: 300;
  text-decoration: underline;
}

.editor {
  position: relative;
  width: 90%;
  margin: 1rem auto 0rem;
}

.menubar {
  margin-bottom: 0rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.editor__content__bubble {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  font-size: 12px;
  /* font-weight: bold; */
  color: rgb(0, 155, 207);
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 1px;
  -webkit-transition: none;
  transition: none;
  /* padding: 5px; */
}

.editor__content__table {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  max-width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 5px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar__button__menu {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: white;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.menubar.is-focused {
  visibility: visible;
  opacity: 1;
}
</style>

<style>
.editor__content__table table td {
  cursor: col-resize;
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor__content__table table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
}

.editor__content__table table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editor__content_bubble table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.is-empty.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.editor__content__bubble p {
  text-align: left;
}

/* .has-focus {
  border-radius: 3px;
    box-shadow: 0 0 0 3px black
} */
</style>

<style lang="scss" scoped>
.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    display: flex;
    align-items: center;
  }
  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: white;
  }
}
</style>

<style lang="scss">
// pre {
//     &::before {
//         content: attr(data-language);
//         text-transform: uppercase;
//         display: block;
//         text-align: right;
//         font-weight: bold;
//         font-size: 0.6rem;
//     }
//     code {
//         .hljs-comment,
//         .hljs-quote {
//             color: #999999;
//         }
//         .hljs-variable,
//         .hljs-template-variable,
//         .hljs-attribute,
//         .hljs-tag,
//         .hljs-name,
//         .hljs-regexp,
//         .hljs-link,
//         .hljs-name,
//         .hljs-selector-id,
//         .hljs-selector-class {
//             color: #f2777a;
//         }
//         .hljs-number,
//         .hljs-meta,
//         .hljs-built_in,
//         .hljs-builtin-name,
//         .hljs-literal,
//         .hljs-type,
//         .hljs-params {
//             color: #f99157;
//         }
//         .hljs-string,
//         .hljs-symbol,
//         .hljs-bullet {
//             color: #99cc99;
//         }
//         .hljs-title,
//         .hljs-section {
//             color: #ffcc66;
//         }
//         .hljs-keyword,
//         .hljs-selector-tag {
//             color: #6699cc;
//         }
//         .hljs-emphasis {
//             font-style: italic;
//         }
//         .hljs-strong {
//             font-weight: 700;
//         }
//     }
// }
//
</style>
