import { defineStore } from 'pinia';

import defaultState from './defaultState';
import actions from './actions';
import getters from './getters';

export const useAppStore = defineStore({
  id: 'appStore',
  state: () => defaultState,
  actions,
  getters
});
