import { FlowUserRPCAdapter } from '@/helpers/rpc/FlowUserRPCAdapter';
import { useAppStore, useCollabStore, useVcmStore } from '@/store/index.js';
import firebase from 'firebase/compat/app';
import debounce from 'lodash/debounce';
import { CollabService } from '@/helpers/collabService';

function checkPresence(users, getInfo) {
  const { editor, readers } = users;
  const email = firebase.auth().currentUser?.email;
  if (email && [editor, ...readers].includes(email) === false) {
    const info = getInfo();
    FlowUserRPCAdapter.sendStart(info);
  }
}

export function collabPlugin() {
  const appStore = useAppStore();
  const collabStore = useCollabStore();
  const vcmStore = useVcmStore();

  const getStore = () => {
    const getInfo = () => ({
      slotId: appStore.current?.slot?.id,
      language: appStore.language,
      taxonomy: `${appStore.current?.taxonomy}_flows`
    });

    const onActivity = () => {
      const info = getInfo();
      FlowUserRPCAdapter.onActivity(info);
    };

    let remove1 = undefined;
    let remove2 = undefined;
    const stop = () => {
      remove1?.();
      remove2?.();
      remove1 = undefined;
      remove2 = undefined;
    };
    const start = () => {
      remove1 = vcmStore.$onAction(({ name, args: [arg1] }) => {
        if (name === 'updateStatus' && arg1 === 'dirty') {
          onActivity();
        }
      });
      remove2 = appStore.$onAction(({ name /* , args: [arg1] */ }) => {
        if (name === 'setComponentId') {
          onActivity();
        }
      });
    };

    const onFlowUpdate = ({ users } = {}) => {
      if (users) {
        checkPresence(users, getInfo);
        collabStore.updateFlowUsers(users);
      }
    };

    const onErrorOrConnectionChange = debounce(
      (message) => {
        collabStore.onErrorOrConnectionChange(message);
      },
      500,
      { trailing: true }
    );

    return {
      getInfo,
      onFlowUpdate,
      onErrorOrConnectionChange,
      start,
      stop
    };
  };

  return new CollabService({ store: getStore(), options: {} });
}
