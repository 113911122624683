const STATUS_NAMES = ['Disabled', 'None', 'In progress', 'Missing content', 'Ready for review', 'Client reviewed', 'Approved', 'Impersonation', 'Tested by QA', 'AE/PC', 'ONFs', 'Form', 'Offboarding'];
const statuses = [
  {
    name: 'Disabled',
    value: false
  },
  {
    name: 'None',
    value: false
  },
  {
    name: 'In progress',
    value: false
  },
  {
    name: 'Missing content',
    value: false
  },
  {
    name: 'Ready for review',
    value: false
  },
  {
    name: 'Client reviewed',
    value: false
  },
  {
    name: 'Approved',
    value: false
  },
  {
    name: 'Impersonation',
    value: false
  },
  {
    name: 'Tested by QA',
    value: false
  },
  {
    name: 'AE/PC',
    value: false
  },
  {
    name: 'ONFs',
    value: false
  },
  {
    name: 'Form',
    value: false
  },
  {
    name: 'Offboarding',
    value: false
  }
];

export { STATUS_NAMES, statuses };
