<template>
  <div v-if="show" class="release-modal">
    <div class="release-modal-inner-wrapper">
      <div class="release-modal-header">
        {{ title }}
      </div>
      <div v-if="showInvalidFlows" class="release-modal-body">
        <div class="invalid-box">
          <ConversationBuilderIcon name="warning_icon" :width="20" />
          <div class="invalid-box-flows">
            <span>The following flows have invalid actions:</span>
            <ul v-for="(flow, index) in invalidFlows" :key="index">
              <li>{{ flow }}</li>
            </ul>
          </div>
        </div>
        <div>Resolve invalid actions to be able to publish flows.</div>
      </div>
      <div v-else class="release-modal-body" v-html="content" />
      <div class="release-modal-footer">
        <div v-if="!showInvalidFlows" class="cancel-release-button">
          <button type="button" class="btn release-modal-cancel-button" @click="showHide(title)">Cancel</button>
        </div>
        <div v-if="title === 'Release' && !showInvalidFlows" class="release-button">
          <button type="button" class="btn release-modal-release-button" @click="release">Release</button>
        </div>
        <div v-if="title === 'Reset'" class="release-button">
          <button type="button" class="btn release-modal-release-button" @click="reset">Continue</button>
        </div>
        <div v-if="showInvalidFlows" class="release-button">
          <button type="button" class="btn release-modal-release-button" @click="reset">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import formattedValidationMessage from '../../../helpers/formattedValidationMessage';

export default {
  name: 'ConfirmationModal',
  props: {
    show: Boolean,
    content: String,
    title: String,
    agentName: String,
    selectedFlows: Object
  },
  data() {
    return {
      appStore: useAppStore(),
      showInvalidFlows: false,
      invalidFlows: []
    };
  },
  methods: {
    showHide(modal) {
      this.showInvalidFlows = false;
      this.invalidFlows = [];
      this.$emit('showHideModals', modal.toLowerCase());
    },
    reset() {
      this.showInvalidFlows = false;
      this.invalidFlows = [];
      this.$emit('showHideModals', 'reset');
      this.$emit('reset-page');
    },
    async release() {
      try {
        this.$vs.loading();

        const invalidResults = await this.appStore.bulkValidateFlows({
          agentName: this.agentName,
          flows: this.selectedFlows
        });

        this.$vs.loading.close();
        if (invalidResults.length > 0) {
          this.invalidFlows = invalidResults.map((r) => {
            return formattedValidationMessage(r, Object.keys(this.appStore.languageCodes)?.length > 1);
          });
          this.showInvalidFlows = true;
        } else {
          this.$emit('showHideModals', 'release');
          this.$emit('release');
        }
      } catch (error) {
        console.error(`Error: Release failed: `, error);
        this.$vs.loading.close();
        this.$message({
          showClose: true,
          message: 'Release failed.',
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.release-modal {
  position: absolute;
  z-index: 999;
  max-width: 628px;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  border: solid 1px #d7dde5;
}
.release-modal-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 40px 30px 40px;
  gap: 20px;
}
.release-modal-header {
  font-size: 24px;
  color: #383f45;
}
.release-modal-body {
  font-size: 17px;
  color: #383f45;
}
.release-modal-footer {
  display: flex;
}
.release-modal-release-button {
  background-color: #1996f0;
  color: #fff;
  width: 120px !important;
}
.release-modal-cancel-button {
  background-color: #f2f2f2;
  margin-right: 20px;
  width: 120px !important;
}
</style>
