<template>
  <div @mouseover="hover = true" @mouseleave="hover = false" class="message-editor" id="editor" :class="{}">
    <div v-if="editor" :editor="editor">
      <div class="menubar">
        <button :title="`Reference`" class="menubar__button" @click="editHorizontalRule" v-if="!excludeTools.includes('chevron')">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="12" :name="referencePresent" />
        </button>
        <button :title="`Link`" class="menubar__button" :class="{ 'is-active': editor.isActive('link') || linkMenuIsActive }" @click="toggleLinkMenu()">
          <ConversationBuilderIcon :custom-style="iconStyle" :styleOnIcon="{ 'border-radius': '3px' }" :width="30" :name="`link${editor.isActive('link') ? '_active' : ''}`" />
        </button>
        <button :title="`Bold`" class="menubar__button" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().focus().toggleBold().run()">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="12" :name="`bold${editor.isActive('bold') ? '_active' : ''}`" />
        </button>
        <button :title="`Italic`" class="menubar__button" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="7" :name="`italics${editor.isActive('italic') ? '_active' : ''}`" />
        </button>
        <button :title="`Strikethrough`" class="menubar__button" :class="{ 'is-active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
          <span class="fa fa-strikethrough fa-lg strikethrough" :class="{ 'is-active-st': editor.isActive('strike') }"></span>
        </button>
        <button :title="`Subscript`" class="menubar__button" :class="{ 'is-active': editor.isActive('subscript') }" @click="scriptClick('sub', $event)">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="12" :name="`subscript${editor.isActive('subscript') ? '_active' : ''}`" />
        </button>
        <button :title="`Superscript`" class="menubar__button" :class="{ 'is-active': editor.isActive('superscript') }" @click="scriptClick('super', $event)">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="12" :name="`superscript${editor.isActive('superscript') ? '_active' : ''}`" />
        </button>
        <button :title="`Unordered list`" class="menubar__button" :class="{ 'is-active': editor.isActive('bulletList') }" @click="editor.commands.toggleBulletList()">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="12" :name="`bullet${editor.isActive('bulletList') ? '_active' : ''}`" />
        </button>
        <button :title="`Highlight`" class="menubar__button" :class="{ 'is-active': editor.isActive('highlight') }" @click="editor.commands.toggleHighlight()">
          <ConversationBuilderIcon :custom-style="iconStyle" :width="14" name="highlight" />
        </button>
      </div>
    </div>

    <hr />

    <div class="row" :class="{ decision: type === 'decision', flow: type === 'flow' }">
      <div class="col-12">
        <editor-content v-if="showEditorContent" id="sidebar-message-editor-input-field" :class="editorContentClass" :editor="editor"> </editor-content>
        <div @click="removeHorizontalRule" class="removeButton">
          <ConversationBuilderIcon v-if="horizontalExists" class="add-button" :width="20" name="remove" nameHover="remove-hover" />
        </div>
      </div>
    </div>

    <div class="popup-wrapper" v-if="linkMenuIsActive">
      <div class="link-wrapper">
        <div class="px-3 py-4 w-100" @click="focusInput" @mouseleave="isOverLinkInput = false" @mouseenter="isOverLinkInput = true">
          <p>URL link</p>
          <div class="link-input-wrapper">
            <img :src="require('../../../../../assets/icons/linkUrl_dark.svg')" alt="" @click="openLink" />
            <p @focusout="saveLink(false, false)" @input="onLinkInput" contenteditable ref="linkInput" class="popup-input px-2 py-1" @keydown.esc="saveLink(false, true)" @keydown.enter="saveLink(true, false)">
              {{ linkUrlValue }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { nextTick } from 'vue';
import { Alignment, Superscript, Subscript, Highlight } from '@/components/ConversationBuilder/Extensions/index.js';
import Link from '@tiptap/extension-link';
import { mapActions } from 'pinia';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Focus from '@tiptap/extension-focus';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';

export default {
  name: 'MessageEditorSideBar',
  components: {
    EditorContent
  },
  props: {
    excludeTools: {
      type: Array,
      default: () => {
        return [];
      }
    },
    avatar: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    value: {
      type: String,
      default: ''
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    changedFocus: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      tempLink: '',
      placeholder: {
        en: '',
        fr: ''
      },
      hover: false,
      textBetween: '',
      editor: null,
      editorChange: false,
      linkUrl: null,
      observer: null,
      textLength: 0,
      colorsMap: {},
      iconStyle: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        width: '100%',
        height: '100%'
      },
      timeout: null,
      isOverLinkInput: false,
      isLinkActive: false,
      linkMenuIsActive: false,
      loadHTMLTimeout: null
    };
  },
  computed: {
    horizontalExists() {
      if (typeof this.value === 'string' && this.value.includes('<hr>')) {
        return true;
      } else {
        return false;
      }
    },
    linkUrlValue: {
      get() {
        return this.getLink();
      },
      set(newValue) {
        newValue ? this.editor.commands.setLink({ href: newValue }) : this.editor.commands.unsetLink();
        this.$refs.linkInput?.focus();
      }
    },
    showEditorContent() {
      return this.type === 'message' || this.type === 'decision' || this.type === 'flow' || this.type === 'carousel';
    },
    referencePresent() {
      if (typeof this.value === 'string' && this.value.includes('<hr>')) {
        return 'reference-2-active';
      } else {
        return 'reference-2';
      }
    },
    editorContentClass() {
      return {
        message__content__bubble__editor: this.type === 'message',
        'message__content__bubble decision': this.type === 'decision',
        'message__content__bubble flow': this.type === 'flow',
        [`${this.id} ${this.tabindex} ${this.avatar}`]: true
      };
    },
    setLanguage() {
      return this.appStore.language;
    },
    placeHolderText() {
      if (this.table) {
        return '';
      } else if (this.type === 'note') {
        return 'Record Notes';
      } else if (this.type === 'reply') {
        return 'Leave comment';
      } else {
        return this.setLanguage;
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: this.readOnly ? false : true,
      onInit: () => {},
      onTransaction: ({ editor }) => {
        this.loadHTMLTimeout = setTimeout(() => {
          this.$emit('input', editor.getHTML());
        }, 200);
      },
      onFocus: ({ editor }) => {
        const state = editor?.state;
        if (state) {
          this.textLength = state.doc.textContent.length;
          this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
        }
      },
      onBlur: ({ editor, event }) => {
        if (event != null) this.textLength = 0;
        const state = editor?.state;
        if (state) {
          this.textLength = state.doc.textContent.length;
          this.textBetween = state.doc.textBetween(state.selection.from, state.selection.to, ' ');
        }
      },
      onUpdate: ({ editor, transaction }) => {
        this.editorChange = true;
        this.$emit('input', editor.getHTML());
        this.textLength = transaction.doc.textContent.length;
      },
      useBuiltInExtensions: true,
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Superscript,
        Subscript,
        Highlight,
        Alignment,
        TaskItem,
        TaskList,
        Link.configure({
          autolink: false,
          openOnClick: false,
        }),
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: '',
          showOnlyWhenEditable: true,
          showOnlyCurrent: true
        })
      ]
    });
  },
  methods: {
    ...mapActions(useAppStore, ['sendSceneToDB']),
    userSelectedAll(state) {
      // Detect if user has selected all the text in the message edit component; see COMAPP-198 bug
      return state?.doc?.textContent?.length === state?.doc?.textBetween(state?.selection?.from, state?.selection?.to, ' ')?.length ? true : false;
    },
    getLink(validateFunction) {
      try {
        const state = this.editor?.state;
        if (validateFunction != undefined && validateFunction(state)) {
          return false;
        }

        const { from, to } = state?.selection ?? {};
        if (!from || !to) return '';
        let marks = [];
        state?.doc?.nodesBetween(from, to, (node) => {
          marks = [...marks, ...(node?.marks ?? [])];
        });

        if (marks.filter((markItem) => markItem?.type?.name === 'link').length > 1) return undefined;
        const mark = marks.find((markItem) => markItem?.type?.name === 'link');
        return mark && mark.attrs?.href ? mark.attrs.href : '';
      } catch (err) {
        console.error('Error in getLink method:', err);
        return false;
      }
    },
    toggleLinkMenu() {
      if (this.editor.state) {
        if (this.editor.state.selection.from !== this.editor.state.selection.to) {
          // Checking if we are selecting any text, and activate link entry only if text is selected
          this.linkMenuIsActive = true;
          nextTick(() => {
            this.$refs.linkInput?.focus();
          });
        }
      }
    },
    removeHorizontalRule() {
      if (typeof this.value === 'string' && this.editor) {
        const stripChevron = this.value.split('<hr>')[0];
        this.editor.commands.setContent(stripChevron);
      }
    },
    addHorizontalRule() {
      if (typeof this.value === 'string' && this.editor) {
        if (!this.value.includes('<p>')) {
          this.editor.commands.setContent('<p></p>' + this.value + '<hr><p></p>');
        } else {
          this.editor.commands.setContent(this.value + '<hr><p></p>');
        }
      }
    },
    editHorizontalRule() {
      nextTick(() => {
        if (!this.horizontalExists) {
          this.addHorizontalRule();
        } else {
          this.removeHorizontalRule();
        }
      });
    },
    scriptClick(type, event) {
      if (event) {
        event.stopPropagation();
      }
      if (type === 'sub' && !this.editor.isActive('superscript')) {
        this.editor.commands.toggleSubscript();
      } else if (type === 'super' && !this.editor.isActive('subscript')) {
        this.editor.commands.toggleSuperscript();
      }
    },
    openLink() {
      this.tempLink = this.$refs.linkInput.innerText;
      window.open(this.tempLink, '_blank');
    },
    onLinkInput() {
      this.tempLink = this.$refs.linkInput.innerText;
    },
    focusInput() {
      if (this.linkMenuIsActive) {
        nextTick(() => {
          this.$refs.linkInput?.focus();
          this.tempLink = this.linkUrlValue;
        });
      }
    },
    saveLink(saveInput, isEscapePressed) {
      nextTick(() => {
        if (saveInput) {
          this.linkUrlValue = this.$refs.linkInput.innerText;
        } else {
          this.tempLink = '';
          if (this.isOverLinkInput && !isEscapePressed) return;
        }
        this.linkMenuIsActive = false;
        this.editor.commands.focus('end');
      });
    }
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) {
        this.editor.destroy();
      }
    } catch (err) {
      console.error('Error in beforeUnmount lifecycle hook:', err);
    }
    clearTimeout(this.loadHTMLTimeout);
  },
  watch: {
    value(newValue) {
      try {
        if (this.editor && !this.editorChange) {
          this.editor.commands.setContent(newValue ?? '', true);
        }
        this.editorChange = false;
      } catch (err) {
        console.error('Error in value watcher:', err);
      }
    },
    changedFocus: {
      immediate: true,
      handler() {
        if (!this.isLinkActive) return;
        this.saveLink(false, false);
      }
    }
  }
};
</script>

<style lang="css">
@import './MessageEditorSidebar.css';
</style>

<style lang="scss" scoped>
@import './MessageEditorSidebar.scss';
</style>

<style>
.message__content__bubble__editor {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
  font-size: 14px;
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 3px;
  -webkit-transition: none;
  transition: none;
  cursor: text;
  font-family: 'Roboto', sans-serif !important;
  text-align: left;
}

.row.decision {
  padding: 8px 0px 8px 0px;
}

.row.message {
  padding: 8px 0px 8px 0px;
}

.bi.bi-hdd-stack {
  color: #92b8d9;
}

.message_content__bubble.true:before {
  /* background-image: attr(clientLogo); */
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: -27px;
  content: '';
  display: inline-block;
  height: 20px;
  left: -33px;
  position: absolute;
  width: 20px;
  background-size: cover;
}

sup::before,
sub::before {
  background-image: none !important;
}

.message_content__bubble.true:after {
  content: '';
  position: absolute;
  bottom: 3px;
  left: 23px;
  width: 20px;
  height: 0;
  border: 20px solid transparent;
  border-top-color: white;
  border-bottom: 0;
  border-left: 0;
  margin-left: 3px;
  margin-bottom: -5px;
  transform: rotate(16deg);
  z-index: -1;
}

.message__content__bubble p {
  text-align: left;
  margin: 0px;
  font-family: 'Roboto', sans-serif !important;
  margin-left: -2px;
}

.ProseMirror,
.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror {
  position: relative;
  word-wrap: break-word;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
}
</style>
