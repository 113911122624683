<template>
  <div class="row">
    <div class="col-12">
      <div>
        <MessageEditor placeHolder="Message" :readOnly="true" :id="id" :type="`message`" :modelValue="getText" @update:modelValue="(val) => (getText = val.trim())" />
      </div>
      <hr v-if="messageExternalLinksArray && messageExternalLinksArray.length > 0" />
      <div v-for="(externalLink, index) in messageExternalLinksArray" :key="index" class="external-links-container">
        <div class="external-link-wrapper">
          <ConversationBuilderIcon name="external-link" :width="11" />
          <p class="external-link">{{ externalLink }}</p>
        </div>
        <hr v-if="index < messageExternalLinksArray.length - 1" />
      </div>
      <hr v-if="referenceExternalLinksArray && referenceExternalLinksArray.length > 0" />
      <div v-for="(externalLink, index) in referenceExternalLinksArray" :key="index" class="external-links-container">
        <div class="external-link-wrapper">
          <ConversationBuilderIcon name="external-link" :width="11" />
          <p class="external-link">{{ externalLink }}</p>
        </div>
        <hr v-if="index < referenceExternalLinksArray.length - 1" />
      </div>
      <hr v-if="references && references.length > 0" />
      <div v-if="references && references.length > 0" class="references-container">
        <div v-for="(ref, index) in references" :key="index" class="references-wrapper">
          <div v-if="index === 0" class="first-reference-container">
            <ConversationBuilderIcon name="reference" :width="11" />
            <div class="ref-title-container">
              {{ ref }}
            </div>
          </div>
          <div v-else class="reference-container">
            <div class="reference-wrapper">
              <hr />
              <div class="ref-title-container">
                {{ ref }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
import ConversationBuilderIcon from '../Icons/ConversationBuilderIcon.vue';

export default {
  name: 'MessageComponent',
  mixins: [essentials],
  props: {},
  data() {
    return {
      appStore: useAppStore()
    };
  },
  components: {
    ConversationBuilderIcon
  },
  computed: {
    references: {
      get() {
        return this.response.reference.slice().reverse();
      }
    },
    responseArraySplitByMessageAndReferences() {
      if (this.response?.text && typeof this.response?.text === 'string') {
        return this.response.text.split('<hr>');
      } else {
        return [];
      }
    },
    messageExternalLinksArray() {
      return this.getReferences(this.responseArraySplitByMessageAndReferences?.[0]);
    },
    referenceExternalLinksArray() {
      return this.getReferences(this.responseArraySplitByMessageAndReferences?.[1]);
    }
  },
  methods: {
    setComponentId() {
      this.appStore.setComponentId(this.id);
    },
    getReferences(str) {
      if (!str || typeof str !== 'string') return [];
      const regex = /href="(.*?)"/g;
      try {
        const matches = [...str.matchAll(regex)];
        return matches.map((x) => x[1]).filter((y) => this.isValidReference(y));
      } catch (err) {
        return [];
      }
    },
    isValidReference(str) {
      try {
        const url = new URL(str);
        return ['http:', 'https:', 'tel:', 'mailto:'].includes(url.protocol);
      } catch (err) {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Message.scss';
.references-container {
  margin-left: 27px;
}
.reference-container {
  width: 100%;
  hr {
    width: 280px;
  }
}
.reference-wrapper {
  width: 100%;
}
.references-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.first-reference-container {
  display: flex;
  width: 100%;
  align-items: center;
  .ref-title-container {
    color: #383f45;
    font-size: 14px;
    display: block;
    word-wrap: break-word;
    margin-left: 10px;
  }
}
.ref-title-container {
  text-align: left;
  color: #383f45;
  font-size: 14px;
  display: block;
  width: 260px;
  word-wrap: break-word;
  margin-left: 21px;
}
.message-container-title {
  text-align: left;
  margin-left: 5px;
  padding: 4px 0px 8px 0px;
  font-weight: 900;
  font-size: 14px;
}

textarea {
  width: 100%;
  font-size: 12px;
}

.container-overlay {
  background: white;
  padding: 5px 10px 10px 10px;
  border-radius: 4px;
}

.component-overlay {
  background: white;
  padding: 3px 3px 2px 10px;
  border: 1px solid lightblue;
  border-radius: 4px;
}

.external-links-container {
  font-family: Roboto;
  font-size: 14px;
  line-height: 1.36;
  font-style: italic;
  text-align: left;
  color: #383f45;
  margin-left: 27px;
}
.external-link-wrapper {
  display: flex;
  align-items: flex-start;
}
.external-link {
  word-break: break-word;
  margin-left: 10px;
}
</style>
