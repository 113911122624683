import axios from 'axios';
import { nextTick } from 'vue';
import firebase from 'firebase/compat/app';
import { ElLoading, ElMessage } from 'element-plus';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import upperFirst from 'lodash/upperFirst';
import { StarterConfig } from '../components/ConversationBuilder/Canvas/3.0/UtilityFunctions/StoreUtil';
import { decompress } from 'compress-json';
import { useCommentsStore, useVcmStore, useVersionsStore } from '@/store/index.js';
import { saveAs } from 'file-saver';
import { getMousePosition } from '@/components/Utilities/position';
import { getComponentObject, getTypeOptions, responseTypes, routingComponents } from '@/components/Utilities/constants/constants';
import { callBuilderGenerateJSON } from '@/helpers/callBuilderGenerateJSON';
import { getLanguageTitle } from '../components/Utilities/utils';
import setInterceptor from '../helpers/axiosInterceptor';
import moment from 'moment';
import { FILE_TIMESTAMP_FORMAT } from '../components/Utilities/constants/constants';

const { v4: uuidv4 } = require('uuid');

//Mutations are not used by Pinia, with Pinia you can modify state directly (unlike Vuex). Actions are still used, but should be reserved for async operations.This file contains alot of actions that were mutations previously, to avoid regressions after bet brach merges with dev branch so components can still call them. Alot of the actions (the ones that were formerlly mutations) will be deleted in the future and instead we will be modifying the state directly in the components, instead of calling an action to modify the state.

// TODO: Create multiple smaller stores from this file
const actions = {
  updateSnackbar({ snackbar, text }) {
    this.snackbar = snackbar;
    this.snackbarText = text;
  },
  updateFlowStatuses(data) {
    this.updateFilterStatuses(data);
  },
  async updateFlows(data) {
    try {
      const updatedFlows = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/updateFlows`, data);
      return updatedFlows.data;
    } catch (err) {
      console.error('Error updating flows: ', err.message, err);
    }
  },
  setConnectingNodes(nodes) {
    this.sourceNode = nodes?.source || '';
    this.targetNode = nodes?.target || '';
  },
  clearConnectingNodes() {
    this.sourceNode = '';
    this.targetNode = '';
  },
  removeContainerAction({ id }) {
    const commentsStore = useCommentsStore();
    if (this.components[this.componentId]?.parentId === id) {
      this.componentId = '';
    }

    const containerComponents = this.componentList
      .map((componentId) => {
        const component = this.components[componentId];

        if (component?.parentId === id) {
          return component;
        }
      })
      .filter((component) => component !== undefined);

    if (containerComponents) {
      containerComponents.forEach((component) => {
        this.removeComponentAction({ id: component.id });
      });
    }

    this.scene.links = this.scene.links.filter((link) => link.from !== id && link.to !== id);
    this.containers = { ...omit(this.containers, id) };
    this.containerList = this.containerList.filter((containerId) => containerId !== id);

    const index = this.scene.nodes.findIndex((x) => x.id === id);
    this.scene.nodes.splice(index, 1);
    // Delete commment attached to component
    let actualContainerCounter = 0;

    this.containerList.map((currContainerId) => {
      if (actualContainerCounter === 0 || this.containers[currContainerId].title.search(/option/i) !== -1 || this.containers[currContainerId].title.search(/Copy/i) !== -1) {
        actualContainerCounter += 1;
        return;
      }
      if (this.containers[currContainerId].title.search(/container\s\d+/i) !== -1) {
        this.containers[currContainerId].title = 'Container ' + actualContainerCounter;
      }
      actualContainerCounter += 1;
    });

    commentsStore.UNPIN_COMMENT({ id, type: 'container' });
    commentsStore.CLEAR_PENDING();
  },
  async duplicateContainerAction({ id }) {
    const { photoURL, email, displayName } = this.user;
    const offsetX = 0;
    let offsetY = 0;

    const containerComponents = sortBy(
      this.componentList
        .map((componentId) => {
          const component = this.components[componentId];
          if (component.parentId === id) {
            return component;
          }
        })
        .filter((component) => component !== undefined),
      ['index']
    );
    if (containerComponents.length === 0) {
      // We don't want empty containers with no links on the canvas
      return;
    }
    const containerToDuplicate = cloneDeep(this.containers[id]);
    const containerNode = this.scene.nodes.find((node) => node.id === id);
    if (this.duplicateCounter && this.duplicateCounter.id && this.duplicateCounter.id === containerToDuplicate.id) {
      this.duplicateCounter['counter'] = this.duplicateCounter['counter'] + 1;
      offsetY = 50 * (this.duplicateCounter.counter - 1);
    } else {
      this.duplicateCounter['id'] = containerToDuplicate.id;
      this.duplicateCounter['counter'] = 1;
    }
    const newContainerId = uuidv4();

    this.scene.nodes.push({
      author: { photoURL, email, displayName, date: Date.now() },
      id: newContainerId,
      x: containerNode.x + 450 + offsetX,
      y: containerNode.y + offsetY,
      type: 'container'
    });

    this.containerList.push(newContainerId);
    this.containers[newContainerId] = {
      ...containerToDuplicate,
      id: newContainerId,
      title: containerToDuplicate.title + ' Copy'
    };

    if (containerComponents && containerComponents.length > 0) {
      containerComponents.forEach((c) => {
        const newId = uuidv4();
        const clonedComponent = cloneDeep(c);
        if (clonedComponent.type === 'carousel') {
          // This is to update the card id's for carousel switching to sync.
          if (clonedComponent.carousels && clonedComponent.carousels.length > 0) {
            clonedComponent.carousels = clonedComponent.carousels.map((carousel) => {
              carousel.id = uuidv4();
              carousel.mainCarouselId = newId;
              return carousel;
            });
          }
        }

        const newComponent = { [newId]: { ...clonedComponent, parentId: newContainerId, id: newId, index: 1000 } };

        this.componentList.push(newId);

        Object.assign(this.components, newComponent);
      });
    }

    nextTick(() => {
      this.stateRecomputeCounter++;
    });
  },
  removeComponentAction({ id }) {
    const commentsStore = useCommentsStore();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: idToRemove, ...remainingComponents } = this.components;

    const componentLinks = this.scene.links.filter((link) => link.from === id);

    if (componentLinks) {
      componentLinks.forEach((link) => {
        const containerHasComponents = this.componentList.map((id) => this.components[id]).find((component) => component.parentId === link.to);

        if (!containerHasComponents) {
          this.removeContainer({ id: link.to });
        }

        //Need to filter based on the entire link object, as some old flows have all their link ids set to 0.
        this.scene.links = this.scene.links.filter((sceneLink) => sceneLink !== link);
      });
    }
    this.components = remainingComponents;
    this.componentList = this.componentList.filter((componentId) => componentId !== id);
    this.componentId = '';
    // Delete commment attached to component

    nextTick(() => {
      this.stateRecomputeCounter++;
      commentsStore.UNPIN_COMMENT({ id, type: 'component' });
      commentsStore.CLEAR_PENDING();
    });
  },
  removeOptionContainer({ index, decisionComponentId }) {
    const link = this.scene.links.find((link) => link.isFromOption && link.from === decisionComponentId && link.optionIndex === index);
    if (link) {
      const containerHasComponents = this.componentList.map((id) => this.components[id]).find((component) => component?.parentId === link?.to);
      if (!containerHasComponents) {
        this.removeContainer({ id: link.to });
      }
      this.updateNodesLinkedDecisionContainerInfo(index, decisionComponentId);
      this.updateLinksOptionIndex(link, index, decisionComponentId);
    }
  },

  updateNodesLinkedDecisionContainerInfo(indexToDelete, decisionComponentId) {
    const nodes = this.scene.nodes;
    const nodesAssociatedWithDecisionComponentId = nodes.filter((node) => node?.linkedDecisionContainerInfo?.decisionComponentId === decisionComponentId);

    nodesAssociatedWithDecisionComponentId.forEach((node) => {
      if (node?.linkedDecisionContainerInfo?.optionContainerIndex === indexToDelete) {
        //delete the linkedDecisionContainerInfo object for the deleted index
        delete node.linkedDecisionContainerInfo;
      } else if (node?.linkedDecisionContainerInfo?.optionContainerIndex > indexToDelete) {
        //update index of other nodes
        node.linkedDecisionContainerInfo.optionContainerIndex = node.linkedDecisionContainerInfo.optionContainerIndex - 1;
      }
    });
  },

  updateLinksOptionIndex(linkToDelete, optionIndexToDelete, decisionComponentId) {
    //Delete the link assocatied with the option that is removed
    //Need to filter based on the entire link object, as some old flows have all or some of their link ids set to 0.
    this.scene.links = this.scene.links.filter((link) => link !== linkToDelete);

    //Update the optionIndex of the remaining links for this decision component
    this.scene.links.forEach((link) => {
      if (link?.from === decisionComponentId && link.optionIndex > optionIndexToDelete) {
        link.optionIndex = link.optionIndex - 1;
      }
    });
  },

  // AUTO SAVE ACTION
  createOptionContainer({ decisionComponentId, componentType }) {
    const { photoURL, email, displayName } = this.user;

    const decisionComponent = this.components[decisionComponentId];

    if (!decisionComponent) {
      return;
    }

    this.scene.links = this.scene.links.filter((link) => link.from !== decisionComponent.parentId);

    const newOptionIndex = decisionComponent.options.length - 1;

    let optionText = 'Button ' + decisionComponent.options.length;
    if (componentType && componentType === 'clarification') {
      optionText = 'None of these';
    }
    const decisionContainerNode = this.scene.nodes.find((node) => node.id === decisionComponent.parentId);

    if (decisionContainerNode) {
      const uid = uuidv4();

      const decisionContainerElement = document.getElementById(decisionComponent.parentId);

      const newNode = {
        author: { photoURL, email, displayName, date: Date.now() },
        id: uid,
        x: decisionContainerNode.x + Math.ceil(((newOptionIndex + 1) / 2) * 50),
        y: decisionContainerElement.offsetHeight + decisionContainerNode.y + (newOptionIndex + 1) * 150,
        type: 'container',
        linkedDecisionContainerInfo: {
          decisionComponentId: decisionComponent.id,
          decisionComponentParentId: decisionComponent.parentId,
          optionContainerIndex: decisionComponent.options.length - 1
        }
      };
      this.ADD_NODE({ node: newNode });

      this.addContainer({ type: 'container', id: uid, title: optionText });

      const newEdge = {
        id: uuidv4(),
        from: decisionComponentId,
        to: uid,
        color: '#8e9ba7',
        connectorPosition: newOptionIndex % 2 === 0 ? 'left' : 'right',
        start: false,
        status: 'connecting',
        isFromOption: true,
        optionIndex: newOptionIndex,
        decision: true
      };

      this.ADD_EDGE({ obj: newEdge });
    }
  },

  sortContainerNames() {
    const tempContainerList = this.containerList.map((id) => this.containers[id]);
    const titles = [];
    for (let i = 0; i < tempContainerList.length; i++) {
      if (tempContainerList[i].id !== 'start-id') {
        if (tempContainerList[i].title.search(/container\s*\d*/i) !== -1) {
          const titleNumber = parseInt(tempContainerList[i].title.substring(10));
          titles.push({ id: tempContainerList[i].id, number: titleNumber });
        }
      }
    }
    let sortedTitles = sortBy(titles, ['number']);
    for (let i = 0; i < sortedTitles.length; i++) {
      if (sortedTitles[i + 1]) {
        if (sortedTitles[i + 1].number - sortedTitles[i].number !== 1) {
          sortedTitles[i + 1].number = sortedTitles[i].number + 1;
          this.containers[sortedTitles[i + 1].id].title = 'Container ' + (sortedTitles[i].number + 1);
          sortedTitles = sortBy(sortedTitles, ['number']);
        }
      }
    }
  },
  removeEmptyUnconnectedContainers() {
    const containers = this.containerList.map((id) => this.containers[id]);
    for (let i = 0; i < containers.length; i++) {
      if (containers[i].id !== 'start-id') {
        const containerComponent = sortBy(
          this.componentList.map((id) => this.components[id]).filter((y) => y?.parentId === containers[i].id),
          ['index']
        );
        const hasLink = this.scene.links.find((x) => x?.from === containers[i].id || x?.to === containers[i].id) || false;
        if (!hasLink && containerComponent.length === 0) {
          this.removeContainer({ id: containers[i].id });
        }
      }
    }
  },
  async createStart() {
    ElLoading.service();
    this.CLEAR_ALL();
    const flow = await this.queryDatabaseForConversation({ getDrafts: true, limitOverride: 1 });
    if (flow) {
      this.RESTORE_FLOW({ ...flow });
    } else {
      StarterConfig(this); // Assuming this function mutates the state
    }
    ElLoading.service().close();
  },
  async getJobs({ taxonomyId }) {
    // TODO: Deprecate this function once we fully move to RASA training.
    try {
      const r = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs?taxonomyId=${taxonomyId}`, {});
      r.data = decompress(r.data);
      if (!r.data['nlu_engine_train']) {
        r.data['nlu_engine_train'] = [];
      }
      if (!this.jobs[taxonomyId]) this.jobs[taxonomyId] = [];
      if (['CLIENT', 'GUEST', 'CONTENT EDITOR'].includes(this.claims.role))
        Object.keys(r.data).map(function (key) {
          r.data[key] = r.data[key].filter((a) => a.payload?.uid === this.user.uid);
        });

      r.data = [...r.data['nlu_engine_train']].sort((a, b) => {
        return b?.job_creation_date?._seconds - a?.job_creation_date?._seconds;
      });
      this.jobs[taxonomyId] = r.data;
    } catch (err) {
      this.jobs[taxonomyId] = {
        xvalidation: [],
        pdfExport: [],
        clearHighlights: [],
        phonetic_pipeline: [],
        nlu_engine_train: []
      };
      console.error('Error: ', err.message, err);
    }
  },
  async getjobsV3({ taxonomyName, taxonomyId, types }) {
    try {
      const requests = types.map((type) => axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs/v3?taxonomy=${taxonomyName}&type=${type}`));
      const jobs = await Promise.all(requests);
      this.jobsV3[taxonomyId] = jobs.flatMap((job) => job.data);
    } catch (e) {
      console.error('Error: ', e.message, e);
    }
  },
  // TODO: This needs to be merged with JobsV3, since they share the same structure with one, once NLU train and pipebot deprecated
  async getRuns({ taxonomyName, taxonomyId, types = ['rasa_nlu_engine_train'] }) {
    try {
      const requests = types.map((type) => axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs/v3?taxonomy=${taxonomyName}&type=${type}`));
      const runs = await Promise.all(requests);
      this.runs[taxonomyId] = runs.flatMap((run) => run.data);
    } catch (e) {
      console.error('Error: ', e.message, e);
    }
  },
  async getAllSlots({ collection, dropdown }) {
    // Check for existing slots; and return existing slots if they are stored locally already - optimization
    if (collection in this.taxonomySlots && dropdown) return this.taxonomySlots[collection];
    else {
      if (dropdown) {
        ElLoading.service();
      }
      try {
        collection = collection ? collection : this.currentTaxonomy;

        const allSlots = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/getAllSlots`, { collection });

        if (allSlots) {
          this.current.slots = allSlots.data;
          this.taxonomySlots[collection] = allSlots.data.map((x) => pick(x, ['id', 'name']));
          if (dropdown) ElLoading.service().close();
        }
        return allSlots.data;
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }
  },
  async getAllFlows({ collection, dropdown, store, filter, versions } = {}) {
    if (dropdown) {
      ElLoading.service();
    }
    if (collection in this.taxonomyFlows && dropdown) {
      if (dropdown) {
        ElLoading.service().close();
      }
      return this.taxonomyFlows[collection];
    }
    try {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/getAllFlows`, {
        collection: collection ? `${collection}_flows` : `${this.currentTaxonomy}_flows`,
        filter,
        versions
      });
      let slots;
      if (response.status === 200) {
        slots = response.data;
      }
      slots = slots.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });
      if (store) {
        this.current.flows = slots;
        this.current.flows = this.current.flows.map((flow) => ({ ...flow, selected: false }));
      }
      this.taxonomyFlows[collection] = slots.map((x) => pick(x, ['id', 'name']));
      if (dropdown) {
        ElLoading.service().close();
      }
      return slots;
    } catch (err) {
      console.error('Error: ', err.message, err);
    }
  },

  async createFlowCollectionFromTaxonomy({ collection } = {}) {
    await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/createFlowCollectionFromTaxonomy`, {
      collection,
      serverEndpoint: process.env.VUE_APP_INTEGRATIONS_ENDPOINT
    });
  },

  async createDuplicateFlow({ agentName, name, flow } = {}) {
    const data = {
      flow,
      agentName,
      name,
      email: this.user.email
    };

    try {
      await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/createDuplicateFlow`, data);
    } catch (e) {
      alert(e);
    }
  },

  async createNewFlow({ agentName, name } = {}) {
    try {
      await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/createNewFlow`, {
        collection: `${agentName}_flows`,
        name: upperFirst(name).trim(),
        createdBy: this.user.email,
        serverEndpoint: process.env.VUE_APP_INTEGRATIONS_ENDPOINT
      });
    } catch (e) {
      console.log(e);
    }
  },

  async getTaxonomies() {
    try {
      const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/transfer/agent/get`, {
        idToken: this.claims.token
      });
      this.taxonomies = r.data;
    } catch (err) {
      console.error('Error: ', err.message, err);
      ElMessage({
        showClose: true,
        message: 'Error occurred. Please refresh your browser',
        duration: 1000
      });
      this.taxonomies = [];
    }
    return;
  },
  async TRAIN_TAXONOMY({ collection }) {
    try {
      ElLoading.service({ color: process.env.VUE_APP_PRIMARY_COLOR });
      const r = await axios.post('https://fallback.conversationhealth.com/train', {
        dataset: collection,
        auto_deploy: false,
        auto_upload: false,
        projectID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        version: 'CMP',
        done_url: `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/training/logging/${collection.replace(' ', '')}`,
        data_url: {
          url: `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/training/getAll`,
          body: {
            idToken: this.claims.token,
            collection: collection,
            status: ['APPROVED'],
            user: this.user.email
          }
        }
      });
      if (r.status === 400) {
        ElMessage({
          showClose: true,
          message: r.data['ERROR'].toString(),
          duration: 0
        });
      } else {
        ElMessage({
          showClose: true,
          message: r.data['SUCCESS'].toString(),
          duration: 0
        });
      }
      ElLoading.service({ color: process.env.VUE_APP_PRIMARY_COLOR }).close();
    } catch (err) {
      if ('ERROR' in err.response.data) {
        ElMessage({
          showClose: true,
          message: err.response.data['ERROR'].toString(),
          duration: 3000
        });
      } else {
        ElMessage({
          showClose: true,
          message: 'An error occurred. Please try again later',
          duration: 1000
        });
      }
      ElLoading.service({ color: process.env.VUE_APP_PRIMARY_COLOR }).close();
    }
  },

  async updateSlotStatus({ collection, id, username, slotName, status, color, record }) {
    await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/updateSlotStatus`, {
      record,
      collection,
      id,
      user: username,
      status,
      slotName,
      color
    });
  },
  async updateTags(payload) {
    try {
      await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/tags/save_tag`, payload);
    } catch (e) {
      console.log(e);
    }
  },
  async getFlowTags(payload) {
    try {
      const flowTags = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/tags/flow_tags`, payload);
      return flowTags.data;
    } catch (e) {
      console.log(e);
    }
  },
  async updateFlow(payload) {
    try {
      await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/updateFlow`, {
        payload
      });
    } catch (e) {
      console.log(e);
    }
  },

  async refreshToken() {
    return firebase.auth().currentUser?.getIdTokenResult();
  },
  async reCreateToken() {
    return await firebase.auth().currentUser.getIdTokenResult(true);
  },
  createUserList() {
    axios
      .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/list`, {
        claims: this.claims
      })
      .then((result) => {
        if (result.data.status === 'ineligible') {
          this.UPDATE_USER_LIST([]);
        } else {
          this.UPDATE_USER_LIST(result.data.users);
        }
      })
      .catch((error) => {
        console.error('[USERS] Error', error);
        this.UPDATE_USER_LIST([]);
      });
  },
  setResponse(obj) {
    this.SET_RESPONSE(obj);
  },
  setSlots(obj) {
    this.SET_SLOTS(obj);
  },
  setLanguage({ language }) {
    const vcmStore = useVcmStore();
    const { status } = vcmStore;
    if (status !== 'dirty') {
      this.SET_LANGUAGE(language);
      return Promise.resolve(true);
    }
    return new Promise((done) => {
      const callback = () => {
        this.SET_LANGUAGE(language);
        done();
      };
      vcmStore.forceSave({ uxStateOnly: false, callback });
    });
  },
  initializeLanguages(language) {
    this.INITIALIZE_LANGUAGES({ language });
  },
  setUser(user) {
    this.SET_USER({ user });
  },
  clearUser() {
    this.CLEAR_USER();
  },
  updateUser(obj) {
    this.UPDATE_USER(obj);
  },
  updateClaims(obj) {
    this.GET_CLAIMS(obj);
  },
  addNodeSync({ id, scene, type, position, start, firstComponentType, title, component }) {
    const { photoURL, email, displayName } = this.user;
    let uid;
    if (!scene) {
      scene = this.scene;
    }
    if (!id) {
      uid = type === 'start' ? 'start-id' : uuidv4();
      const nodeType = type === 'start' ? 'start' : 'container';
      scene.nodes.push({
        author: { photoURL, email, displayName, date: Date.now() },
        id: uid,
        x: position.x,
        y: position.y,
        type: nodeType.toLowerCase()
      });
      this.addContainer({ type: nodeType.toLowerCase(), id: uid, title: title || '' });
      if (!start && !component) {
        const componentType = firstComponentType?.toLowerCase() || type?.toLowerCase().trim();

        const isFirstDecisionOption = componentType === 'decision';

        this.addComponent({ type: componentType, id: uid, isFirstDecisionOption });
      }
      if (component) {
        this.moveComponent({ id: component.id, parentId: uid });
      }
      if (start && type === 'Container') {
        const newEdge = {
          id: uuidv4(),
          from: 'start-id',
          to: uid,
          color: '#8e9ba7',
          connectorPosition: 'bottom',
          start: true,
          status: 'connecting'
        };
        this.ADD_EDGE({ obj: newEdge });
      }
    }
    this.updateScene({ scene: scene });
    return uid;
  },
  recomputeNodePositions({ scene, nodes, exitPdf }) {
    if (!scene) {
      scene = this.scene;
    }

    if (nodes) {
      scene.nodes = nodes;
    }
    if (exitPdf) {
      this.originalNodePositions = [];
    }
    this.updateScene({ scene: scene });
  },
  updateScene(obj) {
    this.scene = obj.scene;
  },
  sendSceneToDB(obj) {
    this.SEND_TO_DB(obj);
  },
  updateFormContainer(obj) {
    this.UPDATE_FORM_CONTAINER(obj);
  },
  clearCurrentFlows(obj) {
    this.SET_CURRENT_FLOWS(obj);
  },
  setValidationErrors({ errors }) {
    this.SET_VALIDATION_ERRORS({ errors });
    this.SET_INVALID_ACTIONS_COUNT();
  },
  validateUxState(uxState) {
    if (!uxState.length) return false;
    if (uxState.length) {
      // If components or containers object are empty, do not accept uxState because the flow is corrupt
      if (!uxState[0]?.uxState?.components || !uxState[0]?.uxState?.containers) {
        return false;
      }
    }
    return true;
  },

  async createNewContainer({ event, type, component }) {
    try {
      const [x, y] = await getMousePosition(event);
      if (!type) type = event.dataTransfer.getData('typeFromLibrary') || this.currentComponent.type;
      return await this.addNodeSync({
        firstComponentType: getTypeOptions(type).firstComponentType,
        type: getTypeOptions(type).type,
        position: {
          x,
          y
        },
        component
      });
    } catch (err) {
      console.error('Error occurred creating a new container: ', err.message, err);
    }
  },

  async queryDb(body) {
    try {
      const taxonomies = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/taxonomies/flowDetails`, body);
      if (taxonomies) {
        const { data } = taxonomies;
        return data;
      }
    } catch (error) {
      console.error('[DB] Query error', error);
    }
  },
  async queryDatabaseForConversation({ getDrafts = false, limitOverride = null } = {}) {
    try {
      let limit, status;
      if (!getDrafts) {
        status = 'published';
        limit = 1;
      } else {
        status = 'draft';
        limit = limitOverride || 50;
      }

      const { slot } = this.current;
      let { taxonomy } = this.current;
      const { language } = this;
      taxonomy += '_flows';
      const body = {
        taxonomy,
        slotId: slot.id,
        status,
        language,
        collectionName: 'uxState',
        limit
      };
      const l = [body, { ...body, collectionName: 'comments' }];
      const [uxState, comments] = await Promise.all(
        l.map(async (x) => {
          return await this.queryDb(x);
        })
      );

      if (!this.validateUxState(uxState)) return false;

      return { uxState, comments };
    } catch (error) {
      console.log('Query database for conversation error: ', error);
      return false;
    }
  },
  async saveUxStateAndFlow({ data, draft, versionId = uuidv4(), languages = [] }) {
    console.log('[ACTION] Saving UxState and Flow', versionId);
    const versionsStore = useVersionsStore();
    const status = draft ? 'draft' : 'published';
    const versionName = versionsStore.currentVersion?.versionName ? versionsStore.currentVersion?.versionName : '';
    try {
      const { id, language } = data.flow.properties;
      let { taxonomy } = data.flow.properties;
      const availableLanguages = languages.length > 0 ? languages : ['en'];
      const additionalLanguagesToPublish = process.env.VUE_APP_GLOBAL_CMP ? [] : availableLanguages.filter((x) => x !== language);
      taxonomy += '_flows';
      const uxStateWithoutComments = (
        await axios.post(
          `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/sanitizeFlow`,
          {
            uxState: data.uxState,
            status
          },
          { timeout: 45000 }
        )
      ).data;
      const flowWithoutComments = omit(data.flow, ['comments']);
      const restoredFromDateTime = versionsStore.getRestoredFromDateTime();
      const uxRequest = axios.post(
        `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/taxonomies/saveUxState`,
        {
          taxonomy,
          id,
          status,
          language,
          uxState: uxStateWithoutComments,
          versionId,
          versionName,
          ...(restoredFromDateTime ? { createdBy: this.user, restoredFromDateTime } : {})
        },
        { timeout: 45000 }
      );
      if (restoredFromDateTime) {
        versionsStore.setRestoredFromDateTime(null);
      }

      const updateTaxRequest = axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/taxonomies/updateTaxonomy`, {
        taxonomy,
        id,
        hasFlow: true
      });
      const flowRequestObject = {
        taxonomy,
        id,
        status,
        language: language,
        flow: flowWithoutComments,
        versionId,
        source: this?.current?.hostname,
        additionalLanguagesToPublish,
        versionName
      };
      const flowRequest = axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/taxonomies/saveFlow`, flowRequestObject, { timeout: 45000 });
      // Make request concurrently;
      const promises = [uxRequest, flowRequest, updateTaxRequest];
      const [publishedId] = await Promise.all(
        promises.map(async (x) => {
          return (await x).data;
        })
      );
      const currentDraftVersionId = versionsStore.currentVersion?.id;
      if (!draft) {
        // update the draft version to reflect the new publishedId etc
        versionsStore.updateVersion({
          taxonomy,
          slotId: id,
          language,
          draft,
          versionId: currentDraftVersionId,
          publishedId,
          draftOrPublished: 'draft'
        });
      }
      console.log('[ACTION] Saved UxState Taxonomy and Flow', { draft, versionId, publishedId, currentDraftVersionId });
    } catch (err) {
      console.error('[ACTION] Error saving UxState Taxonomy and FlowError publishing', err);
      throw err;
    }
  },
  async disconnect() {
    console.log('[ACTION] Disconnecting');
    const idtoken = this.claims.token;
    const info = {
      slotId: this.current?.slot?.id,
      language: this.language,
      taxonomy: `${this.current?.taxonomy}_flows`,
      idtoken
    };
    const params = new URLSearchParams(info).toString();
    const url = `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/disconnect?${params}`;
    // const blob = new Blob([JSON.stringify({ idtoken })], { type: 'application/json' });
    navigator.sendBeacon(url);
  },
  async generateJSON({ exportJSON, draft, uxStateOnly } = {}) {
    try {
      console.log('Generating JSON');
      const data = await callBuilderGenerateJSON(this, draft, uxStateOnly);

      if (data === undefined || data === null) {
        console.warn('No data to generate JSON.');
        return;
      }

      // store last json for eventual export
      this.lastJson = data;

      if (exportJSON) {
        const jsonData = data;
        delete jsonData.flow.comments;
        delete jsonData.uxState.comments;
        const timeStamp = moment().format(FILE_TIMESTAMP_FORMAT);
        saveAs(
          new Blob([JSON.stringify(jsonData, null, 4)], {
            type: 'application/json'
          }),
          `${this.current.slot.name}-${timeStamp}.json`
        );
      }
      return data;
    } catch (err) {
      console.error('generateJSON::: Error: ', err.message, err);
    }
  },
  async validateFlow({ data } = {}) {
    try {
      const { data: validationResult } = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/validations/v1/flow/components`, data.flow);
      return validationResult;
    } catch (err) {
      console.error({
        errorStack: err.stack,
        type: err.name,
        errorMessage: `Error: ${err.message}`,
        message: 'Could not validate flow components',
        data
      });
      throw new Error(err);
    }
  },
  async validateAdditionalLanguageFlows(data) {
    try {
      const { data: validationResults } = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/validations/v1/flow/latestDrafts`, data);
      return validationResults;
    } catch (err) {
      console.error({
        errorStack: err.stack,
        type: err.name,
        errorMessage: `Error: ${err.message}`,
        message: `Could not validate latest drafts for following languages: ${data.languageCodes}`,
        data
      });
      throw new Error(err);
    }
  },
  async bulkValidateFlows({ agentName, flows }) {
    const availableLanguages = this.languageCodes && Object.keys(this.languageCodes).length > 0 ? Object.keys(this.languageCodes) : ['en'];
    const payload = { agentName, flows, languageCodes: availableLanguages };
    try {
      const { data: validationResults } = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/validations/v1/bulk/flow/versions`, payload);
      return validationResults.filter((r) => r.valid === false);
    } catch (err) {
      console.error({
        errorStack: err.stack,
        type: err.name,
        errorMessage: `Error: ${err.message}`,
        message: 'Could not bulk validate latest drafts',
        payload
      });
      throw new Error(err);
    }
  },
  hasEdge({ id } = {}) {
    return this.scene.links.find((x) => x?.from === id) || false;
  },
  async storeFlowInRedis({ id, data } = {}) {
    const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/helpers/redis/storeFlow`, {
      id,
      data
    });
    if (response.status === 200 && response?.data?.status === 'stored') return true;
    return false;
  },
  removeLinkedDecisionInfo({ containerId } = {}) {
    const updatedNodes = this.getScene?.nodes?.map((node) => {
      if (node.id === containerId) {
        const nodeCopy = { ...node };
        delete nodeCopy.linkedDecisionContainerInfo;
        return nodeCopy;
      }
      return node;
    });
    this.updateNodes({ nodes: updatedNodes });
  },
  initializeCanvasState(stateToWatch) {
    const watchingState = {};

    stateToWatch.forEach((stateItem) => {
      watchingState[stateItem] = this.stateItem;
    });

    this.initialCanvasState = watchingState;
  },
  emptyState() {
    this.replaceState({ ...this.initialCanvasState });
  },
  openSnackbar() {
    this.snackbar = true;
  },
  closeSnackbar() {
    this.snackbar = false;
  },
  setSnackbarText(text) {
    this.snackbarText = text;
  },
  updateFilterStatuses(data) {
    this.flowsFilterStatusesData = data;
  },
  SET_CURRENT_FLOWS(flows) {
    this.current.flows = flows;
  },
  SET_IS_EDITING_CONTAINER_ID(isEditingContainerId) {
    this.isEditingContainerId = isEditingContainerId;
  },
  SET_SELECTED_CONTAINERS(selectedContainers) {
    this.selectedContainers = selectedContainers;
  },
  ADD_SELECTED_CONTAINER(containerId) {
    this.selectedContainers.push(containerId);
  },
  REMOVE_SELECTED_CONTAINER(containerId) {
    const selectedContainers = cloneDeep(this.selectedContainers.filter((selectedContainerId) => selectedContainerId !== containerId));
    this.selectedContainers = selectedContainers;
  },
  FORCE_STATE_RECOMPUTE() {
    this.stateRecomputeCounter++;
    this.invalidActionsCount = Object.values(this.components).filter((c) => c.warning === true).length + Object.values(this.containers).filter((c) => c.warning === true).length;
  },
  CHANGE_DISPLAY_MODE({ displayMode }) {
    this.canvasDisabled = displayMode !== 'builder';
    this.displayMode = displayMode;
  },
  SET_ORIGINAL_NODE_POSITIONS({ nodes }) {
    this.originalNodePositions = nodes;
  },
  setEdgeType({ id, dotted, curved }) {
    const index = this.scene.links.findIndex((x) => x.id === id);
    if (curved != undefined) {
      this.scene.links[index] = {
        ...this.scene.links[index],
        curved
      };
    }
    if (dotted != undefined) {
      this.scene.links[index] = {
        ...this.scene.links[index],
        dotted
      };
    }
  },
  ADD_NODE({ node }) {
    this.scene.nodes = [...this.scene.nodes, node];
  },
  ADD_EDGE({ obj }) {
    this.scene.links = [...this.scene.links, obj];
  },
  RESTORE_PARTIAL_FLOW(memento) {
    const { scene, components, containers, taxonomies, containerList, componentList, comments, language, slot, taxonomy } = memento;
    this.scene = scene;
    this.components = components;
    this.containers = containers;
    this.taxonomies = taxonomies;
    this.containerList = containerList;
    this.componentList = componentList;
    this.comments = comments;
    this.language = language;
    this.slot = slot;
    this.taxonomy = taxonomy;
  },
  RESTORE_FLOW({ uxState, comments, type }) {
    const commentsStore = useCommentsStore();

    uxState = uxState[0].uxState ? uxState[0].uxState : uxState[0];
    const newComments = [];
    const reorganizeComments = () => {
      if (commentsStore?.comments) {
        commentsStore?.comments?.sort((a, b) => {
          return a.createdAt - b.createdAt;
        });
        commentsStore?.comments?.forEach((comment) => {
          if (comment.pinned) {
            if (comment.attachedTo === 'component' && !uxState.componentList.find((componentId) => componentId === comment.componentId)) {
              newComments.push(omit(comment, ['pinned']));
            } else if (comment.attachedTo === 'container' && !uxState.containerList.find((containerId) => containerId === comment.containerId)) {
              newComments.push(omit(comment, ['pinned']));
            } else {
              newComments.push(cloneDeep(comment));
            }
          } else {
            const newComment = cloneDeep(comment);

            if (comment.attachedTo === 'component' && uxState.componentList.find((componentId) => componentId === comment.componentId)) {
              newComment.pinned = true;
            }

            if (comment.attachedTo === 'container' && uxState.containerList.find((containerId) => containerId === comment.containerId)) {
              newComment.pinned = true;
            }

            newComments.push(newComment);
          }
        });
      }
    };
    if (type !== 'import') {
      if (comments) {
        comments = comments[0];
        commentsStore.comments = comments?.comments;
      }
      reorganizeComments();
    }
    if (type === 'import') {
      //If we decide to import/overwrite comments later this should be changed
      reorganizeComments();
    }
    commentsStore.comments = newComments;
    this.containers = uxState.containers;
    this.components = uxState.components;
    this.componentList = uxState.componentList;
    this.containerList = uxState.containerList;
    this.scene = uxState.scene;
    nextTick(() => {
      this.stateRecomputeCounter++;
    });
    ElLoading.service().close();
  },
  PRINT_FLOW({ uxState, comments }) {
    uxState = uxState[0];

    if (!this.comments) {
      this.comments = {};
    }
    if (comments) {
      comments = comments[0];
      this.comments.comments = comments?.comments;
    }

    const newComments = [];

    this.comments?.comments?.forEach((comment) => {
      if (comment.pinned) {
        if (comment.attachedTo === 'component' && !uxState.componentList.find((componentId) => componentId === comment.componentId)) {
          newComments.push(omit(comment, ['pinned']));
        } else if (comment.attachedTo === 'container' && !uxState.containerList.find((containerId) => containerId === comment.containerId)) {
          newComments.push(omit(comment, ['pinned']));
        } else {
          newComments.push(cloneDeep(comment));
        }
      } else {
        const newComment = cloneDeep(comment);

        if (comment.attachedTo === 'component' && uxState.componentList.find((componentId) => componentId === comment.componentId)) {
          newComment.pinned = true;
        }

        if (comment.attachedTo === 'container' && uxState.containerList.find((containerId) => containerId === comment.containerId)) {
          newComment.pinned = true;
        }

        newComments.push(newComment);
      }
    });

    this.comments.comments = newComments;
    this.containers = uxState.containers;
    this.components = uxState.components;
    this.componentList = uxState.componentList;
    this.containerList = uxState.containerList;
    this.scene = uxState.scene;
    nextTick(() => {
      this.stateRecomputeCounter++;
    });
    ElLoading.service().close();
  },
  FLOW_PRINT_PROPERTIES(printProperties) {
    this.current.flowPrintProperties = printProperties;
  },
  CLEAR_ALL() {
    this.componentList = [];
    this.containerList = [];
    this.components = {};
    this.containers = {};
    this.componentId = '';
    this.scene = {};
    if (!this.comments) {
      this.comments = {};
    }
    this.comments.comments = [];
  },
  editContainer({ field, id, value }) {
    const targetContainer = this.containers[id];
    /*
          resetting the whole containers value so that the state tree registers the change, updating specific key directly does not register as an update on the state tree
          */
    this.containers = { ...this.containers, [id]: { ...targetContainer, [field]: value } };
    this.stateRecomputeCounter++;
  },
  moveComponent({ id, parentId }) {
    this.components = {
      ...this.components,
      [id]: { ...this.components[id], parentId: parentId }
    };
  },
  removeComponent({ id }) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [id]: idToRemove, ...remainingComponents } = this.components;
    this.components = remainingComponents;
    this.componentList = this.componentList.filter((componentId) => componentId !== id);
    this.componentId = '';
    this.stateRecomputeCounter++;
  },
  removeContainer({ id }) {
    // remove selected component if the selected component is inside the deleted container
    if (this.components[this.componentId]?.parentId === id) {
      this.componentId = '';
    }
    this.containers = { ...omit(this.containers, id) };
    this.containerList = this.containerList.filter((containerId) => containerId !== id);
    this.scene.nodes = this.scene.nodes.filter((x) => x.id != id);
    // Remove all edges that connect to container and connect of out container
    this.scene.links = this.scene.links.filter((x) => x.to != id).filter((y) => y.from != id);
    this.stateRecomputeCounter++;
  },
  updateContainerResponses({ containerComponents, id }) {
    /// Set index and parent of each component in order of how they are in the container
    try {
      containerComponents.forEach((x, index) => {
        // Check for components that dont have id or missing from componentList because they were created outside store.js

        if (!(x.id in this.components)) {
          this.components[x.id] = x;
        }
        if (!this.componentList.includes(x.id)) {
          this.componentList.push(x.id);
        }
        // Force routing components to the bottom at all times
        if (routingComponents().includes(x.type.toUpperCase())) {
          index = index + 1000;
        }
        // Reset Components to trigger Pinia update
        const newComponent = { ...this.components[x.id], parentId: id, index: index };
        this.components = {
          ...this.components,
          [x.id]: { ...newComponent }
        };
        if (this.containers[id]?.warning === true) {
          delete this.containers[id].warning;
          delete this.containers[id].warningMessage;
          this.invalidActionsCount--;
        }
      });
    } catch (err) {
      console.error('Error: ', err.message, err);
    }
  },
  addContainer({ type, id, title }) {
    const obj = getComponentObject(type);
    const newId = id || uuidv4();
    const tempContainerList = cloneDeep(this.containerList);

    let min = Infinity;
    let max = 0;
    let totalForms = 0;

    tempContainerList.forEach((currentEle) => {
      if (this.containers[currentEle].title.search(/container\s*\d*/i) !== -1) {
        const titleNumber = parseInt(this.containers[currentEle].title.substring(10));
        if (titleNumber > max) {
          max = titleNumber;
        }
        if (titleNumber < min) {
          min = titleNumber;
        }
      }
      if (this.containers[currentEle].title.search(/form/i) !== -1) {
        totalForms += 1;
      }
    });

    this.containerList.push(newId);

    const defaultTitle = type === 'form' ? 'Form ' + (totalForms + 1) : 'Container ' + (max + 1);

    this.containers[newId] = {
      ...obj,
      id: newId,
      title: title || defaultTitle
    };

    if (type === 'form') {
      this.containers[newId].properties.formData = [];
    }
  },
  addComponent({ type, id, isFirstDecisionOption }) {
    const obj = getComponentObject(type);

    if (isFirstDecisionOption) {
      obj.options[0].text = 'Button 1';
    }

    const newId = uuidv4();
    this.components = {
      ...this.components,
      [newId]: { parentId: id, id: newId, ...obj, index: 1000 }
      //Always add component to bottom by setting very high index, this index will be changed by draggable library to its position in the array of components;
    };
    this.componentList.push(newId);
    this.stateRecomputeCounter++;
  },
  updateSelectedFlows(flows) {
    this.current.flows = flows;
  },
  removeLinkUrlAdvancedData({ linkUrlComponentId, subpath, queryParameters, mainCarouselId }) {
    let linkUrlComponent = this.components[linkUrlComponentId];
    if (mainCarouselId && mainCarouselId !== '') {
      linkUrlComponent = this.components[mainCarouselId];
      linkUrlComponent = linkUrlComponent.carousels.find((x) => x.id === linkUrlComponentId);
    }
    if (subpath) {
      linkUrlComponent.subpath = '';
      linkUrlComponent.queryParameters = [getComponentObject('QUERY_PARAMETER')];
    }

    if (queryParameters) {
      linkUrlComponent.queryParameters = [getComponentObject('QUERY_PARAMETER')];
    }

    if (subpath && queryParameters) {
      linkUrlComponent.advancedViewType = '';
    }
  },
  updateQueryParameter({ name, value, linkUrlComponentId, paramIndex, mainCarouselId }) {
    const tempStateComponents = cloneDeep(this.components);
    let linkUrlComponent = tempStateComponents[linkUrlComponentId];
    if (mainCarouselId && mainCarouselId !== '') {
      linkUrlComponent = tempStateComponents[mainCarouselId];
      linkUrlComponent = linkUrlComponent.carousels.find((x) => x.id === linkUrlComponentId);
    }
    if (name !== undefined) {
      linkUrlComponent.queryParameters[paramIndex].name = name;
    }

    if (value !== undefined) {
      linkUrlComponent.queryParameters[paramIndex].value = value;
    }

    this.components = tempStateComponents;
  },
  addItemToCollection({ componentId, collectionField, mainCarouselId, componentType }) {
    let component = this.components[componentId];
    let item;
    if (collectionField === 'queryParameters') {
      item = getComponentObject('QUERY_PARAMETER');
      if (mainCarouselId) {
        component = this.components[mainCarouselId];
        component = component.carousels.find((x) => x.id === componentId);
      }
    }
    if (collectionField === 'options') {
      item = getComponentObject('OPTION');
      item.id = uuidv4();
      const newOptionIndex = this.components[componentId].options.length;
      item.index = newOptionIndex;
      if (componentType && componentType === 'clarification') {
        item.text = 'None of these';
      } else {
        item.text = 'Button ' + (newOptionIndex + 1);
      }
    }
    if (collectionField === 'carousels') {
      item = getComponentObject('CAROUSEL');
      item.id = uuidv4();
      item.mainCarouselId = componentId;
      item.parentId = component.parentId;
      if (component[collectionField].length === 0) {
        // When it's the first card we add, lets create this field to keep track of each carousel components selected cards internally.
        component.selectedCarouselCard = componentId;
        component.index = 0;
        item.index = 1;
      } else {
        item.index = Math.max(...component[collectionField].map((c) => c.index)) + 1;
      }
    }
    if (item && component[collectionField]) {
      component[collectionField].push(item);
    }
  },
  removeItemFromCollection({ index, componentId, collectionField, mainCarouselId }) {
    let component = this.components[componentId];
    if (collectionField === 'carousels') {
      if (mainCarouselId !== undefined) {
        const mainCarousel = this.components[mainCarouselId];
        mainCarousel.carousels.splice(
          mainCarousel.carousels.findIndex((key) => key.id === componentId),
          1
        );
        this.selectedCarouselCard = mainCarousel;
      }
    } else if (collectionField === 'queryParameters') {
      if (mainCarouselId && mainCarouselId !== '') {
        component = this.components[mainCarouselId];
        component = component.carousels.find((x) => x.id === componentId);
        component[collectionField].splice(index, 1);
      } else {
        component[collectionField].splice(index, 1);
      }
    } else if (collectionField === 'options') {
      //Remove the option associated with the index
      component[collectionField].splice(index, 1);
      //Update the index of the other options
      component[collectionField].forEach((option) => {
        if (option.index > index) {
          option.index = option.index - 1;
        }
      });
    }
  },
  calculateOptionLinks({ decisionComponentId }) {
    const decisionComponent = this.components[decisionComponentId];

    const existingOptionLinks = this.scene.links.filter((link) => link.from === decisionComponentId);

    const newLinks = this.scene.links.filter((link) => link.from !== decisionComponentId);

    decisionComponent.options.forEach((option, index) => {
      const existingOptionLink = JSON.parse(JSON.stringify(existingOptionLinks[index]));

      existingOptionLink.connectorPosition = index % 2 === 0 ? 'left' : 'right';
      existingOptionLink.optionIndex = index;

      newLinks.push(existingOptionLink);
    });

    this.scene.links = newLinks;
  },
  removeOutgoingLinks({ componentId, containerId }) {
    // Finds the container that component is in and removes outgoing links
    // Or just pass containerId directly

    if (!componentId && !containerId) {
      return;
    }

    let fromId = '';

    if (componentId) {
      const component = this.components[componentId];
      if (component && component.parentId) {
        fromId = component.parentId;
      }
    }
    if (containerId) {
      fromId = containerId;
    }

    const hasLink = this.scene.links.find((x) => x?.from === fromId) || false;

    if (hasLink) {
      // Let's remove the link only if it exists, also protection if fromId is empty somehow
      this.scene.links = this.scene.links.filter((link) => link.from !== fromId);
    }
  },
  updateOption({ text, intent, decisionComponentId, optionIndex }) {
    const decisionComponent = this.components[decisionComponentId];

    if (typeof text === 'string') {
      decisionComponent.options[optionIndex].text = text;
      decisionComponent.options[optionIndex].label = text;

      const optionContainerId = this.scene.links.find((link) => link.isFromOption && link.optionIndex === optionIndex && link.from === decisionComponentId).to;

      const optionContainer = this.containers[optionContainerId];

      this.containers = { ...this.containers, [optionContainerId]: { ...optionContainer, title: text } };
    }

    if (intent) {
      decisionComponent.options[optionIndex].intent = intent;
      decisionComponent.options[optionIndex].name = intent.name;
    }
  },
  convertToOptionContainer({ decisionComponentId, decisionContainerId, optionIndex }) {
    if (decisionContainerId in this.containers && decisionComponentId in this.components) {
      const compObj = cloneDeep(this.components[decisionComponentId]);
      const nodeList = this.scene.nodes;
      let decisionContainerNode = nodeList.find((currNode) => {
        if (currNode.id === decisionContainerId) {
          decisionContainerNode = currNode;
          return true;
        }
        return false;
      });
      decisionContainerNode['linkedDecisionContainerInfo'] = {
        decisionComponentId: compObj.id,
        decisionComponentParentId: compObj.parentId,
        optionContainerIndex: optionIndex
      };
      const optionContainer = this.containers[decisionContainerId];
      this.containers = {
        ...this.containers,
        [decisionContainerId]: { ...optionContainer, title: compObj.options[optionIndex].text }
      };
      if (this.components[decisionComponentId]?.warning === true) {
        delete this.components[decisionComponentId].warning;
        delete this.components[decisionComponentId].warningMessage;
        this.invalidActionsCount--;
      }
    }
  },
  moveDecisionOption({ startIndex, endIndex, decisionComponentId }) {
    //Index needs to update in 3 places: options array in the decision component, optionIndex in links within scene, and optionContainerIndex in nodes within scene

    const decisionComponent = this.components[decisionComponentId];
    const options = JSON.parse(JSON.stringify(decisionComponent.options));
    const temp = options[startIndex];

    //mutate options array in component object
    options[startIndex] = options[endIndex];
    options[endIndex] = temp;
    options[startIndex].index = startIndex;
    options[endIndex].index = endIndex;
    decisionComponent.options = options;

    const scene = JSON.parse(JSON.stringify(this.scene));

    const startLink = scene.links.find((link) => link.isFromOption && link.optionIndex === startIndex && link.from === decisionComponentId);
    const endLink = scene.links.find((link) => link.isFromOption && link.optionIndex === endIndex && link.from === decisionComponentId);
    //mutate links in scene
    startLink.optionIndex = endIndex;
    endLink.optionIndex = startIndex;
    this.scene.links = scene.links;

    const originalStartNode = scene.nodes.find((node) => node?.linkedDecisionContainerInfo?.decisionComponentId === decisionComponentId && node?.linkedDecisionContainerInfo?.optionContainerIndex === startIndex);
    const originalEndNode = scene.nodes.find((node) => node?.linkedDecisionContainerInfo?.decisionComponentId === decisionComponentId && node?.linkedDecisionContainerInfo?.optionContainerIndex === endIndex);
    //mutate nodes in scene
    originalStartNode.linkedDecisionContainerInfo.optionContainerIndex = endIndex;
    originalEndNode.linkedDecisionContainerInfo.optionContainerIndex = startIndex;
    this.scene.nodes = scene.nodes;
  },
  updateComponent({ text, id, intent, taxonomy, flowType, label, url, imageUrl, imageSizeSelection, mainCarouselId, selectedCarouselCard, linkTo, imageWidth, imageHeight, imageAltText, cardCta, title, subTitle, viewType, advancedViewType, subpath, timeResponse, reference, value, verbatim, style, dropdownText, linkToContainer, carousels }) {
    if (text === '<p></p>') {
      text = '';
    }
    let newComp = this.components[id];
    if (mainCarouselId !== undefined) {
      // This is to update subcards in a carousel
      newComp = this.components[mainCarouselId];
      newComp = newComp.carousels.find((x) => x.id === id);
    }
    if (carousels && carousels.length > 0) {
      newComp['carousels'] = carousels;
    }
    if (text || text === '') newComp.text = text;
    if (linkToContainer) newComp.linkToContainer = linkToContainer;
    if (intent) {
      newComp.intent = newComp.intent ? newComp.intent : {};
      newComp.intent.name = intent.name;
      newComp.intent.id = intent.id;
      if (this.components[id].type === 'flow') {
        newComp.text = intent.name;
      }
    }
    if (taxonomy) {
      newComp.taxonomy = pick(taxonomy, ['name', 'id']);
      if (this.components[id].type === 'flow') {
        newComp.intent = { id: 0, name: 'None' };
        newComp.text = '';
      }
    }
    if (flowType) newComp['flowType'] = flowType;
    if (viewType !== undefined) newComp['viewType'] = viewType;
    if (advancedViewType !== undefined) newComp['advancedViewType'] = advancedViewType;
    if (subpath !== undefined) newComp['subpath'] = subpath;
    if (label !== undefined) newComp['label'] = label;
    if (url !== undefined) newComp['url'] = url;
    if (imageUrl !== undefined) newComp['imageUrl'] = imageUrl;
    if (imageSizeSelection !== undefined) newComp['imageSizeSelection'] = imageSizeSelection;
    if (imageWidth !== undefined) newComp['imageWidth'] = imageWidth;
    if (imageHeight !== undefined) newComp['imageHeight'] = imageHeight;
    if (imageAltText !== undefined) newComp['imageAltText'] = imageAltText;
    if (cardCta !== undefined) newComp['cardCta'] = cardCta;
    if (title !== undefined) newComp['title'] = title;
    if (subTitle !== undefined) newComp['subTitle'] = subTitle;
    if (selectedCarouselCard !== undefined) newComp['selectedCarouselCard'] = selectedCarouselCard;
    if (linkTo !== undefined) {
      if (linkTo === 'url') {
        if (newComp['linkTo'].type && newComp['linkTo'].type === linkTo) {
          newComp['linkTo'].type = '';
        } else {
          newComp['linkTo'].type = linkTo;
          const emptyLinkComponent = getComponentObject('LINKURL');
          delete emptyLinkComponent.type;
          for (const prop in emptyLinkComponent) {
            if (Object.prototype.hasOwnProperty.call(emptyLinkComponent, prop)) {
              if (!Object.prototype.hasOwnProperty.call(newComp, prop)) {
                newComp[prop] = emptyLinkComponent[prop];
              }
            }
          }
        }
      } else if (linkTo === 'flow') {
        if (newComp['linkTo'].type && newComp['linkTo'].type === linkTo) {
          newComp['linkTo'].type = '';
        } else {
          newComp['linkTo'].type = linkTo;
          newComp['linkTo'].data = getComponentObject('FLOW');
        }
      }
    }
    if (timeResponse) newComp['timeResponse'] = timeResponse;
    if (reference) newComp['reference'] = reference;
    if (verbatim) newComp['verbatim'][this.language] = verbatim;
    if (value) newComp['value'][this.language] = value;
    if (style) newComp['style'] = style;
    if (dropdownText !== undefined) newComp['dropdownText'] = dropdownText;
    if (newComp.warning === true && selectedCarouselCard !== '') {
      delete newComp.warning;
      delete newComp.warningMessage;
      this.invalidActionsCount--;
    }
    this.components = {
      ...this.components,
      [id]: { ...newComp }
    };
  },
  setZoomLevel({ zoomLevel }) {
    this.zoomLevel = zoomLevel * 100;
  },
  setActiveScene({ id }) {
    this.current.activeScene = id;
  },
  setReadyToImport({ status }) {
    this.readyToImport = status;
  },
  setSearchActive({ status }) {
    this.searchActive = status;
  },
  inputDisabled({ status }) {
    this.inputDisabled = status;
  },
  setLastFilter(filter) {
    this.lastFilter = filter;
  },
  setLastFilterClient(filter) {
    this.lastFilterClient = filter;
  },
  SET_DAYS_BACK(days) {
    this.daysBack = days;
  },
  SET_CURRENT_TAXONOMY({ taxonomy, slot }) {
    this.current.taxonomy = taxonomy;
    this.current.slot = slot;

    const taxonomyId = this.taxonomies.find((x) => x.name === taxonomy)?.id;
    this.current.taxonomyId = taxonomyId || '';
    const hostname = process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : 'http://localhost:8080';
    this.current.hostname = hostname;
    this.current.url = `${hostname}/conversationBuilder/${taxonomy}/${slot.id}/${slot.name}`;
    this.current.slot.flowProperties = {
      ...pick(
        this.current.flows.find((c) => c.id === slot.id),
        ['onLabel', 'batch']
      )
    };
  },
  setEdgeTargetSource({ edgeId, targetId, sourceId }) {
    this.selectedEdge = edgeId;
    this.targetNode = targetId;
    this.sourceNode = sourceId;
  },
  updateSelectedEdgeOptionData({ id, selectedOption }) {
    console.log(`updateSelectedEdgeOptionData`, { id: id, selectedOption: selectedOption });
    this.selectedEdgeOptionData = { id, selectedOption };
  },
  setNotesSideBar(type) {
    if (typeof type != 'string') {
      this.notesSideBar ? (this.notesSideBar = false) : (this.notesSideBar = true);
    } else {
      this.notesSideBar = type;
    }
  },
  openDrawer({ drawer, taxonomy, taxonomyId, metadata }) {
    this.drawerMetadata = metadata;
    this.drawer = drawer;
    if (taxonomy) {
      this.current.taxonomy = taxonomy;
    }
    if (taxonomyId) {
      this.current.taxonomyId = taxonomyId;
    }
  },
  updateCurrentFlow({ flow }) {
    const flowIndex = this.current.flows.findIndex((x) => x.id === flow.id);
    this.current.flows[flowIndex] = flow;
  },
  closeDrawer() {
    this.drawer = false;
    this.current.taxonomy = false;
    this.current.taxonomyId = false;
    this.drawerMetadata = {};
  },
  SET_CURRENT_INDEX(index) {
    this.currentIndex = index;
  },
  reloadPage() {
    window.location.reload();
  },
  SOCKET_UPDATE_USERS(users) {
    this.usersConnected = users;
  },
  UPDATE_USERS(users) {
    this.usersConnected = users;
  },
  SOCKET_CONNECT() {
    this.connect = true;
  },
  ADD_USER(user) {
    this.users.push(user);
  },
  SAVE_TO_DB_CONFIG(saveObj) {
    this.saveConfig = saveObj;
  },
  ADD_UPLOAD_OPERATION({ promise }) {
    this.uploadOperations.push(promise);
  },
  SET_COLOR(hex) {
    this.currentColor = hex;
  },
  SET_TOKEN({ token }) {
    this.claims.token = token;
    setInterceptor(token);
  },
  INITIALIZE_LANGUAGES({ language }) {
    const languages = language?.split(',');
    if (!languages || !languages.length > 0) return;
    languages.forEach((lang) => {
      const langLabel = lang.split(':')?.[0];
      const langCode = lang.split(':')?.[1]?.toLowerCase();
      const formattedLabel = getLanguageTitle(langLabel);
      if (langCode && formattedLabel && !this.alternativeLanguage.some((lang) => lang[formattedLabel] === langCode)) {
        this.alternativeLanguage.push({ [formattedLabel]: langCode });
        this.languageCodes[langCode] = formattedLabel;
      }
    });
  },
  SET_LANGUAGE(language) {
    const langCode = language.toLowerCase();
    if (Object.keys(this.languageCodes).includes(langCode)) {
      this.languageTitle = this.languageCodes[langCode];
      this.language = langCode;
    }
  },
  LOGOUT() {
    this.claims = {};
  },
  UPDATE_USER_LIST(list) {
    this.allUsers = list;
  },
  setActiveContainer(id) {
    this.activeContainer = id;
    if (this.containers[id]?.type === responseTypes.START.type) {
      this.componentId = '';
    }
  },
  setComponentId(id) {
    this.componentId = id;
    this.stateRecomputeCounter += 1;
  },
  GET_CLAIMS(claims) {
    this.claims = claims;
    setInterceptor(claims?.token);
  },
  updateNotePreview(obj) {
    this.tippyObj = obj;
  },
  addNotifications({ number }) {
    this.notificationsCount = number;
  },
  updateLinks({ links }) {
    this.scene.links = links;
  },
  updateNodes({ nodes }) {
    this.scene.nodes = nodes;
  },
  setAnnotations({ annotations }) {
    this.annotations = annotations;
  },
  SET_USER(user) {
    this.user = user;
  },
  CLEAR_USER() {
    this.user = null;
  },
  UPDATE_USER({ user }) {
    this.user = user;
  },
  SET_SCENE({ scene }) {
    this.scene = scene;
    ElLoading.service().close();
  },
  SET_BOTTOM_NAV_HOVER({ bool }) {
    this.bottomNavHovering = bool;
  },
  SET_CLIPBOARD({ text }) {
    this.clipboard = text;
  },
  SET_SIDEBAR_ACTIVE({ bool }) {
    this.editSidebarActive = bool;
  },
  async SET_SLOTS({ collection }) {
    this.slots = [];

    const allSlots = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/slots/setSlots`, { collection });

    if (allSlots) this.slots = allSlots.data;
  },
  UPDATE_FORM_CONTAINER({ id, currentFormData }) {
    this.containers[id].properties.formData = currentFormData;
    this.stateRecomputeCounter += 1;
  },
  SET_VALIDATION_ERRORS({ errors }) {
    console.group('Validation Errors Processing');
    for (const err of errors) {
      const { type, componentId } = err._original;
      const message = err.details?.[0]?.message;
      const errorPath = err.details?.[0]?.path.join('.');
      const errorType = err.details?.[0]?.type;
      const errorContext = JSON.stringify(err.details?.[0]?.context);

      // Enhanced error logging
      console.error(`Error in ${type} (ID: ${componentId}): ${message}`);
      console.warn(`Error Path: ${errorPath}`);
      console.info(`Error Type: ${errorType}`);
      console.log(`Error Context: ${errorContext}`);

      if (type === 'container' && Object.keys(this.containers).includes(componentId) && message) {
        this.containers[componentId].warning = true;
        this.containers[componentId].warningMessage = message;
      } else if (Object.keys(this.components).includes(componentId) && message) {
        this.components[componentId].warning = true;
        this.components[componentId].warningMessage = message;
      }
    }
    console.groupEnd();
  },
  SET_INVALID_ACTIONS_COUNT() {
    this.invalidActionsCount = Object.values(this.components).filter((c) => c.warning === true).length + Object.values(this.containers).filter((c) => c.warning === true).length;
  }
};

export default actions;
