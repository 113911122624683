import { Mark, mergeAttributes } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const Reference = Mark.create({
  name: 'reference',

  addOptions: {
    openOnClick: true
  },

  attributes: {
    reference: {
      default: null
    }
  },

  parseHTML() {
    return [
      {
        style: 'float',
        getAttrs: (value) => value === 'right' && {}
      },
      {
        tag: 'ref[reference]',
        getAttrs: (dom) => ({
          reference: dom.getAttribute('reference')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'ref',
      mergeAttributes({
        ...HTMLAttributes,
        type: 'reference'
      }),
      0
    ];
  },

  addCommands() {
    return {
      setReference:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark('reference', attributes);
        },
      toggleReference:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark('reference', attributes);
        },
      unsetReference:
        () =>
        ({ commands }) => {
          return commands.unsetMark('reference');
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-o': ({ state, dispatch, view }) => {
        console.log('testing mod *', state, dispatch, view);
        return true;
      }
    };
  },

  addProseMirrorPlugins() {
    const plugins = [];

    if (this.options.openOnClick) {
      const onClickPlugin = new Plugin({
        props: {
          handleClick: () => {
            const attrs = this.editor.getAttributes('reference');
            console.log(`Reference attrs: `, attrs);
          }
        }
      });

      plugins.push(onClickPlugin);
    }

    return plugins;
  }

  // Uncomment to add paste rules
  /*
  addPasteRules() {
    return [
      this.editor.schema.marks.reference
        .pasteRule(ReferencePasteRuleRegex, (url) => ({ reference: url }))
    ];
  }
  */
});
