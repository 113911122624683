<template>
  <div ref="drawer" class="notification-drawer" :class="getPositionClass">
    <div class="notification-scrolled-header" v-if="isScrolledDown">
      <p>
        {{ getNotificationsByType.length }}
        {{ notificationType === 'direct' ? 'Direct' : 'Watching' }}
        Notifications
      </p>
      <span @click="markAllAsRead">Mark all as read</span>
    </div>
    <div class="notification-header">
      <p class="notification-number">{{ getNotificationsByType.length }}</p>
      <p>Notifications</p>
      <span class="notification-toggle-text" v-if="type !== 'full-view'">Show unread</span>
      <label class="switch" v-if="type !== 'full-view'">
        <input type="checkbox" v-model="showUnread" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="notification-tabs">
      <div :class="`notification-tab ${notificationType === 'direct' ? 'notification-tab_is-active' : ''}`" @click="notificationType = 'direct'">Direct</div>
      <div class="notification-tab-divider"></div>
      <div :class="`notification-tab ${notificationType === 'watching' ? 'notification-tab_is-active' : ''}`" @click="notificationType = 'watching'">Watching</div>
      <div class="notification-view-all" v-if="type !== 'full-view'">
        <span @click="redirectToFullView">View all notifications</span>
      </div>
      <span class="notification-toggle-text" v-if="type === 'full-view'">Show unread</span>
      <div class="switch-container">
        <label class="switch" v-if="type === 'full-view'">
          <input type="checkbox" v-model="showUnread" />
          <span class="slider round"></span>
        </label>
      </div>
    </div>
    <div class="notification-subheader">
      <p>Latest</p>
      <div class="notification-read-container">
        <span @click="markAllAsRead">Mark all as read</span>
      </div>
    </div>
    <div class="notifications-content">
      <NotificationDrawerList v-for="notification in getNotificationsByType" :key="notification.id" :notification="notification" :notificationType="notificationType" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { ref } from 'vue';
import { mapActions, mapState } from 'pinia';
import NotificationDrawerList from './NotificationDrawerList.vue';

export default {
  name: 'NotificationDrawer',
  components: {
    NotificationDrawerList
  },
  props: {
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      isScrolledDown: ref(false),
      showUnread: ref(false),
      notificationType: ref('direct')
    };
  },
  mounted() {
    const drawer = this.$refs.drawer;
    drawer.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    const drawer = this.$refs.drawer;
    drawer?.removeEventListener('scroll', () => {
      return 'Scroll event removed.';
    });
  },
  computed: {
    ...mapState(useNotificationsStore, ['notifications', 'unreadNotifications']),
    getNotificationsByType() {
      const notifications = this.notifications
        .filter((notification) => notification.notificationType === this.notificationType)
        .sort((a, b) => {
          let aDate;
          let bDate;
          if (this.notificationType === 'direct') {
            aDate = new Date(a.createdAt);
            bDate = new Date(b.createdAt);
          } else {
            aDate = new Date(a.publishedAt);
            bDate = new Date(b.publishedAt);
          }

          return aDate - bDate;
        });
      if (!this.showUnread) {
        return notifications;
      }
      return notifications?.filter((notification) => !notification.isRead);
    },
    getPositionClass() {
      switch (this.type) {
        case 'app-navbar':
          return 'app-navbar-position';
        case 'builder-navbar':
          return 'builder-navbar-position';
        case 'full-view':
          return 'full-view';
        default:
          return '';
      }
    }
  },
  methods: {
    ...mapActions(useNotificationsStore, ['setAllNotificationsReadAsync']),
    markAllAsRead() {
      this.setAllNotificationsReadAsync();
    },
    redirectToFullView() {
      this.$router.push('/notifications');
      this.$emit('close-drawer');
    },
    handleScroll() {
      const drawer = this.$refs.drawer;
      if (drawer.scrollTop > 180) {
        this.isScrolledDown = true;
      } else {
        this.isScrolledDown = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.full-view {
  width: 100% !important;
  height: 100% !important;
  z-index: 0 !important;
  position: static !important;
  box-shadow: none !important;
  padding-right: 20px !important;

  .notification-toggle-text {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: #edf0f4 2px solid;
    padding-right: 10px;
    margin-right: 0 !important;
  }

  .switch-container {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: #edf0f4 2px solid;
  }

  .notification-scrolled-header {
    width: calc(100% - 270px) !important;
    top: 60px !important;
  }
}

.app-navbar-position {
  right: 0;
  height: calc(100vh - 100px);
  top: 4.3rem;
}

.builder-navbar-position {
  position: fixed !important;
  right: 880px;
  top: 60px;
}

@media screen and (max-width: 1650px) {
  .builder-navbar-position {
    right: 727px;
  }
}

@media screen and (max-width: 1370px) {
  .builder-navbar-position {
    right: 530px;
  }
}

@media screen and (max-width: 1000px) {
  .builder-navbar-position {
    right: 177px;
  }
}

@media screen and (max-width: 960px) {
  .builder-navbar-position {
    right: 334px;
  }
}

@media screen and (max-width: 780px) {
  .builder-navbar-position {
    right: 10px;
  }
}

.notification-drawer {
  width: 440px;
  height: calc(100vh - 100px);
  position: absolute;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
  background-color: #fff;
  padding-top: 30px;
  z-index: 1;
  cursor: default;
  overflow-y: auto;
  z-index: 99;

  .notification-toggle-text {
    margin-left: auto;
    margin-right: 10px;
    font-size: 14px;
    line-height: 1.36;
    text-align: left;
    color: #383f45;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #e3e8ee;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 0;
    bottom: 0;
    background-color: #8e9ba8;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #e3e8ee;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #1996f0;
  }

  .slider.round {
    border-radius: 18px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .notification-scrolled-header {
    margin-left: 17px;
    width: 400px;
    height: 63px;
    top: 65px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: #edf0f4 2px solid;
    position: fixed;
    background-color: white;
    z-index: 10;

    p {
      font-size: 17px;
      font-weight: 500;
      line-height: 2.35;
      text-align: left;
      color: #383f45;
    }

    span {
      font-size: 14px;
      line-height: 2.36;
      text-align: right;
      color: #8e9ba8;
      cursor: pointer;
    }
  }

  .notification-header {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;

    p {
      font-size: 21px;
      font-weight: 500;
      line-height: 1.33;
      text-align: left;
      color: #383f45;
    }

    .notification-number {
      color: #fd5422;
      margin-right: 8px;
    }
  }

  .notification-tabs {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding-left: 20px;
    padding-right: 20px;

    .notification-tab {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.36;
      text-align: left;
      color: #383f45;
      border-bottom: #edf0f4 2px solid;
      cursor: pointer;
    }

    .notification-tab_is-active {
      border-bottom: #fd5422 2px solid;
    }

    .notification-tab-divider {
      width: 20px;
      height: 100%;
      border-bottom: #edf0f4 2px solid;
    }

    .notification-view-all {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.36;
      text-align: left;
      text-decoration: underline;
      color: #1996f0;
      border-bottom: #edf0f4 2px solid;

      span {
        cursor: pointer;
      }
    }
  }

  .notification-subheader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    p {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 17px;
      font-weight: 500;
      line-height: 1.35;
      text-align: left;
      color: #383f45;
      border-bottom: #edf0f4 2px solid;
      margin-left: 20px;
    }

    .notification-read-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: #edf0f4 2px solid;
      margin-right: 20px;

      span {
        font-size: 14px;
        line-height: 1.36;
        text-align: right;
        color: #8e9ba8;
        cursor: pointer;
      }
    }
  }

  .notifications-content {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
}
</style>
