class APPError extends Error {
  constructor(m) {
    super(m);
    this.name = 'APPError';
  }
}

export function throwError(scope, message) {
  throw new APPError(`[${scope}] ${message}`);
}

export function debugWarn(scope, message) {
  if (process.env.NODE_ENV !== 'production') {
    const error = typeof scope === 'string' ? new APPError(`[${scope}] ${message}`) : scope;
    // eslint-disable-next-line no-console
    console.warn(error);
  }
}
