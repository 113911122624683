<template>
  <div>
    <div class="row set">
      <div class="col-12">
        <MessageEditor
          placeHolder="Message"
          :readOnly="true"
          :id="id"
          :type="`set`"
          :modelValue="getTextSet"
          @update:modelValue="val => getTextSet = val.trim()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
export default {
  name: 'MessageComponentSet',
  mixins: [essentials],
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  computed: {
    getTextSet() {
      return this.response['label'][`${this.appStore.language}`];
    },
  },
};
</script>

<style lang="scss" scoped>
@import './MessageComponentSet.scss';
</style>
