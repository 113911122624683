export { isArray, isFunction, isObject, isString, isDate, isPromise, isSymbol } from '@vue/shared';

export { isVNode } from 'vue';

export const isNumber = (val) => typeof val === 'number';

export const isStringNumber = (val) => {
  if (typeof val !== 'string') {
    return false;
  }
  return !Number.isNaN(Number(val));
};
