import { Mark, textblockTypeInputRule } from '@tiptap/core';

const EXTENSION_NAME = 'alignment';
export const Alignment = Mark.create({
  name: EXTENSION_NAME,

  addOptions: {
    textAlign: ['left', 'center', 'right']
  },

  attributes: {
    textAlign: {
      default: 'left'
    }
  },

  parseHTML() {
    return this.options.textAlign.map((align) => ({
      tag: `span[style="text-align:${align}; display:block;"]`,
      getAttrs: () => {
        return {
          textAlign: align
        };
      }
    }));
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      {
        style: `text-align:${HTMLAttributes.textAlign}; display:block;`,
        ...HTMLAttributes
      },
      0
    ];
  },

  addCommands() {
    return {
      setAlignment:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark('alignment', attributes);
        }
    };
  },

  addInputRules() {
    return [
      textblockTypeInputRule({
        find: /(?:\*\*|__)([^*_]+)(?:\*\*|__)$/,
        type: EXTENSION_NAME
      })
    ];
  }
});
