<template>
  <div class="background release-wrapper">
    <PageHeader :showBreadcrumbs="false" title="Release" />
    <br />
    <br />
    <div class="release-page-wrapper">
      <div class="source-target-wrapper">
        <ConfirmationModal :show="showModal" @showHideModals="showHideModals" @release="release" :content="modalContent" :title="modalTitle" @reset-page="resetPage" :agentName="collection" :selectedFlows="selectedFlows" />
        <div class="source-container">
          <div class="source-target-header">Source</div>
          <el-form label-position="left" ref="form" class="source-form-container">
            <el-form-item>
              <span class="release-copy-token" @click="copy('sourceUrl')">Copy</span>
              <el-input class="release-input" disabled @input="resetVerified" size="default" placeholder="Enter URL of source environment" v-model="source.url">
                <template #prepend> {{ urlPrefix }} </template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <span class="release-copy-token" @click="copy('token')">Copy</span>
              <el-input class="release-input" ref="copyToken" disabled @input="resetVerified" size="default" placeholder="Enter secret API key" :model-value="idToken">
                <template #prepend> API key </template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-select filterable class="release-input" @change="resetVerified()" placeholder="Select agent" v-model="collection">
                <template #label> <i class="fas fa-key"></i> </template>

                <el-option :key="item.name" :value="item.name" :label="item.name" v-for="item in appStore.taxonomies"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="arrows-container">
          <span>
            <img class="menuIcon" width="19" src="../../../../assets/icons/arrow-release.svg" />
          </span>
          <span>
            <img class="menuIcon" width="19" src="../../../../assets/icons/arrow-release.svg" />
          </span>
          <span>
            <img class="menuIcon" width="19" src="../../../../assets/icons/arrow-release.svg" />
          </span>
        </div>
        <div class="target-container">
          <div class="source-target-header">Target</div>
          <el-form label-position="left" ref="form" class="target-form-container">
            <el-form-item>
              <el-input class="release-input" @input="resetVerified(true)" size="default" placeholder="Enter URL of target environment" v-model="target.url">
                <template #prepend> {{ urlPrefix }} </template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-input class="release-input" @input="resetVerified(true)" size="default" placeholder="Enter secret API key" v-model="target.token">
                <template #prepend> API key </template>
              </el-input>
            </el-form-item>

            <el-form-item>
              <el-select filterable v-loading="this.target.loading" :disabled="this.target.agents.length === 0" class="release-input" @change="resetVerified" placeholder="Enter or select name of new agent" v-model="target.agent">
                <el-option :key="item" :value="item" :label="item" v-for="item in target.agents"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item> </el-form-item>
          </el-form>
        </div>
      </div>
      <div :class="`${this.collection === '' ? 'container-disabled' : 'flows-container'}`">
        <ReleaseListComponent type="Flows" :collection="this.collection" @select-flows="selectFlows" />
      </div>
      <div :class="`${this.collection === '' ? 'container-disabled' : 'intents-container'}`">
        <ReleaseListComponent type="Intents" :collection="this.collection" @select-intents="selectIntents" />
      </div>
      <div class="release-footer">
        <div class="release-footer-inner-wrapper">
          <div class="cancel-release-button">
            <button type="button" class="btn btn-cancel" @click="showHideModals('reset')">Reset</button>
          </div>
          <div class="release-button">
            <button type="button" :class="`btn btn-primary ${!this.canRelease ? 'disabled-release' : ''}`" @click="showHideModals('release')">Release</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import _ from 'lodash';
import ConfirmationModal from '@/views/3.0/Release/ConfirmationModal.vue';
import ReleaseListComponent from '@/views/3.0/Release/ReleaseListComponent.vue';
import axios from 'axios';
export default {
  name: 'ReleaseMain',
  components: {
    ConfirmationModal,
    ReleaseListComponent
  },
  mounted() {
    this.source.token = this.appStore.getClaims.token;
    this.source.url = process.env.VUE_APP_INTEGRATIONS_ENDPOINT.replace(/https?:\/\//, '');
    this.timeoutFunction();
  },
  watch: {
    'target.url'() {
      this.target.agent = '';
      this.target.agents = [];
    },
    'target.token'() {
      this.target.agent = '';
      this.target.agents = [];
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      timeoutShow: false,
      timeoutVar: '',
      collection: '',
      existsInTarget: false,
      source: {
        url: '',
        projectId: '',
        token: '',
        verified: 'NONE',
        tokenVerified: 'NONE',
        agent: ''
      },
      target: {
        url: '',
        projectId: '',
        token: '',
        agent: '',
        agents: [],
        verified: 'NONE',
        tokenVerified: false,
        loading: false
      },
      showModal: false,
      modalContent: '',
      modalTitle: '',
      selectedIntents: [],
      selectedFlows: []
    };
  },
  computed: {
    taxonomies() {
      return this.appStore.taxonomies;
    },
    timeToExpiry() {
      return this.appStore.getExpirationTime.date - Date.now();
    },
    idToken() {
      return this.appStore.getClaims.token;
    },
    urlPrefix() {
      return process.env.NODE_ENV === 'production' ? 'https://' : 'http://';
    },
    canRelease() {
      return ((this.selectedIntents && this.selectedIntents.length > 0) || (this.selectedFlows && this.selectedFlows.length > 0)) && this.source.tokenVerified && this.target.url && this.target.token && this.target.agent;
    }
  },
  methods: {
    resetPage() {
      this.source.tokenVerified = 'NONE';
      this.source.verified = 'NONE';
      this.source.agent = '';
      this.target = {
        url: '',
        projectId: '',
        token: '',
        agent: '',
        agents: [],
        verified: 'NONE',
        tokenVerified: false
      };
      this.selectedIntents = [];
      this.selectedFlows = [];
      this.collection = '';
      this.showModal = false;
      this.modalContent = '';
      this.modalTitle = '';
    },
    showHideModals(modal) {
      switch (modal) {
        case 'release':
          if (this.target.agent === '') {
            this.$message({
              message: 'Please select a target agent to continue.',
              duration: 5000,
              showClose: true,
              type: 'info'
            });
            return;
          }
          if (this.selectedFlows.length === 0 && this.selectedIntents.length === 0) {
            this.$message({
              message: 'Please select at least 1 flow/intent.',
              duration: 5000,
              showClose: true,
              type: 'info'
            });
            return;
          }
          if (!this.canRelease) {
            return;
          }
          this.showModal = !this.showModal;
          this.modalContent = `You’re releasing ${this.selectedFlows.length > 0 ? `<b>${this.selectedFlows.length}</b> flow(s)` : ''} ${this.selectedFlows.length > 0 && this.selectedIntents.length ? 'and' : ''} ${this.selectedIntents.length > 0 ? `<b>${this.selectedIntents.length}</b> intent(s)` : ''} from <b>${this.collection !== '' ? this.collection : 'N/A'}</b> to <b>${this.target.agent !== '' ? this.target.agent : 'N/A'}</b>.
                   <br/><br/>\n
                  Are you sure you would like to continue?`;
          this.modalTitle = modal.charAt(0).toUpperCase() + modal.slice(1);
          return;
        case 'reset':
          this.showModal = !this.showModal;
          this.modalContent = 'Are you sure you want to continue? All selections and inputs will be reset.';
          this.modalTitle = modal.charAt(0).toUpperCase() + modal.slice(1);
          return;
        default:
          return;
      }
    },
    copy(type) {
      switch (type) {
        case 'token':
          navigator.clipboard.writeText(this.idToken);
          return;
        case 'sourceUrl':
          navigator.clipboard.writeText(this.source.url);
          return;
      }
    },
    async getTargetAgents(shouldLoad) {
      if (shouldLoad) {
        this.target.loading = true;
      }
      try {
        const r = await axios.post(`${this.convertUrl(this.target.url)}/release/agent/get`, {
          idToken: this.target.token
        });
        this.target.agents = r.data;
        if (shouldLoad) {
          this.target.loading = false;
        }
      } catch (err) {
        if (shouldLoad) {
          this.target.loading = false;
        }
        console.error('Error: ', err.message, err);
        this.$message({
          message: 'Error occurred. Please make sure target values are correct.  ',
          duration: 2000,
          showClose: true,
          type: 'error'
        });
        this.target.agents = [];
      }
    },
    timeoutFunction() {
      if (this.timeoutVar != null) {
        clearTimeout(this.timeoutVar);
      }
      this.timeoutVar = setTimeout(() => {
        this.timeoutShow = true;
      }, this.timeToExpiry - 300000);
    },
    resetVerified(shouldLoad) {
      this.source.verified = false;
      this.target.verified = false;
      this.resetVerifiedPost(shouldLoad);
    },
    resetVerifiedPost: _.debounce(async function (shouldLoad) {
      try {
        if (this.collection) await this.verifySource();
        if (this.target.url && this.target.url !== '' && this.target.token && this.target.token !== '') await this.getTargetAgents(shouldLoad);
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }, 500),
    async verifySource() {
      try {
        const sourceEndpoint = `${this.convertUrl(this.source.url)}/transfer/verify`;
        const r = await axios.post(sourceEndpoint, {
          collection: this.collection,
          idToken: this.idToken,
          exists: false
        });
        if (r.status === 200 && r.data.exists) {
          this.source.verified = true;
          this.source.tokenVerified = true;
        } else {
          this.source.verified = false;
          this.source.tokenVerified = false;
        }
      } catch (err) {
        this.source.verified = false;
        console.error('Error: ', err.message, err);
      }
    },
    convertUrl(url) {
      return process.env.NODE_ENV === 'production' ? 'https://' + url : 'http://' + url;
    },
    selectIntents(intents) {
      this.selectedIntents = intents;
    },
    selectFlows(flows) {
      this.selectedFlows = flows;
    },
    async release() {
      const intents = this.selectedIntents?.map((x) => {
        return {
          id: x.id,
          name: x.name,
          status: x.status,
          vault: x.vault
        };
      });
      const flows = this.selectedFlows?.map((x) => {
        return {
          id: x.id,
          name: x.name,
          published: typeof x.currentVersion === 'object' && Object.keys(x.currentVersion).includes('isPublished') ? x.currentVersion?.isPublished : x.published,
          versionId: x.currentVersion?.versionId || null,
          status: x.status,
          batch: x.batch
        };
      });
      const payload = {
        intents,
        flows,
        idToken: this.idToken,
        source: {
          agent: this.collection,
          url: this.convertUrl(this.source.url)
        },
        target: {
          idToken: this.target.token,
          agent: this.target.agent,
          url: this.convertUrl(this.target.url)
        },
        user: {
          email: this.appStore.getUser.email,
          displayName: this.appStore.getUser.displayName
        }
      };
      this.resetPage();
      this.$message({
        message: 'Release in progress.',
        duration: 5000,
        showClose: true,
        type: 'info'
      });
      try {
        await axios.post(`${this.convertUrl(this.source.url)}/release`, payload);
        this.$message({
          showClose: true,
          message: 'Release completed',
          type: 'success'
        });
      } catch (error) {
        console.error(`Error: Unable to perform release job: `, error);
        this.$message({
          showClose: true,
          message: 'Release failed.',
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.release-wrapper {
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
  height: calc(100vh - 160px);
}
.release-wrapper::-webkit-scrollbar {
  display: none;
}
.release-page-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 38px;
  width: 100%;
}
.release-footer {
  position: fixed;
  display: flex;
  bottom: 0;
  height: 88px;
  width: 100%;
  background-color: #383f45;
  color: white;
  z-index: 999;
}
.release-footer-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: calc(100% - 180px);
}
.release-button,
.cancel-release-button {
  button {
    width: 160px;
  }
}
.source-target-wrapper {
  background-color: #f4f6fa;
  display: flex;
  padding: 30px 40px 0 40px;
  justify-content: center;
  width: 100%;
}
.source-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.arrows-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
}
.target-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.source-target-header {
  font-size: 17px;
  font-weight: bold;
  text-align: left;
  color: #383f45;
}

.input-box {
  display: flex;
  align-items: center;
  width: 560px;
  border: solid 1px #8e9ba7;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
}

.input-box .prefix {
  font-size: 14px;
  color: #8e9ba8;
  background-color: #f4f6fa;
  min-width: 67px;
  padding: 10px;
}

.input-box input {
  padding: 10px;
  width: 100%;
}
.source-form-container {
  margin-top: 10px;
}
.release-input {
  width: 100%;
  height: 100%;
}
.el-form-item {
  margin-bottom: 10px;
}
.release-copy-token {
  position: absolute;
  cursor: pointer;
  top: -1px;
  right: 8px;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  width: 51px;
  height: 26px;
  vertical-align: middle;
  margin: 4px 0 4px 6px;
  padding: 5.5px 10px 5.5px 9px;
  background-color: #fff;
  font-size: 14px;
  text-align: left;
  color: #56616a;
  border-radius: 15px;
}
.release-copy-token:hover {
  border: 1px solid #8e9ba7;
}
.target-form-container {
  margin-top: 10px;
}
.container-disabled {
  pointer-events: none;
  opacity: 0.2;
}
.disabled-release {
  opacity: 0.2;
}
</style>
