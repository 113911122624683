<template>
  <div>
    <PageHeader :showBreadcrumbs="false" title="Users" />

    <br />
    <br />
    <vs-popup v-model:active="openNewUser" title="Create New User" class="adminEdit">
      <div class="accessEdit">
        <span class="claims">Name</span>
        <br />
        <h3>
          <input class="newField" placeholder="Enter Name" v-model="newUser.displayName" />
        </h3>
        <vs-divider />
        <span class="claims">Email</span>
        <br />
        <br />
        <input class="newField" placeholder="Enter Email" v-model="newUser.email" />
        <vs-divider />
        <span class="claims">Password</span>
        <br />
        <br />
        <input placeholder="Enter Password" class="newField" type="password" v-model="newUser.password" />
        <vs-divider />
      </div>
      <br />
      <div class="grid justify-items-center">
        <vs-button @click="submitNewUser" style="color: white; background-color: black; border-radius: 5px; font-size: 14px">CREATE</vs-button>
      </div>
    </vs-popup>

    <vs-popup :key="forceRecomputeCounter" v-model:active="openChangeClaims" title="Edit User Access">
      <div class="accessEdit">
        <h3>
          <div class="grid justify-items-center">
            <img class="userPhoto" @click="imageClick" :src="currentUser.photoURL" />
            <input type="file" :ref="`imageUpload-${this.currentUser.uid}`" style="display: none" @change="previewImage($event, r)" accept="image/*" />
          </div>
          <div class="grid justify-items-center">
            <input class="edit-input-field" v-model="currentUser.name" />
          </div>
        </h3>
        <div class="grid justify-items-center">
          <span style="font-size: 14px; color: red">{{ currentUser.email }}</span>
          <br />
          <span style="font-size: 12px; color: gray">{{ currentUser.role }}</span>
        </div>

        <vs-divider />
        <span class="claims">ROLE</span>
        <el-select v-model="newClaims" placeholder="Select" value-key="role" popper-class="el-select-dropdown">
          <el-option v-for="value in getRoles" :key="value.role" :label="value.role" :value="value" />
        </el-select>
      </div>
      <br />
      <div class="grid justify-items-center">
        <vs-button @click="changeClaims" style="color: white; background-color: black; border-radius: 5px; font-size: 14px">SAVE CHANGES</vs-button>
      </div>
    </vs-popup>

    <div class="grid justify-items-center">
      <div style="margin: auto; width: 40%; margin-top: 70px">
        <el-input placeholder="Search For User" v-model="search"></el-input>
      </div>
    </div>

    <div style="margin-top: 15px" class="centered">
      <div>
        <button v-if="isEditor" @click="toggleNewUser" class="addNew">
          <i class="fas fa-plus"></i>
        </button>
        <div class="adminList">
          <vs-list>
            <vs-list-item v-for="user in filteredUsers" :key="user" :title="user.displayName" :subtitle="user.email">
              <template #avatar>
                <vs-avatar :src="user.photoURL" />
              </template>
              <vs-button class="rounded-e-md btn-user-role" @click="editClaims(user)" v-if="user.customClaims" :color="roleColor(user.customClaims)" :text-color="roleTextColor()">{{ user.customClaims.role }}</vs-button>
            </vs-list-item>
          </vs-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { storage } from '@/main.js';
import auth from '../helpers/auth';
import { mapState } from 'pinia';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';

export default {
  name: 'AppAdmin',

  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      forceRecomputeCounter: 0,
      imageData: '',
      image: '',
      uploadValue: '',
      clientColor: process.env.VUE_APP_PRIMARY_COLOR,
      currentRole: null,
      users: [],
      search: '',
      newClaims: {
        role: '',
        admin: false
      },
      roles: [
        {
          role: 'ADMINISTRATOR',
          admin: true
        },
        {
          role: 'CONTENT EDITOR',
          admin: false
        }
      ],
      openChangeClaims: false,
      openNewUser: false,
      newUser: {
        displayName: '',
        email: '',
        password: '',
        reEnterPassword: ''
      },
      currentUser: { email: '', name: '', uid: '', photoURL: '' }
    };
  },
  mounted() {
    this.$vs.loading();
    auth(this);
  },
  methods: {
    async submitNewUser() {
      try {
        this.$vs.loading();
        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/create`, {
          idToken: this.appStore.getClaims.token,
          ...this.newUser
        });
        console.log('WHAT IS R', r);
        if (r.status != 200) {
          this.$message({
            showClose: true,
            message: r.data.msg
          });
          this.$vs.loading.close();
        } else {
          setTimeout(async () => {
            const usersList = await this.retrieveUsers();
            const users = cloneDeep(usersList);
            const foundUser = users.find((user) => user.email === this.newUser.email);
            if (foundUser) {
              this.editClaims(foundUser, true);
              this.changeClaims(true);
            }
            this.$vs.loading.close();
            this.$message({
              showClose: true,
              message: 'User created successfully'
            });
            this.openNewUser = false;
          }, 7000);
        }
      } catch (err) {
        this.$vs.loading.close();
        this.$message({
          showClose: true,
          message: 'Error occured when creating user. Please try again'
        });
      }
    },
    toggleNewUser() {
      if (!this.isEditor) {
        return;
      }
      this.openNewUser ? (this.openNewUser = false) : (this.openNewUser = true);
      this.newUser = { displayName: '', email: '', password: '' };
    },
    imageClick() {
      this.$refs[`imageUpload-${this.currentUser.uid}`].click();
    },
    updateValue(text) {
      this.currentUser.name = text.target.innerHTML.trim();
    },
    async retrieveUsers() {
      this.$vs.loading();
      const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/list`, {
        claims: {}
      });
      if (r.status !== 200) {
        this.$message({ message: 'An error occurred', showClose: true });
        this.$vs.loading.close();
      } else {
        const users = r.data?.users;
        this.users = users;
        this.$vs.loading.close();
        this.forceRecomputeCounter++;
        return users;
      }
    },
    roleColor(claims) {
      if (claims.admin) {
        return 'danger';
      }
      if (claims.role === 'GUEST') {
        return 'dark';
      } else if (claims.role === 'CONTENT EDITOR') {
        return this.clientColor;
      } else {
        return 'warning';
      }
    },
    roleTextColor() {
      return 'primary';
    },
    timeoutFunction() {
      if (this.timeoutVar != null) {
        clearTimeout(this.timeoutVar);
      }
      this.timeoutVar = setTimeout(() => {}, this.timeToExpiry - 300000);
    },
    previewImage(event, r) {
      this.image = null;
      this.imageData = event.target.files[0];
      this.onUpload(r, event.target.files[0]);
    },
    async onUpload(r, imgData) {
      const storageRef = storage.ref(`Images/${imgData.name}-${Date.now()}`).put(imgData);
      storageRef.on(
        `state_changed`,
        () => {
          // let uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error('Error: ', error.message, error);
        },
        async () => {
          const url = await storageRef.snapshot.ref.getDownloadURL();
          this.updateUserPhoto(url);
        }
      );
    },
    editClaims(user, onCreate) {
      const claims = cloneDeep(user.customClaims);
      if (!onCreate) {
        !this.openChangeClaims ? (this.openChangeClaims = true) : (this.openChangeClaims = false);
      }
      this.currentUser = {
        email: user.email,
        name: user.displayName ? user.displayName : user.email,
        role: claims.role
      };
      this.newClaims.role = claims.role;
      this.newClaims.admin = claims.admin;
      this.newClaims.taxonomy = claims.taxonomy;
      this.currentUser.uid = user.uid;
      this.currentUser.photoURL = user.photoURL;
      this.forceRecomputeCounter++;
      this.roles = [
        {
          role: 'ADMINISTRATOR',
          admin: true
        },
        {
          role: 'CONTENT EDITOR',
          admin: false
        }
      ];
    },
    async updateUserName() {
      const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/update/name`, {
        idToken: this.appStore.getClaims.token,
        displayName: this.currentUser.name.trim(),
        ...this.currentUser
      });
      if (r.status != 200) {
        this.$message({
          showClose: true,
          message: 'Error occurred updating the user'
        });
      }
    },
    async updateUserPhoto(photoURL) {
      try {
        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/update/photo`, {
          idToken: this.appStore.getClaims.token,
          photoURL,
          uid: this.currentUser.uid
        });
        if (r.status != 200) {
          this.$message({
            showClose: true,
            message: 'Error occurred updating the users photo'
          });
        } else {
          this.$message({ message: 'User Photo Modified', showClose: true });
          this.retrieveUsers();
          setTimeout(() => {
            this.openChangeClaims = false;
          }, 1000);
        }
      } catch (err) {
        this.$message({
          showClose: true,
          message: 'Error occurred updating the users photo'
        });
      }
    },
    changeClaims(onCreate) {
      this.updateUserName();
      axios
        .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/users/setClaims`, {
          idToken: this.appStore.getClaims.token,
          claims: {
            role: onCreate ? 'CONTENT EDITOR' : this.newClaims.role,
            admin: this.newClaims.admin
            // taxonomy: this.newClaims.taxonomy
          },
          userToChange: this.currentUser
        })
        .then(async (result) => {
          if (result.data.status === 'success') {
            if (!onCreate) {
              this.$message({ message: 'User Access Modified', showClose: true });
            }
            await this.appStore.reCreateToken();
            await this.retrieveUsers();
            setTimeout(() => {
              this.openChangeClaims = false;
            }, 500);
          } else {
            this.$message({
              message: 'An error occurred while changing users access',
              showClose: true
            });
          }
        })
        .catch(() => {
          this.$message({
            message: 'An error occurred while changing users access',
            showClose: true
          });
        });
    }
  },
  computed: {
    ...mapState(useAppStore, ['isEditor']),
    filteredUsers() {
      return sortBy(this.users, 'displayName').filter((data) => {
        if (!Object.prototype.hasOwnProperty.call(data, 'displayName')) {
          data.displayName = 'n/a';
        }
        if (!Object.prototype.hasOwnProperty.call(data, 'email')) {
          data.email = 'n/a';
        }
        return !this.search || data.email.toLowerCase().includes(this.search.toLowerCase()) || data.displayName.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    timeToExpiry() {
      return this.appStore.getExpirationTime.date - Date.now();
    },
    getRoles() {
      return this.roles;
    }
  }
};
</script>

<style lang="scss" scoped>
.role-container {
  height: 40px;

  .role-dropdown {
    width: 100%;
    height: 100%;
  }
}

.adminList {
  overflow: hidden scroll !important;
  max-height: calc(100vh - 250px) !important;
  padding-bottom: 50px;
  padding-top: 5px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.adminList::-webkit-scrollbar {
  display: none;
}

.adminEdit {
  z-index: 200;
}

.btn-user-role {
  color: #ffffff;
}

.newField {
  font-size: 16px;
  color: black;
  text-align: left;
  margin-left: 5px;
  width: 100%;
}

.newField::placeholder {
  color: lightgrey;
}

.edit-input-field {
  width: 100%;
  text-align: center;
}
.addNew {
  margin-left: 15px;
  background: black;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.userPhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid white;
}

.centered {
  width: 60%;
  overflow-y: scroll;
  height: 90vh;
  padding-bottom: 60px;
  margin-top: 20px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: auto;
}

.claims {
  font-size: 16px;
  color: grey;
}

.accessEdit {
  padding-bottom: 15px;
  z-index: 200;
}

.centered::-webkit-scrollbar {
  display: none;
}
</style>
