<template>
  <div container-fluid-login>
    <div class="login">
      <div class="grid justify-items-center">
        <h2 class="header">Welcome</h2>
        <h2 class="sub-header">sign in to continue</h2>
        <br />
        <div class="login-credentials" v-if="!useToken">
          <input id="mainLoginEmail" tabindex="0" required type="text" v-model="email" placeholder="Email" /><br />
          <input id="mainLoginPassword" tabindex="1" required type="password" v-model="password" placeholder="Password" /><br />
          <button style="color: white" tabindex="2" @click="login">Sign In</button>
          <br />
          <br />
          <div class="special-hr">OR</div>

          <button @click="socialLogin" class="social-button">
            <img alt="Google Logo" src="../../assets/google-logo.png" />
            <span class="google-button-text">Sign in with Google</span>
          </button>
        </div>
        <div v-else>
          <input id="mainLoginToken" required type="text" v-model="token" placeholder="Token" /><br />
          <hr />
          <button id="Connect" style="color: white" @click="withTokenLogin">Connect</button>
        </div>
      </div>
      <br />
      <!-- <p>You don't have an account ? You can <router-link to="/sign-up">create one</router-link></p> -->
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import firebase from 'firebase/compat/app';
import { mapActions } from 'pinia';
import auth from '../helpers/auth';

export default {
  name: 'AppLogin',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      email: '',
      password: '',
      user_information: '',
      chLogo: process.env.VUE_APP_CONVERSATIONHEALTH_LOGO,
      useToken: false,
      token: ''
    };
  },
  mounted() {
    this.$route.query.token ? (this.useToken = true) : '';
  },
  methods: {
    ...mapActions(useAppStore, ['updateUser']),
    withTokenLogin: function () {
      firebase
        .auth()
        .signInWithCustomToken(this.token)
        .then((user) => {
          user.email = this.email;
          user.name = this.email;
          this.appStore.updateUser({ user: user });
          this.notificationsStore.getNotifications();
          location.reload();
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    },
    login: function () {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          async (user) => {
            user.email = this.email;
            user.name = this.email;
            this.appStore.updateUser({ user: user });
            // let idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
            // if (!idTokenResult?.claims?.version || idTokenResult?.claims?.version != '3x') {

            //   this.$message({
            //     message: 'Your account has not been authorized for 3x yet',
            //     duration: 2000
            //   });
            //   firebase.auth().signOut();
            //   return;
            // }
            this.notificationsStore.getNotifications();
            location.reload();
          },
          (err) => {
            alert('Please enter a valid email and password');
            console.log('Invalid username or password ' + err.message);
          }
        );
    },
    socialLogin() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({
        hd: 'conversationhealth.com'
      });
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          if (result.user) {
            this.appStore.updateUser({ user: result.user });
            this.notificationsStore.getNotifications();
            result.user.getIdToken(true);
          } else {
            console.warn('No user found in signInWithPopup');
          }

          // let idTokenResult = await firebase.auth().currentUser.getIdTokenResult(true);
          // if (!idTokenResult?.claims?.version || idTokenResult?.claims?.version != '3x') {
          //   this.$message({
          //     message: 'Your account has not been authorized for 3x yet',
          //     duration: 2000
          //   });
          //   firebase.auth().signOut();
          //   return;
          // }
          if (result.additionalUserInfo.isNewUser) {
            this.$vs.loading({
              text: 'Preparing permissions...',
              background: 'white',
              color: 'black'
            });
            setTimeout(() => {
              this.$vs.loading.close();
              this.$vs.loading({
                text: 'Counting backwards from Infinity....',
                background: 'white',
                color: 'black'
              });
              setTimeout(() => {
                this.$vs.loading.close();
                this.$vs.loading({
                  text: 'Cleaning off the cobwebs...',
                  background: 'white',
                  color: 'black'
                });
                setTimeout(() => {
                  this.$vs.loading.close();
                  this.$vs.loading({
                    text: "I swear it's almost done.",
                    background: 'white',
                    color: 'black'
                  });
                }, 2000);
              }, 2000);
            }, 2000);
            setTimeout(() => {
              location.reload();
            }, 7000);
          } else {
            this.$router.push('taxonomy');
            auth(this);
          }
        })
        .catch((err) => {
          alert('Oops. ' + err.message);
        });
    }
  }
};
</script>

<style scoped>
.header {
  color: #fb4222;
  margin: 0;
}

.sub-header {
  font-size: 25px;
}

.login-credentials {
  width: 300px;
}

.google-button-text {
  font-size: 16px;
}

.special-hr {
  color: rgba(0, 0, 0, 0.233);
  font-size: 16px;
  margin: 1.25rem 0;
  position: relative;
  text-align: center;
}

.special-hr:after {
  right: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#mainLoginEmail {
  background-color: #e7f0fe;
  margin-bottom: 10px;
  font-style: bold !important;
}

#mainLoginPassword {
  background-color: #e7f0fe;
}

::placeholder {
  font-style: normal;
}

.special-hr:before,
.special-hr::after {
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  content: '';
  height: 2px;
  position: absolute;
  top: 8px;
  width: 46%;
}

.container-fluid-login {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 101vh;
  background: white;
}

.login {
  /* margin-top: 20px; */
  justify-content: center;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  /* NEW, Spec - Firefox, Chrome, Opera */
  align-items: center;
  position: relative;
}

input {
  /* margin: 10px 0; */
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  /* border: 1px solid #fb4222; */
  height: 15%;
}

button {
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  background: #fb4222;
  height: 15%;
}

p {
  margin-top: 5px;
  font-size: 13px;
}

p a {
  text-decoration: underline;
  cursor: pointer;
}

img {
  padding: 5px;
}

.social-button {
  display: flex;
  flex-direction: row;
  background: white;
  height: 51px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  outline: 0;
  border: 0;
  align-items: center;
  display: flex;
  justify-content: center;
}

.social-button:active {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.social-button img {
  width: 40px;
  margin-right: 10px;
  height: 40px;
}
</style>
