<template>
  <div class="flow-status-properties-dropdown">
    <v-select
      :options="statuses"
      v-model="currentStatus"
      @input="updateParentData"
      placeholder="Choose status"
      filterable
      autocomplete
      :menu-props="{
        allowOverflow: true,
        bottom: true,
        contentClass: 'flow-status-properties-menu',
      }"
    />
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { defineComponent } from 'vue';
import { STATUS_NAMES } from '../../../components/Utilities/constants/status';

export default defineComponent({
  name: 'FlowStatusPropertiesDropdown',
  props: {
    selectedStatus: {
      type: String,
      default: 'No status passed',
    },
  },

  data() {
    return {
      appStore: useAppStore(),
      statuses: STATUS_NAMES,
      selected: '',
    };
  },

  computed: {
    currentStatus: {
      get() {
        return this.selectedStatus;
      },
      set(value) {
        this.updateParentData(value);
      }
    }
  },

  methods: {
    updateParentData(value) {
      this.$emit('updateParentData', { key: 'status', value: value });
    },
  }
});
</script>

<style scoped>
:deep(.vs__dropdown-menu) {
  max-height: 225px;
  overflow-y: auto;
}

.flow-status-properties-dropdown {
  padding: 0;
  width: 100%;
}
</style>