<template>
  <div
    :class="`comment-container ${hover ? 'active' : ''}`"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <div class="header">
      <img :src="comment.userPhoto" alt="" />
      <span class="user-name">{{ comment.userName }}</span>
      <span v-if="!hover || (hover && !isUsersComment)" class="age">{{
        commentHowOld
      }}</span>
      <div
        v-if="hover && isUsersComment && !getCommentFilters.resolved"
        @click="openList"
        v-click-outside="onClickOutsideList"
        tabindex="0"
      >
        <ConversationBuilderIcon
          :key="hover"
          class="add-button"
          :width="18"
          name="ellipsis_blue"
          :custom-style="iconContainerStyle"
        />
      </div>
    </div>
    <div class="options_container" v-if="isContainerOpen">
      <!-- <div class="option">
          <ConversationBuilderIcon :width="14" name="resolve"/>
          <span>Resolve</span>
        </div> -->
      <div class="option" @click="handleClick('edit')">
        <ConversationBuilderIcon :width="14" name="edit" />
        <span>Edit</span>
      </div>
      <div class="option" @click="handleClick('delete')">
        <ConversationBuilderIcon :width="14" name="delete" />
        <span>Delete</span>
      </div>
    </div>
    <div
      class="options_container"
      v-if="showConfirmDeleteContainer"
      v-click-outside="onClickOutsideConfirmDelete"
    >
      <div class="option" @click="handleClick('confirmDelete')">
        <ConversationBuilderIcon :width="14" name="delete" />
        <span>Confirm delete</span>
      </div>
      <div class="option" @click="handleClick('cancelDelete')">
        <ConversationBuilderIcon :width="14" name="remove" />
        <span>Cancel delete</span>
      </div>
    </div>
    <div class="content">
      <!-- <span class="number">{{ index + 1 }}</span> -->
      <p
        v-if="!showEditComment"
        class="comment-content"
        v-html="comment.text"
      />
      <CommentEditText
        @removeEditComment="handleClick('removeEditComment')"
        :id="this.comment.id"
        v-if="showEditComment"
        :isUpdating="true"
        :startingText="comment.text"
        @finished="handleFinishEditing"
        :index="index"
        :parentCommentId="parentCommentId"
      />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapActions, mapState } from 'pinia';
import CommentEditText from './CommentEditText.vue';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'ChildComment',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    CommentEditText,
  },
  props: {
    comment: {
      default: {},
    },
    index: {
      default: 0,
    },
    parentCommentId: {
      default: null,
    },
  },
  mounted() {
    this.commentAge();
    this.loadCommentAgeInterval = setInterval(() => this.commentAge(), 10000);
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
hover: false,
      isContainerOpen: false,
      showEditComment: false,
      iconContainerStyle: {
        width: '30px',
        height: '30px',
        'background-color': '#fff',
        'border-radius': '15px',
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        cursor: 'pointer',
      },
      commentHowOld: null,
      showConfirmDeleteContainer: false,
      loadCommentAgeInterval: null,
    };
  },
  computed: {
    ...mapState(useCommentsStore, ['getCommentFilters']),
    isUsersComment() {
      return this.comment.userId === this.appStore.getUser.uid;
    },
  },
  methods: {
    ...mapActions(useCommentsStore, ['deleteChildComment']),
    onClickOutsideList() {
      this.isContainerOpen = false;
    },
    onClickOutsideConfirmDelete() {
      this.showConfirmDeleteContainer = false;
    },
    openList() {
      this.isContainerOpen = !this.isContainerOpen;
    },
    handleClick(e) {
      if (e === 'delete') {
        this.showConfirmDeleteContainer = true;
      }
      if (e === 'edit') {
        this.showEditComment = true;
      }
      if (e === 'confirmDelete') {
        this.deleteChildComment({
          index: this.index,
          parentCommentId: this.parentCommentId,
        });
        this.showConfirmDeleteContainer = false;
      }
      if (e === 'cancelDelete') {
        this.showConfirmDeleteContainer = false;
      }
      if (e === 'removeEditComment') {
        this.showEditComment = false;
      }
      this.isContainerOpen = false;
    },
    handleFinishEditing() {
      this.showEditComment = false;
    },
    commentAge() {
      const seconds = Math.floor((new Date() - this.comment.createdAt) / 1000);

      let interval = seconds / 31536000;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' years ago';
        return;
      }

      interval = seconds / 2592000;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' months ago';
        return;
      }

      interval = seconds / 86400;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' days ago';
        return;
      }

      interval = seconds / 3600;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' hours ago';
        return;
      }

      interval = seconds / 60;
      if (interval >= 1) {
        this.commentHowOld = Math.floor(interval) + ' minutes ago';
        return;
      }

      this.commentHowOld = Math.floor(seconds) + ' seconds ago';
      return;
    },
  },
  beforeUnmount() {
    clearInterval(this.loadCommentAgeInterval);
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #f2f2f2;
}

.comment-container {
  width: 100%;
  // min-height: 134px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px 30px;
  // border-left: 5px solid #fd5422;
  position: relative;

  .options_container {
    width: 141px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 4;

    .option {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      i {
        margin-left: 11px;
      }

      span {
        margin-left: 13px;
        font-size: 14px;
        line-height: 2.14;
        text-align: left;
      }
    }

    .option:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .option:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .option:hover {
      background-color: #e8f5fe;
    }
  }

  .header {
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 20px;
      height: 20px;
      background-color: #fd5422;
      border-radius: 10px;
      margin-right: 10px;
      object-fit: cover;
    }

    .user-name {
      font-size: 14px;
      line-height: 1.36;
      margin-right: auto;
    }

    .age {
      font-size: 12px;
      line-height: 1.33;
    }
  }

  .content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    // margin-bottom: 10px;

    .number {
      width: 20px;
      height: 20px;
      background-color: #fd5422;
      border-radius: 10px;
      margin-right: 10px;
      font-size: 12px;
      line-height: 2;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .comment-content {
      width: 330px;
      // min-height: 45px;
      font-size: 17px;
      line-height: 1.29;
      color: #383f45;
      text-align: left;
      margin: 0;
      word-wrap: break-word;
    }
  }
}
</style>
