<template>
  <div class="pageHeader px-5">
    <BreadCrumb v-if="showBreadcrumbs" />
    <h2 v-if="subtitle">{{ subtitle }}</h2>
    <h1 v-if="title">{{ title }}</h1>
  </div>
</template>

<script>
import BreadCrumb from '../BreadCrumbs/BreadCrumb.vue';

export default {
  name: 'PageHeader',
  components: {
    BreadCrumb
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    showBreadcrumbs: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.pageHeader {
  height: 80px;
  border-bottom: 1px solid #ebeef5;
  position: fixed;
  z-index: 2;
  width: 100%;
  background: #fff;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}
.drwr .pageHeader {
  border-bottom: none;
}
h1 {
  font-size: 21px;
  line-height: 28px;
  font-weight: bold;
  color: #383f45;
  margin-bottom: 0px;
}
h2 {
  font-size: 14px;
  line-height: 56px;
  color: #383f45;
  line-height: 28px;
  margin-bottom: 0px;
}
</style>
