<template>
  <div class="graph vs-con-loading__container" id="sunburst-content-loading">
    <!-- Use this slot to add information on top or bottom of the graph-->
    <slot name="legend" :width="width" :nodes="graphNodes" :actions="actions"> </slot>

    <div class="agentIntentsDropDown dropdown-container">
      <span v-if="graphNodes.mouseOver" class="mouse-over-label">{{ graphNodes?.mouseOver?.data?.data?.name }}</span>
      <div class="dropdown-wrapper">
        <v-select append-to-body :clearable="false" @search:blur="filterTaxonomy('')" :filter="filteredSlots" :clearSearchOnSelect="false" filterable autocomplete class="select-intent" @visible-change="sameSlot($event, searchInput)" @update:modelValue="searchToSlot" placeholder="Select Intent" style="width: 100%; min-width: 250px" name="search" :reduce="(slot) => slot" label="name" :options="slots" :modelValue="searchInput">
          <template v-slot:option="slot">
            <span :style="{ color: slot.color, fontWeight: 'bold' }">{{ slot.name }}</span>
          </template>
        </v-select>
      </div>
    </div>

    <div :style="{ visibility: appStore.isEditor ? 'visible' : 'hidden' }" class="viewport2">
      <div v-if="!opentree" class="viewport" v-resize.throttle.250="resize">
        <div class="taxonomyLegend">
          <ul style="padding: 14px; list-style-type: none">
            <li style="" :key="item" v-for="(item, key) in appStore.statusColor">
              <i :style="{ color: item, marginRight: '10px' }" class="fas fa-circle"></i>
              <span style="font-size: 12px">{{ key }}</span>
            </li>
          </ul>
        </div>
        <slot name="top" :nodes="graphNodes" :actions="actions"> </slot>

        <slot :nodes="graphNodes" :actions="actions"> </slot>
      </div>
    </div>

    <vs-popup v-model:active="editStatus" title="Edit Status">
      <div class="grid justify-items-center">
        <div style="width: max-content; color: black">
          <el-radio-group :fill="statusColors(currentStatus)" @change="changeStatus" size="large" v-model="currentStatus">
            <el-radio-button label="APPROVED">APPROVED <i class="fas fa-check"></i></el-radio-button>
            <el-radio-button label="IN PROGRESS">IN PROGRESS <i class="fas fa-spinner"></i></el-radio-button>
            <el-radio-button label="TESTED BY QA">TESTED BY QA <i class="fas fa-user-edit"></i></el-radio-button>
            <el-radio-button label="MISSING CONTENT">MISSING CONTENT <i class="fas fa-exclamation-triangle"></i></el-radio-button>
            <el-radio-button label="READY FOR REVIEW">READY FOR REVIEW <i class="fas fa-binoculars"></i></el-radio-button>
            <el-radio-button label="CLIENT REVIEWED">CLIENT REVIEWED <i class="far fa-thumbs-up"></i> </el-radio-button>
            <el-radio-button label="DISABLED">DISABLED <i class="fas fa-eye-slash"></i></el-radio-button>
            <el-radio-button label="AE/PC">AE/PC <i class="fas fa-user-md"></i></el-radio-button>
            <el-radio-button label="IMPERSONATION">IMPERSONATION <i class="fas fa-user-md"></i></el-radio-button>
            <el-radio-button label="ONFS">ONFs <i class="fas fa-user-md"></i></el-radio-button>
            <el-radio-button label="NONE">NONE <i class="fas fa-battery-empty"></i></el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </vs-popup>

    <vs-popup v-model:active="TestUtterance" title="Edit Test Utterances">
      <div class="con-exemple-prompt">
        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 20 }" placeholder="Paste Test Utterances" v-model="TestUtteranceField"> </el-input>
      </div>

      <button @click="saveTestUtterance()" style="color: white; float: right; background-color: rgb(0, 155, 207); padding: 10px; border-radius: 15px; font-weight: 700">Save</button>
    </vs-popup>

    <vs-popup v-model:active="activePrompt" title="Add Multiple Slots">
      <div>
        <span class="slotProps">Enter name of child slot(s)</span>
        <vs-input :key="n" v-for="(n, index) in 6" placeholder="Enter Slot Name" v-model="Slot[`name${index}`]" />
      </div>

      <vs-button class="saveButton" @click="batchCreate_slots(Object.values(Slot), graphNodes?.clicked)">Accept</vs-button>
    </vs-popup>

    <vs-popup v-model:active="displayNameOpen" title="Display Names">
      <div class="flag-input-container-wrapper">
        <div class="flag-input-container">
          <div class="flag-input-row" v-for="(item, index) in languageTypes" :key="index">
            <country-flag :country="item" size="normal" class="country-flag" />
            <vs-input style="margin-top: 0px; font-size: 16px" placeholder="" v-model="displayNames[`${item.toUpperCase()}`]" class="language-input" />
          </div>
        </div>
      </div>

      <vs-button @click="displayStatus()" class="saveButton">Save</vs-button>
    </vs-popup>

    <vs-popup v-model:active="slotProperties" class="popup-slot-properties" title="Slot Properties">
      <div class="slot-property-container-wrapper">
        <div class="slot-property-container">
          <div
            class="slot-property-row"
            v-for="(prop, index) in [
              {
                propTitle: 'Vault Number',
                value: 'vault',
                placeholder: 'Vault'
              }
            ]"
            :key="index"
          >
            <span class="prop-title">{{ prop.propTitle }}</span>
            <vs-input style="margin-top: 0px; font-size: 16px" :placeholder="prop.placeholder" v-model="slotPropertiesObject[`${prop.value}`]" class="slot-input" />
          </div>
          <div class="slot-property-row route-to-flow">
            <span class="prop-title">Route to Flow:</span>
            <v-select :clearable="true" v-model="routeToFlow" placeholder="Select Flow" :reduce="(option) => option" class="select-flow" :getOptionLabel="(option) => option.name" :options="flowPropertiesArray" filterable autocomplete autoscroll />
          </div>
          <div class="slot-property-row route-to-flow">
            <span class="prop-title">User:</span>
            <span>{{ this.slotPropertiesObject.createdBy }}</span>
          </div>
          <div class="slot-property-row route-to-flow">
            <span class="prop-title">Timestamp</span>
            <span>{{ this.slotPropertiesObject.timestamp }}</span>
          </div>
        </div>
      </div>
      <vs-button @click="saveSlotProperties()" class="saveButton">Save</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "transition" }]*/

import { languageTypes, STATUS_COLORS } from '@/components/Utilities/constants/constants';
import resize from 'vue-resize-directive';
import { scaleLinear } from 'd3-scale';
import { hierarchy, partition, stratify } from 'd3-hierarchy';
import { interpolate } from 'd3-interpolate';
import { arc } from 'd3-shape';
import { colorSchemes } from '@/components/Utilities/infra/colorSchemes';
import TrainingPhrases from '@/components/TrainingPhrases/TrainingPhrases.vue';
import * as d3 from 'd3';
import CountryFlag from 'vue-country-flag-next';
import CustomDialog from '@/components/ConversationBuilder/Popover/CustomDialog';
import { setTimeout } from 'timers';
import _ from 'lodash';
import { mapState } from 'pinia';
import isValidIntentName from '../../helpers/isValidIntentName';
import { useModal, useModalSlot, VueFinalModal } from 'vue-final-modal';
import { mapActions } from 'pinia';
import axios from 'axios';

function recursiveName(node) {
  const res = node
    .ancestors()
    .map((node) => node?.data?.name)
    .join('-');
  return res;
}

function copyCurrentValues(to, from) {
  const { x0, x1, y0, y1 } = from;
  to._current = { x0, x1, y0, y1 };
}

function arc2Tween(element, arcSunburst, d, indx) {
  const positionsKeys = ['x0', 'x1', 'y0', 'y1'];
  const interpolates = {};
  positionsKeys.forEach((key) => {
    if (element) {
      interpolates[key] = interpolate(element._current[key], d[key]);
    }
  });
  copyCurrentValues(element, d);

  return function (t) {
    const tmp = {};
    positionsKeys.forEach((key) => {
      tmp[key] = interpolates[key](t);
    });
    return arcSunburst(tmp, indx);
  };
}

const useNameForColor = (d) => d.data?.data?.name;

export default {
  name: 'AgentIntentsSunburst',
  components: {
    CountryFlag
  },

  props: {
    /**
     * Sunburst data where children property is a array containing children.
     */
    collection: {
      type: String,
      required: true
    },

    data: {
      type: Object,
      required: false
    },
    dataNonTaxonomy: {
      type: Object,
      required: false
    },
    /**
     * D3 color scheme to be used.
     */
    colorScheme: {
      type: String,
      required: false,
      default() {
        return Object.keys(colorSchemes)[0];
      },
      validator: (value) => Object.keys(colorSchemes).indexOf(value) !== -1
    },
    /**
     * Function used to map an item and its color.
     * (nodeD3: Object) => category: Number | String
     * By default use the node name
     */
    getCategoryForColor: {
      type: Function,
      required: false,
      default: useNameForColor
    },
    /**
     * Minimal arc angle to be displayed (in radian).
     */
    minAngleDisplayed: {
      type: Number,
      required: false,
      default: 0.0
    },
    /**
     * Function used to identify an arc, will be used during graph updates.
     * (nodeD3: Object) => id: String
     * @default id based on recursive agregation of node parent name
     */
    arcIdentification: {
      type: Function,
      required: false,
      default: recursiveName
    },
    /**
     *  Duration for in animation in milliseconds
     */
    inAnimationDuration: {
      type: Number,
      required: false,
      default: 100
    },
    /**
     *  Duration for out animation in milliseconds
     */
    outAnimationDuration: {
      type: Number,
      required: false,
      default: 1000
    }
  },

  directives: {
    resize
  },

  data() {
    const scaleX = scaleLinear()
      .range([0, 2 * Math.PI])
      .clamp(true);

    const scaleY = scaleLinear().range([0, 1.0]);
    // range([0,0.98,1.0]);
    this.arcSunburst = arc()
      .startAngle((d) => scaleX(d.x0))
      .endAngle((d) => scaleX(d.x1))
      .innerRadius((d) => Math.max(0, scaleY(d.y0)))
      .outerRadius((d) => Math.max(0, scaleY(d.y1)));

    this.scaleX = scaleX;
    this.scaleY = scaleY;

    return {
      appStore: useAppStore(),
      taxonomyData: this.data,
      viewport: null,
      Deselect: {
        render: (createElement) => createElement('span', '❌')
      },
      graphNodes: {
        clicked: null,
        mouseOver: null,
        zoomed: null,
        root: null,
        highlighted: null
      },
      width: null,
      clientLogo: process.env.VUE_APP_CLIENT_LOGO,
      tempSlot: null,
      displayNameOpen: false,
      displayName: { value: null },
      displayNames: {},
      currentStatus: null,
      colorCounter: {
        blue: [],
        red: [],
        pink: [],
        green: [],
        grey: [],
        orange: [],
        other: []
      },
      slots: [],
      fontSizeLonger: 2,
      searchInput: '',
      searchInputTemp: '',
      fontSize: 3,
      height: null,
      showModal: false,
      slotsNonTaxonomy: [],
      activePrompt: false,
      svg: null,
      start_x: null,
      start_y: null,
      opentree: false,
      newView: true,
      slotExists: false,
      g: null,
      editStatus: false,
      TestUtterance: false,
      TestUtteranceField: '',
      languageTypes,
      Slot: {
        name1: '',
        name2: '',
        name3: '',
        name4: '',
        name5: '',
        name6: ''
      },
      slotPropertiesObject: {
        vault: '',
        batch: '',
        id: '',
        onLabel: '',
        lastEdited: '',
        createdBy: '',
        timestamp: ''
      },
      slotProperties: false,
      flowPropertiesArray: [],
      routeToFlow: {},
      loadResetTimeout: null,
      loadSlotsTimeout: null
    };
  },
  async mounted() {
    this.$vs.loading({
      container: '#sunburst-content-loading'
    });
    await this.appStore.getAllFlows({
      collection: this.collection,
      dropdown: false,
      store: true
    });
    const slots = await this.appStore.getAllSlots({
      collection: this.collection,
      dropdown: false
    });
    this.flowPropertiesArray = this.currentFlows.map((x) => {
      return {
        name: x.name,
        id: x.id
      };
    });

    // eslint-disable-next-line vue/no-mutating-props
    if (slots.length) {
      this.taxonomyData = stratify()
        .id(function (d) {
          return d.id;
        })
        .parentId(function (d) {
          return d.parent;
        })(slots);

      this.slots = slots.sort(function (a, b) {
        return a.name.localeCompare(b.name);
      });

      this.browserDimensionsCheck();

      const zoom = d3
        .zoom()
        .scaleExtent([1, 8])
        .on('zoom', (e) => this.zoomed(e));
      const [viewport] = document.getElementsByClassName('viewport');
      this.viewport = viewport;

      this.vis = d3.select(viewport).append('svg:svg').attr('width', '100%').attr('height', '100%').call(zoom).append('svg:g');

      const g = this.vis.append('g');
      g.transition().duration(750).call(zoom.transform, d3.zoomIdentity.scale(1));
      this.vis.transition().duration(750).call(zoom.transform, d3.zoomIdentity.scale(1));
      this.resize();

      try {
        if (this.$route.query.flow) {
          this.$vs.loading();
          const target = await this.slots.find(async (item) => {
            return item.id === this.$route.query.flow || item.name === this.$route.query.flow;
          });
          if (target === undefined) {
            this.$message({ message: 'Unable to find slot', duration: 0 });
          }
          this.searchInput = this.$route.query.flow;
          this.searchToSlot();
          this.$vs.loading.close();
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
        this.$vs.loading.close();
      }
    } else {
      this.$vs.loading.close();
    }

    this.$vs.loading.close('#sunburst-content-loading > .con-vs-loading');
  },
  methods: {
    ...mapActions(useAppStore, ['setSlots']),
    async getSlots() {
      try {
        this.appStore.getAllSlots({ collection: this.collection }).then((slots) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.taxonomyData = stratify()
            .id(function (d) {
              return d.id;
            })
            .parentId(function (d) {
              return d.parent;
            })(slots);

          this.slots = slots.sort(function (a, b) {
            return a.name.localeCompare(b.name);
          });
        });
      } catch (err) {
        console.error('Error fetching slots:', err);
      }
    },

    filteredSlots(options, search) {
      this.filterTaxonomy(search);
      return options.filter((x) => {
        if (x.name) return x.name?.toLowerCase().includes(search?.toLowerCase());
      });
    },
    async loadSlotProperties(value) {
      try {
        //Reset slot Props. fixing issue from another feature
        this.slotPropertiesObject.vault = '';
        this.slotPropertiesObject.id = '';
        this.slotPropertiesObject.onLabel = '';
        this.slotPropertiesObject.batch = '';
        this.slotPropertiesObject.lastEdited = '';
        this.slotPropertiesObject.createdBy = '';
        this.slotPropertiesObject.timestamp = '';
        this.routeToFlow = {};

        const slotProperties = (
          await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/getSlotProperties`, {
            collection: this.collection,
            dataId: value.data?.data?.id
          })
        ).data;
        if (!slotProperties) return;

        const seconds = slotProperties.timestamp?._seconds;
        const nanoseconds = slotProperties.timestamp?._nanoseconds;
        const milliseconds = seconds * 1000 + Math.round(nanoseconds / 1e6);
        const date = new Date(milliseconds);

        this.slotPropertiesObject.vault = slotProperties?.vault || '';
        this.slotPropertiesObject.createdBy = slotProperties?.createdBy || '';
        this.slotPropertiesObject.timestamp = date.toString() !== 'Invalid Date' ? date : '';
        //To deal with case where the slot properties version document is missing vault, but the slot document itself has a value for vault
        if (!this.slotPropertiesObject.vault) {
          const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/getSlotVault`, {
            collection: this.collection,
            intentDocId: value.data?.data?.id
          });
          if (response.status === 200 && response.data?.vault) {
            this.slotPropertiesObject.vault = response.data?.vault;
          }
        }
        this.slotPropertiesObject.id = slotProperties?.id || '';
        this.slotPropertiesObject.onLabel = typeof slotProperties.onLabel === 'string' ? false : slotProperties.onLabel;
        this.slotPropertiesObject.batch = value.data?.data?.batch ? value.data?.data?.batch : '0';

        if (slotProperties?.routeToFlow) {
          const flowId = slotProperties?.routeToFlow?.id;
          const flowName = this.flowPropertiesArray.find((x) => x.id === flowId)?.name || slotProperties?.routeToFlow?.name;
          this.routeToFlow = { id: flowId, name: flowName };
        } else {
          this.routeToFlow = {};
        }
      } catch (err) {
        console.error('Error: Could not load Slot Properties', err.message, err);
      }
    },

    async saveDescription(e) {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/saveDescription`, {
        collection: this.collection,
        doc: this.graphNodes?.clicked?.data?.data?.id,
        description: e.target.value
      });

      if (response.status === 200) {
        this.$message({
          message: 'Change to slot description was saved',
          showClose: true,
          offset: 100,
          duration: 1000
        });
        this.$emit('reset');
      } else if (response.status === 404) {
        this.$message({ message: 'Error occured saving', showClose: true });
      }
    },
    filterTaxonomy(e) {
      const intent = e?.name;
      const searchTemp = intent?.toLowerCase();
      if (searchTemp?.length === 0 || !searchTemp) {
        this.vis.selectAll('path').each(function () {
          d3.select(this).style('opacity', 1);
        });
      } else {
        this.vis.selectAll('path').each(function (d) {
          d3.select(this).style('opacity', 1); //making all paths to opacity 1
          if (d.data?.data?.name && d.data?.data?.name?.toLowerCase().includes(searchTemp)) {
            d3.select(this).style('opacity', 1); //making matching path's to opacity 1
          } else if (d.data?.data?.color && d.data?.data?.color?.toLowerCase() === searchTemp) {
            d3.select(this).style('opacity', 1); //making matching path's to opacity 1
          } else if (d.data?.data?.description && d.data?.data?.description?.toLowerCase().includes(searchTemp)) {
            d3.select(this).style('opacity', 1); //making matching path's to opacity 1
          } else if (d.data?.data?.entitiesFormatted && d.data?.data?.entitiesFormatted?.toLowerCase().includes(searchTemp)) {
            d3.select(this).style('opacity', 1); //making matching path's to opacity 1
          } else if (d.data?.data?.status && d.data?.data?.status?.toLowerCase() === searchTemp) {
            d3.select(this).style('opacity', 1); //making matching path's to opacity 1
          } else {
            // else if (d.data?.data?.status?.toLowerCase() === document.getElementById("searchid").value?.toLowerCase()) {
            //     d3.select(this).style("opacity", 1);//making matching path's to opacity 1
            // }
            d3.select(this).style('opacity', 0.3); //making matching path's to opacity 0.1
          }
        });
        // this.searchToSlot(e);
      }
    },
    sameSlot(status, test) {
      if (!status && this.tempSlot === test) {
        this.searchToSlot();
      }
      this.tempSlot = test;
      this.$emit('blur');
    },
    browserDimensionsCheck() {
      // console.log(window.innerWidth, window.innerHeight);
      if (window.innerWidth < 1200 && window.innerHeight > 650) {
        this.$message({
          message: 'Increase browser width for better experience',
          showClose: true
        });
      } else if (window.innerWidth > 1200 && window.innerHeight < 650) {
        this.$message({
          message: 'Increase browser height for better experience',
          showClose: true
        });
      } else if (window.innerWidth < 1200 && window.innerHeight < 650) {
        this.$message({
          message: 'Increase browser height and width for better experience',
          showClose: true
        });
      }
    },
    statusColors(status) {
      return STATUS_COLORS[status];
    },
    fontSizeCalculator(d) {
      if (d.data.height === 0) {
        return `${this.fontSize - 1}px`;
      } else if (d.data.depth === 0) {
        return '15px';
      } else {
        return `${this.fontSize}px`;
      }
    },
    textClass(d) {
      if (d.data.depth === 0) {
        return 'middleSlot';
      } else {
        return '';
      }
    },
    zoomed(event) {
      if (event.transform.k <= 1) {
        event.transform.x = event.transform.x < this.viewport.clientWidth / 2.3 ? this.viewport.clientWidth / 2.3 : event.transform.x;
        event.transform.y = event.transform.y < this.viewport.clientHeight / 2.5 ? this.viewport.clientHeight / 2.5 : event.transform.y;
        event.transform.y = event.transform.y < 0 && event.transform.k < 1.5 ? this.viewport.clientHeight / 2 : event.transform.y;
      }
      this.vis.attr('transform', event.transform);
    },
    clipboardSuccessHandler() {
      this.$vs.notify({
        title: `Slots have been copied   <i class="fas fa-thumbs-up"></i>`,
        text: 'Paste anywhere',
        color: 'primary',
        time: 3000
      });
      // console.log(value.sort())
    },
    clipboardErrorHandler() {
      this.$vs.notify({
        title: `Slots copying unsuccessful  <i class="fas fa-thumbs-down"></i>`,
        text: 'Please try again',
        color: 'primary',
        time: 3000
      });
    },
    async displayStatus() {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/displayStatus`, {
        collection: this.collection,
        document: this.displayName?.value?.data?.data?.id,
        displayNames: JSON.stringify(this.displayNames)
      });

      if (response.status === 200) {
        this.$message({
          message: 'Display Names change saved',
          showClose: true,
          duration: 1500
        });
      } else if (response.status === 404) {
        this.notify('Error saving change', 'danger');
      }
    },
    changeStatus() {
      this.editStatus = false;
      const value = this.currentSlotData;
      this.$confirm(`Are you sure you want to change the status of this slot to ${this.currentStatus}?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
        roundButton: true
      })
        .then(() => {
          this.appStore
            .updateSlotStatus({
              record: true,
              slotName: value.data?.data?.name,
              color: this.statusColors(this.currentStatus),
              username: this.appStore.getUser.email,
              collection: this.collection,
              id: value.data?.data?.id,
              status: this.currentStatus.toUpperCase().trim()
            })
            .then(() => {
              this.$emit('reset');
            })
            .catch(() => {
              // this.notify('Error saving change', 'danger')
            });

          this.editStatus = false;
          this.currentSlotData = null;
        })
        .catch(() => {
          this.editStatus = false;
        });
    },
    async batchCreate_slots(slot_object, value) {
      this.slotExists = false;

      for (let i = 0; i < slot_object.length; i++) {
        this.slots.forEach((element) => {
          if (element.name?.toLowerCase() === slot_object[i]?.toLowerCase()) {
            this.slotExists = true;
          }
        });
      }
      if (this.slotExists === true) {
        this.$message({
          message: `Cannot create a slot that already exists`,
          showClose: true
        });
      } else {
        this.activePrompt = false;
        this.loadSlotsTimeout = setTimeout(async () => {
          for (let i = 0; i < slot_object.length; i++) {
            if (!isValidIntentName(slot_object[i]) && slot_object[i].length > 1) {
              this.$vs.notify({
                title: `Could not create this Slot titled: ${slot_object[i]}`,
                text: "Cannot use spaces or special characters except '-' and '_' in intent names",
                color: 'danger',
                time: 5000
              });
            } else if (slot_object[i].length > 1) {
              const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/batchCreateSlots`, {
                collection: this.collection,
                name: _.upperFirst(slot_object[i]).trim(),
                parent: value.data?.data?.id,
                createdBy: this.appStore.user.email
              });

              if (response.status === 201) {
                this.activePrompt = false;
                this.$message({
                  message: 'Slot change saved',
                  showClose: true,
                  duration: 1000
                });
              } else if (response.status === 500) this.notify('Error saving change', 'danger');
            }
          }
          this.$emit('reset');
        }, 200);
      }
    },
    getSize() {
      if (!this.viewport.value) return { width: 800, height: 600 };
      const width = this.viewport.clientWidth;
      const height = this.viewport.clientHeight;
      return { width, height };
    },
    onData(data) {
      if (!data) {
        this.vis.selectAll('path').remove();
        Object.keys(this.graphNodes).forEach((k) => (this.graphNodes[k] = null));
        return;
      }

      this.root = hierarchy(data)
        .sum((d) => d.data.size)
        .sort((a, b) => b.value - a.value);

      this.nodes = partition()(this.root)
        .descendants()
        .filter((d) => Math.abs(this.scaleX(d.x1 - d.x0)) > this.minAngleDisplayed);

      const paths = this.getPaths();
      const texts = this.getTexts();
      // const colorGetter = this.colorGetter;
      const mouseOver = this.mouseOver.bind(this);
      this.responses_click.bind(this);
      this.utterance_click.bind(this);
      const editClick = this.editClick.bind(this);
      this.batchCreate.bind(this);

      // const node_edit = this.node_edit.bind(this);

      const { arcSunburst } = this;

      function getAngle(d) {
        // Offset the angle by 90 deg since the '0' degree axis for arc is Y axis, while
        // for text it is the X axis.
        const thetaDeg = ((180 / Math.PI) * (arcSunburst.startAngle()(d) + arcSunburst.endAngle()(d))) / 2 - 90;
        // If we are rotating the text by more than 90 deg, then "flip" it.
        // This is why "text-anchor", "middle" is important, otherwise, this "flip" would
        // a little harder.
        return thetaDeg > 90 ? thetaDeg - 180 : thetaDeg;
      }

      paths
        .enter()
        .append('path')
        .style('opacity', 0.8)
        .attr('stroke', 'black')
        .attr('stroke-width', '0.2')
        .attr('font-size', (d) => this.fontSizeCalculator(d))
        .attr('cursor', 'crosshair')
        .on('mouseover', (event, d) => mouseOver(d))
        .on('click', function (event, d) {
          editClick(d);
        })
        .each(function (d) {
          copyCurrentValues(this, d);
        })
        .merge(paths)
        .style('fill', (d) => (d.data?.data?.name ? d.data?.data?.color : d.parent?.data?.color))
        .transition('enter')
        .duration(this.inAnimationDuration)
        .attrTween('d', function (d, index) {
          return arc2Tween(this, arcSunburst, d, index);
        });

      const arcText = this.vis.select(this.parentNode).select('text');
      // fade in the text element and recalculate positions
      arcText
        .transition('enter')
        .duration(750)
        .attr('opacity', 1)
        .text((d) => d.data?.data?.color)
        .attr('font-size', (d) => this.fontSizeCalculator(d))
        .attr('transform', function (d) {
          if (d.depth > 0 && arcSunburst.centroid(d) && getAngle(d)) {
            return 'translate(' + arcSunburst.centroid(d) + ')' + 'rotate(' + getAngle(d) + ')';
          } else {
            return null;
          }
        });

      paths.exit().remove();

      if (this.newView === true) {
        texts
          .enter()
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('pointer-events', 'none')
          .attr('dy', '0.35em')
          .attr('font-size', (d) => this.fontSizeCalculator(d))
          // .style('font-family', d => this.fontFamily(d))
          .attr('class', (d) => this.textClass(d))
          .style('fill', 'whitesmoke')
          .on('mouseover', (event, d) => mouseOver(d))
          .on('click', function (event, d) {
            editClick(d);
          })
          .each(function (d) {
            copyCurrentValues(this, d);
          })
          .merge(texts)
          .attr('transform', function (d) {
            if (d.depth > 0 && arcSunburst.centroid(d) && getAngle(d)) {
              return 'translate(' + arcSunburst.centroid(d) + ')' + 'rotate(' + getAngle(d) + ')';
            } else {
              return null;
            }
          })
          .transition('enter')
          .duration(this.inAnimationDuration)
          .text((d) => d.data?.data?.name.toUpperCase())
          .style('pointer-events', 'auto')
          .attrTween('d', function (d, index) {
            return arc2Tween(this, arcSunburst, d, index);
          });
      } else {
        texts
          .enter()
          .append('text')
          .attr('text-anchor', 'middle')
          .attr('pointer-events', 'none')
          .attr('dy', '0.35em')
          .attr('font-size', this.fontSize + 'px')
          .style('fill', 'whitesmoke')

          .on('click', function (event, d) {
            editClick(d);
          })
          .each(function (d) {
            copyCurrentValues(this, d);
          })
          .merge(texts)
          .attr('transform', function (d) {
            if (d.depth > 0 && arcSunburst.centroid(d) && getAngle(d)) {
              return 'translate(' + arcSunburst.centroid(d) + ')' + 'rotate(' + getAngle(d) + ')';
            } else {
              return null;
            }
          })
          .transition('enter')
          .duration(this.inAnimationDuration)
          .text((d) => d.data?.data?.status)
          .style('pointer-events', 'auto')
          .attrTween('d', function (d, index) {
            return arc2Tween(this, arcSunburst, d, index);
          });
      }
      this.$route.query.builder ? '' : this.$vs.loading.close();
      texts.exit().remove();
      this.graphNodes.root = this.nodes[0];
    },
    searchToSlot(e) {
      try {
        if (e) this.searchInput = e.name;
        this.vis.selectAll('path').each((d) => {
          if (d.data.data.name === this.searchInput || d.data.data.id === this.searchInput) {
            if (this.$route.query.responses) {
              this.responses_click(d);
              this.$vs.loading.close();
            } else if (this.$route.query.utterance) {
              this.utterance_click(d);
              this.$vs.loading.close();
            } else {
              this.editClick(d);
              this.$vs.loading.close();
            }
          }
        });
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    getPaths() {
      return this.vis.selectAll('path').data(this.nodes, this.arcIdentification);
    },
    getTexts() {
      return this.vis.selectAll('text').data(this.nodes, this.arcIdentification);
    },
    resize() {
      const { width, height } = this.getSize();
      // this.vis
      //    .attr('width', width)
      //    .attr('height', height)
      //    .attr('transform', `translate(${width / 2}, ${height / 2} )`)

      this.radius = Math.min(width, height) / 2;
      const [scaleYMin] = this.scaleY.range();
      this.scaleY.range([scaleYMin, this.radius]);

      this.onData(this.taxonomyData);

      this.width = width;
      this.height = height;
    },
    reDraw() {
      this.onData(this.taxonomyData);
    },
    notify(title_text, color_notify) {
      this.$vs.notify({
        title: title_text + '    ' + "     <i class='fas fa-save'></i>",
        text: 'Successfully saved to database',
        color: color_notify,
        time: 3000
      });
    },
    mouseOver(value) {
      this.graphNodes.mouseOver = value;
      /**
       * Fired when mouse is over a sunburst node.
       * @param {Object} value - {node, sunburst} The corresponding node and sunburst component
       */
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('mouseOverNode', { node: value, sunburst: this });
    },
    responses_clickNew(value) {
      this.graphNodes.clicked = value;

      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: {
          focusTrap: false,
          name: 'taxonomy-response-modal',
          adaptive: true,
          teleportTo: 'body',
          modelValue: false,
          displayDirective: 'if',
          hideOverlay: false,
          overlayTransition: 'vfm-fade',
          contentTransition: 'vfm-fade',
          clickToClose: true,
          escToClose: true,
          background: 'non-interactive',
          lockScroll: true,
          swipeToClose: 'none',
          transition: 'slide-fade',
          fitParent: true,
          overlayClass: 'grid justify-items-center items-center taxonomy-response-modal',
          contentClass: 'grid justify-items-center items-center overflow-auto rounded-lg taxonomy-response-modal-content relative',
          onBeforeClose() {
            const r = confirm('Are you sure you want to navigate away from this page?');
            if (r === false) {
              return false;
            }
          }
        },
        slots: {
          default: useModalSlot({
            component: Responses,
            attrs: {
              collection: this.collection,
              nodes: this.graphNodes,
              data: this.taxonomyData,
              slots: this.slots,
              slotsNonTaxonomy: this.slotsNonTaxonomy,
              onClose() {
                modalInstance.close();
              }
            }
          })
        }
      });
      modalInstance.open();
    },
    responses_click(value) {
      this.graphNodes.clicked = value;

      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: {
          focusTrap: false,
          name: 'taxonomy-response-modal',
          adaptive: true,
          teleportTo: 'body',
          modelValue: false,
          displayDirective: 'if',
          hideOverlay: false,
          overlayTransition: 'vfm-fade',
          contentTransition: 'vfm-fade',
          clickToClose: true,
          escToClose: true,
          background: 'non-interactive',
          lockScroll: true,
          swipeToClose: 'none',
          transition: 'slide-fade',
          fitParent: true,
          overlayClass: 'grid justify-items-center items-center taxonomy-response-modal',
          contentClass: 'grid justify-items-center items-center overflow-auto rounded-lg taxonomy-response-modal-content relative',
          onBeforeClose() {
            const r = confirm('Are you sure you want to navigate away from this page?');
            if (r === false) {
              return false;
            }
          }
        },
        slots: {
          default: useModalSlot({
            component: Responses,
            attrs: {
              collection: this.collection,
              nodes: this.graphNodes,
              data: this.taxonomyData,
              slots: this.slots,
              slotsNonTaxonomy: this.slotsNonTaxonomy,
              onClose() {
                modalInstance.close();
              }
            }
          })
        }
      });
      modalInstance.open();
    },
    openTree() {
      this.opentree = true;
    },
    batchCreate(value) {
      this.activePrompt = true;
      this.graphNodes.clicked = value;
    },
    async updateName(collection, document, name) {
      if (!isValidIntentName(name)) {
        this.$vs.notify({
          title: `Could not change intent name to: ${name}`,
          text: "Cannot use spaces or special characters except '-' and '_' in intent names",
          color: 'danger',
          time: 5000
        });
        return;
      }

      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/updateSlotName`, {
        collection,
        document,
        name
      });

      if (response.status === 200) {
        this.notify('Slot change saved', 'primary');
        this.getSlots();
        this.$emit('reset');
      } else if (response.status === 404) {
        this.notify('Error saving change', 'danger');
      }
    },

    async getDisplayNames(collection, document) {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/getDisplayNames`, {
        collection,
        document
      });
      if (response.status === 200) {
        this.displayNames = response?.data?.displayNames ? JSON.parse(response.data.displayNames) : {};
      } else {
        console.error('Error fetching display names for intent with id:', document);
        this.displayNames = {};
      }
    },

    async findSlot(collection, whereParentIs, document, deletedBy, modalInstance) {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/findSlot`, {
        collection,
        whereParentIs
      });

      if (!response.data.isEmpty) {
        const delete_answer = confirm('Are you sure you want to delete this slot');
        if (delete_answer === true) {
          this.deleteSlot(collection, document, deletedBy);
          modalInstance.close();
        }
      } else {
        this.$vs.notify({
          title: 'Unable to delete',
          text: 'This slot has children you must delete first',
          color: 'danger'
        });
      }
    },
    async deleteSlot(collection, document, deletedBy) {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/deleteSlot`, {
        collection,
        document,
        deletedBy
      });

      if (response.status === 200) {
        this.notify('Slot deleted', 'dark');
        this.$emit('reset');
      } else if (response.status === 404) {
        this.$vs.notify({
          title: 'Unable to delete',
          text: 'This slot has children you must delete first',
          color: 'danger'
        });
      }
    },

    async editClick(value) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('clickNode', { node: value, sunburst: this });
      this.graphNodes.clicked = value;

      await this.loadSlotProperties(value);

      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: {
          focusTrap: false,
          name: 'custom-modal',
          adaptive: true,
          teleportTo: 'body',
          modelValue: false,
          displayDirective: 'if',
          hideOverlay: false,
          overlayTransition: 'vfm-fade',
          contentTransition: 'vfm-fade',
          clickToClose: true,
          escToClose: true,
          background: 'non-interactive',
          lockScroll: true,
          swipeToClose: 'none',
          transition: 'slide-fade',
          fitParent: true,
          overlayClass: 'flex justify-center items-center custom-modal',
          contentClass: 'grid place-items-center h-screen overflow-auto custom-modal-content relative'
        },
        slots: {
          default: useModalSlot({
            component: CustomDialog,
            attrs: {
              collection: this.collection,
              inputParams: {
                title: `You clicked on intent: ${value.data?.data?.name}`,
                description: value.data?.data?.description ? `${value.data?.data?.description}` : '',
                buttons: [
                  {
                    title: `<i class="fas fa-running"></i></i><br><span style="font-size:16px;">Training</span>`,
                    handler: () => {
                      this.$emit('closed');
                      this.utterance_click(value);
                      modalInstance.close();
                    }
                  },
                  {
                    title: '<i class="fas fa-font"></i><br><span style="font-size:16px;">Name</span>',
                    class: 'vue-dialog-button',
                    handler: () => {
                      const result = prompt('Change the name of the node', value.data?.data?.name);
                      if (result) {
                        value.data.data.name = result;

                        const collection = this.collection;
                        const document = value.data?.data?.id;
                        const name = result.trim();

                        this.updateName(collection, document, name);
                        modalInstance.close();
                      }
                    }
                  },
                  {
                    title: '<i class="fas fa-robot"></i><br><span style="font-size:14px;">Display Names</span>',
                    class: 'vue-dialog-button',
                    handler: async () => {
                      this.$emit('closed');
                      const collection = this.collection;
                      const document = value.data?.data?.id;

                      await this.getDisplayNames(collection, document);

                      this.displayNameOpen = true;
                      this.displayName.value = value;
                      modalInstance.close();
                    }
                  },
                  {
                    title: '<i class="fas fa-thermometer-three-quarters"></i><br>Status',
                    handler: () => {
                      this.currentStatus = value.data?.data?.status;
                      this.editStatus = true;
                      this.$emit('closed');
                      this.currentSlotData = value;
                      modalInstance.close();
                    }
                  },
                  {
                    title: '<i class="fas fa-child"></i><br>Add Child',
                    handler: () => {
                      this.$emit('closed');
                      this.batchCreate(value);
                      modalInstance.close();
                    }
                  },
                  {
                    title: '<i class="fas fa-trash"></i><br><span style="font-size:14px">Delete Slot</span>',
                    handler: () => {
                      if (value.data?.data?.parent === '' || value.children?.length > 0) {
                        this.$vs.notify({
                          title: 'Unable to delete',
                          text: 'This slot has children you must delete first',
                          color: 'danger'
                        });
                        return;
                      }

                      const collection = this.collection;
                      const whereParentIs = value.data?.data?.id;
                      const document = value.data?.data?.id;
                      const deletedBy = this.appStore.user.email;

                      this.findSlot(collection, whereParentIs, document, deletedBy, modalInstance);
                    }
                  },
                  {
                    title: '<i class="fas fa-cog"></i><br><span style="font-size:16px">Properties</span>',
                    handler: async () => {
                      this.slotPropertiesObject = {
                        vault: '',
                        id: '',
                        onLabel: '',
                        batch: '',
                        lastEdited: ''
                      };
                      this.$emit('closed');

                      try {
                        await this.loadSlotProperties(value);
                        this.slotProperties = true;
                      } catch (err) {
                        console.error('Error: ', err.message, err);
                        this.slotProperties = true;
                      }
                      modalInstance.close();
                    }
                  }
                ]
              },
              saveDescription() {
                this.saveDescription();
              },
              onClose() {
                modalInstance.close();
              }
            }
          })
        }
      });
      modalInstance.open();
    },
    async saveTestUtterance() {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/saveTestUtterance`, {
        collection: this.collection,
        document: this.graphNodes?.clicked?.data?.data?.id,
        testUtterance: this.TestUtteranceField,
        createdBy: this.appStore.user.email
      });

      if (response.status === 201) {
        this.notify('Saved Test Utterances', 'dark');
        this.TestUtterance = false;
      }
    },
    async saveSlotProperties() {
      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/intents/saveSlotProperties`, {
        collection: this.collection,
        document: this.graphNodes?.clicked?.data?.data?.id,
        batch: this.slotPropertiesObject?.batch || '',
        vault: this.slotPropertiesObject?.vault || '',
        id: this.slotPropertiesObject?.id || '',
        onLabel: this.slotPropertiesObject?.onLabel || false,
        createdBy: this.appStore.user.email,
        routeToFlow: this.routeToFlow
      });

      if (response.status === 201) {
        this.$message({
          message: 'Slot properties saved',
          showClose: true,
          duration: 1000,
          offset: 100
        });
        this.slotProperties = false;
        this.loadResetTimeout = setTimeout(() => {
          this.$emit('reset');
        }, 1500);
      } else if (response.status === 404) {
        this.$message({ message: 'Error occured saving', showClose: true });
      }
    },
    utterance_click(value) {
      this.graphNodes.clicked = value;
      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: this.trainingPhrasesModalAttributes,
        slots: {
          default: useModalSlot({
            component: TrainingPhrases,
            attrs: {
              collection: this.collection,
              nodes: this.graphNodes,
              data: this.taxonomyData,
              slots: this.slots,
              onClose() {
                modalInstance.close();
              }
            }
          })
        }
      });
      modalInstance.open();
    },
    /**
     * Highlight the arc path leading to a given node.
     * @param {Object} node the D3 node to highlight
     * @param {Number} opacity opacity of the none highlighted nodes, default to 0.3
     */
    highlightPath(node, opacity = 0.7) {
      const sequenceArray = node.ancestors();

      this.vis
        .selectAll('path')
        .filter((d) => sequenceArray.indexOf(d) === -1)
        .transition()
        .duration(this.inAnimationDuration)
        .style('opacity', opacity);

      this.vis
        .selectAll('path')
        .filter((d) => sequenceArray.indexOf(d) >= 0)
        .style('opacity', 1);

      this.graphNodes.highlighted = node;
    },
    /**
     * Zoom to a given node.
     * @param {Object} node the D3 node to zoom to.
     */
    zoomToNode(node) {
      this.vis
        .transition('zoom')
        .duration(750)
        .tween('scale', () => {
          const xd = interpolate(this.scaleX.domain(), [node.x0, node.x1]);
          const yd = interpolate(this.scaleY.domain(), [node.y0, 1]);
          const yr = interpolate(this.scaleY.range(), [node.y0 ? 20 : 0, this.radius]);

          return (t) => {
            this.scaleX.domain(xd(t));
            this.scaleY.domain(yd(t)).range(yr(t));
          };
        })

        .selectAll('path')
        .attrTween('d', (nd) => () => this.arcSunburst(nd));

      const arcText = this.vis.select(this.parentNode).select('text');
      // fade in the text element and recalculate positions
      arcText
        .transition('enter')
        .duration(750)
        .attr('opacity', 1)
        .text((d) => d.data?.data?.color)
        .attr('transform', function (d) {
          if (d.depth > 0 && arcSunburst.centroid(d) && getAngle(d)) {
            // eslint-disable-next-line no-undef
            return 'translate(' + arcSunburst.centroid(d) + ')' + 'rotate(' + getAngle(d) + ')';
          } else {
            return null;
          }
        });

      this.graphNodes.zoomed = node;
    },

    /**
     * Reset the highlighted path
     */
    resetHighlight() {
      this.vis.selectAll('path').transition().duration(this.outAnimationDuration).style('opacity', 1);
    },
    setModalWidthHeight(initialWidth, initialHeight) {
      const maxWidth = '90%';
      const maxHeight = '90%';
      let width = initialWidth;
      let height = initialHeight;

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    }
  },
  computed: {
    slotsFiltered() {
      return this.slots.filter((x) => x.name);
    },

    actions() {
      return {
        highlightPath: this.highlightPath.bind(this),
        zoomToNode: this.zoomToNode.bind(this),
        resetHighlight: this.resetHighlight.bind(this)
      };
    },
    ...mapState(useAppStore, ['currentFlows']),
    customModalStyles() {
      const initialWidth = '1050px';
      const initialHeight = 'auto';
      return {
        width: `${this.setModalWidthHeight(initialWidth, initialHeight).width}`,
        height: `${this.setModalWidthHeight(initialWidth, initialHeight).height}`,
        'max-width': `${this.setModalWidthHeight(initialWidth, initialHeight).maxWidth}`,
        'max-height': `${this.setModalWidthHeight(initialWidth, initialHeight).maxHeight}`,
        'pivot-y': 0.3
      };
    },
    modalOptions() {
      const initialWidth = '90%';
      const initialHeight = '100%';
      const initialTopPosition = '0';
      const initialLeftPosition = '5%';
      const modelOverlayStyles = {
        'z-index': 5003
      };
      const modalContentStyles = {
        width: `${this.setModalWidthHeight(initialWidth, initialHeight).width}`,
        height: `${this.setModalWidthHeight(initialWidth, initialHeight).height}`,
        'max-width': `${this.setModalWidthHeight(initialWidth, initialHeight).maxWidth}`,
        'max-height': `${this.setModalWidthHeight(initialWidth, initialHeight).maxHeight}`,
        'pivot-y': 0.3,
        'z-index': 5004,
        position: 'absolute',
        left: initialLeftPosition,
        top: initialTopPosition
      };
      return {
        adaptive: true,
        teleportTo: 'body',
        modelValue: false,
        displayDirective: 'if',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        clickToClose: true,
        escToClose: true,
        background: 'non-interactive',
        lockScroll: true,
        swipeToClose: 'none',
        transition: 'slide-fade',
        fitParent: true,
        overlayStyle: { ...modelOverlayStyles },
        contentStyle: { ...modalContentStyles }
      };
    },
    trainingPhrasesModalAttributes() {
      return {
        name: 'taxonomy-training-phrases-modal',
        adaptive: this.modalOptions.adaptive,
        teleportTo: this.modalOptions.teleportTo,
        modelValue: this.modalOptions.modelValue,
        displayDirective: this.modalOptions.displayDirective,
        hideOverlay: this.modalOptions.hideOverlay,
        overlayTransition: this.modalOptions.overlayTransition,
        contentTransition: this.modalOptions.contentTransition,
        clickToClose: this.modalOptions.clickToClose,
        escToClose: this.modalOptions.escToClose,
        background: this.modalOptions.background,
        lockScroll: this.modalOptions.lockScroll,
        swipeToClose: this.modalOptions.swipeToClose,
        transition: this.modalOptions.transition,
        fitParent: this.modalOptions.fitParent,
        overlayStyle: this.modalOptions.overlayStyle,
        contentStyle: this.modalOptions.contentStyle,
        overlayClass: 'grid justify-items-center items-center taxonomy-training-phrases-modal',
        contentClass: 'grid justify-items-center items-center overflow-auto taxonomy-training-phrases-modal-content relative',
        onBeforeClose() {
          const r = confirm('Are you sure you want to navigate away from this page?');
          if (r === false) {
            return false;
          }
        }
      };
    }
  },

  watch: {
    searchInput: {
      handler(current) {
        this.filterTaxonomy(current);
      }
    },
    data: {
      handler(current) {
        this.onData(current);
      },
      deep: true
    },
    fontSize: {
      handler() {
        this.fontSizeLonger = this.fontSize - 2;
      }
    },
    minAngleDisplayed() {
      this.reDraw();
    }
  },
  beforeUnmount() {
    this.slots = [];
    clearTimeout(this.loadSlotsTimeout);
    clearTimeout(this.loadResetTimeout);
  }
};
</script>

<style scoped>
.vue-dialog-button i {
  color: #fb5422 !important;
}

.taxonomyLegend {
  position: absolute !important;
  -webkit-transform: scale(1) !important;
  transform: scale(0.8) !important;
  bottom: 194px;
  left: 0px;
  background-color: transparent !important;
  background: transparent;
  z-index: 343242;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: none;
  opacity: 0.3;
}

.taxonomyLegend:hover {
  opacity: 1;
}

.agentIntentsDropDown {
  margin-top: 20px;
  z-index: 100;
  width: 200px;
  position: fixed;
  margin-left: 20px;
}

.saveButton {
  color: white;
  float: right;
  background: black !important;
  font-size: 16px;
}

.el-select-dropdown__item {
  min-width: 250px;
}

input.el-input__inner {
  background: none;
  color: black;
  border: black;
}
</style>

<style lang="less" scoped>
.el-radio-button__inner:checked {
  color: black;
}

.el-message {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1) !important;
  background: white !important;
}

.el-message__content {
  font-size: 1.2em !important;
}

.middleSlot {
  font-family: 'Cabin' !important;
  font-weight: 900;
  text-transform: uppercase;
}

.v--modal {
  // box-shadow: 0 1px 12px 1px #009bcf !important;
  padding: 2px !important;
  border-radius: 15px !important;
  width: 500px;
}

.builderModal {
  border-radius: 0px !important;
  padding: 0px;

  @media screen and (max-width: 1001px) {
    width: 100% !important;
  }
}
@media print {
  .builderModal {
    display: inline;
    height: unset !important;
  }
  @page {
    size: A4 portrait;
  }
  .vm--container {
    position: absolute !important;
    -webkit-print-color-adjust: exact !important;
    background-color: #edf0f4 !important;
  }
  .vm--overlay {
    background: #ffffff !important;
  }
}
.el-slider__bar {
  background-color: rgb(0, 155, 207);
}

.el-slider__button {
  border: 2px solid rgb(0, 155, 207);
}

.searching:focus {
  border: 1px solid #fb4222;
  box-shadow: 0 0 3pt 2pt #fb4222;
}

.vue-dialog-button.notavailable {
  font-size: 12px !important;
  background: transparent;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 40px;
  height: 50px;
  color: lightgray;
  font: inherit;
  outline: none;
  pointer-events: none;
}

.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
  .vs-input {
    width: 100%;
    margin-top: 10px;
  }
}

.vs-button {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  padding: 10px;
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: transparent;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.slotProps {
  font-size: 16px;
  padding-right: 10px;
}

h3 {
  margin-bottom: 0rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.graph {
  height: 88%;
  width: 95%;
  display: flex;
  flex-flow: column wrap;
  padding-bottom: 30px;
}

.viewport {
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
  position: fixed;
}

.viewport2 {
  width: 100%;
  flex: 1 1 auto;
  height: 100%;
}

.dialog-content-overlay {
  .vue-dialog-button {
    font-size: 14px !important;
    height: 50px;
    border-radius: 1px !important;
    &:hover {
      background-color: rgb(0, 155, 207);
      opacity: 0.5;
    }
  }
  .vue-dialog-buttons {
    padding: 5px;
    border-radius: 1px !important;
  }
  .dialog-content {
    color: black;
    font-size: 16px;
    .dialog-c-title {
      font-style: bold;
      padding-bottom: 15px;
      font-size: 20px;
    }
  }
}

.select-intent {
  width: 100%;
  min-width: 250px;
}

.select-intent .vs__selected {
  margin: 4px 7px 5px;
}

.select-intent .vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search {
  opacity: 0;
}

.select-intent .vs__open-indicator {
  cursor: grab;
}

.vs--single.vs--open .vs__selected {
  opacity: 0 !important;
}
.select-intent .vs__search::placeholder,
.select-intent .vs__dropdown-toggle,
.select-intent .vs__dropdown-menu {
  border-radius: 10px;
  padding: 0px;
  color: white;
  width: 270px;
  overflow: hidden scroll;
  font-family: 'Roboto', sans-serif !important;
  font-size: 15px;
}

.select-intent .vs__dropdown-toggle {
  border: 1px solid rgba(60, 60, 60, 0.26) !important;
  border-radius: 10px !important;
}

.select-intent .vs__dropdown-menu {
  margin-top: 6px;
}

.select-intent .vs__open-indicator {
  margin-bottom: 2px;
}

.select-intent .vs__dropdown-menu::-webkit-scrollbar {
  display: none;
}

.select-intent .vs__dropdown-toggle,
.select-intent .vs__search::placeholder {
  height: 34px;
}
.select-intent .vs__search,
.select-intent .vs__search:focus {
  line-height: 0 !important;
  margin: 0px !important;
}

.select-intent .vs__selected-options input::placeholder {
  margin: 0px !important;
}

.select-intent .vs__dropdown-option {
  height: 30px;
  font-family: 'Roboto', sans-serif !important;
  font-size: 15px;
}

.select-intent .vs__selected {
  margin: 1px 5px 5px 7px !important;
}

.select-intent .vs__dropdown-option--highlight {
  background: #f5f7fa;
}

.select-intent .vs__dropdown-option:hover {
  background: #f5f7fa;
}

.select-intent .vs__selected-options input {
  overflow: hidden scroll;
  color: black;
}

.select-intent .vs__search::placeholder {
  color: lightgrey;
}

.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mouse-over-label {
  margin-left: 5px;
  font-size: 12px;
  color: gray;
}

.dropdown-wrapper {
  width: 100%;
  min-width: 250px;
}

.flag-input-container-wrapper {
  padding-bottom: 15px;
  width: 400px;
}

.flag-input-container {
  display: flex;
  flex-direction: column;
}

.flag-input-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Optional spacing */
}

.country-flag {
  margin-right: 15px; /* Optional spacing */
}

.slot-property-container-wrapper {
  padding-bottom: 15px;
}

.slot-property-container {
  display: flex;
  flex-direction: column;
}

.slot-property-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Optional spacing */
}

.select-flow {
  min-width: 200px;
}

.prop-title {
  margin-right: 15px; /* Optional spacing */
}
</style>
