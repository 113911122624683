<template>
  <div>
    <div v-show="appStore.drawer === 'voiceJobs'" class="overlay"></div>
    <DrawerMain id="voiceDrawer" v-show="appStore.drawer === 'voiceJobs'">
      <template v-slot:header>
        <PageHeader title="Phonetic Generator" :subtitle="`Voice / ${activeTab}`" />
      </template>
      <template v-slot:filters> </template>
      <template v-slot:body>
        <div v-if="appStore.currentTaxonomy" class="pageLayout">
          <div v-if="readOnly">
            <ul class="jobTabs">
              <li @click="loadTab('Configuration')" :class="{ isActive: activeTab === 'Configuration' }">Configuration</li>
              <li
                @click="if (appStore.drawerMetadata.job['job_output']) loadTab('Results');"
                :class="{
                  isActive: activeTab === 'Results',
                  disabled: !appStore.drawerMetadata.job['job_output']
                }"
              >
                Results
              </li>
            </ul>
          </div>
          <IntentList v-if="!readOnly || activeTab === 'Configuration' || !appStore.drawerMetadata.job['job_output']" :readOnly="readOnly" :metadata="appStore.drawerMetadata" @selectionChanged="selectionChanged" :collection="appStore.currentTaxonomy" v-show="appStore.drawer === 'voiceJobs'">
            <template v-slot:extraFilters>
              <div class="filterEach">
                <span class="filterName">Top_n</span>
                <!-- eslint-disable -->
                <!-- prettier-ignore -->
                <VueMultiselect :close-on-select="true" :disabled="readOnly" :show-labels="false" tag-position="bottom" :searchable="false" v-model="top_n" :options="topNOptions" :multiple="false" :allow-empty="false" class="top_n">
                    <template #option="props">
                      <div style="display: flex; justify-content: space-between">
                        <span style="margin-top: 2px">{{ props.option }}</span>
                      </div>
                    </template>
                  </VueMultiselect>
              </div>
              <div class="filterEach">
                <span class="filterName">Strapi</span>
                <!-- prettier-ignore -->
                <VueMultiselect :disabled="readOnly" @input="cmsSelected" :searchable="true" :show-labels="false" v-model="cmsURL" :options="cmsUrlOptions" :multiple="false" class="strapi" placeholder="Select an environment">
                    <template #option="props">
                      <div class="flex">
                        <span style="margin-top: 2px">{{ props.option }}</span>
                      </div>
                    </template>
                  </VueMultiselect>
              </div>
              <div class="filterEach">
                <span class="filterName">Bot Name</span>
                <!-- prettier-ignore -->
                <VueMultiselect :disabled="readOnly" :searchable="true" :show-labels="false" v-model="bot" track-by="name" label="name" :options="botOptions" :multiple="false" :loading="botNameIsLoading" class="botName" placeholder="Select bot for upload">
                    <template #singleLabel="{ option }">
                      <div class="flex">
                        <span style="margin-top: 2px">{{ option.name }}</span>
                      </div>
                    </template>
                  </VueMultiselect>
              </div>
            </template>
          </IntentList>
          <div class="resultsTab" v-if="activeTab === 'Results'">
            <div v-if="!appStore.drawerMetadata.job['job_output'].phonetic_correction">Jobs results pending</div>
            <div v-else class="resultsList">
              <a v-if="appStore.drawerMetadata.job['job_output'].data_sample" :href="appStore.drawerMetadata.job['job_output'].data_sample" target="_blank"><i class="fas fa-external-link-alt"></i>Data Sample</a>
              <a v-if="appStore.drawerMetadata.job['job_output'].phonetic_correction" :href="appStore.drawerMetadata.job['job_output'].phonetic_correction" target="_blank"><i class="fas fa-external-link-alt"></i>Phonetic Correction JSON</a>
              <a v-if="appStore.drawerMetadata.job['job_output'].speech_contexts" :href="appStore.drawerMetadata.job['job_output'].speech_contexts" target="_blank"><i class="fas fa-external-link-alt"></i>Speech Contexts JSON</a>
            </div>
          </div>
          <div v-if="!readOnly" class="actionsOptions">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="scheduleJob" type="button" class="btn btn-primary">Run</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import IntentList from './Lists/IntentList.vue';
import VueMultiselect from 'vue-multiselect';
import axios from 'axios';

export default {
  name: 'VoiceJobsDrawer',
  components: {
    DrawerMain,
    IntentList,
    VueMultiselect
  },
  computed: {
    readOnly() {
      return this.appStore.drawerMetadata?.job?.id !== undefined;
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      activeTab: 'Configuration',
      intents: [],
      batches: [],
      statuses: [],
      allIntents: [],
      topNOptions: [100, 500, 1000],
      top_n: 1000,
      cmsUrlOptions: process.env.VUE_APP_FIREBASE_PROJECT_ID === process.env.VUE_APP_DEFAULT_TEST_PROJECT ? [process.env.VUE_APP_CMS_DEV_ENV, process.env.VUE_APP_CMS_QA_ENV] : [process.env.VUE_APP_CMS_QA_ENV],
      cmsURL: null,
      botOptions: [],
      bot: null,
      botNameIsLoading: false
    };
  },
  methods: {
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    loadTab(tab) {
      this.activeTab = tab;
    },
    selectionChanged(payload) {
      this.intents = payload.intents;
      this.batches = payload.batches;
      this.statuses = payload.statuses;
      this.allIntents = payload.allIntents;
      this.top_n = this.appStore.drawerMetadata?.job?.['payload'] ? this.appStore.drawerMetadata?.job?.['payload']?.top_n : this.top_n;
      this.cmsURL = this.appStore.drawerMetadata?.job?.['payload'] ? this.appStore.drawerMetadata?.job?.['payload'].cmsURL : this.cmsURL;
      this.bot =
        this.appStore.drawerMetadata?.job?.['payload'] && this.appStore.drawerMetadata?.job?.['payload']?.botId && this.appStore.drawerMetadata?.job?.['payload']?.botName
          ? {
              id: this.appStore.drawerMetadata?.job?.['payload']?.botId,
              name: this.appStore.drawerMetadata?.job?.['payload']?.botName
            }
          : this.bot;
    },
    async cmsSelected(url) {
      this.botOptions = [];
      this.bot = null;
      if (!url) return;
      this.botNameIsLoading = true;
      this.cmsURL = url;
      const cmsResponse = (
        await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/strapi/getBots`, {
          idToken: this.appStore.getClaims.token,
          url
        })
      ).data;
      this.botNameIsLoading = false;
      this.botOptions = cmsResponse;
    },
    async scheduleJob() {
      try {
        const statusesSelected = [...new Set(this.intents.map((s) => s.status))];
        const whitelist = this.intents.map((s) => s.name);

        if (!this.intents.length) return alert('No intents selected');
        if (!this.top_n || this.top_n < 100 || this.top_n > 1000) return alert('Top_n should be between 100 and 1000');
        if (statusesSelected.includes('NONE')) return alert("Cannot select intents with status 'NONE'");

        const jobName = prompt('Please enter new Job name', '');
        if (jobName === null) return;
        if (!jobName)
          return this.$message({
            showClose: true,
            message: 'Please provide a job name',
            type: 'error'
          });

        this.$vs.loading();

        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/jobs`, {
          uid: this.appStore.getUser.uid,
          email: this.appStore.getUser.email,
          user: this.appStore.getUser.displayName,
          taxonomyId: this.appStore.currentTaxonomyId,
          status: statusesSelected,
          top_n: this.top_n,
          cmpServerURL: process.env.VUE_APP_INTEGRATIONS_ENDPOINT,
          idToken: this.appStore.getClaims.token,
          taxonomy: this.appStore.currentTaxonomy,
          collection: this.appStore.currentTaxonomy,
          cmsURL: this.cmsURL || '',
          botId: this.bot?.id || '',
          botName: this.bot?.name || '',
          metadata: {
            batches: this.batches.map((x) => x.value),
            statuses: this.statuses.map((x) => x.value),
            allIntents: this.allIntents.filter((a) => whitelist.includes(a.name))
          },
          config: {
            whitelist
          },
          jobName,
          jobType: 'phonetic_pipeline'
        });

        if (r.status === 200) {
          this.$message({
            message: 'Phonetic Generator job scheduled.',
            duration: 3000,
            showClose: true
          });
        } else {
          this.$message({
            message: 'An error occurred trying to start the job. Please try again',
            duration: 3000,
            showClose: true
          });
        }
        this.appStore.closeDrawer();
        this.$vs.loading.close();
      } catch (error) {
        this.$message({
          message: 'An error occurred trying to start the job. Please try again',
          duration: 3000,
          showClose: true
        });
        this.$vs.loading.close();
      }
    }
  }
};
</script>

<style>
.jobTabs {
  border-bottom: 4px solid #fd5422;
  list-style: none;
}
.jobTabs li {
  display: inline-block;
  padding: 9px 27px 10px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  background-color: #f6f8fa;
  margin-left: 5px;
}
.jobTabs li.isActive {
  background-color: #fd5422;
  color: #fff;
}
.jobTabs li.disabled {
  color: #8e9ba8;
  cursor: default;
  background-color: transparent;
}
.resultsTab {
  height: 100%;
  padding-left: 20px;
}
.resultsList a {
  color: #383f45;
  text-decoration: none !important;
  margin-right: 10px;
}
.resultsList a:hover {
  text-decoration: underline !important;
}
.resultsList i {
  margin-right: 3px;
}
.multiselect.top_n {
  width: max-content;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}
.multiselect.strapi {
  width: 432px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}
.multiselect.botName {
  width: 240px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  height: max-content;
}
.filtersWrapper {
  padding: 5px 20px 5px 20px;
}
.taxonomyFilters {
  width: 100%;
  flex-wrap: wrap;
}
</style>
