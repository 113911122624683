<template>
  <path
    :marker-end="pathStyle.markerEnd"
    class="edge__connection-path"
    :id="`line-${source}-${status}`"
    v-if="curved"
    :d="getPathPoints"
    :style="pathStyle"
  ></path>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'EdgesCurvedContainers',
  props: {
    optionIndex: {},
    source: {},
    target: {},
    curved: {},
    dotted: {},
    status: {
      default: '',
    },
    positions: {},
    id: {},
    start: {},
    end: {},
    radius: {
      default: 5,
    },
    pathStyle: {},
  },
  computed: {
    arrowStyle() {
      return {
        // stroke: this.color,
        // strokeWidth: 4,
        fill: this.color,
        // strokeDasharray: 1
      };
    },
    getPathPoints() {
      const [sourceX, sourceY] = this.start;
      const [targetX, targetY] = this.end;
      if (!sourceX && !sourceY) {
        return;
      }

      const targetPosition = 'bottom';
      const sourcePosition = 'top';
      const [_centerX, _centerY /*, offsetX, offsetY*/] = this.getCenter(
        sourceX,
        sourceY,
        targetX,
        targetY
      );
      const centerX = _centerX;
      const centerY = _centerY;
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      const leftAndRight = [Position.Left, Position.Right];

      const cX = typeof centerX !== 'undefined' ? centerX : _centerX;
      const cY = typeof centerY !== 'undefined' ? centerY : _centerY;

      let path = `M${sourceX},${sourceY} C${sourceX},${cY} ${targetX},${cY} ${targetX},${targetY}`;

      if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
      ) {
        path = `M${sourceX},${sourceY} C${cX},${sourceY} ${cX},${targetY} ${targetX},${targetY}`;
      } else if (leftAndRight.includes(targetPosition)) {
        path = `M${sourceX},${sourceY} Q${sourceX},${targetY} ${targetX},${targetY}`;
      } else if (leftAndRight.includes(sourcePosition)) {
        path = `M${sourceX},${sourceY} Q${targetX},${sourceY} ${targetX},${targetY}`;
      }
      if (path.includes('NaN')) {
        return false;
      }
      return path;
    },
    arrowTransform() {
      // const [arrowX, arrowY] = this.calculateCenterPoint()
      const [sourceX /*, sourceY*/] = this.start;
      const [targetX /*, targetY*/] = this.end;
      const [arrowX, arrowY] = this.end;

      const leftAndRight = ['left', 'right'];
      const [targetPosition, sourcePosition] = this.positions;
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      let arrowPosition = 0;
      const angle = { down: 360, right: 280, left: 95 };

      if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if (
          (sourcePosition === Position.Right &&
            targetPosition === Position.Left) ||
          (sourcePosition === Position.Left &&
            targetPosition === Position.Right) ||
          (sourcePosition === Position.Left && targetPosition === Position.Left)
        )
          arrowPosition = angle['left'];
      }

      if (
        sourcePosition === 'bottom' &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if (
          (sourcePosition === Position.Right &&
            targetPosition === Position.Left) ||
          (sourcePosition === Position.Left &&
            targetPosition === Position.Right) ||
          (sourcePosition === Position.Left && targetPosition === Position.Left)
        )
          arrowPosition = angle['down'];
      }

      if (sourcePosition === 'bottom') {
        if (sourceX === targetX) arrowPosition = angle['down'];
      }

      // let [targetPosition, sourcePosition] = this.positions;
      return `translate(${arrowX}, ${arrowY}) rotate(${arrowPosition || 90})`;
    },

    points() {
      const isLeft = this.positions[0] === 'left';

      return [
        this.start,
        [
          isLeft ? this.leadLeft(this.start[0]) : this.leadRight(this.start[0]),
          this.start[1],
        ],
        [this.end[0], this.leadTop(this.end[1])],
        this.end,
      ];
    },
    line() {
      return d3
        .line()
        .x((d) => d[0])
        .y((d) => d[1])
        .curve(d3.curveBundle.beta(1))(this.points);
    },
  },
  methods: {
    leadLeft(point) {
      return point - 100;
    },
    leadRight(point) {
      return point + 100;
    },
    leadTop(point) {
      return point - 100;
    },
    getCenter(
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition
    ) {
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      const LeftOrRight = [Position.Left, Position.Right];

      const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition);
      const targetIsLeftOrRight = LeftOrRight.includes(targetPosition);
      // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
      // a mixed edge is when one the source is on the left and the target is on the top for example.
      const mixedEdge =
        (sourceIsLeftOrRight && !targetIsLeftOrRight) ||
        (targetIsLeftOrRight && !sourceIsLeftOrRight);
      if (mixedEdge) {
        const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0;
        const centerX =
          sourceX > targetX ? sourceX - xOffset : sourceX + xOffset;

        const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY);
        const centerY =
          sourceY < targetY ? sourceY + yOffset : sourceY - yOffset;

        return [centerX, centerY, xOffset, yOffset];
      }
      const xOffset = Math.abs(targetX - sourceX) / 2;
      const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;

      const yOffset = Math.abs(targetY - sourceY) / 2;
      const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;
      return [centerX, centerY, xOffset, yOffset];
    },
  },
};
</script>

<style lang="scss" scoped>
.edge__connection {
  position: relative;
}

.edge__connection:hover {
  cursor: pointer;

  .edge__connection-path:hover {
    stroke: #2698ed;
    stroke-width: 2;
  }
}
</style>

<style lang="scss" scoped>
path:hover ~ text {
  display: block;
}

path ~ text {
  display: none;
}

path {
  z-index: 1000;
  //   -webkit-animation: dash 10s infinite linear; /* Chrome, Safari, Opera */
  //   animation: dash 10s infinite linear;
  //   animation-direction: reverse
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-moz-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

// g {
//   cursor: not-allowed;
// }
</style>
