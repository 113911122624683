import { defineStore } from 'pinia';
import _ from 'lodash';
import firebase from 'firebase/compat/app';
import axios from 'axios';
import { socket } from '../helpers/socket';

export const useNotificationsStore = defineStore({
  id: 'notifications',

  state: () => ({
    notifications: []
  }),

  getters: {
    allNotifications: (state) => state.notifications,
    unreadNotifications: (state) => state.notifications.filter((notification) => !notification.isRead)
  },

  actions: {
    removeAllNotifications() {
      this.notifications = [];
    },

    setNotifications(notifications) {
      this.notifications = notifications;
    },

    setNotificationRead(notificationId) {
      const notification = this.notifications.find((n) => n.id === notificationId);
      notification.isRead = true;
    },

    setAllNotificationsRead() {
      this.notifications.forEach((notification) => (notification.isRead = true));
    },

    deleteNotification(notificationId) {
      const indexToBeRemoved = this.notifications.findIndex((n) => n.id === notificationId);
      if (indexToBeRemoved > -1) {
        this.notifications.splice(indexToBeRemoved, 1);
      }
      if (this.notifications.length === 0) {
        this.notifications = [];
      }
    },

    async setAllNotificationsReadAsync() {
      const stateCopy = _.cloneDeep(this.notifications);
      stateCopy.forEach((notification) => (notification.isRead = true));

      const currentUserEmail = firebase.auth().currentUser?.email;

      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/notifications/setAllNotificationsRead`, {
        currentUserEmail
      });

      if (response) this.setAllNotificationsRead();
    },

    async setNotificationReadAsync(notificationId) {
      const stateCopy = _.cloneDeep(this.notifications);
      const notification = stateCopy.find((n) => n.id === notificationId);
      notification.isRead = true;

      const currentUserEmail = firebase.auth().currentUser?.email;

      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/notifications/setNotificationRead`, {
        currentUserEmail,
        notificationId
      });

      if (response) {
        this.setNotificationRead(notificationId);
        this.setNotifications(stateCopy);
      }
    },

    async deleteNotificationAsync(notificationId) {
      const tempNotifications = _.cloneDeep(this.notifications);
      const indexToBeRemoved = tempNotifications.findIndex((n) => n.id === notificationId);
      tempNotifications.splice(indexToBeRemoved, 1);

      const currentUserEmail = firebase.auth().currentUser?.email;

      const response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/notifications/deleteNotification`, {
        currentUserEmail,
        notificationId
      });

      if (response) {
        this.deleteNotification(notificationId);
        this.setNotifications(tempNotifications);
      }
    },

    async getNotifications() {
      const currentUserEmail = firebase.auth().currentUser?.email;

      socket.emit('getNotifications', currentUserEmail);

      socket.on('allNotifications', (data) => {
        if (data.email && data.notifications && data.email === currentUserEmail) {
          this.setNotifications(data.notifications);
        }
      });
    }
  }
});
