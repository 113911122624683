<template>
  <div @mouseup="setComponentId" class="row">
    <div class="col-12">
      <form class="form">
        <div class="form-group">
          <input v-model="customLabel" type="text" class="label" />
          <input
            v-model="customField"
            type="text"
            class="form-control"
            placeholder="Enter First Name"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
export default {
  name: 'CustomTextField',
  methods: {
    requiredOnClickHandler() {
      this.required = !this.required;
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
customLabel: '',
      customField: '',
    };
  },
};
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  position: absolute;
}

.slider.round:before {
  border-radius: 50%;
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  margin-right: auto;
}

.reqLabel {
  position: absolute;
  left: 17px;
}
</style>
