import { isObject, isString, isNumber, isStringNumber } from './types';
import { debugWarn } from './error';
import { cloneDeep } from 'lodash';

const SCOPE = 'utils/utils';

export function addUnit(value, defaultUnit = 'px') {
  if (!value) return '';
  if (isNumber(value) || isStringNumber(value)) {
    return `${value}${defaultUnit}`;
  } else if (isString(value)) {
    return value;
  }
  debugWarn(SCOPE, 'binding value must be a string or number');
}

export function isEmpty(value) {
  return value === null && typeof value === 'undefined';
}

export function treeToArray(data, props = { children: 'children' }) {
  data = cloneDeep(data);
  const { children } = props;
  const newData = [];
  const queue = [];
  data.forEach((child) => queue.push(child));
  while (queue.length) {
    const item = queue.shift();
    if (item[children]) {
      item[children].forEach((child) => queue.push(child));
      delete item[children];
    }
    newData.push(item);
  }
  return newData;
}

export function arrayToTree(data, props = { id: 'id', parentId: 'pid', children: 'children' }) {
  data = cloneDeep(data);
  const { id, parentId, children } = props;
  const result = [];
  const map = new Map();
  data.forEach((item) => {
    map.set(item[id], item);
    const parent = map.get(item[parentId]);
    if (parent) {
      parent[children] = parent[children] || [];
      parent[children].push(item);
    } else {
      result.push(item);
    }
  });
  return result;
}

export function getNormalPath(path) {
  if (path.length === 0 || !path || path === 'undefined') {
    return path;
  }
  const newPath = path.replace('//', '/');
  const length = newPath.length;
  if (newPath[length - 1] === '/') {
    return newPath.slice(0, length - 1);
  }
  return newPath;
}

export function objectToQuery(params) {
  let query = '';
  for (const props of Object.keys(params)) {
    const value = params[props];
    const part = encodeURIComponent(props) + '=';
    if (!isEmpty(value)) {
      if (isObject(value)) {
        for (const key of Object.keys(value)) {
          if (!isEmpty(value[key])) {
            const param = props + '[' + key + ']';
            const subPart = encodeURIComponent(param) + '=';
            query += subPart + encodeURIComponent(value[key]) + '&';
          }
        }
      } else {
        query += part + encodeURIComponent(value) + '&';
      }
    }
  }
  return query.slice(0, -1);
}

export function timeFormat(dateTime, fmt = 'yyyy-mm-dd') {
  if (!dateTime) {
    dateTime = Number(new Date());
  }
  if (dateTime.toString().length === 10) {
    dateTime *= 1000;
  }
  const date = new Date(dateTime);
  let ret;
  const opt = {
    'y+': date.getFullYear().toString(),
    'm+': (date.getMonth() + 1).toString(),
    'd+': date.getDate().toString(),
    'h+': date.getHours().toString(),
    'M+': date.getMinutes().toString(),
    's+': date.getSeconds().toString()
  };
  for (const k in opt) {
    ret = new RegExp('(' + k + ')').exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}

export function firstToUpperCase(str = '') {
  return str.toLowerCase().replace(/( |^)[a-z]/g, ($1) => $1.toUpperCase());
}
