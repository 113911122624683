<template>
  <div class="flow-status-properties-dropdown">
    <v-select
      :options="statuses"
      v-model="currentStatus"
      @input="updateParentData"
      placeholder="Choose status"
      filterable
      autocomplete
    />
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'FlowStatusPropertiesDropdown',
  
  props: {
    selectedStatus: {
      type: String,
      default: 'No status passed',
    },
  },
  
  emits: ['updateParentData'],
  
  data() {
    return {
      appStore: useAppStore(),
      statuses: [
        'Approved',
        'In progress',
        'Missing content',
        'Ready for review',
        'Client reviewed',
        'Disabled',
        'None',
      ],
    };
  },

  computed: {
    currentStatus: {
      get() {
        return this.selectedStatus;
      },
      set(value) {
        this.updateParentData(value);
      }
    }
  },

  methods: {
    updateParentData(value) {
      this.$emit('updateParentData', { key: 'status', value: value });
    },
  }
};
</script>
