import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core';

export const Superscript = Mark.create({
  name: 'superscript',

  addOptions() {
    return {
      HTMLAttributes: {}
    };
  },

  parseHTML() {
    return [
      { tag: 'sup' },
      { tag: 'del' },
      { tag: 'superscript' },
      {
        style: 'vertical-align',
        getAttrs: (value) => value === 'super' && null
      },
      {
        style: 'font-size',
        getAttrs: (value) => value === 'smaller' && null
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['sup', mergeAttributes(HTMLAttributes), 0];
  },

  addCommands() {
    return {
      toggleSuperscript:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-6': ({ commands }) => {
        return commands.toggleMark(this.name);
      }
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: /~([^~]+)~$/,
        type: this.type
      })
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: /~([^~]+)~/g,
        type: this.type
      })
    ];
  }
});
