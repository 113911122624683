import { Node } from '@tiptap/core';
import Suggestion from '@tiptap/suggestion';
import { Fragment } from '@tiptap/pm/model';

export const Mention = Node.create({
  name: 'mention',

  addOptions: {
    matcher: {
      char: '@',
      allowSpaces: false,
      startOfLine: false
    },
    mentionClass: 'mention',
    suggestionClass: 'mention-suggestion'
  },

  inline: true,
  group: 'inline',
  atom: true,
  selectable: false,

  addAttributes() {
    return {
      id: {
        default: null
      },
      label: {
        default: null
      },
      role: {
        default: null
      },
      name: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: `span.${this.options.mentionClass}[data-mention-id]`,
        getAttrs: (dom) => ({
          id: dom.getAttribute('data-mention-id'),
          role: dom.getAttribute('role'),
          name: dom.getAttribute('name'),
          label: this.getLabel(dom)
        }),
        getContent: (dom) => {
          const label = this.getLabel(dom);
          return this.createFragment(this.editor.schema, label);
        }
      }
    ];
  },

  renderHTML({ node }) {
    return [
      'span',
      {
        ...node.attrs,
        class: this.options.mentionClass,
        'data-mention-id': node.attrs.id
      },
      `${this.options.matcher.char}${node.attrs.label}`
    ];
  },

  addCommands() {
    return {
      insertMention: (attrs) => {
        return ({ commands }) => {
          commands.insertContent({
            type: this.name,
            attrs
          });
          return true;
        };
      }
    };
  },

  addKeyboardShortcuts() {
    return {
      ...this.options.matcher,
      callback: () => {
        this.editor.commands.insertMention(this.options.attrs);
        return true;
      }
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        command: ({ attrs }) => this.editor.commands.insertMention(attrs),
        appendText: ' ',
        editor: this.editor,
        ...this.options
      })
    ];
  },

  getLabel(dom) {
    return dom.innerText.split(this.options.matcher.char).join('');
  },

  createFragment(schema, label) {
    return Fragment.fromJSON(schema, [{ type: 'text', text: `${this.options.matcher.char}${label}` }]);
  }
});
