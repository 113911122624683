<template>
  <div>
    <slot name="header"></slot>
    <div class="select-container">
      <v-select append-to-body class="intent-dropdown" :clearable="false" v-model="itemSelected" placeholder="Select Intent" label="name" :reduce="(slots) => slots" :options="slots" filterable autocomplete>
        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <i class="fa fa-caret-down"></i>
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'DecisionIntentDropdown',
  props: {
    data: {},
    index: {
      type: Number,
      default: 0
    },
    componentId: {
      type: String,
      default: ''
    },
    option: {
      type: Object
    }
  },
  mounted() {
    this.slots = this.appStore.currentSlots;
  },
  data() {
    return {
      appStore: useAppStore(),
      slots: []
    };
  },
  computed: {
    itemSelected: {
      get() {
        if (this.option?.intent?.name) {
          return this.option.intent;
        }
        return false;
      },
      set(value) {
        this.appStore.updateOption({
          optionIndex: this.index,
          decisionComponentId: this.componentId,
          intent: value
        });
      }
    }
  },
  methods: {
    formatName(name) {
      if (name.length > 40) {
        return name.substring(0, 40) + '...';
      }
      return name;
    }
  }
};
</script>

<style lang="scss">
.select-container .intent-dropdown .vs__selected {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
}
</style>

<style lang="scss" scoped>
.select-container {
  height: 40px;

  .intent-dropdown {
    width: 100%;
    height: 100%;
  }
}
</style>
