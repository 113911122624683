<template>
  <!-- TODO: Handle Components and connectors in a better way-->
  <div
    ref="forEvent"
    tabindex="0"
    @keydown.delete="handleComponentKeyDown"
    :id="type === 'start' ? 'start-id' : id"
    :key="75"
    @mouseup="handleMouseUp"
    @mousedown="handleMousedown"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave"
    class="container-node node-input parent-container"
    :style="containerStyle"
    :class="{
      selected: isSelected,
      start: this.type === 'start',
      connected: isConnectedToHighlightedEdge
    }"
  >
    <div class="start-node-wrapper" v-if="type === 'start'">
      <NodeConnector :hide="(!isHovering && !isLinking) || (isLinking && this.appStore.activeContainer !== this.id) || this.appStore.getDisplayMode === 'pdfPreview'" :isHovering="isHovering" :isSource="true" :isConnected="false" :componentId="`start-id`" :data-nodeType="`start`" @linking-start="rootLinking" @linking-stop="linkingStop" />
      <NodeConnector :hide="this.appStore.getDisplayMode === 'pdfPreview'" :isHovering="isHovering" :isSource="true" :isConnected="true" :componentId="`start-id`" :data-nodeType="`start`" />
      <div class="start-text" v-html="getTitle || 'Start Here'"></div>
    </div>

    <div v-else :id="`container-${id}`" :data-containerId="id" :class="`container-overlay ${type} ${showInvalidActions && containerHasWarning(id) ? 'warning' : ''}`">
      <CommentPinpointContainer v-if="getDisplayMode !== 'pdfPreview'" :comment="containerComment" />
      <CommentPinpointContainer v-if="pendingContainerComment && getDisplayMode !== 'pdfPreview'" :comment="pendingContainerComment" />
      <NodeConnector :hide="(!isHovering && !isLinking) || containsRoutingComponent" :isConnected="false" :isSource="isSource" :componentId="`${id}`" :data-nodeType="`container`" @linking-start="decideLinking($event)" @linking-stop="linkingStop" />
      <!-- TODO: Handle node with both source and target handles -->
      <NodeConnector :hide="containsRoutingComponent || getDisplayMode === 'pdfPreview'" :isConnected="true" :isSource="true" :componentId="`${id}`" :data-nodeType="`container`" />
      <NodeConnector :hide="getDisplayMode === 'pdfPreview'" :isConnected="true" :isSource="false" :componentId="`${id}`" :data-nodeType="`container`" />

      <div class="row containerHandleOverlay" :style="rowStyle" @click="unsetComponent">
        <div class="col-11 containerHandle">
          <draggable v-model="commentsContainerDropped" item-key="id" :data-containerId="id" group="comments" class="commentDropZone" ghostClass="ghost-hidden" handle=".handle" emptyInsertThreshold="10" filter="input" :preventOnFilter="false" @change="commentsContainerDroppedLog">
            <template #item="{ element }">
              <div class="containerHeader" :key="id" :data-containerId="id">
                <div :data-containerId="id" v-if="!isEditingContainer(id)" @dblclick="handleDoubleClick" class="message-container-title" v-html="getTitle || 'Container'" :parent="element" />
                <div v-show="isEditingContainer(id)" class="message-container-title-input">
                  <input type="text" @change="onContainerTitleChangeHandler($event, id)" ref="titleInput" v-model="titleValue" @focus="$event.target.select()" @blur="saveEditing" @keyup.esc="cancelEditing" @keyup.enter="saveEditing" />
                </div>
              </div>
            </template>
          </draggable>
        </div>
        <div class="containerMoreInfo">
          <div v-if="showInvalidActions && containerHasComponentsWithWarnings(id)" class="warning-triangle">
            <el-tooltip effect="dark" placement="top">
              <template #content>
                <span v-html="getAllValidationMessages(id)"></span>
              </template>
              <ConversationBuilderIcon name="warning_icon" :width="20" />
            </el-tooltip>
          </div>
          <div v-if="appStore.activeContainer === id" class="col-1 message-container-popup" :title="`Container options`">
            <CustomPopover
              placement="bottom-start"
              :offset="5"
              popper-class="containerOptions"
              :closeDelay="100"
              :popper-options="{
                boundariesElement: 'body',
                gpuAcceleration: false
              }"
              trigger="click"
            >
              <ContainerEdit
                :opened="true"
                :closeParent="closeContainer"
                :items="[
                  {
                    icon: '',
                    title: 'Duplicate',
                    click: () => {
                      this.duplicateContainer();
                    }
                  },
                  {
                    icon: '',
                    title: 'Delete',
                    click: () => {
                      this.removeContainer();
                    }
                  },
                  {
                    icon: '',
                    title: 'Colors',
                    click: () => {}
                  }
                ]"
              >
                <template v-slot:extra>
                  <ColorPalette :onSelect="editContainer" :currentColor="getColor" :row="3" />
                </template>
              </ContainerEdit>

              <template #reference>
                <ConversationBuilderIcon @mouseover="changeCursorForImage" slot="reference" :width="4" :styleOnIcon="{ marginTop: '8px' }" name="ellipsis" />
              </template>
            </CustomPopover>
          </div>
        </div>
      </div>
      <div :key="forceRecomputeCounter" @mouseover="handleLocalMouseOver" class="componentsList" :style="componentsListStyle" v-show="containerResponses">
        <draggable v-model="containerResponses" item-key="id" :group="{ name: 'components', pull: true, put: true }" :ghost-class="this.containerResponses.length === 0 ? 'ghost-hidden' : 'ghost'" :disabled="getCanvasDisabled" :move="checkMove" emptyInsertThreshold="10" filter=".add-component" tag="div" class="list-group" :preventOnFilter="false" @end="onDragEnd" @mouseover="changeCursorForContainer" @change="containerResponsesLog">
          <template #item="{ element, index }">
            <div :key="element.id" :class="`component-overlay ${element.type} ${element.index || index} ${element.type === 'carousel' && getDisplayMode === 'pdfPreview' ? carouselPdfPreviewStyling(element) : ''} ${isActiveOrHighlighted(element.id) ? 'active' : ''} ${showInvalidActions && element.warning === true ? 'warning' : ''}`" tabindex="0" @keydown="handleComponentKeyDown">
              <div tabindex="1" class="commentComponent" :id="element.id" :data-componentId="element.id" @mouseup="setComponentId(element.id)">
                <CommentPinpointComponent v-if="componentComment && getDisplayMode !== 'pdfPreview'" :comment="componentComment(element.id)" />
                <CommentPinpointComponent v-if="pendingCommentComponent && getDisplayMode !== 'pdfPreview'" :comment="pendingCommentComponent(element.id)" />
                <!--
                <DraggableComponentsList :id="id" :component="element" :collection="collection" :parent="element" :titleValue="titleValue" :readOnly="true" :isLinking="isLinking" :type="type" :isConnectedToHighlightedEdge="isConnectedToHighlightedEdge"></DraggableComponentsList>
                -->
                <draggable v-model="commentsComponentDropped" item-key="id" group="comments" :data-componentId="element.id" :class="`commentDropZone-component${getDisplayMode !== 'comments' ? ' disabled' : ''}`" ghost-class="ghost-hidden" handle=".handle" emptyInsertThreshold="10" filter="input" :preventOnFilter="false" @change="commentsComponentDroppedLog">
                  <template #item="{ comment }">
                    <div
                      class="row containerComponentOverlay"
                      :data-componentId="element.id"
                      :parent="comment"
                      :class="{
                        decision: element.type === 'decision',
                        message: element.type === 'message'
                      }"
                    >
                      <div class="col-12 containerComponent" :data-componentId="element.id" :data-componentType="element.type">
                        <CarouselComponent :componentId="element.id" :response="element" v-if="element.type === 'carousel'" :readOnly="true" :id="element.id" :collection="collection" :type="`carousel`" />
                        <MessageComponent :componentId="element.id" :response="element" v-if="element.type === 'message'" :readOnly="true" :id="element.id" :collection="collection" :type="`message`" />
                        <DecisionComponent :componentId="element.id" :response="element" :id="element.id" @linking-stop="linkingStop" @linking-start="linkingStart" :isLinking="isLinking" v-if="element.type === 'decision'" placeHolder="Add Text" :readOnly="true" :collection="collection" :type="`decision`" :connected="isConnectedToHighlightedEdge" />
                        <ClarificationComponent :componentId="element.id" :response="element" :id="element.id" @linking-stop="linkingStop" @linking-start="linkingStart" :isLinking="isLinking" v-if="element.type === 'clarification'" :readOnly="true" :collection="collection" :type="`clarification`" />
                        <FormComponent :componentId="element.id" :response="element" v-if="element.type === 'form'" :readOnly="true" :id="element.id" :collection="collection" :type="`form`" />
                        <FlowComponent :componentId="element.id" :response="element" v-if="element.type === 'flow'" placeHolder="Select Flow" :readOnly="true" :id="id" :collection="collection" :type="`flow`" />
                        <LinkUrl :componentId="element.id" :response="element" v-if="element.type === 'linkUrl'" :readOnly="true" :id="element.id" :type="`linkUrl`" />
                        <MessageComponentSet :componentId="element.id" :response="element" v-if="element.type === 'set'" :readOnly="true" :id="element.id" type="set" />
                        <LinkToContainerComponent :componentId="element.id" v-if="element.type === 'linkToContainer'" :id="element.id" :response="element" :readOnly="true" />
                      </div>
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="add-component" slot="header">
              <AddComponent v-if="containerResponses.length === 0" />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useCommentsStore } from '@/store/index.js';
import { nextTick } from 'vue';
import AddComponent from '../Placeholders/AddComponent';
// import DraggableComponentsList from './DraggableComponentsList.vue';
import MessageComponent from './MessageComponent.vue';
import DecisionComponent from './DecisionComponent.vue';
import FlowComponent from './FlowComponent.vue';
import ClarificationComponent from './ClarificationComponent.vue';
import LinkUrl from './LinkUrl.vue';
import FormComponent from './FormComponent.vue';
import CarouselComponent from './CarouselComponent.vue';
import MessageComponentSet from './MessageComponentSet.vue';
import LinkToContainerComponent from './LinkToContainerComponent.vue';
import NodeConnector from './NodeConnector.vue';
import CommentPinpointContainer from './CommentPinpointContainer.vue';
import CommentPinpointComponent from './CommentPinpointComponent.vue';
import ContainerEdit from '../Dropdowns/ContainerEdit';
import colorPalette from '../../../../Utilities/colorPalette';
import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapState } from 'pinia';
import draggable from 'vuedraggable';
import sortBy from 'lodash/sortBy';
import { routingComponents } from '@/components/Utilities/constants/constants.js';
import { containerCheckMove, droppedOnBackground } from '@/components/Utilities/dragging';
import KEYBOARD_KEYS_ENUM from '@/components/Utilities/constants/keyboardKeys';
import CustomPopover from '@/components/ConversationBuilder/Popover/CustomPopover.vue';
import debounce from 'lodash/debounce';

export default {
  name: 'ContainerComponent',
  data() {
    return {
      appStore: useAppStore(),
      openContainerOptions: true,
      containerColors: colorPalette,
      forceRecomputeCounter: 0,
      showPlaceholder: false,
      titleValue: '',
      previousTitle: '',
      originalTitle: '',
      // isEditing: false,
      isHovering: false,
      containerIdHover: '',
      note: false,
      commentsContainerDropped: [
        {
          id: 'default'
        }
      ],
      commentsComponentDropped: [
        {
          id: 'default'
        }
      ],
      localComponents: {}
    };
  },
  components: {
    AddComponent,
    CommentPinpointContainer,
    CommentPinpointComponent,
    NodeConnector,
    ContainerEdit,
    draggable,
    CustomPopover,
    MessageComponentSet,
    FormComponent,
    DecisionComponent,
    ClarificationComponent,
    LinkUrl,
    CarouselComponent,
    LinkToContainerComponent,
    MessageComponent,
    FlowComponent
  },
  props: {
    color: {},
    id: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      required: false
    },
    collection: {
      required: false,
      type: String
    },
    x: {
      type: Number,
      default: 0,
      validator(val) {
        return typeof val === 'number';
      }
    },
    y: {
      type: Number,
      default: 0,
      validator(val) {
        return typeof val === 'number';
      }
    },
    type: {
      type: String
    },
    isLinking: {
      type: Boolean,
      required: true
    },
    author: {},
    spaceDown: {
      type: Boolean,
      required: false
    },
    showInvalidActions: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  beforeUpdate() {
    if (this.spaceDown) {
      this.$refs.forEvent.style.cursor = 'grab';
    }
  },
  computed: {
    ...mapState(useAppStore, ['getContainer', 'getComponentListFromComponents', 'getScene', 'getContainerComponents', 'getComponentId', 'getContainerListFromContainers', 'getSelectedContainers', 'getCanvasDisabled', 'getTargetNode', 'getSourceNode', 'getDisplayMode']),
    ...mapState(useCommentsStore, ['getPendingComment', 'getComments']),
    checkEditingContainerId() {
      return this.appStore.isEditingContainerId;
    },
    isSource() {
      return this.appStore.activeContainer === this.id || (this.isHovering && this.appStore.activeContainer !== this.id && !this.isLinking);
    },
    isSelected() {
      return (this.appStore.activeContainer === this.id && !this.getComponentId) || this.getSelectedContainers.includes(this.id);
    },
    isConnectedToHighlightedEdge() {
      return this.id === this.getTargetNode || this.id === this.getSourceNode;
    },
    pendingContainerComment() {
      const checkContainer = this.getPendingComment?.containerId && this.id === this.getPendingComment.containerId;
      if (checkContainer) {
        const pinnedComments = this.getComments.filter((y) => y?.pinned && !y?.resolved);
        const existingCommentIndex = pinnedComments.findIndex((comment) => comment?.id === this.getPendingComment.id);
        const pinnedCommentIndex = existingCommentIndex !== -1 ? existingCommentIndex + 1 : pinnedComments.length + 1;

        return {
          ...this.getPendingComment,
          pinnedCommentIndex
        };
      } else {
        return false;
      }
    },
    containerComment() {
      return this.getComments.filter((y) => y?.pinned && !y?.resolved).filter((x) => x?.containerId === this.id && x.id !== this.getPendingComment.id) || false;
    },
    getColor() {
      return this.getContainer(this.id)?.color;
    },
    containsRoutingComponent() {
      if (this.getContainerComponents(this.id).find((component) => component.type === 'decision' || component.type === 'flow' || component.type === 'linkToContainer')) {
        return true;
      }
      return false;
    },
    getTitle() {
      return this.getContainer(this.id)?.title;
    },
    containerResponses: {
      get() {
        this.forceRecomputeCounter;
        const sortedResponse = sortBy(this.getContainerComponents(this.id), ['index']);
        return sortedResponse || [];
      },
      set(value) {
        const numberOfRoutingComponentsPresent = value?.filter((component) => routingComponents().includes(component.type.toUpperCase()));
        if (numberOfRoutingComponentsPresent?.length > 1) {
          return;
        }
        this.appStore.updateContainerResponses({
          containerComponents: value,
          id: this.id
        });
      }
    },
    reference() {
      const x = this.response?.split('<hr>');
      if (x?.length > 0) {
        return x[1];
      } else {
        return '';
      }
    },
    containerStyle() {
      this.forceRecomputeCounter;
      if (this.type !== 'start')
        return {
          top: this.y + 'px',
          left: this.x + 'px',
          // transform: ` translate(${this.x}px, ${this.y}px) scale(${this.options.scale})`,
          // transform: ` translate(${this.x}px, ${this.y}px) scale(1)`,
          height: 'max-content',
          width: this.getDisplayMode === 'pdfPreview' ? (this.getContainerComponents(this.id).find((component) => component.type === 'carousel') ? 'fit-content' : '100%') : '350px',
          border: this.getDisplayMode === 'pdfPreview' ? '1px solid #d7dde5' : '1px solid #ffffff',
          borderRadius: '8px',
          overflow: 'initial',
          minHeight: '45px',
          backgroundColor: this.getContainer(this.id)?.color || '#ffffff',
          position: 'absolute',
          pageBreakAfter: 'always'
        };
      else {
        return {
          top: this.y + 'px',
          left: this.x + 'px',
          // transform: ` translate(${this.x}px, ${this.y}px) scale(${this.options.scale})`,
          // transform: ` translate(${this.x}px, ${this.y}px) scale(1)`,
          width: '168px',
          height: 'max-content',
          borderRadius: '8px',
          overflow: 'initial',
          minHeight: '45px',
          backgroundColor: '#fd5422 !important',
          border: 'solid 1px #fff',
          position: 'absolute',
          cursor: 'default'
        };
      }
    },
    rowStyle() {
      this.forceRecomputeCounter;
      return {
        backgroundColor: this.getContainer(this.id)?.color || '#ffffff',
        'margin-left': 0,
        'margin-right': 0,
        'margin-top': '-6px'
      };
    },
    componentsListStyle() {
      this.forceRecomputeCounter;

      return {
        backgroundColor: this.getContainer(this.id)?.color || '#ffffff'
      };
    }
  },
  watch: {
    getTitle: {
      deep: true,
      handler: function (newValue) {
        this.originalTitle = newValue || '';
        this.previousTitle = this.originalTitle;
        this.titleValue = this.originalTitle;
      }
    },
    commentsComponentDropped: {
      deep: true,
      handler: function (newValue) {
        if (newValue.length > 1) {
          this.commentsComponentDropped = [
            {
              id: 'default'
            }
          ];
        }
      }
    }
  },

  mounted() {
    this.localComponents = cloneDeep(this.appStore.getComponentListFromComponents);
    this.originalTitle = this.getContainer(this.id)?.title || '';
    this.previousTitle = this.originalTitle;
    this.titleValue = this.originalTitle;
    this.setRootComponentCss();
  },
  updated() {
    this.setRootComponentCss();
  },
  methods: {
    ...mapActions(useAppStore, ['removeComponentAction', 'FORCE_STATE_RECOMPUTE']),
    isEditingContainer(id) {
      return this.checkEditingContainerId === id;
    },
    containerResponsesLog: function (evt) {
      console.log(`containerResponsesLog:: Logging drag container`, {
        event: evt,
        containerResponses: this.containerResponses
      });
    },
    commentsContainerDroppedLog: function (evt) {
      console.log(`commentsContainerDroppedLog:: Logging drag`, {
        event: evt,
        commentsContainerDropped: this.commentsContainerDropped
      });
    },
    commentsComponentDroppedLog: function (evt) {
      console.log(`commentsContainerDroppedLog:: Logging drag`, {
        event: evt,
        commentsComponentDropped: this.commentsComponentDropped
      });
    },
    setRootComponentCss() {
      if (document.querySelector('.start-text p h')) {
        document.querySelector('.start-text p h').style.color = '#FCD846';
        document.querySelector('.start-text p h').style.background = '#E63500';
        document.querySelector('.start-text p h').style.padding = '0 9px 0 9px';
        document.querySelector('.start-text p h').style['text-shadow'] = 'none';
      }
    },
    setComponentId(id) {
      if (!this.spaceDown) {
        this.appStore.SET_SIDEBAR_ACTIVE({ bool: true });
        this.appStore.setComponentId(id);
      }
    },
    onContainerTitleChangeHandler(e, id) {
      const nodeList = this.appStore.scene.nodes;
      const nodeObj = nodeList.find((currNode) => {
        if (id === currNode.id) {
          return true;
        }

        return false;
      });

      if (nodeObj?.linkedDecisionContainerInfo) {
        this.appStore.updateOption({
          text: e.target.value,
          decisionComponentId: nodeObj.linkedDecisionContainerInfo.decisionComponentId,
          optionIndex: nodeObj.linkedDecisionContainerInfo.optionContainerIndex
        });
      }
    },
    isActiveOrHighlighted(componentId) {
      return this.getComponentId === componentId || this.getSourceNode === componentId || this.getTargetNode === componentId;
    },
    carouselPdfPreviewStyling(comp) {
      if (comp.carousels && comp.carousels.length === 0) {
        return 'carousel-pdf-single';
      } else {
        return 'carousel-pdf-multiple';
      }
    },
    pendingCommentComponent(id) {
      try {
        const checkComponent = this.getPendingComment?.componentId && id === this.getPendingComment.componentId;
        if (checkComponent) {
          const pinnedComments = this.getComments.filter((y) => y?.pinned && !y?.resolved);
          const existingCommentIndex = pinnedComments.findIndex((comment) => comment?.id === this.getPendingComment.id);
          const pinnedCommentIndex = existingCommentIndex !== -1 ? existingCommentIndex + 1 : pinnedComments.length + 1;

          return {
            ...this.getPendingComment,
            pinnedCommentIndex
          };
        } else {
          return false;
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    async rootLinking(evt) {
      //Check if there is already an edge connected to root container
      this.$emit('preventSidebarOpening');
      if (!(await this.appStore.hasEdge({ id: this.id }))) {
        this.linkingStart(evt);
      }
    },
    cancelEditing() {
      this.titleValue = this.previousTitle;
      this.appStore.SET_IS_EDITING_CONTAINER_ID(undefined);
    },
    saveEditing() {
      const containerData = {
        field: 'title',
        id: this.id,
        value: ''
      };
      if (this.titleValue) {
        containerData.value = this.titleValue;
      } else {
        containerData.value = this.originalTitle;
        this.titleValue = this.originalTitle;
      }

      this.appStore.SET_IS_EDITING_CONTAINER_ID(undefined);
      this.previousTitle = containerData.value;
      this.appStore.editContainer(containerData);
    },
    handleDoubleClick() {
      if (this.getCanvasDisabled) {
        return;
      }
      // this.isEditingContainerTitle = true;
      this.appStore.SET_IS_EDITING_CONTAINER_ID(this.id);
      nextTick(() => {
        this.$refs.titleInput.focus();
      });
    },
    changeCursorForImage(e) {
      if (!this.spaceDown && e?.target?.style) {
        e.target.style.cursor = 'pointer';
      }
    },
    changeCursorForContainer(e) {
      const target = e.target;
      debounce(
        function () {
          if (target?.style) {
            target.style.cursor = 'move';
          }
        },
        100,
        { maxWait: 100 }
      );
    },
    componentComment(id) {
      return this.getComments.filter((y) => y?.pinned && !y?.resolved).filter((x) => x?.componentId === id && x.id !== this.getPendingComment.id);
    },
    handleComponentKeyDown(event) {
      const { code } = event;
      if ((code === KEYBOARD_KEYS_ENUM.BACKSPACE || code === KEYBOARD_KEYS_ENUM.DELETE) && !this.getCanvasDisabled && !this.checkEditingContainerId) {
        if (!this.getComponentId && this.getSelectedContainers.length) {
          this.getSelectedContainers.forEach((containerId) => {
            if (containerId !== 'start-id') {
              this.appStore.removeContainerAction({ id: containerId });
            }
          });
        } else if (!this.getComponentId && this.appStore.activeContainer) {
          if (this.id !== 'start-id') {
            this.appStore.removeContainerAction({ id: this.id });
          }
        } else if (this.getComponentId) {
          this.appStore.removeComponentAction({
            id: this.getComponentId
          });
        }
        // Deleting will set active container to null and selected containers to empty array;
        this.appStore.setActiveContainer(null);
        this.appStore.SET_SELECTED_CONTAINERS([]);
        this.appStore.removeEmptyUnconnectedContainers({});

        event.stopPropagation();
      }

      this.forceRecompute();
    },
    onTitleChange(evt) {
      this.appStore.editContainer({
        field: 'title',
        value: evt.target.innerText,
        id: this.id
      });
    },
    // Force recomputing of edges and other container elements - may need to optimize later
    forceRecompute() {
      nextTick(() => {
        this.forceRecomputeCounter++;
        this.appStore.FORCE_STATE_RECOMPUTE();
      });
    },
    async onDragEnd(evt) {
      try {
        const { item, originalEvent } = evt;
        const { type, id } = item['_underlying_vm_'];
        // Could add logic here to disable add if off canvas
        const isDroppedOnBackground = droppedOnBackground(originalEvent);
        if (isDroppedOnBackground) {
          await this.appStore.createNewContainer({
            event: originalEvent,
            type,
            component: item['_underlying_vm_']
          });
          this.containerResponses.splice(
            this.containerResponses.findIndex((x) => x.id === id),
            1
          );
        } else if (evt.to.classList.value === 'commentDropZone-component' || evt.to.classList.value === 'dropZone') {
          this.appStore.getComponentListFromComponents = cloneDeep(this.localComponents);
          return;
        }
        if (type.toLowerCase() === 'decision') {
          this.appStore.removeEmptyUnconnectedContainers({});
          this.appStore.calculateOptionLinks({
            decisionComponentId: id
          });
        }
        if (type === 'flow' || type === 'linkToContainer') {
          this.appStore.removeOutgoingLinks({ componentId: id });
        }
        this.appStore.removeEmptyUnconnectedContainers({});
        this.appStore.sortContainerNames({});
        this.forceRecompute();
      } catch (err) {
        this.forceRecompute();
      }
    },
    checkMove(targetEvt) {
      this.localComponents = cloneDeep(this.appStore.getComponentListFromComponents);
      return containerCheckMove(targetEvt);
    },
    removeContainer() {
      if (this.id !== 'start-id' && !this.checkEditingContainerId) {
        nextTick(() => {
          this.appStore.removeContainerAction({ id: this.id });
        });
      }
    },
    duplicateContainer() {
      if (this.id !== 'start-id' && !this.checkEditingContainerId) {
        nextTick(async () => {
          await this.appStore.duplicateContainerAction({
            id: this.id
          });
        });
        this.forceRecompute();
      }
    },
    closeContainer() {
      this.openContainerOptions = false;
    },
    editContainer(color) {
      this.appStore.editContainer({
        field: 'color',
        value: color,
        id: this.id
      });
      this.forceRecompute();

      this.openContainerOptions = false;
    },
    handleDragOver(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      evt.dataTransfer.dropEffect = 'copy';
      // evt.target.classList.add('drag-over'); // Explicitly show this is a copy.
    },
    linkingStart(payload) {
      payload.containerTitle = this.titleValue;
      this.$emit('node-selected', {
        id: this.id,
        event: payload.event,
        drag: true
      });
      this.$emit('linking-start', payload);
    },
    linkingStop(e) {
      this.$emit('linking-stop', e);
    },
    handleNoteDrop() {
      this.note = true;
    },
    handleMouseUp(e) {
      if (this.spaceDown) {
        this.$refs.forEvent.style.cursor = 'grab';
        return;
      }
      this.handleNoteDrop();

      if (e.which === 3) {
        return;
      }
      if (this.getCanvasDisabled) {
        return;
      }
      const target = e.target || e.srcElement;

      if (target.className.includes('handle')) {
        return;
      } else {
        this.$emit('node-selected', { id: this.id, event: e, drag: false });
      }

      /// Disallow connecting to start container/node;
      if (this.type != 'start') this.$emit('linking-stop', e);
    },
    handleLocalMouseOver(e) {
      if (e?.target?.style?.cursor) {
        e.target.style.cursor = 'move';
      }
    },
    outputMouseDown(e) {
      e.preventDefault();
      this.$emit('linking-start', -200);
    },
    handleMousedown(e) {
      if (this.spaceDown) {
        this.$refs.forEvent.style.cursor = 'grab';
        this.$emit('handle-down', e);
        return;
      }
      if (e.which === 3) {
        return;
      }
      if (this.getCanvasDisabled) {
        return;
      }
      const target = e.target || e.srcElement;

      if (target.className.includes('handle')) {
        return;
      } else {
        this.$emit('node-selected', { id: this.id, event: e, drag: true });
      }
    },
    handleMouseOver(e) {
      if (this.getCanvasDisabled) {
        return;
      }
      if (this.spaceDown && e?.target?.style) {
        e.target.style.cursor = 'grab';
      }

      this.isHovering = true;
    },
    handleMouseLeave() {
      this.$refs.forEvent.style.cursor = 'default';
      document.getElementsByClassName('message-container-title').forEach((currEle) => {
        if (currEle) {
          currEle.style.cursor = 'move';
        }
      });
      try {
        this.isHovering = false;
        this.containerIdHover = '';
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    unsetComponent() {
      // When we click outside the component area, lets set it to false so sidebar doesn't stay open.
      this.appStore.setComponentId(false);
    },
    decideLinking(evt) {
      //Prevents two or more links from a single source connector. A source connector should only connect to one other container.
      const isLinked = this.appStore.getScene.links.find((link) => link.from === this.id);
      if (isLinked === undefined && !this.getCanvasDisabled) {
        this.linkingStart(evt);
      }
    },
    containerHasWarning(id) {
      return this.appStore.getContainer(id)?.warning === true;
    },
    containerHasComponentsWithWarnings(containerId) {
      return this.containerHasWarning(containerId) || Object.values(this.appStore.getComponents).find((c) => c.warning === true && c.parentId === containerId);
    },
    getAllValidationMessages(id) {
      return (
        this.appStore.getContainer(id)?.warningMessage ||
        Object.values(this.appStore.getComponents)
          .map((c) => {
            if (c.warning === true && c.parentId === id) {
              return c.warningMessage;
            }
          })
          .filter((x) => x)
          .join('<br/>')
      );
    }
  }
};
</script>

<style>
.componentsList :not([class*='component-overlay decision 1']).component-overlay.decision .sideBarIcon,
.componentsList :nth-last-child(1).component-overlay.decision:not([class*='component-overlay decision 1']) .sideBarIcon {
  visibility: hidden;
}
</style>

<style lang="scss">
.commentDropZone-component {
  &.disabled {
    pointer-events: none;
  }
}
.ghost {
  opacity: 0.3;
  background: #2698ed !important;
}

.ghost-hidden {
  opacity: 0;
  display: none;
}

.decision {
  &.connected {
    border: solid 1px #2698ed;
  }
}

.warning-triangle:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.component-overlay.message.active {
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.5);
  border: solid 1px #2698ed;
}

.componentsList {
  min-height: 50px;
}

.start {
  z-index: 2 !important;
}

.list-group {
  z-index: 100;
}

div.start-text p,
ul,
li {
  margin: 0;
}

div.start-text li {
  text-align: left;
}

div.start-text s {
  text-decoration-color: rgb(255, 255, 255);
  font-style: normal;
}

div.start-text em s {
  font-style: italic;
}

@media print {
  .container {
    min-width: unset !important;
    max-width: 1500px !important;
  }
  .row {
    overflow: hidden;
  }
  .container-overlay {
    cursor: default !important;
  }
}
</style>

<style lang="scss" scoped>
@import './Container.scss';
</style>
