import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const Limit = Extension.create({
  name: 'limit',

  addOptions: {
    limit: 640
  },

  addProseMirrorPlugins() {
    const { limit } = this.options;

    return [
      new Plugin({
        appendTransaction: (transactions, oldState, newState) => {
          const tr = newState.tr;
          const contentSize = tr.steps.reduce((acc, step) => {
            return acc + ((step.slice && step.slice.content.size) || 0);
          }, 0);

          if (newState.doc.textContent.length + contentSize >= limit) {
            // Check if the slice content size is more than 0
            if (contentSize > 0) {
              // Discard the transaction or take other appropriate actions
              tr.setMeta('addToHistory', false); // This will prevent the transaction from being added to the history
              return tr;
            }
          }
        }
      })
    ];
  }
});
