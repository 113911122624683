import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import { useCollabStore } from '../modules/collabModule';
import { useVcmStore } from '@/modules/vcmModule';

const getters = {
  getSnackbar: (state) => {
    return state.snackbar;
  },
  getSnackbarText: (state) => {
    return state.snackbarText;
  },
  getFlowStatuses: (state) => {
    return state.flowsFilterStatusesData;
  },
  getFilteredFlowStatusesNumber: (state) => {
    const statusFiltersNumber = state.flowsFilterStatusesData.status.map((status) => status.value === true).filter((a) => a).length;
    const filterNumber = statusFiltersNumber + Number(state.flowsFilterStatusesData.published) + Number(state.flowsFilterStatusesData.watching);
    return filterNumber;
  },
  getStateRecomputeCounter: (state) => {
    return state.stateRecomputeCounter;
  },
  getTaxonomySlots: (state) => (taxonomy) => {
    return [{ name: 'None', id: 0 }].concat(state.taxonomySlots[taxonomy]);
  },
  getCurrentFlow: (state) => (flowId) => {
    return state.current.flows.find((x) => x.id === flowId);
  },
  getActiveScene: (state) => {
    return state.current.activeScene;
  },
  getDaysBack: (state) => {
    return state.daysBack;
  },
  getJobsFromState: (state) => {
    return state.jobs;
  },
  getJobsV3: (state) => {
    return state.jobsV3;
  },
  getSnapshots: (state) => {
    return state.snapshots;
  },
  currentSlot: (state) => {
    return state.current.slot;
  },
  currentSlots: (state) => {
    return state.current.slots;
  },
  currentFlows: (state) => {
    return state.current.flows;
  },
  currentTaxonomy: (state) => {
    return state.current.taxonomy;
  },
  currentIntent: (state) => {
    return state.current.slot.name;
  },
  currentIntentId: (state) => {
    return state.current.slot.id;
  },
  currentTaxonomyId: (state) => {
    return state.current?.taxonomyId || state.taxonomies.find((x) => x.name === state.current.taxonomy).id;
  },
  getUsersConnected: (state) => {
    return sortBy(state.usersConnected, 'name');
  },
  getMode: (state) => {
    return state.saveConfig.type;
  },
  getUploadOperations: (state) => {
    return state.uploadOperations;
  },
  getBrowser: (state) => {
    return state.browser;
  },
  getVoiceActive: (state) => {
    return state.voiceActive;
  },
  getCurrentColor: (state) => {
    return state.currentColor;
  },
  getResponse: (state) => {
    return state.responses;
  },
  getSlots: (state) => {
    return state.slots;
  },
  getScene: (state) => {
    return state.scene;
  },
  getComponentList: (state) => {
    return state.componentList;
  },
  getComponents: (state) => {
    return state.components;
  },
  getCurrentIndex: (state) => {
    return state.currentIndex;
  },
  getOlderScenes: (state) => {
    return state.undoScenes;
  },
  getExpirationTime: (state) => {
    return { string: state.claims?.expirationTime || 0, date: Date.parse(state.claims?.expirationTime) };
  },
  getLanguageTitle: (state) => {
    return state.languageTitle;
  },
  getAlternativeLanguage: (state) => {
    return state.alternativeLanguage;
  },
  getLanguageCodes: (state) => {
    return state.languageCodes;
  },
  getLanguage: (state) => {
    return state.language;
  },
  getClaims: (state) => {
    return state.claims;
  },
  isContentEditor: (state) => {
    const editorRoles = ['CONTENT EDITOR', 'CLIENT EDITOR', 'EDITOR', 'ADMINISTRATOR'];
    const nonEditorRoles = ['GUEST', 'CLIENT'];
    if (nonEditorRoles.includes(state.claims?.role)) return false;
    else if (state.claims?.admin === true || editorRoles.includes(state.claims?.role)) return true;
    else return false;
  },
  isGuest: (state) => {
    return state.claims?.role === 'GUEST';
  },
  isEditor: (state) => {
    const editorRoles = ['EDITOR', 'ADMINISTRATOR'];
    const nonEditorRoles = ['GUEST', 'CLIENT', 'CONTENT EDITOR'];
    if (nonEditorRoles.includes(state.claims?.role)) return false;
    else if (state.claims?.admin === true || editorRoles.includes(state.claims?.role)) return true;
    else return false;
  },
  isAdmin: (state) => {
    const adminRoles = ['ADMINISTRATOR', 'EDITOR'];
    if (state.claims?.admin === true && adminRoles.includes(state.claims?.role)) return true;
    else return false;
  },
  searchActiveEnabled: (state) => {
    return state.searchActive;
  },
  inputDisabledEnabled: (state) => {
    return state.inputDisabled;
  },
  getClientEditAccess: (state) => {
    if (state.claims?.role === 'CLIENT EDITOR') {
      return true;
    } else {
      return false;
    }
  },

  getContainerListFromContainers: (state) => {
    return state.containerList.map((id) => state.containers[id]);
  },
  getContainerList: (state) => {
    return state.containerList;
  },
  currentComponent: (state) => {
    return state.components[state.componentId];
  },
  getSourceNode: (state) => {
    return state.sourceNode;
  },
  getTargetNode: (state) => {
    return state.targetNode;
  },
  getContainer: (state) => (id) => {
    return state?.containers[id];
  },
  getContainers: (state) => () => {
    return state?.containers;
  },
  getContainerComponents: (state) => (id) => {
    return sortBy(
      state.componentList.map((id) => state.components[id]).filter((y) => y?.parentId === id),
      ['index']
    );
  },
  getParentContainerIdByComponentId: (state) => (id) => {
    return state.components[id].parentId;
  },
  getComponentListFromComponents: (state) => {
    return state.componentList.map((id) => state.components[id]);
  },
  getUserLess: (state) => {
    return pick(state.user, ['displayName', 'email', 'photoURL']);
  },
  getUser: (state) => {
    return state.user;
  },
  getAllUsers: (state) => {
    return state.allUsers;
  },
  getSidebarFlag: (state) => {
    return state.sidebarFlag;
  },
  currentTimestamp: (state) => {
    return state.scene.timestamp;
  },
  getNotificationsCount: (state) => {
    return state.notificationsCount;
  },
  getTippyObj: (state) => {
    return state.tippyObj;
  },
  getActiveContainer: (state) => {
    return state.activeContainer;
  },
  getComponentId: (state) => {
    return state.componentId;
  },
  getZoomLevel: (state) => {
    return state.zoomLevel;
  },
  getSortedTaxonomies: (state) => {
    return state.taxonomies.sort(function (a, b) {
      return a.name.localeCompare(b.name);
    });
  },
  getNotesSideBar: (state) => {
    return state.notesSideBar;
  },
  getDrawer: (state) => {
    return state.drawer;
  },
  getDrawerMetadata: (state) => {
    return state.drawerMetadata;
  },
  getCuiServer: (state) => {
    return state.cuiServer;
  },
  getReadyToImport: (state) => {
    return state.readyToImport;
  },
  getSearchActive: (state) => {
    return state.searchActive;
  },
  getInputDisabled: (state) => {
    return state.inputDisabled;
  },
  getDisplayMode: (state) => {
    return state.displayMode;
  },
  getSelectedEdge: (state) => {
    return state.selectedEdge;
  },
  getSelectedContainers: (state) => {
    return state.selectedContainers;
  },
  getCanvasDisabled: (state) => {
    const collabStore = useCollabStore();
    const vcmStore = useVcmStore();
    const isDisabled = state.canvasDisabled || collabStore.collabMode !== 'editor' || !vcmStore.connected;
    return isDisabled;
  },
  checkEditingContainerId: (state) => {
    return state.isEditingContainerId;
  },
  checkBottomNavHovering: (state) => {
    return state.bottomNavHovering;
  },
  getClipboard: (state) => {
    //In house clipboard, not the computers
    return state.clipboard;
  },
  isSideBarActive: (state) => {
    return state.editSidebarActive;
  },
  getOriginalNodePositions: (state) => {
    return state.originalNodePositions;
  },
  getStatusColor: (state) => {
    return state.statusColor;
  },
  getPrintProperties: (state) => {
    return state.current.flowPrintProperties;
  },
  getInvalidActionsCount: (state) => {
    return state.invalidActionsCount;
  }
};

export default getters;
