<template>
  <VueFinalModal
    v-model="modalOptions.modelValue"
    :teleport-to="modalOptions.teleportTo"
    :display-directive="modalOptions.displayDirective"
    :hide-overlay="modalOptions.hideOverlay"
    :overlay-transition="modalOptions.overlayTransition"
    :content-transition="modalOptions.contentTransition"
    :click-to-close="modalOptions.clickToClose"
    :esc-to-close="modalOptions.escToClose"
    :background="modalOptions.background"
    :lock-scroll="modalOptions.lockScroll"
    :swipe-to-close="modalOptions.swipeToClose"
    :transition="modalOptions.transition"
    :fit-parent="modalOptions.fitParent"
    :adaptive="modalOptions.adaptive"
    :style="wrapperStyles"
    :contentStyle="modalStyles"
    @before-close="
      {
        clear();
      }
    "
    @opened="
      {
        startTimer();
      }
    "
    class="grid justify-items-center items-center app-timeout-modal"
    content-class="grid justify-items-center items-center overflow-auto rounded-lg w-10/12 rounded-lg h-max app-timeout-modal-content relative"
  >
    <div class="timeoutWrapper">
      <div class="timeoutContent">
        <div class="icon">
          <img style="width: 80px" :src="logoSrc" />
        </div>
        <br />
        <span v-if="!timesUp" class="expire">Your session will expire in </span>
        <span v-else class="expired">Your session has expired</span>
        <br />
        <span v-if="!timesUp" class="time">
          <span class="base-timer__label">{{ formattedTimeLeft }}</span>
        </span>
        <br />
        <br />
        <div>
          <button v-if="!timesUp" @click="hideTimeoutPop()" class="continueButton">
            <span class="text">Continue</span>
          </button>
          <button v-else @click="appStore.reloadPage()" class="continueButton">
            <span class="text">Reload</span>
          </button>
        </div>
        <br />
        <!-- <div>
            <button
              v-if="!timesUp"
              @click="
                appStore.openOffboardingDrawer(true);
                this.hideTimeoutPop();
              "
              class="endButton"
            >
              <span class="text end">Close</span>
            </button>
            <button
              v-else
              @click="
                appStore.openOffboardingDrawer(true);
                this.hideTimeoutPop();
              "
              class="endButton"
            >
              <span class="text end">Close</span>
            </button>
          </div> -->
      </div>
    </div>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal';

const TIME_LIMIT = 300;

export default {
  name: 'AppTimeout',
  components: {
    VueFinalModal
  },
  props: {
    data: { type: Object },
    visible: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'btt'
    },
    size: {
      type: String,
      default: '65%'
    },
    text: {
      type: String
    }
  },
  data: () => ({
    timePassed: 0,
    timerInterval: null,
    timesUp: false,
    modalOptions: {
      adaptive: true,
      teleportTo: 'body',
      modelValue: false,
      displayDirective: 'if',
      hideOverlay: false,
      overlayTransition: 'vfm-fade',
      contentTransition: 'vfm-fade',
      clickToClose: false,
      escToClose: false,
      background: 'non-interactive',
      lockScroll: true,
      swipeToClose: 'none',
      transition: 'slide-fade',
      fitParent: true
    }
  }),
  mounted() {
    // console.log('props', this.data.logo);
    // this.startTimer();
  },
  computed: {
    logoSrc() {
      return process.env.VUE_APP_CONVERSATIONHEALTH_LOGO;
    },
    formattedTimeLeft() {
      // console.log(this.timePassed);
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes + ' min '}${seconds + ' sec'}`;
    },
    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    },
    wrapperStyles() {
      return {
        'z-index': 5000
      };
    },
    modalStyles() {
      return {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxImageWidth}`,
        'max-height': `${this.setModalWidthHeight().maxImageHeight}`,
        'box-shadow': 'none'
      };
    }
  },
  watch: {
    timeLeft(newValue) {
      if (newValue === 0) {
        this.timePassed = 0;
        this.onTimesUp();
      }
    }
  },
  methods: {
    clear() {
      clearInterval(this.timerInterval);
    },
    onTimesUp() {
      // console.log('hello')
      clearInterval(this.timerInterval);
      this.timesUp = true;
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    hideTimeoutPop() {
      this.$emit('closed');
    },
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '800px';
      let width = `300px`;
      let height = '368px';

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    }
  }
};
</script>

<style scoped>
span {
  font-family: 'Open Sans', sans-serif;
}

.timeoutWrapper {
  display: flex;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 368px;
  justify-content: center;
  align-items: center;
}

.timeoutContent {
  display: grid;
  justify-items: center;
}

.time {
  font-family: 'Open Sans', sans-serif;
  font-size: 150%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #1996f0;
  font-size: 33px;
}

.expiredTime {
  display: none;
}

.expire {
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #2f2f2f;
}

.expired {
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.3;
  letter-spacing: normal;
  color: #1996f0;
}

.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.continueButton,
.endButton {
  cursor: pointer;
}

.continueButton {
  border-radius: 22px;
  border: solid 2px #1996f0;
  background-color: #1996f0;
  color: white;
  width: 190px;
  height: 44px;
  margin-bottom: -10px;
}

.endButton {
  border-radius: 22px;
  border: solid 2px #1996f0;
  background-color: #ffffff;
  width: 190px;
  height: 44px;
  margin-top: -4px;
  color: #1996f0;
}

.continueButton:hover {
  background-color: white;
  color: #1996f0;
}

.endButton:hover {
  color: white;
  background-color: #1996f0;
}

.endButton:hover {
  color: white;
  background-color: #1996f0;
}
</style>
