<template>
  <div class="container-fluid-entities">
    <div class="entity table">
      <div v-if="showEntityTable" class="entity-table-container">
        <EntityTable :collection="collectionName" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import firebase from 'firebase/compat/app';
import EntityTable from './EntityTable.vue';
import { mapState } from 'pinia';

export default {
  name: 'AgentEntities',
  components: {
    EntityTable
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      entities: [],
      counter: 0,
      search: '',
      wheels: [],
      collection: this.$route.params.id
    };
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies']),
    showEntityTable() {
      return this.appStore.taxonomies && this.appStore.taxonomies.length && this.$route.params.id;
    },
    collectionName() {
      const collectionItem = this.appStore.taxonomies.find((i) => i.id === this.$route.params.id);
      return collectionItem ? collectionItem.name : '';
    }
  },
  mounted() {
    this.currentUser();
  },
  methods: {
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    removeEntity() {
      this.entities.pop();
    },
    addEntity() {
      this.entities.push([
        {
          name: 'Placeholder',
          value: 'Placeholder',
          synonyms: ['Placeholder']
        }
      ]);
    },
    addEntityName(index) {
      this.entities[index].push({
        name: 'Placeholder',
        value: 'Placeholder',
        synonyms: ['Placeholder']
      });
    }
  }
};
</script>

<style scoped>
.con-select-example {
  width: 50%;
}

.container-fluid-entities {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
  background: white;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* overflow-x: scroll; */
}

.el-table td,
.el-table th {
  padding: 0px;
}

.el-table {
  font-size: 12px;
}

.el-tag {
  color: black;
  /* font-weight: bold; */
}

hr {
  margin-top: 0.1rem;
  margin-bottom: 0.4rem;
  border: 0;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
}

.entity.table {
  background-color: transparent;
  width: 83%;
  height: 96%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  /* padding: 10px; */
  border-radius: 15px;
  /* padding: 30px; */
}

.entity-table-container {
  width: 99%;
  background-color: white;
  border-radius: 15px;
  padding: 15px;
}

.vs-table--not-data {
  display: none;
}

.vs-table-text {
  color: dodgerblue;
}

.vs-table-text {
  font-size: 12px;
}

.vs-table--content {
  width: 100%;
  overflow: hidden scroll;
  height: 91%;
}

.vs-con-table {
  background: white;
}

.vs-component.vs-con-table.vs-table-primary {
  width: 99vh;
  padding: 20px;
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  /* background: mediumseagreen; */
}
</style>
