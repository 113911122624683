import { io } from 'socket.io-client';
import { useAppStore } from '@/store/index.js';

const getConnectionOptions = () => ({
  multiplex: true, // default,
  closeOnBeforeunload: false, // DO NOT use true Cf. https://github.com/socketio/socket.io/issues/3639
  forceNew: true,
  reconnectionAttempts: 'Infinity',
  reconnectionDelay: 500, // default 1000
  reconnectionDelayMax: 8000, // default 5000
  timeout: 10000, // default 20000
  transports: ['websocket'],
  pingTimeout: 21 * 60 * 1000, // 21 minutes
  pingInterval: 45 * 1000 // 45 seconds
});

let socket = null;

function initSocket() {
  const appStore = useAppStore();
  socket = io(appStore.cuiServer, getConnectionOptions());
}

function initCMPSocket(user) {
  const endPoint = process.env.VUE_APP_INTEGRATIONS_ENDPOINT;
  const connectionOptions = getConnectionOptions();
  if (user) {
    console.log('[RPC] Initializing CMP Socket', { endPoint, user }, new Date());
    connectionOptions.auth = {
      token: user.token
    };
  }
  socket = io(`${endPoint}`, connectionOptions);
}

export { socket, initSocket, initCMPSocket };
