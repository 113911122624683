<template>
  <path
    :marker-end="pathStyle.markerEnd"
    class="edge__connection-path"
    :id="`line-${source}-${status}`"
    :d="getSmoothStepPath"
    :style="pathStyle"
  ></path>
</template>

<script>
export default {
  name: 'EdgesStraightContainers',
  props: {
    optionIndex: {},
    source: {},
    target: {},
    curved: {},
    dotted: {},
    status: {
      default: '',
    },
    positions: {},
    id: {},
    start: {},
    end: {},
    radius: {
      default: 5,
    },
    pathStyle: {},
  },
  computed: {
    arrowStyle() {
      return {
        // stroke: this.color,
        // strokeWidth: 4,
        fill: this.color,
        // strokeDasharray: 1
      };
    },
    getSmoothStepPath() {
      const [sourceX, sourceY] = this.start;
      const [targetX, targetY] = this.end;
      if (!sourceX && !sourceY) {
        return;
      }
      const [targetPosition, sourcePosition] = this.positions;
      const borderRadius = 5;
      const [_centerX, _centerY, offsetX, offsetY] = this.getCenter(
        sourceX,
        sourceY,
        targetX,
        targetY
      );
      const centerX = _centerX;
      const centerY = _centerY;
      const bottomLeftCorner = (x, y, size) =>
        `L ${x},${y - size}Q ${x},${y} ${x + size},${y}`;
      const leftBottomCorner = (x, y, size) =>
        `L ${x + size},${y}Q ${x},${y} ${x},${y - size}`;
      const bottomRightCorner = (x, y, size) =>
        `L ${x},${y - size}Q ${x},${y} ${x - size},${y}`;
      const rightBottomCorner = (x, y, size) =>
        `L ${x - size},${y}Q ${x},${y} ${x},${y - size}`;
      const leftTopCorner = (x, y, size) =>
        `L ${x + size},${y}Q ${x},${y} ${x},${y + size}`;
      const topLeftCorner = (x, y, size) =>
        `L ${x},${y + size}Q ${x},${y} ${x + size},${y}`;
      const topRightCorner = (x, y, size) =>
        `L ${x},${y + size}Q ${x},${y} ${x - size},${y}`;
      const rightTopCorner = (x, y, size) =>
        `L ${x - size},${y}Q ${x},${y} ${x},${y + size}`;
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      const cornerWidth = Math.min(borderRadius, Math.abs(targetX - sourceX));
      const cornerHeight = Math.min(borderRadius, Math.abs(targetY - sourceY));
      const cornerSize = Math.min(cornerWidth, cornerHeight, offsetX, offsetY);
      const leftAndRight = [Position.Left, Position.Right];
      const cX = typeof centerX !== 'undefined' ? centerX : _centerX;
      const cY = typeof centerY !== 'undefined' ? centerY : _centerY;
      let firstCornerPath = null;
      let secondCornerPath = null;

      if (sourceX <= targetX) {
        firstCornerPath =
          sourceY <= targetY
            ? bottomLeftCorner(sourceX, cY, cornerSize)
            : topLeftCorner(sourceX, cY, cornerSize);
        secondCornerPath =
          sourceY <= targetY
            ? rightTopCorner(targetX, cY, cornerSize)
            : rightBottomCorner(targetX, cY, cornerSize);
      } else {
        firstCornerPath =
          sourceY < targetY
            ? bottomRightCorner(sourceX, cY, cornerSize)
            : topRightCorner(sourceX, cY, cornerSize);
        secondCornerPath =
          sourceY < targetY
            ? leftTopCorner(targetX, cY, cornerSize)
            : leftBottomCorner(targetX, cY, cornerSize);
      }
      if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) {
          firstCornerPath =
            sourceY <= targetY
              ? rightTopCorner(cX, sourceY, cornerSize)
              : rightBottomCorner(cX, sourceY, cornerSize);
          secondCornerPath =
            sourceY <= targetY
              ? bottomLeftCorner(cX, targetY, cornerSize)
              : topLeftCorner(cX, targetY, cornerSize);
        } else if (
          (sourcePosition === Position.Right &&
            targetPosition === Position.Left) ||
          (sourcePosition === Position.Left &&
            targetPosition === Position.Right) ||
          (sourcePosition === Position.Left && targetPosition === Position.Left)
        ) {
          // and sourceX > targetX
          firstCornerPath =
            sourceY <= targetY
              ? leftTopCorner(cX, sourceY, cornerSize)
              : leftBottomCorner(cX, sourceY, cornerSize);
          secondCornerPath =
            sourceY <= targetY
              ? bottomRightCorner(cX, targetY, cornerSize)
              : topRightCorner(cX, targetY, cornerSize);
        }
      } else if (
        leftAndRight.includes(sourcePosition) &&
        !leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) {
          firstCornerPath =
            sourceY <= targetY
              ? rightTopCorner(targetX, sourceY, cornerSize)
              : rightBottomCorner(targetX, sourceY, cornerSize);
        } else {
          firstCornerPath =
            sourceY <= targetY
              ? leftTopCorner(targetX, sourceY, cornerSize)
              : leftBottomCorner(targetX, sourceY, cornerSize);
        }
        secondCornerPath = '';
      } else if (
        !leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) {
          firstCornerPath =
            sourceY <= targetY
              ? bottomLeftCorner(sourceX, targetY, cornerSize)
              : topLeftCorner(sourceX, targetY, cornerSize);
        } else {
          firstCornerPath =
            sourceY <= targetY
              ? bottomRightCorner(sourceX, targetY, cornerSize)
              : topRightCorner(sourceX, targetY, cornerSize);
        }
        secondCornerPath = '';
      }
      return `M ${sourceX},${sourceY}${firstCornerPath}${secondCornerPath}L ${targetX},${targetY}`;
    },
    arrowTransform() {
      const [sourceX, sourceY] = this.start;
      const [targetX, targetY] = this.end;
      const [arrowX, arrowY] = this.end;
      sourceY, targetY;
      const leftAndRight = ['left', 'right'];
      const [targetPosition, sourcePosition] = this.positions;
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      let arrowPosition = 0;
      const angle = { down: 360, right: 280, left: 95 };

      if (
        leftAndRight.includes(sourcePosition) &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if (
          (sourcePosition === Position.Right &&
            targetPosition === Position.Left) ||
          (sourcePosition === Position.Left &&
            targetPosition === Position.Right) ||
          (sourcePosition === Position.Left && targetPosition === Position.Left)
        )
          arrowPosition = angle['left'];
      }

      if (
        sourcePosition === 'bottom' &&
        leftAndRight.includes(targetPosition)
      ) {
        if (sourceX <= targetX) arrowPosition = angle['right'];
        else if (
          (sourcePosition === Position.Right &&
            targetPosition === Position.Left) ||
          (sourcePosition === Position.Left &&
            targetPosition === Position.Right) ||
          (sourcePosition === Position.Left && targetPosition === Position.Left)
        )
          arrowPosition = angle['down'];
      }

      if (sourcePosition === 'bottom') {
        if (sourceX === targetX) arrowPosition = angle['down'];
      }
      return `translate(${arrowX}, ${arrowY}) rotate(${arrowPosition || 90})`;
    },
  },
  methods: {
    getCenter(
      sourceX,
      sourceY,
      targetX,
      targetY,
      sourcePosition,
      targetPosition
    ) {
      const Position = {
        Left: 'left',
        Top: 'top',
        Right: 'right',
        Bottom: 'bottom',
      };
      const LeftOrRight = [Position.Left, Position.Right];

      const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition);
      const targetIsLeftOrRight = LeftOrRight.includes(targetPosition);
      // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
      // a mixed edge is when one the source is on the left and the target is on the top for example.
      const mixedEdge =
        (sourceIsLeftOrRight && !targetIsLeftOrRight) ||
        (targetIsLeftOrRight && !sourceIsLeftOrRight);
      if (mixedEdge) {
        const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0;
        const centerX =
          sourceX > targetX ? sourceX - xOffset : sourceX + xOffset;

        const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY);
        const centerY =
          sourceY < targetY ? sourceY + yOffset : sourceY - yOffset;

        return [centerX, centerY, xOffset, yOffset];
      }
      const xOffset = Math.abs(targetX - sourceX) / 2;
      const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;

      const yOffset = Math.abs(targetY - sourceY) / 2;
      const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;
      return [centerX, centerY, xOffset, yOffset];
    },
  },
};
</script>

<style lang="scss" scoped>
.edge__connection {
  position: relative;
}
.edge__connection:hover {
  cursor: pointer;

  .edge__connection-path:hover {
    stroke: #2698ed;
    stroke-width: 2;
  }
}
</style>

<style lang="scss" scoped>
path:hover ~ text {
  display: block;
}
path ~ text {
  display: none;
}

path {
  z-index: 1000;
  //   -webkit-animation: dash 10s infinite linear; /* Chrome, Safari, Opera */
  //   animation: dash 10s infinite linear;
  //   animation-direction: reverse
}

@keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-moz-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 100;
  }
}

// g {
//   cursor: not-allowed;
// }
</style>
