import { useAppStore } from '@/store/index.js';
import debounce from 'lodash/debounce';
import { responseTypes } from '@/components/Utilities/constants/constants';

export default {
  props: {
    id: String,
    response: Object
  },
  data() {
    return {
      appStore: useAppStore()
    };
  },
  methods: {
    setComponentIdDebounced: debounce(
      function () {
        this.appStore.setComponentId({ componentId: this.id });
      },
      1000,
      { maxWait: 1000 }
    ),

    setComponentId() {
      this.setComponentIdDebounced();
    },

    getComponentById(id) {
      return this.components.find((component) => component.id === id);
    }
  },

  computed: {
    components() {
      return this.appStore.getComponentListFromComponents;
    },

    componentList() {
      return this.appStore.componentList;
    },

    language() {
      return this.appStore.language;
    },

    getContainer() {
      return this.appStore.getContainer;
    },

    isRootNode() {
      if (this.getContainer(this.id)?.type === responseTypes.START.type) {
        this.appStore.SET_SIDEBAR_ACTIVE({ bool: true });
      }
      return this.getContainer(this.id)?.type === responseTypes.START.type;
    },

    componentSelected() {
      return this.getComponentById(this.id) || (this.isRootNode && this.getContainer(this.id)) || {};
    },

    type() {
      return this.componentSelected?.type || '';
    },

    parentId() {
      return this.getComponentById(this.id)?.parentId;
    },

    decisionOptions() {
      const selectedComponent = this.components.filter((component) => component.id === this.componentList.find((id) => id === component.id)).find((component) => component.parentId === this.parentId && component.type === 'decision');

      return selectedComponent?.options;
    },

    clarificationOptions() {
      const selectedComponent = this.components.filter((component) => component.id === this.componentList.find((id) => id === component.id)).find((component) => component.parentId === this.parentId && component.type === 'clarification');

      return selectedComponent?.options;
    },

    getText() {
      if (this.response?.text) {
        return this.response?.text?.trim() || '';
      } else {
        return '';
      }
    }
  }
};
