export const CSSExample = `.conversationHealth_button:active{
	background-image: url(content/dam/commercial-eu/neurology/microsites/biosimilars-medical-academy/images/common/icon-close-chatbot.svg);
    /* transform: perspective(2000px) rotateY(180deg); */
    
}
.conversationHealth_button:hover{
	cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 20px 0px, rgba(0, 0, 0, 0.5) 0px 5px 10px 0px;
}

#footer .row.move  {
    display: flex !important;
    flex-direction: row-reverse !important;
} 

@media all and (min-width: 992px){
    #conversationHealth_webchat.move{
		transform: translate(500px, -210px);
    }
    #conversationHealth_webchat.active.move{
		transform: translate(0, -210px);
    }
}

@media all and (min-width: 530px) and (max-width: 991px){
    #conversationHealth_webchat.move{
		transform: translate(500px, -210px);
    }
    #conversationHealth_webchat.active.move{
		transform: translate(0, -210px);
    }
}

@media all and (min-width: 501px) and (max-width: 529px){
    #conversationHealth_webchat.move{
		transform: translate(500px, -140px);
    }
    #conversationHealth_webchat.active.move{
		transform: translate(0, -140px);
    }
}

@media all and (min-width: 320px) and (max-width: 500px){
    #conversationHealth_webchat.move{
		transform: translate(500px, -265px);
    }
    #conversationHealth_webchat.active.move{
		transform: translate(0, -265px);
    }
}`;

import css from 'highlight.js/lib/languages/css';
import { Editor } from '@tiptap/core';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { lowlight } from 'lowlight';

lowlight.registerLanguage('css', css);

export default {
  components: {
    Editor
  },
  data() {
    return {
      extensions: [
        CodeBlockLowlight.configure({
          lowlight
        })
      ]
    };
  }
};
