<template>
  <div class="menu-main" @mouseenter="showChevron = true" @mouseleave="showChevron = false">
    <button v-show="showChevron" id="toggleMenu" class="menu-toggle" @click="toggleMenu">
      <div class="chevron-wrapper">
        <span :title="this.expandedMenu ? 'Collapse' : 'Expand'"><i :class="this.expandedMenu ? 'fa fa-chevron-left chevron-left' : 'fa fa-chevron-right chevron-right'" :aria-hidden="true"></i></span>
      </div>
    </button>
    <aside :class="this.expandedMenu ? 'is-expanded' : 'not-expanded'">
      <div id="paletteZone" class="palette menu" v-if="displayMode !== 'versions'">
        <div class="menu">
          <span> <b>Content</b> </span>
          <draggable :list="getList('Content')" :group="{ name: 'components', pull: 'clone', put: false }" :clone="addComponent" :move="checkMove" :disabled="this.canvasDisabled" item-key="index" class="libraryComponents" filter="input" :preventOnFilter="false" @change="log" @start="onDragStart" @end="onDragEnd">
            <template #item="{ element, index }">
              <div :title="`${element.altText}`" :id="`LIBRARY-${element.type}`" :key="index" class="draggable" draggable="true">
                <img class="sideBarIcon" width="13" :src="getSvg(element)" /><span class="sideBarName">
                  {{ element.displayText }}
                </span>
              </div>
            </template>
          </draggable>
        </div>
        <div class="menu">
          <span> <b>Routing</b> </span>
          <draggable :list="getList('Routing')" :move="checkMove" :group="{ name: 'components', pull: 'clone', put: false }" :disabled="this.canvasDisabled" :clone="addComponent" item-key="index" class="libraryComponents" filter="input" :preventOnFilter="false" @change="log" @start="onDragStart" @end="onDragEnd">
            <template #item="{ element, index }">
              <div :title="`${element.altText}`" :id="`LIBRARY-${element.type}`" :key="index" class="draggable" draggable="true">
                <img class="sideBarIcon" width="13" :src="getSvg(element)" /><span class="sideBarName"> {{ element.displayText }} </span>
              </div>
            </template>
          </draggable>
        </div>
        <div class="menu">
          <span> <b>Actions</b> </span>
          <draggable :list="getList('Actions')" :move="checkMove" :group="{ name: 'components', pull: 'clone', put: false }" :disabled="this.canvasDisabled" :clone="addComponent" item-key="index" class="libraryComponents" filter="input" :preventOnFilter="false" @change="log" @start="onDragStart" @end="onDragEnd">
            <template #item="{ element, index }">
              <div :title="`${element.altText}`" :id="`LIBRARY-${element.type}`" :key="index" class="draggable" draggable="true">
                <img class="sideBarIcon" width="13" :src="getSvg(element)" /><span class="sideBarName"> {{ element.displayText }} </span>
              </div>
            </template>
          </draggable>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import draggable from 'vuedraggable';
import { v4 as uuidv4 } from 'uuid';
import { getComponentObject, libraryDefaults } from '@/components/Utilities/constants/constants';
import { droppedOnBackground, libraryCheckMove } from '@/components/Utilities/dragging';
import { mapState } from 'pinia';

export default {
  name: 'FlowComponentLibrary',
  components: {
    draggable
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      grabbed: false,
      counter: 0,
      options: {
        group: { name: 'components', pull: 'clone', put: false },
        sort: false,
        ghostClass: 'sortable-ghost', // Class name for the drop placeholder
        chosenClass: 'sortable-chosen', // Class name for the chosen item
        dragClass: 'dragging-sort' // Class name for the dragging item
      },
      expandedMenu: true,
      showChevron: false
    };
  },
  computed: {
    ...mapState(useAppStore, ['canvasDisabled', 'displayMode'])
  },
  methods: {
    log: function (evt) {
      console.log(`FlowComponentLibrary:: Logging drag`, {
        event: evt
      });
    },
    toggleMenu() {
      this.expandedMenu = !this.expandedMenu;
    },
    getList(headerTitle) {
      const list = libraryDefaults.filter((x) => x.header === headerTitle);
      return list;
    },
    checkMove(targetEvt) {
      return libraryCheckMove(targetEvt);
    },
    getSvg(item) {
      return require(`./../../../../../assets/icons/${item?.icon || item.text.toLowerCase()}.svg`);
    },
    onDragStart(evt) {
      evt.target.classList.add('grabbing');
    },
    async onDragEnd(evt) {
      try {
        const { item, originalEvent } = evt;
        const { type, id } = item['_underlying_vm_'];
        // Could add logic here to disable add if off canvas
        const isDroppedOnBackground = droppedOnBackground(originalEvent);
        if (isDroppedOnBackground) {
          const containerId = await this.appStore.createNewContainer({
            event: originalEvent,
            type
          });
          if (type === 'flow' && containerId) {
            this.appStore.removeOutgoingLinks({ containerId });
          }
          if (type === 'decision' && containerId) {
            const decisionComponent = this.appStore.getContainerComponents(containerId)[0];
            await this.appStore.createOptionContainer({
              decisionComponentId: decisionComponent.id,
              componentType: 'decision'
            });
          }
        } else if (type === 'decision') {
          await this.appStore.createOptionContainer({
            decisionComponentId: id,
            componentType: 'decision'
          });
        } else if (type === 'flow' || type === 'linkToContainer') {
          this.appStore.removeOutgoingLinks({ componentId: id });
        }
        this.appStore.removeEmptyUnconnectedContainers({});
        this.appStore.FORCE_STATE_RECOMPUTE();
        [...document.getElementsByClassName('draggingDOM')].forEach((node) => {
          node.remove();
        });
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    addComponent({ text }) {
      const obj = getComponentObject(text);
      const newId = uuidv4();

      if (text.toLowerCase() === 'decision') {
        obj.options[0].text = 'Button 1';
      }

      return { id: newId, ...obj };
    },
    typeFromLibrary(event) {
      event.dataTransfer.setData('typeFromLibrary', event.target.id.replace('LIBRARY-', ''));
    },
    drag(event) {
      const y = document.createElement('div');
      y.className = 'draggingDOM';
      const crt = event.target.cloneNode(true);
      crt.style.transform = '';
      crt.style.cursor = 'grabbing';
      crt.classList.add('dragging-sort');
      crt.style.background = 'white';
      crt.style.width = '280px';
      crt.style.fontSize = '14px';
      y.appendChild(crt);
      document.body.appendChild(y);
      event.dataTransfer.setDragImage(y, 20, 20);
    }
  },
  beforeUnmount() {
    document.removeEventListener(
      'dragstart',
      (e) => {
        this.typeFromLibrary(e);
      },
      false
    );
  },
  mounted() {
    document.addEventListener(
      'dragstart',
      (e) => {
        if (e.target.id.includes('LIBRARY')) {
          this.typeFromLibrary(e);
          this.drag(e);
        }
      },
      false
    );
  }
};
</script>

<style lang="scss" scoped>
span,
img {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.menu-main {
  display: flex;
  background-color: transparent;
  flex-direction: row-reverse;
  z-index: 2000;
}

.menu {
  margin-bottom: 25px;
}

.menu-toggle {
  background-color: #edf0f4 !important;
  position: absolute;
  top: 50%;
}

.chevron-wrapper {
  position: absolute;
  z-index: 99;
  left: -10px;

  span {
    background-color: #fff;
    border: 1px solid #d7dde5;
    padding: 2px;
    border-radius: 50%;
    i {
      font-size: 12px;
    }
  }

  span:hover {
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.11);
  }

  @media screen and (max-width: 1001px) {
    display: none;
  }
}

.chevron-right {
  margin: 2px;
  padding-left: 2px;
}
.chevron-left {
  margin: 2px;
  padding-right: 2px;
}

.toggle-line:hover {
  background-color: #1996f0;
}

aside {
  display: flex;
  flex-direction: row-reverse;
  transition: 0.2s ease-in-out;
  &.is-expanded {
    width: var(--sidebar-width);
  }
  &.not-expanded {
    .sideBarName {
      display: none;
    }
    .draggable {
      width: 38px !important;
    }
    .palette {
      width: 60px !important;
    }
  }
}
</style>

<style>
.draggingDOM {
  cursor: grabbing;
  background: transparent;
  width: max-content;
  height: auto;
}
</style>

<style scoped>
.draggable .sortable-chosen .sor .grabbing * {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}
/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbing:active {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
}

.sortable-chosen {
  cursor: grabbing !important;
  cursor: -moz-grabbing !important;
  cursor: -webkit-grabbing !important;
  transform: rotate(-2deg) !important;
}
.sortable-ghost {
  opacity: 0.5;
  border: 2px solid rgba(0, 0, 0, 0.5) !important;
  border-style: dashed !important;
  transform: rotate(0deg) !important;
}
.dragging-sort {
  transform: rotate(-2deg) !important;
}
</style>

<style lang="css">
.sideBarIcon {
  margin-right: 12px;
  width: 14px;
  margin-bottom: 3px;
  z-index: 999999;
}

.sideBarName {
  color: #383f45;
}

.palette {
  scrollbar-color: #edf0f4;
  height: 98% !important;
  max-height: 98% !important;
  margin-right: 2px;
  background-color: white;
  padding: 10px;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .sideBarName {
    display: none;
  }
  .draggable {
    width: 38px !important;
  }
  .palette {
    width: 60px !important;
  }

  .menu-main {
    margin-top: 2% !important;
  }
}

@media screen and (max-width: 800px) {
  .menu-main {
    margin-top: 5% !important;
  }
}

@media screen and (max-width: 1001px) {
  .active-toggle-line {
    display: none;
  }

  .deactive-toggle-line {
    display: none;
  }
}

@media screen and (max-width: 1001px) {
  .active-toggle-line {
    display: none;
  }

  .deactive-toggle-line {
    display: none;
  }
}

@media screen and (min-width: 1001px) {
  .sideBarName {
    display: inline;
  }
  .palette {
    width: 280px !important;
  }
}
@media screen and (min-width: 1200px) {
  .sideBarName {
    display: inline;
  }
  .palette {
    width: 280px !important;
  }
}

.carouselIcon {
  color: #7fa7a0;
}

.bi.bi-chat-square-text-fill {
  color: #557996;
}

.bi.bi-file-earmark-medical {
  color: #94cad1;
}

.fas.fa-code {
  color: #edb78f;
}

.bi.bi-hdd-stack {
  color: #92b8d9;
}

.bi.bi-caret-right-square-fill {
  color: #c3afc9;
}

#paletteZone {
  margin-left: 5px;
  margin-top: 8%;
  padding: 5px;
  text-align: left;
  position: relative;
  height: calc(100vh - 60px);
  padding-bottom: 50px;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}
span {
  font-size: 14px;
}
i {
  margin-right: 5px;
}
.draggable {
  margin: 10px 10px 10px 1px;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  font-style: normal;
  font-size: 13px;
  border-radius: 4px;
  background-color: rgb(237, 240, 244, 0.8);
  color: #383f45;
}

.draggable:hover {
  border: 1px solid #557996;
  cursor: grab;
  cursor: -moz-grab;
}
.draggable i {
  padding-right: 5px;
}
#paletteZone::-webkit-scrollbar-thumb {
  background: #8e9ba8 !important;
  color: #edf0f4;
}
#paletteZone::-webkit-scrollbar-track {
  background: #edf0f4 !important;
  color: #edf0f4;
}
* {
  scrollbar-color: #edf0f4;
}
</style>
