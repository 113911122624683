<template>
  <div class="bulk-actions">
    <v-menu offset-y transition="scale-transition">
      <template #activator="{ props }">
        <v-btn
          v-bind="props"
          class="bulk-actions-btn"
          flat
          density="comfortable"
          :disabled="disabled"
        >
          <span class="label">Bulk Actions</span><span class="down triangle">▾</span>
        </v-btn>
      </template>
      <v-list class="bulk-actions-list">
        <v-list-item
          v-for="(option, index) in options"
          :key="index"
          class="bulk-actions-list-item"
          @click="$emit('performBulkUpdates', option)"
        >
          <v-list-item-title class="bulk-actions-list-item-title">
            {{ option }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';
export default {
  name: 'BulkActions',
  props: ['disabled'],
  data() {
    return {
      appStore: useAppStore(),
    };
  },
  computed: {
    ...mapState(useAppStore, ['currentFlows', 'isAdmin']),
    options() {
      // future menu options
      // options: ['Publish', 'Flow Status', 'Delete', 'Duplicate']
      let tempOptions = ['Flow Status'];
      if (this.isAdmin) tempOptions = ['Publish', ...tempOptions];
      return tempOptions;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.bulk-actions {
  padding: 0;
  width: 100%;
}

.label {
  margin-right: 10px;
}
.bulk-actions-btn {
  background-color: white;
  height: 48px;
  border: solid 1px #8e9ba7;
  border-radius: 4px;
  margin: 10px 0;
}

.bulk-actions-list {
  display: flex;
  flex-direction: column;

  .bulk-actions-list-item {
    display: flex;
    justify-content: left;
    align-items: left;
    cursor: pointer;
    height: 2em;
    &:hover {
      background-color: #e4e4e4;
    }
    .bulk-actions-list-item-title {
      width: 75%;
      overflow: visible;
    }
  }
}
</style>
