<template>
  <div>
    <div v-show="appStore.drawer === 'pdf'" class="overlay"></div>
    <DrawerMain id="pdfDrawer" v-show="appStore.drawer === 'pdf'">
      <template v-slot:header>
        <PageHeader title="PDF Export" subtitle="Utilities / Configuration" />
      </template>
      <template v-slot:filters></template>
      <template v-slot:body>
        <div v-if="appStore.currentTaxonomy" class="pageLayout">
          <FlowList @selectionChanged="selectionChanged" :collection="appStore.currentTaxonomy" v-show="appStore.drawer === 'pdf'"></FlowList>
          <div class="actionsOptions">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="goToPDFOptions" type="button" class="btn btn-primary">Next</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import FlowList from './Lists/FlowList.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'PdfDrawer',
  components: {
    DrawerMain,
    FlowList
  },
  data() {
    const appStore = useAppStore();

    return {
      notes: [],
      intents: [],
      language: [],
      filtersNotes:
        appStore.getClaims?.role !== 'CLIENT'
          ? [
              { text: 'CLIENT', value: 'CLIENT', selected: false },
              { text: 'INTERNAL', value: 'INTERNAL', selected: false }
            ]
          : [{ text: 'CLIENT', value: 'CLIENT', selected: false }]
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    autoSaveStore() {
      return useAutoSaveStore();
    },
    commentsStore() {
      return useCommentsStore();
    },
    versionsStore() {
      return useVersionsStore();
    },
    historyStore() {
      return useHistoryStore();
    },
    notificationsStore() {
      return useNotificationsStore();
    },
    vcmStore() {
      return useVcmStore();
    }
  },
  methods: {
    isChecked(array, value) {
      return array.find((x) => x.value === value) === undefined ? false : true;
    },
    selectionChanged(payload) {
      this.language = payload.language;
      this.intents = payload.intents;
    },
    goToPDFOptions() {
      if (!this.intents.length) return alert('No flows selected');
      const metaData = cloneDeep(this.appStore.drawerMetadata);
      metaData.language = this.language?.code;
      metaData.selectedFlows = this.intents.map(({ id, name, published, impersonate }) => ({ id, name, published, impersonate }));
      this.appStore.openDrawer({ drawer: 'bulkPdfOptions', taxonomy: metaData.taxonomy, taxonomyId: metaData.taxonomyId, metadata: metaData });
    }
  }
};
</script>
