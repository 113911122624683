<template>
  <div :class="`row button-row ${hover ? 'hover' : ''}`" @mouseover="hover = true" @mouseleave="hover = false">
    <div @click="execute" class="remove-container">
      <ConversationBuilderIcon :key="hover" class="remove-button" :width="width" name="remove_button" />
      <span class="remove-text">Remove option</span>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  data() {
    return {
      appStore: useAppStore(),
      hover: false
    };
  },
  props: {
    func: {
      type: Function
    },
    width: {
      type: Number,
      default: 45
    }
  },
  methods: {
    execute() {
      this.func();
    }
  }
};
</script>

<style lang="scss" scoped>
.button-row {
  width: 170px;
  height: 30px;
  margin-left: 11px;
  margin-top: 20px;
  padding: 3px 16.8px 3px 3px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  background-color: #fff;
  cursor: pointer;

  .remove-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    .remove-button {
      display: block;
    }

    .remove-text {
      margin-left: 11px;
      font-size: 17px;
      line-height: 1.29;
      text-align: left;
    }
  }
}

.hover {
  background-color: #1996f0;
  color: #fff;
}
</style>
