<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <div class="sidebar-text-input">
        <input :id="componentSelected.id" v-model="text" :placeHolder="placeHolder" :readOnly="false" />
      </div>
    </div>
    <div v-if="this.reference.length > 0" class="sidebar-reference-group">
      <div v-for="(arrayItem, index) in reference" :key="index">
        <div class="reference-group">
          <div class="reference-item" v-html="arrayItem"></div>
          <button class="reference-exit" @click="deleteEvent(index)">
            <ConversationBuilderIcon name="x" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      reference: []
    };
  },
  props: {
    id: {},
    field: {},
    placeHolder: {
      default: 'Add Verbatim'
    }
  },
  methods: {
    deleteEvent(index) {
      this.reference.splice(index, 1);
    }
  },
  computed: {
    text: {
      get() {
        return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id)[this.field][this.appStore.language] || '';
      },
      set(value) {
        const array = [];
        array.push(value);
        this.appStore.updateComponent({ verbatim: array, id: this.id });
      }
    },
    componentSelected() {
      return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.reference-group {
  // height: 40px;
  align-items: center;
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  justify-content: space-between;
}

.reference-exit {
  padding: 12px 12px;
  cursor: pointer;
}
.reference-item {
  padding: 8px 20px;
}
.sidebar-reference-group {
  flex-direction: column-reverse;
  display: flex;
  padding: 0px 2px 0px 2px;
  border-radius: 3px;
  border: solid 1px #d7dde5;
  width: 100%;
  text-align: left;
}
.sidebar-reference-group:hover {
  border: solid 1px #2698ed;
}
input {
  width: 100%;
  font-size: 12px;
  padding: 0px 8px;
}
.sidebar-reference-group::placeholder {
  font-style: italic;
  color: #8e9ba8;
}

.sidebar-text-input {
  display: flex;
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: solid 1px #d7dde5;
  width: 100%;
  text-align: left;
  height: 40px;
  word-wrap: break-word;
  word-break: break-word;
  width: 100%;
}
.sidebar-text-input:hover {
  border: solid 1px #2698ed;
}
input {
  width: 100%;
  font-size: 12px;
}
.sidebar-text-input::placeholder {
  font-style: italic;
  color: #8e9ba8;
}
</style>
