<template>
  <div>
    <div class="row flow">
      <div class="col-12">
        <MessageEditor placeHolder="" :readOnly="true" read-only :id="id" :collection="agentName" :type="`flow`" :modelValue="getFlowName" @update:modelValue="val => getFlowName = val.trim()" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import essentials from '../Mixins/essentials';
export default {
  name: 'FlowComponent',
  mixins: [essentials],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      flows: [],
      currentAgent: useAppStore().currentTaxonomy,
      agentName: this.response?.taxonomy?.name || useAppStore().currentTaxonomy
    };
  },
  async mounted() {
    await this.getFlows();
  },
  computed: {
    getFlowName() {
      const id = this.response?.intent?.id;
      const flow = this.flows?.length > 0 ? this.flows.find((x) => x.id === id) : {};
      if (flow?.name != undefined) {
        return flow.name;
      } else if (this.response.text != 'None') {
        return this.response.text;
      } else {
        return '';
      }
    }
  },
  watch: {
    async response() {
      await this.getFlows();
    }
  },
  methods: {
    async getFlows() {
      const agentName = this.response?.taxonomy?.name;
      if (agentName === '') {
        // Same agent
        this.flows = this.appStore.currentFlows;
      } else {
        // Different agent
        this.flows = await this.appStore.getAllFlows({ collection: agentName });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Flow.scss';
</style>
