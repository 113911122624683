<template>
  <!-- <input type="text" name="message" class="form-control" placeholder="Message" v-model="newMessage"> -->
  <el-row>
    <EditorMessage @send-message="createMessage" :placeholder="`Leave Message #${nodes.clicked.data.data.name}`" :readOnly="false" :id="10000" :collection="collection" :nodes="nodes" v-model="newMessage" :type="`message`" />
  </el-row>
  <!-- <el-row :span='4' @click='createMessage' class='sendButton'>SEND MESSAGE <i class="fas fa-paper-plane"></i></el-row> -->
  <!-- <p class="text-danger" v-if="errorText">{{ errorText }}</p> -->
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { db } from '@/main';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export default {
  name: 'CreateMessage',
  props: {
    user: {
      type: Object,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    slotID: {
      type: String,
      required: false
    },
    collection: {
      type: String,
      required: false
    },
    nodes: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      newMessage: null,
      errorText: null
    };
  },
  methods: {
    createMessage() {
      if (this.newMessage) {
        db.collection(this.collection)
          .doc(this.slotID)
          .collection('messages')
          .add({
            message: this.newMessage,
            name: this.name,
            timestamp: Date.now(),
            photoURL: !Object.prototype.hasOwnProperty.call(this.user, 'photoURL') ? '' : this.user.photoURL,
            uid: this.user.uid,
            displayName: !Object.prototype.hasOwnProperty.call(this.user, 'displayName') ? this.user.email : this.user.displayName
          })
          .catch((err) => {
            console.error('Error: ', err.message, err);
          });
        this.newMessage = null;
        this.errorText = null;
      } else {
        this.errorText = 'A message must be entered!';
      }
    }
  }
};
</script>

<style scoped>
.form-control {
  border-radius: 5px;
}

.sendButton {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #009bcf;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.inputStyle {
  display: block;
}
</style>
