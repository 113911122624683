<template>
  <div class="notifications-wrapper">
    <NotificationDrawer :type="'full-view'" />
  </div>
</template>

<script>
import NotificationDrawer from '@/components/Drawer/Notifications/NotificationDrawer.vue';

export default {
  name: 'AppNotifications',
  components: {
    NotificationDrawer,
  },
};
</script>

<style lang="scss" scoped>
.notifications-wrapper {
  width: 100%;
  height: calc(100vh - 60px);
  padding-left: 20px;
  // padding-right: 20px;
  overflow-y: auto;
}
</style>
