import { Node, nodeInputRule } from '@tiptap/core';

const EXTENSION_NAME = 'horizontal_rule';
export const HorizontalRule = Node.create({
  name: EXTENSION_NAME,

  addOptions: {
    HTMLAttributes: {}
  },

  group: 'block',

  parseHTML() {
    return [
      {
        tag: 'hr'
      }
    ];
  },

  renderHTML() {
    return ['hr', this.options.HTMLAttributes];
  },

  addCommands() {
    return {
      setHorizontalRule:
        () =>
        ({ commands }) => {
          return commands.insertContent({ type: EXTENSION_NAME });
        }
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /^(?:---|—-|___\s|\*\*\*\s)$/,
        type: EXTENSION_NAME
      })
    ];
  }
});
