import { mapState } from 'pinia';
import debounce from 'lodash/debounce';
import { useAppStore } from '@/store/index.js';

export default {
  props: {
    id: {},
    data: {},
    field: {},
    default: {},
  },
  data() {
    return {
      appStore: useAppStore(),
      slots: [],
    };
  },
  methods: {
    setComponentId() {
      debounce(
        function () {
          this.appStore.setComponentId({ componentId: this.id });
        },
        50,
        { maxWait: 100 }
      );
    },
    getDefault(field) {
      if (field === 'taxonomy')
        return this.appStore.taxonomies.find(
          (x) => x.name === this.appStore.currentTaxonomy
        );
      else return '';
    },
  },
  computed: {
    ...mapState(useAppStore, ['components', 'componentList']),
    ...mapState(useAppStore, ['language', 'currentTaxonomy', 'currentSlots']),
    ...mapState(useAppStore, ['updateComponent']),
    itemSelected: {
      get() {
        if (typeof this.components[this.id][this.field] === 'string') {
          return this.components[this.id][this.field];
        } else if (typeof this.components[this.id][this.field] === 'object') {
          if (
            Object.values(this.components[this.id][this.field]).includes('') ||
            this.components[this.id][this.field]?.name === 'None'
          )
            return '';
          else return this.components[this.id][this.field];
        } else {
          return this.getDefault(this.field);
        }
      },
      set(value) {
        const obj = { [this.field]: value };
        this.appStore.updateComponent({ ...obj, id: this.id });
      },
    },
  },
  beforeUnmount() {
    this.slots = [];
  },
};
