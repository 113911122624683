<template>
  <ul :class="{ opened: true }" id="edgeOptionsMenu" class="edgeOptionsMenu" :data-lineId="lineId">
    <li class="edgeOptionsItem" :id="getId(item)" :ref="getId(item)" :data-lineId="lineId" @mouseover="handleMouseEnter($event, item)" @mouseout="handleMouseLeave($event, item)" @click="handleClick($event, item)" v-for="(item, i) in filteredItems" :key="i">
      <ConversationBuilderIcon :id="`icon-${item.icon}`" :name="item.icon" :nameHover="item.iconHover" :width="item.width" />
    </li>
  </ul>
</template>

<script>
import { nextTick } from 'vue';
export default {
  name: 'EdgeOptions',
  data() {
    return {
      filteredItems: []
    };
  },
  props: {
    lineId: {
      type: String,
      default: ''
    },
    opened: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array
    },
    closeParent: {
      type: Function
    }
  },
  mounted() {
    try {
      this.filteredItems = this.items.filter((x) => typeof x === 'object');
      nextTick(() => {
        for (const item of this.filteredItems) {
          const elementId = this.getId(item);
          const element = document.getElementById(elementId);

          if (element) {
            element.addEventListener('click', (e) => this.handleClick(e, item));
            element.addEventListener('mouseover', (e) => this.handleMouseEnter(e, item));
            element.addEventListener('mouseout', (e) => this.handleMouseLeave(e, item));
          }
        }
      });
    } catch (err) {
      console.error('Edge options error: ', err.message, err);
    }
  },
  methods: {
    getId(item) {
      const id = `${this.lineId}-edgeOptionsItem-${item.title}`;
      return id;
    },
    handleMouseEnter(e, item) {
      if (item?.iconHover) {
        e.target.src = require(`../../../../../../assets/icons/${item.iconHover}.svg`);
      }
    },
    handleMouseLeave(e, item) {
      if (item?.iconHover) {
        e.target.src = require(`../../../../../../assets/icons/${item.icon}.svg`);
      }
    },
    handleClick(e, item) {
      item.click();
      this.closeParent();
    }
  },
  beforeUnmount() {
    try {
      for (const item of this.filteredItems) {
        const elementId = this.getId(item);
        const element = document.getElementById(elementId);
        if (element) {
          element.removeEventListener('click', (e) => this.handleClick(e, item));
          element.removeEventListener('mouseover', (e) => this.handleMouseEnter(e, item));
          element.removeEventListener('mouseout', (e) => this.handleMouseLeave(e, item));
        }
      }
      this.filteredItems = [];
    } catch (err) {
      console.error('Edge options error: ', err.message, err);
    }
  }
};
</script>

<style scoped>
li {
  padding: 0px;
}

.edgeOptionsMenu {
  font-size: 17px;
  width: max-content;
  height: max-content;
  list-style-type: none;
  padding: 0px 0px 0px 0px !important;
  margin-bottom: 0px !important;
  background-color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1rem;
  text-align: inherit;
  color: white;
  border-radius: 4px;
}

li.opened {
  display: flex;
}

ul.opened.edgeOptionsMenu {
  display: flex;
}
.edgeOptionsMenu li {
  width: 100%;
  cursor: pointer;
  line-height: 12px;
  padding: 7px 7px 7px 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.edgeOptionsMenu li span {
  margin-left: 0px;
  display: block;
}

.edgeOptionsMenu li span .icon {
  display: block;
}
</style>
