<template>
  <VueMultiselect :max-height="125" open-direction="bottom" v-model="selectedVersion" @input="versionChanged" @select="versionChanged" @remove="versionChanged" :show-labels="false" :options="versions" :option-height="30" :searchable="false" :close-on-select="true" class="versioning-dropdown">
    <template #option="props">
      <div class="versioning-option">
        <el-tooltip placement="top-start" v-if="getVersionName(props.option).length > 20">
          <template #content>
            {{ getVersionName(props.option) }}
          </template>
          <div class="versioning-option-title">
            {{ getVersionName(props.option) }}
          </div>
        </el-tooltip>
        <span v-else class="versioning-option-title">
          {{ getVersionName(props.option) }}
        </span>
        &nbsp;<span class="published" v-if="props.option.isPublished === true && !props.option.default">Published</span>
      </div>
    </template>
    <template #noOptions>
      <span> No other named versions are available. </span>
    </template>
    <template #singleLabel="props">
      <div class="versioning-title">{{ getVersionName(props.option) }}</div>
    </template>
  </VueMultiselect>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'VersionsDropdown',
  components: {
    VueMultiselect
  },
  props: {
    slot: Object,
    versions: Array,
    currentVersion: Object
  },
  watch: {
    currentVersion(newVersion, _oldVersion) {
      this.selectedVersion = newVersion;
    }
  },
  data() {
    return {
      selectedVersion: this.currentVersion
    };
  },
  emits: ['update:currentVersion'],
  methods: {
    versionChanged(option) {
      this.$emit('update:currentVersion', option);
    },
    getVersionName(version) {
      if (typeof version === 'string') {
        return version;
      } else if (typeof version === 'object') {
        return version?.versionName || version?.name || '';
      } else {
        return '';
      }
    }
  }
};
</script>
<style lang="scss">
.versioning-option .published {
  width: 60px;
  height: 20px;
  font-size: 12px;
  color: #1996f0;
  background-color: #e8f5fe;
  padding: 2px;
}
</style>
