import moment from 'moment/moment';

export const DEFAULT_TIME_AGGREGATE_MINUTES = 5;

function isSameDay(date, today) {
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

function isYesterday(date, today) {
  const yesterday = addDay(today, -1);
  return isSameDay(date, yesterday);
}

function addDay(date, days) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
}

function startOfWeek(date) {
  const temp = new Date(date);
  // Set to previous Sunday
  temp.setDate(temp.getDate() - temp.getDay());
  temp.setHours(0, 0, 0, 0);
  return temp;
}

function isThisWeek(date, today) {
  const start1 = startOfWeek(date);
  const start2 = startOfWeek(today);
  return start1.getTime() === start2.getTime();
}

function isLastWeek(date, today) {
  return isThisWeek(date, addDay(today, -7));
}

function isThisMonth(date, today) {
  return date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

function isThisYear(date, today) {
  return date.getFullYear() === today.getFullYear();
}

function yearOf(date) {
  return date.getFullYear();
}

function getOldestDraftYear(drafts, today) {
  let oldestYear = today.getFullYear(); // default to this year

  drafts.forEach((draft) => {
    const year = new Date(draft.createdAt).getFullYear();
    if (year < oldestYear) {
      oldestYear = year;
    }
  });

  return oldestYear;
}

function getYearsArrayFromLastYearToOldest(drafts, today) {
  const currentYear = today.getFullYear();
  const oldestYear = getOldestDraftYear(drafts, today);

  const years = [];
  for (let year = currentYear - 1; year >= oldestYear; year--) {
    years.push(year);
  }

  return years;
}

export function aggregateDrafts(draftList, timespan = DEFAULT_TIME_AGGREGATE_MINUTES) {
  if (draftList.length === 0) return [];
  let currentDraft = undefined;
  let currentTempArr = [];

  const reducedDrafts = draftList.reduce((tempArr, doc) => {
    if (currentDraft === undefined) {
      currentDraft = doc;
    }
    const currentTime = moment(currentDraft.createdAt).subtract(timespan, 'minutes');

    let isCurrent;
    if (currentDraft.versionGroupId || doc.versionGroupId) {
      isCurrent = currentDraft.versionGroupId === doc.versionGroupId;
    } else {
      isCurrent = moment(currentTime).isBefore(doc.createdAt) && currentDraft.user === doc.user;
    }
    if (isCurrent) {
      currentTempArr.push(doc);
    } else {
      tempArr.push(currentTempArr);
      currentTempArr = [];
      currentDraft = doc;
      currentTempArr.push(doc);
    }

    return tempArr;
  }, []);

  reducedDrafts.push(currentTempArr);

  return reducedDrafts;
}

export const analyseDraftVersionsForDateGrouping = (drafts, today) => {
  const sortedDrafts = drafts.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const filters = [
    (draft) => isSameDay(new Date(draft.createdAt), today) && 'Today',
    (draft) => isYesterday(new Date(draft.createdAt), today) && 'Yesterday',
    ...['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (draft) => {
      const date = new Date(draft.createdAt);
      return isThisWeek(date, today) && !isSameDay(date, today) && !isYesterday(date, today) && date.getDay() === index && `${day}`;
    }),
    (draft) => isLastWeek(new Date(draft.createdAt), today) && 'Last Week',
    (draft) => {
      const date = new Date(draft.createdAt);
      return isThisMonth(date, today) && !isThisWeek(date, today) && !isLastWeek(date, today) && 'This Month';
    },
    ...Array.from({ length: 12 }).map((_, index) => (draft) => {
      const date = new Date(draft.createdAt);
      return (
        date.getMonth() === index &&
        isThisYear(date, today) &&
        !isThisMonth(date, today) &&
        new Intl.DateTimeFormat('en-US', {
          month: 'long',
          year: 'numeric'
        }).format(date)
      );
    }),
    ...getYearsArrayFromLastYearToOldest(sortedDrafts, today).map((year) => (draft) => {
      const date = new Date(draft.createdAt);
      return yearOf(date) === year && date.getFullYear().toString();
    })
  ];
  return sortedDrafts.reduce((groups, draft) => {
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      const groupName = filter(draft);
      if (groupName) {
        let group = groups.find(({ key }) => key === groupName);
        if (group?.draftList) {
          group.draftList.push(draft);
        } else {
          group = { key: groupName, draftList: [draft] };
          groups.push(group);
        }
        break;
      }
    }
    return groups;
  }, []);
};
