import { defineStore } from 'pinia';

export const useHistoryStore = defineStore({
  id: 'history',

  state: () => ({
    snapshots: [],
    currentIndex: -1,
    maxSnapshotsCount: 100
  }),

  // Getters
  getters: {
    canUndo: (state) => state.currentIndex > 0,
    canRedo: (state) => state.currentIndex < state.snapshots.length - 1 && state.snapshots.length > 0
  },

  // Actions
  actions: {
    clearHistory() {
      this.snapshots = [];
      this.currentIndex = -1;
    },

    saveSnapshot(snapshot) {
      // Remove any snapshots after the current index
      this.snapshots = this.snapshots.slice(0, this.currentIndex + 1);

      // Add the new snapshot
      this.snapshots.push(snapshot);

      // Update the currentIndex
      this.currentIndex++;

      // Ensure we don't exceed the max maxSnapshotsCount history length
      while (this.snapshots.length > this.maxSnapshotsCount) {
        this.snapshots.shift();
        this.currentIndex--;
      }
    },

    undo() {
      if (this.canUndo) {
        this.currentIndex--;
      }
    },

    redo() {
      if (this.canRedo) {
        this.currentIndex++;
      }
    }
  }
});
