//Transforms a pre 3.3 multi-lang carousel object to a language agnostic post 3.3.3 schema
export const transformCarouselComponent = (component) => {
  if (!component || typeof component !== 'object' || !Object.keys(component).length > 0) return component;
  return {
    ...transformCarouselCard(component),
    carousels:
      component.carousels &&
      component.carousels.map((c) => {
        return transformCarouselCard(c);
      })
  };
};

const transformCarouselCard = (card) => {
  if (!card || typeof card !== 'object' || !Object.keys(card).length > 0) return card;
  return {
    ...card,
    label: typeof card.label === 'object' ? card.label.en : card.label,
    cardCta: typeof card.cardCta === 'object' ? card.cardCta.en : card.cardCta,
    subTitle: typeof card.subTitle === 'object' ? card.subTitle.en : card.subTitle,
    imageAltText: typeof card.imageAltText === 'object' ? card.imageAltText.en : card.imageAltText,
    title: typeof card.title === 'object' ? card.title.en : card.title,
    url: typeof card.url === 'object' ? card.url.en : card.url,
    subpath: typeof card.subpath === 'object' ? card.subpath.en : card.subpath,
    reference: typeof card.reference === 'object' && !Array.isArray(card.reference) ? card.reference.en : card.reference,
    queryParameters:
      card.queryParameters &&
      card.queryParameters.map((queryParam) => {
        return {
          ...queryParam,
          name: typeof queryParam.name === 'object' ? queryParam.name.en : queryParam.name,
          value: typeof queryParam.value === 'object' ? queryParam.value.en : queryParam.value
        };
      })
  };
};
