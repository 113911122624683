<template>
  <div class="grid justify-items-center">
    <div style="height: 100vh">
      <iframe frameborder="0" :src="previewURL" height="100%" width="100%"></iframe>
    </div>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'ChatPreview',
  props: {
    collection: {
      type: String
    },
    slotInfo: {}
  },
  data() {
    return {
      appStore: useAppStore()
    };
  },
  computed: {
    previewURL() {
      // return `http://localhost:8081/${this.appStore.language != 'gb' ? this.appStore.language : ''}?preview=true&collection=${this.collection.replace(' ', '%20')}&slotId=${this.slotInfo[1]}&singleId=2`
      return `${process.env.VUE_APP_CLIENT_TESTING_BOT_ENDPOINT}/?chLanguage=${this.appStore.language != 'en' ? this.appStore.language : ''}&preview=true&collection=${this.collection.replace(' ', '%20')}&slotId=${this.slotInfo[1]}&singleId=2`;
    }
  }
};
</script>
