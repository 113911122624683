<template>
  <div class="row link-url">
    <div class="col-12">
      <MessageEditor :placeHolder="` `" :readOnly="true" :id="id" :collection="agentName" :type="`linkUrl`" v-model.trim="label" :modelValue="label" @update:modelValue="(val) => (label = val.trim())" />
      <MessageEditor :placeHolder="` `" :url="true" :readOnly="true" read-only :id="id" :collection="agentName" :type="`linkUrl`" :modelValue="url" @update:modelValue="(val) => (url = val.trim())" />
    </div>
  </div>
</template>

<script>
import essentials from '../Mixins/essentials';
import { useAppStore } from '@/store/index.js';
export default {
  name: 'LinkUrl',
  mixins: [essentials],
  data() {
    const appStore = useAppStore();
    return {
      agentName: this.response?.taxonomy?.name || appStore.currentTaxonomy
    };
  },
  computed: {
    label: {
      get() {
        return this.response['label'];
      }
    },
    url: {
      get() {
        const baseUrl = this.response['url'];

        if (this.response.viewType === 'basic') {
          return baseUrl;
        }

        if (this.response.advancedViewType === 'subpath') {
          return baseUrl + '/' + this.response['subpath'];
        }

        let queryParameters = '';

        this.response.queryParameters.forEach((param, index) => {
          if (!index) {
            queryParameters += `?${param.name}=${param.value}`;
          } else {
            queryParameters += `&${param.name}=${param.value}`;
          }
        });

        return baseUrl + queryParameters;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './LinkUrl.scss';
</style>
