<template>
  <div class="agents-wrapper">
    <div class="agents-header">
      <div class="title">
        <span><router-link to="/home">Home</router-link> / <router-link to="/agents">Agents</router-link></span>
        <p>{{ active }}</p>
      </div>
      <div class="tabs">
        <div class="tab" :class="active === 'flows' ? 'active' : ''" @click="handleTabClick('flows')">
          <ConversationBuilderIcon :width="30" :name="`agents-flows${active === 'flows' ? '-active' : ''}`" />
          <span>Flows</span>
        </div>
        <div class="tab" v-if="isEditor" :class="active === 'intents' ? 'active' : ''" @click="handleTabClick('intents')">
          <ConversationBuilderIcon :width="30" :name="`agents-intents${active === 'intents' ? '-active' : ''}`" />
          <span>Intents</span>
        </div>
        <div class="tab" v-if="isEditor" :class="active === 'entities' ? 'active' : ''" @click="handleTabClick('entities')">
          <ConversationBuilderIcon :width="30" :name="`agents-entities${active === 'entities' ? '-active' : ''}`" />
          <span>Entities</span>
        </div>
        <!--        <div class="tab disabled">
            <ConversationBuilderIcon :width="30" :name="`agents-variables${active === 'variables' ? '-active' : ''}`" />
            <span>Variables</span>
          </div>-->
      </div>
      <div class="update">
        <span> {{ lastUpdated }} </span>
      </div>
    </div>
    <div class="agents-container">
      <AgentFlows v-if="active === 'flows'" :agentName="agentName" :tab="active" />
      <AppTaxonomy v-if="active === 'intents'" :agentName="agentName" :tab="active" />
      <AgentEntities v-if="active === 'entities'" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import ConversationBuilderIcon from '../../components/ConversationBuilder/Canvas/3.0/Icons/ConversationBuilderIcon.vue';
import AgentEntities from '../../components/Entities/AgentEntities.vue';
import AppTaxonomy from '../AppTaxonomy.vue';
import AgentFlows from './AgentFlows.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'AppAgent',
  components: {
    ConversationBuilderIcon,
    AgentFlows,
    AppTaxonomy,
    AgentEntities
  },
  props: {
    id: String,
    initial: String
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      active: 'flows'
    };
  },
  mounted() {
    this.active = this.$route.query.initial ? this.$route.query.initial : 'flows';
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies', 'isEditor', 'currentFlows']),
    agentName() {
      return this.taxonomies.find((taxonomy) => taxonomy.id === this.id)?.name;
    },
    lastUpdated() {
      if (this.currentFlows?.length) {
        const tempFlows = cloneDeep(this.currentFlows);
        const sorted = tempFlows.sort((a, b) => {
          return b.updatedAt - a.updatedAt;
        });
        const newDate = new Date(sorted[0].updatedAt).toISOString();
        const daysAgo = this.$dayjs(`${newDate}`).fromNow();
        return `Last updated: ${daysAgo}`;
      }
      return 'Last updated:';
    }
  },
  methods: {
    handleTabClick(tabName) {
      this.active = tabName;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, initial: tabName }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.agents-wrapper {
  width: 100%;
  height: 94vh;
  display: flex;
  flex-direction: column;

  .agents-container {
    width: 100%;
    height: calc(100% - 80px);
  }

  .agents-header {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px;

    .title {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        font-size: 14px;
        line-height: 1.36;
        text-align: left;
        color: #383f45;
        a {
          text-decoration: none !important;
          color: #383f45 !important;
        }

        .router-link-active {
          color: #383f45;
          cursor: pointer;
          &:hover {
            text-decoration: none;
          }
        }
      }

      p {
        font-size: 21px;
        font-weight: bold;
        line-height: 1.33;
        text-align: left;
        color: #383f45;
        text-transform: capitalize;
      }
    }

    .tabs {
      width: 524px;
      height: 100%;
      display: flex;

      .tab {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-left: solid 1px #e3e8ee;
        cursor: pointer;
        padding-bottom: 12px;
        border-bottom: 4px solid transparent;

        span {
          font-size: 14px;
          line-height: 1.14;
          text-align: center;
          color: #8e9ba8;
          margin-top: 10px;
        }
      }

      .active {
        border-bottom: solid 4px #fd5422;

        span {
          color: #383f45;
        }
      }

      .tab:last-of-type {
        border-right: solid 1px #e3e8ee;
      }

      .disabled {
        cursor: not-allowed;
      }
    }

    .update {
      height: 100%;
      display: flex;
      align-items: flex-start;
      padding-top: 18px;
    }
  }
}
</style>
