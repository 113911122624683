<template>
  <div class="properties-card" v-click-outside="updateFlowProperties">
    <div class="content">
      <div class="content-each grid content-item-full">
        <div class="sub-title text-grey">Batch Number</div>
        <input class="user-input" v-model="batchNumber" />
      </div>
      <div class="content-each grid content-item-full">
        <div class="sub-title text-grey">Status</div>
        <FlowStatusPropertiesDropdown :selectedStatus="status" @updateParentData="updateData" />
      </div>
      <div v-if="isAdmin" class="content-each flex content-item">
        <div class="w-1/2 sub-title text-grey">Active</div>
        <BooleanToggle :parentDataToBindTo="isActive" parentDataToUpdate="isActive" @updateParentData="updateData" />
      </div>
      <div class="content-each flex content-item">
        <div class="w-1/2 sub-title text-grey">On Label</div>
        <BooleanToggle :parentDataToBindTo="onLabelBoolean" parentDataToUpdate="onLabelBoolean" @updateParentData="updateData" />
      </div>
      <div class="content-each flex content-item">
        <div class="w-1/2 sub-title text-grey">Gated</div>
        <BooleanToggle :parentDataToBindTo="gatedBoolean" parentDataToUpdate="gatedBoolean" @updateParentData="updateData" />
      </div>
      <div class="content-each column content-item-full">
        <div class="w-full sub-title text-grey">Categorization</div>
        <TagsInput :selectedTag="category" @updateParentData="updateData" :options="options" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import FlowStatusPropertiesDropdown from './Flows/FlowStatusPropertiesDropdown.vue';
import BooleanToggle from './Flows/BooleanToggle.vue';
import cloneDeep from 'lodash/cloneDeep';
import vClickOutside from 'click-outside-vue3';
import TagsInput from './Tags/TagsInput';
import options from '../../components/Utilities/constants/tags';
import isEqual from 'lodash/isEqual';
export default {
  name: 'FlowProperties',
  components: {
    FlowStatusPropertiesDropdown,
    BooleanToggle,
    TagsInput
  },
  props: {
    flowObject: Object,
    agentName: String
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      status: this.upperCaseToSentenceCase(this.flowObject.status),
      batchNumber: this.flowObject?.batch,
      onLabelBoolean: this.flowObject?.onLabel,
      gatedBoolean: this.flowObject?.isGated,
      isActive: this.flowObject?.isActive,
      category: this.flowObject?.category || 'None',
      options: options
    };
  },
  mounted() {
    this.appStore.getFlowTags({ flowName: this.agentName }).then((tags) => {
      const flowTags = tags.map((document) => document.tag);
      tags = [...options, ...flowTags];
      this.options = [...new Set(tags)];
    });
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    ...mapState(useAppStore, ['currentFlows', 'isAdmin'])
  },
  methods: {
    updateData(payload) {
      const { key, value } = payload;
      this[key] = value;
    },
    updateFlowProperties() {
      //get the flow object to update
      const newFlows = cloneDeep(this.currentFlows);
      const flow = newFlows.find((flow) => flow.id === this.flowObject.id);
      // if flow object has not change, do not modify updatedAt time - no chance has been made;
      const date = isEqual(flow, this.flowObject) ? flow.updatedAt : Date.now();
      const onLabelValue = this.onLabelBoolean !== this.flowObject.onLabel ? this.onLabelBoolean : this.flowObject.onLabel;
      //modify state
      flow.updatedAt = date;
      flow.status = this.status.toUpperCase();
      flow.batch = this.batchNumber;
      flow.onLabel = onLabelValue;
      flow.isGated = this.gatedBoolean;
      flow.category = this.category;
      flow.isActive = this.isActive;
      //Update DB
      const payload = {
        agentName: this.agentName,
        flowId: this.flowObject.id,
        updatedAt: date,
        status: this.status.toUpperCase(),
        batch: this.batchNumber,
        onLabel: onLabelValue,
        isGated: this.gatedBoolean,
        isActive: this.isActive,
        category: this.category,
        published: flow.published
      };
      const availableLanguages = typeof this.appStore.languageCodes === 'object' && Object.keys(this.appStore.languageCodes).length > 0 ? Object.keys(this.appStore.languageCodes) : ['en'];
      for (const language of availableLanguages) {
        this.appStore.updateFlow({ ...payload, language });
      }
      this.appStore.getFlowTags({ flowName: this.agentName }).then((flowTagDocuments) => {
        const flowTags = flowTagDocuments?.map((document) => document.tag);
        if (!this.options?.includes(this.category) || !flowTags?.includes(this.category)) {
          this.appStore.updateTags({
            tag: this.category,
            flowId: this.flowObject.id,
            flowName: this.agentName
          });
        }
      });
      //Update state in store
      this.appStore.SET_CURRENT_FLOWS(newFlows);
      this.$emit('close-flow-properties-form');
    },
    upperCaseToSentenceCase(str) {
      //Status in DB is UPPERCASE but dropdown should display in sentence case as per Zeplin designs
      if (str === 'AE/PC') {
        return str;
      } else if (str === 'ONFS' || str === 'ONFs') {
        return 'ONFs';
      } else if (str === 'TESTED BY QA' || str === 'Tested by QA') {
        return 'Tested by QA';
      }
      const sentenceCaseStr = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      return sentenceCaseStr;
    }
  }
};
</script>

<style scoped>
.properties-card {
  border: 1px #e3e8ee solid;
  background: white;
  z-index: 2;
  position: absolute;
  right: 60px;
  width: 380px;
}
.main-title {
  font-family: Roboto;
  font-size: 17px;
  font-weight: bold;
  padding: 24px 30px;
  border-bottom: solid 1px #e3e8ee;
}
.content {
  display: flex;
  flex-direction: column;
  padding: 30px 30px 0 30px;
}
.content-each {
  padding-bottom: 30px;
}

.content-item {
  align-items: center;
  justify-content: space-between;
}
.column {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
}
.sub-title {
  font-size: 14px;
  font-weight: normal;
}
.text-grey {
  color: #8e9ba8;
  padding: 0 0 10px 0;
}
.text-dark-grey {
  color: #383f45;
}
.user-input {
  border-radius: 4px;
  border: solid 1px #e3e8ee;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  color: #383f45;
}

.flow-status-dropdown-container {
  width: 100%;
  padding: 0;
}
</style>
