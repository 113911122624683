<template>
  <div class="comment_edit_container">
    <div class="input_container" :class="isUpdating ? 'no_margin' : ''">
      <!-- <textarea :disabled="getPendingComment.pinned" ref="textArea" v-model="text" placeholder="Make a comment" :style="getPendingComment.pinned ? 'opacity: 0.7' : 'opacity: 1'" /> -->
      <CommentMentions :isEdit="isEdit" :editCommentText="commentText" @addToMentionedList="addToNotifyList" :label="'Comment'" :placeholder="'Make a comment'" :clearInputFlag="clearInputFlag" @populateCommentText="populateCommentText" :disabledText="getPendingComment.pinned" :users="sendUsers" />
      <draggable v-model="commentsArray" item-key="id" :group="{ name: 'comments', pull: 'clone', put: false }" :move="checkMove" :disabled="!this.commentText" :clone="createPinnedComment" ghostClass="ghost-hidden" class="commentPins" filter="input" :preventOnFilter="false" @end="onDragEnd" @change="log">
        <template #item="{ element }">
          <div v-if="showIcon" class="icon pin-icon" :parent="element">
            <ConversationBuilderIcon :width="20" :name="!commentText ? 'pin' : 'pin-hover'" :styleOnIcon="commentStyleOnIcon" />
          </div>
        </template>
      </draggable>
    </div>
    <div v-if="displayEmptyCommentError" style="color: red">A comment cannot contain ONLY spaces. Please enter a valid comment.</div>
    <div class="buttons_container" :style="isUpdating ? { padding: 0 } : {}">
      <button class="cancel_button" :disabled="!commentText && !isUpdating" @click="cancelComment" data-test="cancel-button">Cancel</button>
      <button class="send_button" :disabled="!commentText || displayEmptyCommentError" @click="saveComment" data-test="save-button">
        {{ isUpdating ? 'Save' : 'Send' }}
      </button>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import axios from 'axios';
import CommentMentions from './CommentMentions.vue';
import draggable from 'vuedraggable';
import { mapActions, mapState } from 'pinia';
import { droppedOnContainer, isContainerHandle, droppedOnValidComponent, isComponent } from '@/components/Utilities/dragging';
import _ from 'lodash';

export default {
  name: 'CommentEditText',
  components: {
    draggable,
    CommentMentions
  },
  props: {
    showIcon: {
      default: false
    },
    isUpdating: {
      default: false
    },
    startingText: {
      default: ''
    },
    index: {
      default: 0
    },
    id: {
      default: ''
    },
    parentCommentId: {
      default: null
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      commentText: '',
      userList: [],
      clearInputFlag: false,
      mentionedUsers: [],
      presentUsers: [],
      isEdit: false,
      commentsArray: [
        {
          id: 'default'
        }
      ],
      originalComment: {}
    };
  },
  computed: {
    ...mapState(useCommentsStore, ['getPendingComment', 'getComments', 'getMostRecentCommentId']),
    sendUsers() {
      if (this.userList.length === 0) {
        return [];
      }

      const tempValArr = _.sortBy(this.userList, ['displayName']).map((currEle) => {
        return { value: '@' + currEle.displayName, email: currEle.email };
      });

      return tempValArr;
    },
    dragOptions() {
      return {
        group: { name: 'comments', pull: 'clone', put: false },
        ghostClass: 'ghost-hidden',
        disabled: !this.commentText
      };
    },
    text: {
      get() {
        return this.commentText;
      },
      set(newValue) {
        const textArea = this.$refs.textArea;
        textArea.style.height = '1px';
        textArea.style.height = textArea.scrollHeight + 'px';
        this.commentText = newValue;
      }
    },
    displayEmptyCommentError() {
      //Display error message if the comment is ONLY spaces.
      if (this.commentText?.trim().length === 0 && this.commentText.length != 0) {
        return true;
      } else {
        return false;
      }
    },
    commentStyleOnIcon() {
      if (!this.commentText) {
        return { cursor: 'default' };
      } else {
        return { cursor: 'grab' };
      }
    }
  },
  methods: {
    ...mapActions(useCommentsStore, ['addComment', 'updateComment', 'addChildComment', 'updateChildComment', 'persistComments', 'PENDING_COMMENT', 'CLEAR_PENDING', 'ACCEPT_PENDING', 'ADD_COMMENT', 'UNPIN_COMMENT', 'SET_COMMENTS']),
    log: function (evt) {
      console.log(`Comment pinpoint:: Logging drag`, {
        event: evt,
        commentsArray: this.commentsArray
      });
    },
    checkMove(targetEvt) {
      // Only allow comment pinpoint drop on container or component
      // Later add logic to disallow 2nd comment on component or container
      const checkContainerHandle = isContainerHandle(targetEvt);
      const checkComponent = isComponent(targetEvt);
      if (checkContainerHandle || checkComponent) return true;
      else return false;
    },
    cancelComment() {
      if (this.isEdit) {
        this.$emit('removeEditComment');
      }
      this.clearInputFlag = true;
      this.commentText = '';
      this.mentionedUsers = [];
      const origComments = this.getComments.map((currComment) => {
        if (currComment.id === this.id) {
          currComment = this.originalComment;
        }
        return currComment;
      });
      this.SET_COMMENTS({ comments: _.cloneDeep(origComments) });
      this.commentsStore.CLEAR_PENDING();
    },
    populateCommentText(value) {
      this.commentText = value;
    },
    addToNotifyList({ value, email }) {
      this.mentionedUsers.push({ value, email });
    },
    async onDragEnd(evt) {
      try {
        const { item, originalEvent, to } = evt;
        const comment = await item['_underlying_vm_'];

        if (droppedOnContainer(originalEvent)) {
          const target = originalEvent?.target;
          const containerId = target?.getAttribute('data-containerId') || target?.getAttribute('data-containerid');

          delete comment.componentId;

          this.PENDING_COMMENT({
            comment: { ...comment, attachedTo: 'container', containerId }
          });
        } else if (droppedOnValidComponent(to)) {
          const componentId = to?.getAttribute('data-componentId');

          delete comment.containerId;

          this.PENDING_COMMENT({
            comment: { ...comment, attachedTo: 'component', componentId }
          });
        } else {
          this.UNPIN_COMMENT({ commentId: comment.id });
          this.CLEAR_PENDING();
        }
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    async createPinnedComment() {
      try {
        if (this.isUpdating) {
          const comment = _.cloneDeep(this.getComments.find((comment) => comment.id === this.id));

          if (comment) {
            comment.pinned = true;
          }

          return comment;
        }
        return await this.addComment({
          index: this.index,
          commentText: this.commentText,
          pinnedComment: true
        });
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    async saveComment(e) {
      this.mentionedUsers = this.mentionedUsers.filter((currEle) => {
        if (this.commentText.search(currEle.value) !== -1) {
          return true;
        }

        return false;
      });
      const comment = this.getComments.find((currComment) => {
        return currComment.id === this.id;
      });
      this.originalComment = _.cloneDeep(comment);
      try {
        e.preventDefault();
        if (this.parentCommentId != null) {
          //Updating child comment
          if (!this.isUpdating) {
            this.addChildComment({
              commentText: this.commentText,
              parentCommentId: this.parentCommentId,
              mentionedUsers: this.mentionedUsers,
              presentUsers: this.presentUsers,
              type: 'reply'
            });
          } else {
            this.updateChildComment({
              index: this.index,
              commentText: this.commentText,
              parentCommentId: this.parentCommentId,
              mentionedUsers: this.mentionedUsers,
              presentUsers: this.presentUsers,
              type: 'reply'
            });
          }
        } else {
          //Updating parent comment
          if (!this.isUpdating) {
            const comment = await this.addComment({
              commentText: this.commentText,
              pinnedComment: this.getPendingComment.pinned || false,
              attachedTo: this.getPendingComment.attachedTo,
              containerId: this.getPendingComment.containerId,
              componentId: this.getPendingComment.componentId,
              mentionedUsers: this.mentionedUsers,
              presentUsers: this.presentUsers,
              type: 'comment'
            });
            this.ADD_COMMENT({ comment });
          } else {
            this.updateComment({
              id: this.id,
              commentText: this.commentText,
              pinnedComment: _.isEmpty(this.getPendingComment) ? undefined : this.getPendingComment.pinned,
              attachedTo: this.getPendingComment.attachedTo,
              containerId: this.getPendingComment.containerId,
              componentId: this.getPendingComment.componentId,
              mentionedUsers: this.mentionedUsers,
              presentUsers: this.presentUsers,
              type: 'comment'
            });
          }
        }
        this.clearInputFlag = true;
        this.commentText = '';
        this.commentsStore.CLEAR_PENDING();
      } catch (err) {
        console.error('Error: ', err.message, err);
        this.clearInputFlag = true;
        this.commentText = '';
      }
      if (this.parentCommentId === null) {
        this.persistComments({
          mentionedUsers: this.mentionedUsers,
          presentUsers: this.presentUsers,
          type: 'comment'
        });
      }

      this.mentionedUsers = [];
      this.presentUsers = [];
      if (this.isEdit === true) {
        this.$emit('removeEditComment');
      }
    },

    async getPresentUsers() {
      const response = await axios.get(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/comments/getPresentUsers`);
      if (response.status === 200) this.presentUsers = response.data;
    }
  },
  created() {
    const comment = this.getComments.find((currComment) => {
      return currComment.id === this.id;
    });
    this.originalComment = _.cloneDeep(comment);
  },
  mounted() {
    this.userList = this.appStore.getAllUsers;
    this.getPresentUsers();
  },
  updated() {
    this.clearInputFlag = false;
  },
  beforeMount() {
    if (this.startingText !== '') {
      this.commentText = this.startingText;
      this.isEdit = true;
    }

    this.getPresentUsers();
  },
  beforeUnmount() {
    // remove all pinned comments which are not saved
    this.commentsStore.CLEAR_PENDING();
  }
};
</script>

<style lang="scss" scoped>
.comment_edit_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .input_container {
    width: 100%;
    height: auto;
    padding: 20px 20px 0 20px;
    position: relative;
    textarea {
      width: 100%;
      height: 38px;
      padding: 8px 32px 9px 10px;
      resize: none;
      border-radius: 4px;
      border: solid 1px #d7dde5;
      background-color: #fff;
      font-size: 16px;
      line-height: 1.29;
      text-align: left;
      overflow: hidden;
    }

    textarea::placeholder {
      color: #8e9ba8;
    }

    textarea:focus {
      border: solid 1px #2698ed;
    }

    .icon {
      width: 19px;
      height: 22px;
      position: absolute;
      top: 43px;
      right: 40px;
    }
  }

  .no_margin {
    margin-top: 0;
    padding: 0;
    .icon {
      top: 24px;
      right: 20px;
    }
  }

  .buttons_container {
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: flex-end;
    padding-right: 8%;
    margin-bottom: 20px;

    button {
      width: 93px;
      height: 38px;
      border-radius: 4px;
      background-color: #fff;
      cursor: pointer;
    }
    button:disabled {
      color: #8e9ba8;
    }
    .cancel_button {
      color: #1996f0;
    }
    .send_button {
      margin-left: 10px;
      background-color: #1996f0;
      color: #fff;
    }
    .send_button:disabled {
      background-color: #edf0f4;
    }
  }
}
</style>
