<template>
  <transition name="vs-sidebar-animate">
    <div data-html2canvas-ignore v-show="showSidebar" ref="sidebarbackground" class="vs-content-sidebar">
      <div v-if="!hiddenBackground" class="vs-sidebar--background"></div>
      <div
        ref="sidebarContainer"
        :class="[
          `vs-sidebar-${color}`,
          {
            'vs-sidebar-parent': parent != 'body',
            'vs-sidebar-staticPosition': staticPosition,
            'vs-sidebar-position-right': positionRight,
            'vs-sidebar-reduce': reduce,
            'vs-sidebar-reduceNotRebound': reduceNotRebound,
            'vs-sidebar-reduceNotHoverExpand': reduceNotHoverExpand,
            'vs-sidebar-showHeader': showHeader
          }
        ]"
        class="vs-sidebar"
      >
        <header v-if="$slots.header" class="vs-sidebar--header">
          <slot name="header"></slot>
        </header>

        <div class="vs-sidebar--items">
          <slot></slot>
        </div>

        <vs-spacer v-if="spacer"></vs-spacer>

        <footer v-if="$slots.footer" class="vs-sidebar--footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';
import { responseTypes } from '../../Utilities/constants/constants';
export default {
  name: 'NotesSidebar',
  props: {
    value: {
      default: false
    },
    color: {
      default: 'primary',
      type: String
    },
    parent: {
      default: null,
      type: [String, Object]
    },
    spacer: {
      default: false,
      type: Boolean
    },
    staticPosition: {
      default: false,
      type: Boolean
    },
    positionRight: {
      default: false,
      type: Boolean
    },
    reduce: {
      default: false,
      type: Boolean
    },
    reduceNotRebound: {
      default: false,
      type: Boolean
    },
    reduceNotHoverExpand: {
      default: false,
      type: Boolean
    },
    hiddenBackground: {
      default: false,
      type: Boolean
    },
    showHeader: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState(useAppStore, ['getContainer', 'activeContainer']),
    showSidebar() {
      if (this.value === 'comments') {
        return true;
      }
      const selectedContainer = this.activeContainer && this.getContainer(this.activeContainer);
      return this.value || selectedContainer?.type === responseTypes.START.type;
    }
  },
  mounted() {
    this.insertBody();
  },
  methods: {
    insertBody() {
      if (this.parent) {
        const elx = this.$refs.sidebarbackground;
        const parentx = typeof this.parent === 'string' ? document.querySelector(this.parent) : this.parent;
        try {
          if (parentx && parentx !== null) {
            parentx.insertBefore(elx, parentx.firstChild);
          }
        } catch {
          //
        }
      }
    }
  }
};
</script>

<style scoped>
@import './NotesSideBar.css';
</style>
