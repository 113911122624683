<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <div class="sidebar-text-input">
        <input class="ml-3" :id="componentSelected.id" v-model="currVal" :placeHolder="placeHolder" :readOnly="false" @keypress.enter="addAndUpdateReference" @focus="referenceButtonsVisible = true" @blur="referenceButtonsVisible = false" />
        <div v-if="referenceButtonsVisible" class="d-flex reference-buttons">
          <button class="mr-2" :class="{ 'is-active': currVal.trim() === '' }" @mousedown="addAndUpdateReference">Save</button>
        </div>
      </div>
    </div>
    <div v-if="references && references.length > 0" class="sidebar-reference-group">
      <div v-for="(arrayItem, index) in references" :key="index">
        <ReferenceEach :arrayItem="arrayItem" :index="index" @edit="editEvent" @delete="deleteEvent" @copyToClipboard="copyToClipboard" />
        <hr v-if="index !== 0" />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import ReferenceEach from './ReferenceEach.vue';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'ReferenceInput',
  components: {
    ReferenceEach
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      onHover: false,
      currVal: '',
      references: [],
      referenceButtonsVisible: false,
      clipBoard: '',
      isEdit: false,
      editIdx: null
    };
  },
  beforeMount() {
    navigator.clipboard.readText().then((clipText) => {
      if (clipText && clipText !== '') {
        this.clipBoard = clipText;
      }
    });
  },
  mounted() {
    this.references = cloneDeep(this.reference);
  },
  watch: {
    reference() {
      this.references = cloneDeep(this.reference);
    }
  },
  props: {
    id: {},
    field: {},
    placeHolder: {
      default: '+ Add Reference'
    },
    reference: {
      type: Array,
      default() {
        return [];
      }
    },
    mainComponentId: {}
  },
  methods: {
    addAndUpdateReference() {
      if (this.currVal.trim() !== '' && !this.isEdit) {
        this.references = cloneDeep(this.reference);
        this.references.unshift(this.currVal);
      } else {
        this.references[this.editIdx] = this.currVal;
      }
      if (this.mainComponentId && this.mainComponentId !== '') {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          reference: this.references,
          mainCarouselId: this.mainComponentId
        });
      } else {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          reference: this.references
        });
      }
      this.isEdit = false;
      this.editIdx = null;
      this.currVal = '';
    },
    deleteEvent(index) {
      this.references = cloneDeep(this.reference);
      this.references.splice(index, 1);
      if (this.mainComponentId && this.mainComponentId !== '') {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          reference: this.references,
          mainCarouselId: this.mainComponentId
        });
      } else {
        this.appStore.updateComponent({
          id: this.componentSelected.id,
          reference: this.references
        });
      }
    },
    editEvent(index) {
      this.isEdit = true;
      this.editIdx = index;
      this.currVal = this.references[this.editIdx];
    },
    mouseover() {
      this.onHover = true;
    },
    mouseleave() {
      this.onHover = false;
    },
    async copyToClipboard(value) {
      navigator.clipboard.writeText(value).then(
        () => {
          this.clipBoard = value;
          /* clipboard successfully set */
        },
        () => {
          /* clipboard write failed */
        }
      );
    }
  },
  computed: {
    text: {
      get() {
        if (this.mainComponentId && this.mainComponentId !== '') {
          const obj = this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId);
          return obj.carousels.find((x) => x.id === this.id)[this.field][this.appStore.language] || '';
        } else {
          return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id)[this.field][this.appStore.language] || '';
        }
      },
      set(value) {
        const array = [];
        array.push(value);
        if (this.mainComponentId && this.mainComponentId !== '') {
          this.appStore.updateComponent({
            reference: array,
            id: this.id,
            mainCarouselId: this.mainComponentId
          });
        } else {
          this.appStore.updateComponent({
            reference: array,
            id: this.id
          });
        }
      }
    },
    componentSelected() {
      if (this.mainComponentId && this.mainComponentId !== '') {
        const obj = this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId);
        return obj.carousels.find((x) => x.id === this.id);
      } else {
        return this.appStore.getComponentListFromComponents.find((x) => x?.id === this?.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin: 0px -2px 0 -2px;
}
.reference-add {
  align-content: center;
}

.button-row {
  padding: 5px;
}

.sidebar-reference-group {
  flex-direction: column-reverse;
  display: flex;
  padding: 0px 2px 0px 2px;
  border-radius: 3px;
  border: solid 1px #d7dde5;
  width: 100%;
  text-align: left;
}

// .sidebar-reference-group:hover {
//   border: solid 1px #2698ed;
// }

input {
  width: 100%;
  font-size: 12px;
}

.sidebar-reference-group::placeholder {
  font-style: italic;
  color: #8e9ba8;
}

.sidebar-text-input {
  display: flex;
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: solid 1px #d7dde5;
  width: 100%;
  text-align: left;
  height: 40px;
}

.sidebar-text-input:hover {
  border: solid 1px #2698ed;
}

input {
  width: 100%;
  font-size: 12px;
}

.sidebar-text-input::placeholder {
  font-style: italic;
  color: #8e9ba8;
}

.reference-buttons {
  button {
    background: rgb(235, 235, 235);
    border-radius: 3px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px 0px 2px 0px;
    font-size: 12px;
  }

  button:hover {
    background: white;
    border: #2698ed 1px solid;
  }

  button:active {
    background: #2698ed;
    color: white;
  }
}

.is-active {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #fff;
}
</style>
