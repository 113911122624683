import { defineStore } from 'pinia';
import axios from 'axios';
import { useAppStore, useVersionsStore } from '@/store/index.js';

export const useVcmStore = defineStore({
  id: 'vcm',

  state: () => ({
    paused: true,
    status: 'idle',
    connected: false
  }),

  getters: {
    getEditors: (state) => state.editors,
    currentStatus: (state) => state.status // Renamed to avoid conflict with the state
  },

  actions: {
    setConnected(connected) {
      this.connected = connected;
    },
    async createVersion() {
      // store adaptor is expected to handle this. Cf. "handles vcm/createVersion action"
    },
    async loadLatestDraft() {
      const appStore = useAppStore();
      const versionsStore = useVersionsStore();
      // Current draft loaded onto the canvas
      const currentDraftVersionId = versionsStore.currentVersion?.versionId || '';
      // Version shown when previewing
      const previewVersionId = versionsStore.previewVersion?.versionId || '';

      const shouldSkipGettingLatest = currentDraftVersionId?.toString() === previewVersionId?.toString();
      if (shouldSkipGettingLatest) {
        //Current version is the same as preview version, so no point in getting latest
        appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
        return;
      }

      const draft = await axios
        .post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/builder/getLatestVersion`, {
          slotId: appStore.current.slot.id,
          taxonomy: `${appStore.current.taxonomy}_flows`,
          draftOrPublished: 'draft',
          language: appStore.language
        })
        .then((response) => response.data)
        .catch((error) => {
          console.error(error);
          window.location.reload();
        });

      versionsStore.SET_CURRENT_VERSION(draft);
      versionsStore.loadDraft({ draft });
      appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
    },

    async forceSave() {
      // store adaptor is expected to handle this. Cf. "handles vcm/forceSave action"
    },

    setPaused(paused) {
      this.paused = paused;
    },
    async updateStatus(status) {
      this.status = status;
      console.log('[VCM]', { status });
    },

    clear() {
      this.$vcm?.clear();
    },

    undo() {
      this.$vcm?.undo();
    },

    redo() {
      this.$vcm?.redo();
    },

    canUndo() {
      return this.$vcm?.canUndo();
    },

    canRedo() {
      return this.$vcm?.canRedo();
    }
  }
});
