<template>
  <div class="flows-wrapper">
    <div class="flows-header">
      <AgentsSearch :agentName="agentName" :tab="tab" />
      <div class="flows-header-right">
        <div class="flows-search-bar">
          <input type="text" placeholder="Search Flow" v-model="searchFlow" />
          <img src="../../../assets/icons/search.svg" alt="" />
          <FilterDrawer v-if="showFilterModal" :currentStatusData="getFlowStatuses" @applyFilter="applyFilter" @closeFilterDrawer="closeFilterDrawer" />
        </div>
        <div class="flows-filter-bar" @keydown.esc="closeFilterDrawer">
          <button type="button" class="filter-button" @click="clickFilter">
            <img class="buttonIcon" width="18" src="../../../assets/icons/filter.svg" />
            <img class="buttonIconHover" width="18" src="../../../assets/icons/filter-active.svg" />

            Filter
            <span v-show="getFilteredFlowStatusesNumber > 0" class="selected-filter-number">{{ getFilteredFlowStatusesNumber }}</span>
          </button>
        </div>
        <div class="flows-bulk-actions">
          <!-- Try <component :is="currentBulkAction" /> -->
          <BulkActions :disabled="selectedFlowCount < 1" @performBulkUpdates="performBulkUpdates" />
        </div>
        <button
          @click="
            appStore.openDrawer({
              drawer: 'createFlow',
              taxonomy: getCurrentAgent(),
              metadata: { flows, agentName: getCurrentAgent() }
            })
          "
          type="button"
          class="btn btn-primary newFlowButton"
        >
          + New Flow
        </button>
      </div>
    </div>

    <div id="flow-content-loading" class="flows-content vs-con-loading__container">
      <div class="selected-flow-count" v-show="selectedFlowCount > 0">
        <span class="primary-highlight">{{ selectedFlowCount }}</span>
        &nbsp;Flows selected
      </div>
      <div class="flow-content-header">
        <div class="flex items-center flow-content-column" style="width: 8.9%" @click="setSorting('selected')">
          <div class="checkbox-container">
            <input class="input-checkbox" v-model="selected" @click.stop="toggleSelected" type="checkbox" />
          </div>
          <p>All Flows</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'selected' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <div class="flow-content-column" style="width: 26.1%" @click="setSorting('flows')">
          <p>Flows</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'flows' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <div class="flow-content-column" style="width: 17%" @click="setSorting('updated')">
          <p>Last Updated</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'updated' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <div class="flow-content-column" style="width: 13%" @click="setSorting('status')">
          <p>Flow Status</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'status' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <div v-if="isAdmin" class="flow-content-column" style="width: 10%" @click="setSorting('publishedStatus')">
          <p>Published Status</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'publishedStatus' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <!-- Replace above div if not admin, to maintain spacing/alignment -->
        <div v-if="!isAdmin" class="flow-content-column" style="width: 12%"></div>
        <div class="flow-content-column" style="width: 8%; justify-content: center">
          <p v-if="isAdmin" class="grid justify-items-center">Linked Intents</p>
        </div>
        <div class="flow-content-column" style="width: 11%" @click="setSorting('published')">
          <p>Published</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'published' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
        <div class="flow-content-column" style="width: 8%" @click="setSorting('watching')">
          <p>Watching</p>
          <ConversationBuilderIcon :width="20" :name-hover="'sort-active'" :name="`${this.sort === 'watching' ? 'sort-active' : 'sort'}`" :extension="'png'" />
        </div>
      </div>
      <FlowRow @flowDuplicated="flowDuplicated" @flowDeleted="flowDeleted" v-for="flow in flows" :key="flow.id + flow.selected" :flow="flow" :agentName="agentName" @set-watching="setWatching" :browserWidth="getBrowserWidth()" />
    </div>
    <AppSnackbar />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapActions, mapState } from 'pinia';
import ConversationBuilderIcon from '../../components/ConversationBuilder/Canvas/3.0/Icons/ConversationBuilderIcon.vue';
import BulkActions from './BulkActions.vue';
import FlowRow from './FlowRow.vue';
import cloneDeep from 'lodash/cloneDeep';
import vClickOutside from 'click-outside-vue3';
import FilterDrawer from '../../components/Drawer/FilterDrawer.vue';
import AgentsSearch from '../../components/Layout/3.0/AgentsSearch.vue';
import AppSnackbar from './AppSnackbar.vue';
import AgentFlowsBulkActionsModal from './AgentFlowsBulkActionsModal';
import { useModal, useModalSlot, VueFinalModal } from 'vue-final-modal';

export default {
  name: 'AgentFlows',
  components: {
    BulkActions,
    ConversationBuilderIcon,
    FlowRow,
    FilterDrawer,
    AgentsSearch,
    AppSnackbar
  },
  props: {
    agentName: String,
    tab: String
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      showDrawer: false,
      sort: 'flows',
      previousSort: '',
      searchFlow: '',
      searchAgent: '',
      showAgentDrawer: false,
      showFilterModal: false,
      currentBulkAction: ''
    };
  },
  async mounted() {
    this.$vs.loading({
      container: '#flow-content-loading'
    });
    await this.appStore.getAllFlows({
      collection: this.agentName,
      dropdown: false,
      store: true
    });
    //TODO: We need to allow entering flow view in an agent with no flows without creating 1:1 from intents. One option is just checking for the flows collection rather than getting flow docs. Or ideally this is only used in migration endpoints.
    // if (!flows?.length) {
    //   await this.appStore.createFlowCollectionFromTaxonomy({ collection: this.agentName });
    //   await this.appStore.getAllFlows({ collection: this.agentName, dropdown: false, store: true });
    // }
    if (this.$route?.params.id && this.$route?.query?.flow) {
      await this.loadFlow(this.appStore.taxonomies.find((i) => i.id === this.$route.params.id).name, this.$route.query.flow);
    }
    this.$vs.loading.close('#flow-content-loading > .con-vs-loading');
    this.appStore.closeSnackbar();
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies', 'getUserLess', 'currentFlows', 'isAdmin', 'getFilteredFlowStatusesNumber', 'getFlowStatuses']),
    selected() {
      // refactor to use reduce()
      const filteredFlowCount = this.flows.filter((flow) => flow.selected).length;
      return filteredFlowCount === this.flows.length;
    },
    selectedFlows() {
      const selectedFlows = this.currentFlows.filter((flow) => flow.selected);
      return selectedFlows;
    },
    selectedFlowCount() {
      return this.selectedFlows.length;
    },
    getTaxonomies() {
      return this.taxonomies
        .filter((item) => item.name.toLowerCase().includes(this.searchAgent.toLowerCase()))
        .sort((firstEl, secondEl) => {
          return firstEl.timestamp && secondEl.timestamp ? secondEl.timestamp._seconds - firstEl.timestamp._seconds : 1;
        });
    },
    flows() {
      const filters = this.appStore.getFlowStatuses;
      const statusFilters = filters.status
        .map((filter) => {
          if (filter.value) {
            return filter.name.toLowerCase();
          }
        })
        .filter((a) => a);
      const flows = this.currentFlows;
      return flows
        .sort((a, b) => {
          switch (this.sort) {
            case 'selected':
              return this.sortBoolean('selected', a, b);
            case 'flows':
              return this.previousSort === 'flows' ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name);
            case 'created':
              return this.previousSort === 'created' ? b.createdAt - a.createdAt : a.createdAt - b.createdAt;
            case 'updated':
              return this.previousSort === 'updated' ? b.updatedAt - a.updatedAt : a.updatedAt - b.updatedAt;
            case 'status':
              return this.previousSort === 'status' ? b.status.localeCompare(a.status) : a.status.localeCompare(b.status);
            case 'publishedStatus':
              return this.previousSort === 'publishedStatus' ? b.isActive - a.isActive : a.isActive - b.isActive;
            case 'published':
              return this.sortBoolean('published', a, b);
            case 'watching':
              return this.previousSort === 'watching' ? b.watching?.includes(this.getUserLess.email) - a.watching?.includes(this.getUserLess.email) : a.watching?.includes(this.getUserLess.email) - b.watching?.includes(this.getUserLess.email);
            default:
              break;
          }
        })
        .filter((flow) => flow.name.toLowerCase().includes(this.searchFlow.toLowerCase()) && (filters.watching ? flow.watching.includes(filters.email) : true) && (filters.published ? flow.published === filters.published : true) && (statusFilters.length > 0 ? statusFilters.includes(flow.status.toLowerCase()) : true));
    },
    selectedBulkActionType() {
      return this.currentBulkAction;
    },
    modalOptions() {
      const modelOverlayStyles = {
        'z-index': 5000
      };
      const modalContentStyles = {
        width: `${this.setModalWidthHeight().width}`,
        height: `${this.setModalWidthHeight().height}`,
        'max-width': `${this.setModalWidthHeight().maxImageWidth}`,
        'max-height': `${this.setModalWidthHeight().maxImageHeight}`,
        'pivot-y': 0.3,
        'z-index': 5001,
        position: 'absolute',
        left: '45%',
        top: '45%'
      };
      return {
        adaptive: true,
        teleportTo: 'body',
        modelValue: false,
        displayDirective: 'if',
        hideOverlay: false,
        overlayTransition: 'vfm-fade',
        contentTransition: 'vfm-fade',
        clickToClose: true,
        escToClose: true,
        background: 'non-interactive',
        lockScroll: true,
        swipeToClose: 'none',
        transition: 'slide-fade',
        fitParent: true,
        overlayStyle: { ...modelOverlayStyles },
        contentStyle: { ...modalContentStyles }
      };
    },
    commonModalAttributes() {
      return {
        name: 'agent-flows-bulk-actions-modal',
        adaptive: this.modalOptions.adaptive,
        teleportTo: this.modalOptions.teleportTo,
        modelValue: this.modalOptions.modelValue,
        displayDirective: this.modalOptions.displayDirective,
        hideOverlay: this.modalOptions.hideOverlay,
        overlayTransition: this.modalOptions.overlayTransition,
        contentTransition: this.modalOptions.contentTransition,
        clickToClose: this.modalOptions.clickToClose,
        escToClose: this.modalOptions.escToClose,
        background: this.modalOptions.background,
        lockScroll: this.modalOptions.lockScroll,
        swipeToClose: this.modalOptions.swipeToClose,
        transition: this.modalOptions.transition,
        fitParent: this.modalOptions.fitParent,
        overlayStyle: this.modalOptions.overlayStyle,
        contentStyle: this.modalOptions.contentStyle,
        overlayClass: 'grid justify-items-center items-center agent-flows-bulk-actions-modal',
        contentClass: 'grid justify-items-center items-center agent-flows-bulk-actions-modal-content bulk-actions-modal relative'
      };
    }
  },
  methods: {
    ...mapActions(useAppStore, ['updateSnackbar', 'closeSnackbar']),
    sortBoolean(key, a, b) {
      if (this.previousSort === key) {
        if (a[key] && !b[key]) {
          return 1;
        }
        if (!a[key] && b[key]) {
          return -1;
        }
        return 0;
      }
      if (a[key] && !b[key]) {
        return -1;
      }
      if (!a[key] && b[key]) {
        return 1;
      }
      return 0;
    },
    clickFilter() {
      this.showFilterModal = !this.showFilterModal;
    },
    applyFilter(data) {
      this.showFilterModal = false;
      this.appStore.updateFilterStatuses(data);
    },
    closeFilterDrawer() {
      this.showFilterModal = false;
    },
    toggleSelected() {
      this.selected ? this.selectAllFlows(false) : this.selectAllFlows(true);
    },
    selectAllFlows(isSelected) {
      const selectedFlows = this.currentFlows.map((currentFlow) => {
        this.flows.forEach((flow) => {
          if (flow.id === currentFlow.id) {
            currentFlow.selected = isSelected;
          }
        });
        return currentFlow;
      });
      this.appStore.updateSelectedFlows(selectedFlows);
    },
    async loadFlow(taxonomy, flowId) {
      try {
        const flowData = this.currentFlows.find((x) => x.id === flowId);
        this.$router.push({
          path: `/conversationBuilder/${taxonomy}/${flowId}/${flowData.flowName}`
        });
        return;
      } catch (err) {
        console.error('Error: ', err.message, err);
        return;
      }
    },
    performBulkUpdates(updateType) {
      let modalComponent = null;
      switch (updateType) {
        case 'Flow Status': {
          this.currentBulkAction = 'BulkStatusModal';
          modalComponent = this.createModal(this.currentBulkAction);
          console.log('updating bulk action modal component');
          break;
        }
        case 'Publish': {
          this.currentBulkAction = 'BulkPublishModal';
          modalComponent = this.createModal(this.currentBulkAction);
          console.log('open Publish modal');
          break;
        }
        case 'Delete':
          console.log('open Delete modal');
          break;
        case 'Duplicate':
          console.log('open Duplicate modal');
          break;
      }

      if (modalComponent) {
        modalComponent.open();
      }
    },
    createModal(actionType) {
      const modalInstance = useModal({
        component: VueFinalModal,
        attrs: this.commonModalAttributes,
        slots: {
          default: useModalSlot({
            component: AgentFlowsBulkActionsModal,
            attrs: {
              agentName: this.agentName,
              currentBulkAction: actionType,
              selectedFlows: this.selectedFlows,
              selectedFlowCount: this.selectedFlowCount,
              onClose: () => {
                modalInstance.close();
              }
            }
          })
        }
      });

      return modalInstance;
    },
    setSorting(val) {
      if (this.previousSort === this.sort) {
        this.previousSort = '';
      } else {
        this.previousSort = this.sort;
      }

      this.sort = val;
    },
    getBrowserWidth() {
      return Math.max(
        //eslint-disable-line
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      );
    },
    flowDeleted(flow) {
      const newFlows = this.flows.filter((currEle) => currEle.name !== flow.name);
      this.flows = newFlows;
    },
    async flowDuplicated(dupFlow) {
      this.flows.push(dupFlow);
    },
    getCurrentAgent() {
      return this.agentName;
    },
    setWatching(flowId) {
      const newFlows = cloneDeep(this.currentFlows);

      const flow = newFlows.find((flow) => flow.id === flowId);

      const userMail = this.getUserLess.email;

      if (!flow.watching) {
        flow.watching = [userMail];
      } else if (!flow.watching.includes(userMail)) {
        flow.watching.push(userMail);
      } else {
        flow.watching = flow.watching.filter((email) => email !== userMail);
      }

      this.appStore.updateFlow({
        agentName: this.agentName,
        flowId,
        published: flow.published,
        watching: flow.watching
      });

      this.appStore.SET_CURRENT_FLOWS(newFlows);
    },
    setModalWidthHeight() {
      const maxWidth = '90%';
      const maxHeight = '800px';
      let width = '300px';
      let height = '300px';

      if (window.innerWidth < 500) {
        width = '100%';
        height = '100%';
      }

      return { width, height, maxWidth, maxHeight };
    }
  }
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.selected-flow-count {
  display: flex;
  padding-left: 20px;
  font-weight: bold;
  .primary-highlight {
    color: #1996f0;
  }
}
.flows-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row wrap;
  row-gap: 10px;
  column-gap: 10px;
  > .v-btn {
    margin: 0 !important;
  }
}
.flows-wrapper {
  width: calc(100vw - 230px);
  height: 100%;
  display: flex;
  flex-direction: column;

  .flows-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 25px;

    .flow-content-header {
      width: 100%;
      min-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;
      font-weight: bold;

      .flow-content-column {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        p {
          font-size: 14px;
          font-weight: bold;
          line-height: 1.36;
          text-align: left;
          color: #383f45;
          margin: 0 5px 0 0;
        }
      }
    }
  }
  .flows-bulk-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .flows-header {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f6fa;
    padding: 0 40px;
    margin-bottom: 20px;

    .flows-search-bar {
      height: 48px;
      min-width: 200px;
      border-radius: 4px;
      border: solid 1px #8e9ba7;
      background-color: #fff;
      position: relative;
      margin-left: auto;

      input {
        width: 100%;
        height: 100%;
        padding-left: 15px;
        font-size: 14px;
        line-height: 3.07;
        text-align: left;
        color: #383f45;
      }

      input::placeholder {
        font-size: 14px;
        font-style: italic;
        line-height: 3.07;
        text-align: left;
        color: #8e9ba8;
      }

      img {
        position: absolute;
        top: 17px;
        right: 12px;
      }
    }

    button {
      min-width: 100px;
      height: 48px;
      border-radius: 4px;
      background-color: #fd5422;
      font-size: 14px;
      line-height: 1.36;
      text-align: center;
      color: #fff;
    }

    .filter-button {
      background-color: #fff;
      color: black;
      border-radius: 4px;
      border: solid 1px #8e9ba7;
      width: 130px;

      .buttonIcon {
        display: inline;
      }

      .selected-filter-number {
        position: absolute;
        max-width: 23px;
        height: 16px;
        padding: 0 5px;
        border-radius: 10px;
        background-color: #1996f0;
        margin-left: 5px;
        margin-top: 1px;
        color: white;
      }
    }

    .buttonIconHover {
      display: none;
    }

    .filter-button:hover {
      .buttonIcon {
        display: none;
      }
      .buttonIconHover {
        display: inline;
      }
    }

    .filter-button:active {
      border: solid 1px #1996f0;

      .buttonIcon {
        display: none;
      }
      .buttonIconHover {
        display: inline;
      }
    }
  }
}
</style>
