<template>
  <div>
    <div v-show="appStore.drawer === 'renameFlow'" class="overlay"></div>
    <DrawerMain id="createFlow" v-show="appStore.drawer === 'renameFlow'">
      <template v-slot:header>
        <PageHeader title="Rename Flow" subtitle="" />
      </template>
      <template v-slot:body>
        <div class="pageLayout">
          <div class="layoutTax">
            <label for="renameFlowName">New flow name </label><br />
            <input ref="input" class="display" type="text" id="renameFlowName" name="renameFlowName" :placeholder="placeholder" v-model="value" />
          </div>
          <div class="actionsOptions">
            <div class="flex items-center justify-center">
              <button @click="appStore.closeDrawer()" type="button" class="btn btn-cancel">Cancel</button>
              <button @click="verifyFlow()" type="button" class="btn btn-primary">Rename</button>
            </div>
          </div>
        </div>
      </template>
    </DrawerMain>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import DrawerMain from './DrawerMain.vue';
import axios from 'axios';

export default {
  name: 'RenameFlowDrawer',
  components: {
    DrawerMain
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      value: '',
      placeholder: 'Enter new name for the flow'
    };
  },
  methods: {
    async verifyFlow() {
      const value = this.value.toUpperCase();
      if (!value) {
        alert('Please enter a new name for the flow.');
        return;
      }
      if (/^[a-zA-Z0-9_-\s]+$/.test(value) === false) {
        this.$vs.notify({
          title: `Could not create this Slot titled :${value}`,
          text: 'Cannot use special characters except - and _',
          color: 'danger',
          time: 5000
        });
        return;
      }
      const flows = this.appStore.drawerMetadata.flows;
      if (flows.find((flow) => flow.name === value)) {
        alert('Flow with same name exists.');
        this.$refs['input'].select();
        return;
      }
      this.$vs.loading();
      const agentName = this.appStore.drawerMetadata.agentName;
      let response;
      try {
        const payload = {
          agentName,
          flowId: this.appStore.drawerMetadata.flow.id,
          newName: value
        };
        response = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/renameFlow`, payload);
        await this.appStore.getAllFlows({ collection: agentName, dropdown: false, store: true, reload: true });
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
      if (response && response.status === 200) {
        this.$message({
          showClose: true,
          message: 'Flow renamed successfully.',
          type: 'info'
        });
      } else {
        this.$message({
          showClose: true,
          message: 'Error renaming flow.',
          type: 'error'
        });
      }
      this.appStore.closeDrawer();
      this.value = '';
      this.$vs.loading.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.display {
  border-radius: 7px;
  width: 90%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1.5px solid rgb(167, 165, 165);
  /* -webkit-transition: 0.5s; */
  transition: 0.5s;
  outline: none;
}

.layoutTax {
  margin-left: 50px;
  margin-top: 40px;
}

label {
  font-weight: bold;
  color: rgb(135, 134, 134);
  font-size: 15px;
}
::-webkit-input-placeholder {
  color: rgb(135, 134, 134);
}
</style>
