<template>
  <div>
    <span class="numTab">
      {{ parseInt(pageNumber) + 1 }}
      <img
        v-if="parseInt(pageNumber) !== 0"
        class="image-close"
        src="../../../../../../assets/icons/close.svg"
        alt="Icon not found"
      />
    </span>
    <div v-for="(element, idx) in formData" :key="idx" class="collapsible">
      <p>
        <button class="btn btn-primary" type="button" data-bs-toggle="collapse">
          <span v-if="element.search(/textField/i) !== -1"
            ><img
              :class="{
                icon: true,
                selected: activeEleIndices.indexOf(idx) !== -1,
              }"
              :src="require('../../../../../../assets/icons/textField.svg')"
              alt="icon not found"
            />
            Text Field
            <img
              class="delete"
              :src="require('../../../../../../assets/icons/delete_icon.svg')"
            />
            <img
              :class="{
                arrow: true,
                selected: activeEleIndices.indexOf(idx) !== -1,
              }"
              :src="
                require('../../../../../../assets/icons/' +
                  (activeEleIndices.indexOf(idx) !== -1 ? 'down' : 'right') +
                  '_arrow.svg')
              "
              alt="image not found."
            />
          </span>
          <!-- <span v-if="element === 'customtextField'"><img :class="{ icon: true, selected: activeEleIndices.indexOf(idx) !== -1 }" :src="require('../../../../../../assets/icons/' + element.replace(/custom/i, '') + '.svg')" alt="icon not found" /> Customized Text Field <img :class="{ arrow: true, selected: activeEleIndices.indexOf(idx) !== -1 }" :src="require('../../../../../../assets/icons/' + (activeEleIndices.indexOf(idx) !== -1 ? 'down' : 'right') + '_arrow.svg')" alt="image not found." /></span> -->
          <span v-if="element.search(/email/i) !== -1"
            ><img
              :class="{
                icon: true,
                selected: activeEleIndices.indexOf(idx) !== -1,
              }"
              :src="require('../../../../../../assets/icons/email.svg')"
              alt="icon not found" />
            Email
            <img
              class="delete"
              :src="
                require('../../../../../../assets/icons/delete_icon.svg')
              " />
            <img
              :class="{
                arrow: true,
                selected: activeEleIndices.indexOf(idx) !== -1,
              }"
              :src="
                require('../../../../../../assets/icons/' +
                  (activeEleIndices.indexOf(idx) !== -1 ? 'down' : 'right') +
                  '_arrow.svg')
              "
              alt="image not found."
          /></span>
        </button>
      </p>
      <hr v-if="activeEleIndices.indexOf(idx) === -1" class="solid" />
      <div class="collapse">
        <TextField
          :textFieldData="data[element]"
          v-if="element.search(/textField/i) !== -1"
        />
        <CustomTextField v-if="element === 'customtextField'" />
        <Email
          :emailFieldData="data[element]"
          v-if="element.search(/email/i) !== -1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import TextField from '../SidebarComponents/FormElements/TextField.vue';
import Email from '../SidebarComponents/FormElements/EmailField.vue';
import CustomTextField from '../SidebarComponents/FormElements/CustomTextField.vue';
export default {
  name: 'FormCollapseContainer',
  props: {
    formData: {
      required: true,
      type: Object,
    },
    pageNumber: {
      type: String,
    },
    data: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
activeEleIndices: [],
    };
  },
  components: {
    TextField,
    CustomTextField,
    Email,
  },
};
</script>

<style scoped>
.collapsible {
  display: flex;
  flex-direction: column;
  margin-top: 12%;
}
.btn {
  background-color: white !important;
  border-color: white !important;
  color: black;
}

p {
  margin-bottom: 40px;
  width: 30%;
}

.icon {
  position: absolute;
  left: 0;
}

.arrow {
  position: absolute;
  right: 0;
}

.selected {
  filter: invert(48%) sepia(32%) saturate(3743%) hue-rotate(181deg)
    brightness(98%) contrast(92%);
}

.numTab {
  display: flex;
  width: 11%;
  margin-bottom: 10px;
  padding: 6px 10px 6px 10px;
  border-radius: 5px;
  background-color: #edf0f4;
  font-size: 12px;
  background-color: #1996f0;
  color: white;
  border-color: #1996f0;
}

.image-close {
  align-content: left;
  position: relative;
  margin-left: 40px;
  position: absolute;
  right: 0;
  filter: invert(93%) sepia(25%) saturate(123%) hue-rotate(179deg)
    brightness(97%) contrast(92%);
}
.image-close:hover {
  cursor: pointer;
}

hr.solid {
  border-top: 3px solid #d1d8e1;
}

.delete {
  position: absolute;
  right: 0;
  margin-right: 8%;
}
</style>
