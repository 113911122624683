<template>
  <div @mouseover="displayComments === true" @mouseout="displayComments === false" :class="['comment__handle', `comment__handle-${position}-connected`, 'nodrag']">
    <div v-if="comment" class="connector-point" @mouseover="displayComments = true" @mouseout="displayComments = false">
      <div v-if="pendingComment" class="connector-point">
        <div @click="goToPinpoint(pinned)" class="number" v-for="(pinned, index) in comment" :key="index" :class="{ hoverComments: displayComments }">
          <span>{{ pinned.pinnedCommentIndex }}</span>
        </div>
      </div>
      <div class="number" v-if="!pendingComment" :class="{ hoverComments: displayComments }">
        <span>{{ comment.pinnedCommentIndex }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useCommentsStore } from '@/store/index.js';
export default {
  data() {
    return {
      appStore: useAppStore(),
      commentsStore: useCommentsStore(),
      displayComments: false
    };
  },
  name: 'CommentPinpointContainer',
  props: {
    comment: {},
    position: {
      type: String,
      required: false,
      default: 'left'
    }
  },
  methods: {
    goToPinpoint({ id }) {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'comments' });
      this.commentsStore.goToComment({ id });
    }
  },
  computed: {
    pendingComment() {
      if (Array.isArray(this.comment)) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hoverComments {
  margin-right: -5px !important;
}
.connector-point {
  cursor: default;
  display: flex;
  flex-direction: row;
  direction: rtl;
}
.number {
  z-index: 999 !important ;
  width: 20px;
  height: 20px;
  background-color: #fd5422;
  border-radius: 10px;
  box-shadow: 1.5px 0.5px rgba(0, 0, 0, 0.3);
  white-space: normal;
  margin-right: -15px;
  font-size: 12px;
  line-height: 2;
  color: #fff;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.number:first-child {
  box-shadow: none;
}

.comment__handle {
  background: transparent;
  border: none;
  border-radius: 100%;
  position: absolute;
  width: 6px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.comment__handle-left {
  left: -10px;
  top: -5px;
  justify-content: center;
  z-index: 2;
}

.comment__handle-left-connected {
  left: -10px;
  top: -10px;
  z-index: 1;
}

* {
  font-family: 'Roboto', sans-serif !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
