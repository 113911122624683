<template>
  <div
    class="editor"
    :class="{
      editorMessage: type === 'message',
      editoMessage__readOnly: type === 'message' && readOnly
    }"
  >
    <div v-if="type === 'message'" class="suggestion-list" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults">
        <div v-for="(user, index) in filteredUsers" :key="user" class="suggestion-list__item" :class="{ 'is-selected': navigatedUserIndex === index }" @click="selectUser(user, $event)">
          {{ user.displayName ? user.displayName : 'N/A' }}
        </div>
      </template>
      <div v-else class="suggestion-list__item is-empty">No users found</div>
    </div>

    <!-- <editor-content v-if='type === "text" || type === "voice"' :class="`editor__content__bubble ${id} ${tabindex}`" :editor="editor" />
              <span style='font-size:8px;float:right' v-if='type === "voice" && textLength > 0'>{{textLength}} / 640</span>
             <ol type="i" v-if='type === "text" && linksCreated.length > 0' style='float:left'>
                      <li v-for='link in linksCreated' style='font-size:8px;color:red; text-align: left;'>{{link.toString()}}</li>
              </ol> -->

    <el-row>
      <editor-content @keyup.enter="$emit('send-message')" v-if="type === 'message' && !readOnly" class="editor__content__bubble__shorten__message" :editor="editor" />
    </el-row>
    <!-- <i  @click="$emit('send-message')" style='float: right;
      margin-right: 12px;
      margin-top: 4%;' v-if='type === "message" && !readOnly' class="fas fa-paper-plane"></i> -->

    <el-row>
      <div v-if="!readOnly" :editor="editor">
        <div class="menubar" :class="{ 'is-active': isActive }">
          <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(linkUrl)">
            <input class="menububble__input" type="text" v-model="linkUrl" placeholder="Paste Link" ref="linkInput" @keydown.esc="hideLinkMenu" />
            <button class="menububble__button" @click="setLinkUrl(editor.commands.toggleLink(), null)" type="button">Remove</button>
          </form>

          <form class="menububble__form" v-if="telephoneMenuIsActive" @submit.prevent="setTelephoneNumber(editor.commands.toggleTelephone(), phoneNumber)">
            <input class="menububble__input" type="text" v-model="phoneNumber" placeholder="Enter Phone #" ref="telephoneInput" @keydown.esc="hideTelephoneMenu" />
            <button class="menububble__button" @click="setTelephoneNumber(editor.commands.toggleTelephone(), null)" type="button">Remove</button>
          </form>

          <template v-if="!linkMenuIsActive && !telephoneMenuIsActive && !noteMenuIsActive">
            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('bold') }" @click="editor.chain().toggleBold().run()">
              <i class="fas fa-bold"></i>
            </button>
            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('italic') }" @click="editor.chain().focus().toggleItalic().run()">
              <i class="fas fa-italic"></i>
            </button>
            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('superscript') }" @click="editor.commands.toggleSuperscript()">
              <i class="fas fa-superscript"></i>
            </button>
            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('subscript') }" @click="editor.commands.toggleSubscript()">
              <i class="fas fa-subscript"></i>
            </button>

            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('underline') }" @click="editor.commands.toggleUnderline()">
              <i class="fas fa-underline"></i>
            </button>

            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('code')() }" @click="editor.commands.toggleCode()">
              <i class="fas fa-code"></i>
            </button>

            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('strike') }" @click="editor.chain().focus().toggleStrike().run()">
              <i class="fas fa-strikethrough"></i>
            </button>

            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('bulletList') }" @click="editor.commands.toggleBulletList()">
              <i class="fas fa-circle"></i>
            </button>

            <button class="menubar__button__menu" :class="{ 'is-active': editor.isActive('orderedList') }" @click="editor.commands.toggleOrderedList()">
              <i class="fas fa-list-ol"></i>
            </button>

            <button @click="$emit('send-message')" class="menubar__button__menu">
              <span style="background: #009bcf; border-radius: 5px; padding: 3px">SEND</span>
            </button>
          </template>
        </div>
      </div>
    </el-row>
    <editor-content v-if="type === 'message' && readOnly" class="editor__content__bubble__shorten__message__readOnly" :editor="editor" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { nextTick } from 'vue';
import { Editor, EditorContent } from '@tiptap/vue-3';
import { Superscript, Subscript, Highlight, HighlightSecondary, Link, Limit, Image, Note, Telephone, SayAsTelephone, Mention, CustomEnterKeyExtension } from '@/components/ConversationBuilder/Extensions/index.js';
import Fuse from 'fuse.js';
import tippy from 'tippy.js';
import { mapActions } from 'pinia';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Focus from '@tiptap/extension-focus';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Underline from '@tiptap/extension-underline';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import { lowlight } from 'lowlight';

import css from 'highlight.js/lib/languages/css';
import js from 'highlight.js/lib/languages/javascript';
import ts from 'highlight.js/lib/languages/typescript';
import html from 'highlight.js/lib/languages/xml';

lowlight.registerLanguage('html', html);
lowlight.registerLanguage('css', css);
lowlight.registerLanguage('js', js);
lowlight.registerLanguage('ts', ts);
export default {
  components: {
    EditorContent
  },
  props: {
    placeholder: {
      type: String,
      default: 'Leave Message'
    },
    tabindex: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    value: {
      type: String
    },
    table: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    collection: {
      required: false,
      type: String
    },
    nodes: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      editor: null,
      editorChange: false,
      linkUrl: null,
      sayAsPhrase: null,
      phoneNumber: null,
      note: '',
      linkMenuIsActive: false,
      telephoneMenuIsActive: false,
      noteMenuIsActive: false,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {},
      observer: null,
      users: useAppStore().getAllUsers,
      textLength: 0,
      linksCreated: []
    };
  },
  computed: {
    setLanguage() {
      return this.appStore.language;
    },
    placeHolderText() {
      if (this.table) {
        return '';
      } else if (this.type === 'note') {
        return 'Record Notes';
      } else {
        return this.setLanguage;
      }
    },
    voiceActive() {
      return this.appStore.voiceActive;
    },
    usersFiltered() {
      return this.users.filter((item) => {
        if (item.displayName) {
          return this.value.includes(item.displayName.replace(/\s/g, ''));
        }
      });
    },
    hasResults() {
      return this.filteredUsers.length;
    },
    showSuggestions() {
      return this.query || this.hasResults;
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: this.readOnly ? false : true,
      onFocus: ({ state }) => {
        this.textLength = state.doc.textContent.length;
      },
      onBlur: () => {
        this.textLength = 0;
      },
      onUpdate: ({ getHTML, transaction }) => {
        this.editorChange = true;
        this.$emit('input', getHTML());
        this.textLength = transaction.doc.textContent.length;
      },
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3]
          }
        }),
        Note,
        CustomEnterKeyExtension,
        Telephone,
        SayAsTelephone,
        Superscript(),
        Subscript(),
        Highlight,
        HighlightSecondary,
        Document,
        CodeBlockLowlight.configure({
          lowlight
        }),
        TaskItem,
        TaskList,
        Link,
        Image,
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Table.configure({
          resizable: true
        }),
        TableHeader,
        TableCell,
        TableRow,
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: true,
          showOnlyCurrent: false
        }),
        Limit.configure({
          limit: this.type != 'text' ? 1000000 : 640
        }),
        Mention.configure({
          // a list of all suggested items
          items: () => this.users,
          // is called when a suggestion starts
          onEnter: ({ items, query, range, command, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.renderPopup(virtualNode);
            // we save the command for inserting a selected mention
            // this allows us to call it inside of our custom popup
            // via keyboard navigation and on click
            this.insertMention = command;
          },
          // is called when a suggestion has changed
          onChange: ({ items, query, range, virtualNode }) => {
            this.query = query;
            this.filteredUsers = items;
            this.suggestionRange = range;
            this.navigatedUserIndex = 0;
            this.renderPopup(virtualNode);
          },
          // is called when a suggestion is cancelled
          onExit: () => {
            // reset all saved values
            this.query = null;
            this.filteredUsers = [];
            this.suggestionRange = null;
            this.navigatedUserIndex = 0;
            this.destroyPopup();
          },
          // is called on every keyDown event while a suggestion is active
          onKeyDown: ({ event }) => {
            // pressing up arrow
            if (event.keyCode === 38) {
              this.upHandler();
              return true;
            }
            // pressing down arrow
            if (event.keyCode === 40) {
              this.downHandler();
              return true;
            }
            // pressing enter
            if (event.keyCode === 13) {
              this.enterHandler(event);
              return true;
            }
            return false;
          },
          // is called when a suggestion has changed
          // this function is optional because there is basic filtering built-in
          // you can overwrite it if you prefer your own filtering
          // in this example we use fuse.js with support for fuzzy search
          onFilter: (items, query) => {
            if (!query) {
              return items;
            }
            const fuse = new Fuse(items, {
              threshold: 0.2,
              keys: ['displayName']
            });
            return fuse.search(query);
          }
        })
      ]
    });
  },
  methods: {
    ...mapActions(useAppStore, ['sendSceneToDB']),
    addRegistered() {
      const previousText = this.editor.view.state.doc.textContent;
      const newText = previousText + '®';
      this.editor.commands.setContent(newText, true);
      this.$clipboard('®');
      document.execCommand('paste');
    },
    showSelection(item, index) {
      const txtarea = document.getElementById(index);
      const start = txtarea.selectionStart;
      const finish = txtarea.selectionEnd;
      const allText = txtarea.value;
      const sel = allText.substring(start, finish);
      // console.log(txtarea);
      // console.log(sel);
      this.editor.commands.setContent(sel + '<sup></sup>', true);
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    showTelephoneMenu(attrs) {
      this.phoneNumber = attrs.href;
      this.telephoneMenuIsActive = true;
      nextTick(() => {
        this.$refs.telephoneInput.focus();
      });
    },
    showNoteMenu(attrs) {
      this.note = attrs.note;
      this.noteMenuIsActive = true;
      nextTick(() => {
        this.$refs.noteInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    hideTelephoneMenu() {
      this.phoneNumber = null;
      this.telephoneMenuIsActive = false;
    },
    hideNoteMenu() {
      this.noote = null;
      this.noteMenuIsActive = false;
    },
    setLinkUrl(url) {
      this.editor.commands.toggleLink({ href: url });
      this.hideLinkMenu();
    },
    setTelephoneNumber(command, number) {
      command({ href: 'tel:' + number });
      this.hideTelephoneMenu();
    },
    setNote(command, note) {
      // console.log(note);
      command({ note: note });
      this.hideNoteMenu();
    },
    upHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + this.filteredUsers.length - 1) % this.filteredUsers.length;
    },

    downHandler() {
      this.navigatedUserIndex = (this.navigatedUserIndex + 1) % this.filteredUsers.length;
    },
    enterHandler(event) {
      const user = this.filteredUsers[this.navigatedUserIndex];
      if (user) {
        this.selectUser(user, event);
      }
    },

    selectUser(user, e) {
      // console.log(e, 'event');
      e.preventDefault();
      e.stopPropagation();
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: user.uid,
          role: user.customClaims.role,
          name: user.displayName,
          label: user.displayName.replace(/\s/g, '')
        }
      });
      this.editor.commands.focus();
    },

    renderPopup(node) {
      this.destroyPopup();
      this.popup = tippy(node, {
        content: this.$refs.suggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        inertia: true,
        duration: [400, 200],
        showOnInit: true,
        arrow: true,
        arrowType: 'round'
      });
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate();
        });
        this.observer.observe(this.$refs.suggestions, {
          childList: true,
          subtree: true,
          characterData: true
        });
      }
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy();
        this.popup = null;
      }
      if (this.observer) {
        this.observer.disconnect();
      }
    }
  },
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) this.editor.destroy();
    } catch (err) {
      //
    }
  },
  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style>
/* say-as[interpret-as='telephone']:after,
a[phoneNumber='true']:after {
    font-family: "Font Awesome 5 Free";
    content: "\f095";
    font-weight: 900;
    font-size: 10px;
    vertical-align: super;
    font-size: 7px;
} */

a[target='_blank'] {
  text-decoration: underline;
}

/* 
highlightsecondary[note]::after {
    font-family: "Font Awesome 5 Free";
    content: "\f075";
    font-weight: 900;
    font-size: 12px;
    vertical-align: super;
    color:black;
} */

u {
  text-decoration: underline;
}

s {
  text-decoration: line-through;
  font-style: italic;
  text-decoration-color: black;
}

.editor__content table .column-resize-handle,
.column-resize-handle.ProseMirror-widget {
  position: absolute;
  right: -2px;
  top: 0;
  bottom: 0;
  width: 4px;
  z-index: 20;
  background-color: #adf;
  pointer-events: none;
}

.tableWrapper table {
  min-width: 340px;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}
</style>

<style scoped>
.editor__content__bubble:before {
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 2px;
  content: '';
  display: inline-block;
  height: 12px;
  left: 5px;
  position: absolute;
  width: 12px;
  background-size: cover;
}

.menububble__button {
  font-size: 10px;
}

.menububble__input::placeholder {
  color: lightgrey;
  font-size: 10px;
}

.tableOptions {
  font-size: 8px;
  font-weight: 300;
  text-decoration: underline;
}

.editor {
  position: relative;
  max-width: 30rem;
  margin: 1rem auto 0rem;
}

.editorMessage {
  position: relative;
  max-width: 100%;
  margin: 0rem auto 0rem;
  /* display: flex; */
  border-top: 1px solid lightgray;
}

.editoMessage__readOnly {
  border: none;
}

.menubar {
  margin-bottom: 0rem;
  -webkit-transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;
  background: black;
  padding-left: 8px;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.editor__content__bubble {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  font-size: 12px;
  margin-left: 10px;
  /* font-weight: bold; */
  color: rgb(0, 155, 207);
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 25px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 12px 10px 10px;
  cursor: text;
}

.editor__content__bubble__shorten__message {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 100%;
  height: 60px;
  overflow-y: scroll;
  font-size: 1.2em;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  /* border-top: 1px solid lightgray; */
  /* border: rgb(0, 155, 207); */
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
}

.editor__content__bubble__shorten__message__readOnly {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-size: 1.2em;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: none;
  -webkit-transition: none;
  transition: none;
  padding: 0px;
}

/* .editor__content__bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 10px;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: white;
	border-bottom: 0;
	border-left: 0;
	margin-left: -10px;
    margin-bottom: -5px;
} */

.editor__content__bubble__shorten__readOnly {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: inherit;
  width: 100%;
  font-size: 12px;
  /* font-weight: bold; */
  color: black;
  /* border: rgb(0, 155, 207); */
  border-radius: 25px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
}

.editor__content__table {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  width: 360px;
  max-width: 100%;
  font-size: 14px;
  /* font-weight: bold; */
  color: black;
  background-color: white;
  border: rgb(0, 155, 207);
  border-radius: 5px;
  -webkit-transition: none;
  transition: none;
  padding: 10px 15px 15px 15px;
  cursor: text;
}

.menubar__button {
  font-weight: 700;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: #000;
  padding: 0.2rem 0.5rem;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: pointer;
}

.menubar__button__menu {
  font-weight: 300;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: transparent;
  border: 0;
  color: white;
  padding: 8px;
  margin-right: 0.2rem;
  border-radius: 3px;
  cursor: grab;
}

.menubar.is-hidden {
  visibility: hidden;
  opacity: 0;
}

.menubar.is-focused {
  visibility: visible;
  opacity: 1;
}
</style>

<style>
.mention {
  background: #fb5422;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 15px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

.editor__content__table table td {
  cursor: col-resize;
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}

.editor__content__table table th {
  min-width: 1em;
  border: 2px solid #ddd;
  padding: 3px 5px;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
}

.editor__content__table table .selectedCell:after {
  z-index: 2;
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(200, 200, 255, 0.4);
  pointer-events: none;
}

.editor__content_bubble table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.is-empty.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}

.editor__content__bubble p {
  text-align: left;
}

/* .has-focus {
  border-radius: 3px;
    box-shadow: 0 0 0 3px black
} */
</style>

<style lang="scss" scoped>
.menububble {
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    display: flex;
    align-items: center;
  }
  &__input {
    font-size: 10px;
    border: none;
    background: transparent;
    color: white;
  }
}

.addNote {
  position: absolute;
  display: flex;
  z-index: 999999;
  background: black;
  border-radius: 30px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  margin-left: 40px;
  transform: translateX(-50%) translateY(-20px);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
  width: 200px;
  &.is-active {
    opacity: 1;
    visibility: visible;
  }
  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: #009bcf;
    }
    &.is-active {
      background-color: #009bcf;
    }
  }
  &__form {
    background: transparent;
    color: white;
  }
  &__input {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: inherit;
    color: white !important;
    border-radius: 25px;
    border-style: none;
    color: inherit;
  }
}

.mention-suggestion {
  color: black;
}

.suggestion-list {
  padding: 0.2rem; // border: 2px solid black;
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: white;
      color: #009bcf;
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}

.tippy-tooltip.dark-theme {
  background-color: black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: white;
  border-radius: 5px;
  .tippy-backdrop {
    display: none;
  }
  .tippy-roundarrow {
    fill: black;
  }
  .tippy-popper[x-placement^='top'] & .tippy-arrow {
    border-top-color: black;
  }
  .tippy-popper[x-placement^='bottom'] & .tippy-arrow {
    border-bottom-color: black;
  }
  .tippy-popper[x-placement^='left'] & .tippy-arrow {
    border-left-color: black;
  }
  .tippy-popper[x-placement^='right'] & .tippy-arrow {
    border-right-color: black;
  }
}
</style>
