import { Mark, mergeAttributes } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const SayAsTelephone = Mark.create({
  name: 'sayAsTelephone',

  addOptions: {
    openOnClick: false
  },

  attributes: {
    'interpret-as': {
      default: null
    }
  },

  parseHTML() {
    return [
      {
        tag: 'say-as[interpret-as]',
        getAttrs: (dom) => ({
          'interpret-as': dom.getAttribute('interpret-as')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'say-as',
      mergeAttributes({
        ...HTMLAttributes,
        'interpret-as': 'telephone'
      }),
      0
    ];
  },

  addCommands() {
    return {
      toggleSayAsTelephone:
        (attrs) =>
        ({ commands }) => {
          console.log(attrs, 'attributes sayas');

          if (attrs['interpret-as']) {
            return commands.updateMark('sayAsTelephone', attrs);
          }

          return commands.removeMark('sayAsTelephone');
        }
    };
  },

  addProseMirrorPlugins() {
    const plugins = [];

    if (this.options.openOnClick) {
      const onClickPlugin = new Plugin({
        props: {
          handleClick: (/*view, pos, event */) => {
            const attrs = this.editor.getAttributes('sayAsTelephone');
            console.log(attrs);

            // if (attrs['interpret-as'] && event.target instanceof HTMLAnchorElement) {
            //   event.stopPropagation();
            //   window.open(attrs['interpret-as']);
            // }
          }
        }
      });

      plugins.push(onClickPlugin);
    }

    return plugins;
  }
});
