const libraryDefaults = [
  {
    text: 'MESSAGE',
    icon: 'message',
    displayText: 'Message',
    firstComponentType: 'message',
    type: 'MESSAGE',
    header: 'Content',
    altText: 'Message'
  },
  {
    text: 'CAROUSEL',
    icon: 'carousel',
    displayText: 'Carousel / Card',
    firstComponentType: 'carousel',
    type: 'CAROUSEL',
    header: 'Content',
    altText: 'Carousel'
  },
  {
    text: 'DECISION',
    displayText: 'Decision',
    firstComponentType: 'decision',
    routing: true,
    type: 'DECISION',
    header: 'Routing',
    altText: 'Decision'
  },
  // {
  //   text: 'CLARIFICATION',
  //   displayText: 'Clarification',
  //   firstComponentType: 'clarification',
  //   routing: true,
  //   type: 'CLARIFICATION',
  //   header: 'Routing',
  //   altText: 'Clarification'
  // },
  {
    text: 'FLOW',
    displayText: 'Link to Flow',
    firstComponentType: 'flow',
    routing: true,
    type: 'FLOW',
    header: 'Actions',
    altText: 'Flow'
  },
  {
    text: 'LINK URL',
    icon: 'linkUrl',
    displayText: 'Link URL',
    firstComponentType: 'linkUrl',
    type: 'LINK URL',
    header: 'Actions',
    altText: 'Link URL'
  },
  {
    text: 'LINK TO CONTAINER',
    icon: 'linkToContainer',
    firstComponentType: 'linkToContainer',
    routing: true,
    type: 'LINK TO CONTAINER',
    header: 'Actions',
    altText: 'Link to Container',
    displayText: 'Link to Container'
  }
  // {
  //   text: 'FORM',
  //   icon: 'form',
  //   displayText: 'Form',
  //   firstComponentType: 'form',
  //   type: 'form',
  //   header: 'User Input',
  //   altText: 'Form'
  // }
];

const routingComponents = function () {
  const routing = libraryDefaults.filter((x) => x.routing).map((z) => z.text.toUpperCase().replace(/\s/g, ''));
  return routing;
};

function getTypeOptions(type) {
  type = type.trim().toLowerCase();

  const find = libraryDefaults.find((x) => {
    return x.type.toLowerCase().replace(/\s/g, '') === type;
  });
  return find;
}
const languageTypes = ['GB', 'FR', 'ES', 'JP', 'IT'];

//
const SCENE_MUTATIONS = ['setComponentId', 'RESTORE_FLOW', 'SET_SELECTED_EDGE', 'SET_TARGET_NODE', 'SET_SOURCE_NODE', 'SET_LANGUAGE', 'setActiveContainer', 'SET_SLOTS', 'SET_SCENE'];
const CONVERSATION_MUTATIONS = ['SET_IS_EDITING_CONTAINER_ID', 'ADD_SELECTED_CONTAINER', 'REMOVE_SELECTED_CONTAINER', 'SET_ORIGINAL_NODE_POSITIONS', 'SET_EDGE_TYPE', 'ADD_EDGE', 'PRINT_FLOW', 'editContainer', 'moveComponent', 'removeComponent', 'removeContainer', 'updateContainerResponses', 'addContainer', 'addComponent', 'removeLinkUrlAdvancedData', 'updateQueryParameter', 'addItemToCollection', 'removeItemFromCollection', 'calculateOptionLinks', 'removeOutgoingLinks', 'updateOption', 'convertToOptionContainer', 'moveDecisionOption', 'updateComponent', 'setActiveScene', 'updateLinks', 'UPDATE_SCENE', 'UPDATE_FORM_CONTAINER'];
const MUTATION_TYPES_TO_WATCH = [...SCENE_MUTATIONS, ...CONVERSATION_MUTATIONS];

const responseTypes = {
  START: {
    title: 'Start Here',
    type: 'start',
    timestamp: Date.now()
  },
  CONTAINER: {
    title: '',
    type: 'message',
    text: '',
    properties: {
      color: 'default',
      position: []
    },
    timeResponse: 0,
    reference: '',
    verbatim: ''
  },
  LINKTOCONTAINER: {
    type: 'linkToContainer',
    linkToContainer: ''
  },
  SET: {
    type: 'set',
    value: {
      en: ''
    },
    label: {
      en: ''
    }
  },
  LINKURL: {
    type: 'linkUrl',
    url: '',
    label: '',
    viewType: 'basic',
    advancedViewType: '',
    subpath: '',
    queryParameters: [
      {
        name: '',
        value: ''
      }
    ]
  },
  QUERY_PARAMETER: {
    name: '',
    value: ''
  },
  CAROUSEL: {
    type: 'carousel',
    title: '',
    subTitle: '',
    imageSizeSelection: 'auto',
    imageWidth: '',
    imageHeight: '',
    imageAltText: '',
    imageUrl: '',
    linkTo: {
      type: '',
      data: {}
    },
    cardCta: '',
    reference: [],
    carousels: []
  },
  MESSAGE: {
    title: '',
    type: 'message',
    text: '',
    properties: {
      color: 'default',
      position: []
    },
    timeResponse: 0,
    reference: []
  },
  DECISION: {
    title: '',
    index: 0,
    type: 'decision',
    position: 'right',
    style: 'suggestions',
    dropdownText: '',
    variable: {
      name: 'decision',
      type: 'string'
    },
    options: [
      {
        index: 0,
        name: '',
        label: '',
        text: '',
        intent: {
          id: '',
          name: ''
        }
      }
    ]
  },
  CLARIFICATION: {
    title: '',
    index: 0,
    type: 'clarification',
    position: 'right',
    style: 'suggestions',
    dropdownText: '',
    variable: {
      name: 'clarification',
      type: 'string'
    },
    options: []
  },
  OPTION: {
    index: 0,
    name: '',
    label: '',
    text: '',
    intent: {
      id: '',
      name: ''
    }
  },
  properties: {
    color: 'default',
    position: []
  },
  FLOW: {
    title: '',
    type: 'flow',
    text: '',
    taxonomy: {
      id: '',
      name: ''
    },
    intent: {
      id: '',
      name: ''
    }
  },
  FORM: {
    title: {
      en: ''
    },
    type: 'form',
    text: {
      en: ''
    },
    properties: {
      color: 'default',
      position: []
    },
    timeResponse: 0,
    reference: {
      en: '',
      fr: ''
    },
    verbatim: {
      en: '',
      fr: ''
    }
  }
};

//ONFS vs ONFs
//Missing In Review
const STATUS_COLORS = {
  APPROVED: 'blue',
  'TESTED BY QA': 'green',
  'MISSING CONTENT': 'purple',
  DISABLED: 'orange',
  'IN PROGRESS': 'salmon',
  NONE: 'grey',
  'CLIENT REVIEWED': 'brown',
  'READY FOR REVIEW': 'red',
  'AE/PC': 'teal',
  ONFS: '#ff1493',
  IMPERSONATION: '#00bfff'
};

const getComponentObject = function (type) {
  return JSON.parse(JSON.stringify(responseTypes[type.trim().replace(/\s/g, '').toUpperCase()]));
};

const flowSchema = {
  type: 'object',
  properties: {
    flow: { type: 'object' },
    uxState: { type: 'object' },
    comments: { type: 'array' }
  },
  required: ['uxState'],
  additionalProperties: false
};

const FILE_TIMESTAMP_FORMAT = 'YYYY-MM-DDTHHmmss';

export { flowSchema, getTypeOptions, getComponentObject, libraryDefaults, routingComponents, responseTypes, languageTypes, STATUS_COLORS, MUTATION_TYPES_TO_WATCH, FILE_TIMESTAMP_FORMAT };
