<template>
  <ul :class="{ opened: opened }" class="export-sub-menu">
    <li
      :id="item.title"
      @click="
        item.click();
        closeParent();
      "
      v-for="(item, i) in items.filter((x) => typeof x === 'object')"
      :key="i"
    >
      <span>{{ item.title }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ExportDropdown',

  data() {
    return {};
  },
  methods: {},
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
    },
    closeParent: {
      type: Function,
    },
  },
};
</script>

<style scoped>
li > .caption {
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
}
.export-sub-menu {
  display: none;
  font-size: 14px;
  list-style-type: none;
  padding-left: 0;
}

li.opened {
  display: block;
}

ul.export-sub-menu {
  display: none;
}

ul.opened.export-sub-menu {
  display: block;
}
.export-sub-menu li {
  width: 100%;
  height: max-content;
  cursor: pointer;
  line-height: 10px;
  padding: 7px 3px 7px 3px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}

.export-sub-menu li span {
  margin-left: 4px;
}

.export-sub-menu li:hover {
  background: #e8f5fe;
}
.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  margin-top: 3px;
}
.label {
  background: #fd5422;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 21px;
  display: none;
  margin-right: 10px;
}
.export-sub-menu li:hover .label {
  display: block;
}
</style>
