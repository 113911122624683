<template>
  <transition name="slide">
    <div style="padding: 25px; height: 100vh; background: white; margin-top: 80px">
      <div class="grid justify-items-center"></div>
      <div class="centered">
        <vs-list>
          <!-- <vs-list-header style='color:black' icon="fas fa-comment" icon-pack='fas' title="SLOTS"></vs-list-header> -->

          <div style="margin-right: 20px; width: 100%">
            <!-- <input style='border:none !important; float:right; width:100px; margin-right:25px;' placeholder="Search" v-model="search"/> -->
            <div :class="{ page: page }" class="grid justify-items-center slotWrapper">
              <el-radio-group @change="onChange(filter)" v-model="filter" size="small">
                <el-radio-button border label="1">BATCH 1</el-radio-button>
                <el-radio-button border label="2">BATCH 2</el-radio-button>
                <el-radio-button border label="3">BATCH 3</el-radio-button>
                <el-radio-button border label="4">BATCH 4</el-radio-button>
                <!-- <el-radio-button v-if='!isEditor' border label="DISABLED">DISABLED <i class="fas fa-eye-slash"></i></el-radio-button> -->
                <el-radio-button v-if="isEditor" border label="NONE">NONE</el-radio-button>
                <!-- <el-radio-button v-if='!isEditor' border label="TESTED BY QA">TESTED BY QA <i class="fas fa-user-edit"></i></el-radio-button> -->
                <!-- <el-radio-button v-if='!isEditor' border label="MISSING CONTENT">MISSING CONTENT <i class="fas fa-exclamation-triangle"></i></el-radio-button> -->
                <!-- <el-radio-button v-if='!isEditor' border label="DISABLED">DISABLED <i class="fas fa-eye-slash"></i></el-radio-button> -->
                <el-radio-button v-if="isEditor" border label="AE/PC">AE/PC</el-radio-button>
                <el-radio-button v-if="isEditor" border label="IN PROGRESS">IN PROGRESS</el-radio-button>
                <el-radio-button border label="READY FOR REVIEW">READY FOR REVIEW <i class="fas fa-binoculars"></i></el-radio-button>
                <el-radio-button border label="CLIENT REVIEWED">CLIENT REVIEWED <i class="far fa-thumbs-up"></i> </el-radio-button>
                <el-radio-button border label="APPROVED">APPROVED <i class="fas fa-check"> </i> </el-radio-button>
              </el-radio-group>
            </div>
          </div>
          <br />
          <div style="overflow-y: auto !important; height: 80vh !important">
            <div class="grid justify-items-center">
              <span v-if="filteredSlots.length === 0">NO DATA</span>
            </div>
            <vs-list-item @click="goToSlot(item)" color="#009bcf" style="font-size: 12px" :key="item.id" v-for="item in filteredSlots" :title="item.name">
              <vs-button style="border-radius: 5px !important; box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26)" color="#fb5422"><i class="fas fa-paper-plane"></i></vs-button>
            </vs-list-item>
          </div>
        </vs-list>
      </div>
    </div>
  </transition>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';
import _ from 'lodash';
export default {
  name: 'AgentIntents',
  props: {
    collection: {
      type: String,
      required: true
    },
    page: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      vcmStore: useVcmStore(),
      search: '',
      filter: '',
      loadRouterTimeout: null
    };
  },
  mounted() {
    this.filter = this.appStore.isEditor || this.appStore.getClientEditAccess ? this.appStore.lastFilterClient : this.appStore.lastFilter;
    this.appStore.setSlots({ collection: this.collection });
    // Need to push this to hotfix for client taxonomy;
  },
  methods: {
    goToSlot(slot) {
      const id = this.$route.params.id;
      this.$router.push('/changeSlot');
      this.loadRouterTimeout = setTimeout(() => {
        this.$router.replace({
          path: `/taxonomy/${id}`,
          query: {
            product: this.collection,
            slot: slot.impersonate && slot.impersonate != 'NONE' ? slot.impersonate : slot.name,
            builder: 'yes'
          }
        });
      }, 500);
    },
    onChange(filter) {
      if (this.isEditor) {
        this.appStore.setLastFilterClient(filter);
      } else {
        this.appStore.setLastFilter(filter);
      }
    }
  },
  beforeUnmount() {
    clearTimeout(this.loadRouterTimeout);
  },
  computed: {
    ...mapState(useAppStore, ['isEditor', 'getClientEditAccess', 'getSlots']),
    filteredSlots() {
      // let statuses = [
      //         'CLIENT REVIEWED', 'READY FOR REVIEW',
      //         'APPROVED',
      //         'TESTED BY QA',
      //         'MISSING CONTENT',
      //         "DISABLED",
      //         "IN PROGRESS",
      //         'NONE'
      //     ]

      return _.sortBy(this.appStore.getSlots, 'name').filter((data) => {
        if (!Object.prototype.hasOwnProperty.call(data, 'name')) {
          data.name = 'n/a';
        }

        if (!Object.prototype.hasOwnProperty.call(data, 'status')) {
          data.status = data.conversationID;
        }

        if (['1', '2', '3', '4', '5'].includes(this.filter)) {
          return data.batch === this.filter;
        }

        return data.status.includes(this.filter);
      });
      // return !this.search || data.color.toLowerCase().includes(this.search.toLowerCase()) || data.name.toLowerCase().includes(this.search.toLowerCase())
    }
  }
};
</script>

<style lang="scss" scoped>
.ssmlWrapper {
  transform: translate(1.01);
}

.ssmlWrapper.page {
  transform: translate(1.5);
}

::placeholder {
  color: white; // text-align: right;
}

.vs-list--item:hover {
  background: lightgray !important;
}
</style>
