<template>
  <div class="sidebar_comments-container">
    <CommentEditText :id="commentIdForEdit" v-if="!getCommentFilters.resolved" :showIcon="true" />
    <hr v-if="!getCommentFilters.resolved" />
    <div v-show="comments_ && comments_.length === 0 && !getCommentFilters.resolved" class="empty-comments-wrapper">
      <img src="./../../../../../../assets/icons/empty_comments.svg" class="empty-comments-image" alt="empty-comments" />
      <span class="empty-comments-text-1">No comments</span>
      <span class="empty-comments-text-2">Give feedback, ask questions, or start a discussion by commenting</span>
    </div>
    <div v-show="comments_ && comments_.length === 0 && getCommentFilters.resolved" class="empty-comments-wrapper">
      <img src="./../../../../../../assets/icons/empty_resolved.svg" class="empty-comments-image" alt="empty-comments" />
      <span class="empty-comments-text-1">No resolved comments</span>
      <span class="empty-comments-text-2">There are no resolved comments at this time.</span>
    </div>
    <div class="comments_container">
      <CommentsSidebarEach @editCommentId="handleCommentEditId" v-for="(comment, index) in comments_" :key="index" :comment="comment" :index="index" :resolvedView="getCommentFilters.resolved" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import CommentsSidebarEach from './CommentsSidebarEach.vue';
import CommentEditText from './CommentEditText.vue';

export default {
  name: 'CommentsSidebar',
  components: {
    CommentsSidebarEach,
    CommentEditText
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      forceRecomputeCounter: 0,
      updateInterval: undefined,
      commentIdForEdit: ''
    };
  },
  methods: {
    handleCommentEditId({ id }) {
      this.commentIdForEdit = id;
    }
  },
  computed: {
    ...mapState(useCommentsStore, ['getComments', 'getCommentFilters']),
    comments_() {
      let comments = this.getComments;
      if (!this.getCommentFilters.resolved) {
        comments = this.getComments.filter((c) => !c.resolved);
      } else {
        comments = this.getComments.filter((c) => c.resolved);
      }
      let pinnedIndex = 0;
      const pinnedCommentFilter = comments.map((comment) => {
        if (comment.childComments) {
          comment.childComments = comment.childComments.sort((a, b) => {
            return a.createdAt - b.createdAt;
          });
        }
        if (comment['pinned']) {
          pinnedIndex += 1;
          return { ...comment, pinnedCommentIndex: pinnedIndex };
        } else {
          return { ...comment };
        }
      });

      return pinnedCommentFilter;
    }
  },
  updated() {
    this.commentIdForEdit = '';
  }
};
</script>

<style lang="scss" scoped>
.sidebar_comments-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 63px;

  hr {
    border: solid 1px #d7dde5;
    margin: 0;
  }

  .comments_container {
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    padding-bottom: 25px;
  }
}
.empty-comments-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 300px;
  align-items: center;
}
.empty-comments-image {
  width: 125px;
  height: 160px;
}
.empty-comments-text-1 {
  font-size: 17px;
  text-align: center;
  color: #56616a;
  margin-top: 20px;
  font-weight: 800;
}
.empty-comments-text-2 {
  font-size: 17px;
  text-align: center;
  color: #8e9ba8;
  margin-top: 20px;
}
</style>
