<template>
  <div class="row clarification">
    <div class="col-12">
      <div class="row option-container">
        <div class="col-2">
          <img class="referenceIcon" width="17" src="./../../../../../../assets/icons/clarification.svg" />
        </div>
        <div class="col-10 text-container">
          <span>Clarification</span>
        </div>
      </div>
      <hr v-if="clarificationOptions && clarificationOptions.length > 0" class="divider" />
      <div v-if="clarificationOptions && clarificationOptions.length > 0">
        <div class="decision-container" v-for="(option, index) in clarificationOptions" :key="index" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
          <div class="row option-container">
            <div class="col-2"></div>
            <div class="col-10 text-container one-option">
              <span>{{ option.text[`${language}`] }}</span>
            </div>
          </div>
          <hr v-if="index !== clarificationOptions.length - 1" class="divider" />
          <NodeConnector :hide="false" :isConnected="isConnected(index)" :isSource="true" :isContainer="false" :componentId="`${id}`" :optionIndex="index" :data-nodeType="`decision`" />
          <NodeConnector :hide="!isHovering && !isLinking" :isConnected="isConnected(index)" :isSource="true" :isContainer="false" :componentId="`${id}`" :optionIndex="index" :data-nodeType="`decision`" @linking-stop="linkingStop" @linking-start="decideLinking(index, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import NodeConnector from './NodeConnector.vue';
import essentials from '../Mixins/essentials';
import { mapState } from 'pinia';

export default {
  name: 'ClarificationComponent',
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      isHovering: false
    };
  },
  mixins: [essentials],
  components: {
    NodeConnector
  },
  props: {
    componentId: {
      type: String
    },
    index: {},
    isLinking: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(useAppStore, ['canvasDisabled'])
  },
  methods: {
    isConnected(decisionIndex) {
      return this.appStore.getScene.links.some((link) => link?.isFromOption && link?.from === this.id && link?.optionIndex === decisionIndex);
    },
    decideLinking(decisionIndex, evt) {
      const isLinked = this.appStore.getScene.links.find((link) => link.isFromOption && link.from === this.id && link.optionIndex === decisionIndex);
      if (isLinked === undefined && !this.canvasDisabled) {
        evt.isFromOption = true;
        evt.optionIndex = decisionIndex;
        this.linkingStart(evt);
      }
    },
    outputMouseDown(e) {
      e.event.preventDefault();
      this.$emit('linking-start', e);
    },
    linkingStart(payload) {
      payload.containerTitle = this.titleValue;
      this.$emit('node-selected', {
        id: this.id,
        event: payload.event,
        drag: true
      });
      this.$emit('linking-start', payload);
    },
    linkingStop(e) {
      this.$emit('linking-stop', e);
    },
    handleMouseOver() {
      if (this.canvasDisabled) {
        return;
      }
      this.isHovering = true;
    },
    handleMouseLeave() {
      try {
        this.isHovering = false;
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './Decision.scss';
.clarification {
  padding: 8px 0px 8px 0px;
}
.decision-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .option-container {
    display: flex;
    align-items: center;
  }

  .divider {
    width: 89%;
    align-self: center;
    margin-top: 11.5px;
    margin-bottom: 10.5px;
  }
}
.text-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 1px;
  font-weight: 800;
}
.one-option {
  margin-top: 5px;
}
</style>
