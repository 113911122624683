<template>
  <div id="container-fluid" class="container-fluid">
    <PageHeader title="Agents" />

    <div class="px-5 pt-3" style="margin-top: 80px">
      <div style="width: 100%">
        <div class="row">
          <div class="col mb-1">
            <div class="input-group search-input">
              <input v-model="filterTaxonomy" class="form-control py-2 border-0" type="search" placeholder="Search agents..." />
              <span class="input-group-append">
                <div class="input-group-text border-0">
                  <i class="fa fa-search"></i>
                </div>
              </span>
            </div>
          </div>
          <div v-if="appStore.getClaims.admin" class="col text-right">
            <button @click="verifyTaxonomy" type="button" class="btn btn-primary add-agent">+ Add new agent</button>
          </div>
        </div>
      </div>

      <transition name="slide-fade">
        <div class="cards">
          <TaxonomyCard
            :key="index"
            v-for="(item, index) in taxonomies
              .filter((item) => item.name.toLowerCase().includes(filterTaxonomy.toLowerCase()))
              .sort((firstEl, secondEl) => {
                return firstEl.timestamp && secondEl.timestamp ? secondEl.timestamp._seconds - firstEl.timestamp._seconds : 1;
              })"
            :item="item"
          />
        </div>
      </transition>
      <br />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import 'material-icons/iconfont/material-icons.css';
import TaxonomyCard from '@/components/Layout/TaxonomyCard.vue';
import axios from 'axios';

export default {
  name: 'AppTaxonomies',
  data() {
    return {
      filterTaxonomy: ''
    };
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    autoSaveStore() {
      return useAutoSaveStore();
    },
    commentsStore() {
      return useCommentsStore();
    },
    versionsStore() {
      return useVersionsStore();
    },
    historyStore() {
      return useHistoryStore();
    },
    notificationsStore() {
      return useNotificationsStore();
    },
    vcmStore() {
      return useVcmStore();
    },
    taxonomies() {
      return this.appStore.taxonomies;
    }
  },
  methods: {
    async verifyTaxonomy() {
      let value = prompt('Please enter new Agent name', '');
      if (value === null) return;
      if (!value)
        return this.$message({
          showClose: true,
          message: 'Please provide a name',
          type: 'error'
        });

      value = value.toUpperCase();

      try {
        const r = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/transfer/verify`, {
          collection: value,
          idToken: this.appStore.getClaims.token
        });
        if (r.status === 200 && !r.data.exists) {
          this.createNewTaxonomy(value);
        } else {
          this.$message({
            showClose: true,
            message: 'Unable to create this agent. This agent name already exists',
            type: 'error'
          });
        }
      } catch (err) {
        this.$message({
          showClose: true,
          message: 'Error occurred while verifying agent',
          type: 'error'
        });
      }
    },
    async createNewTaxonomy(value) {
      const r = confirm(`Are you sure you would like to create a new agent : ${value}. You will not be able to change the name of the agent after it has been created`);
      if (r === true) {
        try {
          const x = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/transfer/addAgent`, {
            idToken: this.appStore.getClaims.token,
            name: value.trim()
          });
          if (x.status === 200) {
            const y = await axios.post(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/transfer/taxonomy/create`, {
              idToken: this.appStore.getClaims.token,
              collection: value.trim(),
              user: this.appStore.user.email
            });
            if (y.status === 200) {
              await this.appStore.getTaxonomies();
            } else {
              this.$message({
                showClose: true,
                message: 'Unable to create this agent',
                type: 'error'
              });
            }
          }
        } catch (err) {
          this.$message({
            showClose: true,
            message: 'Unable to create this agent',
            type: 'error'
          });
        }
      }
    }
  },
  mounted() {
    // if current flows exist, set them to an empty array so the previous state can't be shown while loading new flows
    if (this.appStore.currentFlows.length > 0) {
      this.appStore.clearCurrentFlows([]);
    }

    if (this.taxonomies.length === 0) {
      this.appStore.getTaxonomies();
    }
  },
  components: {
    TaxonomyCard
  }
};
</script>

<style scoped>
.container-fluid {
  background: #edf0f4;
  padding: 0 0 250px 0 !important;
}
.search-input {
  width: 360px;
  height: 48px;
  max-width: 100%;
  border: 1px solid transparent;
}
.search-input:hover {
  border-color: #fd5422;
}

.search-input input {
  height: 46px;
  outline: none !important;
  box-shadow: none !important;
}
.search-input .input-group-text {
  background: #fff;
  color: #8e9ba7;
  width: 100%;
  height: 100%;
}
.cards {
  width: 100%;
}

.add-agent {
  color: #fff;
}
</style>
