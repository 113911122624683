<template>
  <v-snackbar v-model="snackbar" class="toast-message" :bottom="y === 'bottom'" :left="x === 'left'" :multi-line="mode === 'multi-line'" :right="x === 'right'" :timeout="timeout" :top="y === 'top'" :vertical="mode === 'vertical'">
    {{ snackbarText }}
    <v-btn color="orange" class="toast-message-close" density="compact" variant="text" @click="closeSnackbar">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import { mapState } from 'pinia';
export default {
  name: 'AppSnackbar',
  computed: {
    ...mapState(useAppStore, ['snackbar', 'snackbarText'])
  },
  methods: {
    closeSnackbar() {
      this.appStore.closeSnackbar();
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      y: 'bottom',
      x: null,
      mode: 'multiline',
      timeout: 0
    };
  }
};
</script>

<style scoped>
.toast-message-close {
  float: right;
}

.v-snackbar__content {
  display: flex;
  align-items: center;
}
</style>
