<template>
  <div class="entity-wrapper">
    <el-tag class="entity-name mx-1" effect="plain" size="large">@{{ entityInfo.name.toUpperCase() }}</el-tag>
    <el-button class="save-btn" @click="SendtoDB" size="default">SAVE</el-button>

    <el-table @row-contextmenu="rowClickCopy" class="entity-table" stripe :data="filteredEntities">
      <el-table-column align="left" label="Values" prop="name" width="150">
        <template #default="scope">
          <el-input :disabled="true" @keyup.down="passTo(scope.row.name, entityInfo)" @keyup.enter="addEntity" placeholder="Enter Value" size="large" v-model="scope.row.name"> </el-input>
        </template>
      </el-table-column>
      <el-table-column prop="synonyms" label="Synonyms" :width="560">
        <template #default="scope">
          <div class="tag-group my-2 flex flex-wrap gap-1 items-center">
            <el-tag :key="i" :color="entityInfo.highlightColor" v-for="i in scope.row.synonyms" closable @close="handleClose(i, scope.$index)" class="mx-1" effect="plain"> {{ i }}</el-tag>
            <el-input class="input-new-tag" v-model="inputValue" size="small" placeholder="Synonym" @keyup.enter="handleInputConfirm(inputValue, scope.$index)"> </el-input>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { db } from '../../main';
import firebase from 'firebase/compat/app';

export default {
  name: 'EntityPhrases',
  props: {
    collection: {
      type: String,
      required: true
    },

    entityInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      entities: [],
      entities_id: '',
      search: '',
      inputVisible: false,
      inputValue: '',
      last_addition_timestamp: ''
    };
  },
  watch: {
    entities: function () {}
  },
  mounted() {
    this.currentUser();
    this.getEntities();
  },
  methods: {
    getEntities() {
      const ref = db.collection(this.collection).doc('EntityDoc').collection('EntityColl').doc(this.entityInfo.id).collection('values').orderBy('timestamp', 'desc').limit(1);

      ref
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.addDefaultEntity();
          } else {
            const doc = querySnapshot.docs[0];
            this.entities = doc.data().entities;
            this.entities_id = doc.id;
            this.last_addition_timestamp = doc.data()?.timestamp?.toDate()?.toLocaleString();
          }
        })
        .catch((err) => {
          console.error('Error: ', err.message, err);
        });
    },
    addDefaultEntity() {
      db.collection(this.collection)
        .doc('EntityDoc')
        .collection('EntityColl')
        .doc(this.entityInfo.id)
        .collection('values')
        .add({
          entities: [{ name: 'Enter Value', synonyms: [] }],
          user: this.email,
          timestamp: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then((docRef) => {
          this.entities_id = docRef.id;
          console.log('Entity Does not exist: ', this.entities);
        });
    },
    rowClickCopy(e) {
      this.passTo(e.name, this.entityInfo);
    },
    passTo(value, entityInfo) {
      this.$emit('copyref', { value: value, entityInfo: entityInfo });
    },
    deleteEntry(index) {
      this.entities.splice(index, 1);
    },

    handleClose(tag, index) {
      this.entities[index].synonyms.splice(this.entities[index].synonyms.indexOf(tag), 1);
    },
    handleInputConfirm(new_synonym, index) {
      if (this.entities[index].synonyms.includes(new_synonym)) {
        this.$vs.notify({
          title: 'Synonym has already been added',
          text: 'Cannot add synonym',
          color: 'danger',
          time: 3000
        });
        return;
      }
      this.entities[index].synonyms.push(new_synonym);
      this.inputValue = '';
    },
    currentUser() {
      const user = firebase.auth().currentUser;
      if (user != null) {
        this.email = user.email;
      } else {
        this.fullname = '';
        this.email = '';
      }
    },
    SendtoDB() {
      let counter = 0;

      this.entities.forEach((item) => {
        if (/^[a-zA-Z0-9,_-\s]+[^\s]$/.test(item.name) === false) {
          this.$vs.notify({
            title: 'Unable to save.',
            text: 'Inappropriate character/space in Entity Value',
            color: 'danger',
            time: 3000
          });
          counter++;
        }
      });

      if (counter < 1) {
        db.collection(this.collection)
          .doc('EntityDoc')
          .collection('EntityColl')
          .doc(this.entityInfo.id)
          .collection('values')
          .add({
            entities: this.entities,
            user: this.email,
            timestamp: firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            this.$vs.notify({
              title: 'Entities saved' + '    ' + "     <i class='fas fa-save'></i>",
              text: 'Successfully saved to database',
              color: 'dark',
              time: 3000
            });
          });
      }
    },
    removeEntity() {
      this.entities.pop();
    },
    addEntity() {
      this.entities.push({
        name: '',
        synonyms: []
      });
    },
    addEntityName(index) {
      this.entities[index].push({
        name: 'Placeholder',
        value: 'Placeholder',
        synonyms: ['Placeholder']
      });
    }
  },
  computed: {
    filteredEntities() {
      return this.entities.filter((data) => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()));
    }
  }
};
</script>

<style scoped>
.entity-wrapper {
  padding: 20px;
}

.entity-name {
  margin-left: 20px;
  font-size: 25px;
  background: black;
  color: white;
}

.entity-name span {
  color: white;
}

.save-btn {
  float: right;
  background: black;
  color: white;
  margin-right: 10px;
  margin-bottom: 20px;
}

.entity-table {
  width: 100%;
  padding: 10px;
  overflow: hidden;
  max-height: 400px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-table {
  overflow: scroll;
  font-size: 10px;
  border-radius: 15px;
}

.el-table td,
.el-table th {
  padding: 0px;
}

.el-table__empty-text {
  display: none;
}

.el-tag {
  padding: 0 5px;
  font-size: 25px;
  background: black;
  color: white;
}

.el-tag .el-tag__content {
  padding: 0 5px;
  font-size: 25px;
  background: black;
  color: white;
}

.el-tag .el-tag__close {
  color: #000;
}

.entity.table {
  background-color: white;
  width: 73%;
  height: 96%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  /* padding: 30px; */
}

.vs-table-text {
  color: dodgerblue;
}

.vs-table-text {
  font-size: 12px;
}

.vs-table--content {
  width: 100%;
  overflow: scroll;
  height: 91%;
}

.vs-con-table {
  background: white;
}

.vs-component.vs-con-table.vs-table-primary {
  width: 99vh;
  padding: 20px;
  /* border: 1px solid lightgray; */
  border-radius: 30px;
  /* background: mediumseagreen; */
}
</style>
