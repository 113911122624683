<template>
  <div>
    <div class="sidebar components flow">
      <div class="row editor-header">
        <div class="col-11">
          <span class="sidebar type"> Link to Agent </span>
          <ConversationBuilderIcon v-if="false" name="info" :styleOnIcon="{ marginTop: '-3px' }" />
        </div>
      </div>
      <div style="height: 50px">
        <v-select append-to-body @input="getTaxonomyFlowsOnTaxonomyChange" class="taxonomy-dropdown" :clearable="false" v-model="taxonomySelected" placeholder="Search Agent" :reduce="(taxonomies) => taxonomies" label="name" :options="taxonomies" filterable autocomplete>
          <template #selected-option="{ name }">
            <span>{{ formatName(name) }}</span>
          </template>
        </v-select>
      </div>
    </div>
    <div class="sidebar components flow">
      <div class="row editor-header">
        <div class="col-11">
          <span class="sidebar type">Link to Flow</span>
          <ConversationBuilderIcon v-if="false" name="info" :styleOnIcon="{ marginTop: '-3px' }" />
        </div>
      </div>
      <div style="height: 50px">
        <v-select @open="getTaxonomyFlowsOnOpen" class="intent-dropdown" :clearable="false" v-model="flowSelected" placeholder="Link" :items="flows" label="name" :options="flows" :loading="flowsLoading" filterable autocomplete>
          <template #selected-option="{ name }">
            <span>{{ formatName(name) }}</span>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';

export default {
  name: 'FlowEdit',
  methods: {
    formatName(name) {
      if (name && name.length > 50) {
        return name.substring(0, 50) + '...';
      }

      return name;
    },
    async getTaxonomyFlowsOnOpen() {
      if (this.currentTaxonomy != this.taxonomySelected?.name) {
        this.flowsLoading = true;
        const flows = await this.appStore.getAllFlows({ collection: this.taxonomySelected.name, dropdown: this.componentKey != 0 });
        this.flowsLoading = false;
        this.componentKey += 1;
        this.flows = [{ name: 'None', id: 0 }].concat(flows);
        this.currentTaxonomy = this.taxonomySelected?.name;
      }
    },
    async getTaxonomyFlowsOnTaxonomyChange(val) {
      this.flowsLoading = true;
      const flows = await this.appStore.getAllFlows({ collection: val?.name, dropdown: this.componentKey != 0 });
      this.flowsLoading = false;
      this.flows = [{ name: 'None', id: 0 }].concat(flows);
      this.componentKey += 1;
      this.currentTaxonomy = val?.name;
    },
    editFlowIntent(intent) {
      this.appStore.updateComponent({ intent, id: this.id });
    },
    editFlowTaxonomy(taxonomy) {
      this.appStore.updateComponent({ taxonomy, id: this.id });
    },
    editFlowType(payload) {
      this.appStore.updateComponent({ ...payload, id: this.id });
      this.componentKey += 1;
    },
    getDefault(field) {
      if (field === 'taxonomy') return this.appStore.taxonomies.find((x) => x.name === this.appStore.currentTaxonomy);
      else return '';
    }
  },
  computed: {
    ...mapState(useAppStore, ['components', 'componentList', 'current']),
    componentSelected() {
      if (this.mainComponentId && this.mainComponentId !== '') {
        const obj = this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId);
        return obj.carousels.find((x) => x.id === this.id);
      } else {
        return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id);
      }
    },
    taxonomySelected: {
      get() {
        if (this.components[this.id] && typeof this.components[this.id]['taxonomy'] === 'string') {
          return this.components[this.id]['taxonomy'];
        } else if (this.components[this.id] && typeof this.components[this.id]['taxonomy'] === 'object') {
          if (this.components[this.id]['taxonomy']?.name === 'None' || this.components[this.id]['taxonomy']?.name === '') {
            return this.getDefault('taxonomy');
          } else {
            return this.components[this.id]['taxonomy'];
          }
        } else {
          return this.getDefault('taxonomy');
        }
      },
      set(value) {
        const obj = { ['taxonomy']: value };
        if (this.mainComponentId && this.mainComponentId !== '') {
          obj['mainCarouselId'] = this.mainComponentId;
        }
        this.appStore.updateComponent({ ...obj, id: this.id });
      }
    },
    flowSelected: {
      get() {
        const intentObj = this.components[this.id]?.intent;
        if (typeof intentObj === 'string') {
          return intentObj;
        } else if (typeof intentObj === 'object') {
          if (intentObj.name?.toLowerCase() === 'none') return '';
          else {
            const flowId = intentObj?.id;
            const flow = this.flows.length > 0 && flowId ? this.flows.find((x) => x.id === flowId) : {};

            let flowObj;
            if (flow?.id && flow?.name) {
              flowObj = { id: flow?.id, name: flow?.name };
            } else {
              flowObj = this.components[this.id]['intent'];
            }
            return flowObj;
          }
        } else {
          return this.getDefault('intent');
        }
      },
      set(value) {
        const obj = { ['intent']: value };
        if (this.mainComponentId && this.mainComponentId !== '') {
          obj['mainCarouselId'] = this.mainComponentId;
        }
        if (this.components[this.id]?.['taxonomy']?.name === '') {
          obj.taxonomy = this.getDefault('taxonomy');
        }
        this.appStore.updateComponent({ ...obj, id: this.id });
      }
    }
  },
  props: {
    mainComponentId: {},
    id: {},
    text: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  async mounted() {
    this.taxonomies = this.appStore.taxonomies;

    if (this.currentTaxonomy != this.taxonomySelected?.name) {
      await this.getTaxonomyFlowsOnOpen();
    } else {
      this.flows = [{ name: 'None', id: 0 }].concat(this.appStore.currentFlows);
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      componentKey: 0,
      taxonomies: [],
      flows: [],
      currentTaxonomy: useAppStore().currentTaxonomy,
      flowsLoading: false
    };
  },
  beforeUnmount() {
    this.taxonomies = [];
    this.flows = [];
  }
};
</script>

<style scoped>
.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}
.sidebar.components {
  border-bottom: 1px solid #e3e8ee;
  padding: 15px 15px 5px 15px;
  height: max-content;
}

.sidebar.components.flow {
  /* border-bottom: 1px solid #e3e8ee; */
  border-bottom: none;
  padding: 15px 15px 5px 15px;
  height: 75px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
  font-weight: 700;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

textarea,
option,
select {
  font-size: 12px;
}

textarea::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
