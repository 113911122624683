<template>
  <div class="switch-container">
    <span :class="`boolean-value ${isOnOrOff}`">{{ isOnOrOff }}</span>
    <label class="switch">
      <input
        type="checkbox"
        :checked="parentDataToBindTo"
        @input="updateParentData"
      />
      <span class="slider round"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BooleanToggle',
  props: {
    parentDataToBindTo: {
      type: Boolean,
      default: false,
    },
    parentDataToUpdate: {
      type: String,
    },
  },
  computed: {
    isOnOrOff() {
      return this.parentDataToBindTo ? 'on' : 'off';
    },
  },
  methods: {
    updateParentData(event) {
      this.$emit('updateParentData', { key: this.parentDataToUpdate, value: event.target.checked });
    }
  },
};
</script>

<style lang="scss" scoped>
.switch-container {
  height: 100%;
  display: flex;
  align-items: center;
}
.boolean-value {
  text-transform: uppercase;
  padding-right: 10px;
  &.off {
    color: #8e9ba8;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e3e8ee;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 0;
  bottom: 0;
  background-color: #8e9ba8;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e3e8ee;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: #1996f0;
}

.slider.round {
  border-radius: 18px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
