<template>
  <div data-app class="cmp-form-categorization-select">
    <v-combobox
      :model-value="select"
      @update:modelValue="updateParentData($event)"
      :items="options"
      hide-selected
      :menu-props="{ auto: true }"
      variant="underlined"
      density="comfortable"
      class="ch-flow-list-category-select"
    >
      <template v-slot:selection="data">
        <v-chip closable @click:close="remove($event)">
          {{ data.item.title }}
        </v-chip>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
export default {
  name: 'TagsInput',
  props: {
    selectedTag: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      appStore: useAppStore(),
      select: this.selectedTag,
    };
  },
  methods: {
    remove(event) {
      event.stopPropagation();
      this.updateParentData('None');
    },
    updateParentData(value) {
      this.select = value;
      this.$emit('updateParentData', { key: 'category', value: value });
    },
  },
  watch: {
    selectedTag(newVal) {
      this.select = newVal;
    }
  }
};
</script>


<style lang="scss" scoped>
.cmp-form-categorization-select {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: stretch;
  width: 100%;
  .v-select__slot {
    max-width: 225px;
    overflow: hidden;
  }
}
.v-menu__content--auto {
  top: 360px !important;
  left: 20px !important;
}

.v-text-field {
  padding-top: 0;
}
.user-input {
  border-radius: 4px;
  border: solid 1px #e3e8ee;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 17px;
  color: #383f45;
  margin-right: 0.5em;
}

.ch-flow-list-category-select {
  width: 100%;
  text-align: left;
}

.v-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: stretch;
}

.v-list-item__content {
  width: 100%;
}

.ch-chip {
  border-radius: 2px !important;
  background-color: #f6f8fa !important;
  width: 100%;
  text-align: left;
}
.ch-chip-delete {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background-color: #edf0f4;
  text-align: center;
  span {
    color: #56616a;
  }
}
.ch-chip-delete:hover {
  cursor: pointer;
  background-color: #56616a;
  text-align: center;

  span {
    color: #ffffff;
    font-weight: bold;
  }
}
.ch-chip-wrapper {
  display: flex;
  align-items: stretch;
}
</style>
