<template>
  <span @mouseover="handleMouseOver" @mouseleave="handleMouseLeave" class="connector" :style="customStyle">
    <img class="icon" :class="{ infoIcon: name === 'info' }" :style="customIconStyle" :width="width" :height="height" :src="svg" />
    <slot name="popover"></slot>
  </span>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'ConversationBuilderIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    nameDisabled: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      required: true
    },
    nameHover: {
      type: String,
      required: false
    },
    width: {
      default: 15,
      required: false
    },
    height: {
      required: false
    },
    customStyle: {
      type: Object,
      required: false
    },
    styleOnIcon: {},
    extension: {
      type: String,
      required: false,
      default: 'svg'
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      isHovering: false
    };
  },
  computed: {
    svg() {
      let name = this.name;

      if (this.nameHover && this.isHovering) {
        name = this.nameHover;
      }

      if (this.disabled && this.nameDisabled) {
        name = this.nameDisabled;
      }

      return require(`../../../../../../assets/icons/${name}.${this.extension}`);
    },
    customIconStyle() {
      let widthValue, heightValue;

      if (typeof this.width === 'number') {
        widthValue = `${this.width}px`;
      } else {
        widthValue = this.width;
      }

      if (this.width > 15) {
        heightValue = widthValue;
      } else if (typeof this.height === 'number') {
        heightValue = `${this.height}px`;
      } else {
        heightValue = this.height ? this.height : this.width;
      }

      return {
        ...this.styleOnIcon,
        width: widthValue,
        height: heightValue
      };
    }
  },
  methods: {
    handleMouseOver() {
      this.isHovering = true;
    },
    handleMouseLeave() {
      this.isHovering = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon {
  cursor: pointer;
}
.infoIcon {
  margin-left: 7px;
}
</style>
