<template>
  <div>
    <slot name="header"></slot>
    <div style="height: 50px">
      <div :class="computedClass">
        <span v-if="prefix" class="sidebar-text-input prefix-box prefix">{{ prefix }}</span>
        <input :id="componentSelected.id" v-model="text" :placeHolder="placeHolder" :readOnly="false" @keypress="isNumber($event)" />
        <span v-if="imageUpload">
          <span class="upload-button" @click="$refs.file.click()">Upload</span>
          <input type="file" ref="file" style="display: none" accept="image/*" @change="previewImage" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore } from '@/store/index.js';
import { storage } from '@/main';
// import debounce from 'lodash/debounce';
import _ from 'lodash';

export default {
  props: {
    id: {},
    field: {},
    placeHolder: {},
    prefix: {},
    mainComponentId: {},
    imageUpload: {},
    number: {}
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore()
    };
  },
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      if (this.number === 'true') {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },
    update(e) {
      const obj = {};
      obj[this.field] = e.target.value;
      this.appStore.updateComponent({ ...obj, id: this.id });
    },
    updateComponent(e) {
      this.update(e);
    },
    // debouncedUpdate: debounce(function (obj) {
    //   this.$store.commit('updateComponent', { ...obj, id: this.id });
    //   this.$store.dispatch('autoSave/autoSave', { uxStateOnly: true });
    // }, 300),
    previewImage(e) {
      this.uploadValue = 0;
      this.image = null;
      this.imageData = e.target.files[0];
      this.uploadImage(e);
    },
    uploadImage(e) {
      this.image = null;
      // Validate the imageData is present and has a name
      if (!this.imageData || !this.imageData.name) {
        this.$vs.loading.close();
        this.$message({
          message: 'Upload failed',
          duration: 1000,
          showClose: true
        });
        return;
      }

      this.$vs.loading({ text: 'Validating image...' });

      // Load and validate the image
      const reader = new FileReader();
      reader.onload = (e) => {
        const tempImage = new Image();
        tempImage.onload = () => {
          this.$vs.loading.close(); // Close the loading message for validation
          this.$vs.loading({ text: 'UPLOADING IMAGE' }); // New loading message for upload

          // Proceed with upload
          const storageRef = storage.ref(`Images/${this.imageData.name}-${Date.now()}`).put(this.imageData);
          this.attachUploadListeners(storageRef, e); // Use a method to handle upload and its callbacks
        };
        tempImage.onerror = () => {
          this.$vs.loading.close();
          this.$message({
            message: 'The image is broken or not loadable.',
            duration: 2000,
            showClose: true
          });
        };
        tempImage.src = e.target.result;
      };
      reader.onerror = () => {
        this.$vs.loading.close();
        this.$message({
          message: 'Error reading image file.',
          duration: 2000,
          showClose: true
        });
      };
      reader.readAsDataURL(this.imageData);
    },

    attachUploadListeners(storageRef, e) {
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          this.$vs.loading.close();
          this.$message({
            message: 'Upload error',
            duration: 2000,
            showClose: true
          });
          console.error('Error: ', error.message, error);
        },
        () => {
          this.uploadValue = 100;
          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.image = url;
            e.imageUri = url;
            const obj = {};
            obj['imageUrl'] = url;
            if (this.mainComponentId && this.mainComponentId !== '') {
              obj['mainCarouselId'] = this.mainComponentId;
            }
            this.appStore.updateComponent({ ...obj, id: this.id });
            this.$vs.loading.close();
            this.$message({
              message: 'Upload complete',
              duration: 1000,
              showClose: true
            });
          });
        }
      );
    }
  },
  computed: {
    computedClass() {
      let className = 'sidebar-text-input'; // We can use it to add more custom classes on the input
      if (this.prefix) {
        className = 'sidebar-text-input prefix-box';
      }
      if (this.imageUpload) {
        className = 'sidebar-text-input image-upload';
      }

      return className;
    },
    text: {
      get() {
        if (this.mainComponentId && this.mainComponentId !== '') {
          const obj = _.cloneDeep(this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId));
          const selectedCarousel = obj.carousels.find((x) => x.id === this.id)[this.field];
          if (selectedCarousel != null && selectedCarousel.constructor.name === 'Object') {
            return selectedCarousel[this.appStore.language] || '';
          } else {
            return selectedCarousel || '';
          }
        } else {
          const obj = _.cloneDeep(this.appStore.getComponentListFromComponents.find((x) => x.id === this.id)[this.field]);
          if (obj != null && obj.constructor.name === 'Object') {
            return obj[this.appStore.language] || '';
          } else {
            return obj || '';
          }
        }
      },
      set(value) {
        const obj = {};

        obj[this.field] = value;

        if (this.mainComponentId && this.mainComponentId !== '') {
          obj['mainCarouselId'] = this.mainComponentId;
        }

        // this.debouncedUpdate(obj);

        this.appStore.updateComponent({ ...obj, id: this.id });
      }
    },
    componentSelected() {
      if (this.mainComponentId && this.mainComponentId !== '') {
        const obj = this.appStore.getComponentListFromComponents.find((x) => x.id === this.mainComponentId);
        return obj.carousels.find((x) => x.id === this.id);
      } else {
        return this.appStore.getComponentListFromComponents.find((x) => x.id === this.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-text-input {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: solid 1px #d7dde5;
  width: 100%;
  text-align: left;
  .prefix-box {
    width: 120px;
    height: 40px;
    display: flex;
    align-items: center;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    font-family: sans-serif;
    input {
      flex-grow: 1;
      background: #fff;
      width: 80px;
    }
    .prefix {
      border: none;
      text-transform: uppercase;
    }
  }
  .image-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      margin-right: 5px;
    }
  }
}
.upload-button {
  cursor: pointer;
  padding: 5.5px 14.5px;
  border-radius: 2px;
  color: #56616a;
  background-color: #f2f2f2;
}
.upload-button:hover {
  background-color: #1996f0;
  color: #ffffff;
}
.sidebar-text-input:hover {
  border: solid 1px #2698ed;
}

input {
  width: 100%;
  height: 30px;
  font-size: 14px;
  padding-left: 5px;
}

.sidebar-text-input::placeholder {
  font-style: italic;
  color: #8e9ba8;
}
</style>
