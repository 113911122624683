import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import axios from 'axios';
import Caretaker from './memento';
import cloneDeep from 'lodash/cloneDeep';
import { FlowUserRPCAdapter } from '@/helpers/rpc/FlowUserRPCAdapter';
import { updateNodesHeightAndWidth, nodesOverlap } from '../components/Utilities/containerDimensions';

export const WATCH_DEBOUNCE_DELAY = process.env.NODE_ENV !== 'test' ? 100 : 0;

function findDeepDifference(prev, current) {
  const changes = {};

  const compare = (prev, current, keyPath = '') => {
    if (prev === current) return;

    const prevType = typeof prev;
    const currentType = typeof current;

    if (prevType !== currentType || prevType !== 'object' || prev === null || current === null) {
      changes[keyPath] = { from: prev, to: current };
      return;
    }

    Object.keys({ ...prev, ...current }).forEach((key) => {
      const newKeyPath = keyPath ? `${keyPath}.${key}` : key;
      compare(prev[key], current[key], newKeyPath);
    });
  };

  compare(prev, current);
  return changes;
}

export function isDeepEqual(obj1, obj2, visited = new WeakMap()) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  if (visited.has(obj1) && visited.get(obj1) === obj2) {
    return true;
  }

  visited.set(obj1, obj2);

  const obj1Props = Reflect.ownKeys(obj1);
  const obj2Props = Reflect.ownKeys(obj2);

  if (obj1Props.length !== obj2Props.length) {
    return false;
  }

  for (const prop of obj1Props) {
    if (!obj2Props.includes(prop)) {
      return false;
    }

    if (typeof obj1[prop] === 'object' && typeof obj2[prop] === 'object') {
      if (!isDeepEqual(obj1[prop], obj2[prop], visited)) {
        return false;
      }
    } else if (obj1[prop] !== obj2[prop]) {
      return false;
    }
  }

  return true;
}

export function getStoreAdapter(appStore, vcmStore) {
  const getState = () => {
    const { scene, components, containers, taxonomies, containerList, componentList, comments, language } = appStore;
    const { slot, taxonomy } = appStore.current ?? {};
    return cloneDeep({
      scene,
      components,
      containers,
      taxonomies,
      containerList,
      componentList,
      comments,
      language,
      slot,
      taxonomy
    });
  };

  const storeOriginator = {
    save() {
      return omit(getState(), ['componentId']);
    },
    restore(memento) {
      appStore.RESTORE_PARTIAL_FLOW(cloneDeep(memento));
    }
  };

  const caretaker = new Caretaker(storeOriginator);

  const watchStore = (onChange, saveRequested) => {
    let previousState;

    const callSave = (force) => {
      previousState = getState();
      return saveRequested(force);
    };

    const eventHandler = () => {
      if (document.visibilityState === 'hidden') {
        appStore.disconnect();
        callSave();
      } else {
        const getInfo = () => ({
          slotId: appStore.current?.slot?.id,
          language: appStore.language,
          taxonomy: `${appStore.current?.taxonomy}_flows`
        });
        const info = getInfo();

        console.log('[RPC] Reconnecting User');
        FlowUserRPCAdapter.sendStart(info);
      }
    };
    let removeBeforeLeaveHandler = () => {};
    const addBeforeLeaveHandler = () => {
      window.addEventListener('visibilitychange', eventHandler);
      removeBeforeLeaveHandler = () => {
        window.removeEventListener('visibilitychange', eventHandler);
        removeBeforeLeaveHandler = () => {};
      };
    };

    const checkIfStateChanged = debounce((isRestoring) => {
      const currentState = getState();
      const areEqual = isDeepEqual(previousState, currentState);
      if (!areEqual) {
        if (!isRestoring) {
          caretaker.backup();
        }
        console.log('[VCM] Change', findDeepDifference(previousState, currentState));
        previousState = currentState;
        setTimeout(onChange, 1);
      }
    }, WATCH_DEBOUNCE_DELAY); // Adjust the delay as needed

    const unsubscribeAppAction = appStore.$onAction(({ name, args: [arg1] }) => {
      if (name === 'CHANGE_DISPLAY_MODE') {
        callSave();
        return;
      }
      let restoring = false;
      switch (name) {
        case 'FORCE_STATE_RECOMPUTE':
          if (arg1?.createBackup) {
            break;
          }
          return;
        case 'updateUser':
        case 'UPDATE_USER':
          return;
        case 'RESTORE_PARTIAL_FLOW':
          restoring = true;
          break;
        case 'RESTORE_FLOW':
          restoring = true;
          caretaker.clear();
      }

      if (name === 'SET_INVALID_ACTIONS_COUNT') {
        // using this action to set previousState
        previousState = getState();
      }

      if (appStore.displayMode !== 'builder') {
        return;
      }

      if (vcmStore.paused) {
        return;
      }

      checkIfStateChanged(restoring);
    });

    const unsubscribeVcmAction = vcmStore.$onAction(({ name, args: [arg1] }) => {
      if (name === 'setPaused') {
        if (arg1) {
          removeBeforeLeaveHandler();
        } else {
          addBeforeLeaveHandler();
          caretaker.start();
        }
        if (!previousState) {
          previousState = getState();
        }
        return;
      }
      if (name === 'createVersion') {
        // handles vcm/createVersion action
        const callback = arg1?.callback;
        return callSave(true).then((data) => {
          if (typeof callback === 'function') {
            callback(data);
          }
        });
      }
      if (name === 'forceSave') {
        // handles vcm/forceSave action
        const callback = arg1?.callback;
        const saved = callSave();
        if (saved?.then && typeof callback === 'function') {
          saved.then(callback);
        }
      }
      if (name === 'updateStatus' && arg1 === 'saved') {
        checkIfStateChanged();
      }
    });

    // console.log('[VCM] Watching store');
    return () => {
      removeBeforeLeaveHandler();
      unsubscribeVcmAction();
      unsubscribeAppAction();
      // console.log('[VCM] Unwatching store');
    };
  };

  const updateStatus = (status) => {
    vcmStore.updateStatus(status);
  };

  return {
    getState,
    watchStore,
    updateStatus,
    undo: () => caretaker.undo(),
    redo: () => caretaker.redo(),
    canUndo: () => caretaker.canUndo(),
    canRedo: () => caretaker.canRedo(),
    clear: () => caretaker.clear()
  };
}

async function isServerReachable() {
  try {
    const url = `${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}`;
    const response = await axios.get(url);
    return response?.status === 200;
  } catch (_) {
    return false;
  }
}

export function getDBAdapter(appStore, vcmStore, versionsStore) {
  function updateCurrentVersionToLatest() {
    const taxonomy = appStore.currentTaxonomy;
    const slotId = appStore.currentSlot.id;
    const language = appStore.language ? appStore.language : 'en';

    versionsStore.getLatestVersion(
      {
        taxonomy,
        slotId,
        draftOrPublished: 'draft',
        language
      },
      { root: true }
    );
  }

  const saveVersion = async (versionId) => {
    vcmStore.updateStatus('saving');
    const paused = vcmStore.paused;
    vcmStore.setPaused(true);

    try {
      const reachable = await isServerReachable();
      if (!reachable) {
        vcmStore.updateStatus('offline');
        return;
      }

      try {
        const updatedNodes = updateNodesHeightAndWidth(appStore.scene.nodes, appStore.zoomLevel);
        appStore.updateNodes({ nodes: updatedNodes });

        const updatedScene = {
          ...appStore.scene,
          nodesOverlap: false
        };

        if (nodesOverlap(appStore.scene.nodes)) {
          updatedScene.nodesOverlap = true;
        }

        appStore.updateScene({ scene: updatedScene });

        let data = await appStore.generateJSON({ draft: true, uxStateOnly: false });
        const { valid, errors } = await appStore.validateFlow({ data });
        const languages = Object.keys(appStore.languageCodes);
        if (valid === false) {
          await appStore.setValidationErrors({ errors });
          data = await appStore.generateJSON({ draft: true, uxStateOnly: false });
        }
        await appStore.saveUxStateAndFlow({ data, draft: true, versionId, languages });
        vcmStore.updateStatus('saved');
        updateCurrentVersionToLatest();
        setTimeout(() => {
          vcmStore.updateStatus('idle');
        }, 5000);
        console.log('[VCM]', { saved: versionId, paused });

        return data;
      } catch (error) {
        console.error('Error updating current version to latest', error);
        vcmStore.updateStatus('error');
      }
    } finally {
      vcmStore.setPaused(paused);
    }
  };
  return {
    saveVersion
  };
}
