<template>
  <div class="navbar-container flex">
    <div class="navbar flex justify-between">
      <div class="navbar-head-wrapper">
        <span class="back-arrow-wrapper" @click="closePage" :title="`Back`"><i :class="`fa fa-chevron-left back-arrow`" :aria-hidden="true"></i></span>
        <span class="slotName" v-if="displayMode === 'builder' || displayMode === 'preview'" :title="`${slotName}`" @mouseenter="toggleHovered(true)" @mouseleave="toggleHovered(false)">
          <el-tooltip effect="dark" placement="bottom" :content="`${slotName}`" v-if="hovered">
            <span class="slotName">{{ truncatedSlotName }}</span>
          </el-tooltip>
          <span class="slotName" v-else> {{ truncatedSlotName }}</span>
        </span>
        <span class="slotName" v-if="displayMode === 'comments'" :title="`Comments`">Comments</span>
        <span class="slotName" v-if="displayMode === 'versions'" :title="`Versioning`">Versioning</span>
        <span class="slotName" v-if="displayMode === 'pdfExport'" :title="`Export`">Export</span>
        <div class="slotName navbar-save-btn util tight" v-if="displayMode === 'builder'" :title="`Save Flow`" @click="vcmStatus === 'dirty' ? forceSave() : null">
          <span class="navbar-vcm-button" :title="`Save`" v-if="vcmStatus === 'dirty'"><ConversationBuilderIcon name="vcm/floppy_active" :width="16" /></span>
          <span class="navbar-vcm-button" v-if="vcmStatus !== 'dirty'"><ConversationBuilderIcon name="vcm/floppy_inactive" :width="16" /></span>
        </div>
        <span class="slotName tight navbar-flow-status-btn util tight" v-if="displayMode === 'builder'" :title="`Flow Status`">
          <span class="navbar-vcm-button" v-if="vcmStatus === 'idle' && connected"><ConversationBuilderIcon name="vcm/autosaved" :width="21" /></span>
          <span class="navbar-vcm-button" v-if="vcmStatus === 'dirty'"><ConversationBuilderIcon name="vcm/dirty" :width="21" /></span>
          <span class="navbar-vcm-button rotating" v-if="vcmStatus === 'saving'"><ConversationBuilderIcon name="vcm/saving_loader" :width="12" /> Saving...</span>
          <span class="navbar-vcm-button" v-if="vcmStatus === 'saved'"><ConversationBuilderIcon name="vcm/autosaved" :width="21" /> Saved</span>
          <span class="navbar-vcm-button" v-if="vcmStatus === 'offline' || connected === false"><ConversationBuilderIcon name="vcm/offline" :width="21" /> Working offline</span>
        </span>
      </div>

      <div class="versions-buttons-wrapper" v-if="displayMode === 'versions'">
        <div :title="`Draft`" class="col version-button" :class="isShowPublish ? '' : 'is-active '" @click="showPublishedVersions(false)">
          <span>Draft Versions</span>
        </div>
        <div :title="`Published`" class="col version-button" :class="isShowPublish ? 'is-active ' : ''" @click="showPublishedVersions(true)">
          <span>Published Versions</span>
        </div>
      </div>

      <div class="conversation-menubar flex justify-between" v-if="displayMode === 'builder' || displayMode === 'preview'" :title="`Change Display Mode`">
        <div class="util collab-button" :title="`Editor status`" v-if="collabMode === 'reader' && vcmStatus !== 'saving' && connected" @click="setCollabMode(true)">
          <ConversationBuilderIcon name="vcm/file-write" :width="15" />
          <span class="navbar-button"> Edit</span>
        </div>
        <div class="util collab-button disabled" :title="`Someone else is editing this flow`" v-if="collabMode === 'reader-disabled' || vcmStatus === 'saving' || !connected">
          <ConversationBuilderIcon name="vcm/file-write-disabled" :width="15" />
          <span class="navbar-button"> {{ collabMode === 'editor' ? 'View' : 'Edit' }}</span>
        </div>
        <div class="util collab-button" :title="`Viewer status`" v-if="collabMode === 'editor' && vcmStatus !== 'saving' && connected" @click="setCollabMode(false)">
          <ConversationBuilderIcon name="vcm/file-read" :width="15" />
          <span class="navbar-button"> View</span>
        </div>
        <AutocompleteInput :title="`Search`" :items="currentFlows" @setFlow="goToFlow" />
        <div :title="`Notifications`" :class="`util notification-button ${showNotificationDrawer ? 'active' : ''}`" @click="toggleNotificationDrawer">
          <div class="notification-button-content">
            <ConversationBuilderIcon :name="`notification${showNotificationDrawer ? '-active' : ''}`" :width="20" />
            <div class="notification-number" v-if="unreadNotifications.length">
              {{ getUnreadNotifications }}
            </div>
            <span class="navbar-button">Notifications</span>
          </div>
        </div>
        <div v-if="false" :title="`Variables`" class="util">
          <ConversationBuilderIcon name="variables" :width="20" />
          <span class="navbar-button"> Variables</span>
        </div>
        <div :title="`Comments`" class="util" @click="toggleCommentMode" v-if="vcmStatus !== 'saving'">
          <ConversationBuilderIcon name="comments" :width="17" />
          <span class="navbar-button notify"> Comments</span>
        </div>
        <div :title="`Comments`" class="util" v-if="vcmStatus === 'saving'">
          <ConversationBuilderIcon name="comments-disabled" :width="17" />
          <span class="navbar-button notify"> Comments</span>
        </div>
        <div :title="`Versioning`" class="util" @click="toggleVersionsMode(showPublished)" v-if="vcmStatus !== 'saving'">
          <ConversationBuilderIcon name="versioning" :width="17" />
          <span class="navbar-button">Versioning</span>
        </div>
        <div :title="`Versioning`" class="util" v-if="vcmStatus === 'saving'">
          <ConversationBuilderIcon name="versioning-disabled" :width="17" />
          <span class="navbar-button">Versioning</span>
        </div>
        <div :title="`Export`" @click="toggleExport" @mouseleave="toggleLeave" class="util">
          <ConversationBuilderIcon name="export1" :width="18" />
          <span class="navbar-button"> Export</span>
          <div class="caption">
            <span class="expandMenu"
              ><i
                :class="{
                  fas: true,
                  'fa-caret-right': !exportOpened,
                  'fa-caret-down': exportOpened
                }"
              ></i
            ></span>
          </div>
          <div :title="`Export`" class="actions-export" :class="{ opened: exportOpened }" v-if="!this.appStore.getUser.email.includes('@conversationhealth.com')">
            <ExportDropdown
              :opened="exportOpened"
              :title="`Export`"
              :closeParent="toggle"
              :items="[
                {
                  icon: '',
                  title: 'PDF',
                  click: () => {
                    togglePDFExportMode();
                  }
                }
              ]"
            />
          </div>
          <div :title="`Export`" class="actions-export" :class="{ opened: exportOpened }" v-if="this.appStore.getUser.email.includes('@conversationhealth.com')">
            <ExportDropdown
              :opened="exportOpened"
              :title="`Export`"
              :closeParent="toggle"
              :items="[
                {
                  icon: '',
                  title: 'PDF',
                  click: () => {
                    togglePDFExportMode();
                  }
                },
                {
                  icon: '',
                  title: 'JSON',
                  click: () => {
                    this.appStore.generateJSON({
                      exportJSON: true,
                      draft: true
                    });
                  }
                }
              ]"
            />
          </div>
        </div>
        <!-- <div class="util"><i class="fas fa-cog"></i> NLU</div> -->
        <div :title="`Language`" @click="toggleLanguage" @mouseleave="toggleLeave" class="util">
          <ConversationBuilderIcon name="globe" :width="17" />
          <span class="navbar-button"> {{ currentLanguage.title }} </span>
          <div class="caption">
            <span class="expandMenu"
              ><i
                :class="{
                  fas: true,
                  'fa-caret-right': !languageOpened,
                  'fa-caret-down': languageOpened
                }"
              ></i
            ></span>
          </div>
          <div class="actions-language" :class="{ opened: languageOpened }">
            <LanguageDropdown :opened="languageOpened" v-if="isContentEditor" :title="currentLanguage.title" :closeParent="toggle" />
          </div>
        </div>
        <!-- <div :title="`Publish`" @click="publish" class="util fill">Publish</div> -->
        <div v-if="isEditor" :title="`Publish`" class="play-button-wrapper" @click="togglePublishMenu">
          <button :disabled="disablePublishing" class="play-button" :class="disablePublishing ? 'disabled' : ''">Publish</button>
        </div>
        <div :title="`Play`" class="play-button-wrapper" @click="togglePreviewMode">
          <button class="play-button"><span class="fa fa-play"></span>Play</button>
        </div>
      </div>
      <div class="comments-menubar" v-if="displayMode === 'comments'">
        <button :title="`Back`" v-if="getCommentFilters.resolved" class="resolve-back-button" @click="toggleResolvedComments"><span class="arrow-circle fa fa-chevron-left back-arrow"></span>Back</button>
        <button :title="`Resolved`" v-if="!getCommentFilters.resolved" class="resolve-button" @click="toggleResolvedComments">Resolved</button>
      </div>
      <div class="export-menubar" v-if="displayMode === 'pdfExport'">
        <button :title="`Export`" class="export-button" @click="exportToPDF">Export</button>
      </div>
    </div>
    <div v-if="showNotificationDrawer" v-click-outside="closeNotificationDrawer" class="notification-drawer-container">
      <NotificationDrawer v-if="showNotificationDrawer" :type="'builder-navbar'" @close-drawer="closeAndRedirectToNotifications" />
    </div>
    <div class="masterPublish">
      <div v-if="showPublishMenu" class="card">
        <div class="card-body">
          <h5 class="card-title">Publish Options</h5>
          <p class="text-start">Click "Publish Now" to go live with your latest changes or choose "History" to view the latest versions.</p>
          <button type="button" @click="publish" class="btn btn-outline-primary btn btn-block">Publish Now</button>
          <button type="button" @click="toggleVersionsMode(true)" class="btn btn-outline-primary btn btn-block">View History</button>
        </div>
      </div>
    </div>
    <div v-if="showPublishSuccessAlert" class="alert alert-success collab" role="alert">
      <ConversationBuilderIcon :name="'vcm/success'" :width="20" />
      <div class="messages">
        <i>Draft published successfully</i>
      </div>
    </div>
    <div v-if="publishValidationAlert.show" class="alert alert-error collab" role="alert">
      <ConversationBuilderIcon :name="'vcm/error'" :width="20" />
      <div class="messages">
        <i><strong>Invalid.</strong> Resolve missing actions {{ this.publishValidationAlert.invalidLanguageCodes.length > 0 ? `in languages "${publishValidationAlert.invalidLanguageCodes}" ` : '' }}to be able to publish flow</i>
      </div>
    </div>
    <div v-if="showPublishErrorAlert" class="alert alert-danger collab" role="alert">
      <ConversationBuilderIcon :name="'vcm/error'" :width="20" />
      <div class="messages">Error publishing draft.</div>
    </div>
    <div v-if="collabMessage.level" :class="`alert collab alert-${collabMessage.level}`" role="alert">
      <ConversationBuilderIcon :name="`vcm/${collabMessage.icon}`" :width="20" />
      <div class="messages" v-html="collabMessage.message" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useCollabStore, useCommentsStore, useNotificationsStore, useVcmStore, useVersionsStore } from '@/store/index.js';
import LanguageDropdown from '@/components/ConversationBuilder/Canvas/3.0/Dropdowns/LanguageDropdown';
import ExportDropdown from '@/components/ConversationBuilder/Canvas/3.0/Dropdowns/ExportDropdown.vue';
import AutocompleteInput from '@/components/ConversationBuilder/Canvas/3.0/Inputs/AutocompleteInput';
import { mapState } from 'pinia';
import sortBy from 'lodash/sortBy';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import vClickOutside from 'click-outside-vue3';
import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import moment from 'moment';
import { callBuilderGenerateJSON } from '@/helpers/callBuilderGenerateJSON';
import { FILE_TIMESTAMP_FORMAT } from '../../../Utilities/constants/constants';

export default {
  name: 'NavBar',
  components: {
    ExportDropdown,
    LanguageDropdown,
    AutocompleteInput
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      appStore: useAppStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      vcmStore: useVcmStore(),
      collabStore: useCollabStore(),
      search: '',
      hovered: false,
      opened: false,
      languageOpened: false,
      exportOpened: false,
      currentLanguage: {
        title: useAppStore().languageTitle,
        code: useAppStore().language
      },
      filter: '',
      showPublishMenu: false,
      showPublishSuccessAlert: false,
      showPublishErrorAlert: false,
      showNotificationDrawer: false,
      publishValidationAlert: {
        show: false,
        invalidLanguageCodes: []
      },
      flowPrintProperties: {
        fileName: '',
        customTitle: '',
        internalNotes: false,
        clientNotes: false
      },
      alertTimeout: null,
      loadHidePublishSuccessTimeout: null
    };
  },
  beforeMount() {
    this.loadLanguage();
  },

  props: {
    slotName: {
      type: String,
      default: 'FLOW_NAME'
    }
  },

  methods: {
    forceSave() {
      this.vcmStore.forceSave();
    },
    closeAndRedirectToNotifications() {
      this.showNotificationDrawer = false;
    },
    toggleHovered(value) {
      this.hovered = value;
    },
    toggleNotificationDrawer() {
      this.showNotificationDrawer = !this.showNotificationDrawer;
    },
    closeNotificationDrawer() {
      this.showNotificationDrawer = false;
    },
    async setCollabMode(shouldEdit) {
      await this.forceSave();
      this.collabStore.setCollabMode(shouldEdit);
    },
    toggleVersionsMode(showPublished) {
      const callback = () => {
        this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'versions' });
        this.commentsStore.CLEAR_PENDING();

        if (showPublished) {
          this.showPublishedVersions(showPublished);
        }
      };
      this.vcmStore.forceSave({ uxStateOnly: false, callback });

      this.showPublishMenu = false;
    },
    async toggleCommentMode() {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'comments' });
      this.commentsStore.CLEAR_PENDING();

      this.showPublishMenu = false;
    },
    togglePreviewMode() {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'preview' });

      this.showPublishMenu = false;
    },
    togglePublishMenu() {
      this.showPublishMenu = !this.showPublishMenu;
    },
    toggleResolvedComments() {
      this.commentsStore.SET_COMMENT_FILTERS({
        filterName: 'resolved',
        filterValue: !this.getCommentFilters.resolved
      });
    },
    async exportToPDF() {
      ElLoading.service({ text: 'Please wait while PDF is generating...' });
      try {
        const timeStamp = moment().format(FILE_TIMESTAMP_FORMAT);
        const fileName = this.appStore.current.flowPrintProperties?.fileName === '' ? `${this.appStore.currentIntent}_${timeStamp}` : this.appStore.current.flowPrintProperties?.fileName;
        const state = this.appStore;
        const flow = state.lastJson || (await callBuilderGenerateJSON(state, true));
        const isPublished = typeof state.versions?.currentVersion?.publishedId === 'string' && state.versions?.currentVersion?.publishedId !== '';

        const r = await axios({
          method: 'post',
          url: (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_INTEGRATIONS_ENDPOINT.replace(process.env.VUE_APP_CMP_SERVER_NAME, process.env.VUE_APP_PDF_EXPORT_SERVICE_NAME) : 'http://localhost:4000') + '/export/single',
          responseType: 'arraybuffer',
          data: {
            cmpURL: process.env.NODE_ENV === 'production' ? `https://${window.location.hostname}` : process.env.VUE_APP_DEFAULT_TEST_PROJECT_CMP_URL,
            agentId: this.appStore.currentTaxonomy,
            slotName: this.appStore.currentIntent,
            slotId: this.appStore.currentIntentId,
            taxonomyId: this.appStore.currentTaxonomyId,
            userId: this.appStore.getUser.uid,
            userEmail: this.appStore.getUser.email,
            idToken: this.appStore.getClaims.token,
            userName: this.appStore.getUser.displayName,
            language: this.appStore.language,
            timestamp: timeStamp,
            pdfOptions: this.appStore.current.flowPrintProperties?.pdfOptions,
            userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            payload: {
              flow
            },
            fileName,
            isPublished
          },
          timeout: 180000
        });

        if (r.status === 200) {
          const blob = new Blob([r.data], { type: 'application/pdf' }),
            url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${fileName}.pdf`;
          return link.click();
        }
        throw new Error('Invalid response: ' + JSON.stringify(r.data));
      } catch (error) {
        console.error(error);
        ElMessage({
          showClose: true,
          message: 'There was an error generating PDF.',
          duration: 4000,
          type: 'error'
        });
      } finally {
        ElLoading.service().close();
      }
    },
    async togglePDFExportMode() {
      this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'pdfExport' });
      this.appStore.SET_ORIGINAL_NODE_POSITIONS({
        nodes: this.appStore.getScene.nodes
      });
      this.commentsStore.CLEAR_PENDING();

      console.log('Generating JSON on server');

      await this.appStore.generateJSON({ draft: true });

      console.log('JSON stored in state');

      const query = {
        lang: this.currentLanguage.code ? this.currentLanguage.code : 'en'
      };
      this.$router.push({ query });

      this.showPublishMenu = false;
    },
    async publish() {
      const decision = confirm('You are about to publish the current draft. Do you wish to continue with this action?');
      if (decision) {
        try {
          this.$vs.loading({ text: 'SAVING CONVERSATION', color: 'black' });
          await new Promise((callback) => {
            this.vcmStore.forceSave({ uxStateOnly: false, callback });
          });

          const {
            current: { slot, taxonomy }
          } = this.appStore;

          const data = await this.appStore.generateJSON({ save: true });
          const { valid, errors = [] } = await this.appStore.validateFlow({ data });

          const languages = Object.keys(this.appStore.languageCodes);
          const alternativeLanguages = languages.filter((langCode) => langCode !== this.currentLanguage.code);
          let invalidAltLanguages = [];
          if (alternativeLanguages && alternativeLanguages.length > 0) {
            const result = await this.appStore.validateAdditionalLanguageFlows({
              agentName: taxonomy,
              flowId: slot.id,
              languageCodes: alternativeLanguages
            });
            invalidAltLanguages = result.filter((r) => r.valid === false);
          }

          if (valid === true && !invalidAltLanguages.length > 0) {
            await this.appStore.saveUxStateAndFlow({ data, languages });
            this.alertTimeout = setTimeout(() => {
              this.$vs.loading.close();
              this.showPublishMenu = false;
              this.showPublishSuccessAlert = true;
            }, 2000);
            await this.appStore.getAllFlows({ collection: taxonomy, store: true });
          } else if (errors.length > 0 || invalidAltLanguages.length > 0) {
            await this.appStore.setValidationErrors({ errors });
            this.alertTimeout = setTimeout(() => {
              this.$vs.loading.close();
              this.showPublishMenu = false;
              this.publishValidationAlert.show = true;
              this.publishValidationAlert.invalidLanguageCodes = invalidAltLanguages.length > 0 ? invalidAltLanguages.map((x) => x?.languageCode) : [];
            }, 2000);
          }
        } catch (error) {
          console.error(`Error: Unable to publish: `, error);
          this.alertTimeout = setTimeout(() => {
            this.$vs.loading.close();
            this.showPublishMenu = false;
            this.showPublishErrorAlert = true;
          }, 2000);
        }
      }

      this.loadHidePublishSuccessTimeout = setTimeout(() => {
        this.showPublishSuccessAlert = false;
        this.publishValidationAlert.show = false;
        this.showPublishErrorAlert = false;
      }, 4000);
    },
    async loadLanguage() {
      const languageParam = this.$route.query.lang;
      if (languageParam && languageParam !== this.currentLanguage.code) {
        await this.appStore.setLanguage({ language: languageParam });
        this.currentLanguage = {
          code: this.appStore.language,
          title: this.appStore.languageTitle
        };
      } else if (languageParam) {
        await this.appStore.setLanguage({ language: languageParam });
        this.currentLanguage = {
          code: this.appStore.language,
          title: this.appStore.languageTitle
        };
      }
    },
    setLanguage(language) {
      const dispatched = this.appStore.setLanguage({ language });
      const done = () => {
        const query = { lang: this.appStore.language ? this.appStore.language : 'en' };
        this.$router.push({ query });
      };
      if (dispatched?.then) {
        dispatched.then(done);
      } else {
        done();
      }
    },
    toggle() {
      this.$emit('toggle');
    },
    toggleLanguage() {
      this.languageOpened = !this.languageOpened;
    },
    toggleLeave() {
      this.languageOpened = false;
      this.exportOpened = false;
    },
    toggleExport() {
      this.exportOpened = !this.exportOpened;
    },
    async closePage() {
      const {
        current: { taxonomyId }
      } = this.appStore;
      switch (this.displayMode) {
        case 'builder':
          this.$router.push({ path: `/agents/flows/${taxonomyId}`, query: { initial: 'flows' } });
          break;
        case 'comments':
          this.commentsStore.SET_COMMENT_FILTERS({
            filterName: 'resolved',
            filterValue: false
          });
          this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
          this.goToSlot(this.appStore.current.slot);
          break;
        case 'preview':
          this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
          break;
        case 'pdfExport':
          this.appStore.recomputeNodePositions({
            nodes: this.appStore.originalNodePositions,
            exitPdf: true
          });
          this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
          break;
        case 'versions':
          this.vcmStore.loadLatestDraft({
            callback: () => {
              this.appStore.CHANGE_DISPLAY_MODE({ displayMode: 'builder' });
            }
          });
          break;
        default:
          break;
      }
    },

    goToSlot(slot) {
      this.$router.push({
        path: `/conversationBuilder/${this.appStore.currentTaxonomy}/${slot.id}/${slot.name}`
      });
    },
    goToFlow(flow) {
      if (flow) {
        this.$router.push({
          path: `/conversationBuilder/${this.appStore.currentTaxonomy}/${flow.id}/${flow.name}`
        });
      }
    },
    async toggleActions() {
      this.actionsOpened = !this.actionsOpened;
    },
    showPublishedVersions(value) {
      this.versionsStore.SET_SHOW_PUBLISHED(value);
    }
  },
  computed: {
    ...mapState(useCommentsStore, ['getCommentFilters']),
    ...mapState(useVcmStore, ['currentStatus']),
    ...mapState(useNotificationsStore, ['unreadNotifications']),
    ...mapState(useAppStore, ['isContentEditor', 'isEditor', 'getCurrentFlow', 'currentIntentId']),
    ...mapState(useAppStore, ['currentFlows']),
    connected() {
      return this.vcmStore.connected;
    },
    truncatedSlotName() {
      const maxLength = 20; // You can adjust this to your desired length
      if (this.slotName.length > maxLength) {
        return this.slotName.substring(0, maxLength) + '...';
      } else {
        return this.slotName;
      }
    },
    displayMode() {
      return this.appStore.displayMode;
    },
    vcmStatus() {
      return this.currentStatus || 'idle';
    },
    collabMode() {
      return this.collabStore.collabMode;
    },
    collabMessage() {
      return this.collabStore.collabMessaqe;
    },
    collabCount() {
      const collaborators = this.collabStore.collaborators;
      return new Set([collaborators.editor, ...collaborators.readers].filter(Boolean)).size;
    },
    getUnreadNotifications() {
      if (this.unreadNotifications.length <= 9) {
        return this.unreadNotifications.length;
      }

      return '9+';
    },
    isCurrentVersionEmpty() {
      return this.versionsStore.currentVersion?.id === undefined;
    },
    isShowPublish() {
      return this.versionsStore.showPublished;
    },
    showPublished() {
      // return this.getCurrentFlow(this.currentIntentId)?.published
      // activating above return instead will allow a feature where toggle on versioning will be dynamically selected based on flow published status
      return false;
    },
    filteredSlots() {
      const slots = sortBy(this.appStore.getSlots, 'name').filter((data) => {
        if (!Object.prototype.hasOwnProperty.call(data, 'name')) {
          data.name = 'n/a';
        }
        if (!Object.prototype.hasOwnProperty.call(data, 'status')) {
          data.status = data.conversationID;
        }
        //Excluding currently selected intent as well
        return data.status.includes(this.filter) && data.name !== this.appStore.currentIntent;
      });
      return slots;
    },
    invalidActionsCount() {
      return this.appStore.getInvalidActionsCount;
    },
    disablePublishing() {
      return !this.isEditor || this.isCurrentVersionEmpty || this.invalidActionsCount > 0 || this.appStore.scene?.nodesOverlap === true;
    }
  },
  beforeUnmount() {
    clearTimeout(this.alertTimeout);
    clearTimeout(this.loadHidePublishSuccessTimeout);
  }
};
</script>

<style lang="scss" scoped>
.slotName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.slotName.hovered {
  white-space: initial;
  overflow: initial;
  text-overflow: initial;
}

.navbar-container {
  z-index: 2;
  width: 100%;
}

.navbar {
  width: 100%;
  height: 120%;
  -webkit-box-shadow: 3px 6px 0 rgba(142, 155, 167, 0.2);
  box-shadow: 0 3px 6px 0 rgba(142, 155, 167, 0.2);
  padding: 0 15px 0 15px !important;
  z-index: 1000;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: #fff;
}

.navbar-head-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  border: solid 1px #2698ed;
}

.conversation-menubar {
  vertical-align: middle;
}

.conversation-menubar > div {
  border: solid 1px transparent;
}

.conversation-menubar .active {
  border: solid 1px #1996f0 !important;
}

.notification-button {
  display: flex;
  align-items: center;
  position: relative;

  .notification-button-content {
    position: relative;

    .notification-number {
      position: absolute;
      top: 15%;
      left: 10%;
      max-width: 23px;
      height: 16px;
      padding: 0 5px;
      background-color: #fd5422;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .navbar-button {
      margin-left: 25px;
    }
  }
}

img,
button {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

@media screen and (max-width: 200px) {
  .play-button-wrapper {
    display: none !important;
  }
}

@media screen and (max-width: 1220px) {
  .slotName {
    margin-left: 3px !important;
    font-size: 16px !important;
  }
  .notification-number {
    left: 50% !important;
  }
  .conversation-menubar {
    margin-bottom: 5px !important;
  }

  .version-button {
    margin: 6.01px 0px 4.01px 2px !important;
  }

  .resolve-button {
    margin: 5.8535px 0px 5.8535px 0px !important;
  }

  .resolve-back-button {
    margin: 5.8535px 0px 5.8535px 0px !important;
  }

  .export-button {
    margin: 5.8535px 0px 5.8535px 0px !important;
  }
}

@media screen and (max-width: 1685px) {
  .util {
    //padding: 14.7px 20.1px 14px 15.9px !important;
    margin: 2px 0px 0px 0px !important;
  }
  .util.collab-button {
    margin: 8.5px 0px 0px 0px !important;
  }
  .navbar-button {
    margin-left: 0px !important;
    display: none !important;
  }

  .notification-number {
    left: 50% !important;
  }
}

@media screen and (max-width: 1370px) {
  .slotName {
    margin-left: 5px !important;
  }

  .navbar-button {
    margin-left: 0px !important;
    display: none !important;
  }

  .notification-number {
    left: 50% !important;
  }
}

.back-arrow {
  cursor: pointer;
}

.back-arrow-wrapper {
  cursor: pointer;
  background-color: #edf0f4;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 12px;
  }
}

.form-control:focus {
  border-color: none;
}

.navbar-button {
  margin-left: 5px;
}

.navbar-vcm-button {
  margin-right: 0px;
  font-size: 12px;
  vertical-align: middle;
}

.rotating span {
  width: 12px;
  height: 12px;
  animation: spin 2s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.play-button-wrapper {
  margin-left: 10px;
  margin-top: 2px;
}

.play-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 48px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #1996f0;

  span {
    margin-right: 5px;
  }
}

.play-button.disabled {
  background-color: #edf0f4;
  color: #c2c2c2;
}

.export-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 48px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #1996f0;
  margin: 3.354px 0px 3.354px 0px;

  span {
    margin-right: 5px;
  }
}

@media screen and (min-width: 500px) {
  .search-input {
    display: none;
  }
  .navbar-button {
    margin-left: 0px;
    display: none;
  }

  .sideBarName {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  .search-input {
    display: flex;
    width: 170px;
  }

  .sideBarName {
    display: inline;
  }

  .navbar-button {
    margin-left: 0px;
    display: none;
  }
}

@media screen and (min-width: 1200px) {
  .search-input {
    width: 250px;
    display: flex;
  }

  .sideBarName {
    display: inline;
  }

  .navbar-button {
    margin-left: 5px;
    display: inline;
  }
  .navbar-vcm-button {
    margin-left: 0px;
    margin-right: 9px;
    display: inline;
  }
}

.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-top: 1px;
}

.caption {
  float: right;
  padding-left: 5px;
}

.actions-language.opened,
.actions-export.opened {
  display: block;
  position: fixed;
}

.actions-language,
.actions-export {
  position: absolute;
  display: none;
  z-index: 100;
  padding: 0px 0px 0px 0px;
  border-radius: 4px;
  box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.5);
  background-color: #ffffff;
  width: 100px;
  margin-top: 4px;
  height: max-content;
}

.action-language-menu,
.action-export-menu {
  list-style-type: none;
  padding-left: 0;
}

.search:active {
  border: none !important;
  box-shadow: none !important;
}

.fill {
  border: solid 1px #2698ed;
  // color: white !important;
}

.fill-icon {
  color: #2698ed;
}

.search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-input:active {
  border-radius: 4px;
  border: solid 1px #2698ed;
}

.search-navbar-container {
  display: flex;
  width: 305px;
  height: 48px;
  border-radius: 4px;
  margin: 2px 10px 0 2px;
  border: solid 1px #d7dde5;
  justify-content: center;
  align-items: center;
}

.search-navbar-container:active {
  border: solid 1px #2698ed;
  background-color: #fff;
  color: #2698ed;

  .search-navbar-icon {
    i {
      color: #2698ed;
    }
  }
}

.active-search-box {
  border: solid 1px #2698ed;
}

.search-navbar {
  width: inherit;
  padding: 14px 20.1px 14px 19.9px;
}

.search-navbar-button {
  background-color: #1996f0;
  color: white;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 39px;
}

.search-navbar-end {
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.search-navbar-icon {
  color: #383f45;
  margin-right: 5px;

  i {
    font-size: 16px;
  }
}

i {
  margin-right: 3px;
}

.util {
  display: block;
  color: #383f45;
  font-size: 12px;
  //   text-align: center;
  padding: 14.7px 20.1px 14px 19.9px;
  text-decoration: none;
  border-radius: 4px;
  margin: 2px 0px 0px 2px;
  cursor: pointer;
}

.util:hover {
  background-color: #f6f8fa;
}

.resolve-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 48px;
  border: solid 1px #20bbd0;
  color: #20bbd0;
  border-radius: 4px;
  margin: 3.354px 0px 3.354px 0px;
}

.resolve-button:hover {
  background-color: #20bbd0;
  color: #ffffff;
}

.resolve-back-button {
  background-color: #20bbd0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 48px;
  border-radius: 4px;
  margin: 3.354px 0px 3.354px 0px;

  span {
    background-color: #ffffff;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    color: #56616a;
    margin-right: 10px;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.slotName {
  font-weight: 900;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 8px;
  user-select: text;
}

.slotName.tight {
  margin-left: 5px;
}

// .right {
//   float: right;
// }

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
}

div {
  float: left;
}

div a {
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.versions-buttons-wrapper {
  display: flex;
  text-align: center;
  margin-right: 20px;
  width: 340px;
}

.version-button {
  color: #2698ed;
  background: #fff;
  font-weight: 700;
  border-color: #2698ed;
  display: block;
  font-size: 12px;
  padding: 14.7px 20.1px 14px 19.9px;
  text-decoration: none;
  border-radius: 4px;
  margin: 3.5025px 0px 1.5025px 2px;
  cursor: pointer;
}

.version-button:hover {
  background-color: #f6f8fa;
  color: #2698ed;
}

.version-button:active {
  background-color: #90c5ee;
  color: #fff;
}

.is-active {
  color: white;
  background: #2698ed;
  font-weight: 700;
}

/* Change the divnk color to #111 (black) on hover */
// div a:hover {
//   background-color: black;
// }

.btn:hover {
  background-color: #1996f0 !important;
  border-color: #1996f0 !important;
}

.masterPublish {
  position: absolute;
  z-index: 100;
  right: 0px;
  top: 60px;
}

.card {
  float: right !important;
  width: 252px;
}

.card-body {
  display: grid;
  border: 1px solid #d3d3d3;
}

.text-start {
  margin-bottom: 20px !important;
}

.alert {
  position: absolute;
  right: 0;
  left: 0;
  top: 80px;
  text-align: center;
  width: 20%;
  z-index: 5;
  margin: auto;
}

@media print {
  .navbar-container {
    display: none !important;
  }
}

.util.collab-button {
  border-radius: 4px;
  padding: 7px 15px;
  margin: 10px 10px 10px 0px !important;
  color: #1996f0;
}

.util.collab-button.disabled {
  color: #8e9ba8;
  background-color: #edf0f4;
}

@media screen and (min-width: 1371px) {
  .util.collab-button {
    background-color: #e8f5fe;
  }
}

.util:active {
  border: solid 1px #2698ed;
  background-color: #fff;
  color: #2698ed;
}

.util:active img {
  border: solid 1px #2698ed;
  stroke: #2698ed;
}

.alert.collab div.messages {
  display: table-cell;
  margin-left: 10px;
  text-align: left;
  font-size: 17px;
  vertical-align: middle;
  padding-right: 4px;
}

.alert.collab span.connector {
  display: table-cell;
  vertical-align: top;
}

.alert.collab {
  border-radius: 4px;
  padding: 20px;
  margin-top: 10px;
  display: table;
  align-items: center;
  width: unset;
}

.alert-error.collab {
  font-size: 17px;
  font-style: italic;
  border: solid 1px #ef231a;
  background-color: #fee9e6;
}

.alert-success.collab {
  font-size: 17px;
  font-style: italic;
  border: solid 1px #6fc724;
  background-color: #f0fae6;
}

.alert-warning.collab {
  font-size: 17px;
  font-style: italic;
  border: solid 1px #fccc05;
  background-color: #fef8d5;
}
</style>
