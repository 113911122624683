import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

export const inputRegex = /(?:^|\s)((?:==)((?:[^~]+))(?:==))$/;
export const pasteRegex = /(?:^|\s)((?:==)((?:[^~]+))(?:==))/g;
const EXTENSION_NAME = 'highlightsecondary';
export const HighlightSecondary = Mark.create({
  name: EXTENSION_NAME,

  addOptions: {
    openOnClick: true
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-highlightsecondary]'
      },
      {
        tag: 'del'
      },
      {
        style: 'font-style',
        getAttrs: (value) => value === 'italic' && {}
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ 'data-highlightsecondary': '', ...HTMLAttributes }), 0];
  },

  addCommands() {
    return {
      toggleHighlightSecondary:
        () =>
        ({ commands }) => {
          return commands.toggleMark('highlightsecondary');
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      'Mod-n': () => this.editor.commands.toggleHighlightSecondary()
    };
  },

  addInputRules() {
    return [
      markInputRule({
        find: inputRegex,
        type: EXTENSION_NAME
      })
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: pasteRegex,
        type: EXTENSION_NAME
      })
    ];
  },

  addProseMirrorPlugins() {
    const plugins = [];

    if (this.options.openOnClick) {
      const onClickPlugin = new Plugin({
        props: {
          handleClick: (/*view, pos, event*/) => {
            // Handle click event as needed.
          }
        }
      });

      plugins.push(onClickPlugin);
    }

    return plugins;
  }
});
