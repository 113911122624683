<template>
  <BulkActionsModal>
    <template #title>
      <h3>Change Status in Bulk</h3>
    </template>

    <template #body>
      You have <strong>{{ selectedFlowCount }} flows</strong> selected. Choose the status you would like to change <br /><br />
      <FlowStatusPropertiesDropdown :selectedStatus="status" @updateParentData="updateData" />
    </template>

    <template #actions>
      <v-btn class="v-btn-cancel" flat @click="closeModal"> Cancel </v-btn>
      <v-btn class="v-btn-bulk" dark flat @click="bulkUpdateStatus" :disabled="!selectedStatus"> Save Changes </v-btn>
    </template>
  </BulkActionsModal>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { defineComponent } from 'vue';
import FlowStatusPropertiesDropdown from './Flows/FlowStatusPropertiesDropdown.vue';
import { STATUS_NAMES } from '../../components/Utilities/constants/status';
import BulkActionsModal from './BulkActionsModal.vue';

export default defineComponent({
  name: 'BulkStatusModal',
  components: {
    BulkActionsModal,
    FlowStatusPropertiesDropdown
  },
  props: ['selectedFlowCount'],
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      statuses: STATUS_NAMES,
      status: null
    };
  },
  computed: {
    selectedStatus() {
      return this.status;
    }
  },
  methods: {
    updateData(payload) {
      const { key, value } = payload;
      this[key] = value;
    },
    closeModal() {
      console.log('closing modal');
      this.status = null;
      this.$emit('closeModal');
    },
    bulkUpdateStatus() {
      this.$emit('bulkUpdateStatus', this.status);
      this.status = null;
      this.$emit('closeModal');
    }
  }
});
</script>

<style lang="scss">
.bulk-actions-modal {
  height: auto;
  .vm--modal {
    overflow: visible !important;
  }
  .v-card-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .bulk-actions-title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .v-btn .v-btn--icon .theme--light {
    padding-left: 0.3em;
  }
  .v-btn-cancel {
    background-color: #e4e4e4;
  }
  .v-btn-bulk {
    background-color: #1996f0;
    color: #ffffff;
    &:disabled {
      background-color: #d7dde5;
      &.v-btn--disabled {
        color: darkgrey !important;
      }
    }
  }
  .bulk-card-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
  }
}
</style>
