import { Position } from '../Utilities/constants/canvas';

function getConnectorPositionInCanvas(element) {
  const canvas = document.getElementById('zoomID');
  if (!canvas || !element) {
    return;
  }

  const style = window.getComputedStyle(canvas);
  const { m22: zoom } = new window.DOMMatrixReadOnly(style.transform);

  const canvasBounds = canvas?.getBoundingClientRect();
  const elementBounds = element?.getBoundingClientRect();

  return {
    x: (elementBounds.left - canvasBounds.left) / zoom,
    y: (elementBounds.top - canvasBounds.top) / zoom,
    width: elementBounds.width / zoom,
    height: elementBounds.height / zoom
  };
}

function getConnectorPosition(position, connector) {
  const connectorPositionInCanvas = getConnectorPositionInCanvas(connector);
  const x = connectorPositionInCanvas?.x || 0;
  const y = connectorPositionInCanvas?.y || 0;
  const width = connectorPositionInCanvas?.width || 0;
  const height = connectorPositionInCanvas?.height || 0;

  switch (position) {
    case Position.Top:
      return {
        x: x + width / 2,
        y: y + height / 2
      };
    case Position.Right:
      return {
        x: x + width / 2,
        y: y + height / 2
      };
    case Position.Bottom:
      return {
        x: x + width / 2,
        y: y + height
      };
    case Position.Left:
      return {
        x: x + width / 2,
        y: y + height / 2
      };
  }
}

function getEdgePositions(sourceConnector, sourcePosition, targetConnector, targetPosition) {
  const sourceConnectorPos = getConnectorPosition(sourcePosition, sourceConnector);
  const targetConnectorPos = getConnectorPosition(targetPosition, targetConnector);

  return {
    sourceX: sourceConnectorPos.x,
    sourceY: sourceConnectorPos.y,
    targetX: targetConnectorPos.x,
    targetY: targetConnectorPos.y
  };
}

function getMousePosition(event) {
  try {
    const canvas = document.getElementsByClassName('viewer2')[0];
    if (!canvas) {
      return;
    }

    const style = window.getComputedStyle(canvas);
    const { m22: zoom } = new window.DOMMatrixReadOnly(style.transform);

    const canvasBounds = canvas?.getBoundingClientRect();
    const x = (event.clientX - canvasBounds.left) / zoom;
    const y = (event.clientY - canvasBounds.top) / zoom;
    return [x, y];
  } catch (err) {
    console.error('getMousePosition Error: ', err.message, err);
  }
}

function getElementPosition(event, element) {
  const target = document.getElementsByClassName('viewer2')[0];
  if (!target) {
    return;
  }
  const containerBounds = target?.getBoundingClientRect();
  if (event)
    return {
      x: event.clientX - containerBounds.left,
      y: event.clientY - containerBounds.top
    };
  if (element) {
    const elementBounds = element?.getBoundingClientRect();
    const leftBound = elementBounds?.left - containerBounds?.left;
    const topBound = elementBounds?.top - containerBounds?.top;
    const width = elementBounds?.width;
    const height = elementBounds?.height;
    return {
      x: leftBound,
      y: topBound,
      width: width,
      height: height
    };
  }
}

function getOptionConnectorSource(sourceConnectingId, optionIndex, mousePosition) {
  const sourceConnectingIdLeft = `${sourceConnectingId}-left-${optionIndex}`;
  const sourceConnectingIdRight = `${sourceConnectingId}-right-${optionIndex}`;
  const sourceConnectingElementLeft = document.getElementById(sourceConnectingIdLeft);
  const sourceConnectingElementRight = document.getElementById(sourceConnectingIdRight);
  const leftConnectorPosition = getConnectorPositionInCanvas(sourceConnectingElementLeft);
  const rightConnectorPosition = getConnectorPositionInCanvas(sourceConnectingElementRight);

  //Get whichever source connector on the current option (left or right) that is closest to the current mouses position, so that the links source updates accordingly in real time.
  if (Math.abs(mousePosition.x - leftConnectorPosition.x) > Math.abs(mousePosition.x - rightConnectorPosition.x)) {
    return {
      sourceConnectingPosition: Position.Right,
      sourceConnectingElement: sourceConnectingElementRight
    };
  } else {
    return {
      sourceConnectingPosition: Position.Left,
      sourceConnectingElement: sourceConnectingElementLeft
    };
  }
}

export { getMousePosition, getConnectorPosition, getEdgePositions, getElementPosition, getOptionConnectorSource };
