import { Mark, markPasteRule } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

const LinkPasteRuleRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-zA-Z]{2,}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;

export const Link = Mark.create({
  name: 'link',

  addOptions: {
    openOnClick: false,
    target: '_blank'
  },

  parseHTML() {
    return [
      {
        tag: 'a[href]',
        getAttrs: (dom) => ({
          href: dom.getAttribute('href')
        })
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'a',
      {
        ...HTMLAttributes,
        rel: 'noopener noreferrer nofollow',
        target: this.options.target
      },
      0
    ];
  },

  addCommands() {
    return {
      setLink:
        (attributes) =>
        ({ commands }) => {
          return commands.setMark('link', attributes);
        },
      toggleLink:
        () =>
        ({ commands }) => {
          return commands.toggleMark('link');
        },
      unsetLink:
        () =>
        ({ commands }) => {
          return commands.unsetMark('link');
        }
    };
  },

  addPasteRules() {
    return [markPasteRule(LinkPasteRuleRegex, this.type, (match) => ({ href: match[0] }))];
  },

  addProseMirrorPlugins() {
    if (!this.options.openOnClick) {
      return [];
    }

    return [
      new Plugin({
        props: {
          handleClick: (/*view, pos, */ event) => {
            const attrs = this.editor.getAttributes('link');
            if (attrs.href && event.target instanceof HTMLAnchorElement) {
              event.stopPropagation();
              window.open(attrs.href, this.options.target);
            }
          }
        }
      })
    ];
  }
});
