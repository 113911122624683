<template>
  <div>
    <editor-content :style="customStyle" v-if="type === 'input' && !readOnly" :class="customClass" :editor="editor" />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { Superscript, Subscript, Highlight, Limit } from '@/components/ConversationBuilder/Extensions/index.js';
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Focus from '@tiptap/extension-focus';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
export default {
  components: {
    EditorContent
  },
  props: {
    limit: {
      type: Number,
      default: 10000
    },
    customStyle: {
      type: Object,
      required: false
    },
    customClass: {
      type: Object,
      required: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: String
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      editor: null,
      editorChange: false,
      query: null,
      users: useAppStore().getAllUsers
    };
  },
  computed: {
    setLanguage() {
      return this.appStore.language;
    },
    voiceActive() {
      return this.appStore.voiceActive;
    }
  },
  mounted() {
    this.editor = new Editor({
      editable: this.readOnly ? false : true,
      onFocus: () => {},
      onBlur: () => {},
      onUpdate: ({ getHTML }) => {
        this.editorChange = true;
        this.$emit('input', getHTML());
      },
      content: this.value,
      extensions: [
        StarterKit,
        Superscript,
        Subscript,
        Highlight,
        Underline,
        Focus.configure({
          className: 'has-focus',
          nested: true
        }),
        Limit.configure({
          limit: this.limit
        }),
        Placeholder.configure({
          emptyEditorClass: 'is-editor-empty',
          emptyNodeClass: 'is-empty',
          emptyNodeText: this.placeholder,
          showOnlyWhenEditable: true,
          showOnlyCurrent: false
        })
      ]
    });
  },
  methods: {},
  beforeUnmount() {
    try {
      if (this.editor && this.editor?.element) this.editor.destroy();
    } catch (err) {
      //
    }
  },
  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        this.editor.commands.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>

<style>
.bubble.bot.card p highlight {
  color: yellow;
}

.bubble.bot.card > div > p,
input.bubble.bot.card {
  color: #2c3f51;
}

.bubble.bot.card::placeholder,
input.bubble.bot.card::placeholder {
  color: #aaa;
}

.subtitlenew p,
.infonew p {
  margin-bottom: 0em;
}
</style>
