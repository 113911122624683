import AppTaxonomies from '@/views/AppTaxonomies.vue';
import AppHome from '@/views/AppHome.vue';
import ChangeSlot from '@/views/ChangeSlot.vue';
import Training from '@/components/TrainingPhrases/TrainingPhrases.vue';
import ConversationBuilder from '@/components/ConversationBuilder/ConversationBuilder';
import HistoryPage from '@/views/HistoryPage.vue';
import AppLogin from '@/views/AppLogin.vue';
import AppAdmin from '@/views/AppAdmin.vue';
import AppNotifications from '@/views/3.0/AppNotifications.vue';
import SignUp from '@/views/SignUp.vue';
import FlowTransform from '@/views/3.0/FlowTransform';
import AppAgent from '@/views/3.0/AppAgent';
import AgentTransfer from '@/views/AgentTransfer.vue';
import ReleaseMain from '@/views/3.0/Release/ReleaseMain.vue';
import { createWebHistory, createRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useAppStore } from '@/store/index.js';
import { h } from 'vue';
import { RouterView } from 'vue-router';

const agentRoutes = [
  {
    path: '/agents',
    component: { render: () => h(RouterView) },
    children: [
      {
        path: '',
        name: 'Home',
        component: AppTaxonomies,
        meta: {
          breadCrumbs: [
            {
              to: '/agents',
              text: 'Agents'
            }
          ],
          requiresAuth: true,
          clientAccessible: true,
          match: 'taxonomies'
        },
        props: true
      },
      {
        path: 'flows/:id',
        component: AppAgent,
        name: 'Agent',
        meta: {
          requiresAuth: true,
          clientAccessible: true,
          match: 'agents'
        },
        props: true
      },
      {
        path: 'intents/:id',
        component: AppAgent,
        name: 'Intents',
        meta: {
          breadCrumbs: [
            {
              to: '/agents/intents',
              text: 'Intents'
            }
          ],
          requiresAuth: true,
          clientAccessible: true,
          match: 'intents'
        },
        props: true
      },
      {
        name: 'Entities',
        path: 'entities/:id',
        component: AppAgent,
        breadCrumbs: 'Entities',
        meta: {
          breadCrumbs: [
            {
              to: '/agents/entities',
              text: 'Entities'
            }
          ],
          requiresAuth: true,
          clientAccessible: false,
          match: 'entities'
        },
        props: true
      }
    ]
  },
  {
    name: 'ConvoBuilder',
    path: '/conversationBuilder/:collection/:flowId/:flowName',
    component: ConversationBuilder,
    meta: {
      breadCrumbs: [
        {
          to: '/conversationBuilder',
          text: 'ConvoBuilder'
        }
      ],
      requiresAuth: true,
      clientAccessible: true,
      match: 'conversationbuilder'
    },
    props: true
  }
];

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Home' }
  },
  ...agentRoutes,
  {
    path: '/transform',
    name: 'transform',
    component: FlowTransform,
    meta: {
      requiresAuth: true,
      clientAccessible: false,
      breadCrumbs: [
        {
          to: '/Transform', // hyperlink
          text: 'Transform' // crumb text
        }
      ]
    },
    props: true
  },
  {
    path: '/changeSlot',
    name: 'changeSlot',
    component: ChangeSlot,
    meta: {
      requiresAuth: true,
      clientAccessible: true
    },
    props: true
  },
  {
    path: '/admin',
    name: 'admin',
    component: AppAdmin,
    meta: {
      requiresAuth: true,
      clientAccessible: false,
      breadCrumbs: [
        {
          to: '/admin', // hyperlink
          text: 'Users' // crumb text
        }
      ]
    },
    props: true
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: AppNotifications,
    meta: {
      requiresAuth: true,
      clientAccessible: true,
      breadCrumbs: [
        {
          to: '/notifications', // hyperlink
          text: 'Notifications' // crumb text
        }
      ]
    },
    props: true
  },
  {
    path: '/godflow', // TODO: Add meaningful names
    name: 'godflow',
    component: ConversationBuilder,
    meta: {
      requiresAuth: true,
      clientAccessible: true
    },
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: AppHome,
    meta: {
      requiresAuth: true,
      clientAccessible: true,
      breadCrumbs: [
        {
          to: '/home', // hyperlink
          text: 'Home' // crumb text
        }
      ]
    },
    props: true
  },
  { path: '/training', component: Training },
  {
    path: '/login',
    name: 'Login',
    component: AppLogin,
    meta: {
      requiresAuth: false,
      clientAccessible: true
    },
    props: true
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    meta: {
      clientAccessible: false
    }
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryPage,
    meta: {
      requiresAuth: true,
      clientAccessible: false
    },
    props: true
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: AgentTransfer,
    meta: {
      requiresAuth: true,
      clientAccessible: false,
      breadCrumbs: [
        {
          to: '/transfer', // hyperlink
          text: 'Transfer' // crumb text
        }
      ]
    },
    props: true
  },
  {
    path: '/release',
    name: 'release',
    component: ReleaseMain,
    meta: {
      requiresAuth: true,
      clientAccessible: false,
      breadCrumbs: [
        {
          to: '/release', // hyperlink
          text: 'Release' // crumb text
        }
      ]
    },
    props: true
  }
];

const router = createRouter({
  routes: routes,
  history: createWebHistory()
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// Route Guard
router.beforeEach((to, from, next) => {
  const appStore = useAppStore();
  const currentUser = appStore.user;
  const requiresAuth = to.matched.some((record) => record.meta?.requiresAuth);
  const clientAccessible = to.matched.some((record) => record.meta?.clientAccessible);

  if (!currentUser && requiresAuth) {
    return next('login');
  } else if (currentUser && !requiresAuth) {
    return next('agents');
  }

  if (currentUser) {
    currentUser
      .getIdTokenResult()
      .then((idTokenResult) => {
        const isGuest = idTokenResult.claims.role === 'GUEST';
        const isAdmin = idTokenResult.claims.admin;

        if (isGuest && to.name !== 'home') {
          displayErrorMessage('Access denied');
          return next('agents');
        }

        if (!isAdmin && !clientAccessible) {
          displayErrorMessage('Access denied for non admin user');
          return next('agents');
        }

        next();
      })
      .catch((error) => {
        console.error('Routing Error: ', error.message, error);
        next('login');
      });
  } else {
    next();
  }
});

function displayErrorMessage(message) {
  ElMessage({
    showClose: true,
    message: message,
    duration: 4000,
    type: 'error'
  });
}

export default router;
