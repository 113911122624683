<template>
  <div>
    <div class="row query-parameter-edit" @mouseover="hover = true" @mouseleave="hover = false">
      <div class="col-1 query-param-index">
        <span class="query-parameter-index">{{ index + 1 }}</span>
      </div>
      <div class="col-6 param-name">
        <input class="query-parameter-title" :id="componentId + '-title'" @input="updateName" :value="param.name" placeHolder="" :readOnly="false" />
      </div>
      <div class="col-1 remove-button" v-if="paramsCount > 1 && hover" @mouseover="hoverRemove = true" @mouseleave="hoverRemove = false" @click="removeParam">
        <ConversationBuilderIcon class="add-button" :width="20" :name="`remove${hoverRemove ? '-grey-hover' : ''}`" />
      </div>
    </div>
    <div class="value-container">
      <div class="row editor-header">
        <div class="col-11">
          <span class="sidebar type">Value</span>
        </div>
      </div>
      <input class="value-input" :id="componentId + '-value'" @input="updateValue" :value="param.value" placeHolder="Enter value" :readOnly="false" />
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
export default {
  name: 'LinkUrlParameterEach',
  props: {
    components: {},
    index: {
      type: Number,
      default: 0
    },
    param: {
      type: Object
    },
    componentId: {
      type: String,
      default: ''
    },
    paramsCount: {
      type: Number
    },
    mainComponentId: {}
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      hover: false,
      hoverRemove: false,
      paramName: '',
      paramValue: ''
    };
  },
  methods: {
    updateName(e) {
      const linkUrlComponent = this.appStore.currentComponent;

      const isNameUsed = linkUrlComponent.queryParameters.some((param, index) => param.name === e.target.value && this.index !== index);

      if (isNameUsed) {
        alert('The query parameters names have to be unique, please type another name.');

        return;
      }

      this.appStore.updateQueryParameter({
        name: e.target.value,
        value: this.param.value,
        linkUrlComponentId: this.componentId,
        paramIndex: this.index,
        mainCarouselId: this.mainComponentId
      });
    },
    updateValue(e) {
      this.appStore.updateQueryParameter({
        name: this.param.name,
        value: e.target.value,
        linkUrlComponentId: this.componentId,
        paramIndex: this.index,
        mainCarouselId: this.mainComponentId
      });
    },
    removeParam() {
      this.appStore.removeItemFromCollection({
        index: this.index,
        componentId: this.componentId,
        collectionField: 'queryParameters',
        mainCarouselId: this.mainComponentId
      });
      this.$emit('update-key');
    }
  },
  mounted() {
    this.paramName = this.param.name;
  }
};
</script>

<style scoped>
.value-input {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
  height: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #383f45;
  padding-left: 10px;
}

.value-input::placeholder {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #8e9ba8;
}

.value-input:hover {
  border: solid 1px #1996f0;
}

.arrow-button {
  padding: 0;
  margin-right: 8px;
}

.remove-button {
  padding: 0;
}

.query-parameter-edit {
  padding-right: 12px;
  display: flex;
}

.param-name {
  margin-right: auto;
}

.value-container {
  margin-left: 10px;
}

input.query-parameter-title {
  /* width: 240px !important; */
  height: 20px;
  margin-top: 1px;
  font-size: 14px;
  margin-bottom: 20px;
}
input::placeholder {
  font-style: normal !important;
  font-weight: normal;
  font-size: 14px;
}

.query-parameter-index {
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: left;
  color: #1996f0;
  margin-left: 5px;
}

.query-parameter-title {
  font-weight: bold;
  /* margin-left: 12px; */
  width: 100%;
}

.bi.bi-record-circle-fill {
  color: #1996f0;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.bi.bi-record-circle {
  color: #d7dde5;
  padding-right: 5px;
  font-size: 18px;
  margin-top: 2px;
}

.fas.fa-info-circle {
  margin-left: 5px;
}

.referenceInput {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
  height: 80px;
}

#rightSidebar {
  width: 270px;
  height: 80vh;
  margin-right: 0px;
  background-color: white;
  overflow: hidden scroll;
  padding-top: 10px;
}

.sidebar.type {
  font-size: 14px;
  color: #383f45;
}

.sideBarIcon {
  margin-right: 5px;
  width: 12px;
  font-size: 10px;
}

.message-editor-container {
  padding: 5px 5px 5px 8px;
  margin-left: 5px;
}

.editor-header {
  text-align: left;
  padding-bottom: 7px;
}

.message-editor {
  text-align: left;
  padding: 10px;
  border-radius: 4px;
  border: solid 1px #d7dde5;
  background-color: #fff;
}

select {
  padding: 3px 2px 3px 2px;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 100%;
}

input,
option,
select {
  font-size: 12px;
}

input::placeholder,
select::placeholder,
option::placeholder {
  font-size: 12px;
}
</style>
