<template>
  <div class="flows-agent">
    <p>{{ agentName }}</p>
    <div class="flows-drawer-button" v-click-outside="closeAgentDrawer">
      <div @click="showAgentDrawer = !showAgentDrawer">
        <ConversationBuilderIcon :width="30" :name="`drawer-button${showAgentDrawer ? '-active' : ''}`" />
      </div>
      <div class="flows-agent-drawer" v-if="showAgentDrawer">
        <div class="flows-search-bar">
          <input type="text" placeholder="Search Agent" v-model="searchAgent" />
          <img src="../../../../assets/icons/search.svg" alt="" />
        </div>
        <div class="flows-agents-container">
          <p v-for="taxonomy in getTaxonomies" :key="taxonomy.id" @click="() => changeAgent(taxonomy.id, tab)">
            {{ getName(taxonomy.name) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import ConversationBuilderIcon from '../../ConversationBuilder/Canvas/3.0/Icons/ConversationBuilderIcon.vue';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'AgentsSearch',
  props: {
    agentName: String,
    tab: String
  },
  components: {
    ConversationBuilderIcon
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      filterTaxonomy: '',
      showAgentDrawer: false,
      searchAgent: ''
    };
  },
  computed: {
    ...mapState(useAppStore, ['taxonomies']),
    getTaxonomies() {
      return this.taxonomies
        .filter((item) => item.name.toLowerCase().includes(this.searchAgent.toLowerCase()))
        .sort((firstEl, secondEl) => {
          return firstEl.timestamp && secondEl.timestamp ? secondEl.timestamp._seconds - firstEl.timestamp._seconds : 1;
        });
    }
  },
  methods: {
    changeAgent(agentId, tab) {
      this.$router.push({
        path: `/agents/${tab}/${agentId}`,
        query: { initial: tab }
      });
    },
    getName(name) {
      if (name.length <= 32) {
        return name;
      }

      return name.substring(0, 30) + '...';
    },
    closeAgentDrawer() {
      this.showAgentDrawer = false;
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
};
</script>

<style lang="scss" scoped>
.flows-agent {
  display: flex;
  align-items: center;

  p {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.33;
    text-align: left;
    color: #383f45;
    user-select: text;
  }

  .flows-drawer-button {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    margin-left: 14px;
    position: relative;

    .flows-agent-drawer {
      width: 320px;
      height: 280px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      position: absolute;
      top: 50px;
      left: 0;
      box-shadow: 0 0 6px 0 rgba(142, 155, 167, 0.2);
      border-radius: 4px;
      padding-top: 10px;
      z-index: 9999;

      .flows-search-bar {
        width: 300px;
        min-height: 48px;
        margin-left: 10px;
        margin-bottom: 10px;
      }

      .flows-agents-container {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        p {
          width: 100%;
          height: 30px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          font-size: 14px;
          line-height: 2.14;
          text-align: left;
          color: #383f45;
          cursor: pointer;
        }

        p:hover {
          background-color: #e8f5fe;
        }
      }
    }
  }
}

.flows-filter-bar {
  margin-left: auto;
  margin-right: 20px;
}

.flows-search-bar {
  height: 48px;
  border-radius: 4px;
  border: solid 1px #8e9ba7;
  background-color: #fff;
  position: relative;
  margin-right: 20px;

  input {
    width: 100%;
    height: 100%;
    padding-left: 15px;
    font-size: 14px;
    line-height: 3.07;
    text-align: left;
    color: #383f45;
  }

  input::placeholder {
    font-size: 14px;
    font-style: italic;
    line-height: 3.07;
    text-align: left;
    color: #8e9ba8;
  }

  img {
    position: absolute;
    top: 17px;
    right: 12px;
  }
}
</style>
