<template>
  <div id="edgeOptions" class="edgeOptions" :style="edgeOptionsContainerStyle" v-click-outside="destroyEdgeMenu">
    <EdgeOptions :lineId="id" :items="edgeOptionsData" @close="destroyEdgeMenu" :closeParent="destroyEdgeMenu" />
  </div>
</template>

<script>
import { useAppStore } from '@/store/index.js';
import tippy, { followCursor } from 'tippy.js';
import EdgeOptions from '../Dropdowns/EdgeOptions.vue';
import { nextTick } from 'vue';
import vClickOutside from 'click-outside-vue3';
import debounce from 'lodash/debounce';

export default {
  name: 'EdgeOptionsContainer',
  components: {
    EdgeOptions
  },
  props: {
    id: {
      type: [String, Number],
      default: ''
    },
    position: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      }
    },
    edgeProperties: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      show: {
        popup: null
      },
      edgeOptionsData: [
        {
          icon: 'dotted',
          title: 'Dotted',
          width: '30px',
          click: debounce(() => {
            this.setEdgeDotted();
          }, 300)
        },
        {
          icon: 'curved',
          title: 'Curved',
          width: '30px',
          click: debounce(() => {
            this.setEdgeCurved(true);
          }, 300)
        },
        {
          icon: 'smooth',
          title: 'Square',
          width: '30px',
          click: debounce(() => {
            this.setEdgeCurved(false);
          }, 300)
        },
        {
          icon: 'trash-2',
          iconHover: 'trash-hover',
          title: 'Delete',
          style: {},
          width: '30px',
          click: debounce(() => {
            this.linkDelete();
          }, 300)
        }
      ]
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    setEdgeCurved(isCurved) {
      if (isCurved) {
        this.appStore.selectedEdgeOptionData = { id: this.id, selectedOption: 'curved' };
      } else {
        this.appStore.selectedEdgeOptionData = { id: this.id, selectedOption: 'smooth' };
      }
    },
    setEdgeDotted() {
      this.appStore.selectedEdgeOptionData = { id: this.id, selectedOption: 'dotted' };
    },
    destroyEdgeMenu() {
      if (this.show.popup) {
        this.show.popup.destroy();
        this.show.popup = null;
      }
      this.$emit('closeEdgeOptions');
    },
    openOptions(target) {
      try {
        nextTick(() => {
          this.show.popup = tippy(target, {
            content: () => {
              const container = document.getElementById('edgeOptions');
              return container;
            },
            interactive: true,
            trigger: 'manual',
            hideOnClick: true,
            theme: 'light',
            delay: 0,
            inertia: true,
            duration: 0,
            followCursor: 'initial',
            plugins: [followCursor]
          });
          this.show.popup.show();
        });
      } catch (err) {
        console.error('Error: ', err.message, err);
      }
    },
    linkDelete() {
      this.appStore.selectedEdgeOptionData = { id: this.id, selectedOption: 'delete' };
    }
  },
  computed: {
    edgeOptionsContainerStyle() {
      const styleObject = {
        position: 'absolute',
        left: `${this.position.x}px`,
        top: `${this.position.y}px`
      };

      return styleObject;
    }
  },
  beforeUnmount() {
    this.destroyEdgeMenu();
  }
};
</script>

<style lang="scss">
#edgeOptions {
  height: 55px;
  padding: 7px 7px 7px 7px;
  z-index: 5;
}

.edgeOptionsWrapper {
  z-index: 1;
  position: absolute;
}

.tippy-tooltip.light-theme {
  background-color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 1rem;
  text-align: inherit;
  color: white;
  border-radius: 4px;
  .tippy-backdrop {
    display: none;
  }
  .tippy-roundarrow {
    fill: #fff;
  }
  .tippy-popper[x-placement^='top'] & .tippy-arrow {
    border-top-color: #fff;
  }
  .tippy-popper[x-placement^='bottom'] & .tippy-arrow {
    border-bottom-color: #fff;
  }
  .tippy-popper[x-placement^='left'] & .tippy-arrow {
    border-left-color: #fff;
  }
  .tippy-popper[x-placement^='right'] & .tippy-arrow {
    border-right-color: #fff;
  }
}
</style>
