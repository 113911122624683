<template>
  <div class="flow-row-wrapper" :class="{ active: flowPropertiesVisible }">
    <div class="flow-row-content">
      <div class="flow-row-content-column visible" :title="`selectFlow`" style="width: 9%">
        <div class="checkbox-container">
          <!-- eslint-disable -->
          <input class="input-checkbox" v-model="flow.selected" type="checkbox" />
        </div>
      </div>
      <div class="flow-row-content-column visible" :title="getName" style="width: 26%">
        <p>{{ getName }}</p>
      </div>
      <div class="flow-row-content-column" style="width: 17%">
        <p>{{ getFormattedTimestamp(flow.updatedAt) }}</p>
      </div>
      <div class="flow-row-content-column" style="width: 13%">
        <img class="status-icon" :src="getStatusIconSource" :alt="getStatus" />
        <p>{{ getStatus }}</p>
      </div>
      <div class="flow-row-content-column" style="width: 10%">
        <div v-if="isAdmin" class="publishedStatus-icon" :class="{ active: flow.isActive, inactive: !flow.isActive }"></div>
        <p v-if="isAdmin">{{ flow.isActive ? 'Active' : 'Inactive' }}</p>
      </div>
      <div class="flow-row-content-column" style="width: 8%; justify-content: center">
        <div v-if="isAdmin">
          <p class="intent-count" @mouseover="linkedIntentsHover = true" @mouseleave="linkedIntentsHover = false">
            {{ getLinkedIntentsCount }}
          </p>
          <div v-if="getLinkedIntentsCount > 0 && linkedIntentsHover" class="linked-intents">
            <div class="linked-intents content">
              <p class="intent" v-for="(intent, index) in flow.linkedIntents" :key="index">
                {{ intent.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flow-row-content-column flow-row-content-column-end hide-on-hover" style="width: 11%">
        <p class="flow-row-content-published active" :class="flow.isActive ? 'active' : 'inactive'" v-if="flow.published">Published</p>
      </div>
      <div class="flow-row-content-column flow-row-content-column-end hide-on-hover" style="width: 8%">
        <ConversationBuilderIcon v-if="isWatching" :width="30" name="watching-currently" />
      </div>
      <div class="flow-row-content-column flow-row-hover-column" :style="{ width: '11%', 'margin-left': 0 }">
        <div class="flow-row-edit-row" title="Edit Flow" @click="openConvoBuilder" @mouseover="hoverEdit = true" @mouseleave="hoverEdit = false" style="min-width: 120px">
          <ConversationBuilderIcon :width="22" :name="`flow-edit${hoverEdit ? '-active' : ''}`" />
          <p>Edit Flow</p>
        </div>
      </div>
      <div class="flow-row-content-column flow-row-hover-column" :style="{ width: '8%' }">
        <div title="Watch this Flow" @click="setWatching">
          <ConversationBuilderIcon :width="30" :name-hover="`watching${isWatching ? '-active' : '-hover'}`" :name="`watching${isWatching ? '-active' : ''}`" />
        </div>
        <div title="Flow Properties" @click="openFlowProperties">
          <ConversationBuilderIcon :width="30" :name-hover="'settings-hover'" :name="`settings`" />
          <FlowProperties v-if="flowPropertiesVisible" :flowObject="flow" :agentName="agentName" @close-flow-properties-form="closeFlowProperties" />
        </div>
        <div class="dropdown show">
          <div id="dropdownMenuLink" data-bs-toggle="dropdown">
            <ConversationBuilderIcon :width="30" :name-hover="'dots-hover'" :name="`dots`" />
          </div>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
            <a @click="onRename" class="dropdown-item"><img class="img" :src="require('../../../assets/icons/rename_icon.svg')" alt="icon not found" />Rename</a>
            <a @click="onDuplicate" class="dropdown-item"><img class="img" :src="require('../../../assets/icons/duplicate_icon.svg')" alt="icon not found" />Duplicate</a>
            <!-- <a class="dropdown-item"><img class="img" :src="require('../../../assets/icons/save_template_icon.svg')"
                  alt="icon not found" />Save as template</a> -->
            <a @click="onDelete" class="dropdown-item"><img class="img" :src="require('../../../assets/icons/delete_icon.svg')" alt="icon not found" />Delete</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { mapState } from 'pinia';
import ConversationBuilderIcon from '../../components/ConversationBuilder/Canvas/3.0/Icons/ConversationBuilderIcon.vue';
import FlowProperties from './FlowProperties.vue';
import { mixin as clickAway } from 'vue3-click-away';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

export default {
  name: 'FlowRow',
  mixins: [clickAway],
  components: {
    ConversationBuilderIcon,
    FlowProperties
  },
  props: {
    flow: Object,
    agentName: String,
    browserWidth: Number
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      hoverEdit: false,
      flowPropertiesVisible: false,
      scrDebounceWidth: window.innerWidth,
      scrWidthTimeout: null,
      prevScrWidth: 1280,
      dynamicLeftMargin: 0,
      linkedIntentsHover: false
    };
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
  },
  mounted() {
    this.scrWidth = window.innerWidth;
    const diff = this.prevScrWidth - this.scrWidth;
    this.dynamicLeftMargin = diff * 0.13125 + 79; //calculating the left-margin relative to 1280px screen width
    return;
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  },
  computed: {
    ...mapState(useAppStore, ['getUserLess', 'isAdmin']),
    getLinkedIntentsCount() {
      if (this.flow.linkedIntents?.length === 0) return '';

      return this.flow.linkedIntents?.length;
    },
    getName() {
      let cutoffPoint = 52;
      const flowName = this.flow?.name;

      if (this.browserWidth < 1920) {
        cutoffPoint = 38;
      }

      if (this.browserWidth <= 1440) {
        cutoffPoint = 30;
      }

      if (this.flow.name.length <= cutoffPoint) {
        return flowName;
      }

      return flowName.substring(0, cutoffPoint - 2) + '...';
    },
    isWatching() {
      return this.flow.watching?.includes(this.getUserLess.email);
    },
    getStatus() {
      if (this.flow.status === 'AE/PC') {
        return this.flow.status;
      } else if (this.flow.status === 'ONFS' || this.flow.status === 'ONFs') {
        return 'ONFs';
      } else if (this.flow.status === 'TESTED BY QA' || this.flow.status === 'Tested by QA') {
        return 'Tested by QA';
      }

      return this.flow.status.charAt(0).toUpperCase() + this.flow.status.substr(1).toLowerCase();
    },
    getStatusIconSource() {
      const statusIcons = {
        APPROVED: 'status-approved',
        'MISSING CONTENT': 'status-missing-content',
        DISABLED: 'status-disabled',
        'IN PROGRESS': 'status-in-progress',
        NONE: 'status-none',
        'CLIENT REVIEWED': 'status-client-reviewed',
        'READY FOR REVIEW': 'status-ready-for-review',
        'TESTED BY QA': 'status-checkmark',
        IMPERSONATION: 'status-checkmark',
        'AE/PC': 'status-checkmark',
        ONFS: 'status-checkmark',
        FORM: 'status-checkmark',
        OFFBOARDING: 'status-checkmark'
      };
      const currentStatus = statusIcons[this.flow.status.toUpperCase()] || 'status-none';

      return require(`../../../assets/icons/${currentStatus}.svg`);
    },
    scrWidth: {
      get() {
        return this.scrDebounceWidth;
      },
      set(val) {
        if (this.scrWidthTimeout) {
          clearTimeout(this.scrWidthTimeout);
        }
        this.scrWidthTimeout = setTimeout(() => {
          this.scrDebounceWidth = val;
        }, 500);
      }
    }
  },
  methods: {
    getFormattedTimestamp(timestamp) {
      return this.$dayjs(`${new Date(timestamp).toISOString()}`).format('MMMM D, YYYY h:mm A') || 'null';
    },
    async onRename() {
      await this.appStore.openDrawer({
        drawer: 'renameFlow',
        taxonomy: this.agentName,
        metadata: {
          flow: this.flow,
          flows: this.appStore.current.flows,
          agentName: this.agentName
        }
      });
    },
    async onDelete() {
      const performDelete = confirm('Are you sure that you want to  delete the flow, this action is not reversible.');
      if (performDelete) {
        this.$vs.loading();
        await axios.delete(`${process.env.VUE_APP_INTEGRATIONS_ENDPOINT}/flows/${this.agentName}/${this.flow.id}/delete`).catch((err) => {
          console.log(err.message);
        });
        await this.appStore.getAllFlows({
          collection: this.agentName,
          dropdown: false,
          store: true,
          reload: true
        });
        this.$vs.loading.close();
        this.$message({
          showClose: true,
          message: 'Flow Deleted.',
          type: 'info'
        });
        this.$emit('flowDeleted', { name: this.flow.name });
      }
    },
    async onDuplicate() {
      const flows = this.appStore.current.flows.filter((flow) => {
        if (flow.name.search(`${this.flow.name}`) !== -1) {
          return true;
        }
        return false;
      });

      this.$vs.loading();
      await this.appStore.createDuplicateFlow({
        agentName: this.agentName,
        name: this.flow.name + ' Copy ' + flows.length,
        flow: this.flow
      });
      await this.appStore.getAllFlows({
        collection: this.agentName,
        dropdown: false,
        store: true,
        reload: true
      });
      this.$vs.loading.close();
      this.$message({
        showClose: true,
        message: 'Duplicate flow created.',
        type: 'info'
      });
      this.$emit('flowDuplicated', this.flow);
    },
    setWatching() {
      this.$emit('set-watching', this.flow.id);
    },
    openConvoBuilder() {
      this.$router.push({
        path: `/conversationBuilder/${this.agentName}/${this.flow.id}/${this.flow.name}`,
        query: { lang: this.appStore.language || 'en' }
      });
    },
    //Don't want to just toggle this.flowPropertiesVisible with one function. Having two functions prevents pressing the gear icon from closing the form without submitting the data. Form data is submitted when clicking outside form.
    openFlowProperties() {
      this.flowPropertiesVisible = true;
    },
    closeFlowProperties() {
      this.flowPropertiesVisible = false;
    }
  },
  resizeHandler() {
    this.prevScrWidth = this.scrWidth;
    this.scrWidth = window.innerWidth;
    const diff = this.prevScrWidth - this.scrWidth;

    this.dynamicLeftMargin = diff * 0.13125 + this.dynamicLeftMargin;
  }
};
</script>

<style lang="scss" scoped>
.status-icon {
  padding-right: 16px;
}

.visible {
  display: flex !important;
}

.img {
  margin-right: 3%;
}

.dropdown-item {
  cursor: pointer;
  padding: 2%;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background: #e8f5fe;
}

.flow-row-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  min-height: 50px;
  padding: 0 20px;
  height: 50px;

  .flow-row-content {
    width: 100%;
    display: flex;
    align-items: center;

    .flow-row-hover-column {
      display: none !important;
      margin-left: auto;
      justify-content: flex-start;
    }

    .flow-row-content-column {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      user-select: text;

      .intent-count {
        cursor: pointer;
        padding: 5px;
      }
      .intent-count:hover {
        color: #1996f0;
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.36;
        text-align: left;
        color: #383f45;
        margin-right: 10px;
      }

      .flow-row-status-color {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        // background-color: #27bdbe;
        margin-right: 17px;
      }

      .flow-row-content-published {
        width: 95px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        line-height: 3.43;
        border-radius: 4px;
      }
      .flow-row-content-published.active {
        color: #1996f0;
        background-color: rgba($color: #1996f0, $alpha: 0.1);
      }
      .flow-row-content-published.inactive {
        color: #d31b1b;
        background-color: rgba($color: #d31b1b, $alpha: 0.1);
      }

      .publishedStatus-icon {
        width: 7px;
        height: 7px;
        margin: 7px 9.5px 5px 0;
        padding: 1px;
        border-radius: 4px;
      }
      .publishedStatus-icon.active {
        background-color: #6cc900;
      }
      .publishedStatus-icon.inactive {
        background-color: #8e9ba7;
      }

      .flow-row-edit-row:hover {
        background-color: #1996f0;

        p {
          color: #fff;
        }
      }

      .flow-row-edit-row {
        width: 113px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: solid 1px #1996f0;
        background-color: #fff;
        cursor: pointer;

        p {
          font-size: 14px;
          text-align: left;
          color: #1996f0;
          margin-left: 5px;
        }
      }
    }

    .flow-row-content-column-end {
      width: 90px;
    }
  }
}

.flow-row-wrapper:hover,
.flow-row-wrapper.active {
  background-color: #f4f6fa;
  .flow-row-content {
    .hide-on-hover {
      display: none;
    }
    .flow-row-hover-column {
      display: flex !important;
    }
  }
}

.linked-intents {
  position: absolute;
  width: auto;
  border: 1px #e3e8ee solid;
  background-color: white;
  z-index: 2;

  .content {
    .intent {
      margin: 10px;
      word-wrap: break-word;
      width: auto;
    }
  }
}
</style>
