<template>
  <div>
    <div class="p-3">
      <div class="position-relative">
        <div ref="textareaCover" class="textarea-cover" />
        <Mentionable @apply="applyMention" :keys="['@', '＠']" :items="items" insert-space omit-key offset="6" @open="loadItems()" @search="loadItems($event)">
          <textarea :placeholder="placeholder" ref="comment" class="form-control comment" v-model="comment" @focus="autoGrow" @keyup="commentScroll" @scroll="commentScroll" @input="autoGrow" />
          <template #item="{ item }">
            <div class="user">
              <span class="user-item">
                {{ item.value }}
              </span>
            </div>
          </template>
        </Mentionable>
      </div>
    </div>
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import { Mentionable } from 'vue-mention';

export default {
  name: 'CommentMentions',
  components: {
    Mentionable
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      comment: '',
      editMode: this.isEdit,
      loadResizeTimeout: null,
      items: []
    };
  },
  props: {
    users: {
      required: true,
      type: Object
    },
    clearInputFlag: {
      required: true,
      type: Boolean
    },
    placeholder: {
      required: true,
      type: String
    },
    label: {
      required: true,
      type: String
    },
    editCommentText: {
      required: true,
      type: String,
      default: ''
    },
    isEdit: {
      required: true,
      type: Boolean
    }
  },
  watch: {
    clearInputFlag() {
      if (this.clearInputFlag === true) {
        this.comment = '';
        // When we send comment, lets put back textarea height back to normal
        const textArea = this.$refs.comment;
        textArea.style.height = '40px';
        this.comment = '';
      }
    }
  },
  mounted() {
    this.loadResizeTimeout = setTimeout(() => {
      this.resize();
      this.autoGrow();
      window.addEventListener('resize', this.resize);
    }, 0);
  },
  beforeUpdate() {
    if (this.clearInputFlag === true) {
      this.comment = '';
    }
  },
  beforeMount() {
    this.editMode = this.isEdit;
    if (this.editCommentText !== '' && this.editMode) {
      this.comment = this.editCommentText;
      this.editMode = false;
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resize);
    clearTimeout(this.loadResizeTimeout);
  },
  methods: {
    loadItems(searchText = null){
      this.items = searchText ? this.users.filter((user) => user.value.substring(1).toLowerCase().startsWith(searchText.toLowerCase())) : this.users;
    },
    resize() {
      const barWidth = this.$refs.comment?.getBoundingClientRect().width - this.$refs.comment.clientWidth - 2; // border
      this.$refs.textareaCover.style.paddingRight = `calc(12px + ${barWidth}px)`;
    },
    autoGrow() {
      // Makes textarea create new lines automatically instead of specifying rows
      const textArea = this.$refs.comment;
      textArea.style.height = '5px';
      textArea.style.height = textArea.scrollHeight + 'px';
    },
    clearInput() {
      const textArea = this.$refs.comment;
      textArea.value = '';
      this.comment = '';
      textArea.style.height = '1px';
      textArea.style.height = textArea.scrollHeight + 'px';
      this.CLEAR_PENDING();
    },
    commentScroll() {
      this.$refs.textareaCover.scrollTop = this.$refs.comment.scrollTop;
      this.resize();
      this.comment = this.$refs.comment.value;
      this.$emit('populateCommentText', this.$refs.comment.value);
    },
    applyMention(mentionedUser) {
      this.$emit('addToMentionedList', mentionedUser);
      this.$emit('populateCommentText', this.$refs.comment.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.comment {
  font-size: 16px !important;
  opacity: 1;
  resize: none;
  overflow: auto;
  height: 40px;
  min-height: 40px;
  max-height: 107px;
  padding-right: 18px;
}

.user {
  display: grid;
  align-items: center;
  justify-content: center;
  .user-item {
    width: 100%;
    font-weight: bold;
    padding: 4px 10px;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background: #00abe7;
      color: white;
    }
  }
}
</style>
