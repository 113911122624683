<template>
  <draggable v-model="commentsComponentDropped" item-key="id" :group="{ name: 'comments', pull: false, put: true }" :data-componentId="component.id" class="commentDropZone-component" ghost-class="ghost-hidden" handle=".handle" emptyInsertThreshold="10" filter="input" :preventOnFilter="false" @change="log">
    <template #item="{ element }">
      <div
        class="row"
        :data-componentId="component.id"
        :parent="element"
        :class="{
          decision: component.type === 'decision',
          message: component.type === 'message'
        }"
      >
        <div class="col-12" :data-componentId="component.id">
          <CarouselComponent :componentId="component.id" :response="component" v-if="component.type === 'carousel'" :readOnly="true" :id="component.id" :collection="collection" :type="`carousel`" />
          <MessageComponent :componentId="component.id" :response="component" v-if="component.type === 'message'" :readOnly="true" :id="component.id" :collection="collection" :type="`message`" />
          <DecisionComponent :componentId="component.id" :response="component" :id="component.id" @linking-stop="$emit('linkingStop')" @linking-start="$emit('linkingStart')" :isLinking="isLinking" v-if="component.type === 'decision'" placeHolder="Add Text" :readOnly="true" :collection="collection" :type="`decision`" :connected="isConnectedToHighlightedEdge" />
          <ClarificationComponent :componentId="component.id" :response="component" :id="component.id" @linking-stop="$emit('linkingStop')" @linking-start="$emit('linkingStart')" :isLinking="isLinking" v-if="component.type === 'clarification'" :readOnly="true" :collection="collection" :type="`clarification`" />
          <FormComponent :componentId="component.id" :response="component" v-if="component.type === 'form'" :readOnly="true" :id="component.id" :collection="collection" :type="`form`" />
          <FlowComponent :componentId="component.id" :response="component" v-if="component.type === 'flow'" placeHolder="Select Flow" :readOnly="true" :id="id" :collection="collection" :type="`flow`" />
          <LinkUrl :componentId="component.id" :response="component" v-if="component.type === 'linkUrl'" :readOnly="true" :id="component.id" :type="`linkUrl`" />
          <MessageComponentSet :componentId="component.id" :response="component" v-if="component.type === 'set'" :readOnly="true" :id="component.id" type="set" />
          <LinkToContainerComponent :componentId="component.id" v-if="component.type === 'linkToContainer'" :id="component.id" :response="component" :readOnly="true" />
        </div>
      </div>
    </template>
  </draggable>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
// TODO: This is WIP code to split ContainerComponent.vue
import { mapState } from 'pinia';
import MessageComponent from './MessageComponent.vue';
import DecisionComponent from './DecisionComponent.vue';
import FlowComponent from './FlowComponent.vue';
import ClarificationComponent from './ClarificationComponent.vue';
import LinkUrl from './LinkUrl.vue';
import FormComponent from './FormComponent.vue';
import CarouselComponent from './CarouselComponent.vue';
import MessageComponentSet from './MessageComponentSet.vue';
import LinkToContainerComponent from './LinkToContainerComponent.vue';

export default {
  name: 'DraggableComponentsList',
  props: {
    id: {
      type: String
    },
    titleValue: {
      type: String
    },
    component: {
      type: Object
    },
    collection: {
      type: Object
    },
    parent: {
      type: Object
    },
    readOnly: {
      type: Boolean
    },
    isLinking: {
      type: Boolean
    },
    type: {
      type: String
    },
    isConnectedToHighlightedEdge: {
      type: Boolean
    }
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      commentsContainerDropped: [],
      commentsComponentDropped: []
    };
  },
  components: {
    MessageComponentSet,
    FormComponent,
    DecisionComponent,
    ClarificationComponent,
    LinkUrl,
    CarouselComponent,
    LinkToContainerComponent,
    MessageComponent,
    FlowComponent
  },
  computed: {
    ...mapState(useAppStore, ['getContainer', 'components', 'getScene', 'containerComponents', 'componentId', 'containers', 'selectedContainers', 'canvasDisabled', 'isEditingContainerId', 'getTargetNode', 'getSourceNode', 'displayMode'])
  },
  methods: {
    linkingStart(payload) {
      payload.containerTitle = this.titleValue;
      this.$emit('node-selected', {
        id: this.id,
        event: payload.event,
        drag: true
      });
      this.$emit('linking-start', payload);
    },
    linkingStop(e) {
      this.$emit('linking-stop', e);
    }
  }
};
</script>

<style lang="scss" scoped>
.ghost-hidden {
  display: none !important;
}
</style>
