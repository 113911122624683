<template>
  <li :class="{ opened: opened }">
    <div @click="toggle" class="caption">
      <span class="expandMenu"
        ><i
          :class="{
            fas: true,
            'fa-caret-right': !opened,
            'fa-caret-down': opened
          }"
        ></i
      ></span>
      {{ title }}
    </div>
    <div class="sectionBody">
      <slot name="body"> </slot>
    </div>
  </li>
</template>

<script>
export default {
  name: 'ActionHistoryItem',
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    opened: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
li {
  padding: 10px 0;
}
li > .caption {
  font-weight: bold;
  font-size: 14px !important;
  cursor: pointer;
  height: 30px;
  line-height: 30px;
  padding-left: 10px;
}
.sectionBody {
  display: none;
  font-size: 14px;
  list-style-type: none;
  padding: 20px 10px 10px 30px;
}
li.opened .sectionBody {
  display: block;
}

.expandMenu {
  width: 20px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
}
.label {
  background: #fd5422;
  color: #fff;
  height: 30px;
  line-height: 30px;
  border-radius: 4px;
  padding: 0 21px;
  display: none;
  margin-right: 10px;
}
</style>
