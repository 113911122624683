<template>
  <div class="slider">
    <br />
    <flickity ref="flickity" :options="flickityOptions">
      <div class="carousel-cell" v-for="(slide, i) in slides" :key="i" :index="i">
        <figcaption :style="{ backgroundColor: backgroundColor, margin: 'auto' }">
          <div class="figcap">
            <div class="grid justify-items-center">
              <div>
                <tr>
                  <span v-html="slide.title" class="carouselTitle"></span>
                </tr>
                <tr>
                  <span class="carouselSubtitle" v-html="slide.subtitle"></span>
                </tr>
                <tr>
                  <div @click="postBackClick(slide.postback)" class="cta">
                    <span class="ctaText" v-html="slide.button"></span>
                  </div>
                </tr>
              </div>
            </div>
          </div>
        </figcaption>
      </div>
    </flickity>
    <br />
    <br />
  </div>
</template>

<script>
import { useAppStore, useAutoSaveStore, useCommentsStore, useVersionsStore, useHistoryStore, useNotificationsStore, useVcmStore } from '@/store/index.js';
import Flickity from 'vue-flickity';

export default {
  props: {
    dots: {
      type: Boolean,
      default: false
    },
    language: {
      type: String,
      default: ''
    },
    slides: {
      type: Array
    },
    backgroundColor: {
      type: String,
      default: 'white'
    },
    titleFontSize: {
      type: String,
      default: '18px'
    },
    subtitleFontSize: {
      type: String,
      default: '16px'
    }
  },
  components: {
    Flickity
  },
  data() {
    return {
      appStore: useAppStore(),
      autoSaveStore: useAutoSaveStore(),
      commentsStore: useCommentsStore(),
      versionsStore: useVersionsStore(),
      historyStore: useHistoryStore(),
      notificationsStore: useNotificationsStore(),
      vcmStore: useVcmStore(),
      flickityOptions: {
        prevNextButtons: false,
        pageDots: this.dots,
        wrapAround: true,
        adaptiveHeight: true
      }
    };
  },
  methods: {
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    postBackClick(url) {
      window.open(url, '__blank');
    }
  }
};
</script>

<style scoped>
.cta {
  padding: 8px 8px 8px 8px;
  border-radius: 4px;
  background: white;
  border: solid 1px #1996f0;
  color: #1996f0;
  width: max-content;
  margin-top: 10px;
}

.ctaText {
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.carouselTitle {
  font-weight: bold;
  font-stretch: normal;
  line-height: 1.49;
  letter-spacing: normal;
  color: #00214e;
}

.carouselSubtitle {
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: normal;
  color: #00214e;
  opacity: 0.4;
}

@media screen and (min-width: 200px) {
  /* half-width cells for larger devices */
  .carousel-cell {
    height: 10vh;
  }
  .carouselTitle {
    font-size: 14px;
  }
  .carouselSubtitle {
    font-size: 11px;
  }
  .ctaText {
    font-size: 11px;
  }
}

.flickity-viewport {
  height: 150px;
}

@media screen and (min-width: 550px) {
  /* half-width cells for larger devices */
  .carousel-cell {
    height: 20vh;
  }

  .carouselTitle {
    font-size: 14px;
  }
  .carouselSubtitle {
    font-size: 11px;
  }
  .ctaText {
    font-size: 11px;
  }
}

.carousel-cell {
  width: 90%;
  /* half-width */
  height: 250px;
  margin-left: -15px;
  margin-top: 3px;
}

.figcap {
  padding: 11px;
}

.slider {
  /* max-width: 600px; */
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  /* margin-right: auto; */
}

.carousel-3d-container figure {
  margin: 0;
}

figcaption {
  /* padding: 15px; */
  border-radius: 8px;
  min-width: 50%;
  max-width: 90%;
  background-color: white !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 5px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}

.carousel-3d-slide.current {
  border-radius: 10px;
  border: none;
}
</style>

<style>
.carousel-cell p {
  margin: 0px !important;
  display: block !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>
