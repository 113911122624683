const Position = {
  Left: 'left',
  Top: 'top',
  Right: 'right',
  Bottom: 'bottom'
};

const XYPosition = {
  x: undefined,
  y: undefined
};

const Dimensions = {
  width: undefined,
  height: undefined
};

const EdgePositions = {
  sourceX: undefined,
  sourceY: undefined,
  targetX: undefined,
  targetY: undefined
};

export { Position, XYPosition, Dimensions, EdgePositions };
